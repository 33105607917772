import React, { useState, useEffect, useContext } from 'react'

import './loop-back-detect-settings.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchLoopBackDetectSettings, saveLoopBackDetectSettings } from '../../../resources/loop-back-detect-settings';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import List from '../../../../components/list/list';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input'
import Button from '../../../../components/button/button'
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import extraValidators from '../../../common/validators';
import RemoveIcon from '../../../../components/icons/remove';
import { loopBackDetectConstants } from '../../../resources/loop-back-detect-settings'
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import LoopBackDetectStatus from './loop-back-detect-status';

export default function LoopBackDetectSettings({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [loopBackDetectSettings, setLoopBackDetectSettings] = useState(null)
    const [loopBackDetectSettingsPagination, setLoopBackDetectSettingsPagination] = useState({ pageNumber: 1, pageSize: 6 })

    const [newEntry, setNewEntry] = useState(null)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [success, setSuccess] = useState(false)

    const [pristine, setPristine] = useState(true)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {

        fetchLoopBackDetectSettings(backend, setLoopBackDetectSettings)

        setNewEntry({ vlan_id: '' })

        // eslint-disable-next-line
    }, [])

    const getColumns = () => {

        return [
            { header: t('tools.loop_back_detection.label.VLAN_ID'), align: 'center' },
            { header: t('common.label.ACTIONS'), align: 'center' },
        ]
    }

    const getVLANIds = () => {
        let begining = (loopBackDetectSettingsPagination.pageNumber - 1) * (loopBackDetectSettingsPagination.pageSize)
        let end = begining + loopBackDetectSettingsPagination.pageSize
        if (end > loopBackDetectSettings.vlan_list.length) {
            end = loopBackDetectSettings.vlan_list.length
        }
        let loopBackDetectSettingsLines = [];
        for (let i = begining; i < end; i++) {
            loopBackDetectSettingsLines.push(
                [
                    loopBackDetectSettings.vlan_list[i] === 0 ? t('tools.loop_back_detection.label.NO_TAG') : loopBackDetectSettings.vlan_list[i],
                    <div>
                        <span className="clickable" onClick={e => removeItem(loopBackDetectSettings.vlan_list[i])}><RemoveIcon></RemoveIcon></span>
                    </div>
                ]);
        }
        return loopBackDetectSettingsLines;
    }

    const removeItem = (vlanID) => {
        setPristine(false)
        loopBackDetectSettings.vlan_list = loopBackDetectSettings.vlan_list.filter(it => it !== vlanID)
        setLoopBackDetectSettings({ ...loopBackDetectSettings })
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            handleAddVlanId();
        }
    }

    const handleAddVlanId = () => {
        if (newEntry.vlan_id && !isNaN(newEntry.vlan_id) && !vlanIdAlreadyExists(newEntry.vlan_id) &&
            loopBackDetectSettings.vlan_list.length < loopBackDetectConstants.MAX_VLAN_ID_ELEMENTS) {
            loopBackDetectSettings.vlan_list.push(parseInt(newEntry.vlan_id))
            setLoopBackDetectSettings({ ...loopBackDetectSettings })
            setNewEntry({ vlan_id: '' })
        }
    }

    const vlanAlreadyExistsValidator = async (value) => {
        return value && vlanIdAlreadyExists(value) ? t('validators.ID_ALREADY_EXISTS') : '';
    }

    const vlanMaxSize = async (value) => {
        const max = loopBackDetectConstants.MAX_VLAN_ID_ELEMENTS;
        return value && loopBackDetectSettings.vlan_list.length > max - 1 ? t('validators.MAX_NUMBER_OF_ELEMENTS', { max: max }) : '';
    }

    const vlanIdAlreadyExists = (vlanId) => {
        return loopBackDetectSettings.vlan_list.filter(it => Number(it) === Number(vlanId)).length > 0;
    }

    const changePage = page => {
        setLoopBackDetectSettingsPagination({ ...loopBackDetectSettingsPagination, pageNumber: page })
    }

    const loopBackDetectSettingsForm = () => {
        return <React.Fragment>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="tools.loop_back_detection.title.INTERVAL_FRAME_CONFIG"></Translator></div>
                <Checkbox id='loop-back-detect-settings-enable'
                    name='enabled'
                    label={<Translator path="tools.loop_back_detection.label.ENABLE_LOOP_DETECTION"></Translator>}
                    value={loopBackDetectSettings.enabled}
                    toggleFn={(e) => {
                        loopBackDetectSettings.enabled = !loopBackDetectSettings.enabled;
                        setLoopBackDetectSettings({ ...loopBackDetectSettings })
                    }}
                    validators={[]}
                ></Checkbox>
                <Input id='loop-back-detect-settings-detection-interval'
                    name='detection_interval'
                    type="number"
                    label={<Translator path="tools.loop_back_detection.label.DETECTION_INTERVAL" required={true}></Translator>}
                    value={loopBackDetectSettings.detection_interval}
                    onChange={(e) => {
                        loopBackDetectSettings.detection_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setLoopBackDetectSettings({ ...loopBackDetectSettings })
                    }}
                    validators={[
                        extraValidators.required,
                        extraValidators.isNumber,
                        {
                            fn: extraValidators.value, params: {
                                min: loopBackDetectConstants.DETECTION_INTERVAL_MIN,
                                max: loopBackDetectConstants.DETECTION_INTERVAL_MAX
                            }
                        }
                    ]}
                ></Input>
                <Input id='loop-back-detect-settings-frame-type'
                    name='frame_type'
                    label={<Translator path="tools.loop_back_detection.label.FRAME_TYPE" required={true}></Translator>}
                    value={loopBackDetectSettings.frame_type}
                    onChange={(e) => {
                        loopBackDetectSettings.frame_type = e.target.value
                        setLoopBackDetectSettings({ ...loopBackDetectSettings })
                    }}
                    validators={[extraValidators.required, { fn: extraValidators.isHex }, { fn: extraValidators.size, params: { min: 3, max: 6 } }]}
                ></Input>
                <Input id='loop-back-detect-settings-recovery-interval'
                    name='recovery_interval'
                    type="number"
                    label={<Translator path="tools.loop_back_detection.label.RECOVERY_INTERVAL" required={true}></Translator>}
                    value={loopBackDetectSettings.recovery_interval}
                    onChange={(e) => {
                        loopBackDetectSettings.recovery_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setLoopBackDetectSettings({ ...loopBackDetectSettings })
                    }}
                    validators={[
                        extraValidators.required,
                        extraValidators.isNumber,
                        {
                            fn: extraValidators.value, params: {
                                min: loopBackDetectConstants.RECOVERY_INTERVAL_MIN,
                                max: loopBackDetectConstants.RECOVERY_INTERVAL_MAX
                            }
                        }
                    ]}
                ></Input>
            </div>

            <div className='card mt2'>
                <div className='subtitle'><Translator path="tools.loop_back_detection.label.ADD_VLAN"></Translator></div>
                <Input id='loop-back-detect-settings-vlan-id'
                    name='vlan_id'
                    label={<Translator path="tools.loop_back_detection.label.ADD_VLAN_ID"></Translator>}
                    value={newEntry.vlan_id}
                    onChange={(e) => {
                        newEntry.vlan_id = e.target.value
                        setNewEntry({ ...newEntry })
                    }}
                    onKeyDown={handleKeyDown}
                    validators={[extraValidators.isNumber, { fn: vlanAlreadyExistsValidator }, { fn: vlanMaxSize }, { fn: extraValidators.value, params: { min: 0, max: 4094 } }]}
                ></Input>

                <strong><Translator path="tools.loop_back_detection.info.SAVE_WARN"></Translator></strong>
                <strong><Translator path="tools.loop_back_detection.info.VLAN_WARN"></Translator></strong>

                <div className="align-center">
                    <Button
                        onClick={handleAddVlanId} text={t('common.label.ADD')}>
                    </Button>
                </div>
            </div>

            <div id='loop-back-detect-vlan-table'>
                <div className='subtitle mt2'><Translator path="tools.loop_back_detection.label.CONFIG_TABLE"></Translator></div>
                <List
                    lines={getVLANIds()}
                    columns={getColumns()}
                ></List>
                <Pager
                    pageNumber={loopBackDetectSettingsPagination.pageNumber}
                    totalElements={loopBackDetectSettings.vlan_list.length}
                    pageSize={loopBackDetectSettingsPagination.pageSize}
                    pageChangeFn={changePage}>
                </Pager>
            </div>
        </React.Fragment>
    }

    const submit = async () => {
        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        if (loopBackDetectSettings.vlan_list.length === 0 && loopBackDetectSettings.enabled) {
            setErrorMessage(t("tools.loop_back_detection.error.LOOP_BACK_DETECT_REQUIRE_VLAN"))
            setSaving(false)
            setError(true)
            return
        }

        let ok = await saveLoopBackDetectSettings(backend, loopBackDetectSettings)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !loopBackDetectSettings ? <Loading show={true}></Loading> :
            <>
                <div id='loop-back-detect-status-page' className='with-tabs'>
                    <div className='subtitle'><Translator path="tools.loop_back_detection.title.LOOP_BACK_DETECT_STATUS"></Translator></div>
                    <LoopBackDetectStatus />
                </div>

                <div id='loop-back-detect-settings-page' className='with-tabs'>
                    <div className='subtitle'><Translator path="tools.loop_back_detection.title.LOOP_BACK_DETECT"></Translator></div>
                    <DefaultStatusModals
                        saving={saving}
                        error={error}
                        errorText={errorMessage}
                        success={success}
                        continueFn={dismissModal}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    ></DefaultStatusModals>

                    {!isFormSegment ? <Form
                        key={pristine}
                        onSubmit={submit}
                        buttonId='button-save'
                        isPristine={pristine}
                    >
                        {loopBackDetectSettingsForm()}
                    </Form> :

                        <FormSegment
                            title={formSegment.title}
                            active={formSegment.active}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={submit}
                        >
                            {loopBackDetectSettingsForm()}
                        </FormSegment>}
                </div>
            </>
    )
}

import React, { useState, useEffect, useContext } from 'react'

import './firewall-domain-blocking.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchFirewallDomainBlocking, saveFirewallDomainBlocking } from '../../../resources/firewall-domain-blocking';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import List from '../../../../components/list/list';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input'
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import RemoveIcon from '../../../../components/icons/remove';
import { firewallDomainBlockingConstants } from '../../../resources/firewall-domain-blocking'
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';
import extraValidators from '../../../common/validators';
import Pager from '../../../../components/pager/pager';

export default function FirewallDomainBlocking({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [firewallDomainBlocking, setFirewallDomainBlocking] = useState(null)
    const [firewallDomainBlockingPagination, setFirewallDomainBlockingPagination] = useState({ pageNumber: 1, pageSize: 5 })

    const [newEntryDomain, setNewEntryDomain] = useState(null)

    const { t } = useTranslation()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    let [pristine, setPristine] = useState(true)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchFirewallDomainBlocking(backend, setFirewallDomainBlocking)
        setNewEntryDomain({ domain: '' })

        // eslint-disable-next-line
    }, [])

    const getColumnsDomains = () => {

        return [
            { header: t('common.label.DOMAIN'), align: 'center', size: '380px' },
            { header: t('common.label.ACTIONS'), align: 'center', size: '100px' },
        ]
    }

    const createStringSpan = string => {
        const MAX_STRING_SIZE = 40
        return (
            <span title={string}>
                {string.length > MAX_STRING_SIZE ? `${string.substring(0, MAX_STRING_SIZE)} ...` : string}
            </span>
        )
    }


    const getDomains = () => {
        let begining = (firewallDomainBlockingPagination.pageNumber - 1) * (firewallDomainBlockingPagination.pageSize)
        let end = begining + firewallDomainBlockingPagination.pageSize
        if (end > firewallDomainBlocking.domains.length) {
            end = firewallDomainBlocking.domains.length
        }
        let firewallDomainBlockingLines = [];
        for (let i = begining; i < end; i++) {
            if (firewallDomainBlocking.domains[i]) {
                firewallDomainBlockingLines.push(
                    [
                        createStringSpan(firewallDomainBlocking.domains[i]),
                        <div>
                            <span className="clickable" onClick={e => removeItemDomain(firewallDomainBlocking.domains[i])}><RemoveIcon></RemoveIcon></span>
                        </div>
                    ]);
            }
        }
        return firewallDomainBlockingLines;
    }

    const removeItemDomain = (domainId) => {
        setPristine(false)
        firewallDomainBlocking.domains = firewallDomainBlocking.domains.filter(it => it !== domainId)
        setFirewallDomainBlocking({ ...firewallDomainBlocking })
    }

    const handleKeyDownDomain = (event, fromButton) => {
        if (event.key === 'Enter' || fromButton) {
            if (!fromButton) {
                event.preventDefault()
            }
            if (newEntryDomain.domain.trim() && !domainIdAlreadyExists(newEntryDomain.domain) &&
                firewallDomainBlocking.domains.length < firewallDomainBlockingConstants.MAX_DOMAIN_ELEMENTS) {
                firewallDomainBlocking.domains.push(newEntryDomain.domain)
                setFirewallDomainBlocking({ ...firewallDomainBlocking })
                setNewEntryDomain({ domain: '' })
            }
        }
    }

    const domainAlreadyExistsValidator = async (value) => {
        return value && domainIdAlreadyExists(value) ? t('common.message.error.ITEM_ALREADY_EXISTS') : '';
    }

    const domainMaxSize = () => {
        return firewallDomainBlocking.domains.length > (firewallDomainBlockingConstants.MAX_DOMAIN_ELEMENTS - 1);
    }

    const domainIdAlreadyExists = (domainId) => {
        return firewallDomainBlocking.domains.filter(it => it === domainId).length > 0;
    }

    const changePage = page => {
        setFirewallDomainBlockingPagination({ ...firewallDomainBlockingPagination, pageNumber: page })
    }

    const firewallDomainBlockingForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="firewall.domain_blocking.label.ENABLE_DOMAIN_BLOCKING"></Translator></div>
                <Checkbox id='firewall-domain-blocking-enable'
                    name='enable'
                    label={<Translator path="common.label.ENABLE"></Translator>}
                    value={firewallDomainBlocking.enable}
                    toggleFn={(e) => {
                        firewallDomainBlocking.enable = !firewallDomainBlocking.enable;
                        setFirewallDomainBlocking({ ...firewallDomainBlocking })
                    }}
                    validators={[]}
                ></Checkbox>
                <Input id='firewall-domain-blocking-domain'
                    name='domain'
                    label={<Translator path="firewall.domain_blocking.label.ADD_DOMAIN_BLOCKING"></Translator>}
                    value={newEntryDomain.domain}
                    onChange={(e) => {
                        newEntryDomain.domain = e.target.value
                        setNewEntryDomain({ ...newEntryDomain })
                    }}
                    onKeyDown={handleKeyDownDomain}
                    validators={[
                        { fn: domainAlreadyExistsValidator }, 
                        extraValidators.forbidWhitespacesOnly,
                        {fn: extraValidators.size, params: { min: 0, max: firewallDomainBlockingConstants.MAX_DOMAIN_LEN}},
                        ]}
                ></Input>

                {domainMaxSize() && 
                    <strong>{t('common.message.error.MAX_NUMBER_OF_ELEMENTS_IS', { max: firewallDomainBlockingConstants.MAX_DOMAIN_ELEMENTS })}</strong>
                }

                <Button
                    type='button'
                    id='btn-add-rule'
                    text={<Translator path="common.label.ADD"></Translator>}
                    onClick={evt => handleKeyDownDomain(evt, true)}
                    disabled={domainMaxSize() || newEntryDomain.domain.length > firewallDomainBlockingConstants.MAX_DOMAIN_LEN}
                ></Button>
            </div>
            <div className="subtitle"> <Translator path="firewall.domain_blocking.title.DOMAINS"></Translator> </div>
            {firewallDomainBlocking.domains.length > 0 ?
                <div>
                    <div className='firewall-domain-blocking-table-wrapper'>
                        <List
                            width={'500px'}
                            lines={getDomains()}
                            columns={getColumnsDomains()}
                        ></List>
                    </div>
                    <Pager
                        pageNumber={firewallDomainBlockingPagination.pageNumber}
                        totalElements={firewallDomainBlocking.domains.length}
                        pageSize={firewallDomainBlockingPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                </div>
                :
                <div id='firewall-domain-blocking-no-domains'>
                    <div className='info-card noelements-card'>
                        <Translator path="firewall.domain_blocking.info.NO_DOMAINS" />
                    </div>
                </div>
            }
        </React.Fragment>
    }

    const submit = async () => {
        setPristine(true)
        if (saving || error || success) return

        setSaving(true)

        let ok = await saveFirewallDomainBlocking(backend, firewallDomainBlocking)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !firewallDomainBlocking ? <Loading show={true}></Loading> :

            <div id='firewall-domain-blocking-page' className='with-tabs'>
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path="firewall.domain_blocking.title.DOMAIN_BLOCKING_SETTINGS"></Translator></div>

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    success={success}
                    continueFn={dismissModal}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                {!isFormSegment ? <Form
                    key={pristine}
                    onSubmit={submit}
                    buttonId='button-save'
                    isPristine={pristine}
                >
                    {firewallDomainBlockingForm()}

                </Form> :

                    <FormSegment
                        title={formSegment.title}
                        active={formSegment.active}
                        nextLabel={formSegment.nextLabel}
                        nextClicked={submit}
                    >

                        {firewallDomainBlockingForm()}

                    </FormSegment>}
            </div>
    )
}

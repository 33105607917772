import React, { useState, useEffect, useContext } from 'react'

import './firewall-port-forward.css'
import { BackendContext } from '../../../../backend/backend'
import {
    fetchFirewallPortForwardEnable,
    saveFirewallPortForwardEnable,
    fetchFirewallPortForwardTable,
    saveFirewallPortForwardTable,
    deleteFirewallPortForwardTableById,
} from '../../../resources/firewall-port-forward';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import RemoveIcon from '../../../../components/icons/remove';
import Select from '../../../../components/select/select';
import extraValidators from '../../../common/validators';
import Input from '../../../../components/input/input';
import { fetchNetworkWanList } from '../../../resources/network-wan';
import Checkbox from '../../../../components/checkbox/checkbox';
import Modal, { ModalTypes } from '../../../../components/modal/modal';
import EditIcon from '../../../../components/icons/edit';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Ip, { } from 'ip'
import { fetchInterfaceLan } from '../../../resources/lan';
import Pager from '../../../../components/pager/pager';

export default function FirewallPortForward({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [firewallPortForwardEnabled, setFirewallPortForwardEnabled] = useState(null)
    const [firewallPortForwardTable, setFirewallPortForwardTable] = useState([])
    const [firewallPortForwardTablePagination, setFirewallPortForwardTablePagination] = useState({ pageNumber: 1, pageSize: 5 })

    const [deleteFirewallPortForwardTableData, setDeleteFirewallPortForwardTableData] = useState(null)

    const [newRegister, setNewRegister] = useState({
        remote_ip: '',
        remote_port_from: 0,
        remote_port_to: 0,
        interface: 'any',
        protocol: 1,
        local_ip: '',
        local_port_from: 0,
        local_port_to: 0,
        comment: '',
    })

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const { t } = useTranslation()

    const [errorMessage, setErrorMessage] = useState('')

    const [interfaces, setInterfaces] = useState([])

    const backend = useContext(BackendContext)


    useEffect(() => {

        fetchNetworkWanList(backend, setInterfaces)
        fetchFirewallPortForwardEnable(backend, setFirewallPortForwardEnabled)
        fetchFirewallPortForwardTable(backend, setFirewallPortForwardTable)
        setDeleteFirewallPortForwardTableData({ showDelete: false, id: null })

        // eslint-disable-next-line
    }, [])


    const getProtocol = (proto) => {
        switch (proto) {
            case 1: return t('common.label.TCP')
            case 2: return t('common.label.UDP')
            case 4: return t('common.label.BOTH')
            default: return '-'
        }
    }

    const validatePort = value => {
        return value && value >= 1 && value <= 65535 ? '' : t('common.message.error.VALIDATION_PORT_RANGE')
    }

    const validateRangeSource = value => {
        let validation = newRegister.local_port_to &&
            newRegister.local_port_from &&
            newRegister.local_port_from > newRegister.local_port_to ? t('common.message.error.VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM') : ''

        if (validation) {
            return validation
        }

        return firewallPortForwardTable.filter(pf => {
            return (newRegister.id ? newRegister.id !== pf.id : true) && pf.local_ip === newRegister.local_ip && ((pf.local_port_from >= newRegister.local_port_from &&
                pf.local_port_from <= newRegister.local_port_to) ||
                (pf.local_port_to >= newRegister.local_port_from &&
                    pf.local_port_to <= newRegister.local_port_to))
        }).length > 0 ? t('firewall.port_forward.message.error.RULE_ALREADY_EXISTS') : ''
    }

    const validateRangeDest = value => {
        let validation = newRegister.remote_port_to &&
            newRegister.remote_port_from &&
            newRegister.remote_port_from > newRegister.remote_port_to ? t('common.message.error.VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM') : ''

        if (validation) {
            return validation
        }

        return firewallPortForwardTable.filter(pf => {
            return (newRegister.id ? newRegister.id !== pf.id : true) && pf.remote_ip === newRegister.remote_ip && ((pf.remote_port_from >= newRegister.remote_port_from &&
                pf.remote_port_from <= newRegister.remote_port_to) ||
                (pf.remote_port_to >= newRegister.remote_port_from &&
                    pf.remote_port_to <= newRegister.remote_port_to))
        }).length > 0 ? t('firewall.port_forward.message.error.RULE_ALREADY_EXISTS') : ''
    }

    const getColumnsIpPort = () => {

        return [
            { header: t('firewall.port_forward.label.LOCAL'), align: 'center', size: '230px' },
            { header: t('firewall.port_forward.label.REMOTE'), align: 'center', size: '230px' },
            { header: t('firewall.port_forward.label.INTERFACE'), align: 'center', size: '125px' },
            { header: t('firewall.port_forward.label.PROTOCOL'), align: 'center', size: '125px' },
            { header: t('common.label.ACTIONS'), align: 'center', size: '90px' },
        ]
    }

    const getIpPort = () => {
        let begining = (firewallPortForwardTablePagination.pageNumber - 1) * (firewallPortForwardTablePagination.pageSize)
        let end = begining + firewallPortForwardTablePagination.pageSize
        if (end > firewallPortForwardTable.length) {
            end = firewallPortForwardTable.length
        }

        let firewallPortForwardTableLines = [];
        for (let i = begining; i < end; i++) {
            if (firewallPortForwardTable[i]) {
                firewallPortForwardTableLines.push(
                    [
                        `${firewallPortForwardTable[i].local_ip}:[${firewallPortForwardTable[i].local_port_from}-${firewallPortForwardTable[i].local_port_to}]`,
                        `${firewallPortForwardTable[i].remote_ip}:[${firewallPortForwardTable[i].remote_port_from}-${firewallPortForwardTable[i].remote_port_to}]`,
                        firewallPortForwardTable[i].interface,
                        getProtocol(firewallPortForwardTable[i].protocol),
                        <>
                            <span className="clickable" onClick={e => editItemIpPort(firewallPortForwardTable[i])}><EditIcon></EditIcon></span>
                            <span className="clickable" onClick={e => removeItemIpPort(firewallPortForwardTable[i])}><RemoveIcon></RemoveIcon></span>
                        </>
                    ]);

            }
        }
        return firewallPortForwardTableLines;
    }

    const deleteFirewallPortForwardTable = id => {
        deleteFirewallPortForwardTableById(backend, id, setFirewallPortForwardTable)
    }

    const DeleteFirewallPortForwardTableModal = (firewallPortForwardTable) => {
        return <Modal show='true'
            type={ModalTypes.CONFIRM_WARNING}

            onDismissClick={conf => {
                if (conf) {
                    deleteFirewallPortForwardTable(firewallPortForwardTable.id)
                }
                setDeleteFirewallPortForwardTableData({ showDelete: false })
            }}
            content={
                <div style={{ width: '420px' }}>
                    <span style={{ display: 'block' }}><Translator path="common.message.warning.PERMANENT_DELETE_WARNING"></Translator>
                        <br></br>
                    </span>
                    <b><Translator path="common.message.warning.CONFIRM_EXCLUSION"></Translator></b>
                </div>
            }
            dismissText={t('common.label.CANCEL')}
            confirmText={t('common.label.CONFIRM')}
        ></Modal>
    }

    const removeItemIpPort = (ipPort) => {
        setDeleteFirewallPortForwardTableData({ showDelete: true, id: ipPort.id })
    }

    const editItemIpPort = (ipPort) => {
        setNewRegister({ ...ipPort })
    }

    const getInterfaceOptions = () => {
        return [{ value: 'any', text: t('common.label.INTERFACE_ANY') }, ...interfaces.map(it => ({ value: it.id, text: it.id }))]
    }

    const submitNewRegister = async () => {
        let interface_br0 = await fetchInterfaceLan(backend, null)
        let currentNewRegister = newRegister;

        var ipMod = Ip
        let ip_mask = ipMod.mask(interface_br0.ip4, interface_br0.netmask);
        let current_local_ip_mask = ipMod.mask(currentNewRegister.local_ip, interface_br0.netmask);

        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        if (ip_mask !== current_local_ip_mask) {
            setErrorMessage(t('firewall.port_forward.message.error.ERROR_IP_OUT_OF_RANGE'))
            setSaving(false)
            setError(true)
            return
        }

        let ok = await saveFirewallPortForwardTable(backend, newRegister, setErrorMessage)

        if (!ok) {
            setErrorMessage(t('firewall.port_forward.message.error.ERROR_CREATING_PORT_FORWARD_RULE') +
                t('firewall.port_forward.message.error.FULL_TABLE_PORT_FORWARD'))
        }



        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

        setNewRegister({
            remote_ip: '',
            remote_port_from: 0,
            remote_port_to: 0,
            interface: 'any',
            protocol: 1,
            local_ip: '',
            local_port_from: 0,
            local_port_to: 0,
            comment: '',
        })
        await fetchFirewallPortForwardTable(backend, setFirewallPortForwardTable)
    }

    const firewallPortForwardForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div>
                <Checkbox id='firewall-port-forward-enabled'
                    name='enabled'
                    label={<Translator path="common.label.ENABLE"></Translator>}
                    value={firewallPortForwardEnabled.enabled}
                    toggleFn={(e) => {
                        firewallPortForwardEnabled.enabled = !firewallPortForwardEnabled.enabled;
                        setFirewallPortForwardEnabled({ ...firewallPortForwardEnabled })
                    }}
                    validators={[]}
                ></Checkbox>
            </div>
        </React.Fragment>
    }


    const newRegisterForm = () => {
        return <React.Fragment>
            {deleteFirewallPortForwardTableData.showDelete && <DeleteFirewallPortForwardTableModal id={deleteFirewallPortForwardTableData.id}></DeleteFirewallPortForwardTableModal>}

            <div className='subtitle'>
                {
                    newRegister.id ?
                        <Translator path="firewall.port_forward.label.EDIT_PORT_FORWARD_RULE"></Translator> :
                        <Translator path="firewall.port_forward.label.ADD_PORT_FORWARD_RULE"></Translator>}
            </div>
            <div>
                <div className="row larger-field-wrapper">
                    <div className="firewall-filter-group">
                        <div className="firewall-filter-sub-group">
                            <Input id='firewall-port-forward-source-ip-address'
                                name='local_ip'
                                type="text"
                                label={<Translator path="firewall.port_forward.label.LOCAL_IP"></Translator>}
                                value={newRegister.local_ip}
                                onChange={(e) => {
                                    newRegister.local_ip = e.target.value
                                    setNewRegister({ ...newRegister })
                                }}
                                validators={[
                                    extraValidators.nonASCII,
                                    extraValidators.required,
                                    extraValidators.validateIPv4,
                                    extraValidators.validateIfNotLocalhost
                                ]}
                            ></Input>
                        </div>
                        <div className="firewall-filter-sub-group">
                            <Input id='firewall-port-forward-source-port-from'
                                name='local_port_from'
                                type="number"
                                label={<Translator path="firewall.port_forward.label.LOCAL_PORT_FROM"></Translator>}
                                value={newRegister.local_port_from}
                                onChange={(e) => {
                                    newRegister.local_port_from = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    setNewRegister({ ...newRegister })
                                }}
                                validators={[extraValidators.nonASCII, extraValidators.isNumber, extraValidators.required, validatePort]}
                            ></Input>
                            <Input id='firewall-port-forward-source-port-to'
                                name='local_port_to'
                                type="number"
                                label={<Translator path="firewall.port_forward.label.LOCAL_PORT_TO"></Translator>}
                                value={newRegister.local_port_to}
                                onChange={(e) => {
                                    newRegister.local_port_to = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    setNewRegister({ ...newRegister })
                                }}
                                validators={[extraValidators.nonASCII, extraValidators.isNumber, extraValidators.required, validatePort, validateRangeSource]}
                            ></Input>
                        </div>
                    </div>
                </div>
                <div className="row larger-field-wrapper">
                    <div className="firewall-filter-group">
                        <div className="firewall-filter-sub-group">
                            <Input id='firewall-port-forward-dest-ip-address'
                                name='remote_ip'
                                type="text"
                                label={<Translator path="firewall.port_forward.label.REMOTE_IP"></Translator>}
                                value={newRegister.remote_ip}
                                onChange={(e) => {
                                    newRegister.remote_ip = e.target.value
                                    setNewRegister({ ...newRegister })
                                }}
                                validators={[
                                    extraValidators.nonASCII,
                                    extraValidators.required,
                                    extraValidators.validateIPv4,
                                    extraValidators.validateIfNotLocalhost
                                ]}
                            ></Input>
                        </div>

                        <div className="firewall-filter-sub-group">
                            <Input id='firewall-port-forward-dest-port-from'
                                name='remote_port_from'
                                type="number"
                                label={<Translator path="firewall.port_forward.label.REMOTE_PORT_FROM"></Translator>}
                                value={newRegister.remote_port_from}
                                onChange={(e) => {
                                    newRegister.remote_port_from = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    setNewRegister({ ...newRegister })
                                }}
                                validators={[extraValidators.nonASCII, extraValidators.isNumber, extraValidators.required, validatePort]}
                            ></Input>

                            <Input id='firewall-port-forward-dest-port-to'
                                name='remote_port_to'
                                type="number"
                                label={<Translator path="firewall.port_forward.label.REMOTE_PORT_TO"></Translator>}
                                value={newRegister.remote_port_to}
                                onChange={(e) => {
                                    newRegister.remote_port_to = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    setNewRegister({ ...newRegister })
                                }}
                                validators={[extraValidators.nonASCII, extraValidators.isNumber, extraValidators.required, validatePort, validateRangeDest]}
                            ></Input>
                        </div>
                    </div>
                </div>
                <div className="row larger-field-wrapper">
                    <div className="firewall-filter-group">
                        <div className="firewall-filter-sub-group">
                            <Select
                                id='firewall-port-forward-interface-index'
                                name='interface'
                                label={<Translator path="common.label.INTERFACE"></Translator>}
                                options={getInterfaceOptions()}
                                value={newRegister.interface}
                                onChange={(e) => {
                                    newRegister.interface = e.target.value
                                    setNewRegister({ ...newRegister })
                                }}

                            ></Select>
                        </div>
                    </div>
                    <div className="firewall-filter-sub-group">
                        <Select
                            id='firewall-port-forward-protocol'
                            name='protocol'
                            label={<Translator path="firewall.port_forward.label.PROTOCOL"></Translator>}
                            options={
                                [
                                    { value: 1, text: t('common.label.TCP') },
                                    { value: 2, text: t('common.label.UDP') },
                                    { value: 4, text: t('common.label.BOTH') }
                                ]
                            }
                            value={newRegister.protocol}
                            onChange={(e) => {
                                newRegister.protocol = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                setNewRegister({ ...newRegister })
                            }}
                        ></Select>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }

    const submitFirewallPortForwardEnable = async () => {
        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        let ok = await saveFirewallPortForwardEnable(backend, firewallPortForwardEnabled)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    const changePage = page => {
        setFirewallPortForwardTablePagination({ ...firewallPortForwardTablePagination, pageNumber: page })
    }

    return (
        !firewallPortForwardEnabled ? <Loading show={true}></Loading> :

            <div id='firewall-port-forward-page' className='with-tabs'>
                <div className='section-divider'></div>
                <div className='subtitle'>{<Translator path="firewall.port_forward.title.PORT_MAPPING"></Translator>}</div>

                <div className='card mt2'>
                    <div className='subtitle'>{<Translator path="firewall.port_forward.label.ENABLE_PORT_MAPPING"></Translator>}</div>

                    <DefaultStatusModals
                        saving={saving}
                        error={error}
                        errorText={errorMessage}
                        success={success}
                        continueFn={dismissModal}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    ></DefaultStatusModals>

                    {!isFormSegment ? <Form
                        onSubmit={submitFirewallPortForwardEnable}
                        buttonId='button-save'
                    >
                        {firewallPortForwardForm()}
                    </Form> :

                        <FormSegment
                            title={formSegment.title}
                            active={formSegment.active}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={submitFirewallPortForwardEnable}
                        >
                            {firewallPortForwardForm()}
                        </FormSegment>}
                </div>

                <div className='card mt2'>
                    {!isFormSegment ? <Form
                        onSubmit={submitNewRegister}
                        buttonId='button-save'
                    >
                        {newRegisterForm()}
                    </Form> :

                        <FormSegment
                            title={formSegment.title}
                            active={formSegment.active}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={submitNewRegister}
                        >
                            {newRegisterForm()}
                        </FormSegment>}
                </div>

                <div id='firewall-port-forward-table'>
                    <div className='subtitle'> {<Translator path="firewall.port_forward.title.PORT_MAPPING"></Translator>} </div>
                    <div>
                        {firewallPortForwardTable.length > 0 ?
                            <div>
                                <div className="scroll-list">
                                    <List
                                        lines={getIpPort()}
                                        columns={getColumnsIpPort()}
                                    ></List>
                                </div>
                                <Pager
                                    pageNumber={firewallPortForwardTablePagination.pageNumber}
                                    totalElements={firewallPortForwardTable.length}
                                    pageSize={firewallPortForwardTablePagination.pageSize}
                                    pageChangeFn={changePage}>
                                </Pager>
                            </div>
                            :
                            <div id='firewall-port-forward-no-redirects'>
                                <div className='info-card noelements-card'>
                                    <Translator path="firewall.port_forward.info.NO_REDIRECTS" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
    )
}

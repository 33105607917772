import { backendStatus } from '../../backend/backend'

export async function fetchPONStats(backend, setPONStats) {
    let result = await backend.retrieveFresh('pon_stats')

    if(result.status === backendStatus.SUCCESS){

        let ponStatsList = result.content

        let ponStats = ponStatsList.length !== 0 ? ponStatsList[0] : {
            throughput: {
                tx: '',
                rx: ''
            },
            pps: {
                tx: '',
                rx: ''
            },
            isCreate: true
        }

        setPONStats(ponStats)
    }
}

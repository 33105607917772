import React, { useState, useEffect, useContext } from 'react'

import './firewall-dmz.css'

import { fetchFirewallDmz, saveFirewallDmz } from '../../../resources/firewall-dmz';
import { BackendContext } from '../../../../backend/backend';

import Input from '../../../../components/input/input';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';

import Form from '../../../../components/form/form';
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next'
import { fetchInterfaceLan } from '../../../resources/lan';
import iputils from '../../../../components/utils/iputils'
import Translator from '../../../common/components/translator/translator';
import Checkbox from '../../../../components/checkbox/checkbox';
import extraValidators from '../../../common/validators';

export default function FirewallDmz({ isWizard, isFormSegment, formSegment }) {
    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [firewallDmz, setFirewallDmz] = useState(null)
    const [interfaceLan, setInterfaceLan] = useState(null)
    let [lastValidIP, setLastValidIP] = useState(null)
    let [canSaveDisabled, setCanSaveDisabled] = useState(true)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchFirewallDmz(backend, 0, setFirewallDmz)
        fetchInterfaceLan(backend, setInterfaceLan)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (firewallDmz) {
            setLastValidIP(firewallDmz.dmz.ip4)
        }

        // eslint-disable-next-line
    }, [interfaceLan])

    let save = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await saveFirewallDmz(backend, firewallDmz, setErrorMessage)

        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setLastValidIP(firewallDmz.dmz.ip4)
        setSaving(false)
        setSuccess(true)

    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const nextClicked = () => {
        if (isWizard) {
            formSegment.nextClicked()
        }
    }

    const hasIPinRange = () => {
        return !iputils.sameNetwork(firewallDmz.dmz.ip4, interfaceLan.ip4, interfaceLan.netmask) ? t('firewall.dmz.message.error.HAS_IP_OUT_OF_RANGE') : ''
    }

    const isHostIP = () => {
        return (firewallDmz.dmz.ip4 === interfaceLan.ip4) ? t('firewall.dmz.message.error.HAS_HOST_IP') : ''
    }

    const systemDmzForm = () => {
        return <React.Fragment>
            <Checkbox id='firewall-dmz-enable'
                name='enabled'
                label={<Translator path="common.label.ENABLE"></Translator>}
                value={firewallDmz.dmz.enabled}
                toggleFn={(e) => {
                    firewallDmz.dmz.enabled = !firewallDmz.dmz.enabled;
                    if (!firewallDmz.dmz.enabled) {
                        firewallDmz.dmz.ip4 = lastValidIP
                        if (firewallDmz.dmz.ip4 === '0.0.0.0') {
                            setCanSaveDisabled(false);
                        }
                    }
                    else {
                        setCanSaveDisabled(true);
                    }
                    setFirewallDmz({ ...firewallDmz, dmz: { ...firewallDmz.dmz } })
                }}
                validators={[]}
            ></Checkbox>
            <Input name='firewall-dmz-host-ip'
                id='ip4'
                label={<Translator path="common.label.IP_ADDRESS"></Translator>}
                disabled={!firewallDmz.dmz.enabled}
                value={firewallDmz.dmz.ip4}
                dependentValues={[firewallDmz.dmz.enabled]}
                onChange={
                    e => {
                        firewallDmz.dmz.ip4 = e.target.value
                        setFirewallDmz({ ...firewallDmz, dmz: { ...firewallDmz.dmz } })
                    }
                }
                validators={[
                    {
                        fn: extraValidators.optionalValidators, params: {
                            shouldValidate: () => firewallDmz.dmz.enabled,
                            validators: [
                                { fn: extraValidators.required, params: '' },
                                { fn: extraValidators.validateIPv4, params: '' },
                                { fn: extraValidators.validateIfNotLocalhost, params: '' },
                                { fn: hasIPinRange, params: '' },
                                { fn: isHostIP, params: '' },
                            ]
                        }
                    },
                ]}
            ></Input>
        </React.Fragment>
    }

    return !firewallDmz ? <Loading show={true}></Loading> :
        <div id='firewall-dmz-page' className='container scroll-area'>
            <div className='subtitle'><Translator path="firewall.dmz.title.FIREWALL_DMZ"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                success={success}
                continueFn={dismiss}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <div className='card mt2'>
                {!isFormSegment ?

                    <React.Fragment>
                        <div className='subtitle'><Translator path="firewall.dmz.title.FIREWALL_DMZ_ENABLE"></Translator></div>

                        <Form id='firewall-dmz-form'
                            onSubmit={save}
                            buttonId='button-save-firewall-dmz'
                            disableButton={!canSaveDisabled}
                        >
                            {systemDmzForm()}
                        </Form>
                    </React.Fragment> :

                    <FormSegment
                        title={formSegment.title}
                        active={formSegment.active}
                        previousLabel={formSegment.previousLabel}
                        previousClicked={formSegment.previousClicked}
                        disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                        nextLabel={formSegment.nextLabel}
                        nextClicked={nextClicked}
                    >
                        {systemDmzForm()}
                    </FormSegment>}
            </div>
        </div>
}
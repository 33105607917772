import httpStatus from "http-status"

const DEFAULT_WAIT_TIME = 20000

async function relogin(backend, username, password) {

    if(!username || !password)
        return false

    let baseUrl = process.env.REACT_APP_REST_ENDPOINT
    let result = await fetch(`${baseUrl}/session`, {
        method: 'POST',
        body: JSON.stringify({
            username: username,
            password: password
        })
    })

    if(result.ok) {
        let auth = await result.json()
        backend.setAuthInfo(auth)
        sessionStorage.setItem('token', auth.token)
        return true
    }

    return false
}

export default async function waitRouter(backend, username, password, history, time) {

    backend.setAuthHandlerEnabled(false)

    await new Promise(resolve => setTimeout(() => resolve(), time ? time : DEFAULT_WAIT_TIME))

    while(true){
        try {
            
            let baseUrl = process.env.REACT_APP_REST_ENDPOINT
            
            let result = await fetch(`${baseUrl}/status`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
            
            if(result.ok){
                backend.setAuthHandlerEnabled(true)
                return true
            }

            if(result.status === httpStatus.UNAUTHORIZED || result.status === httpStatus.NOT_FOUND){
                let relogged = await relogin(backend, username, password)

                if(relogged) continue

                history.push('/login?expired')

                backend.setAuthHandlerEnabled(true)
                return false
            }

            console.error('Waiting router: trying again in 5s')
            await new Promise(resolve => setTimeout(() => resolve(), 5000))

        }catch(error) {

            console.error('Waiting router: trying again in 5s')
            await new Promise(resolve => setTimeout(() => resolve(), 5000))

        }
    }
}

import { backendStatus } from  '../../backend/backend'

export const bandwidth = {
    _20MHz: 0,
    _40MHz: 1,
    _80MHz: 2,
    _160MHz: 3
}

export const extension = {
    UPPER: 0,
    LOWER: 1
}

export function channelExtension(base) {
    let extension = []

    if(base >= 5){
        extension.push({value: 1, text: base - 4})
    }
    if(base <= 9){
        extension.push({value: 0, text: base + 4})
    }

    return extension
}

export const frequency =  {
    _2_4GHz: 0,
    _5GHz: 1,
    dualband: 2
}

export const frequencyString = [
    '2,4 GHz',
    '5 GHz',
    'Dual band (2,4 GHz / 5 GHz)'
]

export const radioOpMode = {
    A: 0,
    B: 1,
    G: 2,
    N: 3,
    BGN: 4,
    AC: 5,
    ANAC: 6,
    AX: 7,
    ANACAX: 8,
    BG: 9,
    AN: 10,
    NAC: 11,
    GN: 12,
}

export async function fetchRadioList(backend, setRadios){
    try {
        let result = await backend.retrieveFresh('radio')

        if(result.status === backendStatus.SUCCESS){
            setRadios(result.content)
        }
    }catch(error){
        console.error('Error retrieving radio:', error)
        return false
    }
}

export async function fetchRadio(backend, id, setRadio){

    let result = await backend.retrieveFresh(`radio/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setRadio(result.content)
    }
}

export async function saveRadio(backend, radio) {

    try{

        let result = await backend.update(`radio/${radio.id}`, radio)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving radio:', error)

        return false
    }

}


import { backendStatus } from '../../backend/backend'

const DEFAULT_LAN_WAIT_TIME = 60000
export const LAN_COMMS_LOSS_FLAG = 'LAN_COMMS_LOSS_FLAG'

export async function fetchInterfaceLan(backend, setInterfaceLan) {
    let result = await backend.retrieve('lan')
    if(result.status === backendStatus.SUCCESS){
        let lan = result.content[0]
        result = await backend.retrieveFresh(`interface/${lan.interfaceID}`)
        if(result.status === backendStatus.SUCCESS){
            if (setInterfaceLan)
                setInterfaceLan(result.content)
            else
                return result.content
        }
    }
}

export async function fetchLan(backend, setLan) {
    let result = await backend.retrieve('lan')
    if(result.status === backendStatus.SUCCESS){
        let lan = result.content[0]
        if (setLan)
            setLan(lan)
        else
            return lan
    }
}

export async function updateInterfaceLan(backend, interfaceLan, setErrorMessage) {
    try {
        let result = await backend.update(`interface/${interfaceLan.id}`, interfaceLan, null, false)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body?.message)
        }

        return result.status === backendStatus.SUCCESS
    }catch(error){
        console.error('Error updating interfaceLan:', error)
        return false
    }
}

const withTimeout = (time, promise) => {
    const timeout = new Promise((resolve, reject) =>
        setTimeout(
            () => reject(LAN_COMMS_LOSS_FLAG),
            time));
    return Promise.race([
        promise,
        timeout
    ]);
};

export async function updateLan(backend, lan, time) {
    try {
        let result = await withTimeout(time ? time : DEFAULT_LAN_WAIT_TIME, backend.update(`lan/${lan.id}`, lan, null, false))        
        return result.status === backendStatus.SUCCESS        
    }catch(error){
        if(error === LAN_COMMS_LOSS_FLAG)
            return LAN_COMMS_LOSS_FLAG
        else {
            console.error('Error updating lan:', error)
            return false
        }        
    }
}
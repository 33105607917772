import { backendStatus } from '../../backend/backend'

const URL = 'ipv6_prefix_delegation';

export const PrefixDelegationMode = {
    PD_STATIC: 0,
    PD_WAN_DELEGATED: 1
}

export async function fetchIpv6PrefixDelegation(backend, setPrefixDelegation, id = 0) {

    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setPrefixDelegation(result.content)
    }
}

export async function updateIpv6PrefixDelegation(backend, prefixDelegation, setErrorMessage, id = 0) {

    try{

        let result = null;

        result = await backend.update(`${URL}/${id}`, prefixDelegation)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || prefixDelegation)

    }catch(error){

        console.error('Error saving PREFIX_DELEGATION:', error)

        return false
    }
}
import { backendStatus } from '../../backend/backend'

export async function fetchGemPortStatistics(backend, setGemPortStatistics, handleError) {
    let result = await backend.retrieveFresh('gem_port_stats')

    if(result.status === backendStatus.SUCCESS){

        let gemPortStatistics = result.content
        gemPortStatistics.sort((e1, e2) => e1.flow_id - e2.flow_id)
        setGemPortStatistics(gemPortStatistics)
    } else {
        handleError();
    }
}

export const terms_of_use_es = `
<html>
<head>
	<meta http-equiv="Content-Type" content="text/html" charset="utf-8">
	<link href="/admin/LoginFiles/favicon_intelbras.ico" type="image/x-icon" rel="shortcut icon" />
	<script type="text/javascript" src="rollups/md5.js"></script>
	<script type="text/javascript" src="php-crypt-md5.js"></script>
	<script language="javascript" src="/common.js"></script>
	<title>Membrete Intelbras</title>
	<script>
		function openAsp(url)
		{	
			var features = "height=500, width=800, top=100, left=300, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
			window.open(url, "new2", features);
		}
	</script>
</head>

<body lang=PT-BR link="#0563C1" vlink="#954F72">
    <p align=center style='margin-bottom:0cm;margin-bottom:.0001pt;text-align:center'>
        <b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">Términos de uso de INTELBRAS</span></b>
    </p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt'>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Versión actualizada el 06.01.2020</span>
		</p>

		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Para utilizar el dispositivo $ONU.MODEL, es imprescindible aceptar los términos que se describen a continuación.</span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Estos Términos de uso del producto (ACUERDO) es un acuerdo legal entre el USUARIO (persona física o jurídica), denominado USUARIO, y</span>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">INTEBRAS S/A – Industria Brasileña de Telecomunicaciones Electrónicas,</span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">persona jurídica de derecho privado, registrada en el CNPJ con el número 82.901.000/0001-27, con domicilio en la carretera BR 101, km 210, Área Industrial, São José – SC, en lo sucesivo denominada INTELBRAS, para el uso de dispositivo $ONU.MODEL, y puede incluir los medios físicos asociados, así como cualquier material impreso y cualquier documentación en línea o electrónica. Al utilizar el PRODUCTO, aunque sea parcialmente o como prueba, el USUARIO estará sujeto a los términos de este acuerdo, aceptando sus disposiciones, principalmente en lo que se refiere al consentimiento para el acceso, recolección, uso, almacenamiento, tratamiento y técnicas de protección de la información. del USUARIO por parte de INTEBRAS, necesarios para la plena ejecución de las funcionalidades que ofrece el PRODUCTO. En caso de disconformidad con los términos aquí presentados, el uso del PRODUCTO deberá ser inmediatamente interrumpido por el USUARIO, mediante las cláusulas que se establecen a continuación. El USUARIO declara que tiene plena capacidad civil y legal para aceptar las condiciones de uso del PRODUCTO. A los efectos de la aplicación de estos Términos de uso, un PRODUCTO significa la solución INTEBRAS proporcionada como un servicio en la nube, una aplicación, una licencia de software (integrada en el hardware o disponible para descargar) o incluso un firmware de hardware INTEBRAS.</span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;"><br>1. Aceptación del contrato</span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Al acceder a la solución de INTELBRAS, en lo sucesivo denominada simplemente como el PRODUCTO, el USUARIO expresa su acuerdo de obligarse y someterse a todos los términos de este contrato. Si este PRODUCTO viene con una contraseña predeterminada de fábrica, corresponderá al USUARIO configurar INMEDIATAMENTE una nueva contraseña para la seguridad del PRODUCTO. INTEBRAS no se hace responsable de los daños que pueda sufrir el USUARIO si no le son aplicables las configuraciones mínimas de seguridad.</span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">2. Do Cadastro<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Caso seja solicitado um cadastro para acesso ao PRODUTO é necessário que o USUÁRIO forneça voluntariamente informações sobre si, tais como: nome de usuário, senha, endereço de e-mail (“Dados”). O USUÁRIO declara que os Dados fornecidos são fiéis e verdadeiros e compromete-se a manter seus dados sempre atualizados. Ao realizar o cadastro, o USUÁRIO declara que possui plena capacidade civil, nos termos da lei, para acessar o PRODUTO.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">A INTELBRAS não é responsável pelas informações prestadas, mas se reserva o direito de verificar, a qualquer momento, a veracidade de tais informações e solicitar, a seu exclusivo critério, a documentação suporte que julgar necessária para a devida comprovação das informações prestadas. Caso a INTELBRAS detecte alguma conta feita a partir de informações falsas, por menor de idade, ou pessoa que não possua plena capacidade civil, o cadastro do USUÁRIO será automaticamente cancelado de forma que o USUÁRIO não terá mais acesso ao uso do PRODUTO, não assistindo ao USUÁRIO, por este motivo, qualquer direito de indenização ou ressarcimento.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O titular e administrador da conta será aquele definido no momento do cadastro, a partir dos Dados oferecidos pelo USUÁRIO. A conta é pessoal e intransferível, e poderá ser acessada unicamente mediante a utilização do USUÁRIO e senha criados pelo próprio USUÁRIO no momento do cadastro, sendo este o único e exclusivo responsável por manter o sigilo, proteção e segurança de seu USUÁRIO e senha, a fim de garantir a segurança de sua conta e impedir o acesso não autorizado por terceiros. O USUÁRIO é o único responsável por todas as atividades associadas a sua conta.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO deverá seguir os padrões de segurança de registro de senha e realizar a troca IMEDIATA da senha padrão de fábrica, caso aplicável.</span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">3. Licença limitada<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Você recebeu o direito de acesso a um RODUTO da INTELBRAS, decorrente de um direito não transferível, não exclusivo, livre de royalties ​e revogável, para baixar, instalar, acessar, executar ou utilizar essa solução em seus dispositivos. Você reconhece e concorda que a INTELBRAS concede ao USUÁRIO uma licença exclusiva para uso e, dessa forma, não lhe transfere os direitos sobre o PRODUTO. A venda, transferência, modificação, engenharia reversa ou distribuição, bem como a cópia de textos, imagens ou quaisquer itens contidos no PRODUTO são expressamente proibidas. Você reconhece que a INTELBRAS é proprietária de todos os direitos, títulos e interesses referentes ao site institucional INTELBRAS e ao software relacionado. El dispositivo $ONU.MODEL é a marca comercial ou registrada da INTELBRAS. Você não pode utilizar, usufruir, comercializar, alterar, destruir, ocultar ou remover de qualquer forma as informações sobre direito autoral, os rótulos ou avisos de propriedade dos produtos e soluções da INTELBRAS.</span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">4. Direitos autorais<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO não adquire, pelo presente instrumento ou pela utilização do PRODUTO, nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou quaisquer direitos sobre informações confidenciais ou segredos de negócio, bem como sobre  o conteúdo disponibilizado no PRODUTO, incluindo, mas não se limitando a textos, gráficos, imagens, logotipos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material, sobre a INTELBRAS ou relacionados a ele ou a qualquer parte dele. O USUÁRIO também não adquire nenhum direito sobre o PRODUTO ou relacionado a ele ou a qualquer componente dele, além dos direitos expressamente relacionados ao USUÁRIO neste Termo ou em qualquer outro contrato mutuamente acordado por escrito entre as partes.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Ao utilizar o PRODUTO, o USUÁRIO concorda em cumprir com as seguintes diretrizes:<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I. &nbsp;&nbsp; ão é permitido postar ou transmitir informação, dado, texto, software, gráficos, sons, fotografias, vídeos, mensagens ou outro conteúdo que seja ilegal, ofensivo, impreciso, difamatório, obsceno, fraudulento, prejudicial, ameaçador ou abusivo.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; II.&nbsp;&nbsp; Não interferir no uso de outros usuários do PRODUTO.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; III.&nbsp;&nbsp;Não postar ou fazer upload de qualquer vírus, worms, arquivo corrompido ou outro software capaz de perturbar, incapacitar ou prejudicar o funcionamento do PRODUTO.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; IV.&nbsp;&nbsp; Cumprir com este Termo e quaisquer leis ou regulamentos aplicáveis.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; V.&nbsp;&nbsp;  Não se passar por qualquer pessoa ou entidade, declarar falsamente ou deturpar sua afiliação com uma pessoa ou entidade.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; VI.&nbsp;&nbsp; Não enviar ou transmitir conteúdo que o USUÁRIO não tem o direito de publicar ou transmitir sob qualquer lei ou sob relações contratuais ou fiduciárias (tais como informação privilegiada, informações confidenciais, etc).<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; VII.&nbsp;&nbsp;Não usar o PRODUTO para solicitar, obter ou armazenar dados pessoais ou senhas de outros usuários.<br></span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">5. Alterações, modificações e rescisão<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">A INTELBRAS reserva-se o direito de, a qualquer tempo, modificar estes termos, seja incluindo, removendo ou alterando quaisquer de suas cláusulas. Tais modificações terão efeito imediato após a publicação. Ao continuar com o uso do PRODUTO você terá aceitado e concordado em cumprir os termos modificados. Assim como, a INTELBRAS pode, de tempos em tempos, modificar ou descontinuar (temporária ou permanentemente) a distribuição ou a atualização desse PRODUTO e não é obrigada a fornecer nenhum tipo de suporte para essa solução, após o cumprimento do prazo legal. O USUÁRIO não poderá responsabilizar a INTELBRAS nem seus diretores, executivos, funcionários, afiliados, agentes, contratados por quaisquer modificações, suspensões ou descontinuidade do PRODUTO.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Para fins contratuais, o USUÁRIO concorda em receber comunicações da INTELBRAS de forma eletrônica (termos e condições, acordos, notificações, divulgações e outras comunicações da INTELBRAS), seja por e-mail ou comunicação interna no próprio PRODUTO e que, desta forma estabelecida, as comunicações da INTELBRAS satisfazem e cumprem com os requisitos legais.</span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">6. Indenização<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Esse PRODUTO estará em contínuo desenvolvimento e pode conter erros, por isso, o uso é fornecido "no estado em que se encontra" e sob risco do usuário final. Na extensão máxima permitida pela legislação aplicável, a INTELBRAS e seus fornecedores isentam-se de quaisquer garantias e condições expressas ou implícitas incluindo, sem limitação, garantias de comercialização, adequação a um propósito específico, titularidade e não violação no que diz respeito ao software e a qualquer um de seus componentes ou ainda à prestação ou não de serviços de suporte. A INTELBRAS não garante que a operação desse serviço seja contínua e sem defeitos.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Em nenhum caso, a INTELBRAS será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao uso do PRODUTO ou a sua inabilidade em usar o PRODUTO ou qualquer motivo.</span>
		</p>
		
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">7. Consentimento para coleta e proteção do uso de dados<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO concorda que a INTELBRAS pode coletar os dados pessoais de cadastro e perfil, e usar dados técnicos de seu dispositivo, tais como especificações, configurações, versões de sistema operacional, tipo de conexão à internet e afins  para fornecer determinadas funções, como atualizações on-line, P2P, DDNS, redefinição de senha entre outras.<br><br></span>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Os dados pessoais coletados do USUÁRIO serão exclusivamente utilizados para fins de execução do presente contrato, com o objetivo principal de ativação das funcionalidades do PRODUTO, sendo que o uso destes dados é intrínseco ao próprio funcionamento da solução INTELBRAS, e para uso e benefícios do titular. Ainda, alguns recursos do PRODUTO poderão solicitar dados adicionais do USUÁRIO, tais como, nome, telefone, e-mail, e dados técnicos.<br><br><br></span>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">No desenvolvimento de quaisquer atividades relacionadas com a execução do presente Contrato, as Partes observam o regime legal de proteção de dados pessoais, empenhando-se em proceder a todo o tratamento de dados pessoais que venha a mostrar-se necessário ao desenvolvimento do Contrato no estrito e rigoroso cumprimento da Lei, nos termos da</span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;"><a href="#" class="ca" onclick="openAsp('/admin/intelbras_privacy_policy.asp')">Política de Privacidade Intelbras.</a><br><br><br></span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Os dados pessoais aqui elencados consideram-se os dados das próprias Partes ou mesmo os dados pessoais de seus colaboradores, contratados ou subcontratados.<br><br><br></span>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">A fim de garantir a proteção dos dados, o USUÁRIO obriga-se a:<br></span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:17px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; a. </span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Tratar e usar os dados pessoais da INTELBRAS ou de seus PARCEIROS nos termos legalmente permitidos, em especial recolhendo, registrando, organizando, conservando, consultando ou transmitindo os mesmos, apenas e somente nos casos em que seu titular tenha dado o consentimento expresso e inequívoco, ou nos casos legalmente previstos;<br></span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:17px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; b. </span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Tratar os dados de modo compatível com as finalidades para os quais tenha sido recolhidos;<br></span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:17px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; c. </span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Conservar os dados apenas durante o período necessário à prossecução das finalidades da recolha ou do tratamento posterior, garantindo a sua confidencialidade;<br></span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:17px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; d. </span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Implementar as medidas técnicas e organizativas necessárias para proteger os dados contra a destruição, acidental ou ilícita, a perda acidental, a alteração, a difusão ou o acesso não autorizada, bem como contra qualquer outra forma de tratamento ilícito dos mesmos;<br></span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:17px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; e. </span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Informar imediatamente a INTELBRAS, devendo prestar toda a colaboração necessária a qualquer investigação que venha a ser realizada, caso exista alguma quebra de segurança, ou suspeita da mesma, independentemente de colocar ou não em causa a segurança e integridade dos dados pessoais;<br></span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:17px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; f. </span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Garantir o exercício, pelos titulares, dos respectivos direitos de informação, acesso e oposição;<br></span>
		<b><span style="font-family:Calibri Light;sans-serif;font-size:17px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; g. </span></b>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Assegurar que os seus colaboradores, contratados ou subcontratados que venham a ter acesso a dados pessoais no contexto deste Contrato cumpram as disposições legais aplicáveis em matéria de proteção de dados pessoais, e as disposições contratuais aqui elencadas, não cedendo, vendendo, compartilhando ou divulgados tais dados a terceiros, nem deles fazendo uso para quaisquer fins que não os estritamente consentidos pelos respectivos titulares.<br><br></span>
		<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO será responsabilizado perante a INTELBRAS ou terceiros em caso de qualquer violação, compartilhamento, exclusão, cessão, venda, alteração automática dos dados sem prévio e expresso consentimento do seu titular.</span>

		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">8.  Marcas Registradas e Direitos de Propriedade Intelectual da INTELBRAS<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO reconhece que as "Marcas Registradas" e os "Direitos de Propriedade Intelectual" da INTELBRAS representam um dos ativos estratégicos da INTELBRAS sendo de exclusiva propriedade da mesma.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO não adquire o direito de uso das Marcas Registradas e outros Direitos de Propriedade Intelectual da INTELBRAS com o Aceite destes Termos. O Uso das Marcas Registradas dependem de autorização prévia e expressa da Intelbras, de acordo com: (i) "Política e Diretrizes da Marca INTELBRAS";  (ii) Programa de Canais INTELBRAS; (iii) a legislação aplicável; (iv) e/ou qualquer outra definição da INTELBRAS. <br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO não poderá registrar quaisquer Direitos de Propriedade Intelectual da INTELBRAS, tais como qualquer palavra, símbolo, marca identificativa ou nome semelhante às Marcas Registradas da INTELBRAS ou nome de domínio durante a vigência deste contrato ou mesmo após o seu encerramento, nem mesmo, utilizar as Marcas Registradas Intelbras ou qualquer outro signo que a identifique, em qualquer ambiente online, sem a prévia e expressa autorização, exceto nos casos que a divulgação seja indispensável para a plena funcionalidade do PRODUTO.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO deverá prontamente notificar a INTELBRAS, por escrito, de qualquer suspeita de uso não autorizado ou infração aos Direitos de Propriedade Intelectual da INTELBRAS e que venha a ter conhecimento. Se solicitado pela INTELBRAS, o USUÁRIO deverá auxiliar a INTELBRAS em quaisquer investigações, negociações ou procedimentos judiciais em virtude de qualquer alegação de uso indevido ou de violação aos Direitos de Propriedade Intelectual da INTELBRAS.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">O USUÁRIO compromete-se a não fazer qualquer tipo de anúncio, propaganda, material publicitário dos Produtos INTELBRAS, contemplando preços e condições de pagamento vinculando produtos INTELBRAS com produtos de concorrentes.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">É vedada a cópia ou qualquer outra forma de reprodução das informações, manuais, literatura técnica e outros documentos fornecidos pela INTELBRAS, exceto para o cumprimento de obrigações estabelecidas nos termos deste instrumento, e de acordo com a legislação aplicável relativamente a direitos autorais e propriedade intelectual.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">As obrigações estabelecidas na presente cláusula obrigam o USUÁRIO durante a vigência do presente instrumento, bem como após seu encerramento ou rescisão.</span>
		</p>
	
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">9. Isenção de garantias e limitações de responsabilidade<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Esse PRODUTO estará em contínuo desenvolvimento e pode conter erros, por isso, o uso é fornecido "no estado em que se encontra" e sob risco do usuário final. Na extensão máxima permitida pela legislação aplicável, a INTELBRAS e seus fornecedores isentam-se de quaisquer garantias e condições expressas ou implícitas incluindo, sem limitação, garantias de comercialização, adequação a um propósito específico, titularidade e não violação no que diz respeito ao serviço e a qualquer um de seus componentes ou ainda à prestação ou não de serviços de suporte. A INTELBRAS não garante que a operação desse serviço seja contínua e sem defeitos. Com exceção do estabelecido neste documento, não há outras garantias, condições ou promessas vinculadas ao serviço, expressas ou implícitas, e todas essas garantias, condições e promessas podem ser excluídas de acordo com o que é permitido por lei sem prejuízo à INTELBRAS e a seus colaboradores.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; I.  &nbsp;&nbsp;A INTELBRAS não garante, declara ou assegura que o uso desse PRODUTO será ininterrupto ou livre de erros e você concorda que a INTELBRAS poderá remover por períodos indefinidos ou cancelar esse PRODUTO a qualquer momento sem que você seja avisado.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; II. &nbsp;&nbsp;A INTELBRAS não garante, declara nem assegura que esse PRODUTO esteja livre de perda, interrupção, ataque, vírus, interferência, pirataria ou outra ameaça à segurança e isenta-se de qualquer responsabilidade em relação a essas questões. Você é responsável pelo backup dos arquivos armazenados em seu dispositivo, pela segurança da rede, pela proteção de senhas e demais configurações de segurança da informação.<br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; III.&nbsp;&nbsp;Em hipótese alguma a INTELBRAS, bem como seus diretores, executivos, funcionários, afiliadas, agentes, contratados responsabilizar-se-ão por perdas ou danos causados pelo uso indevido do PRODUTO e descumprido estes Termos.<br></span>
		</p>
	
		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">10. Validade técnica<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Fica estipulado que a INTELBRAS, seus fornecedores ou distribuidores não oferecem um período de validade técnica deste PRODUTO. Não se pode considerar que a solução esteja isenta de erros, que seu funcionamento seja ininterrupto ou que suas funções satisfaçam os requisitos dos usuários, razão pela qual fica expressamente estipulado que o USUÁRIO o utiliza por sua conta e risco. Devido à complexidade da relação entre software e hardware, a INTELBRAS não garante que o PRODUTO é compatível com todos os demais sistemas de software e hardware, que irá operar corretamente ou atender às suas expectativas, nem mesmo que terá atualizações.</span>
		</p>

		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">11. Rescisão<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Este contrato poderá ser rescindido por interesse das partes a qualquer instante ou por descumprimento de qualquer cláusula.</span>
		</p>

		<p class=MsoNormal style='margin-bottom:0cm;margin-bottom:.0001pt;line-height:normal;text-autospace:none'>
			<b><span style="font-family:Calibri Light;sans-serif;font-size:16px;">12. Foro para dirimir controvérsias<br></span></b>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">Estes Termos de uso serão regidos e interpretados de acordo com as leis do Brasil. As partes se submetem à jurisdição exclusiva dos tribunais do Brasil. Para dirimir eventuais dúvidas acerca do presente instrumento, bem como de qualquer evento relacionado à utilização de nossos serviços, fica desde logo eleito o foro da comarca de São José, estado de Santa Catarina, por mais privilegiado que outro foro seja. Se você ainda possui alguma dúvida sobre a forma de utilização de nosso produto, sobre nossos Termos de uso ou sobre nossa Política de privacidade, entre em contato com a INTELBRAS. Ficaremos felizes com o seu contato.<br><br></span>
			<span style="font-family:Calibri Light;sans-serif;font-size:15px;">A INTELBRAS está inscrita no CNPJ/MF sob o nº 82.901.000/0001-27, tendo sede na Cidade de São José, Estado de Santa Catarina, no endereço Rodovia BR 101, KM 210, Área Industrial, CEP 88104-800.</span>
		</p>		
</body>

</html>
`

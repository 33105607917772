import React from 'react'
import CircledArrow from '../../../components/icons/circled-arrow';

import './power_indicator.css'

export default function PowerIndicator({power, label, isTx, isHorizontal, id}){

    return <div className={`power-indicator ${isHorizontal ? 'horizontal' : ''}`}>

        <div id={isTx ? 'tx-power' : 'rxPower'} className={`power-indicator-icon ${isTx ? 'upload' : ''}`}>
            <CircledArrow size='24px'></CircledArrow>
            <div className='power-indicator-label'>{label}</div>
        </div>
        <div className='power-indicator-text'>
            <div id={id || null} className='power-indicator-value'>
                {power}
                <span className='power-indicator-unit'>dBm</span>
            </div>
        </div>
    </div>
}

import React from 'react'

export default function ConnectedHouseTwibi({size}){

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 200 200">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <path fill="#00A335" d="M175.496 42.234c-.097-25.59-24.473-43.7-48.084-50.418-21.289-6.057-39.912-5.367-58.717 5.435C62.515.8 57.587 6.428 55.186 13.253c-2.365 6.72-1.736 14.805-1.379 21.903.617 12.283 1.938 21.484-5.932 31.16-7.83 9.627-19.388 14.323-21.718 27.682-3.609 20.683-2.026 31.05 11.882 52.193 3.808 5.79 26.716 37.037 66.804 39.71 10.535.703 22.387-2.285 29.151-10.972 7.406-9.511 9.231-22.335 5.89-33.603-4.906-16.55.936-27.076 12.022-39.792 7.454-8.55 15.173-14.856 19.193-25.708 3.873-10.462 4.44-22.506 4.397-33.592" opacity=".06" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(100.000000, 87.000000) rotate(-90.000000) translate(-100.000000, -87.000000)"/>
                        <g>
                            <path fill="#627C85" d="M0 167.006L83.976 167.006 83.976 116.686 0 116.686z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M2.081 164.925L81.895 164.925 81.895 118.767 2.081 118.767z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#627C85" d="M76.696 22.372L76.696 113.419 87.242 113.419 87.242 167.006 116.639 167.006 116.639 22.372 76.696 22.372" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M89.323 164.925L89.323 111.338 78.777 111.338 78.777 24.453 114.558 24.453 114.558 164.925 89.323 164.925" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#627C85" d="M119.907 113.419L138.74 113.419 138.74 48.385 119.907 48.385z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M121.988 111.338L136.659 111.338 136.659 50.466 121.988 50.466z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#627C85" d="M119.906 167.006L167.765 167.006 167.765 116.686 119.906 116.686z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M121.987 164.925L165.684 164.925 165.684 118.767 121.987 118.767z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#627C85" d="M142.006 113.419L167.765 113.419 142.006 96.758 142.006 113.419" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M73.451 164.002c.259 0 .468.21.468.467 0 .259-.21.468-.468.468s-.468-.21-.468-.468.21-.467.468-.467zm6.266 0c.258 0 .467.21.467.467 0 .259-.209.468-.467.468s-.468-.21-.468-.468.21-.467.468-.467zm2.178-32.488v32.488h-9.812v-17.634h-.77v-1.21h5.29v-12.323h-.947v-1.32h6.239z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#779CAB" d="M89.323 164.925L96.245 164.925 96.245 111.338 89.323 111.338zM94.819 111.338L96.245 111.338 96.245 87.731 94.819 87.731z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#A0BAC5" d="M92.223 164.925L114.558 164.925 114.558 161.448 92.223 161.448zM94.497 161.185L114.558 161.185 114.558 157.707 94.497 157.707zM96.22 157.444L114.558 157.444 114.558 153.967 96.22 153.967zM96.22 153.704L114.558 153.704 114.558 150.227 96.22 150.227zM96.22 149.964L114.558 149.964 114.558 146.487 96.22 146.487zM96.22 146.224L114.558 146.224 114.558 142.747 96.22 142.747zM96.22 142.484L114.558 142.484 114.558 139.007 96.22 139.007zM96.22 138.744L114.558 138.744 114.558 135.267 96.22 135.267zM96.22 135.004L114.558 135.004 114.558 131.527 96.22 131.527zM96.22 131.264L114.558 131.264 114.558 127.787 96.22 127.787zM96.22 127.524L114.558 127.524 114.558 124.047 96.22 124.047zM96.22 123.784L114.558 123.784 114.558 120.307 96.22 120.307zM96.22 120.044L114.558 120.044 114.558 116.567 96.22 116.567z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#49646D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.026" d="M97.398 115.279v32.732s-.166 2.51-3.491 4.423c-3.325 1.911-2.41 1.27-2.41 1.27" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#49646D" strokeWidth="1.026" d="M97.398 121.68H96.22m1.178 11.22H96.22m1.178 11.222H96.22" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#779CAB" d="M124.329 98.839c.615 0 1.115.499 1.115 1.114l-.001 5.27h6.027c.138 0 .25.112.25.25s-.112.25-.25.25c-.02 3.292-3.666 2.832-2.312 3.544 1.357.714.428 2.072.428 2.072h-5.87c0-.714.942-.786.942-.786s2.857-1.143 0-1.143-2.621-3.714-2.621-3.714c-.033-.104-.05-.215-.05-.33v-5.413c0-.615.499-1.114 1.115-1.114h1.227zM136.659 111.338h-1.395v-11.141c-2.648-.637-2.782-3.042-2.782-3.042h4.177v14.183zM126.751 135.004L157.555 135.004 157.555 120.307 126.751 120.307z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M134.28 133.23v-11.15c-.69 0-1.25-.56-1.25-1.25h-4.074c0 .69-.56 1.25-1.25 1.25v11.15c.69 0 1.25.56 1.25 1.25h4.074c0-.69.56-1.25 1.25-1.25z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M133.7 132.142c0-.213-.173-.386-.386-.386-.213 0-.385.173-.385.386 0 .213.172.386.385.386.213 0 .386-.173.386-.386" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M135.043 133.23v-11.15c.69 0 1.25-.56 1.25-1.25h4.075c0 .69.56 1.25 1.25 1.25v11.15c-.69 0-1.25.56-1.25 1.25h-4.074c0-.69-.56-1.25-1.25-1.25z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M135.623 132.142c0-.213.173-.386.386-.386.213 0 .386.173.386.386 0 .213-.173.386-.386.386-.213 0-.386-.173-.386-.386" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M149.466 133.23v-11.15c-.69 0-1.25-.56-1.25-1.25h-4.074c0 .69-.56 1.25-1.25 1.25v11.15c.69 0 1.25.56 1.25 1.25h4.074c0-.69.56-1.25 1.25-1.25z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M148.886 132.142c0-.213-.172-.386-.386-.386-.213 0-.385.173-.385.386 0 .213.172.386.385.386.214 0 .386-.173.386-.386" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M150.23 133.23v-11.15c.69 0 1.25-.56 1.25-1.25h4.074c0 .69.56 1.25 1.25 1.25v11.15c-.69 0-1.25.56-1.25 1.25h-4.074c0-.69-.56-1.25-1.25-1.25z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M150.81 132.142c0-.213.172-.386.385-.386.213 0 .386.173.386.386 0 .213-.173.386-.386.386-.213 0-.386-.173-.386-.386" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#779CAB" d="M156.299 151.688v.277h2.882v13.051h-32.66v-12.773h-.804v-.555h30.582z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M146.467 150.398c.262 0 .474.212.474.474s-.212.474-.474.474h-.246v13.568h-.762l-.825-13.569h-13.3l-.824 13.569h-.763l-.001-13.569h-.246c-.26 0-.473-.211-.473-.473s.212-.474.473-.474h16.967z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M125.472 147.13c.148 0 .271.103.303.24l.008.073v.035c.947 0 1.723.729 1.798 1.655l.006.148v4.224c0 .54-.238 1.026-.615 1.357l.121-.005h5.396c1.017 0 1.851.786 1.928 1.783l.006.151h-1.332v7.8c0 .149-.102.273-.24.305l-.072.008c-.172 0-.312-.14-.312-.312v-7.8h-6.684v7.912c0 .148-.102.272-.24.304l-.071.008c-.148 0-.272-.102-.305-.24l-.008-.072v-17.261c0-.173.14-.313.313-.313zM143.501 164.592c0 .172-.14.312-.312.312-.173 0-.312-.14-.312-.312v-7.8h-1.332c0-1.069.866-1.935 1.934-1.935h5.396l.121.005c-.377-.33-.615-.816-.615-1.357v-4.224c0-.996.807-1.803 1.804-1.803v-.035c0-.173.14-.313.312-.313s.312.14.312.313v17.26c0 .174-.14.313-.312.313-.173 0-.312-.14-.312-.312v-7.913H143.5v7.8zM156.845 150.592v-.628h8.839v14.96h-7.418v-14.332h-1.42zM158.266 135.004L165.684 135.004 165.684 120.307 158.266 120.307zM.347 113.419L73.43 113.419 73.43 48.048.347 48.048z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#D0DCE1" d="M2.428 111.338L71.349 111.338 71.349 50.13 2.428 50.13z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#779CAB" d="M52.247 164.925v-26.296s-.368-7.533 7.635-7.533c8.004 0 7.541 7.533 7.541 7.533v26.296M8.466 164.95L41.363 164.95 41.363 143.473 8.466 143.473z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M14.717 146.532c0-.328-.266-.594-.593-.594-.328 0-.594.266-.594.594 0 .328.266.594.594.594.327 0 .593-.266.593-.594z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M18.755 147.654v-2.244c-.418 0-.757-.339-.757-.757h-7.749c0 .418-.339.757-.757.757v2.244c.418 0 .757.339.757.757h7.749c0-.418.339-.757.757-.757zm0 15.024v-12.36c-.418 0-.757-.338-.757-.756h-7.749c0 .418-.339.757-.757.757v12.359c.418 0 .757.34.757.757h7.749c0-.418.339-.757.757-.757zm6.753-16.146c0-.328-.266-.594-.593-.594-.328 0-.594.266-.594.594 0 .328.266.594.594.594.327 0 .593-.266.593-.594z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M29.546 147.654v-2.244c-.418 0-.757-.339-.757-.757H21.04c0 .418-.339.757-.757.757v2.244c.418 0 .757.339.757.757h7.749c0-.418.339-.757.757-.757zm0 15.024v-12.36c-.418 0-.757-.338-.757-.756H21.04c0 .418-.339.757-.757.757v12.359c.418 0 .757.34.757.757h7.749c0-.418.339-.757.757-.757zm6.753-16.146c0-.328-.265-.594-.593-.594-.328 0-.594.266-.594.594 0 .328.266.594.594.594.328 0 .593-.266.593-.594z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#C4BEB2" d="M39.627 158c0 .113-.092.205-.205.205-.114 0-.205-.092-.205-.205v-2.876c0-.113.091-.206.205-.206.113 0 .205.093.205.206V158" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M40.337 147.654v-2.244c-.418 0-.757-.339-.757-.757h-7.748c0 .418-.34.757-.758.757v2.244c.418 0 .758.339.758.757h7.748c0-.418.339-.757.757-.757zm0 15.024v-12.36c-.418 0-.757-.338-.757-.756h-7.748c0 .418-.34.757-.758.757v12.359c.418 0 .758.34.758.757h7.748c0-.418.339-.757.757-.757z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M4.836 163.41c.203.153.334.397.334.672 0 .465-.378.843-.844.843-.465 0-.843-.378-.843-.843 0-.275.131-.52.335-.673l-1.737.001v-11.716H17.42v1.33l-1.52-.002.001 10.388-2.36-.001c.204.154.335.398.335.673 0 .465-.377.843-.843.843-.466 0-.843-.378-.843-.843 0-.275.131-.52.334-.673zM49.185 143.491l1.126.318c.604.17.955.799.784 1.402l-3.124 11.059c.005.053.008.107.008.162v6.037c0 .85-.69 1.539-1.539 1.539l-.182-.002c.2.053.347.235.347.452 0 .258-.21.467-.468.467h-.993c-.258 0-.467-.21-.467-.467 0-.217.147-.4.346-.452h-7.745c.2.053.347.235.347.452 0 .258-.21.467-.468.467h-.993c-.258 0-.467-.21-.467-.467 0-.217.147-.4.346-.452l-.17.002c-.85 0-1.54-.69-1.54-1.54v-5.854h-.36c-.627 0-1.135-.509-1.135-1.136v-1.17c0-.627.508-1.136 1.135-1.136H45.27l2.514-8.897c.171-.603.799-.955 1.402-.784zM3.544 133.34c.232 0 .421.19.421.423v16.505c0 .234-.189.422-.421.422-.233 0-.422-.188-.422-.422v-2.812h-1.04v-10.881h1.04v-2.812c0-.233.189-.422.422-.422z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#779CAB" d="M6.58 111.338L51.698 111.338 51.698 75.515 6.58 75.515z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path stroke="#D0DCE1" strokeWidth=".5" d="M8.505 109.663h9.613V77.185H8.505v32.478zm10.552 0h9.613V77.185h-9.613v32.478zm10.552 0h9.612V77.185H29.61v32.478zm10.55 0h9.614V77.185H40.16v32.478z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M4.004 87.745V99.24l2.168.001c-1.31-.299-2.168-.797-2.168-1.362 0-.914 2.253-1.656 5.03-1.656 2.778 0 5.03.742 5.03 1.656 0 .565-.857 1.063-2.167 1.362h4.696c.088-.767.739-1.362 1.529-1.362h25.722c.85 0 1.54.69 1.54 1.54v6.914c0 .77-.565 1.406-1.302 1.52v3.472h-1.576v-2.01H4.004v2.01H2.428V87.745h1.576z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#627C85" d="M66.419 0L0.347 44.743 73.43 44.743 73.43 4.533 66.419 0" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#A0BAC5" d="M7.547 42.662L66.406 2.469 71.349 5.666 71.349 42.662 7.547 42.662" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#627C85" d="M76.696 6.645L76.696 19.105 95.965 19.105 76.696 6.645M119.906 33.26L138.74 44.743 119.906 44.743 119.906 33.26" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M68.22 164.937h-.573l-.616-8.346h-4.993l-.616 8.346h-.574v-8.346h-.343v-.446h.343v-.827c0-.85.69-1.54 1.54-1.54h4.294c.85 0 1.539.69 1.539 1.54v.827h.344v.446h-.344v8.346z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#627C85" d="M45.032 22.371h12.13v5.773h2.674v14.518H41.295v-7.049l-.006 7.052-9.416-.007.009-11.284 9.412.007.001-3.237h3.737V22.37z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#779CAB" d="M64.07 31.317V21.005h7.279v21.657H57.162V31.317h6.908zM86.133 44.743L107.202 44.743 107.202 31.317 86.133 31.317z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#F2F3F4" d="M87.485 43.915L95.979 43.915 95.979 32.379 87.485 32.379zM97.356 43.915L105.85 43.915 105.85 32.379 97.356 32.379z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M134.976 83.554l-3.33-1.573.842-.34c.07-.073.567-.575 1.176-.534 1.083-1.855 2.995-2.857 2.995-2.857v.895c-1.025.436-1.833 1.55-2.233 2.194l-.06.097c.451.476.337 1.252.337 1.252l.273.866z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#779CAB" d="M136.659 95.905c0 .145-.117.262-.262.262-.145 0-.262-.117-.262-.262V86.08c0-.145.117-.263.262-.263.145 0 .262.118.262.263v9.825" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                            <path fill="#49646D" d="M70.747 96.054v-21.42h1.306v36.522h-8.391V96.054h7.085zM55.038 96.075c.148 0 .267.12.267.266v.03c.85 0 1.539.69 1.539 1.54v2.534h5.713c.126 0 .231.088.259.205l.007.061c0 .148-.12.267-.266.267H61.54v1.773c.616.202 1.073.757 1.13 1.426l.006.142h-1.136v6.656c0 .127-.088.232-.205.26l-.061.007c-.148 0-.267-.12-.267-.267v-6.656h-5.703v6.752c0 .126-.088.232-.206.26l-.06.007c-.148 0-.267-.12-.267-.267V96.34c0-.147.119-.266.266-.266zm5.97 4.903h-4.164v.537c0 .462-.204.877-.527 1.16l.105-.006h4.586v-1.691z" transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(9.000000, 11.000000)"/>
                        </g>
                        <g transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(17.000000, 61.000000)">
                            <circle cx="30.4" cy="30.4" r="30.4" fill="#EAF4ED" opacity=".237"/>
                            <circle cx="30.4" cy="30.4" r="22.8" fill="#EAF4ED" opacity=".477"/>
                            <circle cx="30.4" cy="30.4" r="15.2" fill="#EAF4ED" opacity=".8"/>
                            <g fill="#00A335">
                                <path d="M11.028 14.614c-.676.676-1.773.676-2.45 0-.677-.677-.677-1.774 0-2.45.677-.678 1.774-.678 2.45 0 .677.676.677 1.773 0 2.45M6.798 11.34c-.245 0-.489-.094-.675-.28-.374-.374-.374-.979 0-1.352.983-.984 2.29-1.525 3.68-1.525 1.391 0 2.698.541 3.68 1.525.374.373.374.978 0 1.351-.373.374-.978.374-1.35 0-.623-.622-1.45-.964-2.33-.964-.88 0-1.707.342-2.329.964-.187.187-.431.28-.676.28" transform="translate(20.900000, 22.800000)"/>
                                <path d="M15.565 8.582c-.244 0-.489-.094-.676-.28-1.358-1.359-3.164-2.107-5.086-2.107-1.921 0-3.727.748-5.086 2.107-.373.373-.979.373-1.352 0-.373-.373-.373-.978 0-1.352 1.72-1.72 4.006-2.667 6.438-2.667 2.432 0 4.719.947 6.439 2.667.373.374.373.979 0 1.352-.187.186-.432.28-.677.28" transform="translate(20.900000, 22.800000)"/>
                                <path d="M19.27 5.544c-.224.223-.517.336-.811.336-.294 0-.587-.113-.81-.336-2.096-2.095-4.882-3.25-7.846-3.25-2.963 0-5.75 1.155-7.844 3.25-.449.447-1.175.447-1.623 0-.448-.448-.448-1.175 0-1.622.624-.624 1.299-1.179 2.015-1.66C4.537.79 7.109 0 9.803 0c.538 0 1.072.031 1.598.094 2.972.35 5.721 1.68 7.87 3.828.447.447.447 1.174 0 1.622" transform="translate(20.900000, 22.800000)"/>
                            </g>
                        </g>
                        <g transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(17.000000, 126.000000)">
                            <circle cx="30.4" cy="30.4" r="30.4" fill="#EAF4ED" opacity=".237"/>
                            <circle cx="30.4" cy="30.4" r="22.8" fill="#EAF4ED" opacity=".477"/>
                            <circle cx="30.4" cy="30.4" r="15.2" fill="#EAF4ED" opacity=".8"/>
                            <g fill="#00A335">
                                <path d="M11.028 14.614c-.676.676-1.773.676-2.45 0-.677-.677-.677-1.774 0-2.45.677-.678 1.774-.678 2.45 0 .677.676.677 1.773 0 2.45M6.798 11.34c-.245 0-.489-.094-.675-.28-.374-.374-.374-.979 0-1.352.983-.984 2.29-1.525 3.68-1.525 1.391 0 2.698.541 3.68 1.525.374.373.374.978 0 1.351-.373.374-.978.374-1.35 0-.623-.622-1.45-.964-2.33-.964-.88 0-1.707.342-2.329.964-.187.187-.431.28-.676.28" transform="translate(20.900000, 22.800000)"/>
                                <path d="M15.565 8.582c-.244 0-.489-.094-.676-.28-1.358-1.359-3.164-2.107-5.086-2.107-1.921 0-3.727.748-5.086 2.107-.373.373-.979.373-1.352 0-.373-.373-.373-.978 0-1.352 1.72-1.72 4.006-2.667 6.438-2.667 2.432 0 4.719.947 6.439 2.667.373.374.373.979 0 1.352-.187.186-.432.28-.677.28" transform="translate(20.900000, 22.800000)"/>
                                <path d="M19.27 5.544c-.224.223-.517.336-.811.336-.294 0-.587-.113-.81-.336-2.096-2.095-4.882-3.25-7.846-3.25-2.963 0-5.75 1.155-7.844 3.25-.449.447-1.175.447-1.623 0-.448-.448-.448-1.175 0-1.622.624-.624 1.299-1.179 2.015-1.66C4.537.79 7.109 0 9.803 0c.538 0 1.072.031 1.598.094 2.972.35 5.721 1.68 7.87 3.828.447.447.447 1.174 0 1.622" transform="translate(20.900000, 22.800000)"/>
                            </g>
                        </g>
                        <g transform="translate(-980.000000, -240.000000) translate(980.000000, 240.000000) translate(141.000000, 139.000000)">
                            <path fill="#FFF" stroke="#49646D" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M44.16 3.213c0-1.638-8.765-2.966-19.576-2.966S5.01 1.575 5.01 3.213"/>
                            <path fill="#FFF" stroke="#49646D" strokeLinejoin="round" strokeWidth="1.5" d="M6.813 2.472h35.44c1.258 0 2.311.95 2.44 2.2l4.093 39.582c.14 1.348-.84 2.553-2.188 2.693-.084.009-.168.013-.252.013H2.72c-1.355 0-2.453-1.099-2.453-2.453 0-.085.004-.169.013-.253L4.372 4.673c.13-1.251 1.183-2.201 2.44-2.201z"/>
                            <g fill="#263238">
                                <path d="M14.216 2.042v.006c-.003.34-.28.613-.623.613-.344 0-.623-.277-.623-.619 0-.342.28-.62.623-.62.345 0 .623.278.623.62zM13.593.804c-.688 0-1.245.554-1.245 1.238 0 .684.557 1.239 1.245 1.239.227 0 .44-.061.624-.166v.166h.622V2.042c0-.684-.557-1.238-1.246-1.238zM4.628 2.615c-.063.03-.134.047-.208.047-.268 0-.485-.216-.485-.482v-.757h.472s.078-.273.078-.62h-.55V.08c-.348 0-.623.092-.623.092v2.008c0 .609.496 1.102 1.108 1.102.21 0 .405-.058.572-.159-.156-.14-.28-.313-.364-.507M12.615.962c-.167-.1-.362-.158-.572-.158-.611 0-1.107.493-1.107 1.1v1.377h.623V1.904c0-.266.217-.481.484-.481.075 0 .145.017.209.047.083-.195.208-.368.363-.508M.34.027C.153.027 0 .18 0 .366c0 .187.152.338.34.338C.53.704.682.553.682.366.681.179.53.027.341.027M1.994.804c-.611 0-1.107.493-1.107 1.1v1.377h.623V1.904c0-.266.217-.481.484-.481.268 0 .485.215.485.481v1.377h.623V1.904c0-.607-.496-1.1-1.108-1.1M.03 3.28h.622V.805C.304.804.03.895.03.895v2.386zM7.369 3.28h.623V.08c-.341 0-.612.087-.623.09v3.11zM9.49 2.661c-.345 0-.624-.277-.624-.619v-.004c.003-.34.28-.615.623-.615.345 0 .623.277.623.62 0 .341-.278.618-.623.618m0-1.857c-.227 0-.44.06-.622.166V.08c-.342 0-.613.087-.623.09v1.872c0 .684.557 1.239 1.245 1.239.689 0 1.246-.555 1.246-1.239S10.178.804 9.49.804" transform="translate(15.844226, 38.503169)"/>
                                <path d="M5.357 1.962c.045-.34.358-.578.699-.533.07.009.137.03.198.06l-.89.678c-.014-.066-.017-.135-.007-.205zm1.823.373c-.322-.126-.603-.136-.603-.136-.078.299-.369.5-.684.458-.07-.01-.137-.03-.197-.06l1.409-1.075c-.174-.371-.53-.65-.968-.707-.682-.089-1.307.388-1.397 1.066-.09.678.39 1.3 1.072 1.39.63.082 1.212-.34 1.368-.936zM16.243 1.775h-.41c-.098 0-.177-.08-.177-.177 0-.097.08-.175.177-.175h.895c.003-.011.091-.278.091-.617h-1.028c-.419 0-.758.337-.758.753s.34.754.758.754h.41c.097 0 .176.078.176.175 0 .097-.079.176-.177.176h-1.015c-.004.011-.092.278-.092.617h1.15c.418 0 .757-.337.757-.753s-.34-.753-.757-.753" transform="translate(15.844226, 38.503169)"/>
                            </g>
                            <path stroke="#D0DCE1" strokeLinecap="square" strokeWidth="1.5" d="M7.667 4.21L7.667 45.125" opacity=".64"/>
                            <rect width="3.748" height="1.765" x="2.726" y="46.96" fill="#49646D" rx=".681"/>
                            <rect width="3.748" height="1.765" x="42.592" y="46.96" fill="#49646D" rx=".681"/>
                            <circle cx="24.703" cy="32.54" r="1" fill="#00A335"/>
                            <path stroke="#D0DCE1" strokeLinecap="square" strokeWidth="1.5" d="M41.399 4.21L41.399 45.125" opacity=".64"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}
import { backendStatus } from '../../backend/backend'

export const validatePeriodicInform = value => {
    return (value || value === 0) && value >= 0 && value <= 2147483647 ? '' : 'Valores válidos: [0, 2147483647]'
}

export const TR069ConfigConstants = {
    //ACS Fields
    TR069_CONFIG_MAX_ACS_URL_LENGHT: 256,
    TR069_CONFIG_MAX_ACS_USERNAME_LENGHT: 256,
    TR069_CONFIG_MAX_ACS_PASSWORD_LENGHT: 256,
    //Connection Fields
    TR069_CONFIG_MAX_CONN_NAME_LENGHT: 256,
    TR069_CONFIG_MAX_CONN_PASSWORD_LENGHT: 256,
    TR069_CONFIG_MAX_CONN_PATH_LENGHT: 256
}

export async function fetchTR069Config(backend, setTR069Config) {
    let result = await backend.retrieveFresh('tr069_config')
    if (result.status === backendStatus.SUCCESS) {
        if (setTR069Config) {
            setTR069Config(result.content[0])
            return true
        }
        return result.content[0]
    }

    return false
}

export async function updateTR069Config(backend, TR069Config, setErrorInfo) {

    try {
        let result = await backend.update(`tr069_config/${TR069Config.id}`, TR069Config)
        if(result.status !== backendStatus.SUCCESS) {
            setErrorInfo(result.content?.body)
        }

        return result.status === backendStatus.SUCCESS
    } catch (error) {
        console.error('Error saving TR069 config:', error)
        setErrorInfo(error)
        return false
    }
}


import React, { useState, useEffect, useContext } from 'react'

import './wifi.css'
import RadioIcon from '../../../../components/icons/radio';
import { useHistory } from 'react-router-dom'
import { deleteWireless, fetchWirelessList, reduceWireless } from '../../../resources/wireless'
import { BackendContext } from '../../../../backend/backend'
import { fetchRadioList, frequencyString } from '../../../resources/radio'
import { frequency } from '../../../resources/radio' 
import Loading from '../../../../components/loading/loading'
import EditIcon from '../../../../components/icons/edit'
import DeviceIcon from '../../../../components/icons/device';
import { NetworksContext } from '../networks-context';
import WifiIcon from '../../../../components/icons/wifi-icon'; 
import { fetchWifiLimits } from '../../../resources/limits';
import RemoveIcon from '../../../../components/icons/remove';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import waitRouter from '../../../utils/wait-router';
import { GlobalsContext } from '../../../globals-context';
import { MainHeaderContext } from '../../../../components/main-header/main-header-state';
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next'
import createAction from '../../../resources/action'
import { SystemStatusContext } from '../../../system-status-context'
import WpsIcon from '../../../../components/icons/wps-icon';
import { fetchStatus } from '../../../resources/status';
import { MeshContext } from '../mesh/mesh-context'
import Modal from '../../../../components/modal/modal'
import { ModalTypes } from '../../../../components/modal/modal'
import EyeIcon from '../../../../components/icons/eye-icon';
import TooltipText from '../../../../components/tooltip-text/tooltip-text';

export default function NetworksWifi() {

    let [confirmInfo, setConfirmInfo] = useState(false)
    
    let systemStatus = useContext(SystemStatusContext)
    const backend = useContext(BackendContext)
    const history = useHistory()
    const networks = useContext(NetworksContext)
    const globals = useContext(GlobalsContext)
    const header = useContext(MainHeaderContext)
    const meshContext = useContext(MeshContext)

    const [limits, setLimits] = useState(null)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleteIDs, setDeleteIDs] = useState(null)
    const [errorText, setErrorText] = useState('')
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [loading] = useState(false)
    const [status, setStatus] = useState(null)
    const [easyMesh, setEasyMesh] = useState(null)

    let vapsCounters = {}
    if (networks.wifi.value) {
        vapsCounters = networks.getVAPsNumber()
    }        

    const { t } = useTranslation()

    useEffect(() => {
        header.title.set(t('network.label.NETWORK'))
        fetchWifiLimits(backend, setLimits)
        fetchWirelessList(backend, networks.wifi.set)
        fetchRadioList(backend, networks.radio.set)
        if (!meshContext.easyMesh) {
            meshContext.retrieveEasyMesh()
        }
        setStatus(systemStatus.status)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(!systemStatus.status) return 

        setStatus(systemStatus.status)
        // eslint-disable-next-line
    }, [systemStatus.status])

    useEffect(() => {
        setEasyMesh(meshContext.easyMesh)

        // eslint-disable-next-line
    }, [meshContext.easyMesh])

    useEffect(() => {
        if (!networks.wifi.value || !networks.radio.value) return

        networks.reducedWifi.set(reduceWireless(networks.wifi.value, networks.radio.value))
        // eslint-disable-next-line
    }, [networks.wifi.value, networks.radio.value])

    const connectedDevicesHandler = (wifi) => {
        history.push(`/network/wifi/connected-device/${wifi.id[0]}`)
    }

    const editHandler = (wifi) => {
        history.push(`/network/wifi/${wifi.id[0]}`)
    }

    const deleteHandler = async (wifi) => {
        setDeleteIDs(wifi.id)
        setConfirmDelete(true)
    }

    const continueDelete = async (confirm) => {
        if (success) {
            setSuccess(false)
            return
        }

        if (error) {
            setError(false)
            return
        }

        setConfirmDelete(false)

        if (confirm) {
            setSaving(true)

            for (let id of deleteIDs) {
                let result = await deleteWireless(backend, id)
                if (!result) {
                    setSaving(false)
                    setErrorText(<Translator path="network.wifi.message.delete.DELETE_ERROR"></Translator>)
                    setError(true)
                    setDeleteIDs(null)
                    fetchWirelessList(backend, networks.wifi.set)
                    return
                }
            }

            await backend.backendApply()

            await waitRouter(
                backend,
                globals.getItem('username'),
                globals.getItem('password'),
                history,
                5000
            )

            setSaving(false)
            setSuccess(true)
            fetchWirelessList(backend, networks.wifi.set)
        }

        setDeleteIDs(null)
    }

    const getWifiItems = () => {
        let localReducedWifi = networks.reducedWifi.value
        let ordenatedList = localReducedWifi.sort((a,b) => {
            if(!a.isRoot && b.isRoot){
                return 1
            } 
            else {
                return -1
            }
        })

        if(ordenatedList.length === 0)
            return <div className='aling-center'> <Translator path="network.wifi.message.warning.NO_WIFI_NETWORKS"></Translator> </div>

        return ordenatedList.map((wifi, key) => {
            if (easyMesh && ((easyMesh.enabled && (wifi.id[0] === 'wlan0-vap0' || wifi.id[0] === 'wlan1-vap0'))))
                return null

            return (
                <div id={`wifi-item-${key}`} className='wifi-item' key={key}>

                    {wifi.isRoot ? null : loading ? <Loading show={true}></Loading> : null}

                    <div className='wifi-info'>
                        <label id={`wifi-ssid-${key}`} className='wifi-ssid'>{wifi.ssid}</label>
                        {
                                <div id={`wifi-ssid-action-${key}`} className='wifi-ssid-action'>
                                    <div className='wifi-ssid-action-icon edit' onClick={() => connectedDevicesHandler(wifi)}>
                                        <div className='tooltip'>
                                            <TooltipText message={t('network.wifi.tip.SHOW_CONNECTED_CLIENT')}></TooltipText>
                                            <DeviceIcon size={24} color={"#808080"}></DeviceIcon>
                                        </div>  
                                    </div>
                                    <div className='wifi-ssid-action-icon edit' onClick={() => editHandler(wifi)}>
                                        <div className='tooltip'>
                                            <TooltipText message={t('network.wifi.tip.EDIT_WIFI')}></TooltipText>
                                            <EditIcon size={24}></EditIcon>
                                        </div>
                                    </div>
                                    {wifi.isRoot ? null :
                                        <div className='wifi-ssid-action-icon delete' onClick={() => deleteHandler(wifi)}>
                                            <div className='tooltip'>
                                                <TooltipText message={t('network.wifi.tip.DELETE_WIFI')}></TooltipText>
                                                <RemoveIcon size={24}></RemoveIcon>
                                            </div>
                                        </div>
                                    }
                                </div>
                        }                        
                        {
                            <div id={`wifi-frequency-${key}`} className='wifi-frequency'>{frequencyString[wifi.frequency]}</div>
                        }
                    </div>
                </div>
            )
        })
    }

    const outOfLimits = () => { 
        const counters = networks.getNumberByFrequency()
        const limitsObj = networks.getLimits(limits, [frequency._2_4GHz, frequency._5GHz])
        
        const radio0 = networks.getRadio(networks.getRadioID(frequency._5GHz))
        const radio1 = networks.getRadio(networks.getRadioID(frequency._2_4GHz))

        if (radio0) {
            if (radio0.enabled) {
                if (counters[frequency._5GHz] < limitsObj[frequency._5GHz]) {
                    return false
                }
            }
        }

        if (radio1) {
            if (radio1.enabled) {
                if (counters[frequency._2_4GHz] < limitsObj[frequency._2_4GHz]) {
                    return false
                }
            }
        }

        return true
    }

    const getRadio0VAPsLimit = () => {        
        const limitsObj = networks.getVAPsLimits(limits, [frequency._2_4GHz, frequency._5GHz])

        if(easyMesh && easyMesh.enabled)
            return (limitsObj[frequency._2_4GHz] - 1)
        else
            return limitsObj[frequency._2_4GHz]
    }

    const getRadio1VAPsLimit = () => {        
        const limitsObj = networks.getVAPsLimits(limits, [frequency._2_4GHz, frequency._5GHz])
        
        if(easyMesh && easyMesh.enabled)
            return (limitsObj[frequency._5GHz] - 1)
        else
            return limitsObj[frequency._5GHz]
    }

    const createWifi = () => { 
        if (outOfLimits()) {
            setErrorText(<Translator path="network.wifi.message.warning.MAX_WIFI"></Translator>)
            setError(true)
            return
        }

        history.push('/network/wifi/add')
    }

    const startWiFiWps = async () => {
        const WIFI_WPS_PBC_ACTION = 3
        let result = await createAction(backend, {actionID: WIFI_WPS_PBC_ACTION})

        if (!result) {
            return false
        }

        fetchStatus(backend, setStatus)
        return true
    }

    const checkIfExistEnabledRadios = () => {
        if(!networks.radio.value)
            return ''
        
        return networks.radio.value.some(radio => radio.enabled)
    }

    const checkIfEnabledRadio0 = () => {
        if(!networks.radio.value)
            return ''

        return networks.radio.value.some(radio => (radio.frequency === frequency._2_4GHz) && radio.enabled)
    }

    const checkIfEnabledRadio1 = () => {
        if(!networks.radio.value)
            return ''

        return networks.radio.value.some(radio => (radio.frequency === frequency._5GHz) && radio.enabled)
    }

    const checkIfExistEnabledWps = () => {
        if(!networks.reducedWifi.value)
            return ''
        
        return networks.reducedWifi.value.some(wifi => {
            return wifi.enabled && wifi.isRoot && wifi.wps
        })
    }

    let confirmWPSInfo = () => {
        setConfirmInfo(true)
    }

    let continueConfirmWPSInfo = () => {
        setConfirmInfo(false)

        startWiFiWps()
    }

    return (
        !networks.reducedWifi.value || !limits ? <Loading show={true}></Loading> :
            <React.Fragment>
                <DefaultStatusModals
                    error={error}
                    errorText={errorText}
                    saving={saving}
                    savingText={<Translator path="network.wifi.message.delete.DELETING"></Translator>}
                    success={success}
                    successText={<Translator path="network.wifi.message.delete.DELETE_SUCCESS"></Translator>}
                    confirm={confirmDelete}
                    continueFn={continueDelete}
                    confirmContent={
                        <div style={{ width: '420px' }}>
                            <span style={{ display: 'block' }}><Translator path="network.wifi.message.delete.DELETE_WARNING"></Translator>
                                <br></br>
                            </span>
                            <b><Translator path="network.wifi.message.delete.DELETE_CONFIRM"></Translator></b>
                        </div>
                    }
                    isWarningModal={true}
                    dismissText={<Translator path="common.label.CANCEL"></Translator>}
                    confirmText={<Translator path="common.label.CONFIRM"></Translator>}
                ></DefaultStatusModals>

                <div className='wifi-page with-tabs'>
                    <div className='subtitle'><Translator path="network.wifi.title.WIFI_TITLE"></Translator></div>
                    <div id='wifi-list' className='wifi-list'>
                        {getWifiItems()}
                    </div>
                    <div id='wifi-aps-counter-container'>
                        {checkIfExistEnabledRadios() ?
                            <div id='wifi-aps-count' className='wifi-aps-count'>
                                {checkIfEnabledRadio0() ?
                                    <span><b><Translator path="network.wifi.label.VAP_COUNTER_2_4GHZ"></Translator>:</b> {vapsCounters[frequency._2_4GHz]}/{getRadio0VAPsLimit()}</span> 
                                : null }
                                {checkIfEnabledRadio1() ?
                                    <span><b><Translator path="network.wifi.label.VAP_COUNTER_5GHZ"></Translator>:</b> {vapsCounters[frequency._5GHz]}/{getRadio1VAPsLimit()}</span>
                                : null }
                            </div>
                        :
                            null
                        }
                    </div>
                    <div id='wifi-actions-container'>
                        {checkIfExistEnabledRadios() ?
                            <div id='wifi-create-button' className={`card wifi-action ${outOfLimits() ? 'disabled' : ''}`}
                                onClick={createWifi}
                            >
                                <div className='wifi-action-icon orange'>
                                    <WifiIcon size={25} color='orange'></WifiIcon>
                                </div>

                                <span><Translator path="network.wifi.label.CREATE_NETWORK"></Translator></span>
                            </div>
                        :
                            null
                        }

                        <div id='wifi-radio-config' className='card wifi-action' onClick={() => { history.push('/network/wifi/radio') }}>
                            <div className='wifi-action-icon blue'>
                                <RadioIcon></RadioIcon>
                            </div>
                            <span><Translator path="network.wifi.title.RADIO_TITLE"></Translator></span>
                        </div>

                        <Modal
                            modalID='confirm_wps_press_info'
                            show={confirmInfo}
                            type={ModalTypes.INFO}
                            onDismissClick={continueConfirmWPSInfo}
                            title={t('menu.WPS_DESCRIPTION')}
                            content={
                                <div style={{maxWidth: '420px'}}>
                                    <Translator path="network.wifi.message.warning.EXPLAINING_WPS_INFO"></Translator>
                                </div>
                            }
                            dismissText={t('common.label.CONFIRM')}
                        ></Modal>

                        {checkIfExistEnabledRadios() && checkIfExistEnabledWps() ? 
                            <div id='wifi-wps-button' className='card wifi-action' onClick={confirmWPSInfo}>
                                {
                                    (status && status.wps_status === 1) ?
                                        <div className='wifi-wps-icon syncing green'>
                                            <WpsIcon size='24'></WpsIcon>
                                        </div>
                                    :
                                    <div className='wifi-wps-icon green'>
                                        <WpsIcon size='24'></WpsIcon>
                                    </div>
                                }
                                <span><Translator path="network.wifi.title.WPS_TITLE"></Translator></span>
                            </div>
                        :
                            null
                        }

                        {checkIfExistEnabledRadios() ?
                            <div id='wifi-wlan-status' className='card wifi-action' onClick={() => { history.push('/network/wifi/status/radio5ghz') }}>
                                <div className='wifi-action-icon blue'>
                                    <EyeIcon></EyeIcon>
                                </div>
                                <span>
                                    <Translator path="network.wifi.status.title.SHOW_WLAN_STATUS"></Translator>
                                </span>
                            </div>
                        :
                            null
                        }
                    </div>
                </div>
            </React.Fragment>
    )
}



import { backendStatus } from '../../backend/backend'

const URL = 'ipv6_static_route_list';

export async function fetchIpv6RouteList(backend, id, setIpv6RouteList) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setIpv6RouteList(result.content)
    }
    
}

export async function fetchIpv6RouteListList(backend, setIpv6RouteListList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setIpv6RouteListList(result.content)
    }
}

export async function deleteIpv6RouteListById(backend, id, setIpv6RouteListList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchIpv6RouteListList(backend, setIpv6RouteListList)
    }
}

export async function saveIpv6RouteList(backend, ipv6RouteList, setErrorMessage) {

    try{

        let result = null;

        if(!ipv6RouteList.id) {
            result = await backend.create(`${URL}`, ipv6RouteList)
        }
        else {
            result = await backend.update(`${URL}/${ipv6RouteList.id}`, ipv6RouteList)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || ipv6RouteList)

    }catch(error){

        console.error('Error saving IPV6_ROUTE_LIST:', error)

        return false
    }

}
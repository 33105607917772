import React, { useState, useEffect, useContext } from 'react'

import './advanced-qos-traffic-control.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchAdvancedQosTrafficControlList, deleteAdvancedQosTrafficControlById } from '../../../resources/advanced-qos-traffic-control';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import Button from '../../../../components/button/button'
import RemoveIcon from '../../../../components/icons/remove';
import EditIcon from '../../../../components/icons/edit';

import {
    QosTCIPVersionEnumeration,
    getDirectionEnumerationElementsName,
    getProtocolEnumerationElementsName,
    getIPVersionEnumerationElementsName
} from "./qos-traffic-control-common"

export default function AdvancedQosTrafficControl({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [qosTrafficControl, setAdvancedQosTrafficControl] = useState(null)

    const [qosTrafficControlPagination, setAdvancedQosTrafficControlPagination] = useState(null)

    const backend = useContext(BackendContext)
    const history = useHistory()

    const { t } = useTranslation()

    useEffect(() => {
        setAdvancedQosTrafficControlPagination({ pageNumber: 1, totalElements: 0, pageSize: 10 })
        fetchAdvancedQosTrafficControlList(backend, setAdvancedQosTrafficControl)

        // eslint-disable-next-line
    }, [])

    const getColumns = () => {

        return [
            { header: t('common.label.NUMBER'), align: 'center', size: '30px' },
            { header: t('common.label.INTERFACE'), align: 'center', size: '100px' },
            { header: t('common.label.DIRECTION'), align: 'center', size: '100px' },
            { header: t('common.label.PROTOCOL'), align: 'center', size: '170px' },
            { header: <div style={{ whiteSpace: "normal" }}>{t('advanced.qos_traffic_control.label.LIMIT_RATE')}</div>, align: 'center', size: '100px' },
            { header: t('advanced.qos_classification.label.IP_VERSION'), align: 'center', size: '120px' },
            { header: t('common.label.IP_ADDRESS'), align: 'center', size: '340px' },
            { header: <div style={{ whiteSpace: "normal" }}>{t('advanced.qos_traffic_control.label.SRC_PORT')}</div>, align: 'center', size: '70px' },
            { header: <div style={{ whiteSpace: "normal" }}>{t('advanced.qos_traffic_control.label.DST_PORT')}</div>, align: 'center', size: '70px' },
            { header: t('advanced.qos_classification.label.OPTIONS'), align: 'center', size: '100px' },
        ]
    }

    const changePage = page => {
        setAdvancedQosTrafficControlPagination({ ...qosTrafficControlPagination, pageNumber: page })
    }

    const createIPv6Span = ipv6 => {
        const MAX_STRING_SIZE = 40
        return (
            <span title={ipv6}>
                {ipv6.length > MAX_STRING_SIZE ? `${ipv6.substring(0, MAX_STRING_SIZE)}...` : ipv6}
            </span>
        )
    }

    const createNewQosTrafficControlForm = () => {
        history.push(`/advanced/qos-traffic-control/add`)
    }

    const editQosTrafficControl = (element) => {
        history.push(`/advanced/qos-traffic-control/${element.id}`)
    }

    const removeQosTrafficControl = (element) => {
        deleteAdvancedQosTrafficControlById(backend, element.id, setAdvancedQosTrafficControl)
    }

    const getAdvancedQosTrafficControl = () => {

        let qosTrafficControlFiltered = qosTrafficControl

        if (qosTrafficControlFiltered.length !== qosTrafficControlPagination.totalElements) {
            qosTrafficControlPagination.totalElements = qosTrafficControlFiltered.length
            setAdvancedQosTrafficControlPagination({ ...qosTrafficControlPagination })
        }

        let qosTrafficControlLines = [];

        let begining = (qosTrafficControlPagination.pageNumber - 1) * (qosTrafficControlPagination.pageSize)
        let end = begining + qosTrafficControlPagination.pageSize
        if (end > qosTrafficControlPagination.totalElements) {
            end = qosTrafficControlPagination.totalElements
        }

        for (let i = begining, nr = (1 + (qosTrafficControlPagination.pageNumber - 1) * qosTrafficControlPagination.pageSize); i < end; i++) {
            qosTrafficControlLines.push([
                nr++,
                qosTrafficControlFiltered[i].interfaceID,
                getDirectionEnumerationElementsName(qosTrafficControlFiltered[i].direction, t),
                getProtocolEnumerationElementsName(qosTrafficControlFiltered[i].protocol_type, t),
                qosTrafficControlFiltered[i].limit_rate,
                getIPVersionEnumerationElementsName(qosTrafficControlFiltered[i].ip_version, t),
                (qosTrafficControlFiltered[i].ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4 ?
                    <div>
                        {qosTrafficControlFiltered[i].ip4_config.src_ip ?
                            <div>{`${t('common.label.SOURCE')}: ${qosTrafficControlFiltered[i].ip4_config.src_ip}/${qosTrafficControlFiltered[i].ip4_config.src_prefix_length}`}</div>
                            : <div>{createIPv6Span(`${t('common.label.SOURCE')}: --`)}</div>}
                        {qosTrafficControlFiltered[i].ip4_config.dst_ip ?
                            <div>{`${t('common.label.DESTINATION')}: ${qosTrafficControlFiltered[i].ip4_config.dst_ip}/${qosTrafficControlFiltered[i].ip4_config.dst_prefix_length}`}</div>
                            : <div>{createIPv6Span(`${t('common.label.DESTINATION')}: --`)}</div>}
                    </div>
                    :
                    <div>
                        {qosTrafficControlFiltered[i].ip6_config.src_ip ?
                            <div>{createIPv6Span(`${t('common.label.SOURCE')}: ${qosTrafficControlFiltered[i].ip6_config.src_ip}/${qosTrafficControlFiltered[i].ip6_config.src_prefix_length}`)}</div>
                            : <div>{createIPv6Span(`${t('common.label.SOURCE')}: --`)}</div>}
                        {qosTrafficControlFiltered[i].ip6_config.dst_ip ?
                            <div>{createIPv6Span(`${t('common.label.DESTINATION')}: ${qosTrafficControlFiltered[i].ip6_config.dst_ip}/${qosTrafficControlFiltered[i].ip6_config.dst_prefix_length}`)}</div>
                            : <div>{createIPv6Span(`${t('common.label.DESTINATION')}: --`)}</div>}
                    </div>
                ),
                qosTrafficControlFiltered[i].src_port,
                qosTrafficControlFiltered[i].dst_port,
                <div >
                    <span className="clickable" onClick={e => {
                        editQosTrafficControl(qosTrafficControlFiltered[i])
                    }}><EditIcon /></span>

                    <span style={{ paddingInline: 4 }} />

                    <span className="clickable" onClick={e => {
                        removeQosTrafficControl(qosTrafficControlFiltered[i])
                    }
                    }><RemoveIcon /></span>
                </div>
            ]);
        }

        return qosTrafficControlLines;
    }

    const qosTrafficControlForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="menu.QOS_TRAFFIC_CONTROL"></Translator></div>
            {getAdvancedQosTrafficControl().length > 0 ?
                <>
                    <div className="advanced-qos-traffic-control-list-wrapper">
                        <List
                            lines={getAdvancedQosTrafficControl()}
                            columns={getColumns()}
                            width={1200}
                        ></List>
                    </div>
                    <Pager
                        pageNumber={qosTrafficControlPagination.pageNumber}
                        totalElements={qosTrafficControlPagination.totalElements}
                        pageSize={qosTrafficControlPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                </>
                :
                <div id='qos-tf-no-rules'>
                    <div className='info-card noelements-card'>
                        <Translator path="advanced.qos_traffic_control.info.NO_RULES" />
                    </div>
                </div>
            }
            <Button
                id='new_qos_traffic_control'
                text={t('advanced.qos_traffic_control.label.ADD_NEW_RULE')}
                onClick={createNewQosTrafficControlForm}
            ></Button>
        </React.Fragment>
    }

    return (
        !qosTrafficControl ? <Loading show={true}></Loading> :

            <div id='advanced-qos-traffic-control-page' className='with-tabs'>

                {qosTrafficControlForm()}

            </div>
    )
}

import React from 'react'


export const fixedDecimals = (num, places) => {
    return parseFloat(num).toFixed(places ? places : 2)
} 

export const createLimitedTableCell = (content, maxSize = 30) => {
    return (
        <span title={content}>
            {content.length > maxSize ? `${content.substring(0, maxSize)} ...` : content}
        </span>
    )
}

export const LimitToMaxSafeInteger = (value) => {
    return (isNaN(Number(value)) || !value) ? value : (Number(value) > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : Number(value))
}
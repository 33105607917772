import React, { useState, useEffect, useContext } from 'react'
import Input from '../../../../components/input/input'
import { BackendContext } from '../../../../backend/backend'
import Form from '../../../../components/form/form'
import Loading from '../../../../components/loading/loading'
import network from '../../../../components/form/validators/network'
import { createDhcpLease, updateDhcpLease, fetchDhcpLease, initialLease } from '../../../resources/dhcp-lease'
import { useHistory, useParams } from 'react-router-dom'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import { MainHeaderContext } from '../../../../components/main-header/main-header-state'
import iputils from '../../../../components/utils/iputils'
import macutils from '../../../utils/macutils'
import waitRouter from '../../../utils/wait-router'
import { GlobalsContext } from '../../../globals-context'
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/button/button'
import extraValidators from '../../../common/validators';

export default function DhcpLeaseAdd({ leases, dhcpServer }) {

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [lease, setLease] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)
    const globals = useContext(GlobalsContext)
    const history = useHistory()
    const params = useParams()
    const [idDhcpServerLease, setIdDhcpServerLease] = useState(null);

    const { t } = useTranslation()

    useEffect(() => {

        header.backRoute.set('/network/lan/dhcp-lease')

        let id = params['id']
        setIdDhcpServerLease(id)

        if (!id) {
            setIdDhcpServerLease('add')
            setLease({
                ...initialLease
            })
            return
        }

        fetchDhcpLease(backend, id, setLease)

        // eslint-disable-next-line
    }, [])

    const goBackToList = () => {
        history.push(`/network/lan/dhcp-lease`)
    }

    const validateRange = (value) => {
        if (!iputils.isIpInRange(value, dhcpServer.rangeStart, dhcpServer.rangeEnd)) {
            return t('network.lan.error.ADDRESS_OUT_OF_RANGE')
        }

        return ''
    }

    const validateIpUniqueness = (value) => {
        const ipAvailable = leases.every((otherLease) => {
            if (value === otherLease.ip && params['id'] !== otherLease.id) {
                return false
            } else {
                return true
            }
        })

        if (!ipAvailable) return t('network.lan.error.IP_ADDRESS_ALREADY_IN_USE')

        return ''
    }

    const validateMacUniqueness = (value) => {
        const macAvailable = leases.every((otherLease) => {
            if (value.toLowerCase() === otherLease.mac.toLowerCase() && params['id'] !== otherLease.id) {
                return false
            } else {
                return true
            }
        })

        if (!macAvailable) return t('network.lan.error.MAC_ADDRESS_ALREADY_IN_USE')

        return ''
    }

    const dismiss = () => {
        setSaving(false)
        setError(false)

        history.push('/network/lan/dhcp-lease')
    }

    const waitReturn = async () => {

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        setSaving(false)

        history.push('/network/lan/dhcp-lease')
    }

    const submit = async () => {

        if (saving || error || validateIpUniqueness(lease.ip) || validateMacUniqueness(lease.mac) || validateRange(lease.ip)) {
            return
        }

        setSaving(true)

        let id = params['id']
        if (!id) {
            const [status, code] = await createDhcpLease(backend, lease)

            if (!status) {
                setSaving(false)
                setError(true)
                if (code === 'max') {
                    setErrorText(t('network.lan.error.MAX_LEASES_REACHED'))
                } else {
                    setErrorText(t('network.lan.error.COULD_NOT_ADD_LEASE'))
                }
            } else {
                await waitReturn()
            }

        } else {
            const status = await updateDhcpLease(backend, lease)

            if (!status) {
                setSaving(false)
                setError(true)
                setErrorText(t('network.lan.error.COULD_NOT_EDIT_LEASE'))
            } else {
                await waitReturn()
            }
        }
    }

    return !lease ? <Loading show={true}></Loading> :

        <div id='add-dhcp-lease'>
            <div className='subtitle'><Translator path="network.lan.title.IP_LEASES"></Translator></div>

            <Button
                id='dhcp-lease-new-button'
                text={<Translator path="common.label.BACK"></Translator>}
                onClick={e => goBackToList()}
            ></Button>

            <DefaultStatusModals
                saving={saving}
                error={error}
                errorText={errorText}
                continueFn={dismiss}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <div className='card mt2'>
                <div className='subtitle'><Translator path="network.lan.title.ADD_NEW_IP_LEASE"></Translator></div>

                <Form
                    className='dhcp-lease-form'
                    onSubmit={submit}
                    isPristine={false}
                    buttonId='button-save'
                >

                    {
                        idDhcpServerLease !== 'add' &&
                        <Input id='lease-description'
                            name='description'
                            type='text'
                            value={lease.description}
                            label={<Translator path="common.label.DESCRIPTION" required={true}></Translator>}
                            readOnly={true}
                        ></Input>
                    }

                    <Input id='lease-ip'
                        name='lease-ip'
                        label={<Translator path="network.lan.label.IP" required={true}></Translator>}
                        type='text'
                        value={lease.ip}
                        onChange={(e) => {
                            setLease({ ...lease, ip: e.target.value })
                        }}
                        validators={[
                            extraValidators.required,
                            network.ip4,
                            validateRange,
                            validateIpUniqueness
                        ]}
                    ></Input>

                    <Input id='lease-mac'
                        name='lease-mac'
                        label={<Translator path="network.lan.label.MAC" required={true}></Translator>}
                        type='text'
                        value={lease.mac}
                        onChange={(e) => {
                            setLease({ ...lease, mac: macutils.macAddressMask(e.target.value) })
                        }}
                        validators={[
                            extraValidators.required,
                            network.mac,
                            validateMacUniqueness
                        ]}
                    ></Input>

                </Form>
            </div>
        </div>

}
import { backendStatus } from '../../backend/backend'

export async function fetchVoipHistoryCallList(backend, setVoipHistoryCall) {
    let result = await backend.retrieveFresh('voip_history_call')

    if(result.status === backendStatus.SUCCESS){

        let voipHistoryCallList = result.content

        setVoipHistoryCall(voipHistoryCallList)
    }
}

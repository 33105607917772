import React, { useState } from 'react'
import { frequency, radioOpMode } from '../../resources/radio'
import Translator from '../../common/components/translator/translator'
import { ieeeStd } from '../../resources/wireless'

export const NetworksContext = React.createContext({})

export default function NetworksState({ children }) {

    const [wifi, setWifi] = useState(null)
    const [radio, setRadio] = useState(null)
    const [reducedWifi, setReducedWifi] = useState(null)

    const getReducedWifi = (wifiID) => {

        for (let net of reducedWifi)
            if (net.id.includes(wifiID))
                return net
    }

    const getSingleWiFi = (wifiID) => {

        for (let net of wifi)
            if (net.id === wifiID)
                return net
    }

    const setSingleReducedWifi = (wifi) => {

        for (let i in reducedWifi) {
            if (reducedWifi[i].id === wifi.id) {
                reducedWifi[i] = wifi
            }
        }

        setReducedWifi([...reducedWifi])
    }

    const getWirelessRootIEEEStd = (freq) => {
        let radioIDs = getRadioIDs(freq)
        let wifiRoots = wifi.filter(wireless => wireless.isRoot && radioIDs.includes(wireless.radioID))
        return wifiRoots.reduce((previous, current) => previous | current.ieee_std, 0)
    }

    const getRadioID = (frequency) => {

        for (let r of radio)
            if (r.frequency === frequency)
                return r.id
    }

    const getRadioIDs = (freq) => {
        let radioIDs = []

        switch (freq) {
            case frequency._2_4GHz:
            case frequency._5GHz:
                radioIDs.push(radio.find(radio => radio.frequency === freq)?.id)
                break
            case frequency.dualband:
                radioIDs.push(radio.find(radio => radio.frequency === frequency._2_4GHz)?.id)
                radioIDs.push(radio.find(radio => radio.frequency === frequency._5GHz)?.id)
                break
            // no default
        }
        return radioIDs
    }

    const getRadio = (radioID) => {
        for (let r of radio)
            if (r.id === radioID)
                return r
    }

    const getNumberByFrequency = () => {

        let counters = {}

        for (let net of wifi) {
            let freq = getRadio(net.radioID).frequency

            if (!counters[freq]) counters[freq] = 0

            counters[freq]++
        }

        return counters
    }

    const getVAPsNumber = () => {

        let counters = {}

        for (let net of wifi) {
            let freq = getRadio(net.radioID).frequency

            if (!counters[freq]) counters[freq] = 0

            if (!net.isRoot) counters[freq]++            
        }

        return counters
    }

    const getLimits = (limits) => {
        let limitsObj = {}

        for (let limit of limits) {
            for (let wifi_limit of limit.wifi_limits) {
                if (wifi_limit.radio_id === getRadioID(frequency._2_4GHz)) {
                    limitsObj[frequency._2_4GHz] = wifi_limit.value
                } else if (wifi_limit.radio_id === getRadioID(frequency._5GHz)) {
                    limitsObj[frequency._5GHz] = wifi_limit.value
                }
            }            
        }

        return limitsObj
    }

    const getVAPsLimits = (limits) => {
        let limitsObj = {}

        for (let limit of limits) {
            for (let wifi_limit of limit.wifi_vaps_limits) {
                if (wifi_limit.radio_id === getRadioID(frequency._2_4GHz)) {
                    limitsObj[frequency._2_4GHz] = wifi_limit.value
                } else if (wifi_limit.radio_id === getRadioID(frequency._5GHz)) {
                    limitsObj[frequency._5GHz] = wifi_limit.value
                }
            }            
        }

        return limitsObj
    }

    const ssidAlreadyUsed = (value, current) => {

        for (let i in reducedWifi) {
            if (current.id && reducedWifi[i].id.some(id => current.id.includes(id))) continue

            if (reducedWifi[i].ssid === value) {
                return <Translator path="network.wifi.message.warning.SAME_SSID"></Translator>
            }
        }

        return ''
    }

    const isDualBand = () => {
        if(radio == null)
            return false

        const check_2_4ghz = 1
        const check_5ghz = 2
        
        let checkRadioEnabled = 0
        
        for (let r of radio) {
            if(r.enabled === true) {
                if(r.frequency === frequency._2_4GHz)
                    checkRadioEnabled += check_2_4ghz
                else 
                    checkRadioEnabled += check_5ghz
            }
        }

        if( checkRadioEnabled === (check_2_4ghz + check_5ghz) )
            return true

        return false

    }

    const getRootIDs = () => {
        let ids = []
        for (let i in wifi) {
            if (wifi[i].isRoot) ids.push(wifi[i].id)
        }
        return ids
    }

    const RadioOpModeToIeeeStd = (radio) => {
        let ieee_std = 0
        if (radio.frequency === frequency._2_4GHz) {
            switch(radio.opMode) {
                case radioOpMode.B:
                    ieee_std |= ieeeStd.B
                    break;
                case radioOpMode.G:
                    ieee_std |= ieeeStd.G
                    break;
                case radioOpMode.BG:
                    ieee_std |= ieeeStd.B | ieeeStd.G
                    break;
                case radioOpMode.N:
                    ieee_std |= ieeeStd.N
                    break;
                case radioOpMode.GN:
                    ieee_std |= ieeeStd.G | ieeeStd.N
                    break;
                case radioOpMode.BGN:
                    ieee_std |= ieeeStd.B | ieeeStd.G | ieeeStd.N
                    break;
                case radioOpMode.AX:
                    ieee_std |= ieeeStd.B | ieeeStd.G | ieeeStd.N | ieeeStd.AX
                    break;
                default:
                    ieee_std |= ieeeStd.B | ieeeStd.G | ieeeStd.N | ieeeStd.AX

            }
        }
        else if (radio.frequency === frequency._5GHz) {
            switch(radio.opMode) {
                case radioOpMode.A:
                    ieee_std |= ieeeStd.A
                    break;
                case radioOpMode.N:
                    ieee_std |= ieeeStd.N
                    break;
                case radioOpMode.AN:
                    ieee_std |= ieeeStd.A | ieeeStd.N
                    break;
                case radioOpMode.AC:
                    ieee_std |= ieeeStd.AC
                    break;
                case radioOpMode.NAC:
                    ieee_std |= ieeeStd.N | ieeeStd.AC
                    break;
                case radioOpMode.ANAC:
                    ieee_std |= ieeeStd.A | ieeeStd.N | ieeeStd.AC
                    break;
                case radioOpMode.AX:
                    ieee_std |= ieeeStd.AX
                    break;
                case radioOpMode.ANACAX:
                    ieee_std |= ieeeStd.A | ieeeStd.N | ieeeStd.AC | ieeeStd.AX
                    break;
                default: ieee_std |= ieeeStd.A | ieeeStd.N | ieeeStd.AC | ieeeStd.AX
            }
        }
        return ieee_std
    }

    return <NetworksContext.Provider value={{
        reducedWifi: { value: reducedWifi, set: setReducedWifi },
        wifi: { value: wifi, set: setWifi },
        radio: { value: radio, set: setRadio },
        getSingleWiFi,
        getReducedWifi,
        setSingleReducedWifi,
        getWirelessRootIEEEStd,
        getRadioID,
        getRadioIDs,
        getRadio,
        getNumberByFrequency,
        getVAPsNumber,
        getLimits,
        getVAPsLimits,
        ssidAlreadyUsed,
        isDualBand,
        getRootIDs,
        RadioOpModeToIeeeStd
    }}>
        {children}
    </NetworksContext.Provider>
}

import React, { useContext } from 'react'
import DashboardIcon from '../../../../components/icons/dashboard-icon'
import WifiIcon from '../../../../components/icons/wifi-icon'
import CogIcon from '../../../../components/icons/cog'
import LogoutIcon from '../../../../components/icons/logout-icon'
import ToolsIcon from '../../../../components/icons/tools'
import PONIcon from '../../../../components/icons/pon-icon'
import NetworksIcon from '../../../../components/icons/networks-icon'
import LockActiveIcon from '../../../../components/icons/lock-active-icon'
import SliderControlIcon from '../../../../components/icons/slider-control-icon'
import DeviceIcon from '../../../../components/icons/device'
import { useHistory } from 'react-router-dom'
import PhoneIcon from '../../../../components/icons/phone-icon'
import Translator from '../../../common/components/translator/translator'
import PacketsIcon from '../../../../components/icons/packets-icon'
import { BackendContext } from '../../../../backend/backend'
import { GlobalsContext } from '../../../globals-context'
import PONState, { PONContext } from '../../pon/pon-context';
import MenuID from '../../../utils/menu'
import DisplayableMenuEntry from './displayable-menu-entry'

export default function MainMenu() {
    let history = useHistory()

    const backend = useContext(BackendContext)
    let globals = useContext(GlobalsContext)

    const logout = () => {
        sessionStorage.clear()
        backend.setAuthInfo({ token: '' })
        globals.deleteItem('username')
        history.push('/login')
    }

    const handleMenu = (route) => {
        history.push(route)
    }

    return (
        <div>
            <DisplayableMenuEntry IconComponent={DashboardIcon} iconSize='20px'
                id={MenuID.Dashboard.name}
                label={<Translator path="menu.DASHBOARD"></Translator>} description={<Translator path="menu.DASHBOARD_DESCRIPTION"></Translator>}
                onClick={() => { handleMenu('/') }}
            ></DisplayableMenuEntry>
            {
                <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                    id={MenuID.Network.name}
                    label={<Translator path="menu.NETWORK"></Translator>} description={<Translator path="menu.NETWORK_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                            key={MenuID.NetworkWan.name}
                            id={MenuID.NetworkWan.name}
                            label={<Translator path="menu.NETWORK_WAN"></Translator>} description={<Translator path="menu.NETWORK_WAN_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/network/wan') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                            key={MenuID.NetworkLan.name}
                            id={MenuID.NetworkLan.name}
                            label={<Translator path="menu.NETWORK_LAN"></Translator>} description={<Translator path="menu.NETWORK_LAN_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/network/lan') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                            key={MenuID.NetworkWifi.name}
                            id={MenuID.NetworkWifi.name}
                            label={<Translator path="menu.NETWORK_WIFI"></Translator>} description={<Translator path="menu.NETWORK_WIFI_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/network/wifi') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                            key={MenuID.NetworkMesh.name}
                            id={MenuID.NetworkMesh.name}
                            label={<Translator path="menu.NETWORK_MESH"></Translator>} description={<Translator path="menu.NETWORK_MESH_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/network/mesh/settings') }}
                        ></DisplayableMenuEntry>,

                        /*NETWORK_TAG*/
                    ]}
                ></DisplayableMenuEntry >
            }

            {
                <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                    id={MenuID.Ipv6.name}
                    label={<Translator path="menu.IPV6"></Translator>} description={<Translator path="menu.IPV6_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6Status.name}
                            id={MenuID.Ipv6Status.name}
                            label={<Translator path="menu.IPV6_ENABLE"></Translator>} description={<Translator path="menu.IPV6_ENABLE_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/status') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6ProxyMld.name}
                            id={MenuID.Ipv6ProxyMld.name}
                            label={<Translator path="menu.IPV6_PROXY_MLD"></Translator>} description={<Translator path="menu.IPV6_PROXY_MLD_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/proxy-mld') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6Radvd.name}
                            id={MenuID.Ipv6Radvd.name}
                            label={<Translator path="menu.IPV6_RADVD"></Translator>} description={<Translator path="menu.IPV6_RADVD_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/radvd') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6Dhcp.name}
                            id={MenuID.Ipv6Dhcp.name}
                            label={<Translator path="menu.IPV6_DHCP"></Translator>} description={<Translator path="menu.IPV6_DHCP_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/dhcp') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6Acl.name}
                            id={MenuID.Ipv6Acl.name}
                            label={<Translator path="menu.ACL_IPV6"></Translator>} description={<Translator path="menu.ACL_IPV6_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/acl-ipv6') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6RouteList.name}
                            id={MenuID.Ipv6RouteList.name}
                            label={<Translator path="menu.IPV6_ROUTE_LIST"></Translator>} description={<Translator path="menu.IPV6_ROUTE_LIST_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/ipv6-route-list') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6StaticRoute.name}
                            id={MenuID.Ipv6StaticRoute.name}
                            label={<Translator path="menu.IPV6_STATIC_ROUTE"></Translator>} description={<Translator path="menu.IPV6_STATIC_ROUTE_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/ipv6-static-route') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6IpPortFilter.name}
                            id={MenuID.Ipv6IpPortFilter.name}
                            label={<Translator path="menu.IPV6_IP_PORT_FILTER"></Translator>} description={<Translator path="menu.IPV6_IP_PORT_FILTER_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/ipv6-ip-port-filter') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={NetworksIcon} iconSize='20px'
                            key={MenuID.Ipv6PrefixDelegation.name}
                            id={MenuID.Ipv6PrefixDelegation.name}
                            label={<Translator path="menu.IPV6_PREFIX_DELEGATION"></Translator>} description={<Translator path="menu.IPV6_PREFIX_DELEGATION_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/ipv6/prefix-delegation') }}
                        ></DisplayableMenuEntry>,

                        /*IPV6_TAG*/
                    ]}
                ></DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={PONIcon} iconSize='20px'
                    id={MenuID.Pon.name}
                    label={<Translator path="menu.PON"></Translator>} description={<Translator path="menu.PON_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={PONIcon} iconSize='20px'
                            key={MenuID.PonCounters.name}
                            id={MenuID.PonCounters.name}
                            label={<Translator path="menu.PON_COUNTERS"></Translator>} description={<Translator path="menu.PON_COUNTERS_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/pon/counters') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PONIcon} iconSize='20px'
                            key={MenuID.PonStats.name}
                            id={MenuID.PonStats.name}
                            label={<Translator path="menu.PON_STATS"></Translator>} description={<Translator path="menu.PON_STATS_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/pon/stats') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PONIcon} iconSize='20px'
                            key={MenuID.PonSettings.name}
                            id={MenuID.PonSettings.name}
                            label={<Translator path="menu.PON_SETTINGS"></Translator>} description={<Translator path="menu.PON_SETTINGS_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/pon/settings') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PONIcon} iconSize='20px'
                            key={MenuID.PonStatus.name}
                            id={MenuID.PonStatus.name}
                            label={<Translator path="menu.PON_STATUS"></Translator>} description={<Translator path="menu.PON_STATUS_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/pon/status') }}
                        ></DisplayableMenuEntry>,

                        <PONState key='pon-state'>
                            <PONContext.Consumer>
                                {({ currentPONMode }) => (
                                    currentPONMode() === 'epon' ?
                                        [
                                            <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                                                key={MenuID.PonEponLlidStatus.name}
                                                id={MenuID.PonEponLlidStatus.name}
                                                label={<Translator path="menu.PON_EPON_LLID_STATUS"></Translator>} description={<Translator path="menu.PON_EPON_LLID_STATUS_DESCRIPTION"></Translator>}
                                                onClick={() => { handleMenu('/pon/epon-llid-status') }}
                                            ></DisplayableMenuEntry>,
                                        ]
                                        :
                                        [
                                            <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                                                key={MenuID.PonGponStatus.name}
                                                id={MenuID.PonGponStatus.name}
                                                label={<Translator path="menu.PON_GPON_STATUS"></Translator>} description={<Translator path="menu.PON_GPON_STATUS_DESCRIPTION"></Translator>}
                                                onClick={() => { handleMenu('/pon/gpon-status') }}
                                            ></DisplayableMenuEntry>,

                                            <DisplayableMenuEntry IconComponent={WifiIcon} iconSize='20px'
                                                key={MenuID.PonGemPortStatistics.name}
                                                id={MenuID.PonGemPortStatistics.name}
                                                label={<Translator path="menu.PON_GEM_PORT_STATISTICS"></Translator>} description={<Translator path="menu.PON_GEM_PORT_STATISTICS_DESCRIPTION"></Translator>}
                                                onClick={() => { handleMenu('/pon/gem-port-statistics') }}
                                            ></DisplayableMenuEntry>
                                        ]
                                )}
                            </PONContext.Consumer>
                        </PONState>,

                        /*PON_TAG*/
                    ]}
                ></DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={PhoneIcon} iconSize='20px'
                    id={MenuID.Voip.name}
                    label={<Translator path="menu.VOIP"></Translator>} description={<Translator path="menu.VOIP_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={PhoneIcon} iconSize='20px'
                            key={MenuID.VoipProxy.name}
                            id={MenuID.VoipProxy.name}
                            label={<Translator path="menu.VOIP_ACCOUNT_CONFIG"></Translator>} description={<Translator path="menu.VOIP_ACCOUNT_CONFIG_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/voip/proxy') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PhoneIcon} iconSize='20px'
                            key={MenuID.VoipHistoryCall.name}
                            id={MenuID.VoipHistoryCall.name}
                            label={<Translator path="menu.VOIP_HISTORY_CALL"></Translator>} description={<Translator path="menu.VOIP_HISTORY_CALL_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/voip/history-call') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PhoneIcon} iconSize='20px'
                            key={MenuID.VoipDialPlan.name}
                            id={MenuID.VoipDialPlan.name}
                            label={<Translator path="menu.VOIP_DIAL_PLAN"></Translator>} description={<Translator path="menu.VOIP_DIAL_PLAN_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/voip/dial-plan') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PhoneIcon} iconSize='20px'
                            key={MenuID.VoipAdvancedSip.name}
                            id={MenuID.VoipAdvancedSip.name}
                            label={<Translator path="menu.VOIP_ADVANCED_SIP"></Translator>} description={<Translator path="menu.VOIP_ADVANCED_SIP_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/voip/advanced-sip') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PhoneIcon} iconSize='20px'
                            key={MenuID.VoipCodec.name}
                            id={MenuID.VoipCodec.name}
                            label={<Translator path="menu.VOIP_CODEC"></Translator>} description={<Translator path="menu.VOIP_CODEC_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/voip/codec') }}
                        ></DisplayableMenuEntry>,

                        /*VOIP_TAG*/
                    ]}
                ></DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={DeviceIcon} iconSize='20px'
                    id={MenuID.ParentalControl.name}
                    label={<Translator path="menu.PARENTAL_CONTROL"></Translator>} description={<Translator path="menu.PARENTAL_CONTROL_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={DeviceIcon} iconSize='20px'
                            key={MenuID.ParentalControlConnectedDevices.name}
                            id={MenuID.ParentalControlConnectedDevices.name}
                            label={<Translator path="menu.CONNECTED_DEVICES"></Translator>} description={<Translator path="menu.CONNECTED_DEVICES_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/parental-control/connected-devices') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={DeviceIcon} iconSize='20px'
                            key={MenuID.ParentalControlSub.name}
                            id={MenuID.ParentalControlSub.name}
                            label={<Translator path="menu.PARENTAL_CONTROL"></Translator>} description={<Translator path="menu.PARENTAL_CONTROL_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/parental-control/parental-control') }}
                        ></DisplayableMenuEntry>,

                        /*PARENTAL_CONTROL_TAG*/
                    ]}
                ></DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                    id={MenuID.Firewall.name}
                    label={<Translator path="menu.FIREWALL"></Translator>} description={<Translator path="menu.FIREWALL_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallAlg.name}
                            id={MenuID.FirewallAlg.name}
                            label={<Translator path="menu.FIREWALL_ALG"></Translator>} description={<Translator path="menu.FIREWALL_ALG_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/alg') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallUrlBlocking.name}
                            id={MenuID.FirewallUrlBlocking.name}
                            label={<Translator path="menu.URL_BLOCKING"></Translator>} description={<Translator path="menu.URL_BLOCKING_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/url_blocking') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallMacFiltering.name}
                            id={MenuID.FirewallMacFiltering.name}
                            label={<Translator path="menu.MAC_FILTER"></Translator>} description={<Translator path="menu.MAC_FILTER_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/mac_filtering') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallIpPortFiltering.name}
                            id={MenuID.FirewallIpPortFiltering.name}
                            label={<Translator path="menu.IP_PORT_FILTER"></Translator>} description={<Translator path="menu.IP_PORT_FILTER_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/ip_port_filtering') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallDomainBlocking.name}
                            id={MenuID.FirewallDomainBlocking.name}
                            label={<Translator path="menu.DOMAIN_BLOCKING"></Translator>} description={<Translator path="menu.DOMAIN_BLOCKING_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/domain_blocking') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallPortForwarding.name}
                            id={MenuID.FirewallPortForwarding.name}
                            label={<Translator path="menu.PORT_FORWARD"></Translator>} description={<Translator path="menu.PORT_FORWARD_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/port_forwarding') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallDmz.name}
                            id={MenuID.FirewallDmz.name}
                            label={<Translator path="menu.FIREWALL_DMZ"></Translator>} description={<Translator path="menu.FIREWALL_DMZ_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/dmz') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={LockActiveIcon} iconSize='20px'
                            key={MenuID.FirewallWlanAccessControl.name}
                            id={MenuID.FirewallWlanAccessControl.name}
                            label={<Translator path="menu.FIREWALL_WLAN_ACCESS_CONTROL"></Translator>} description={<Translator path="menu.WLAN_ACCESS_CONTROL_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/firewall/wlan-access-control') }}
                        ></DisplayableMenuEntry>,

                        /*FIREWALL_TAG*/
                    ]}
                >
                </DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={ToolsIcon} iconSize='20px'
                    id={MenuID.Tools.name}
                    label={<Translator path="menu.TOOLS"></Translator>} description={<Translator path="menu.TOOLS_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={ToolsIcon} iconSize='20px' key='ping'
                            id={MenuID.ToolsPing.name}
                            label={<Translator path="menu.PING"></Translator>} description={<Translator path="menu.PING_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/tools/ping') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={ToolsIcon} iconSize='20px' key='tr069-inform'
                            id={MenuID.ToolsTr069Inform.name}
                            label={<Translator path="menu.TR069_INFORM"></Translator>} description={<Translator path="menu.TR069_INFORM_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/tools/tr069-inform') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={ToolsIcon} iconSize='20px' key='traceroute'
                            id={MenuID.ToolsTraceroute.name}
                            label={<Translator path="menu.TRACEROUTE"></Translator>} description={<Translator path="menu.TRACEROUTE_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/tools/traceroute') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={ToolsIcon} iconSize='20px' key='loop-back-detection'
                            id={MenuID.ToolsLoopbackDetect.name}
                            label={<Translator path="menu.LOOP_BACK_DETECT"></Translator>} description={<Translator path="menu.LOOP_BACK_DETECT_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/tools/loop-back-detect') }}
                        ></DisplayableMenuEntry>,

                        /*TOOLS_TAG*/
                    ]}
                ></DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={PacketsIcon} iconSize='20px'
                    id={MenuID.Service.name}
                    label={<Translator path="menu.SERVICE"></Translator>} description={<Translator path="menu.SERVICE_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={PacketsIcon} iconSize='20px'
                            id={MenuID.ServiceProxyIgmp.name}
                            key={MenuID.ServiceProxyIgmp.name}
                            label={<Translator path="menu.PROXY_IGMP"></Translator>} description={<Translator path="menu.PROXY_IGMP_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/service/proxy_igmp') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PacketsIcon} iconSize='20px'
                            id={MenuID.ServiceUpnp.name}
                            key={MenuID.ServiceUpnp.name}
                            label={<Translator path="menu.UPNP"></Translator>} description={<Translator path="menu.UPNP_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/service/upnp') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PacketsIcon} iconSize='20px'
                            id={MenuID.ServiceDynamicDns.name}
                            key={MenuID.ServiceDynamicDns.name}
                            label={<Translator path="menu.DYNAMIC_DNS"></Translator>} description={<Translator path="menu.DYNAMIC_DNS_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/service/dynamic-dns') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={PacketsIcon} iconSize='20px'
                            id={MenuID.ServiceRipConfig.name}
                            key={MenuID.ServiceRipConfig.name}
                            label={<Translator path="menu.RIP_CONFIG"></Translator>} description={<Translator path="menu.RIP_CONFIG_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/service/rip-config') }}
                        ></DisplayableMenuEntry>,

                        /*SERVICE_TAG*/
                    ]}
                >
                </DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px'
                    id={MenuID.Advanced.name}
                    label={<Translator path="menu.ADVANCED"></Translator>} description={<Translator path="menu.ADVANCED_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='neighbour-host'
                            id={MenuID.AdvancedNeighbourHosts.name}
                            label={<Translator path="menu.NEIGHBOUR_HOSTS"></Translator>} description={<Translator path="menu.NEIGHBOUR_HOSTS_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/neighbour-hosts') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='static-routing'
                            id={MenuID.AdvancedStaticRouting.name}
                            label={<Translator path="menu.STATIC_ROUTING"></Translator>} description={<Translator path="menu.STATIC_ROUTING_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/static-routing') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='qos-classification'
                            id={MenuID.AdvancedQosClassification.name}
                            label={<Translator path="menu.QOS_CLASSIFICATION"></Translator>} description={<Translator path="menu.QOS_CLASSIFICATION_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/qos-classification') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='qos-policy'
                            id={MenuID.AdvancedQosPolicy.name}
                            label={<Translator path="menu.ADVANCED_QOS_POLICY"></Translator>} description={<Translator path="menu.ADVANCED_QOS_POLICY_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/qos-policy') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='qos-traffic-control'
                            id={MenuID.AdvancedQosTrafficControl.name}
                            label={<Translator path="menu.QOS_TRAFFIC_CONTROL"></Translator>} description={<Translator path="menu.QOS_TRAFFIC_CONTROL_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/qos-traffic-control') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='global-routing'
                            id={MenuID.AdvancedGlobalRouting.name}
                            label={<Translator path="menu.GLOBAL_ROUTING"></Translator>} description={<Translator path="menu.GLOBAL_ROUTING_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/global-routing') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='bridging'
                            id={MenuID.AdvancedBridging.name}
                            label={<Translator path="menu.BRIDGING"></Translator>} description={<Translator path="menu.BRIDGING_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/bridging') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={SliderControlIcon} iconSize='20px' key='igmp-mld-snooping'
                            id={MenuID.AdvancedIgmpMldSnooping.name}
                            label={<Translator path="menu.IGMP_MLD_SNOOPING"></Translator>} description={<Translator path="menu.IGMP_MLD_SNOOPING_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/advanced/igmp-mld-snooping') }}
                        ></DisplayableMenuEntry>,

                        /*ADVANCED_TAG*/
                    ]}>
                </DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px'
                    id={MenuID.System.name}
                    label={<Translator path="menu.SYSTEM"></Translator>} description={<Translator path="menu.SYSTEM_DESCRIPTION"></Translator>}
                    subEntries={[

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='user'
                            id={MenuID.SystemUsers.name}
                            label={<Translator path="menu.USER_AND_PASSWORD"></Translator>} description={<Translator path="menu.USER_AND_PASSWORD_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/users') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='acl-admin'
                            id={MenuID.SystemAclAdmin.name}
                            label={<Translator path="menu.ACL_ADMIN"></Translator>} description={<Translator path="menu.ACL_ADMIN_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/acl_admin') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='tr069-config'
                            id={MenuID.SystemTr069Config.name}
                            label={<Translator path="menu.TR069_CONFIG"></Translator>} description={<Translator path="menu.TR069_CONFIG_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/tr069-config') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='leds'
                            id={MenuID.SystemLeds.name}
                            label={<Translator path="menu.LEDS"></Translator>} description={<Translator path="menu.LEDS_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/leds') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='reboot'
                            id={MenuID.SystemReboot.name}
                            label={<Translator path="menu.REBOOT"></Translator>} description={<Translator path="menu.REBOOT_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/reboot') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='restore'
                            id={MenuID.SystemRestore.name}
                            label={<Translator path="menu.FACTORY_RESET"></Translator>} description={<Translator path="menu.FACTORY_RESET_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/restore') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='backup_restore'
                            id={MenuID.SystemBackupRestore.name}
                            label={<Translator path="menu.BACKUP_RESTORE"></Translator>} description={<Translator path="menu.BACKUP_RESTORE_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/backup_restore') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='change-to-firmware-version'
                            id={MenuID.SystemChangeFirwareVersion.name}
                            label={<Translator path="menu.CHANGE_TO_FIRMWARE_VERSION"></Translator>} description={<Translator path="menu.CHANGE_TO_FIRMWARE_VERSION_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/change_to_firmware_version') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='update'
                            id={MenuID.SystemUpdate.name}
                            label={<Translator path="menu.UPDATE_FIRMWARE"></Translator>} description={<Translator path="menu.UPDATE_FIRMWARE_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/update') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='datetime'
                            id={MenuID.SystemDateTime.name}
                            label={<Translator path="menu.DATETIME"></Translator>} description={<Translator path="menu.DATETIME_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/datetime') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='automatic-maintenance'
                            id={MenuID.SystemAutomaticMaintenance.name}
                            label={<Translator path="menu.AUTOMATIC_MAINTENANCE"></Translator>} description={<Translator path="menu.AUTOMATIC_MAINTENANCE_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/automatic-maintenance') }}
                        ></DisplayableMenuEntry>,

                        <DisplayableMenuEntry IconComponent={CogIcon} iconSize='20px' key='information'
                            id={MenuID.SystemInformation.name}
                            label={<Translator path="menu.INFORMATION"></Translator>} description={<Translator path="menu.INFORMATION_DESCRIPTION"></Translator>}
                            onClick={() => { handleMenu('/system/information') }}
                        ></DisplayableMenuEntry>,

                        /*SYSTEM_TAG*/
                    ]}
                ></DisplayableMenuEntry>
            }

            {
                <DisplayableMenuEntry IconComponent={LogoutIcon} iconSize='20px'
                    id={MenuID.Logout.name}
                    label={<Translator path="menu.LOGOUT"></Translator>} description={<Translator path="menu.LOGOUT_DESCRIPTION"></Translator>}
                    onClick={logout}
                ></DisplayableMenuEntry>
            }

        </div >)


}
// Importando as dependências
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translations from './locales'

if(!localStorage.getItem('language')) {
  localStorage.setItem('language', 'pt-BR')
}

// Intelbras i18n configuration
const i18nConfig = {
  resources: translations,  // resources: Intelbras translations
  fallbackLng: localStorage.getItem('language') || 'pt-BR',     // fallbackLng: Language to use in case auto-detection fails
  defaultNS: 'translations' // defaultNS: default namespace
}

i18n
  .use(initReactI18next) // i18Next package specific for React
  .init(i18nConfig) // Intelbras configurations

  export default i18n
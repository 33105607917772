import React, { useState, useEffect, useContext } from 'react'
import Button from '../../../components/button/button'

import Modal, { ModalTypes } from '../../../components/modal/modal'
import DefaultStatusModals from '../../../components/modal/default-status-modals'
import { BackendContext } from '../../../backend/backend'
import { useHistory } from 'react-router-dom'
import { GlobalsContext } from '../../globals-context'
import createAction from '../../resources/action'
import waitRouter from '../../utils/wait-router'

import Translator from '../../common/components/translator/translator';

export default function SystemRestore() {

    let [success, setSuccess] = useState(false)
    let [restored, setRestored] = useState(false)
    let [error, setError] = useState(false)
    let [saving, setSaving] = useState(false)
    let [restoring, setRestoring] = useState(false)
    let [confirm, setConfirm] = useState(false)

    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const history = useHistory()

    const RESET_ACTION = 2;

    useEffect(() => {

        // eslint-disable-next-line
    }, [])

    let confirmReset = () => {

        setConfirm(true)
    }

    let reset = async () => {

        if (restoring) return

        setRestoring(true)

        let ok = await createAction(backend, { actionID: RESET_ACTION })
        if (!ok) {
            setRestoring(false)
            setError(true)
            return
        }

        backend.setDriverBaseUrl(process.env.FACTORY_URL)
        ok = await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history
        )

        if (!ok) {
            setRestoring(false)
            setError(true)
            return
        }

        setRestoring(false)
        setRestored(true)

    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
        setSaving(false)
        setConfirm(false)
        setRestoring(false)
        setRestored(false)
        history.push('/login')
        window.location.reload()
    }

    let continueReset = (confirmed) => {

        setConfirm(false)

        if (confirmed) {
            reset()
        }
    }

    return <>
        <div id='restore-page' className='info text align-center'>
            <Modal
                modalID='restore'
                title={<Translator path="menu.FACTORY_RESET"></Translator>}
                show={confirm}
                content={
                    <div style={{ maxWidth: '420px' }}>
                        <div>
                            <Translator path="system.factory_reset.warning.EXPLAINING_FACTORY_RESET"></Translator>
                        </div>
                        <div>
                            <Translator path="system.factory_reset.warning.ACTION_CANNOT_BE_UNDONE"></Translator>
                        </div>
                        <br></br>
                        <b>
                            <Translator path="system.factory_reset.warning.CONFIRMATION_TO_RESET"></Translator>
                        </b>
                    </div>
                }
                type={ModalTypes.CONFIRM_WARNING}
                onDismissClick={continueReset}
                dismissText={<Translator path="system.factory_reset.label.DONT_RESTORE"></Translator>}
                confirmText={<Translator path="system.factory_reset.label.DO_RESTORE"></Translator>}
            ></Modal>

            <div className='subtitle'><Translator path="menu.FACTORY_RESET"></Translator></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="system.factory_reset.title.RESTORE_SYSTEM"></Translator></div>

                <div className='info-text'>
                    <Translator path="system.factory_reset.title.RESTORE_SYSTEM_BRIEF"></Translator>

                    <DefaultStatusModals
                        success={success}
                        error={error}
                        saving={saving}
                        continueFn={dismiss}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}>
                    </DefaultStatusModals>

                    <Modal
                        modalID='restoring'
                        title={<Translator path="system.factory_reset.title.RESTORING"></Translator>}
                        show={restoring}
                        content={<React.Fragment>
                            <Translator path="system.factory_reset.warning.WAIT_A_FEW_SECONDS"></Translator>
                            <br></br>
                            <Translator path="system.factory_reset.warning.RECONNECT"></Translator>
                        </React.Fragment>}
                        type={ModalTypes.LOADING}
                    ></Modal>

                    <Modal
                        modalID='restored'
                        show={restored}
                        content={<React.Fragment>
                            <div>{<Translator path="system.factory_reset.warning.RESTORED"></Translator>}</div>
                        </React.Fragment>}
                        dismissText={<Translator path="system.factory_reset.label.UNDERSTOOD"></Translator>}
                        onDismissClick={dismiss}
                        type={ModalTypes.SUCCESS}
                    ></Modal>
                </div>

                <Button
                    id='btn-restore'
                    text={<Translator path="menu.FACTORY_RESET"></Translator>}
                    type='submit'
                    onClick={confirmReset}
                ></Button>
            </div>
        </div>
    </>

}


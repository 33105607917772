import React from 'react'

// Resources
import { terms_of_use_pt_br } from './terms_of_use/terms_of_use_pt_br';
import { terms_of_use_es } from './terms_of_use/terms_of_use_es';

export default function SystemTermsOfUseText(i18n) {

    const getTermsOfUse = (language) => {
        switch (language) {
            case "es":
                return terms_of_use_es
            case "pt-br":
            default:
                return terms_of_use_pt_br
        }
    }

    return <div className='info-text'>
        <div key={i18n.language} dangerouslySetInnerHTML={
            { 
                __html: getTermsOfUse(i18n.language.toLowerCase()).replaceAll("$ONU.MODEL", sessionStorage.getItem('onu_model'))
            }
        } ></div>
    </div>
}

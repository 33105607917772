import React, { useState, useEffect, useContext } from 'react'

import './pon-status.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchPONStatus } from '../../../resources/pon-status';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard'
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function PONStatus({isWizard, setSaved, isFormSegment, formSegment}) {
    const [ponStatus, setPONStatus] = useState(null)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchPONStatus(backend, setPONStatus)

        // eslint-disable-next-line
    }, [])

    const adjustDecimalPlaces = (value, precision = 2) => {
        const nr = Number(value)
        return isNaN(nr) ? '-' : nr.toFixed(precision).toString().replace('.', ',');
    }

    const ponStatusForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="common.label.STATUS"></Translator></div>
            <InfoCard
                lines={[
                    {label: <Translator path="pon.status.label.CURRENT_MODE"></Translator>, value: <span className="pon-status-mode-label"> { ponStatus.current_mode } </span>, id: 'card-pon-status-current-mode'},
                    {label: <Translator path="pon.status.label.TX_SIGNAL_STRENGTH"></Translator>, value: adjustDecimalPlaces(ponStatus.tx_power), id: 'card-pon-status-tx-power'},
                    {label: <Translator path="pon.status.label.RX_SIGNAL_STRENGTH"></Translator>, value: adjustDecimalPlaces(ponStatus.rx_power), id: 'card-pon-status-rx-power'},
                    {label: <Translator path="pon.status.label.VOLTAGE"></Translator>, value: adjustDecimalPlaces(ponStatus.voltage), id: 'card-pon-status-voltage'},
                    {label: <Translator path="pon.status.label.POLARIZATION_CURRENT"></Translator>, value: adjustDecimalPlaces(ponStatus.bias_current), id: 'card-pon-status-bias-current'},
                    {label: <Translator path="pon.status.label.TEMPERATURE"></Translator>, value: adjustDecimalPlaces(ponStatus.temperature, 1), id: 'card-pon-status-temperature'}
                ]}
            ></InfoCard>
            <div className='section-divider'></div>
            <Button
                id='button-refresh'
                type='button'
                text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                onClick={async () => {
                    await fetchPONStatus(backend, setPONStatus)}
                }
            ></Button>
        </React.Fragment>
    }

    return (
        !ponStatus ? <Loading show={true}></Loading> :

        <div id='pon-status-page' className='with-tabs'>

            { ponStatusForm() }

        </div>
    )
}

import { backendStatus } from '../../backend/backend'

export const firewallUrlBlockingConstants = {
    MAX_KEYWORDS_ELEMENTS: 8,
    MAX_KEYWORD_LEN: 19,
    MAX_URLS_ELEMENTS: 8,
    MAX_URL_LEN: 127

}


export async function fetchFirewallUrlBlocking(backend, setFirewallUrlBlocking) {
    let result = await backend.retrieveFresh('firewall_url_blocking')

    if(result.status === backendStatus.SUCCESS){

        let firewallUrlBlockingList = result.content

        let firewallUrlBlocking = firewallUrlBlockingList[0]

        setFirewallUrlBlocking(firewallUrlBlocking)
    }
}

export async function saveFirewallUrlBlocking(backend, firewallUrlBlocking, setErrorMessage) {
    try{

        let result = null;

        result = await backend.update(`firewall_url_blocking/${firewallUrlBlocking.id}`, firewallUrlBlocking)
        if(result.status !== backendStatus.SUCCESS) {
            
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Firewall Url Blocking:', error)

        return false
    }

}
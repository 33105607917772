import { backendStatus } from '../../backend/backend'

const URL = 'qos_traffic_control';

export async function fetchAdvancedQosTrafficControl(backend, id, setQosTrafficControl) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setQosTrafficControl(result.content)
    }
    
}

export async function fetchAdvancedQosTrafficControlList(backend, setQosTrafficControlList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setQosTrafficControlList(result.content)
    }
}

export async function deleteAdvancedQosTrafficControlById(backend, id, setQosTrafficControlList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchAdvancedQosTrafficControlList(backend, setQosTrafficControlList)
    }
}

export async function saveAdvancedQosTrafficControl(backend, qosTrafficControl, setErrorMessage) {

    try{

        let result = null;

        if(!qosTrafficControl.id) {
            result = await backend.create(`${URL}`, qosTrafficControl)
        }
        else {
            result = await backend.update(`${URL}/${qosTrafficControl.id}`, qosTrafficControl)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || qosTrafficControl)

    }catch(error){

        console.error('Error saving QOS_TRAFFIC_CONTROL:', error)

        return false
    }

}
import React from 'react'
import Input from '../../../../components/input/input'
import Loading from '../../../../components/loading/loading'
import Checkbox from '../../../../components/checkbox/checkbox';


import extraValidators from '../../../common/validators';
import Translator from '../../../common/components/translator/translator';
import { LimitToMaxSafeInteger } from '../../../utils/common';

export default function IPv6RadvdPrefix(ipv6Radvd, setIPv6Radvd) {
    
    const validGreaterThanPreferValidator = value => {
        return (ipv6Radvd.ipv6_prefix.adv_valid_lifetime >= ipv6Radvd.ipv6_prefix.adv_preferred_lifetime ? "" : 
        <Translator path="common.message.error.VALIDATE_IPV6_VALID_TIME"></Translator>)
    }

    const shouldValidateRadvdPrefix = () => {
        return !ipv6Radvd.ipv6_prefix_mode_auto
    }
    
    return (
        !ipv6Radvd.ipv6_prefix ? <Loading show={true}></Loading> :
        <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="ipv6.radvd.title.PREFIX"></Translator></div>

            <Checkbox id='ipv6-radvd-ipv6-prefix-mode-auto'
                name='ipv6_prefix_mode_auto'
                label={<Translator path="ipv6.radvd.label.IPV6_PREFIX_MODE_AUTO"></Translator>}
                value={ipv6Radvd.ipv6_prefix_mode_auto}
                clearErrors={true}
                toggleFn={(e) => {
                    ipv6Radvd.ipv6_prefix_mode_auto = !ipv6Radvd.ipv6_prefix_mode_auto;
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[]}
            ></Checkbox>
            
            <Input id='ipv6-radvd-ipv6-prefix-prefix'
                name='prefix'
                label={<Translator path="ipv6.radvd.label.PREFIX"></Translator>}
                value={ipv6Radvd.ipv6_prefix.prefix}
                onChange={(e) => {
                    ipv6Radvd.ipv6_prefix.prefix = e.target.value
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateRadvdPrefix,
                        validators: [
                            {fn: extraValidators.required, params: ''},
                            {fn: extraValidators.validateIPv6, params: ''},
                            {fn: extraValidators.validateIfNotLocalhost, params: ''},
                        ]
                    }}, 
                ]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Input>

            <Input id='ipv6-radvd-prefix-prefix-size'
                name='prefix_size'
                label={<Translator path="ipv6.radvd.label.PREFIX_SIZE"></Translator>}
                value={ipv6Radvd.ipv6_prefix.prefix_size}
                onChange={(e) => {
                    ipv6Radvd.ipv6_prefix.prefix_size = LimitToMaxSafeInteger(e.target.value)
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateRadvdPrefix,
                        validators: [
                            {fn: extraValidators.nonASCII, params: ''},
                            {fn: extraValidators.isNumber, params: ''},
                            {fn: extraValidators.value, params: {min: 32, max: 64}},
                        ]
                    }}, 
                ]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Input>

            <Input id='ipv6-radvd-prefix-adv-valid-lifetime'
                name='adv_valid_lifetime'
                label={<Translator path="ipv6.radvd.label.ADV_VALID_LIFETIME"></Translator>}
                value={ipv6Radvd.ipv6_prefix.adv_valid_lifetime}
                onChange={(e) => {
                    ipv6Radvd.ipv6_prefix.adv_valid_lifetime = LimitToMaxSafeInteger(e.target.value)
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateRadvdPrefix,
                        validators: [
                            {fn: extraValidators.nonASCII, params: ''},
                            {fn: extraValidators.isNumber, params: ''},
                            {fn: extraValidators.value, params: {min: 0, max: 4294967295}}
                        ]
                    }}, 
                ]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Input>

            <Input id='ipv6-radvd-prefix-adv-preferred-lifetime'
                name='adv_preferred_lifetime'
                label={<Translator path="ipv6.radvd.label.ADV_PREFERRED_LIFETIME"></Translator>}
                value={ipv6Radvd.ipv6_prefix.adv_preferred_lifetime}
                onChange={(e) => {
                    ipv6Radvd.ipv6_prefix.adv_preferred_lifetime = LimitToMaxSafeInteger(e.target.value)
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateRadvdPrefix,
                        validators: [
                            {fn: extraValidators.nonASCII, params: ''},
                            {fn: extraValidators.isNumber, params: ''},
                            {fn: extraValidators.value, params: {min: 0, max: 4294967295}},
                            {fn: validGreaterThanPreferValidator, params: ''},
                        ]
                    }}, 
                ]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto, ipv6Radvd.ipv6_prefix.adv_valid_lifetime]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Input>

            <Checkbox id='ipv6-radvd-prefix-adv-on-link'
                name='adv_on_link'
                label={<Translator path="ipv6.radvd.label.ADV_ON_LINK"></Translator>}
                value={ipv6Radvd.ipv6_prefix.adv_on_link}
                toggleFn={(e) => {
                    ipv6Radvd.ipv6_prefix.adv_on_link = !ipv6Radvd.ipv6_prefix.adv_on_link;
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Checkbox>

            <Checkbox id='ipv6-radvd-prefix-adv-autonomous'
                name='adv_autonomous'
                label={<Translator path="ipv6.radvd.label.ADV_AUTONOMOUS"></Translator>}
                value={ipv6Radvd.ipv6_prefix.adv_autonomous}
                toggleFn={(e) => {
                    ipv6Radvd.ipv6_prefix.adv_autonomous = !ipv6Radvd.ipv6_prefix.adv_autonomous;
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Checkbox>

            <Input id='ipv6-radvd-ipv6-prefix-rdnss1'
                name='rdnss1'
                label={<Translator path="ipv6.radvd.label.RDNSS1"></Translator>}
                value={ipv6Radvd.ipv6_prefix.rdnss1}
                onChange={(e) => {
                    ipv6Radvd.ipv6_prefix.rdnss1 = e.target.value
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateRadvdPrefix,
                        validators: [
                            {fn: extraValidators.validateIPv6, params: ''},
                            {fn: extraValidators.validateIfNotLocalhost, params: ''},
                        ]
                    }}, 
                ]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Input>

            <Input id='ipv6-radvd-ipv6-prefix-rdnss2'
                name='rdnss2'
                label={<Translator path="ipv6.radvd.label.RDNSS2"></Translator>}
                value={ipv6Radvd.ipv6_prefix.rdnss2}
                onChange={(e) => {
                    ipv6Radvd.ipv6_prefix.rdnss2 = e.target.value
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateRadvdPrefix,
                        validators: [
                            {fn: extraValidators.validateIPv6, params: ''},
                            {fn: extraValidators.validateIfNotLocalhost, params: ''},
                        ]
                    }}, 
                ]}
                dependentValues={[ipv6Radvd.ipv6_prefix_mode_auto]}
                disabled={ipv6Radvd.ipv6_prefix_mode_auto}
            ></Input>

            
        </React.Fragment>
    )
}
import { backendStatus } from '../../backend/backend'



export async function fetchCustomDefaultConfig(backend, setCustomDefaultConfig) {
    let result = await backend.retrieveFresh('custom_default_config')

    if(result.status === backendStatus.SUCCESS){

        let customDefaultConfigList = result.content

        let customDefaultConfig = customDefaultConfigList[0]
        setCustomDefaultConfig(customDefaultConfig?.enabled)
        return true
    }

    return false
}


export async function updateCustomDefaultConfig(backend, customDefaultConfig, setErrorMessage) {

    try {
        let result = await backend.update(`custom_default_config/0`, customDefaultConfig)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS
        
    }catch(error){
        console.error('Error updating customDefaultConfig:', error)
        return false
    }
}
import React, { useState, useEffect, useContext } from 'react'

import './static-routing.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchStaticRouting, deleteStaticRouting } from '../../../resources/static-routing';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import RemoveIcon from '../../../../components/icons/remove';
import EditIcon from '../../../../components/icons/edit';
import Button from '../../../../components/button/button'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import { AdvancedContext } from '../advanced-context';

export default function StaticRoutingEdit({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [staticRoutingPagination, setStaticRoutingPagination] = useState({pageNumber: 1, pageSize: 10})
    const { staticRoutingElementsList, setStaticRoutingElementsList } = useContext(AdvancedContext)
    const backend = useContext(BackendContext)
    const history = useHistory()
    const { t } = useTranslation()

    useEffect(() => {
        fetchStaticRouting(backend, setStaticRoutingElementsList)
        // eslint-disable-next-line
    }, [])

    const editRoute = (element) => {
        history.push(`/advanced/static-routing/${element.id}`)
    }

    const removeRoute = (element) => {
        deleteStaticRouting(backend, element.id, setStaticRoutingElementsList)
    }

    const getStaticRoutingColumnsName = () => {
        return [
            {header: 'Nº', align: 'center', size: '20px'},
            {header: t('common.label.ENABLE'), align: 'center'},
            {header: t('advanced.static_routing.label.DESTINATION'), align: 'center'},
            {header: t('advanced.static_routing.label.NET_MASK'), align: 'center'},
            {header: t('advanced.static_routing.label.NEXT_HOP'), align: 'center'},
            {header: t('advanced.static_routing.label.METRIC'), align: 'center'},
            {header: t('advanced.static_routing.label.INTERFACEID'), align: 'center'},
            {header: '', align: 'center'},
        ]
    }
    
    const getStaticRoutingLineElements = () => {
        let begining = (staticRoutingPagination.pageNumber - 1) * (staticRoutingPagination.pageSize)
        let end = begining + staticRoutingPagination.pageSize
        if (end > staticRoutingElementsList.length) {
            end = staticRoutingElementsList.length
        }
        
        let staticRoutingLines = [];
        for(let i = begining; i < end; i++) {
            staticRoutingLines.push(
                [
                    i + 1,
                    staticRoutingElementsList[i].state ? t('advanced.static_routing.label.ENABLED') : 
                                             t('advanced.static_routing.label.DISABLED'),
                    staticRoutingElementsList[i].route.destination,
                    staticRoutingElementsList[i].route.net_mask,
                    staticRoutingElementsList[i].route.next_hop,
                    staticRoutingElementsList[i].route.metric,
                    staticRoutingElementsList[i].route.interfaceID === "any" ? t('advanced.static_routing.label.INTERFACE_ANY') : staticRoutingElementsList[i].route.interfaceID,
                    <div>
                        <span className="clickable" onClick={e => editRoute(staticRoutingElementsList[i])}><EditIcon/></span>
                        <span style={{ paddingInline: 4 }}/>
                        <span className="clickable" onClick={e => removeRoute(staticRoutingElementsList[i])}><RemoveIcon/></span>
                    </div>
                ]);
        }
        return staticRoutingLines;
    }

    const changePage = page => {
        setStaticRoutingPagination({...staticRoutingPagination, pageNumber: page})
    }

    const createNewStaticRouteForms = () => {
        history.push(`/advanced/static-routing/add`)
    }

    return ( !staticRoutingElementsList ? <Loading show={true}></Loading> :

        <div id='static-routing-page' className='with-tabs'>
            <div className='section-divider'></div>
                <div className='subtitle'> 
                    <Translator path="advanced.static_routing.title.STATIC_ROUTING"/>
                </div>
                { staticRoutingElementsList.length === 0 ? 
                    <div id='static-route-no-rules'>
                        <div className='info-card noelements-card'>
                            <Translator path="advanced.static_routing.info.NO_ROUTES"/>
                        </div>
                    </div> 
                :
                <>
                    <dir className="scroll-list" id="static-routing-list">
                        <List className="align-center"
                            lines={getStaticRoutingLineElements()}
                            columns={getStaticRoutingColumnsName()}
                            width='1000px'
                        ></List>
                    </dir>
                    <Pager 
                        pageNumber={staticRoutingPagination.pageNumber}
                        totalElements={staticRoutingElementsList.length}
                        pageSize={staticRoutingPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                </> 
                }
                <Button
                    id='new_static_routing'
                    text={t('advanced.static_routing.label.NEW_ROUTE')}
                    onClick={createNewStaticRouteForms}
                ></Button>
        </div>
    );
}

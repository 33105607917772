import { backendStatus } from '../../backend/backend'

export const ponSettingsConstants = {
    PON_SETTINGS_DEFAULT_MODE: 'auto',
    PON_SETTINGS_DEFAULT_OLT_PROFILE: 0,
    DEFAULT_MIN_SIZE: 1,
    // OMCI Fields
    GPON_ONU_MODEL_MAX_SIZE: 63,
    PON_VENDOR_ID_MAX_SIZE: 4,
    GPON_SN_MAX_SIZE: 12, 
    // OAM Fields
    EPON_ONU_MODEL_MAX_SIZE: 4,
    EPON_EXTONU_MODEL_MAX_SIZE: 16,
    // Security Fields
    LOID_MAX_SIZE: 29,
    LOID_PASSWD_MAX_SIZE: 36,
    GPON_PLOAM_PASSWD_MAX_SIZE: 75
}

export const OLTProfileString = {
    INTELBRAS: "pon.settings.olt_profile.INTELBRAS",
    HUAWEI: "pon.settings.olt_profile.HUAWEI",
    ZTE: "pon.settings.olt_profile.ZTE",
    CUSTOM: "pon.settings.olt_profile.CUSTOM",
    FH_CUSTOM_1: "pon.settings.olt_profile.FH_CUSTOM_1",
    HUAWEI_NOKIA: "pon.settings.olt_profile.HUAWEI_NOKIA"
}

export const OLTProfile = {
    INTELBRAS: 0,
    HUAWEI: 1,
    ZTE: 2,
    CUSTOM: 3,
    FH_CUSTOM_1: 4,
    HUAWEI_NOKIA: 5
}

export const OLTProfileOptions = [
    {value: OLTProfile.INTELBRAS , text: OLTProfileString.INTELBRAS},
    {value: OLTProfile.HUAWEI, text: OLTProfileString.HUAWEI},
    {value: OLTProfile.ZTE, text: OLTProfileString.ZTE},
    {value: OLTProfile.CUSTOM, text: OLTProfileString.CUSTOM},
    {value: OLTProfile.FH_CUSTOM_1, text: OLTProfileString.FH_CUSTOM_1},
    {value: OLTProfile.HUAWEI_NOKIA, text: OLTProfileString.HUAWEI_NOKIA}
]

export async function fetchPONSettings(backend, setPONSettings) {
    let result = await backend.retrieveFresh('pon_settings')

    if(result.status === backendStatus.SUCCESS){

        let ponSettingsList = result.content

        let ponSettings = ponSettingsList.length !== 0 ? ponSettingsList[0] : {
            mode: ponSettingsConstants.PON_SETTINGS_DEFAULT_MODE,
            oam: {
                model_id: '',
                vendor_id: '',
                onu_id_mac: '',
                hardware_version: '',
                olt_profile: ponSettingsConstants.PON_SETTINGS_DEFAULT_OLT_PROFILE
            },
            omci: {
                model_id: '',
                vendor_id: '',
                serial_number: '',

            },
            security: {
                loid: '',
                loid_password: '',
                ploam_password: ''
            },
            isCreate: true
        }

        setPONSettings(ponSettings)
    }
}

export async function savePONSettings(backend, ponSettings) {

    try{

        let result = null;

        if(ponSettings.isCreate)
            result = await backend.create('pon_settings', ponSettings)
        else
            result = await backend.update(`pon_settings/${ponSettings.id}`, ponSettings)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving PON Settings:', error)

        return false
    }

}
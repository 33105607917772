import React, { useState, useEffect, useContext, useRef } from 'react'

import Input from '../../../../components/input/input'
import Checkbox from '../../../../components/checkbox/checkbox'
import Select from '../../../../components/select/select'

import { useParams, useHistory } from 'react-router-dom'
import { constants, cipher, crypto, saveWireless, setWifiFromReduced, getEmptyReducedWifi, getWiFiListFromReduced, createWireless, deleteWireless, ieeeStd, fetchWirelessList } from '../../../resources/wireless'
import { BackendContext } from '../../../../backend/backend'
import Form from '../../../../components/form/form'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import waitRouter from '../../../utils/wait-router'
import { GlobalsContext } from '../../../globals-context'
import InfoPopup from '../../../../components/info-popup/info-popup'
import { NetworksContext } from '../networks-context'
import { fetchRadioList, frequency } from '../../../resources/radio'
import RadioButton from '../../../../components/radio-button/radio'
import { fetchWifiLimits } from '../../../resources/limits'
import FormSegment from '../../../../components/form/form-segment'
import { easyMeshType } from '../../../resources/easy-mesh'
import { MeshContext } from '../mesh/mesh-context'
import './wifi.css'
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next'
import extraValidators from '../../../common/validators'
import Button from '../../../../components/button/button'
import Modal, { ModalTypes } from '../../../../components/modal/modal'

const SecurityModeString = {
    NONE: 'network.wifi.label.SECURITY_MODE_CRYPTO',
    WEP: 'WEP',
    WPA: 'WPA',
    WPA2: 'network.wifi.label.WPA2_RECOMMENDED',
    WPA_WPA2: 'WPA/WPA2',
    WPA3: 'WPA3',
    WPA2_WPA3: 'WPA2/WPA3'
}

export const SecurityMode = [
    { value: cipher.NONE, text: SecurityModeString.NONE },
    // { value: cipher.WEP, text: SecurityModeString.WEP }, // unsupported
    // { value: cipher.WPA, text: SecurityModeString.WPA }, // unsupported
    { value: cipher.WPA2, text: SecurityModeString.WPA2 },
    { value: cipher.WPA_WPA2, text: SecurityModeString.WPA_WPA2 },
    { value: cipher.WPA3, text: SecurityModeString.WPA3 },
    { value: cipher.WPA2_WPA3, text: SecurityModeString.WPA2_WPA3 }
]

export default function WifiEdit({ isWizard, setWizardWifi, /*easyMesh, setEasyMesh,*/ isFormSegment, formSegment }) {

    let [confirm, setConfirm] = useState(false)
    let [confirmPasswordWPS, setConfirmPasswordWPS] = useState(false)
    let [confirmBroadcastWPS, setConfirmBroadcastWPS] = useState(false)
    let [confirmWPA3WPS, setConfirmWPA3WPS] = useState(false)

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [wifi, setWiFi] = useState(null)
    const [limits, setLimits] = useState(null)
    const [radioList, setRadioList] = useState(null)
    const [radio0Enabled, setRadio0Enabled] = useState(null)
    const [freqChanged, setFreqChanged] = useState(false)
    const [wifiPreviousRadioFrequency, setWifiPreviousRadioFrequency] = useState(null)
    const [splitDualBandCheckbox, setSplitDualBandCheckbox] = useState(false)

    const wifiRef = useRef(null)

    const params = useParams()
    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const networks = useContext(NetworksContext)
    const history = useHistory()
    const mesh = useContext(MeshContext)

    const { t } = useTranslation()

    let counters = {}
    if (!isWizard && networks.wifi.value)
        counters = networks.getNumberByFrequency()

    useEffect(() => {

        fetchWifiLimits(backend, setLimits)
        fetchRadioList(backend, setRadioList)

        if (isWizard) {
            setWiFi({ ...getEmptyReducedWifi(), cipher: cipher.WPA2, crypto: crypto.AES, wps: true })
        } else {
            if (!networks.reducedWifi.value) {
                history.push('/network/wifi')
                return
            }

            let id = params.id
            if (id === 'add') {
                let emptyWifi = getEmptyReducedWifi()
                emptyWifi.cipher = cipher.WPA2
                emptyWifi.crypto = crypto.AES

                setWiFi(emptyWifi)
                return
            }

            setWiFi(networks.getReducedWifi(id))
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        wifiRef.current = wifi
        if (isWizard)
            setWizardWifi(wifi)

        // eslint-disable-next-line
    }, [wifi])

    useEffect(() => {
        if (!radioList || wifi)
            return

        for (let radio of radioList) {
            if (radio.enabled === true) {
                if (radio.frequency === frequency._5GHz) {
                    setRadio0Enabled(true)
                    return
                }
            }
        }

        setRadio0Enabled(false)

        // eslint-disable-next-line
    }, [radioList])

    useEffect(() => {

        if (!limits || !wifi || params.id !== 'add' || !networks.isDualBand()) return

        let newWifi = { ...wifi }

        const limitsObj = networks.getLimits(limits, [frequency._2_4GHz, frequency._5GHz])

        if (counters[frequency._2_4GHz] >= limitsObj[frequency._2_4GHz]) {
            newWifi.frequency = frequency._5GHz
        }
        if (counters[frequency._5GHz] >= limitsObj[frequency._5GHz]) {
            newWifi.frequency = frequency._2_4GHz
        }

        newWifi.ieee_std = networks.getWirelessRootIEEEStd(newWifi.frequency)

        setWiFi(newWifi)

        // eslint-disable-next-line
    }, [limits])

    const submitConfirm = async () => {

        if (saving || success || error) return

        submit()
    }

    const submit = async () => {

        if (params.id === 'add') {
            await create()
        } else {
            await save()
        }

        await backend.backendApply()
    }

    let continueConfirm = (confirmed) => {
        setConfirm(false)
        setConfirmPasswordWPS(false)

        if (confirmed) {
            submitConfirm()
        }
    }

    let continueConfirmBroadcastWPS = (confirmed) => {
        setConfirmBroadcastWPS(false)

        if (confirmed) {
            wifi.broadcast = false
            wifi.wps = false
            setWiFi({ ...wifi })
        }
    }

    let continueConfirmWPA3WPS = (confirmed) => {
        setConfirmWPA3WPS(false)

        if (confirmed) {
            wifi.cipher = cipher.WPA3
            wifi.wps = false
            setWiFi({ ...wifi })
        }
    }

    const create = async () => {

        if (saving) return

        if (!networks.isDualBand()) {
            if (radio0Enabled)
                wifi.frequency = frequency._5GHz
            else
                wifi.frequency = frequency._2_4GHz
        }

        setSaving(true)

        let wifiList = getWiFiListFromReduced(wifi, networks)

        for (let net of wifiList) {

            setWifiFromReduced(net, wifi)

            if (net.radioID === networks.getRadioID(frequency._5GHz) && !wifi.sameSSID && wifi.ssid_5) {
                net.ssid = wifi.ssid_5
            }

            net.ieee_std &= networks.RadioOpModeToIeeeStd(networks.getRadio(net.radioID))

            let ok = await createWireless(backend, net)
            if (!ok) {
                setSaving(false)
                setError(true)
                return
            }
        }

        fetchWirelessList(backend, networks.wifi.set)

        await waitReturn()
    }

    const singleBandAdjusted = async (net) => {


        if (wifi.frequency !== frequency.dualband && (net.radioID !== networks.getRadioID(wifi.frequency))) {

            let ok = await deleteWireless(backend, net.id)
            if (!ok) {
                setSaving(false)
                setError(true)
                return false
            }

            if (wifi.id.length === 1) {
                net.radioID = networks.getRadioID(wifi.frequency)
                let ok = await createWireless(backend, net)
                if (!ok) {
                    setSaving(false)
                    setError(true)
                    return false
                }
            }

            return true

        }

        return false
    }

    const save = async () => {

        if (saving) return

        setSaving(true)

        let ids
        if (wifi.isRoot && wifi.frequency === frequency.dualband && wifi.id.length === 1) {
            ids = networks.getRootIDs() // root networks being merged, get all their ids to update them
        } else {
            ids = wifi.id
        }

        for (let id of ids) {

            let net = networks.getSingleWiFi(id)

            setWifiFromReduced(net, wifi)

            if (net.radioID === networks.getRadioID(frequency._5GHz) && !wifi.sameSSID && wifi.ssid_5) {
                net.ssid = wifi.ssid_5
            }

            net.ieee_std &= networks.RadioOpModeToIeeeStd(networks.getRadio(net.radioID))

            if (await singleBandAdjusted(net)) continue

            let ok = await saveWireless(backend, net)
            if (!ok) {
                setSaving(false)
                setError(true)
                return
            }

            if (ids.length === 1 && wifi.frequency === frequency.dualband) {

                let radio = networks.getRadio(net.radioID)
                let missingFreq = (radio.frequency === frequency._2_4GHz ? frequency._5GHz : frequency._2_4GHz)

                net.id = ''
                net.interfaceID = ''
                net.radioID = networks.getRadioID(missingFreq)
                net.ieee_std = networks.RadioOpModeToIeeeStd(networks.getRadio(networks.getRadioID(missingFreq)))

                let ok = await createWireless(backend, net)
                if (!ok) {
                    setSaving(false)
                    setError(true)
                    return
                }
            }
        }

        fetchWirelessList(backend, networks.wifi.set)

        await waitReturn()
    }

    const waitReturn = async () => {

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        setSaving(false)
        setSuccess(true)

        history.push('/network/wifi')
    }

    const dismissModals = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const cannotBeDualband = () => {

        if (!networks.isDualBand() || wifi.frequency === frequency.dualband)
            return false

        const limitsObj = networks.getLimits(limits, [frequency._2_4GHz, frequency._5GHz])

        if (params.id === 'add') {
            return counters[frequency._2_4GHz] >= limitsObj[frequency._2_4GHz] ||
                counters[frequency._5GHz] >= limitsObj[frequency._5GHz]
        }

        if (freqChanged) {
            return false
        }

        let opositeFreq = wifi.frequency === frequency._2_4GHz ? frequency._5GHz : frequency._2_4GHz
        let limit = opositeFreq === frequency._2_4GHz ? limitsObj[frequency._2_4GHz] : limitsObj[frequency._5GHz]

        return counters[opositeFreq] >= limit && !wifi.isRoot
    }

    const cannotBeSelected = (freq) => {
        return counters[freq] >= networks.getLimits(limits)[freq] &&
            (params.id === 'add' || (!freqChanged && wifi.frequency !== freq))
    }

    const reservedSSID = (value) => {
        const reserved_2 = 'INTELBRAS'
        const reserved_5 = 'INTELBRAS_5G'

        if (!wifi.isRoot && [reserved_2, reserved_5].includes(value))
            return <Translator path="network.wifi.message.warning.RESERVED_NAME"></Translator>

        return ''
    }

    const ssidAlreadyUsed = (value) => {
        if (isWizard)
            return ''

        return networks.ssidAlreadyUsed(value, wifiRef.current)
    }

    const isMeshController = () => {
        return mesh.hasMesh() && mesh.isMeshEnabled() && mesh.easyMesh.type === easyMeshType.CONTROLLER
    }

    const sameSSIDWifi = () => {
        return <div id='different-ssid-wifi' className='with-info'>
            <Checkbox name='different-ssid'
                label={<Translator path="network.wifi.label.SPLIT_DUAL"></Translator>}
                value={!wifi.sameSSID}
                toggleFn={() => {
                    setWiFi({ ...wifi, sameSSID: !wifi.sameSSID })
                    setSplitDualBandCheckbox(!splitDualBandCheckbox)
                }}
                validateOnChange={true}
                disabled={isMeshController() || (mesh.isMeshAgent() && mesh.hasBackhaulConnection())}
            ></Checkbox>

            <InfoPopup
                message={<div style={{ width: '400px' }}>
                    {isMeshController() || (mesh.isMeshAgent() && mesh.hasBackhaulConnection()) ?
                        <React.Fragment>
                            <Translator path="network.wifi.message.info_popup.MESH_OFF"></Translator>
                        </React.Fragment> :
                        <React.Fragment>
                            <Translator path="network.wifi.message.info_popup.SPLIT_DUAL"></Translator>
                        </React.Fragment>}
                </div>}
            ></InfoPopup>
        </div>
    }

    const setSSID = (value) => {
        if (!wifi.sameSSID) {
            setWiFi({ ...wifi, ssid: value, ssid_5: value + "_5G" })
        } else {
            setWiFi({ ...wifi, ssid: value })
        }
    }

    let confirmSecurity = () => {
        setConfirm(true)
    }

    let confirmPassword = () => {
        setConfirmPasswordWPS(true)
    }

    let confirmBroadcast = () => {
        setConfirmBroadcastWPS(true)
    }

    let confirmWPA3 = () => {
        setConfirmWPA3WPS(true)
    }

    const sameSplitSSID = (value) => {
        if (wifi.ssid === value) {
            return <Translator path="network.wifi.message.warning.SAME_SPLIT_SSID"></Translator>
        }

        return ''
    }

    const hasPasswordWPSRequirements = () => {
        if (!wifi.isRoot || !wifi.wps) {
            return true;
        }

        if (wifi.key.substring(0, 1) === ' ' || wifi.key.substring(wifi.key.length - 1) === ' ') {
            return false
        }

        return true
    }

    const ieeeStd_config = () => {
        let wifiRootIEEStd = networks.getWirelessRootIEEEStd(wifi.frequency)

        return <div className='ieee_std_checkboxes'>
            {
                Object.entries(ieeeStd).map((std) => {
                    return (wifiRootIEEStd & std[1]) ?
                        <Checkbox
                            id={`ieeeStd-${std[0]}-checkbox`}
                            key={`ieeeStd-${std[0]}-checkbox`}
                            label={std[0]}
                            value={wifi.ieee_std & std[1]}
                            // disabled={!(wifiRootIEEStd & std[1])}
                            toggleFn={() => {
                                setWiFi({ ...wifi, ieee_std: wifi.ieee_std ^ std[1] })
                            }}
                        ></Checkbox>
                        :
                        null
                }
                )
            }
        </div>
    }

    const validateIEEEwithBand = (wifi) => {
        switch (wifi.frequency) {
            case frequency._2_4GHz:
                return Boolean(wifi.ieee_std & networks.getWirelessRootIEEEStd(frequency._2_4GHz))
            case frequency._5GHz:
                return Boolean(wifi.ieee_std & networks.getWirelessRootIEEEStd(frequency._5GHz))
            default:
                return Boolean(wifi.ieee_std & networks.getWirelessRootIEEEStd(frequency._2_4GHz)) && Boolean(wifi.ieee_std & networks.getWirelessRootIEEEStd(frequency._5GHz))
        }
    }

    const wifiForm = () => {
        return <React.Fragment>
            <Input name='ssid'
                id='ssid'
                label={<Translator path={t("network.wifi.label.SSID_NAME")} required={true}></Translator>}
                type='text'
                placeholder={t('network.wifi.label.SSID_NAME')}
                value={wifi.ssid}
                onChange={(e) => { setSSID(e.target.value) }}
                disabled={mesh.isMeshAgent() && mesh.hasBackhaulConnection()}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: constants.MIN_SSID_LENGTH, max: constants.MAX_SSID_LENGTH } },
                    ssidAlreadyUsed,
                    reservedSSID,
                    extraValidators.reservedPrefix
                ]}
            ></Input>

            {
                isWizard || wifi.frequency !== frequency.dualband || !networks.isDualBand() || params.id === "add" || !(wifi.id.length > 1) ?
                    null :
                    sameSSIDWifi()
            }


            {
                wifi.sameSSID || wifi.frequency !== frequency.dualband ? <React.Fragment></React.Fragment> :
                    <Input name='ssid-5'
                        id='ssid-5'
                        label={<Translator path={t("network.wifi.label.SSID_5GHZ_NAME")} required={true}></Translator>}
                        type='text'
                        placeholder={t('network.wifi.label.SSID_5GHZ_NAME')}
                        value={wifi.ssid_5 ? wifi.ssid_5 : ""}
                        onChange={(e) => {
                            setWiFi({ ...wifi, ssid_5: e.target.value })
                        }}
                        validators={[
                            extraValidators.required,
                            extraValidators.nonASCII,
                            { fn: extraValidators.size, params: { min: constants.MIN_SSID_LENGTH, max: constants.MAX_SSID_LENGTH } },
                            ssidAlreadyUsed,
                            reservedSSID,
                            sameSplitSSID
                        ]}
                        dependentValues={[wifi.ssid]}
                    ></Input>

            }

            {
                wifi.isRoot ?
                    <div id='wps-wifi' className='with-info'>

                        <Checkbox name='wps'
                            label={<Translator path="network.wifi.label.WPS_CHECKBOX"></Translator>}
                            value={wifi.wps}
                            toggleFn={() => {
                                setWiFi({ ...wifi, wps: !wifi.wps })
                            }}
                            disabled={wifi.cipher === cipher.WPA3 || !wifi.broadcast}
                        ></Checkbox>

                        <InfoPopup
                            message={<div style={{ width: '400px' }}>
                                <Translator path="network.wifi.message.info_popup.WPS_CHECKBOX"></Translator>
                            </div>}
                        ></InfoPopup>

                    </div> : null
            }

            {
                !isWizard ?
                    <div id='broadcast-wifi' className='with-info'>
                        <Modal
                            modalID='confirm_broadcast_wps'
                            title={<Translator path="menu.WPS_REQUIREMENT"></Translator>}
                            show={confirmBroadcastWPS}
                            content={
                                <div style={{ maxWidth: '420px' }}>
                                    <Translator path="network.wifi.message.warning.EXPLAINING_BROADCAST_WPS"></Translator>
                                </div>
                            }
                            type={ModalTypes.CONFIRM_WARNING}
                            onDismissClick={continueConfirmBroadcastWPS}
                            dismissText={<Translator path="common.label.CANCEL"></Translator>}
                            confirmText={<Translator path="common.label.CONFIRM"></Translator>}
                        ></Modal>

                        <Checkbox name='broadcast'
                            label={<Translator path="network.wifi.label.BROADCAST_CHECKBOX"></Translator>}
                            value={wifi.broadcast}
                            toggleFn={() => {
                                let newStatus = !wifi.broadcast

                                if (newStatus === false && wifi.wps === true) {
                                    confirmBroadcast()
                                }
                                else {
                                    wifi.broadcast = newStatus
                                    setWiFi({ ...wifi })
                                }
                            }}
                        ></Checkbox>

                        <InfoPopup
                            message={<div style={{ width: '400px' }}>
                                <Translator path="network.wifi.message.info_popup.BROADCAST_CHECKBOX"></Translator>
                            </div>}
                        ></InfoPopup>
                    </div> : null
            }

            {
                !isWizard ?
                    <div id='open-wifi' className='with-info'>
                        <Checkbox name='open'
                            label={<Translator path="network.wifi.label.OPEN_WLAN_CHECKBOX"></Translator>}
                            value={wifi.cipher === cipher.NONE}
                            toggleFn={() => {
                                let currentCipher = wifi.cipher
                                wifi.cipher = currentCipher === cipher.NONE ? cipher.WPA2 : cipher.NONE
                                wifi.crypto = currentCipher === cipher.NONE ? crypto.AES : crypto.NONE

                                setWiFi({ ...wifi })
                            }}
                            disabled={isMeshController() || (mesh.isMeshAgent() && mesh.hasBackhaulConnection())}
                        ></Checkbox>

                        {isMeshController() || (mesh.isMeshAgent() && mesh.hasBackhaulConnection()) ? <InfoPopup
                            message={<div style={{ width: '400px' }}><Translator path="network.wifi.message.info_popup.MESH_OFF"></Translator></div>}
                        ></InfoPopup> : null}
                    </div> : null
            }

            {
                !isWizard && wifi.cipher !== cipher.NONE ?
                    <React.Fragment>
                        {isMeshController() || (mesh.isMeshAgent() && mesh.hasBackhaulConnection()) ?
                            <div id='wireless-cipher' className='with-info'>
                                <Input
                                    id='wireless-cipher'
                                    name='cipher'
                                    label={<Translator path="network.wifi.label.SECURITY"></Translator>}
                                    value={t(SecurityMode.find(mode => { return mode.value === wifi.cipher }).text)}
                                    disabled={true}
                                ></Input>

                                <InfoPopup
                                    message={<div style={{ width: '400px' }}><Translator path="network.wifi.message.info_popup.MESH_EDIT"></Translator></div>}
                                ></InfoPopup>
                            </div> :
                            <div>
                                <Modal
                                    modalID='confirm_wpa3_wps'
                                    title={<Translator path="menu.WPS_REQUIREMENT"></Translator>}
                                    show={confirmWPA3WPS}
                                    content={
                                        <div style={{ maxWidth: '420px' }}>
                                            <Translator path="network.wifi.message.warning.EXPLAINING_WPA3_WPS"></Translator>
                                        </div>
                                    }
                                    type={ModalTypes.CONFIRM_WARNING}
                                    onDismissClick={continueConfirmWPA3WPS}
                                    dismissText={<Translator path="common.label.CANCEL"></Translator>}
                                    confirmText={<Translator path="common.label.CONFIRM"></Translator>}
                                ></Modal>

                                <Select
                                    id='wireless-cipher'
                                    name='cipher'
                                    label={<Translator path="network.wifi.label.SECURITY"></Translator>}
                                    options={SecurityMode.map(sm => ({ value: sm.value, text: t(sm.text) }))}
                                    value={wifi.cipher}
                                    onChange={(event) => {
                                        let newCipher = Number(event.target.value)

                                        if (newCipher === cipher.WPA3 && wifi.wps === true) {
                                            confirmWPA3();
                                        }
                                        else {
                                            wifi.cipher = newCipher
                                            setWiFi({ ...wifi })
                                        }
                                    }}
                                ></Select>
                            </div>}
                    </React.Fragment> : null
            }

            <Input name='password'
                id='wireless-password'
                label={<Translator path={t("network.wifi.label.PASSWORD")} required={true}></Translator>}
                type='password'
                placeholder={t('network.wifi.label.PASSWORD')}
                autoComplete='off'
                value={wifi.key}
                onChange={(e) => {
                    setWiFi({ ...wifi, key: e.target.value })
                }}
                collapse={wifi.cipher === cipher.NONE ? 1 : 0}
                validators={wifi.cipher !== cipher.NONE ? [
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: constants.MIN_KEY_LENGTH, max: constants.MAX_KEY_LENGTH } }
                ] : []}
                disabled={mesh.isMeshAgent() && mesh.hasBackhaulConnection()}
            ></Input>

            {
                isWizard || !networks.isDualBand() || wifi.id.length > 1 ?
                    null
                    :
                    <div>
                        <div id='dualband-wifi' className='with-info'>

                            <Checkbox name='dualband'
                                label={t('network.wifi.label.DUAL_BAND')}
                                value={wifi.frequency === frequency.dualband}
                                toggleFn={() => {
                                    let newFrequency

                                    if (params.id === "add") {
                                        newFrequency = wifi.frequency === frequency.dualband ? frequency._2_4GHz : frequency.dualband
                                    }
                                    else {
                                        if (wifiPreviousRadioFrequency == null) {
                                            setWifiPreviousRadioFrequency(wifi.frequency)
                                        }

                                        newFrequency = wifi.frequency === frequency.dualband ? wifiPreviousRadioFrequency : frequency.dualband
                                    }

                                    let newIEEEStd
                                    if (newFrequency === frequency.dualband)
                                        newIEEEStd = networks.getWirelessRootIEEEStd(frequency.dualband)
                                    else
                                        newIEEEStd = networks.getWirelessRootIEEEStd(frequency._2_4GHz)

                                    setFreqChanged(true)
                                    setWiFi({ ...wifi, frequency: newFrequency, ieee_std: newIEEEStd })

                                }}
                                disabled={cannotBeDualband()}
                                validateOnChange={true}
                            ></Checkbox>

                            <InfoPopup
                                message={<div style={{ width: '400px' }}><Translator path="network.wifi.message.info_popup.DUALBAND_CHECKBOX"></Translator></div>}
                            ></InfoPopup>
                        </div>

                        {!wifi.isRoot && wifi.frequency !== frequency.dualband ?
                            <div id='wifi-frequency-radios'>

                                <RadioButton name='wifi-radio-2.4'
                                    label='2,4 GHz'
                                    value={wifi.frequency === frequency._2_4GHz}
                                    toggleFn={() => {
                                        setFreqChanged(true)
                                        let newIEEEStd = networks.getWirelessRootIEEEStd(frequency._2_4GHz)
                                        setWiFi({ ...wifi, frequency: frequency._2_4GHz, ieee_std: newIEEEStd })
                                    }}
                                    disabled={cannotBeSelected(frequency._2_4GHz)}
                                    validateOnChange={true}
                                ></RadioButton>

                                <RadioButton name='wifi-radio-5'
                                    label='5 GHz'
                                    value={wifi.frequency === frequency._5GHz}
                                    toggleFn={() => {
                                        setFreqChanged(true)
                                        let newIEEEStd = networks.getWirelessRootIEEEStd(frequency._5GHz)
                                        setWiFi({ ...wifi, frequency: frequency._5GHz, ieee_std: newIEEEStd })
                                    }}
                                    disabled={cannotBeSelected(frequency._5GHz)}
                                    validateOnChange={true}
                                ></RadioButton>

                            </div>
                            : null}
                    </div>
            }

            {!wifi.isRoot &&
                <>
                    <div className="subtitle"><Translator path="network.wifi.label.IEEE_STD"></Translator></div>
                    {ieeeStd_config()}
                </>
            }

        </React.Fragment >
    }

    return (
        !wifi || !limits ? <div className='loading'></div> :

            <div id='edit-wifi-page'>

                <DefaultStatusModals
                    modalID='wifi'
                    saving={saving}
                    success={success}
                    error={error}
                    continueFn={dismissModals}
                    savingText={
                        <div>
                            <Translator path="network.wifi.message.warning.SAVE_CONFIG"></Translator><br></br>
                            <Translator path="network.wifi.message.warning.SAVE_CONN"></Translator><b>{wifi.ssid}</b>.
                        </div>
                    }
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                <Modal
                    modalID='confirm_security'
                    title={<Translator path="menu.WIFI_DESCRIPTION"></Translator>}
                    show={confirm}
                    content={
                        <div style={{ maxWidth: '420px' }}>
                            <Translator path="network.wifi.message.warning.EXPLAINING_WIFI_SECURITY"></Translator>
                        </div>
                    }
                    type={ModalTypes.CONFIRM_WARNING}
                    onDismissClick={continueConfirm}
                    dismissText={<Translator path="common.label.CANCEL"></Translator>}
                    confirmText={<Translator path="common.label.CONFIRM"></Translator>}
                ></Modal>

                <Modal
                    modalID='confirm_password_wps'
                    title={<Translator path="menu.WIFI_DESCRIPTION"></Translator>}
                    show={confirmPasswordWPS}
                    content={
                        <div style={{ maxWidth: '420px' }}>
                            <Translator path="network.wifi.message.warning.EXPLAINING_PASSWORD_WPS"></Translator>
                        </div>
                    }
                    type={ModalTypes.CONFIRM_WARNING}
                    onDismissClick={continueConfirm}
                    dismissText={<Translator path="common.label.CANCEL"></Translator>}
                    confirmText={<Translator path="common.label.CONFIRM"></Translator>}
                ></Modal>

                <div className="subtitle"><Translator path="network.wifi.title.WIFI_EDIT"></Translator></div>

                <Button id='back-to-wifi-list'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={() => history.push('/network/wifi')}
                ></Button>

                <div className="card mt2">
                    {!isFormSegment ?
                        <React.Fragment>
                            <div className="subtitle"><Translator path="network.wifi.title.WIFI_CONFIG"></Translator></div>

                            <Form
                                onSubmit={wifi.cipher === cipher.NONE ? confirmSecurity : (!hasPasswordWPSRequirements() ? confirmPassword : submitConfirm)}
                                submitText={<Translator path="common.label.SAVE"></Translator>}
                                buttonId='button-save'
                                disableButton={((wifi.ssid === wifi.ssid_5) && splitDualBandCheckbox) || !validateIEEEwithBand(wifi)}
                            >

                                {wifiForm()}
                            </Form>
                        </React.Fragment> :

                        <FormSegment
                            segmentID='wifi'
                            title={formSegment.title}
                            active={formSegment.active}
                            previousLabel={formSegment.previousLabel}
                            previousClicked={formSegment.previousClicked}
                            disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={formSegment.nextClicked}
                        >
                            {wifiForm()}
                        </FormSegment>}
                </div>

            </div >
    )
}
import React from 'react'

import Translator from '../../../common/components/translator/translator';

export default function confirmContent(nLeases) {
    return <React.Fragment>
        <div className='confirm-block-modal'>
            <div className='modal-info'>
                <Translator path="network.lan.warning.YOU_GOT"></Translator>
                &nbsp;
                {nLeases}
                &nbsp;
                {nLeases > 1 ? <Translator path="network.lan.warning.RESERVATION_PLURAL"></Translator> : <Translator path="network.lan.warning.RESERVATION_SINGULAR"></Translator>}
                &nbsp;
                <Translator path="network.lan.warning.ADDRESS_OUT_OF_RANGE"></Translator>
                &nbsp;
                {nLeases > 1 ? <Translator path="network.lan.warning.ON_SAVE_PLURAL"></Translator> : <Translator path="network.lan.warning.ON_SAVE_SINGULAR"></Translator>}
                &nbsp;
                {nLeases > 1 ? <Translator path="network.lan.warning.RESERVATION_PLURAL"></Translator> : <Translator path="network.lan.warning.RESERVATION_SINGULAR"></Translator>}
                &nbsp;
                {nLeases > 1 ? <Translator path="network.lan.warning.WILL_BE_PLURAL"></Translator> : <Translator path="network.lan.warning.WILL_BE_SINGULAR"></Translator>}
                &nbsp;
                {nLeases > 1 ? <Translator path="network.lan.warning.REMOVED_PLURAL"></Translator> : <Translator path="network.lan.warning.REMOVED_SINGULAR"></Translator>}.
                &nbsp;
                <br></br>
                <b><Translator path="network.lan.warning.WISH_TO_CONTINUE"></Translator></b>
            </div>
        </div>
    </React.Fragment>
}
import React from 'react'
import { useTranslation } from 'react-i18next'
// useTranslation is a hook 
// return the translation function t and the i18 instance

// Flags
import BrazilFlag from '../../../assets/images/flags/pt-BR.svg'
import SpainFlag from '../../../assets/images/flags/es.svg'
import Flag from '../flag/flag'

import './i18n.css'

const I18n = () => {
  const { i18n } = useTranslation()
  // i18n instance

  function handleChangeLanguage(language) {
    localStorage.setItem('language', language)
    i18n.changeLanguage(language)
  }

  const selectedLanguage = i18n.language // Selected Language
  return (
    <div className="flags-container">
      <Flag
        image={BrazilFlag}
        isSelected={selectedLanguage === 'pt-BR'}
        onClick={() => handleChangeLanguage('pt-BR')}
      />
      <Flag
        image={SpainFlag}
        isSelected={selectedLanguage === 'es'}
        onClick={() => handleChangeLanguage('es')}
      />
    </div>
  )
}

export default I18n
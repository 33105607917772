import React, { useState, useEffect, useContext } from 'react'

import './dhcp-active-clients.css'
import { BackendContext } from '../../../../../backend/backend'
import { fetchDhcpActiveClientsList } from '../../../../resources/dhcp-server';
import Loading from '../../../../../components/loading/loading';
import List from '../../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Translator from '../../../../common/components/translator/translator';
import Pager from '../../../../../components/pager/pager';
import { MainHeaderContext } from '../../../../../components/main-header/main-header-state';
import Button from '../../../../../components/button/button';

export default function DhcpActiveClientsPage({ history }) {
    const [dhcpActiveClients, setDhcpActiveClients] = useState(null)

    const [dhcpActiveClientsPagination, setDhcpActiveClientsPagination] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)

    const { t } = useTranslation()

    useEffect(() => {
        setDhcpActiveClientsPagination({ pageNumber: 1, totalElements: 0, pageSize: 10 })
        fetchDhcpActiveClientsList(backend, setDhcpActiveClients)
        header.backRoute.set('/network/lan')

        // eslint-disable-next-line
    }, [])

    const getDHCPClientColumns = () => {

        return [
            { header: t('common.label.IP_ADDRESS'), align: 'center', size: '220px' },
            { header: 'Hostname', align: 'center', size: '220px' },
            { header: t('common.label.MAC_ADDRESS'), align: 'center', size: '220px' },
            { header: t('Aging Time (seg)'), align: 'center', size: '220px' }
        ]
    }

    const changePage = page => {
        setDhcpActiveClientsPagination({ ...dhcpActiveClientsPagination, pageNumber: page })
    }

    const getDhcpActiveClients = () => {

        let dhcpActiveClientsFiltered = dhcpActiveClients

        if (dhcpActiveClientsFiltered.length !== dhcpActiveClientsPagination.totalElements) {
            dhcpActiveClientsPagination.totalElements = dhcpActiveClientsFiltered.length
            setDhcpActiveClientsPagination({ ...dhcpActiveClientsPagination })
        }

        let dhcpActiveClientsLines = [];

        let begining = (dhcpActiveClientsPagination.pageNumber - 1) * (dhcpActiveClientsPagination.pageSize)
        let end = begining + dhcpActiveClientsPagination.pageSize
        if (end > dhcpActiveClientsPagination.totalElements) {
            end = dhcpActiveClientsPagination.totalElements
        }

        for (let i = begining; i < dhcpActiveClientsFiltered.length; i++) {
            dhcpActiveClientsLines.push([
                dhcpActiveClientsFiltered[i].ip,
                dhcpActiveClientsFiltered[i].hostname,
                dhcpActiveClientsFiltered[i].mac,
                dhcpActiveClientsFiltered[i].aging_time,
            ]);
        }

        return dhcpActiveClientsLines;
    }

    const dhcpActiveClientsForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="network.lan.title.ACTIVE_CLIENTS"></Translator></div>

            <Button id='back-to-dhcp-config'
                text={<Translator path="common.label.BACK"></Translator>}
                onClick={() => history.push('/network/lan')}
            ></Button>

            {(getDhcpActiveClients().length === 0) ?
                <div>
                    <div id='dhcp_client-no-rules'>
                        <div className='info-card noelements-card'>
                            <Translator path="network.lan.info.NO_DHCP_CLIENTS" />
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className="dhcp-active-clients-list-wrapper">
                        <List
                            lines={getDhcpActiveClients()}
                            columns={getDHCPClientColumns()}
                        ></List>
                    </div>
                    <Pager
                        pageNumber={dhcpActiveClientsPagination.pageNumber}
                        totalElements={dhcpActiveClientsPagination.totalElements}
                        pageSize={dhcpActiveClientsPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                </>
            }
            <Button
                id='button-refresh'
                type='button'
                text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                onClick={async () => {
                    await fetchDhcpActiveClientsList(backend, setDhcpActiveClients)
                }
                }
            ></Button>
        </React.Fragment>
    }

    return (
        !dhcpActiveClients ? <Loading show={true}></Loading> :

            <div id='dhcp-active-clients-page' className='with-tabs'>

                {dhcpActiveClientsForm()}

            </div>
    )
}

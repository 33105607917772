
import { backendStatus } from  '../../backend/backend'

export const initialLease = {
    description: '',
    mac: '',
    ip: ''
}

export async function fetchDhcpLeaseList(backend, setDhcpLeaseList = null){

    let result = await backend.retrieveFresh('dhcp_static_lease')

    if(result.status === backendStatus.SUCCESS){
        if (setDhcpLeaseList) {
            setDhcpLeaseList(result.content)
        } else {
            return result.content
        }
    } else {
        console.error('Error fetching dhcp lease list')
    }

    return null
}

export async function fetchDhcpLease(backend, id, setDhcpLease){

    let result = await backend.retrieveFresh(`dhcp_static_lease/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setDhcpLease(result.content)
    } else {
        console.error('Error fetching dhcp lease')
    }
}

export async function createDhcpLease(backend, dhcpLease) {

    try{

        let result = await backend.create('dhcp_static_lease', dhcpLease)
        let code = result.content.body ? result.content.body.code : ''
        return [result.status === backendStatus.SUCCESS, code]

    }catch(error){

        console.error('Error creating dhcp lease:', error)

        return [false, ""]
    }

}

export async function updateDhcpLease(backend, dhcpLease) {

    try{

        let result = await backend.update(`dhcp_static_lease/${dhcpLease.id}`, dhcpLease)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error updating dhcp lease:', error)

        return false
    }

}

export async function deleteDhcpLease(backend, id) {

    try{

        let result = await backend.delete(`dhcp_static_lease/${id}`)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error deleting dhcp lease:', error)

        return false
    }

}

export async function hasLeasesToDelete(backend, setLeasesToDelete, isOutOfRange) {

    const dhcpLeaseList = await fetchDhcpLeaseList(backend)
    let leasesOutOfRange = []

    if (dhcpLeaseList) {
        dhcpLeaseList.forEach(lease => {
            if (isOutOfRange(lease.ip)) {
                leasesOutOfRange.push(lease)
            }
        })
    }

    setLeasesToDelete(leasesOutOfRange)

    return leasesOutOfRange.length > 0
}
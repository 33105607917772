class Node {
    constructor() {
        this.next = {}
        this.isAWord = false
        this.wordInfo = []
    }
}

export default class Trie {

    #root

    constructor() {
        this.#root = new Node()
    }

    clean() {
        this.#root = new Node()
    }

    isValid(text) {
        const pattern = /^[a-z0-9çáéóãõâêôñ,./\- ]+$/i

        return pattern.test(text)
    }

    insert(text, wordInfo) {
        text = this.normalize(text)

        if (!this.isValid(text))
            return false

        return this._insertInNode(this.#root, text, wordInfo, 0)
    }

    has(text) {
        let node = this._getNodeFromRoot(text)
        return (node) ? node.isAWord : false
    }

    wordInfo(text) {
        let node = this._getNodeFromRoot(text)
        return (node) ? node.wordInfo : []
    }

    findCandidates(text) {
        let subtreeRoot = this._getNodeFromRoot(text)

        let infos = (subtreeRoot) ? this._makeTraversal(subtreeRoot) : []
        return infos
    }

    print() {
        this._printSubtree(this.#root, "")
    }

    normalize(text) {
        return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
    }

    _insertInNode(node, text, wordInfo, i) {
        if (i === text.length) {
            node.isAWord = true
            node.wordInfo.push(wordInfo)
            return true;
        }

        let char = text.charAt(i);
        if (node.next[char] === undefined)
            node.next[char] = new Node()

        return this._insertInNode(node.next[char], text, wordInfo, i + 1)
    }

    _makeTraversal(node) {
        let wordsInfos = []

        let keys = Object.keys(node.next)
        for (let i = 0; i < keys.length; i++) {
            const char = keys[i];
            wordsInfos.push(...this._makeTraversal(node.next[char]))
        }

        if (node.isAWord)
            wordsInfos.push(...node.wordInfo)

        return wordsInfos
    }

    _getNodeFromRoot(text) {
        return this._getNode(this.#root, this.normalize(text), 0)
    }

    _getNode(node, str, i) {
        if (node === undefined || i === str.length)
            return node;

        let char = str.charAt(i)
        return this._getNode(node.next[char], str, i + 1)
    }

    _printSubtree(node, str) {
        if (node.isAWord)
            console.log(str)

        const keys = Object.keys(node.next);
        for (let i = 0; i < keys.length; i++) {
            let char = keys[i]
            this._printSubtree(node.next[char], str + char)
        }
    }
}

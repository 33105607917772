
import React, { useState, useContext, useEffect } from 'react'
import Input from '../../../components/input/input'
import Form from '../../../components/form/form'
import InfoCard from '../../../components/infocard/infocard'

import './tools-ping.css'
import { BackendContext, backendStatus } from '../../../backend/backend'
import { fetchPing, createPing, constants, isValidIPv4, isValidIPv6 } from '../../resources/ping'
import network from '../../../components/form/validators/network'
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import PacketsIcon from '../../../components/icons/packets-icon'
import ClockIcon from '../../../components/icons/clock-icon'
import CollapseBox from '../../../components/collapsebox/collapse-box';
import Checkbox from '../../../components/checkbox/checkbox'
import { fetchNetworkWanList } from '../../resources/network-wan'
import Select from '../../../components/select/select'
import Translator from '../../common/components/translator/translator'
import { useTranslation } from 'react-i18next'
import extraValidators from '../../common/validators';

export default function PingPage() {
    const [pingInput, setPingInput] = useState({
        host: '',
        packet_size: 56,
        packets_qty: 4,
        hops_limit: 255,
        deadline_seconds: 0,
        ip6: false,
        interfaceID: 'any',
    })
    const [pingResult, setPingResult] = useState({})
    const [error, setError] = useState(false)
    const [errorInfo, setErrorInfo] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showInfo, setShowInfo] = useState(false)
    const [networkWanList, setNetworkWanList] = useState([])

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchNetworkWanList(backend, setNetworkWanList)

        // eslint-disable-next-line
    }, [])

    const runPing = async () => {
        if (error || loading) return

        setLoading(true)

        let pingRequest = {}
        Object.keys(pingInput).forEach((key) => {
            if (pingInput[key]) {
                pingRequest[key] = pingInput[key]
            }
        })

        // Send ping request
        let result = await createPing(backend, pingRequest, setErrorInfo)
        if (!result || result === backendStatus.ERROR) {
            setError(true)
            setLoading(false)
            return
        }

        // Retrieve ping result
        result = await fetchPing(backend, setPingResult, setErrorInfo)
        if (!result) {
            setError(true)
        }
        else {
            setShowInfo(true)
        }

        setLoading(false)
    }

    const dismiss = () => {
        setLoading(false)
        setError(false)
        setShowInfo(false)
    }

    const getPackets = () => {
        return [
            { label: t('tools.ping.label.SENT'), value: pingResult.packets_sent },
            { label: t('tools.ping.label.RECEIVED'), value: pingResult.packets_received },
            { label: t('tools.ping.label.LOST') + ' (%)', value: pingResult.packets_lost_percent.toFixed(2) }
        ]
    }

    const getRTTs = () => {
        return [
            { label: t('tools.ping.label.MINIMUM'), value: pingResult.min_time.toFixed(2) },
            { label: t('tools.ping.label.MAXIMUM'), value: pingResult.max_time.toFixed(2) },
            { label: t('tools.ping.label.MEAN'), value: pingResult.avg_time.toFixed(2) }
        ]
    }

    const pingInterfaces =  (pingInput) => {
        if (!pingInput.ip6){
            return[{ value: 'any', text: t('tools.ping.label.ALL_INTERFACES') }, ...networkWanList.filter(wan => (wan.mode !== 3 && wan.protocol !== 1)).map(wan => ({ value: wan.id, text: wan.interfaceID }))]
        }
        if (pingInput.ip6){
            return[{ value: 'any', text: t('tools.ping.label.ALL_INTERFACES') }, ...networkWanList.filter(wan => (wan.mode !== 3 && wan.protocol !== 0)).map(wan => ({ value: wan.id, text: wan.interfaceID }))]
        }
    }

    return <div id='ping-page' className='page container'>
        <DefaultStatusModals
            error={error}
            errorText={
                <>
                    <div>
                        {t('tools.ping.message.error.PING_ERROR')}
                    </div>
                    <br />
                    <div>
                        {errorInfo?.message}
                    </div>
                </>                
            }
            saving={loading}
            savingText={t('common.label.WAIT')}
            continueFn={dismiss}
            info={showInfo}
            infoText={
                    !pingResult.packets_sent ? '' :
                    <div className='ping-result'>
                            <div className='subtitle'> <Translator path="common.label.RESULTS"></Translator></div>
                            {
                                pingResult.host !== pingResult.ip ?
                                    <div className="info-text"><b>{<Translator path="common.label.HOST"></Translator>}:</b> {pingResult.host}</div> : ''
                            }
                            <div className="info-text"><b>{<Translator path="common.label.IP_ADDRESS"></Translator>}:</b> {pingResult.ip}</div>
                            <div className="info-text"><b>{<Translator path="common.label.TTL"></Translator>}:</b> {pingResult.ttl}</div>

                            <div className='ping-result-details'>
                                <div>
                                    <div className='dashboard-subtitle'>
                                        <PacketsIcon size='18'></PacketsIcon>
                                        <div className='subtitle'><Translator path="tools.ping.label.PACKAGES"></Translator></div>
                                    </div>
                                    <InfoCard
                                        lines={getPackets()}
                                    ></InfoCard>
                                </div>
                                <div>
                                    <div className='dashboard-subtitle'>
                                        <ClockIcon size='18'></ClockIcon>
                                        <div className='subtitle'><Translator path="tools.ping.label.RTT"></Translator> (ms)</div>
                                    </div>
                                    <InfoCard
                                        lines={getRTTs()}
                                    ></InfoCard>
                                </div>
                            </div>
                        </div>
            }
            >
        </DefaultStatusModals>
        
        <div className='subtitle'> <Translator path="tools.ping.label.PING_SETTINGS"></Translator></div>
        <div className='card mt2'>
            <div className='subtitle'> <Translator path="tools.ping.title.PING"></Translator></div>

            <Form id='ping-form'
                onSubmit={runPing}
                submitText={<Translator path="tools.ping.label.START"></Translator>}
                buttonId='button-ping'
                disableButton={(pingInput.ip6 && isValidIPv4(pingInput.host)) || (!pingInput.ip6 && isValidIPv6(pingInput.host))}
            >
                <Input id='ping-host' type='text'
                    name='host'
                    label={<Translator path={t("tools.ping.label.IP_HOST_ADDRESS")} required={true}></Translator>}
                    value={pingInput.host}
                    onChange={e => setPingInput({ ...pingInput, host: e.target.value })}
                    dependentValues={[pingInput]}
                    validators={[
                        extraValidators.required,
                        extraValidators.nonASCII,
                        { fn: extraValidators.size, params: { min: constants.MIN_HOST_LENGTH, max: constants.MAX_HOST_LENGTH } },
                        network.hostname
                    ]}
                    errorMessage={(pingInput.ip6 && isValidIPv4(pingInput.host) && t('tools.traceroute.message.error.TRACEROUTE_INVALID_IP')) || (!pingInput.ip6 && isValidIPv6(pingInput.host) && t('tools.traceroute.message.error.TRACEROUTE_INVALID_IP'))}
                ></Input>

                <Checkbox
                    name="ip6"
                    label={<Translator path="common.label.IPV6"></Translator>}
                    id='ping-ip6'
                    value={pingInput.ip6}
                    toggleFn={() => setPingInput({ ...pingInput, ip6: !pingInput.ip6 })}
                ></Checkbox>

                <Select
                    id='tools-ping-interface-id'
                    name='interfaceID'
                    label={<Translator path="tools.ping.label.INTERFACE"></Translator>}
                    options={pingInterfaces(pingInput)}
                    value={pingInput.interfaceID}
                    onChange={(e) => {
                        pingInput.interfaceID = e.target.value
                        setPingInput({ ...pingInput })
                    }}

                ></Select>

                <CollapseBox title={<Translator path="tools.ping.title.ADVANCED"></Translator>} startOpen={true}>

                    <Input id='ping-bytes' type='number'
                        name='bytes'
                        label={<Translator path={t("tools.ping.label.PACKAGE_SIZE") + " (bytes)"} required={true}></Translator>}
                        value={pingInput.packet_size ? pingInput.packet_size : ''}
                        onChange={e => setPingInput({ ...pingInput, packet_size: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            extraValidators.required,
                            { fn: extraValidators.value, params: { min: constants.MIN_PACKET_SIZE, max: constants.MAX_PACKET_SIZE } }
                        ]}
                    ></Input>

                    <Input id='ping-packets' type='number'
                        name='packets'
                        label={<Translator path={t("tools.ping.label.NUMBER_OF_PACKAGES")} required={true}></Translator>}
                        value={pingInput.packets_qty}
                        onChange={e => setPingInput({ ...pingInput, packets_qty: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            extraValidators.required,
                            { fn: extraValidators.value, params: { min: constants.MIN_PACKETS_QTY, max: constants.MAX_PACKETS_QTY } }
                        ]}
                    ></Input>

                    <Input id='ping-hops' type='number'
                        name='hops'
                        label={<Translator path={t("tools.ping.label.MAX_HOPS_NUMBER")} required={true}></Translator>}
                        value={pingInput.hops_limit}
                        onChange={e => setPingInput({ ...pingInput, hops_limit: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            extraValidators.required,
                            { fn: extraValidators.value, params: { min: constants.MIN_HOPS_LIMIT, max: constants.MAX_HOPS_LIMIT } }
                        ]}
                    ></Input>

                    <Input id='ping-deadline' type='number'
                        name='deadline'
                        label={<Translator path="tools.ping.label.EXECUTION_TIME_IN_SECONDS"></Translator>}
                        value={pingInput.deadline_seconds}
                        onChange={e => setPingInput({ ...pingInput, deadline_seconds: Number(e.target.value) })}
                        validators={[
                            { fn: extraValidators.value, params: { min: constants.MIN_DEADLINE_SECONDS, max: constants.MAX_DEADLINE_SECONDS } }
                        ]}
                    ></Input>

                </CollapseBox>
            </Form>
        </div>

        {/* {
            !pingResult.packets_sent ? '' :

                <div className='ping-result'>
                    {
                        pingResult.host !== pingResult.ip ?
                            <div className="info-text"><b>{<Translator path="common.label.HOST"></Translator>}:</b> {pingResult.host}</div> : ''
                    }
                    <div className="info-text"><b>{<Translator path="common.label.IP_ADDRESS"></Translator>}:</b> {pingResult.ip}</div>
                    <div className="info-text"><b>{<Translator path="common.label.TTL"></Translator>}:</b> {pingResult.ttl}</div>

                    <div className='ping-result-details'>
                        <div>
                            <div className='dashboard-subtitle'>
                                <PacketsIcon size='18'></PacketsIcon>
                                <label>{<Translator path="tools.ping.label.PACKAGES"></Translator>}</label>
                            </div>
                            <InfoCard
                                lines={getPackets()}
                            ></InfoCard>
                        </div>
                        <div>
                            <div className='dashboard-subtitle'>
                                <ClockIcon size='18'></ClockIcon>
                                <label>{<Translator path="tools.ping.label.RTT"></Translator>} (ms)</label>
                            </div>
                            <InfoCard
                                lines={getRTTs()}
                            ></InfoCard>
                        </div>
                    </div>
                </div>
        } */}
    </div>
}
import React, { useContext, useEffect, useState } from 'react'

import DashboardMesh from './dashboard-mesh'
import { MeshContext } from './mesh-context'
import { fetchDevice } from '../../../resources/device'
import { fetchLan } from '../../../resources/lan'
import { fetchConnectedDevicesList } from '../../../resources/connected-devices'
import { BackendContext } from '../../../../backend/backend'

import './mesh.css'

export default function MeshNodesPage() {
    let mesh = useContext(MeshContext)
    let [device, setDevice] = useState(null)
    let [lan, setLan] = useState(null)
    let [connectedDevices, setConnectedDevices] = useState(null)

    let backend = useContext(BackendContext)

    useEffect(() => {
        if (!mesh.easyMesh) mesh.retrieveEasyMesh()
        fetchDevice(backend, setDevice)
        fetchLan(backend, setLan)
        fetchConnectedDevicesList(backend, setConnectedDevices)
        // eslint-disable-next-line
    }, [])

    return (
        <div className="mesh-nodes-page">
            {device && mesh.hasMesh() && mesh.easyMesh && mesh.easyMesh.enabled ?
                <DashboardMesh device={device} lan={lan} rootConnectedDevices={connectedDevices}></DashboardMesh>
            : null}
        </div>
    )
}

import React from 'react'

export default function RadioIcon({size}) {

    return <svg xmlns="http://www.w3.org/2000/svg" width={size} height="24" viewBox="0 0 36 24">
        <g fill="none">
            <g fill="#7786ED">
                <path d="M35.218 14.12H8.305V6.036A1.088 1.088 0 0 0 7.225 5c-.575 0-1.049.454-1.08 1.035v8.086h-1.36a.79.79 0 0 0-.785.791v8.293a.793.793 0 0 0 .785.795h30.433a.79.79 0 0 0 .782-.795v-8.293a.79.79 0 0 0-.782-.791zm-.373 1.18v7.521H5.155V15.3h1.062v6.058c0 .11.043.214.119.29.076.078.18.121.287.121h26.629a.4.4 0 0 0 .286-.12.41.41 0 0 0 .116-.29v-6.06h1.191zm-1.695 5.945H6.758V15.3H33.15v5.945zM6.933 6.035c0-.162.13-.294.29-.294.16 0 .29.132.29.294v8.086H6.94l-.006-8.086z"/>
                <path d="M25.332 18h4.34c.174.002.32-.158.328-.362v-.25a.42.42 0 0 0-.095-.274.307.307 0 0 0-.234-.114h-4.34c-.177-.002-.324.162-.331.37v.25c0 .101.036.199.098.27.063.071.147.11.234.11zm-.072-.63c0-.046.032-.084.072-.084h4.34c.018 0 .036.01.049.025.013.016.02.037.019.059v.25c0 .044-.03.08-.069.08h-4.34c-.038 0-.069-.036-.07-.08v-.25z"/>
            </g>
            <path stroke="#7786ED" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1.348 9c-.791-2.171-.23-4.677 1.65-6.356C5.557.358 9.588.47 12 2.89"/>
        </g>
    </svg>

}
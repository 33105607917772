import React, { useState, useEffect, useContext } from 'react'

import { fetchDevice, updateDevice } from '../../resources/device';
import { BackendContext } from '../../../backend/backend';
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import Loading from '../../../components/loading/loading';
import Form from '../../../components/form/form';
import { useTranslation } from 'react-i18next'
import Translator from '../../common/components/translator/translator';
import Checkbox from '../../../components/checkbox/checkbox';

export default function SystemLeds({ isWizard, setWizardLeds, isFormSegment, formSegment }) {

    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [success, setSuccess] = useState(false)
    let [device, setDevice] = useState(null)


    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchDevice(backend, setDevice);

        // eslint-disable-next-line
    }, [])

    let save = async () => {
        setErrorMessage('')
        if (saving || error || success) return

        setSaving(true)

        let ok = await updateDevice(backend, device, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const systemLedsForm = () => {
        return <React.Fragment>
            <Checkbox id='leds'
                name='leds'
                label={<Translator path="system.leds.label.ENABLE"></Translator>}
                value={device.leds}
                toggleFn={(e) => {
                    device.leds = !device.leds;
                    setDevice({ ...device })
                }}
                validators={[]}
            ></Checkbox>
        </React.Fragment>
    }

    return !device ? <Loading show={true}></Loading> :
        <div id='leds-page' className='container scroll-area'>
            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismiss}
                errorText={`${t('system.leds.message.error.ERROR_ON_SERVER')} ${errorMessage ? errorMessage : ''}`}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <React.Fragment>
                <div className='subtitle'> <Translator path="system.leds.title.LEDS"></Translator> </div>
                <div className="card mt2">
                    <div className='subtitle'> <Translator path="system.leds.title.ENABLE"></Translator> </div>
                    <Form id='leds-form'
                        onSubmit={save}
                        buttonId='button-save-leds'
                    >
                        {systemLedsForm()}
                    </Form>
                </div>
            </React.Fragment>

        </div>
}
import { backendStatus } from '../../backend/backend'

const URL = 'ipv6_dhcp_client';

export async function fetchIpv6DhcpActiveClients(backend, id, setDhcpActiveClients) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setDhcpActiveClients(result.content)
    }
    
}

export async function fetchIpv6DhcpActiveClientsList(backend, setDhcpActiveClientsList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setDhcpActiveClientsList(result.content)
    }
}

export async function deleteIpv6DhcpActiveClientsById(backend, id, setDhcpActiveClientsList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchIpv6DhcpActiveClientsList(backend, setDhcpActiveClientsList)
    }
}

export async function saveIpv6DhcpActiveClients(backend, dhcpActiveClients, setErrorMessage) {

    try{

        let result = null;

        if(!dhcpActiveClients.id) {
            result = await backend.create(`${URL}`, dhcpActiveClients)
        }
        else {
            result = await backend.update(`${URL}/${dhcpActiveClients.id}`, dhcpActiveClients)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || dhcpActiveClients)

    }catch(error){

        console.error('Error saving DHCP_ACTIVE_CLIENTS:', error)

        return false
    }

}
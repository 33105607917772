
export default class MenuID {

    /* static elements */

    // Menus
    static Dashboard = new MenuID('Dashboard')
    static Network = new MenuID('Network')
    static Ipv6 = new MenuID('Ipv6')
    static Pon = new MenuID('Pon')
    static Voip = new MenuID('Voip')
    static ParentalControl = new MenuID('ParentalControl')
    static Firewall = new MenuID('Firewall')
    static Tools = new MenuID('Tools')
    static Service = new MenuID('Service')
    static Advanced = new MenuID('Advanced')
    static System = new MenuID('System')
    static Logout = new MenuID('Logout')

    // Submenus
    static DashboardSub = new MenuID('DashboardSub', MenuID.Dashboard.name)

    static NetworkWan = new MenuID('NetworkWan', MenuID.Network.name)
    static NetworkLan = new MenuID('NetworkLan', MenuID.Network.name)
    static NetworkWifi = new MenuID('NetworkWifi', MenuID.Network.name)
    static NetworkMesh = new MenuID('NetworkMesh', MenuID.Network.name)
    /*NETWORK_TAG*/

    static Ipv6Status = new MenuID('Ipv6Status', MenuID.Ipv6.name)
    static Ipv6ProxyMld = new MenuID('Ipv6ProxyMld', MenuID.Ipv6.name)
    static Ipv6Radvd = new MenuID('Ipv6Radvd', MenuID.Ipv6.name)
    static Ipv6Dhcp = new MenuID('Ipv6Dhcp', MenuID.Ipv6.name)
    static Ipv6Acl = new MenuID('Ipv6Acl', MenuID.Ipv6.name)
    static Ipv6RouteList = new MenuID('Ipv6RouteList', MenuID.Ipv6.name)
    static Ipv6StaticRoute = new MenuID('Ipv6StaticRoute', MenuID.Ipv6.name)
    static Ipv6IpPortFilter = new MenuID('Ipv6IpPortFilter', MenuID.Ipv6.name)
    static Ipv6PrefixDelegation = new MenuID('Ipv6PrefixDelegation', MenuID.Ipv6.name)
    /*IPV6_TAG*/

    static PonCounters = new MenuID('PonCounters', MenuID.Pon.name)
    static PonStats = new MenuID('PonStats', MenuID.Pon.name)
    static PonSettings = new MenuID('PonSettings', MenuID.Pon.name)
    static PonStatus = new MenuID('PonStatus', MenuID.Pon.name)
    static PonEponLlidStatus = new MenuID('PonEponLlidStatus', MenuID.Pon.name)
    static PonGponStatus = new MenuID('PonGponStatus', MenuID.Pon.name)
    static PonGemPortStatistics = new MenuID('PonGemPortStatistics', MenuID.Pon.name)
    /*PON_TAG*/

    static VoipHistoryCall = new MenuID('VoipHistoryCall', MenuID.Voip.name)
    static VoipDialPlan = new MenuID('VoipDialPlan', MenuID.Voip.name)
    static VoipAdvancedSip = new MenuID('VoipAdvancedSip', MenuID.Voip.name)
    static VoipProxy = new MenuID('VoipProxy', MenuID.Voip.name)
    static VoipCodec = new MenuID('VoipCodec', MenuID.Voip.name)
    /*VOIP_TAG*/

    static ParentalControlConnectedDevices = new MenuID('ParentalControlConnectedDevices', MenuID.ParentalControl.name)
    static ParentalControlSub = new MenuID('ParentalControlSub', MenuID.ParentalControl.name)
    /*PARENTAL_CONTROL_TAG*/

    static FirewallAlg = new MenuID('FirewallAlg', MenuID.Firewall.name)
    static FirewallUrlBlocking = new MenuID('FirewallUrlBlocking', MenuID.Firewall.name)
    static FirewallMacFiltering = new MenuID('FirewallMacFiltering', MenuID.Firewall.name)
    static FirewallIpPortFiltering = new MenuID('FirewallIpPortFiltering', MenuID.Firewall.name)
    static FirewallDomainBlocking = new MenuID('FirewallDomainBlocking', MenuID.Firewall.name)
    static FirewallPortForwarding = new MenuID('FirewallPortForwarding', MenuID.Firewall.name)
    static FirewallDmz = new MenuID('FirewallDmz', MenuID.Firewall.name)
    static FirewallWlanAccessControl = new MenuID('FirewallWlanAccessControl', MenuID.Firewall.name)
    /*FIREWALL_TAG*/

    static ToolsPing = new MenuID('ToolsPing', MenuID.Tools.name)
    static ToolsTr069Inform = new MenuID('ToolsTr069Inform', MenuID.Tools.name)
    static ToolsTraceroute = new MenuID('ToolsTraceroute', MenuID.Tools.name)
    static ToolsLoopbackDetect = new MenuID('ToolsLoopbackDetect', MenuID.Tools.name)
    /*TOOLS_TAG*/

    static ServiceProxyIgmp = new MenuID('ServiceProxyIgmp', MenuID.Service.name)
    static ServiceUpnp = new MenuID('ServiceUpnp', MenuID.Service.name)
    static ServiceDynamicDns = new MenuID('ServiceDynamicDns', MenuID.Service.name)
    static ServiceRipConfig = new MenuID('ServiceRipConfig', MenuID.Service.name)
    /*SERVICE_TAG*/

    static AdvancedNeighbourHosts = new MenuID('AdvancedNeighbourHosts', MenuID.Advanced.name)
    static AdvancedStaticRouting = new MenuID('AdvancedStaticRouting', MenuID.Advanced.name)
    static AdvancedQosClassification = new MenuID('AdvancedQosClassification', MenuID.Advanced.name)
    static AdvancedQosPolicy = new MenuID('AdvancedQosPolicy', MenuID.Advanced.name)
    static AdvancedQosTrafficControl = new MenuID('AdvancedQosTrafficControl', MenuID.Advanced.name)
    static AdvancedGlobalRouting = new MenuID('AdvancedGlobalRouting', MenuID.Advanced.name)
    static AdvancedBridging = new MenuID('AdvancedBridging', MenuID.Advanced.name)
    static AdvancedIgmpMldSnooping = new MenuID('AdvancedIgmpMldSnooping', MenuID.Advanced.name)
    /*ADVANCED_TAG*/

    static SystemUsers = new MenuID('SystemUsers', MenuID.System.name)
    static SystemAclAdmin = new MenuID('SystemAclAdmin', MenuID.System.name)
    static SystemTr069Config = new MenuID('SystemTr069Config', MenuID.System.name)
    static SystemLeds = new MenuID('SystemLeds', MenuID.System.name)
    static SystemReboot = new MenuID('SystemReboot', MenuID.System.name)
    static SystemRestore = new MenuID('SystemRestore', MenuID.System.name)
    static SystemBackupRestore = new MenuID('SystemBackupRestore', MenuID.System.name)
    static SystemChangeFirwareVersion = new MenuID('SystemChangeFirwareVersion', MenuID.System.name)
    static SystemUpdate = new MenuID('SystemUpdate', MenuID.System.name)
    static SystemDateTime = new MenuID('SystemDateTime', MenuID.System.name)
    static SystemAutomaticMaintenance = new MenuID('SystemAutomaticMaintenance', MenuID.System.name)
    static SystemInformation = new MenuID('SystemInformation', MenuID.System.name)
    /*SYSTEM_TAG*/

    static Logout = new MenuID('Logout', MenuID.Logout.name)

    // retrieving IDs by groups
    static _mainMenus // list of IDs
    static _submenus  // map menu ID to list of submenu IDs
    static _parentOf  // quickly find the parent of a submenu

    static mainMenuIDs() {
        return MenuID._mainMenus
    }

    static submenuIDs(parent = null) {
        if (parent === null)
            return MenuID._submenus

        return MenuID._submenus[parent]
    }

    static parentID(submenuId) {
        return MenuID._parentOf[submenuId]
    }

    /* end of static elements */

    #name

    constructor(name, parent = null) {
        this.#name = name
        this.appendToParent(parent)
    }

    appendToParent(parent) {
        if (parent === null) {
            if (!MenuID._mainMenus)
                MenuID._mainMenus = []

            MenuID._mainMenus.push(this.#name)
        } else {
            if (!MenuID._submenus)
                MenuID._submenus = {}

            if (!MenuID._parentOf)
                MenuID._parentOf = {}

            if (!(parent in MenuID._submenus))
                MenuID._submenus[parent] = []

            MenuID._submenus[parent].push(this.#name)
            MenuID._parentOf[this.#name] = parent
        }
    }

    get name() {
        return this.#name
    }

}

import { backendStatus } from '../../backend/backend'

const URL = 'loopback_detect_status';

export async function fetchLoopBackDetectStatus(backend, setloopBackDetectStatus, setErrorMessage) {
    let result = await backend.retrieveFresh(`${URL}`)

    if (result.status === backendStatus.SUCCESS) {
        setloopBackDetectStatus(result.content)
    } else {
        setErrorMessage(result.content?.body.message)
    }
}

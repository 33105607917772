import React, { useState, useEffect, useContext } from 'react'

import './pon-stats.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchPONStats } from '../../../resources/pon-stats';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard'
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function PONStats({isWizard, setSaved, isFormSegment, formSegment}) {

    const [ponStats, setPONStats] = useState(null)
    const [fetchingData, setFetchingData] = useState(false)

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchData()

        // eslint-disable-next-line
    }, [])

    const fetchData = () => {
        fetchPONStats(backend, fetchPonStatsCallback)
        setFetchingData(true)
    }

    const fetchPonStatsCallback = (fetchedData) => {
        setPONStats(fetchedData)
        setFetchingData(false)
    }

    const refreshButtonText = () => {
        return fetchingData ? 
                    <Translator path="common.label.TABLE_REFRESHING" />
                    : <Translator path="common.label.TABLE_REFRESH" />
    }

    const ponStatsForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="pon.stats.title.PON_STATISTICS"></Translator></div>
            <div className='subtitle subtitle2'><Translator path="pon.stats.title.FLOW_RATE"></Translator></div>
            <InfoCard
                lines={[
                    {label: <Translator path="pon.stats.label.TX"></Translator>, value: ponStats.throughput.tx, id: 'card-pon-stats-throughput-tx'},
                    {label: <Translator path="pon.stats.label.RX"></Translator>, value: ponStats.throughput.rx, id: 'card-pon-stats-throughput-rx'},
                ]}
            ></InfoCard>
            <div className='section-divider'></div>
            <div className='subtitle subtitle2'><Translator path="pon.stats.title.PACKAGES_PER_SECOND"></Translator></div>
            <InfoCard
                lines={[
                    {label: <Translator path="pon.stats.label.TX_PACKAGE"></Translator>, value: ponStats.pps.tx, id: 'card-pon-stats-pps-tx'},
                    {label: <Translator path="pon.stats.label.RX_PACKAGE"></Translator>, value: ponStats.pps.rx, id: 'card-pon-stats-pps-rx'},
                ]}
            ></InfoCard>
            <div className='section-divider'></div>
            <Button
                id='button-refresh'
                type='button'
                text={refreshButtonText()}
                disabled={fetchingData}
                onClick={async () => {
                    await fetchData()}
                }
            ></Button>
        </React.Fragment>
    }

    return (
        !ponStats ? <Loading show={true}></Loading> :

        <div id='pon-stats-page' className='with-tabs'>

            { ponStatsForm() }

        </div>
    )
}

import React from 'react'
import Translator from '../common/components/translator/translator'

export default  {

    getRebootSucessContent: () => {
        return (
            <React.Fragment>
                <Translator ></Translator>
                <div><Translator path="system.reboot.info.REBOOT_SUCCESSFUL"></Translator></div>
            </React.Fragment>
        )
    },

}

import React from 'react'

export default function DhcpLeaseIcon({ size, color }) {
    return <svg fill={color} width={size} height={size} viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1">
            <g id="007---IP-Address">
                <path id="Shape" d="m57 51h-54c-.55228475 0-1-.4477153-1-1v-36c.00181871-1.6561003 1.34389967-2.9981813 3-3h9.79c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-9.79c-.55207909.0004962-.9995038.4479209-1 1v35h52v-35c-.0004962-.5520791-.4479209-.9995038-1-1h-9.78c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h9.78c1.6561003.0018187 2.9981813 1.3438997 3 3v36c0 .5522847-.4477153 1-1 1z" />
                <path id="Shape" d="m53 51h-46c-.55228475 0-1-.4477153-1-1v-33c.00126747-1.1040441.89595593-1.9987325 2-2h6.03c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-6.03v32h44v-32h-6.03c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h6.03c1.1040441.0012675 1.9987325.8959559 2 2v33c0 .5522847-.4477153 1-1 1z" />
                <path id="Shape" d="m55 60h-50c-2.76007596-.0032511-4.99674886-2.239924-5-5v-4c.00126747-1.1040441.89595593-1.9987325 2-2h56c1.1040441.0012675 1.9987325.8959559 2 2v4c-.0032511 2.760076-2.239924 4.9967489-5 5zm-53-9v4c.00181871 1.6561003 1.34389967 2.9981813 3 3h50c1.6561003-.0018187 2.9981813-1.3438997 3-3v-4z" />
                <path id="Shape" d="m36.7637 55h-13.5274c-1.1374301.0038057-2.1781341-.6392466-2.6836-1.6582l-1.4472-2.8945c-.1550411-.3100073-.1384911-.6781924.0437383-.9730394.1822294-.2948469.5041463-.4743008.8507617-.4742606h20c.3466154-.0000402.6685323.1794137.8507617.4742606.1822294.294847.1987794.6630321.0437383.9730394l-1.4472 2.8945c-.5054659 1.0189534-1.5461699 1.6620057-2.6836 1.6582zm-15.1455-4 .7236 1.4473c.1686688.3394669.5154408.5537329.8945.5527h13.5274c.3790592.0010329.7258312-.2132331.8945-.5527l.7236-1.4473z" />
                <path id="Shape" d="m13 55h-12c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1h12c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z" />
                <path id="Shape" d="m18 55h-1c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h1c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z" />
                <path id="Shape" d="m59 55h-12c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h12c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z" />
                <path id="Shape" d="m43 55h-1c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h1c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z" />
                <path id="Shape" d="m30 46c-.5781857.0001622-1.119703-.283189-1.4492-.7583l-12.2266-18.2017c-.0356524-.052112-.0658133-.1077754-.09-.1661-2.162915-2.9549934-3.2982097-6.5372027-3.2322-10.1986.1952492-9.25310393 7.7428536-16.65742018 16.998-16.6753h.0068c9.3625594-.00401731 16.9629955 7.56868845 16.9932 16.9312.0093636 3.574165-1.1239093 7.0577598-3.2344 9.9423-.0245844.0581342-.0547242.1137589-.09.1661l-12.2186 18.1904c-.329109.4810776-.8741213.7691074-1.457.77zm-11.8867-19.8838 11.8867 17.6963 11.8955-17.709c.0305455-.0847334.0736611-.1643909.1279-.2363 1.9394834-2.5747047 2.9847059-5.7125493 2.9766-8.936-.0290443-8.25842199-6.7347286-14.93637683-14.9932-14.9312h-.0068c-8.1641516.01303301-14.823973 6.54279296-14.998 14.7051-.0596881 3.3005572.9875138 6.5260558 2.9746 9.1621.0571979.0760628.1032301.1599107.1367.249z" />
                <path id="Shape" d="m25 26c-.5522847 0-1-.4477153-1-1v-14c0-.5522847.4477153-1 1-1s1 .4477153 1 1v14c0 .5522847-.4477153 1-1 1z" />
                <path id="Shape" d="m28 26h-6c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h6c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z" />
                <path id="Shape" d="m28 12h-6c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1h6c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1z" />
                <path id="Shape" d="m32 26c-.5522847 0-1-.4477153-1-1v-14c0-.5522847.4477153-1 1-1s1 .4477153 1 1v14c0 .5522847-.4477153 1-1 1z" />
                <path id="Shape" d="m36.5 19h-4.5c-.5522847 0-1-.4477153-1-1v-7c0-.5522847.4477153-1 1-1h4.5c2.4852814 0 4.5 2.0147186 4.5 4.5s-2.0147186 4.5-4.5 4.5zm-3.5-2h3.5c1.3807119 0 2.5-1.1192881 2.5-2.5s-1.1192881-2.5-2.5-2.5h-3.5z" />
            </g>
        </g>
    </svg>
}
import React, { useState, useEffect } from "react"

import Input from "../../../../../components/input/input"
import Checkbox from "../../../../../components/checkbox/checkbox"
import Translator from "../../../../common/components/translator/translator"
import { useTranslation } from "react-i18next"

import { ProtocolEnumeration } from "../ipv6-ip-port-filter-common"
import extraValidators from "../../../../common/validators"

export default function IpSettings(props) {

    const { t } = useTranslation()

    const [portFromChanged, setPortFromChanged] = useState(false)

    useEffect(() => {
        if (portFromChanged === true) {
            setPortFromChanged(false)
        }

    }, [portFromChanged])

    return (
        <div className='card mt2'>
            <div className='subtitle'> {<Translator path={props.title}></Translator>} </div>
            <div className="row">

                {/* Enable configuration */}
                <Checkbox
                    id={'ip6-port-filtering-ip-enable-' + props.appendID}
                    name='ip_enable'
                    label={<Translator path={props.enableConfigMessage}></Translator>}
                    value={props.allowConfigure}
                    toggleFn={() => { props.setAllowConfigure(!props.allowConfigure) }}
                ></Checkbox>

            </div>
            <div className="row">

                {/* IPv6 From */}
                <Input id={'ip6-port-filtering-ipv6-start-' + props.appendID}
                    name={'ipv6_start_' + props.appendID}
                    label={t('ipv6.ip_port_filtering.label.IPV6_START')}
                    value={props.ipv6Info.start}
                    disabled={!props.allowConfigure}
                    onChange={(e) => { props.handleIpStart(e.target.value) }}
                    dependentValues={!props.allowConfigure}
                    validators={[{
                        fn: extraValidators.optionalValidators, params: {
                            shouldValidate: () => props.allowConfigure,
                            validators: [
                                { fn: extraValidators.validateIPv6, params: '' },
                            ]
                        }
                    }]}
                ></Input>

                {/* IPv6 To */}
                <Input id={'ip6-port-filtering-ipv6-end-' + props.appendID}
                    name={'ipv6_end' + props.appendID}
                    label={t('ipv6.ip_port_filtering.label.IPV6_END')}
                    value={props.ipv6Info.end}
                    disabled={!props.allowConfigure}
                    onChange={(e) => { props.handleIpEnd(e.target.value) }}
                    dependentValues={!props.allowConfigure}
                    validators={[{
                        fn: extraValidators.optionalValidators, params: {
                            shouldValidate: () => props.allowConfigure,
                            validators: [
                                { fn: extraValidators.validateIPv6, params: '' },
                            ]
                        }
                    }]}
                ></Input>

            </div>
            <div className="row">

                {/* Prefix Length */}
                <Input id={'ip6-port-filtering-ipv6-prefix_length' + props.appendID}
                    name={'ipv6_prefix_length' + props.appendID}
                    label={t('ipv6.ip_port_filtering.label.IPV6_PREFIX_LENGTH')}
                    value={props.ipv6Info.prefix_length}
                    disabled={!props.allowConfigure}
                    onChange={(e) => { props.handleIpPrefixLength(e.target.value) }}
                    dependentValues={!props.allowConfigure}
                    validators={[{
                        fn: extraValidators.optionalValidators, params: {
                            shouldValidate: () => (props.allowConfigure),
                            validators: [
                                { fn: extraValidators.isNumber, params: '' },
                                { fn: extraValidators.value, params: { min: 0, max: 128 } }
                            ]
                        }
                    }]}
                ></Input>

                {/* Port From */}
                <Input id={'ip6-port-filtering-port-from' + props.appendID}
                    name={'port_from_' + props.appendID}
                    disabled={!props.allowConfigure || (props.protocol === ProtocolEnumeration.IPV6_FILTER_ICMPV6)}
                    label={t('ipv6.ip_port_filtering.label.PORT_FROM')}
                    value={props.portInfo.from}
                    dependentValues={!props.allowConfigure || props.protocol === ProtocolEnumeration.IPV6_FILTER_ICMPV6}
                    onChange={(e) => {
                        props.handlePortFrom(e.target.value)
                        setPortFromChanged(true)
                    }}
                    validators={[{
                        fn: extraValidators.optionalValidators, params: {
                            shouldValidate: () => (props.allowConfigure) && (props.protocol !== ProtocolEnumeration.IPV6_FILTER_ICMPV6),
                            validators: [
                                { fn: extraValidators.isNumber, params: '' },
                                { fn: extraValidators.value, params: { min: 0, max: 65535 } },
                            ]
                        }
                    }]}
                ></Input>

                {/* Port To */}
                <Input id={'ip6-port-filtering-port-to' + props.appendID}
                    name={'port_to' + props.appendID}
                    disabled={!props.allowConfigure || (props.protocol === ProtocolEnumeration.IPV6_FILTER_ICMPV6)}
                    label={t('ipv6.ip_port_filtering.label.PORT_TO')}
                    value={props.portInfo.to}
                    dependentValues={!props.allowConfigure || portFromChanged || props.protocol === ProtocolEnumeration.IPV6_FILTER_ICMPV6}
                    onChange={(e) => { props.handlePortTo(e.target.value) }}
                    validators={[{
                        fn: extraValidators.optionalValidators, params: {
                            shouldValidate: () => (props.allowConfigure) && (props.protocol !== ProtocolEnumeration.IPV6_FILTER_ICMPV6),
                            validators: [
                                { fn: extraValidators.isNumber, params: '' },
                                { fn: extraValidators.value, params: { min: 0, max: 65535 } },
                            ]
                        }
                    }]}
                ></Input>

            </div>
        </div>
    )
}
import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom';

import './voip-codec.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipCodecList } from '../../../resources/voip-codec';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import EditIcon from '../../../../components/icons/edit';


export default function VoipCodecList({isWizard, setSaved, isFormSegment, formSegment}) {
    const [voipCodecList, setVoipCodecList] = useState(null)

    const history = useHistory()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {

        fetchVoipCodecList(backend, setVoipCodecList)

        // eslint-disable-next-line
    }, [])

    const editCodec = id => {
        history.push(`/voip/codec/${id}`)
    }

    const getCodecs = (codec, parentKey) => {

        return <ul className="codecs-list">{Object.entries(codec).filter(entry => entry[1].enable).map((entry, index) => <li key={`codecs-list-${parentKey}-${index}`}>{entry[0]}</li>)}</ul>
    }

    const getCodecsAsString = (codec) => {
        const codecs = Object.entries(codec).filter(entry => entry[1].enable).map(entry => entry[0])
        return `${codecs[0]}${ codecs.length > 1 ? '...' : ''}` 
    }

    const voipCodecListTable = () => {
        return <React.Fragment>

            <div className='voip-codecs-box'>
                { voipCodecList.map((cod, key) => 
                    <InfoCard key={key}
                        lines={[
                            {label: t('voip.codec.label.PORT'), value: <span className="uppercase">{cod.voip_port ? cod.voip_port : cod.id } </span> , id: `card-voip-codec-name-${key}`},
                            {label: t('voip.codec.label.CODECS'), value:  getCodecsAsString(cod.type) , id: `card-voip-codec-name-${key}`, infoMessage: getCodecs(cod.type, key) },
                            {label: t('common.label.OPERATIONS'), value:  <span className="card-voip-edit-icon" onClick={e => editCodec(cod.id)}><EditIcon /></span> },
                        ]}
                    ></InfoCard>)
                }
            </div>
            
        </React.Fragment>
    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipCodecList ? <Loading show={true}></Loading> :

        <div id='voip-codec-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="voip.codec.title.CODEC"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>
  
            {voipCodecListTable()}

        </div>
    )
}

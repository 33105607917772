import React from 'react'
import CircledArrow from '../../../components/icons/circled-arrow';

import './traffic-indicator.css'

export default function TrafficIndicator({traffic, label, isUpload, isHorizontal, id}){

    return <div className={`traffic-indicator ${isHorizontal ? 'horizontal' : ''}`}>

        <div id={isUpload ? 'internet-upload' : 'internet-download'} className={`traffic-indicator-icon ${isUpload ? 'upload' : ''}`}>
            <CircledArrow size='24px'></CircledArrow>
            <div className='traffic-indicator-label'>{label}</div>
        </div>
        <div className='traffic-indicator-text'>
            <div id={id || null} className='traffic-indicator-value'>
                {traffic}
                <span className='traffic-indicator-unit'>Mbps</span>
            </div>
        </div>
    </div>
}

import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom';

import './network-wan.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchNetworkWanList, deleteWanById } from '../../../resources/network-wan';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import EditIcon from '../../../../components/icons/edit';
import RemoveIcon from '../../../../components/icons/remove';
import Button from '../../../../components/button/button';
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next';

const MAX_WANS = 5

export default function NetworkWanList({isWizard, setSaved, isFormSegment, formSegment}) {
    const [networkWanList, setNetworkWanList] = useState(null)

    const [deleteWanData, setDeleteWanData] = useState(null)

    const history = useHistory()

    const { t } = useTranslation()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchNetworkWanList(backend, setNetworkWanList)
        setDeleteWanData({showDelete: false, id: null})

        // eslint-disable-next-line
    }, [])

    const editWan = id => {
        history.push(`/network/wan/${id}`)
    }

    const insertWan = () => {
        history.push(`/network/wan/new`)
    }

    const continueDelete = async (confirm) => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        setDeleteWanData({...deleteWanData, showDelete: false})
        if(confirm) {
            deleteWan(deleteWanData.id)
        }
    }

    const deleteWan = async id => {
        setSaving(true)
        setNetworkWanList(null)
        let result = await deleteWanById(backend, id, setNetworkWanList)
        setSaving(false)
        if(result) {
            setSuccess(true)
            setError(false)
            setDeleteWanData({showDelete: false, id: null})
        } else {
            setSuccess(false)
            setError(true)
            setDeleteWanData({showDelete: false, id: null})
        }
    }

    const getWan = () => {
        return networkWanList.filter(wan => !wan.omci_configured).map(wan => ({
            id: wan.id,
            interfaceID: wan.interfaceID,
        }));
    }

    const showDeleteWan = id => {
        setDeleteWanData({showDelete: true, id: id})
    }

    const defineInterface = wanId => {
        if(wanId === 'wan.v0')
            return <Translator path="network.wan.title.STANDARD_INTERFACE"></Translator>
        
        return wanId;
    }

    const networkWanListTable = () => {
        return <React.Fragment>
            <div className="network-wans-new-button">
                <Button
                    id='network-wans-new'
                    text={<Translator path="network.wan.title.INSERT_NEW_WAN"></Translator>}
                    onClick={e => insertWan()}
                    disabled={networkWanList?.length >= MAX_WANS}
                ></Button>
                <div className="align-center">
                    <div className="disabled-ports-warning">
                        { networkWanList?.length >= MAX_WANS && <strong><Translator path="network.wan.message.warning.MAX_WANS_REACHED"></Translator></strong> }
                    </div>   
                </div>
            </div>
            <div className='network-wans-box'>
                { getWan().map((wan, key) => 
                    <InfoCard key={key}
                        lines={[
                            {label: t('network.wan.label.INTERFACE'), value: 
                            <span> 
                                {defineInterface(wan.interfaceID)}
                            </span>},
                            {label: t('common.label.ACTIONS'), value: <>                    
                                <span title={t('common.label.EDIT')} className="card-network-wan-edit-icon" onClick={e => editWan(wan.id)}>
                                    <EditIcon />
                                </span>
                                <span title={t('common.label.DELETE')} className="card-network-wan-delete-icon" onClick={e => showDeleteWan(wan.id)}>
                                    <RemoveIcon />
                                </span>
                            </>}
                        ]}
                    ></InfoCard>)
                }
            </div>
            {getWan().length === 0 ?
                <div className='info-card noelements-card'>
                    <Translator path="network.wan.message.NO_WANS_REGISTERED"></Translator>
                </div>
                :
                null
            }
        </React.Fragment>
    }

    return (
        !networkWanList ? <Loading show={true}></Loading> :

        <div id='network-wan-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="network.wan.title.WAN_LIST"></Translator></div>

            <DefaultStatusModals
                    error={error}
                    errorText={t('network.lan.error.ERROR_MESSAGE_CUSTOM')}
                    saving={saving}
                    savingText={<Translator path="common.label.WAIT"></Translator>}
                    success={success}
                    successText={t('service.dynamic_dns.message.delete.DELETE_SUCCESS')}
                    confirm={deleteWanData.showDelete}
                    continueFn={continueDelete}
                    confirmContent={
                        <div style={{ width: '420px' }}>
                            <span style={{ display: 'block' }}><Translator path="network.wan.warning.PERMANENT_DELETE_WARNING"></Translator>
                                <br></br>
                            </span>
                            <b><Translator path="network.lan.warning.CONFIRM_EXCLUSION"></Translator></b>
                        </div>
                    }
                    isWarningModal={true}
                    dismissText={<Translator path="common.label.CANCEL"></Translator>}
                    confirmText={<Translator path="common.label.CONFIRM"></Translator>}
            ></DefaultStatusModals>
  
            {networkWanListTable()}

        </div>
    )
}

import React, { useState, useEffect, useContext } from 'react'
import Input from '../../../../components/input/input';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import { fetchInterfaceLan, fetchLan, LAN_COMMS_LOSS_FLAG, updateInterfaceLan, updateLan } from '../../../resources/lan';
import { BackendContext } from '../../../../backend/backend';
import DhcpLeaseIcon from '../../../../components/icons/dhcp-lease-icon';
import ChevronRightIcon from '../../../../components/icons/chevron-right';
import EyeIcon from '../../../../components/icons/eye-icon';

import './lan.css'
import iputils from '../../../../components/utils/iputils'
import waitRouter from '../../../utils/wait-router';
import { GlobalsContext } from '../../../globals-context';
import { useHistory } from 'react-router-dom';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import LanDhcpServer from './dhcp-server';
import { deleteDhcpLease, hasLeasesToDelete } from '../../../resources/dhcp-lease';
import confirmContent from './dhcp-lease-confirm';
import TooltipText from '../../../../components/tooltip-text/tooltip-text';
import { MainHeaderContext } from '../../../../components/main-header/main-header-state';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators';

const LAN_WAIT_TIME = 10000

export default function NetworkLan() {

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [interfaceLan, setInterfaceLan] = useState(null)
    const [lan, setLan] = useState(null)
    // const [originalLan] = useState(null)
    const [currentLan, setCurrentLan] = useState(null)
    const [confirm, setConfirm] = useState(false)
    const [leasesToDelete, setLeasesToDelete] = useState([])
    const [dhcpServer, setDhcpServer] = useState(null)
    const [IpErrorMsg] = useState('')

    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const history = useHistory()
    const header = useContext(MainHeaderContext)

    const { t } = useTranslation()

    useEffect(() => {
        header.title.set(t('network.label.NETWORK'))
        fetchInterfaceLan(backend, setInterfaceLan)
        fetchLan(backend, setLan)
        fetchInterfaceLan(backend, setCurrentLan)

        // eslint-disable-next-line
    }, [])

    const submit = async () => {
        setErrorMessage("")

        if (saving) return

        setConfirm(false)
        setSaving(true)

        leasesToDelete.forEach(async (lease) => {
            await deleteDhcpLease(backend, lease.id)
        })

        lan.id = lan.interfaceID

        let ok = await updateInterfaceLan(backend, interfaceLan, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        ok = await updateLan(backend, lan, LAN_WAIT_TIME)
        if (!ok) {
            setErrorMessage(t("common.message.error.CHECK_CONNECTION"))
            setSaving(false)
            setError(true)
            return
        }
        else if (ok === LAN_COMMS_LOSS_FLAG) {
            setErrorMessage(t("network.lan.warning.RECONNECT_TO_NETWORK"))
            setSaving(false)
            setError(true)
            return
        }

        backend.backendApply()

        // if (window.location.hostname === originalLan.ip4){
        //     backend.setDriverBaseUrl(`http://${lan.ip4}/api/v1`)
        // }

        ok = await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history
        )

        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        fetchInterfaceLan(backend, setInterfaceLan)
        fetchInterfaceLan(backend, setCurrentLan)

        setSaving(false)
        setSuccess(true)

    }

    const dismiss = async (confirmed) => {
        if (confirm && confirmed) {
            await submit()
        } else {
            setSaving(false)
            setSuccess(false)
            setError(false)
            setConfirm(false)
        }
        setLeasesToDelete([])
    }

    const submitConfirm = async () => {

        if (saving || success || error || confirm) return

        const isOutOfRange = (ip) => !iputils.sameNetwork(ip, interfaceLan.ip4, interfaceLan.netmask)
        const needConfirm = await hasLeasesToDelete(backend, setLeasesToDelete, isOutOfRange)

        if (needConfirm) {
            setConfirm(true)
        } else {
            submit()
        }
    }

    const shouldDisableFormButton = () => {
        return IpErrorMsg !== ""
    }

    function interfaceLanErrorMessage(message) {
        if (message === 'ACL Admin must be disabled to avoid lost of access')
            return t('network.lan.error.ACL_ADMIN_MUST_BE_DISABLE_TO_AVIOD_LOST_OF_ACCESS');
        else
            return message;
    }

    return !interfaceLan ? <Loading show={true}></Loading> :
        <div id='lan-page'>

            <DefaultStatusModals
                saving={saving}
                success={success}
                error={error}
                continueFn={dismiss}
                savingText={<div>
                    <Translator path="network.lan.warning.WAIT_FOR_APPLY"></Translator><br></br>
                    <Translator path="network.lan.warning.MAY_BE_NECESSARY_TO_RECONNECT"></Translator>
                </div>}
                errorText={interfaceLanErrorMessage(errorMessage)}
                confirm={confirm}
                isWarningModal={true}
                confirmContent={confirmContent(leasesToDelete.length)}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <div className='subtitle'><Translator path="network.lan.title.LAN_PAGE"></Translator></div>
            <div id='lan-page-content'>
                <div className='lan-buttons-div'>
                    <Form onSubmit={submitConfirm} buttonId='button-save-lan' disableButton={shouldDisableFormButton()}>

                        <div className='subtitle'><Translator path="network.lan.title.LAN"></Translator></div>

                        <Input id='lan-ip'
                            label={<Translator path="network.lan.label.IPV4_ADDRESS" required={true}></Translator>}
                            value={interfaceLan.ip4}
                            name="ip"
                            onChange={(e) => {
                                setInterfaceLan({ ...interfaceLan, ip4: e.target.value })
                            }}
                            validators={[
                                extraValidators.required, 
                                extraValidators.validateIPv4,
                                extraValidators.validateIfNotLocalhost
                            ]}
                            errorMessage={IpErrorMsg}
                        ></Input>
                        <Input id='lan-netmask'
                            label={<Translator path="network.lan.label.NETMASK" required={true}></Translator>}
                            value={interfaceLan.netmask}
                            name="netmask"
                            onChange={(e) => {
                                setInterfaceLan({ ...interfaceLan, netmask: e.target.value })
                            }}
                            validators={[extraValidators.required, extraValidators.validateSubNetMask]}
                        ></Input>

                    </Form>

                    &nbsp;

                    <div id='dhcp-leases-button' className='card' tooltip={dhcpServer && !dhcpServer.enabled ? '1' : '0'}
                        onClick={() => { history.push('/network/lan/dhcp-lease') }}
                    >
                        <TooltipText width='340px'
                            message={<Translator path="network.lan.warning.DHCP_STATIC_LEASE"></Translator>}>
                        </TooltipText>
                        <DhcpLeaseIcon size={25} color='blue'></DhcpLeaseIcon>
                        <span>{<Translator path="network.lan.label.RESERVATION"></Translator>}</span>
                        <ChevronRightIcon size={22}></ChevronRightIcon>
                    </div>

                    &nbsp;

                    <div id='dhcp-active-clients-button' className='card' tooltip={dhcpServer && !dhcpServer.enabled ? '1' : '0'}
                        onClick={() => { history.push('/network/lan/dhcp-active-clients') }}
                    >
                        <TooltipText width='340px'
                            message={<Translator path="network.lan.warning.DHCP_ACTIVE_CLIENT"></Translator>}>
                        </TooltipText>
                        <EyeIcon></EyeIcon>
                        <span>{<Translator path="network.lan.title.DHCP_ACTIVE_CLIENTS"></Translator>}</span>
                        <ChevronRightIcon size={22}></ChevronRightIcon>
                    </div>

                    &nbsp;

                </div>

                <div className='lan-dhcp-server-div'>
                    <LanDhcpServer dhcpServer={dhcpServer} setDhcpServer={setDhcpServer} lanInterface={interfaceLan} baseLanInterface={currentLan}></LanDhcpServer>
                </div>
            </div>
        </div>
}

import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'

import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import ParentalControlState, { ParentalControlContext } from './parental-control-context';
import { useTranslation } from 'react-i18next';
import TabsMobile from '../../common/components/tabs/tabs-mobile';
import ConnectedDevices from './connected-devices/connected-devices'
import ParentalControlParentalControl from './parental-control/parental-control-parental-control'
import ParentalControlParentalControlEdit from './parental-control/parental-control-parental-control-edit';
/*IMPORTS_TAG*/

export default function ParentalControlPage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const tabs = [
        { label: t('parental_control.label.CONNECTED_DEVICES'), route: '/parental-control/connected-devices' },
        { label: t('menu.PARENTAL_CONTROL'), route: '/parental-control/parental-control' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.PARENTAL_CONTROL'))
        header.backRoute.set('/parental-control/connected-devices')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('parental-control') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <ParentalControlState>

            <ParentalControlContext.Consumer>
                {({ currentParentalControlMode }) => (
                    <TabsMobile
                        data={tabs}
                        route={history.location.pathname}
                        activateFn={changeRoute}
                    ></TabsMobile>
                )}
            </ParentalControlContext.Consumer>

            <Switch>
                <Route path='/parental-control/connected-devices' render={(props) => { 
                    return <ConnectedDevices {...props} ></ConnectedDevices> 
                }}></Route>

                <Route path='/parental-control/parental-control/:id' render={(props) => {
                    return <ParentalControlParentalControlEdit {...props} ></ParentalControlParentalControlEdit> 
                }}></Route>
                    
                <Route path='/parental-control/parental-control' render={(props) => { 
                    return <ParentalControlParentalControl {...props} ></ParentalControlParentalControl> 
                }}></Route>

                {/*ROUTES_TAG*/}
            </Switch>
        </ParentalControlState>
    )
}

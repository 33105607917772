export const MAX_RULES_NUM = 16
export const USERNAME_MAX_LENGTH = 31

export const AddrTypeEnumeration = {
    PARENTAL_CONTROL_ADDR_IP: 0,
    PARENTAL_CONTROL_ADDR_MAC: 1
}

export const ParentalControlElementStruct = {
    username: "",
    address_type: AddrTypeEnumeration.PARENTAL_CONTROL_ADDR_IP,
    ip_address_from: "",
    ip_address_to: "",
    mac_address: "",
    days: 0,
    hours_begin: 0,
    minutes_begin: 0,
    hours_end: 0,
    minutes_end: 0
}

export const getDaysArray = (days, t) => {
    let daysArray = []
    let daysName = [
        t('common.label.SUNDAY'),
        t('common.label.MONDAY'),
        t('common.label.TUESDAY'),
        t('common.label.WEDNESDAY'),
        t('common.label.THURSDAY'),
        t('common.label.FRIDAY'),
        t('common.label.SATURDAY'),
    ]

    for (let i = 0; i < 7; i++) {
        let mask = 1 << i;
        daysArray.push({ en: (days & mask) ? true : false, name: daysName[i] })
    }

    return daysArray
}

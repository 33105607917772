import React from 'react'
import createAction from '../resources/action'
import modalContents from '../utils/modal-contents'
import Modal, { ModalTypes } from "../../components/modal/modal"
import waitRouter from "./wait-router"
import Translator from '../common/components/translator/translator'

const REBOOT_ACTION = 1

export const reboot = async (history, globals, saving, setSaving, setError, setSuccessText, setSavingText, backend, setSuccess) => {

    if(saving) return

    setSavingText(
        <div>
            <Translator path="system.reboot.warning.REBOOTING"></Translator>    
            <br></br>
            <Translator path="system.reboot.warning.MAY_HAVE_TO_RECONNECT"></Translator>
        </div>
    )
    setSaving(true)

    let ok = await createAction(backend, {actionID: REBOOT_ACTION})
    if(!ok){
        setSaving(false)
        setError(true)
        return
    }

    ok = await waitRouter(
        backend,
        globals.getItem('username'),
        globals.getItem('password'),
        history
    )
    if(!ok){
        setSaving(false)
        setError(true)
        return
    }

    setSaving(false)
    setSuccessText(modalContents.getRebootSucessContent())
    setSuccess(true)

}

export const showRebootModal = (confirm, rebootModalDismiss, rebootMessage) => {
    return <Modal 
    show={confirm}
    title={<Translator path="system.reboot.warning.MUST_REBOOT"></Translator>}
    type={ModalTypes.CONFIRM_WARNING}
    onDismissClick={rebootModalDismiss} 
    content={
        <React.Fragment>
            {rebootMessage}
        </React.Fragment>
    } 
    dismissText={<Translator path="modal.answer.NO"></Translator>}
    confirmText={<Translator path="modal.answer.YES"></Translator>}
    ></Modal>
}

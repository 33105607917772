import { backendStatus } from '../../backend/backend'

export const easyMeshType = {
    CONTROLLER: 0,
    AGENT: 1,
}

export const easyMeshSyncStatus = {
    STOPPED: 0,
    RUNNING: 1,
    CONNECTED: 2
}

export const easyMeshBackhaul = {
    AUTO: 0,
    _2_4GHZ: 1,
    _5_0GHZ: 2,
    ETH: 3
}

export async function fetchEasyMesh(backend, setEasyMesh) {
    let result = await backend.retrieveFresh(`easymesh`)
    if(result.status === backendStatus.SUCCESS){
        setEasyMesh(result.content[0])
        return result.content[0]
    }

    return false
}

export async function updateEasyMesh(backend, easyMesh) {
    delete easyMesh.syncing

    try {

        let result = await backend.update('easymesh/0', easyMesh)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error updating easyMesh:', error)
        return false
    }

}

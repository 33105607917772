import React from 'react'

export default function WpsIcon({size}) {
return <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size} height={size} viewBox="0 0 48 48"
        >
        <g transform="matrix(1.182 0 0 1.2008 -79.533 -104.15)">
            <path d="m85.836 88.756c-9.4336 2.9401-15.998 8.9916-13.917 24.764l-4.4696 1.4345c4.7085 8.6182 9.7172 11.751 14.205 11.867 4.3826-4.1816 5.2328-10.514 4.5225-17.796l-6.1807 1.9992c-2.1129-10.607 1.2419-16.7 5.8397-22.268z"/>
            <path d="m89.673 124.91c9.4336-2.9401 15.998-8.9916 13.917-24.764l4.4696-1.4345c-4.7085-8.6182-9.7172-11.751-14.205-11.867-4.3826 4.1816-5.2328 10.514-4.5225 17.796l6.1807-1.9992c2.1129 10.607-1.2419 16.7-5.8397 22.268z"/>
        </g>
        </svg>

}
import React, { useState, useEffect, useContext } from 'react'

import './ipv6-static-route.css'
import { BackendContext } from '../../../../backend/backend'
import { updateIpv6StaticRoute, saveIpv6StaticRoute, fetchIpv6StaticRouteById, fetchIpv6StaticRoute } from '../../../resources/ipv6-static-route';
import Loading from '../../../../components/loading/loading';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom'
import Translator from '../../../common/components/translator/translator';
import Input from '../../../../components/input/input';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form from '../../../../components/form/form';
import Checkbox from '../../../../components/checkbox/checkbox';
import extraValidators from '../../../common/validators';
import Select from '../../../../components/select/select';
import Button from '../../../../components/button/button'
import { fetchWanList } from '../../../resources/wan';
import { IPv6Context } from '../ipv6-context';
import { fetchServiceSystem } from '../../../resources/system';

export default function Ipv6StaticRouteElementEdit({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [ipv6StaticRouteElement, setIpv6StaticRouteElement] = useState(
        {
            enable: false,
            destination: '',
            next_hop: '',
            metric: 0,
            interface: 'any'
        })


        const { ipv6StaticRouteElementsList, setIpv6StaticRouteElementsList } = useContext(IPv6Context)
        const backend = useContext(BackendContext)
        const history = useHistory()
        const params = useParams()
        const { t , i18n } = useTranslation()

        const [success, setSuccess] = useState(false)
        const [error, setError] = useState(false)
        const [saving, setSaving] = useState(false)
        const [errorMessage, setErrorMessage] = useState(false)
        const [wanRes, setWanRes] = useState(null)
        const [interfacesOptions, setInterfacesOptions] = useState( [{ value: '', text: ''}] )
        const [dataFetched, setDataFetched] = useState(false)
        const [updateInterfacesOptions, setUpdateInterfacesOptions] = useState(false)
        let [system, setSystem] = useState(true)

        useEffect(() => {
            fetchServiceSystem(backend, setSystem)
            const fetchData = async () => {
                if(ipv6StaticRouteElementsList === null){ 
                    await fetchIpv6StaticRoute(backend, setIpv6StaticRouteElementsList)
                }
                await fetchWanList(backend, setWanRes)
                setDataFetched(true)
            }
            fetchData()
            // eslint-disable-next-line
        }, [])

        useEffect(() => {
            if(dataFetched === true) {
                if(params.id !== 'add') {
                    if(ipv6StaticRouteElementsList !== null && ipv6StaticRouteElementsList.length > 0) {
                        setIpv6StaticRouteElement( ipv6StaticRouteElementsList.filter( element => element.id === params.id )[0] )
                    }
                    else {
                        fetchIpv6StaticRouteById(backend, setIpv6StaticRouteElement, params.id)
                    }
                }
                setUpdateInterfacesOptions(true) 
            }
            // eslint-disable-next-line
        }, [dataFetched,ipv6StaticRouteElementsList])

        useEffect(() => {

            if(updateInterfacesOptions === true) {
                if(!!wanRes) {
                    let options = wanRes.filter((item) => item.protocol !== 0).map( (item) => {
                        return ( { value: item["interfaceID"], text: item["interfaceID"] } )
                    })

                    options.unshift({ value: "any", text: t('ipv6.ipv6_static_route.label.INTERFACE_ANY')})
                    setInterfacesOptions(options)
                    setIpv6StaticRouteElement( {
                            ...ipv6StaticRouteElement, interface: ipv6StaticRouteElement.interface
                        
                    })
                }
            }
            // eslint-disable-next-line
    }, [updateInterfacesOptions, wanRes, i18n.language])

    const submit = async() => {
        if(saving || error || success) return

        setSaving(true)
        let ok = null
        if(params.id === 'add')
            ok = await saveIpv6StaticRoute(backend, ipv6StaticRouteElement, setErrorMessage) 
        else {
            ok = await updateIpv6StaticRoute(backend, ipv6StaticRouteElement, setErrorMessage) 
        }

        if(!ok){
            setSaving(false)
            setError(true)
            return
        }
        setSaving(false)
        setSuccess(true)
    }

    const dismiss = () => {
        history.push('/ipv6/ipv6-static-route')
    }

    const continueFn = () => {
        let is_error = error
        setSuccess(false)
        setError(false)
        setSaving(false)
        if(!is_error) {
            history.push('/ipv6/ipv6-static-route')
        }
    }

    const checkIfRouteExists = (destination) => {

        if (ipv6StaticRouteElementsList === null || ipv6StaticRouteElementsList.length === 0)
            return false

        let result = ipv6StaticRouteElementsList.filter( element => {
            return element.destination === destination && 
                   element.id !== ipv6StaticRouteElement.id
        }
        )

        return Boolean(result.length > 0)
    }

    const validateIfRouteDestinationExists = (destination) => {
        let result = checkIfRouteExists(destination)
        return (result ? t('ipv6.ipv6_static_route.error.ROUTE_ALREADY_EXISTS') : '')
    }

    const translateErrorMessagesFromBackend = (text) => {
        if(text === 'field not found'){ 
            return t('ipv6.ipv6_static_route.error.FIELD_NOT_FOUND')
        }
        if(text === 'destination already in use'){
            return t('ipv6.ipv6_static_route.error.DESTINATION_FAIL')
        }
        if(text === 'Update route fails'){
            return t('ipv6.ipv6_static_route.error.ROUTE_FAIL')
        }
        if(text === 'create route fails. Please check if Next Hop is in a well known network on desired interface.'){
            return t('ipv6.ipv6_static_route.error.NEXTHOP_FAIL')
        }
     }

     const hasIPv6Requirements = () => {
         if(isWizard || system === true) {
             return true
         }
 
         if(system) {
             if(system.ipv6) {
                 if(system.ipv6.enabled) {
                     return true
                 }
             }
         }
 
         return false
     }

     function hasIpv6Interfaces() {
        if(!dataFetched)
            return true

        for (var i = 0; i < wanRes?.length; i++) {
            const element = wanRes[i];

            if (element.protocol !== 0)
                return true
        }

        return false
    }

    return ( !ipv6StaticRouteElement ? <Loading show={true}></Loading> :

    <div id='ipv6-static-route-edit-page' className='with-tabs'>  

        <DefaultStatusModals
            success={success}
            error={error}
            errorText={
                <>
                    <div>
                        {t('ipv6.ipv6_static_route.error.ERROR_SERVER')}
                    </div>
                    <br />
                    <div>
                        {translateErrorMessagesFromBackend(errorMessage)}
                    </div>
                </>
            }
            saving={saving}
            continueFn={continueFn}
            isWarningModal={true}
            successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
        ></DefaultStatusModals>

        {
            hasIPv6Requirements() ? null : 
            <div>
                <p className='ipv6-static-route-edit-info'>
                    {t(`ipv6.ipv6_static_route.message.IPV6_DISABLED`)}
                    <br/>
                    {t(`common.message.warning.IPV6_ENABLE`)}
                    <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                </p>
            </div>
        }   

        <div className='section-divider'></div>

        <Button
            id='new_ipv6_static_route'
            text={t('common.label.BACK')}
            onClick={dismiss}
        ></Button>

        {
            hasIpv6Interfaces() ? null :
                <div>
                    <p className='ipv6-ip-port-filter-info '>
                        {t(`ipv6.ipv6_static_route.error.WAN_WARNING`)}
                        <br />
                        {t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN`)}
                        <a href='#/network/wan'>{t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN_PAG`)}</a>
                        {t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN_END`)}.
                        <br />
                    </p>
                </div>
        }

        <div className='card mt2'>
            <div className='subtitle'>
                {params.id === 'add' ? 
                    <Translator path="ipv6.ipv6_static_route.title.STATIC_ROUTE_CREATE"/>
                    :
                    <Translator path="ipv6.ipv6_static_route.title.STATIC_ROUTE_EDIT"/>
                }
            </div>

                <Form
                    buttonId='button-save-ipv6-static-route'
                    onSubmit={submit}
                    disableButton={!hasIPv6Requirements() || !hasIpv6Interfaces()}
                >
                    <Checkbox id='ipv6-static-route-edit-enable'
                        label={t('common.label.ENABLE')}
                        value={ipv6StaticRouteElement.enable}
                        toggleFn={() => {
                            ipv6StaticRouteElement.enable = !ipv6StaticRouteElement.enable
                            setIpv6StaticRouteElement({...ipv6StaticRouteElement})
                        }}
                    ></Checkbox>

                    <Input id='ipv6-static-route-edit-destination'
                        name='route-destination'
                        label={t('ipv6.ipv6_static_route.label.DESTINATION')}
                        placeholder={t('ipv6.ipv6_static_route.placeholder.VALID_IPV6_INPUT')}
                        value={ipv6StaticRouteElement.destination}
                        dependentValues={[ipv6StaticRouteElement]}
                        validators={[
                            extraValidators.required,
                            extraValidators.validateIPv6,
                            extraValidators.validateIfNotLocalhost,
                            validateIfRouteDestinationExists
                        ]}
                        onChange={(e) => {setIpv6StaticRouteElement( {
                                ...ipv6StaticRouteElement, destination: e.target.value
                            })}}
                    ></Input>

                    <Input id='ipv6-static-route-edit-next_hop'
                        name='route-next_hop'
                        label={t('ipv6.ipv6_static_route.label.NEXT_HOP')}
                        placeholder={t('ipv6.ipv6_static_route.placeholder.VALID_IPV6_INPUT')}
                        value={ipv6StaticRouteElement.next_hop}
                        dependentValues={[ipv6StaticRouteElement]}
                        validators={[
                            extraValidators.required,
                            extraValidators.validateIPv6,
                            extraValidators.validateIfNotLocalhost
                        ]}
                        onChange={ (e) => {setIpv6StaticRouteElement( {
                                ...ipv6StaticRouteElement, next_hop: e.target.value
                            })}}
                    ></Input>

                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Input id='ipv6-static-route-edit-metric'
                            name='route-metric'
                            label={t('ipv6.ipv6_static_route.label.METRIC')}
                            type="number"
                            placeholder={t('ipv6.ipv6_static_route.placeholder.VALID_METRIC_INPUT')}
                            value={ipv6StaticRouteElement.metric}
                            dependentValues={[ipv6StaticRouteElement]}
                            validators={[ extraValidators.required, {fn: extraValidators.value, params: { min: 0, max: 16 }} ]}
                            onChange={ (e) => { setIpv6StaticRouteElement( {
                                    ...ipv6StaticRouteElement, metric: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                })}}
                        ></Input>

                        <Select
                            id='ipv6-static-route-edit-interface'
                            label={t('ipv6.ipv6_static_route.label.INTERFACE')}
                            options={interfacesOptions}
                            value={ipv6StaticRouteElement.interface}
                            onChange={ (e) => { setIpv6StaticRouteElement( {
                                    ...ipv6StaticRouteElement, interface: e.target.value
                                })}}
                        ></Select>
                    </div>
                </Form>
            </div>
        </div>
        
    );
}
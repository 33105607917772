import React, { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'

import './voip-advanced-sip.css'
import { BackendContext } from '../../../../backend/backend'
import {
    fetchVoipAdvancedSip, saveVoipAdvancedSip, DTMFRelayOptions,
    HookFlashRelayOptions, advancedSipConstants
} from '../../../resources/voip-advanced-sip';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input'
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import Select from '../../../../components/select/select';
import extraValidators from '../../../common/validators';
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function VoipAdvancedSip({ isWizard, setSaved, isFormSegment, formSegment, history }) {
    const [voipAdvancedSip, setVoipAdvancedSip] = useState(null)

    const [voipAdvancedSipOriginal, setVoipAdvancedSipOriginal] = useState(null)

    const params = useParams()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchVoipAdvancedSip(params.id, backend, setVoipAdvancedSipOriginal)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (voipAdvancedSipOriginal) {
            setVoipAdvancedSip({ ...voipAdvancedSipOriginal, dtmf: { ...voipAdvancedSipOriginal?.dtmf }, speaker_agc: { ...voipAdvancedSipOriginal?.speaker_agc }, mic_agc: { ...voipAdvancedSipOriginal?.mic_agc } })
        }
        // eslint-disable-next-line
    }, [voipAdvancedSipOriginal])

    const voipAdvancedSipForm = () => {
        return <React.Fragment>
            <div className='subtitle'><Translator path="voip.advanced_sip.title.VOIP_ADVANCED_SIP"></Translator></div>
            <div className='row'>
                <Input id='voip-advanced-sip-rx-gain'
                    name='rx_gain'
                    label={<Translator path="voip.advanced_sip.label.RX_GAIN" required={true}></Translator>}
                    value={voipAdvancedSip.rx_gain}
                    onChange={(e) => {
                        voipAdvancedSip.rx_gain = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                        setVoipAdvancedSip({ ...voipAdvancedSip })
                    }}
                    validators={[extraValidators.nonASCII, extraValidators.required, extraValidators.isNumber,
                    { fn: extraValidators.value, params: { min: advancedSipConstants.MIN_GAIN, max: advancedSipConstants.MAX_GAIN } }]}
                ></Input>

                <Input id='voip-advanced-sip-tx-gain'
                    name='tx_gain'
                    label={<Translator path="voip.advanced_sip.label.TX_GAIN" required={true}></Translator>}
                    value={voipAdvancedSip.tx_gain}
                    onChange={(e) => {
                        voipAdvancedSip.tx_gain = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                        setVoipAdvancedSip({ ...voipAdvancedSip })
                    }}
                    validators={[extraValidators.nonASCII, extraValidators.required, extraValidators.isNumber,
                    { fn: extraValidators.value, params: { min: advancedSipConstants.MIN_GAIN, max: advancedSipConstants.MAX_GAIN } }]}
                ></Input>
            </div>
            <Select
                id='voip-advanced-sip-hook-flash-relay'
                name='hook_flash_relay'
                label={<Translator path="voip.advanced_sip.label.HOOK_FLASH_RELAY"></Translator>}
                options={HookFlashRelayOptions}
                value={voipAdvancedSip.hook_flash_relay}
                onChange={(e) => {
                    voipAdvancedSip.hook_flash_relay = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                    setVoipAdvancedSip({ ...voipAdvancedSip })
                }}
            ></Select>

            <Checkbox id='voip-advanced-sip-dtmf-as-faxmodem'
                name='dtmf_as_faxmodem'
                label={<Translator path="voip.advanced_sip.label.DTMF_DTMF_AS_FAXMODEM"></Translator>}
                value={voipAdvancedSip?.dtmf?.dtmf_as_faxmodem}
                toggleFn={(e) => {
                    voipAdvancedSip.dtmf.dtmf_as_faxmodem = !voipAdvancedSip.dtmf.dtmf_as_faxmodem;
                    setVoipAdvancedSip({ ...voipAdvancedSip })
                }}
                validators={[]}
            ></Checkbox>

            <Checkbox id='voip-advanced-sip-sip-prack'
                name='dtmf_as_faxmodem'
                label={<Translator path="voip.advanced_sip.label.SIP_PRACK"></Translator>}
                value={voipAdvancedSip?.sip_prack}
                toggleFn={(e) => {
                    setVoipAdvancedSip((voipAdvancedSip) => {
                        voipAdvancedSip.sip_prack = !voipAdvancedSip.sip_prack
                        return { ...voipAdvancedSip }
                    })
                }}
                validators={[]}
            ></Checkbox>

            <div className="voip-advanced-sip-wrapper">
                <div className="voip-advanced-sip-section">
                    <div className="column">

                        <Select
                            id='voip-advanced-sip-dtmf-relay'
                            name='dtmf_relay'
                            label={<Translator path="voip.advanced_sip.label.DTMF_DTMF_RELAY"></Translator>}
                            options={DTMFRelayOptions}
                            value={voipAdvancedSip?.dtmf?.dtmf_relay}
                            onChange={(e) => {
                                setVoipAdvancedSip({ ...voipAdvancedSipOriginal, dtmf: { ...voipAdvancedSipOriginal.dtmf, dtmf_relay: isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value) } })
                            }}
                        ></Select>
                        <Input id='voip-advanced-sip-dtmf-payload'
                            name='dtmf_payload'
                            label={<Translator path="voip.advanced_sip.label.DTMF_DTMF_PAYLOAD" required={true}></Translator>}
                            value={voipAdvancedSip?.dtmf?.dtmf_payload}
                            onChange={(e) => {
                                voipAdvancedSip.dtmf.dtmf_payload = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[extraValidators.nonASCII, extraValidators.required, extraValidators.isNumber, { fn: extraValidators.value, params: { min: 96, max: 127 } }]}
                            readOnly={voipAdvancedSip.dtmf?.dtmf_relay === 0 ? 0 : 1}
                            dependentValues={[voipAdvancedSip?.dtmf?.dtmf_relay]}
                        ></Input>

                        <Input id='voip-advanced-sip-dtmf-packet-interval'
                            name='dtmf_packet_interval'
                            label={<Translator path="voip.advanced_sip.label.DTMF_PACKET_INTERVAL" required={true}></Translator>}
                            value={voipAdvancedSip?.dtmf?.dtmf_packet_interval}
                            onChange={(e) => {
                                voipAdvancedSip.dtmf.dtmf_packet_interval = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[extraValidators.nonASCII, extraValidators.required, extraValidators.isNumber, { fn: extraValidators.value, params: { min: 1, max: 999 } }]}
                            readOnly={voipAdvancedSip.dtmf?.dtmf_relay === 0 ? 0 : 1}
                            dependentValues={[voipAdvancedSip?.dtmf?.dtmf_relay]}
                        ></Input>
                        <Checkbox id='voip-advanced-sip-mic-agc-enabled'
                            name='enabled'
                            label={<Translator path="voip.advanced_sip.label.MIC_AGC_ENABLED"></Translator>}
                            value={voipAdvancedSip.mic_agc.enabled}
                            toggleFn={(e) => {
                                voipAdvancedSip.mic_agc.enabled = !voipAdvancedSip.mic_agc.enabled;
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[]}
                        ></Checkbox>
                        <Select
                            id='voip-advanced-sip-mic-agc-max-gain-up'
                            name='mic_agc_max_gain_up'
                            label={<Translator path="voip.advanced_sip.label.MIC_AGC_MAX_GAIN_UP"></Translator>}
                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9].map(it => ({ value: it, text: it }))}
                            value={voipAdvancedSip.mic_agc.max_gain_up}
                            onChange={(e) => {
                                voipAdvancedSip.mic_agc.max_gain_up = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[{ fn: extraValidators.value, params: { min: 1, max: 9 } }]}
                        ></Select>
                    </div>
                </div>
                <div className="voip-advanced-sip-section">
                    <div className="column">
                        <Input id='voip-advanced-sip-faxmodem-packet-interval'
                            name='faxmodem_packet_interval'
                            label={<Translator path="voip.advanced_sip.label.FAXMODEM_PACKET_INTERVAL" required={true}></Translator>}
                            value={voipAdvancedSip?.dtmf?.faxmodem_packet_interval}
                            onChange={(e) => {
                                voipAdvancedSip.dtmf.faxmodem_packet_interval = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[extraValidators.nonASCII, extraValidators.required, extraValidators.isNumber, { fn: extraValidators.value, params: { min: 1, max: 999 } }]}
                            readOnly={voipAdvancedSip.dtmf?.dtmf_relay === 0 ? 0 : 1}
                            dependentValues={[voipAdvancedSip?.dtmf?.dtmf_relay]}
                        ></Input>

                        <Input id='voip-advanced-sip-faxmodem-payload'
                            name='faxmodem_payload'
                            label={<Translator path="voip.advanced_sip.label.DTMF_FAXMODEM_PAYLOAD" required={true}></Translator>}
                            value={voipAdvancedSip?.dtmf?.faxmodem_payload}
                            onChange={(e) => {
                                voipAdvancedSip.dtmf.faxmodem_payload = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[extraValidators.nonASCII, extraValidators.required, extraValidators.isNumber, { fn: extraValidators.value, params: { min: 1, max: 9999 } }]}
                            readOnly={voipAdvancedSip.dtmf?.dtmf_relay === 0 ? 0 : 1}
                            dependentValues={[voipAdvancedSip?.dtmf?.dtmf_relay]}
                        ></Input>

                        <Input id='voip-advanced-sip-sip-info-duration'
                            name='sip_info_duration'
                            label={<Translator path="voip.advanced_sip.label.DTMF_SIP_INFO_DURATION" required={true}></Translator>}
                            value={voipAdvancedSip?.dtmf?.sip_info_duration}
                            onChange={(e) => {
                                voipAdvancedSip.dtmf.sip_info_duration = isNaN(Number(e.target.value)) || e.target.value === '' ? e.target.value : Number(e.target.value)
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[extraValidators.nonASCII, extraValidators.required, extraValidators.isNumber, { fn: extraValidators.value, params: { min: 1, max: 9999 } }]}
                            readOnly={voipAdvancedSip.dtmf?.dtmf_relay === 1 ? 0 : 1}
                            dependentValues={[voipAdvancedSip?.dtmf?.dtmf_relay]}
                        ></Input>

                        <Checkbox id='voip-advanced-sip-speaker-agc-enabled'
                            name='enabled'
                            label={<Translator path="voip.advanced_sip.label.SPEAKER_AGC_ENABLED"></Translator>}
                            value={voipAdvancedSip.speaker_agc.enabled}
                            toggleFn={(e) => {
                                voipAdvancedSip.speaker_agc.enabled = !voipAdvancedSip.speaker_agc.enabled;
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[]}
                        ></Checkbox>

                        <Select
                            id='voip-advanced-sip-speaker-agc-max-gain-up'
                            name='max_gain_up'
                            label={<Translator path="voip.advanced_sip.label.SPEAKER_AGC_MAX_GAIN_UP"></Translator>}
                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9].map(it => ({ value: it, text: it }))}
                            value={voipAdvancedSip.speaker_agc.max_gain_up}
                            onChange={(e) => {
                                voipAdvancedSip.speaker_agc.max_gain_up = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                setVoipAdvancedSip({ ...voipAdvancedSip })
                            }}
                            validators={[{ fn: extraValidators.value, params: { min: 1, max: 9 } }]}
                        ></Select>
                    </div>
                </div>
            </div>

        </React.Fragment>
    }

    const submit = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await saveVoipAdvancedSip(backend, voipAdvancedSip)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipAdvancedSip ? <Loading show={true}></Loading> :

            <div id='voip-advanced-sip-page' className='with-tabs'>
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path="menu.VOIP_ADVANCED_SIP"></Translator></div>

                <Button id='back-to-voip-advanced-sips'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={() => history.push('/voip/advanced-sip')}
                ></Button>

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    success={success}
                    continueFn={dismissModal}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                <div className='section-divider'></div>
                <div className="card voip-advanced-sip-card">
                    {!isFormSegment ? <Form
                        onSubmit={submit}
                        buttonId='button-save'
                    >
                        {voipAdvancedSipForm()}
                    </Form> :

                        <FormSegment
                            title={formSegment.title}
                            active={formSegment.active}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={submit}
                        >
                            {voipAdvancedSipForm()}
                        </FormSegment>}
                </div>
            </div>
    )
}

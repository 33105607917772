import { backendStatus } from "../../backend/backend"

export default async function createAction(backend, action) {

    try {

        let result = await backend.create('action', action, undefined, false)

        return result.status === backendStatus.SUCCESS

    }catch(error){
        console.error('Error creating action:', error)
        return false
    }
}
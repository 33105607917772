import React from 'react'

export default function LockActiveIcon({size, color}){

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} fill={color} height="23" viewBox="0 0 23 23">
            <g>
               <path fillRule="nonzero" d="M20.936 9.425h-2.772V6.34C17.911 2.803 14.811 0 11.004 0s-6.946 2.832-7.16 6.4v3.025H0V23h22.042V9.425h-1.106zM5.014 6.4c.181-2.987 2.789-5.36 6.007-5.36 3.218 0 5.78 2.342 5.987 5.3v3.086H5.014V6.4zm15.922 15.516H1.106V10.51h19.83v11.404z"/>
               <path d="M10.542 14.375h1v3.833h-1z"/>
           </g>
        </svg>
    )
}
import { backendStatus } from '../../backend/backend'

export const opMode = {
    ROUTER: 0,
    BRIDGE: 1,
    WISP: 2,
    REPEATE: 3
}

export async function fetchServiceSystem(backend, setServiceSystem) {
    let result = await backend.retrieveFresh('system')

    if(result.status === backendStatus.SUCCESS){

        let serviceSystemList = result.content

        let serviceSystem = serviceSystemList[0]

        if(serviceSystem.dmz.ip4 === '0.0.0.0') {
            serviceSystem.dmz.ip4 = ''
        }

        setServiceSystem(serviceSystem)
    }
}

export async function saveServiceSystem(backend, serviceSystem) {

    try{

        let result = null;

        result = await backend.update(`system/${serviceSystem.id}`, serviceSystem)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving System:', error)
        
        return false
    }

}
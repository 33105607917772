import React, { useEffect, useContext, useState } from 'react'
import { MainHeaderContext } from '../../../../../components/main-header/main-header-state'
import { MeshContext } from '../mesh-context'
import Form from '../../../../../components/form/form'
import Checkbox from '../../../../../components/checkbox/checkbox'
import Loading from '../../../../../components/loading/loading'
import { easyMeshType, updateEasyMesh } from '../../../../resources/easy-mesh'
import { BackendContext } from '../../../../../backend/backend'

import './network-mesh.css'
import { fetchRadioList, frequency } from '../../../../resources/radio'
import { cipher, fetchWirelessList, reduceWireless } from '../../../../resources/wireless'
import DefaultStatusModals from '../../../../../components/modal/default-status-modals'
import { NetworksContext } from '../../networks-context'
import { useTranslation } from 'react-i18next'
import MeshNodesPage from '../mesh-nodes'
import Modal, { ModalTypes } from '../../../../../components/modal/modal'

import Translator from '../../../../common/components/translator/translator';

export default function NetworkMesh() {

    let [confirm, setConfirm] = useState(false)

    const [easyMesh, setEasyMesh] = useState(null)
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const header = useContext(MainHeaderContext)
    const meshContext = useContext(MeshContext)
    const backend = useContext(BackendContext)
    const networks = useContext(NetworksContext)

    const { t } = useTranslation()

    const getMeshTranslation = (type, key) => t(`network.mesh.${type}.${key}`)

    useEffect(() => {
        header.title.set(t('menu.NETWORK'))

        if (!meshContext.easyMesh) meshContext.retrieveEasyMesh()

        fetchWirelessList(backend, networks.wifi.set)
        fetchRadioList(backend, networks.radio.set)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (!networks.wifi.value || !networks.radio.value) return

        networks.reducedWifi.set(reduceWireless(networks.wifi.value, networks.radio.value))

        // eslint-disable-next-line
    }, [networks.wifi.value, networks.radio.value])

    useEffect(() => {
        setEasyMesh(meshContext.easyMesh)

        // eslint-disable-next-line
    }, [meshContext.easyMesh])

    let confirmMesh = () => {
        setConfirm(true)
    }

    const hasMeshRequirements = () => {
        // Easy Mesh is disabled
        if (!easyMesh.enabled) return true

        // If the type is not controller there is no further requirements
        if (easyMesh.type !== easyMeshType.CONTROLLER) return true

        // If there is no wifis networks set Easy Mesh will not work
        if (!networks.reducedWifi || !networks.reducedWifi.value) return false

        // All radios must be enabled
        if (networks.radio.value.some(radio => !radio.enabled)) return false

        // There must be at least one wifi working on dual band mode (cipher and key must also be set)
        return networks.reducedWifi.value.some(wifi => {
            return wifi.isRoot && wifi.frequency === frequency.dualband && wifi.cipher !== cipher.NONE &&
                wifi.key !== ''
        })
    }

    const hasVAPsMeshRequirements = () => {
        // Easy Mesh is disabled
        if (!easyMesh.enabled) return true

        // If the type is not controller there is no further requirements
        if (easyMesh.type !== easyMeshType.CONTROLLER) return true

        // If there is no wifis networks set Easy Mesh will not work
        if (!networks.reducedWifi || !networks.reducedWifi.value) return false

        // All radios must be enabled
        if (networks.radio.value.some(radio => !radio.enabled)) return false

        // VAPs other than 0 must have a security option and password
        return !networks.reducedWifi.value.some(wifi => {
            return (!wifi.isRoot && ((wifi.id[0] !== 'wlan0-vap0') && (wifi.id[0] !== 'wlan1-vap0')) && ((wifi.cipher === cipher.NONE) ||
                wifi.key === ''))
        })
    }

    const has5GHzRadioRequirements = () => {
        // Easy Mesh is disabled
        if (!easyMesh.enabled) return true

        // If the type is not controller there is no further requirements
        if (easyMesh.type !== easyMeshType.CONTROLLER) return true

        // If there is no wifis networks set Easy Mesh will not work
        if (!networks.reducedWifi || !networks.reducedWifi.value) return false

        // All radios must be enabled
        if (networks.radio.value.some(radio => !radio.enabled)) return false

        return !networks.radio.value.some(radio => {
            return (radio.frequency === frequency._5GHz && (radio.configuredChannel === 0 || radio.configuredChannel === 21))
        })
    }

    const hasEncryptionRequirements = () => {
        // Easy Mesh is disabled
        if (!easyMesh.enabled) return true

        // If the type is not controller there is no further requirements
        if (easyMesh.type !== easyMeshType.CONTROLLER) return true

        // If there is no wifis networks set Easy Mesh will not work
        if (!networks.reducedWifi || !networks.reducedWifi.value) return false

        // All radios must be enabled
        if (networks.radio.value.some(radio => !radio.enabled)) return false

        // VAPs other than 0 must have a security option and password
        return !networks.reducedWifi.value.some(wifi => {
            return (wifi.isRoot && wifi.cipher !== cipher.WPA2)
        })
    }

    const submit = async () => {
        setConfirm(false)
        setSaving(true)

        let meshData = { ...easyMesh }
        delete meshData.syncing
        let result = await updateEasyMesh(backend, meshData)

        setSaving(false)

        if (result) {
            setSuccess(true)
        } else {
            setError(true)
        }

        meshContext.retrieveEasyMesh()
    }

    const dismiss = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        setConfirm(false)
    }

    let continueConfirm = (confirmed) => {
        setConfirm(false)

        if (confirmed) {
            submit()
        }
    }

    return (
        !easyMesh ? <Loading show={true}></Loading> :
            <>
                <div id='mesh-page'>
                    <div className='section-divider'></div>
                    <div className='subtitle'> <Translator path="network.mesh.label.INMESH"></Translator></div>

                    <DefaultStatusModals
                        modalID='mesh'
                        saving={saving}
                        success={success}
                        error={error}
                        continueFn={dismiss}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    ></DefaultStatusModals>

                    <Modal
                        modalID='confirm_mesh'
                        title={<Translator path="menu.MESH_DESCRIPTION"></Translator>}
                        show={confirm}
                        content={
                            <div style={{ maxWidth: '420px' }}>
                                <Translator path="network.wifi.message.warning.EXPLAINING_MESH_VAP"></Translator>
                            </div>
                        }
                        type={ModalTypes.CONFIRM}
                        onDismissClick={continueConfirm}
                        dismissText={<Translator path="common.label.CANCEL"></Translator>}
                        confirmText={<Translator path="common.label.CONFIRM"></Translator>}
                    ></Modal>

                    <div className="card mt2">
                        <div className='subtitle'> <Translator path="network.mesh.label.ENABLE_INMESH"></Translator></div>
                        <Form onSubmit={easyMesh.enabled ? confirmMesh : submit} buttonId='button-save-mesh' disableButton={!(hasMeshRequirements() && hasVAPsMeshRequirements() && has5GHzRadioRequirements() && hasEncryptionRequirements())}>
                            <Checkbox
                                name='mesh-enable'
                                label={getMeshTranslation('label', 'ENABLE')}
                                value={easyMesh.enabled}
                                toggleFn={() => {
                                    setEasyMesh({ ...easyMesh, enabled: !easyMesh.enabled })
                                }}
                            ></Checkbox>
                        </Form>
                    </div>

                    {
                        hasMeshRequirements() ?
                            (
                                hasVAPsMeshRequirements() ?
                                    (
                                        has5GHzRadioRequirements() ?
                                            (
                                                hasEncryptionRequirements() ? null
                                                    :
                                                    <div>
                                                        <p className='mesh-info'>
                                                            {getMeshTranslation('message.info', 'WPA2_CONDITIONS_FOR_MESH')}
                                                            <br />
                                                            <a href='#/network/wifi/'>{getMeshTranslation('message.info', 'CONFIG_WIRELESS_NETWORK')}</a>
                                                        </p>
                                                    </div>
                                            ) :
                                            <div>
                                                <p className='mesh-info'>
                                                    {getMeshTranslation('message.info', 'RADIO0_CONDITIONS_FOR_MESH')}
                                                    <br />
                                                    <a href='#/network/wifi/'>{getMeshTranslation('message.info', 'CONFIG_WIRELESS_NETWORK')}</a>
                                                </p>
                                            </div>
                                    ) :
                                    <div>
                                        <p className='mesh-info'>
                                            {getMeshTranslation('message.info', 'VAPS_CONDITIONS_FOR_MESH')}
                                            <br />
                                            <a href='#/network/wifi/'>{getMeshTranslation('message.info', 'CONFIG_WIRELESS_NETWORK')}</a>
                                        </p>
                                    </div>
                            ) :
                            <div>
                                <p className='mesh-info'>
                                    {getMeshTranslation('message.info', 'CONDITIONS_FOR_MESH')}
                                    <br />
                                    <a href='#/network/wifi/'>{getMeshTranslation('message.info', 'CONFIG_WIRELESS_NETWORK')}</a>
                                </p>
                            </div>
                    }



                </div>
                <MeshNodesPage></MeshNodesPage>
            </>
    )

}
export const qosClassificationConstants = {
    RULE_NAME_MAX_LENGTH: 63
}

export const DSCPEnumeration = {
    DEFAULT: 1,
    AF12: 49,
    AF11: 41,
    CS1: 33,
    AF23: 89,
    AF22: 81,
    AF21: 73,
    CS2: 65,
    AF33: 121,
    AF32: 113,
    AF31: 105,
    CS3: 97,
    AF43: 153,
    AF42: 145,
    AF41: 137,
    CS4: 129,
    EF: 185,
    CS5: 161,
    CS6: 193,
    CS7: 225
}

export const IEEE_802_1pEnumeration = {
    COS_0: 0,
    COS_1: 1,
    COS_2: 2,
    COS_3: 3,
    COS_4: 4,
    COS_5: 5,
    COS_6: 6,
    COS_7: 7
}

export const QueueEnumeration = {
    QUEUE_1: 1,
    QUEUE_2: 2,
    QUEUE_3: 3,
    QUEUE_4: 4,
}

export const TypeOfRuleEnumeration = {
    PORT: 1,
    ETHER_TYPE: 2,
    IP_PROTO: 3,
    MAC_ADDRESS: 4
}

export const PhysicalPortEnumeration = {
    PORT_ALL: 0,
    LAN1: 1,
    LAN2: 2

}

export const IPProtocolEnumeration = {
    PROTOCOL_NONE: 0,
    PROTOCOL_TCP: 1,
    PROTOCOL_UDP: 2,
    PROTOCOL_ICMP: 3
}

export const IPVersionEnumeration = {
    IPVERSION_IPV4: 1,
    IPVERSION_IPV6: 2,
    IPVERSION_BOTH: 3,
}

export const QoSClassificationStruct = {
    rule_name: "",
    mode: 1,
    precedence: {
        cos_802_1p: IEEE_802_1pEnumeration.COS_0,
        dscp_remarking: DSCPEnumeration.DEFAULT,
        precedence: QueueEnumeration.QUEUE_1
    },
    ethertype: { value: 0 },
    ip_protocol: {
        dscp_pattern: DSCPEnumeration.DEFAULT,
        src_port: { start: 0, end: 0 },
        dst_port: { start: 0, end: 0 },
        ip_config_src: { ip4: "", ip4_mask: "", ip6: "", ip6_prefix_length: 0 },
        ip_config_dst: { ip4: "", ip4_mask: "", ip6: "", ip6_prefix_length: 0 },
        ip_version: IPVersionEnumeration.IPVERSION_IPV4,
        protocol: IPProtocolEnumeration.PROTOCOL_NONE,
    },
    mac: { src: "", dst: "" },
    port: {
        dscp_pattern: DSCPEnumeration.DEFAULT,
        physical_port: PhysicalPortEnumeration.PORT_ALL
    },
}


export const getDSCPEnumerationElementsName = (element, t) => {
    switch (element) {
        case DSCPEnumeration.DEFAULT:
            return "default(000000)"
        case DSCPEnumeration.AF13:
            return "AF13(001110)"
        case DSCPEnumeration.AF12:
            return "AF12(001100)"
        case DSCPEnumeration.AF11:
            return "AF11(001010)"
        case DSCPEnumeration.CS1:
            return "CS1(001000)"
        case DSCPEnumeration.AF23:
            return "AF23(010110)"
        case DSCPEnumeration.AF22:
            return "AF22(010100)"
        case DSCPEnumeration.AF21:
            return "AF21(010010)"
        case DSCPEnumeration.CS2:
            return "CS2(010000)"
        case DSCPEnumeration.AF33:
            return "AF33(011110)"
        case DSCPEnumeration.AF32:
            return "AF32(011100)"
        case DSCPEnumeration.AF31:
            return "AF31(011010)"
        case DSCPEnumeration.CS3:
            return "CS3(011000)"
        case DSCPEnumeration.AF43:
            return "AF43(100110)"
        case DSCPEnumeration.AF42:
            return "AF42(100100)"
        case DSCPEnumeration.AF41:
            return "AF41(100010)"
        case DSCPEnumeration.CS4:
            return "CS4(100000)"
        case DSCPEnumeration.EF:
            return "EF(101110)"
        case DSCPEnumeration.CS5:
            return "CS5(101000)"
        case DSCPEnumeration.CS6:
            return "CS6(110000)"
        case DSCPEnumeration.CS7:
            return "CS7(111000)"
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getQueueEnumerationElementsName = (element, t) => {
    switch (element) {
        case QueueEnumeration.QUEUE_1:
            return t('advanced.qos_classification.label.QUEUE_1')
        case QueueEnumeration.QUEUE_2:
            return t('advanced.qos_classification.label.QUEUE_2')
        case QueueEnumeration.QUEUE_3:
            return t('advanced.qos_classification.label.QUEUE_3')
        case QueueEnumeration.QUEUE_4:
            return t('advanced.qos_classification.label.QUEUE_4')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getIEEE_802_1pEnumerationElementsName = (element, t) => {
    switch (element) {
        case IEEE_802_1pEnumeration.COS_0:
            return "CoS 0"
        case IEEE_802_1pEnumeration.COS_1:
            return "CoS 1"
        case IEEE_802_1pEnumeration.COS_2:
            return "CoS 2"
        case IEEE_802_1pEnumeration.COS_3:
            return "CoS 3"
        case IEEE_802_1pEnumeration.COS_4:
            return "CoS 4"
        case IEEE_802_1pEnumeration.COS_5:
            return "CoS 5"
        case IEEE_802_1pEnumeration.COS_6:
            return "CoS 6"
        case IEEE_802_1pEnumeration.COS_7:
            return "CoS 7"
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getTypeOfRuleEnumerationElementsName = (element, t) => {
    switch (element) {
        case TypeOfRuleEnumeration.PORT:
            return t('advanced.qos_classification.label.PORT_TYPE')
        case TypeOfRuleEnumeration.ETHER_TYPE:
            return t('advanced.qos_classification.label.ETHERNET_TYPE')
        case TypeOfRuleEnumeration.IP_PROTO:
            return t('advanced.qos_classification.label.IP_TYPE')
        case TypeOfRuleEnumeration.MAC_ADDRESS:
            return t('advanced.qos_classification.label.MAC_TYPE')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getPhysicalPortEnumerationElementsName = (element, t) => {
    switch (element) {
        case PhysicalPortEnumeration.PORT_ALL:
            return t('advanced.qos_classification.label.ALL_PORTS')
        case PhysicalPortEnumeration.LAN1:
            return t('advanced.qos_classification.label.LAN_01_PORT')
        case PhysicalPortEnumeration.LAN2:
            return t('advanced.qos_classification.label.LAN_02_PORT')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getIPProtocolEnumerationElementsName = (element, t) => {
    switch (element) {
        case IPProtocolEnumeration.PROTOCOL_NONE:
            return t('advanced.qos_classification.label.NO_PROTO')
        case IPProtocolEnumeration.PROTOCOL_TCP:
            return t('advanced.qos_classification.label.TCP_PROTO')
        case IPProtocolEnumeration.PROTOCOL_UDP:
            return t('advanced.qos_classification.label.UDP_PROTO')
        case IPProtocolEnumeration.PROTOCOL_ICMP:
            return t('advanced.qos_classification.label.ICMP_PROTO')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getIPVersionEnumerationElementsName = (element, t) => {
    switch (element) {
        case IPVersionEnumeration.IPVERSION_IPV4:
            return t('advanced.qos_classification.label.IPV4_VERSION')
        case IPVersionEnumeration.IPVERSION_IPV6:
            return t('advanced.qos_classification.label.IPV6_VERSION')
        case IPVersionEnumeration.IPVERSION_BOTH:
            return t('advanced.qos_classification.label.IPV4_IPV6_VERSION')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

// Generate DropDown Options

export const createDSCPOptions = (t) => {
    let DSCPOptions = []
    Object.keys(DSCPEnumeration).forEach(key => {
        DSCPOptions.push(
            {
                value: DSCPEnumeration[key],
                text: getDSCPEnumerationElementsName(DSCPEnumeration[key], t)
            }
        )
    })
    return DSCPOptions
}

export const createIEEE_802_1pOptions = (t) => {
    let IEEE_802_1pOptions = []
    Object.keys(IEEE_802_1pEnumeration).forEach(key => {
        IEEE_802_1pOptions.push(
            {
                value: IEEE_802_1pEnumeration[key],
                text: getIEEE_802_1pEnumerationElementsName(IEEE_802_1pEnumeration[key], t)
            }
        )
    })
    return IEEE_802_1pOptions
}

export const createQueueOptions = (t) => {
    let QueueOptions = []
    Object.keys(QueueEnumeration).forEach(key => {
        QueueOptions.push(
            {
                value: QueueEnumeration[key],
                text: getQueueEnumerationElementsName(QueueEnumeration[key], t)
            }
        )
    })
    return QueueOptions
}

export const createTypeOfRuleOptions = (t) => {
    let TypeOfRuleOptions = []
    Object.keys(TypeOfRuleEnumeration).forEach(key => {
        TypeOfRuleOptions.push(
            {
                value: TypeOfRuleEnumeration[key],
                text: getTypeOfRuleEnumerationElementsName(TypeOfRuleEnumeration[key], t)
            }
        )
    })
    return TypeOfRuleOptions
}

export const createPhysicalPortOptions = (t) => {
    let PhysicalPortOptions = []
    Object.keys(PhysicalPortEnumeration).forEach(key => {
        PhysicalPortOptions.push(
            {
                value: PhysicalPortEnumeration[key],
                text: getPhysicalPortEnumerationElementsName(PhysicalPortEnumeration[key], t)
            }
        )
    })
    return PhysicalPortOptions
}

export const createIPProtocolOptions = (t) => {
    let IPProtocolOptions = []
    Object.keys(IPProtocolEnumeration).forEach(key => {
        IPProtocolOptions.push(
            {
                value: IPProtocolEnumeration[key],
                text: getIPProtocolEnumerationElementsName(IPProtocolEnumeration[key], t)
            }
        )
    })
    return IPProtocolOptions
}

export const createIPVersionOptions = (t) => {
    let IPVersionOptions = []
    Object.keys(IPVersionEnumeration).forEach(key => {
        IPVersionOptions.push(
            {
                value: IPVersionEnumeration[key],
                text: getIPVersionEnumerationElementsName(IPVersionEnumeration[key], t)
            }
        )
    })
    return IPVersionOptions
}
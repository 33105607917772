import React, { useState, useEffect, useContext } from 'react'

import DefaultStatusModals from '../../../components/modal/default-status-modals';
import Form from '../../../components/form/form';
import { fetchTR069Config, TR069ConfigConstants, updateTR069Config, validatePeriodicInform } from '../../resources/tr069-config';
import { BackendContext } from '../../../backend/backend'
import Input from '../../../components/input/input';
import common from '../../../components/form/validators/common';
import { useTranslation } from 'react-i18next';
import Translator from '../../common/components/translator/translator';
import './system.css';
import Checkbox from '../../../components/checkbox/checkbox';
import CollapseBox from '../../../components/collapsebox/collapse-box';
import extraValidators from '../../common/validators';
import Loading from '../../../components/loading/loading';

export default function SystemTR069ConfigPage() {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorInfo, setErrorInfo] = useState(null)
    const [TR069Config, setTR069Config] = useState(null)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchTR069Config(backend, setTR069Config)
        // eslint-disable-next-line
    }, [])

    const submitTR069Config = async () => {
        if (loading || error) return

        setLoading(true)

        let ok = await updateTR069Config(backend, TR069Config, setErrorInfo)
        if (!ok) {
            setLoading(false)
            setError(true)
            return
        }

        setLoading(false)
    }

    const dismiss = () => {
        setLoading(false)
        setError(false)
    }

    const validateUrlProtocol = value => {
        return value && !(value.startsWith('http://') || value.startsWith('https://')) ? t('system.tr069_config.message.error.MUST_START_WITH_PROTOCOL') : ''
    }

    const validateUrl = async (value, { run_validation }) => {
        return !run_validation ? '' :
            await common.required(value) ||
            await common.nonASCII(value) ||
            await common.size(value, { min: 0, max: TR069ConfigConstants.TR069_CONFIG_MAX_ACS_URL_LENGHT }) ||
            await extraValidators.forbidWhitespacesOnly(value) ||
            validateUrlProtocol(value)
    }

    const mustValidateConnectionFields =  () => {
        return (TR069Config.tr069_enable && TR069Config.connection.enabled);
    }

    return !TR069Config ? <Loading show={true}></Loading> :
        <div id='tr069-config-page' className='info text align-center'>

            <DefaultStatusModals
                error={error}
                errorText={t('system.tr069_config.message.error.SERVER_ERROR') + errorInfo?.message}
                saving={loading} continueFn={dismiss}
            ></DefaultStatusModals>

            <div className='subtitle'><Translator path="menu.TR069_CONFIG"></Translator></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="system.tr069_config.title.TR069_ENABLE"></Translator></div>

                <Form id='tr069-acs-config-connection-form'
                    onSubmit={submitTR069Config}
                    submitText={<Translator path="common.label.SAVE"></Translator>}
                    buttonId='button-acs'
                    isPristine={false}
                >
                    <div className='input-tr069-config'>
                        <Checkbox id='tr069-enable'
                            name='tr069_enable'
                            label={<Translator path="system.tr069_config.label.TR069_ENABLE"></Translator>}
                            value={TR069Config.tr069_enable}
                            clearErrors={true}
                            toggleFn={(e) => {
                                setTR069Config((TR069Config)=>{ 
                                    TR069Config.tr069_enable = !TR069Config.tr069_enable
                                    if (validateUrl(TR069Config.acs.acs_url, { run_validation: true }))
                                        TR069Config.acs.acs_url = 'http://'
                                    return  {...TR069Config}  
                                })
                            }}
                        ></Checkbox>
                    </div>
                    <CollapseBox title={<Translator path="system.tr069_config.title.TR069_ACS_CONFIG"></Translator>} startOpen={true}>

                        <Input id='acs_url' type='text'
                            name='acs_url'
                            label='URL'
                            value={TR069Config?.acs?.acs_url}
                            placeholder="http://"
                            readOnly={!TR069Config?.tr069_enable}
                            onChange={
                                e => {
                                    setTR069Config((TR069Config)=>{ 
                                        TR069Config.acs.acs_url = e.target.value
                                        return  {...TR069Config}  
                                    })
                                }
                            }
                            validators={[
                                {
                                    fn: validateUrl, params: { run_validation: true }
                                }]}
                            dependentValues={[TR069Config?.tr069_enable]}
                        ></Input>

                        <Input id='acs_username' type='text'
                            name='acs_username'
                            label={<Translator path="common.label.USER"></Translator>}
                            value={TR069Config?.acs.acs_username}
                            readOnly={!TR069Config?.tr069_enable}
                            onChange={
                                e => {
                                    setTR069Config((TR069Config)=>{ 
                                        TR069Config.acs.acs_username = e.target.value
                                        return  {...TR069Config}  
                                    })
                                }
                            }
                            validators={[
                                common.nonASCII,
                                {
                                    fn: common.size, params: {
                                        min: 0,
                                        max: TR069ConfigConstants.TR069_CONFIG_MAX_ACS_USERNAME_LENGHT
                                    }
                                },
                                extraValidators.forbidWhitespacesOnly
                            ]}
                            dependentValues={[TR069Config?.tr069_enable]}
                        ></Input>

                        <Input id='acs_password' type='password'
                            name='acs_password'
                            label={<Translator path="common.label.PASSWORD"></Translator>}
                            value={TR069Config?.acs.acs_password}
                            readOnly={!TR069Config?.tr069_enable}
                            onChange={
                                e => {
                                    setTR069Config((TR069Config)=>{ 
                                        TR069Config.acs.acs_password = e.target.value
                                        return  {...TR069Config}  
                                    })
                                }
                            }
                            validators={[
                                common.nonASCII,
                                {
                                    fn: common.size, params: {
                                        min: 0,
                                        max: TR069ConfigConstants.TR069_CONFIG_MAX_ACS_PASSWORD_LENGHT
                                    }
                                },
                                extraValidators.forbidWhitespacesOnly
                            ]}
                            dependentValues={[TR069Config?.tr069_enable]}
                        ></Input>

                        <Checkbox id='tr069-acs-config-interval-enable'
                            name='tr069_inform'
                            label={<Translator path="system.tr069_config.label.ACS_INFORM"></Translator>}
                            value={TR069Config?.acs.tr069_inform}
                            disabled={!TR069Config?.tr069_enable}
                            toggleFn={(e) => {
                                setTR069Config((TR069Config)=>{ 
                                    TR069Config.acs.tr069_inform = !TR069Config.acs.tr069_inform
                                    return  {...TR069Config}  
                                })
                            }}
                        ></Checkbox>

                        <Input id='tr069-acs-config-interval'
                            name='tr069_interval'
                            type="number"
                            label={<Translator path="system.tr069_config.label.ACS_INTERVAL"></Translator>}
                            value={TR069Config?.acs.tr069_interval}
                            readOnly={!TR069Config?.acs.tr069_inform || !TR069Config?.tr069_enable}
                            onChange={(e) => {
                                setTR069Config((TR069Config)=>{ 
                                    TR069Config.acs.tr069_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    return  {...TR069Config}  
                                })
                            }}
                            validators={[
                                common.nonASCII,
                                common.isNumber,
                                {
                                    fn: common.value, params: {
                                        min: 0
                                    }
                                },
                                validatePeriodicInform
                            ]}
                            dependentValues={[TR069Config?.tr069_enable]}
                        ></Input>
                    </CollapseBox>

                    <CollapseBox title={<Translator path="system.tr069_config.title.TR069_CONNECTION_CONFIG"></Translator>} startOpen={true}>

                        <Checkbox id='tr069-connection-config-enable'
                            name='enabled'
                            label={<Translator path="system.tr069_config.label.CONNECTION_ENABLE"></Translator>}
                            value={TR069Config.connection?.enabled}
                            disabled={!TR069Config.tr069_enable}
                            toggleFn={() => {
                                setTR069Config((TR069Config)=>{ 
                                    TR069Config.connection.enabled = !TR069Config.connection.enabled 
                                    return  {...TR069Config}  
                                })
                            }}
                        ></Checkbox>

                        <div>
                            <Input id='tr069-connection-config-username' type='text'
                                name='conreq_name'
                                label={<Translator path="common.label.USER"></Translator>}
                                value={TR069Config.connection?.conreq_name}
                                readOnly={!TR069Config.tr069_enable || !TR069Config.connection.enabled}
                                onChange={
                                    e => {
                                        setTR069Config((TR069Config)=>{ 
                                            TR069Config.connection.conreq_name = e.target.value
                                            return  {...TR069Config}  
                                        })
                                    }
                                }
                                validators={[
                                    {fn: extraValidators.optionalValidators, params: {
                                        shouldValidate: mustValidateConnectionFields,
                                        validators: [
                                            {fn: extraValidators.required, params: ''},
                                            {fn: common.nonASCII, params: ''},
                                            {fn: common.size, params: { min: 0, max: TR069ConfigConstants.TR069_CONFIG_MAX_CONN_NAME_LENGHT}},
                                            {fn: extraValidators.forbidWhitespacesOnly, params: ''}
                                        ]
                                    }}
                                ]}
                                dependentValues={[TR069Config.tr069_enable]}
                            ></Input>

                            <Input id='tr069-connection-config-password' type='password'
                                name='conreq_pw'
                                label={<Translator path="common.label.PASSWORD"></Translator>}
                                value={TR069Config.connection?.conreq_pw}
                                readOnly={!TR069Config.tr069_enable || !TR069Config.connection.enabled}
                                onChange={
                                    e => {
                                        setTR069Config((TR069Config)=>{ 
                                            TR069Config.connection.conreq_pw = e.target.value
                                            return  {...TR069Config}  
                                        })
                                    }
                                }
                                validators={[
                                    {fn: extraValidators.optionalValidators, params: {
                                        shouldValidate: mustValidateConnectionFields,
                                        validators: [
                                            {fn: extraValidators.required, params: ''},
                                            {fn: common.nonASCII, params: ''},
                                            {fn: common.size, params: {min: 0, max: TR069ConfigConstants.TR069_CONFIG_MAX_CONN_NAME_LENGHT}},
                                            {fn: extraValidators.forbidWhitespacesOnly, params: ''}
                                        ]
                                    }}
                                ]}
                                dependentValues={[TR069Config.tr069_enable]}
                            ></Input>

                            <Input id='tr069-connection-config-port'
                                name='conreq_port'
                                type="number"
                                key={TR069Config.connection.enabled}
                                label={<Translator path="system.tr069_config.label.CONNECTION_PORT"></Translator>}
                                value={TR069Config.connection?.conreq_port}
                                readOnly={!TR069Config.tr069_enable || !TR069Config.connection.enabled }
                                onChange={(e) => {
                                    setTR069Config((TR069Config)=>{ 
                                        TR069Config.connection.conreq_port = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                        return  {...TR069Config}  
                                    })
                                }}
                                validators={[
                                    {fn: extraValidators.optionalValidators, params: {
                                        shouldValidate: mustValidateConnectionFields,
                                        validators: [
                                            {fn: common.nonASCII, params: ''},
                                            {fn: common.isNumber, params: ''},
                                            {fn: extraValidators.value, params: { min: 1, max: 65535 }}
                                        ]
                                    }},
                                ]}
                                dependentValues={[TR069Config.connection?.enabled, TR069Config.tr069_enable]}
                            ></Input>
                        </div>

                    </CollapseBox>
                </Form>
            </div >
        </div>
}
import { backendStatus } from '../../backend/backend'

export const IPv6PrefixModeOptions = [
    {value: 0 , text: 'IPV6_PREFIX_MODE_MANUAL'},
    {value: 1 , text: 'IPV6_PREFIX_MODE_AUTO'}
]

export async function fetchIPv6Radvd(backend, setIPv6Radvd) {
    let result = await backend.retrieveFresh('ipv6_radvd')

    if(result.status === backendStatus.SUCCESS){

        let ipv6RadvdList = result.content

        let ipv6Radvd = ipv6RadvdList.length !== 0 ? ipv6RadvdList[0] : {
            "max_rtr_adv_interval": 600,
            "min_rtr_adv_interval": 198,
            "adv_managed_flag": false,
            "ipv6_prefix_mode_auto": false,
            "ipv6_prefix": {
                "prefix": "3ffe:501:ffff:100::",
                "prefix_size": 64,
                "adv_valid_lifetime": 2592000,
                "adv_preferred_lifetime": 604800,
                "adv_on_link": true,
                "adv_autonomous": true,
                "rdnss1": "",
                "rdnss2": ""
            },
            "enable_ula": true,
            isCreate: true
        }

        setIPv6Radvd(ipv6Radvd)
    }
}

export async function saveIPv6Radvd(backend, ipv6Radvd, setErrorMessage) {

    try{

        let result = null;

        if(ipv6Radvd.isCreate)
            result = await backend.create('ipv6_radvd', ipv6Radvd)
        else
            result = await backend.update(`ipv6_radvd/${ipv6Radvd.id ? ipv6Radvd.id : 0 }`, ipv6Radvd)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body?.message)
        }

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving IPv6 RADVD:', error)

        return false
    }

}
export const RuleActionEnumeration = {
    IPV6_FILTER_DENY: 0,
    IPV6_FILTER_ALLOW: 1
}

export const DirectionEnumeration = {
    IPV6_FILTER_INCOMING: 0,
    IPV6_FILTER_OUTGOING: 1
}

export const ProtocolEnumeration = {
    IPV6_FILTER_TCP: 0,
    IPV6_FILTER_UDP: 1,
    IPV6_FILTER_ICMPV6: 2
}

export const Ipv6IpPortFilterStruct = {
    src_ipv6: {
        start: '',
        end: '',
        prefix_length: 128
    },

    dst_ipv6: {
        start: '',
        end: '',
        prefix_length: 128
    },

    source_port: {
        from: '',
        to: ''
    },

    destination_port: {
        from: '',
        to: ''
    },

    interface_id: 'any',
    direction: DirectionEnumeration.IPV6_FILTER_INCOMING,
    protocol: ProtocolEnumeration.IPV6_FILTER_TCP,
    rule_action: RuleActionEnumeration.IPV6_FILTER_DENY
}

export const DEFAULT_INTERFACE_ID = 'any'

export const getDirectionEnumerationElementsName = (element, t) => {
    switch (element) {
        case DirectionEnumeration.IPV6_FILTER_INCOMING:
            return t('common.label.INGOING')
        case DirectionEnumeration.IPV6_FILTER_OUTGOING:
            return t('common.label.OUTGOING')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getRuleActionEnumerationElementsName = (element, t) => {
    switch (element) {
        case RuleActionEnumeration.IPV6_FILTER_DENY:
            return t('common.label.DENY')
        case RuleActionEnumeration.IPV6_FILTER_ALLOW:
            return t('common.label.ALLOW')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getProtocolEnumerationElementsName = (element, t) => {
    switch (element) {
        case ProtocolEnumeration.IPV6_FILTER_TCP:
            return t('common.label.TCP')
        case ProtocolEnumeration.IPV6_FILTER_UDP:
            return t('common.label.UDP')
        case ProtocolEnumeration.IPV6_FILTER_ICMPV6:
            return t('common.label.ICMP')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const createDirectionOptions = (t) => {
    let direction = []
    Object.keys(DirectionEnumeration).forEach(key => {
        direction.push(
            {
                value: DirectionEnumeration[key],
                text: getDirectionEnumerationElementsName(DirectionEnumeration[key], t)
            }
        )
    })
    return direction
}

export const createRuleActionOptions = (t) => {
    let ruleActionOptions = []
    Object.keys(RuleActionEnumeration).forEach(key => {
        ruleActionOptions.push(
            {
                value: RuleActionEnumeration[key],
                text: getRuleActionEnumerationElementsName(RuleActionEnumeration[key], t)
            }
        )
    })
    return ruleActionOptions
}

export const createProtocolOptions = (t) => {
    let iPProtocolOptions = []
    Object.keys(ProtocolEnumeration).forEach(key => {
        iPProtocolOptions.push(
            {
                value: ProtocolEnumeration[key],
                text: getProtocolEnumerationElementsName(ProtocolEnumeration[key], t)
            }
        )
    })
    return iPProtocolOptions
}

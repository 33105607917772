import React, { useState, useEffect, useContext } from 'react'

import './service-dynamic-dns.css'

import { fetchServiceDynamicDns, fetchServiceDynamicDnsList, saveServiceDynamicDns, deleteServiceDynamicDnsById } from '../../../resources/service-dynamic-dns';
import { BackendContext } from '../../../../backend/backend';

import Input from '../../../../components/input/input';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form from '../../../../components/form/form';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators';
import Select from '../../../../components/select/select';
import Checkbox from '../../../../components/checkbox/checkbox';
import List from '../../../../components/list/list';
import Pager from '../../../../components/pager/pager';
import EyeIcon from '../../../../components/icons/eye-icon'
import EyeOffIcon from '../../../../components/icons/eye-off-icon'
import { fetchNetworkWanList } from '../../../resources/network-wan';
import EditIcon from '../../../../components/icons/edit';
import RemoveIcon from '../../../../components/icons/remove';
import Button from '../../../../components/button/button'

const DNSProviderOptions = [
    { value: 0, text: 'DynDNS' },
    { value: 1, text: 'NoIP' }
]

const DDNSStatus = [
    'service.dynamic_dns.label.DDNS_STATUS_SUCCESFULLY_UPDATED',
    'service.dynamic_dns.label.DDNS_STATUS_CONNECTION_ERROR',
    'service.dynamic_dns.label.DDNS_STATUS_AUTH_FAILURE',
    'service.dynamic_dns.label.DDNS_STATUS_WRONG_OPTION',
    'service.dynamic_dns.label.DDNS_STATUS_HANDLING',
    'service.dynamic_dns.label.DDNS_STATUS_LINK_DOWN'
]

export default function ServiceDynamicDns({ isWizard, isFormSegment, formSegment }) {
    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [savingForm, setSavingForm] = useState(false)
    let [errorForm, setErrorForm] = useState(false)
    let [successForm, setSuccessForm] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [dynamicDns, setDynamicDns] = useState(null)
    const [dynamicDnsList, setServiceDynamicDnsList] = useState(null)
    const [interfaces, setInterfaces] = useState([])
    const [showPassword, setShowPassword] = useState(false)

    const [deleteDNSData, setDeleteDNS] = useState(null)

    const [dynamicDnsListPagination, setServiceDynamicDnsListPagination] = useState(null)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchServiceDynamicDns(backend, null, setDynamicDns)
        setServiceDynamicDnsListPagination({ pageNumber: 1, totalElements: 0, pageSize: 3 })
        fetchServiceDynamicDnsList(backend, setServiceDynamicDnsList)
        fetchNetworkWanList(backend, setInterfaces)
        setDeleteDNS({ showDelete: false, id: null })

        // eslint-disable-next-line
    }, [])

    const getColumns = () => {

        return [
            { header: t('common.label.NUMBER'), align: 'center', size: '50px' },
            { header: t('service.dynamic_dns.label.ENABLED'), align: 'center', size: '50px' },
            { header: t('service.dynamic_dns.label.PROVIDER'), align: 'center', size: '150px' },
            { header: t('service.dynamic_dns.label.HOSTNAME'), align: 'center', size: '300px' },
            { header: t('service.dynamic_dns.label.INTERFACE'), align: 'center', size: '150px' },
            { header: t('service.dynamic_dns.label.USERNAME'), align: 'center', size: '150px' },
            {
                header: <span className='password-header'>
                    {t('service.dynamic_dns.label.PASSWORD')}
                    <span onClick={e => setShowPassword(!showPassword)} className='eye-password'>{showPassword ? <EyeOffIcon /> : <EyeIcon />}</span>
                </span>, align: 'center', size: '150px'
            },
            { header: t('service.dynamic_dns.label.STATUS'), align: 'center', size: '300px' },
            { header: t('common.label.ACTIONS'), align: 'center', size: '100px' },
        ]
    }

    const changePage = page => {
        setServiceDynamicDnsListPagination({ ...dynamicDnsListPagination, pageNumber: page })
    }

    const editItem = item => {
        fetchServiceDynamicDns(backend, item.id, setDynamicDns)
    }

    const getServiceDynamicDnsList = () => {

        let dynamicDnsListFiltered = dynamicDnsList

        if (dynamicDnsListFiltered.length !== dynamicDnsListPagination.totalElements) {
            dynamicDnsListPagination.totalElements = dynamicDnsListFiltered.length
            setServiceDynamicDnsListPagination({ ...dynamicDnsListPagination })
        }

        let dynamicDnsListLines = [];

        let begining = (dynamicDnsListPagination.pageNumber - 1) * (dynamicDnsListPagination.pageSize)
        let end = begining + dynamicDnsListPagination.pageSize
        if (end > dynamicDnsListPagination.totalElements) {
            end = dynamicDnsListPagination.totalElements
        }

        for (let i = begining; i < end; i++) {
            if (dynamicDnsListFiltered[i]) {
                dynamicDnsListLines.push([
                    parseInt(dynamicDnsListFiltered[i].id) + 1,
                    dynamicDnsListFiltered[i].enabled ? t('modal.answer.YES') : t('modal.answer.NO'),
                    DNSProviderOptions.filter(po => po.value === dynamicDnsListFiltered[i].provider)[0]?.text ?
                        DNSProviderOptions.filter(po => po.value === dynamicDnsListFiltered[i].provider)[0]?.text : dynamicDnsListFiltered[i].provider,
                    dynamicDnsListFiltered[i].hostname,
                    dynamicDnsListFiltered[i].interface,
                    dynamicDnsListFiltered[i].username,
                    <span>{showPassword ? dynamicDnsListFiltered[i].password : '***'}</span>,
                    DDNSStatus[dynamicDnsListFiltered[i].status] ? t(DDNSStatus[dynamicDnsListFiltered[i].status]) : dynamicDnsListFiltered[i].status,
                    <>
                        <span className="clickable" onClick={e => editItem(dynamicDnsListFiltered[i])}><EditIcon></EditIcon></span>
                        <span className="clickable" onClick={e => showDeleteDynamicDNS(dynamicDnsListFiltered[i])}><RemoveIcon></RemoveIcon></span>
                    </>
                ]);
            }
        }

        return dynamicDnsListLines;
    }

    function translateErrorMessagesFromBackend(errorMsg) {
        if (errorMsg === 'provider not supported')
            return t('service.dynamic_dns.message.error.PROVIDER_NOT_SUPPORTED')
        else if (errorMsg === 'hostname required')
            return t('service.dynamic_dns.message.error.HOSTNAME_REQUIRED')
        else if(errorMsg === 'hostname is invalid')
            return t('service.dynamic_dns.message.error.HOSTNAME_INVALID')
        else if(errorMsg === 'username/password required')
            return t('service.dynamic_dns.message.error.USER_AND_PASSWORD_REQUIRED')
        else if(errorMsg === 'hostname already in use')
            return t('service.dynamic_dns.message.error.HOSTNAME_ALREADY_IN_USE')
        else
            return errorMsg
    }
    
    let save = async () => {

        dynamicDns = { ...dynamicDns, service_port: parseInt(dynamicDns.service_port) }
        setDynamicDns(dynamicDns)

        if (savingForm || errorForm || successForm) return

        setSavingForm(true)

        let ok = await saveServiceDynamicDns(backend, dynamicDns, setErrorMessage)
        if (!ok) {
            setSavingForm(false)
            setErrorForm(true)
            return
        }

        setSavingForm(false)
        setSuccessForm(true)
        setServiceDynamicDnsList(null)
        fetchServiceDynamicDns(backend, null, setDynamicDns)
        setServiceDynamicDnsListPagination({ pageNumber: 1, totalElements: 0, pageSize: 3 })
        fetchServiceDynamicDnsList(backend, setServiceDynamicDnsList)
    }

    let dismissForm = () => {
        setSuccessForm(false)
        setErrorForm(false)
    }

    const dynamicDnsListForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className="service-dynamic-dns-list-list-wrapper">
                <List
                    width={1500}
                    lines={getServiceDynamicDnsList()}
                    columns={getColumns()}
                ></List>
            </div>
            <Pager
                pageNumber={dynamicDnsListPagination.pageNumber}
                totalElements={dynamicDnsListPagination.totalElements}
                pageSize={dynamicDnsListPagination.pageSize}
                pageChangeFn={changePage}>
            </Pager>

        </React.Fragment>
    }

    const continueDelete = async (confirm) => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        setDeleteDNS({ ...deleteDNSData, showDelete: false })
        if (confirm) {
            deleteDNS(deleteDNSData.id)
        }
    }

    const showDeleteDynamicDNS = dns => {
        setDeleteDNS({ showDelete: true, id: dns.id })
    }

    const deleteDNS = async id => {
        setSaving(true)
        if (((dynamicDnsListPagination.pageNumber - 1) * dynamicDnsListPagination.pageSize) >= dynamicDnsListPagination.totalElements - 1) {
            dynamicDnsListPagination.pageNumber--
            setServiceDynamicDnsListPagination({ ...dynamicDnsListPagination })
        }
        setServiceDynamicDnsList(null)
        let result = await deleteServiceDynamicDnsById(backend, id, setServiceDynamicDnsList)
        setSaving(false)
        if (result) {
            setSuccess(true)
            setError(false)
            setDeleteDNS({ showDelete: false, id: null })
        } else {
            setSuccess(false)
            setError(true)
            setDeleteDNS({ showDelete: false, id: null })
        }
    }

    const noRepeatedDNS = host => {
        return dynamicDnsList.find(dle => dle.hostname === host && dle.id !== dynamicDns.id) ? t('service.dynamic_dns.message.error.REPEATED_HOSTNAME') : ''
    }

    const systemDynamicDnsForm = () => {
        return <React.Fragment>

            <Checkbox id='enabled'
                name='enabled'
                label={<Translator path="service.dynamic_dns.label.ENABLED"></Translator>}
                value={dynamicDns.enabled === 1}
                toggleFn={(e) => {
                    dynamicDns.enabled = dynamicDns.enabled === 1 ? 0 : 1;
                    setDynamicDns({ ...dynamicDns })
                }}
                validators={[]}
            ></Checkbox>

            <Input name='hostname'
                id='hostname'
                label={t('service.dynamic_dns.label.HOSTNAME')}
                value={dynamicDns.hostname}
                onChange={(e) => setDynamicDns({ ...dynamicDns, hostname: e.target.value })}
                validators={[
                   extraValidators.required,
                   extraValidators.nonASCII,
                   { fn: extraValidators.size, params: { min: 0, max: 34 } },
                   noRepeatedDNS
                ]}
            ></Input>

            <Input name='username'
                id='username'
                label={t('service.dynamic_dns.label.USERNAME')}
                value={dynamicDns.username}
                onChange={(e) => setDynamicDns({ ...dynamicDns, username: e.target.value })}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    extraValidators.forbidAnyWhitespace,
                    { fn: extraValidators.size, params: { min: 0, max: 34 } },
                ]}
            ></Input>

            <Input name='password'
                id='password'
                type='password'
                label={t('service.dynamic_dns.label.PASSWORD')}
                value={dynamicDns.password}
                onChange={(e) => setDynamicDns({ ...dynamicDns, password: e.target.value })}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII, 
                    extraValidators.forbidAnyWhitespace,
                    { fn: extraValidators.size, params: { min: 0, max: 34 } },
                ]}
            ></Input>

            <Select
                id='provider'
                name='provider'
                label={<Translator path="service.dynamic_dns.label.PROVIDER"></Translator>}
                options={DNSProviderOptions}
                value={dynamicDns.provider}
                onChange={(e) => {
                    dynamicDns.provider = Number(e.target.value)
                    setDynamicDns({ ...dynamicDns })
                }}
                validators={[]}
            ></Select>

            <Select
                id='interface'
                name='interface'
                label={<Translator path="service.dynamic_dns.label.INTERFACE"></Translator>}
                options={[{ value: '', text: t('common.label.CHOOSE') }, ...interfaces.map(it => ({ value: it.id, text: it.id }))]}
                value={dynamicDns.interface}
                onChange={(e) => {
                    dynamicDns.interface = e.target.value
                    setDynamicDns({ ...dynamicDns })
                }}
                validators={[extraValidators.required]}
            ></Select>
        </React.Fragment>
    }

    return !dynamicDns ? <Loading show={true}></Loading> :
        <div id='service-dynamic-dns-page' className='container scroll-area'>

            <DefaultStatusModals
                saving={savingForm}
                error={errorForm}
                errorText={t('common.message.error.SERVER_ERROR', { error: translateErrorMessagesFromBackend(errorMessage) })}
                success={successForm}
                continueFn={dismissForm}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <DefaultStatusModals
                error={error}
                errorText={t('network.lan.error.ERROR_MESSAGE_CUSTOM')}
                saving={saving}
                savingText={<Translator path="common.label.WAIT"></Translator>}
                success={success}
                successText={t('service.dynamic_dns.message.delete.DELETE_SUCCESS')}
                confirm={deleteDNSData.showDelete}
                continueFn={continueDelete}
                confirmContent={
                    <div style={{ width: '420px' }}>
                        <span style={{ display: 'block' }}><Translator path="network.lan.warning.PERMANENT_DELETE_WARNING"></Translator>
                            <br></br>
                        </span>
                        <b><Translator path="network.lan.warning.CONFIRM_EXCLUSION"></Translator></b>
                    </div>
                }
                isWarningModal={true}
                dismissText={<Translator path="common.label.CANCEL"></Translator>}
                confirmText={<Translator path="common.label.CONFIRM"></Translator>}
            ></DefaultStatusModals>

            <React.Fragment>
            <div className='subtitle'><Translator path="menu.DYNAMIC_DNS"></Translator></div>
                <div className='card mt2'>
                    <div className='subtitle'><Translator path="service.dynamic_dns.label.SETTINGS"></Translator></div>
                    <Form id='service-dynamic-dns-form'
                        onSubmit={save}
                        buttonId='button-save-service-dynamic-dns'
                        submitText={t('common.label.ADD')}
                    >
                        {systemDynamicDnsForm()}
                    </Form>
                </div>
            </React.Fragment>

            {
                !dynamicDnsList ? <Loading show={true}></Loading> :

                    <div id='service-dynamic-dns-list-page' className='with-tabs'>
                        <div className='subtitle'><Translator path="service.dynamic_dns.title.DYNAMIC_DNS_LIST"></Translator></div>
                        {dynamicDnsList?.length > 0 ? dynamicDnsListForm() : <div className='info-card noelements-card'>
                            <Translator path="service.dynamic_dns.message.information.NO_ENTRY_ON_LIST"></Translator>
                        </div>}
                        <Button
                            id='button-refresh'
                            text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                            onClick={e => fetchServiceDynamicDnsList(backend, setServiceDynamicDnsList)}
                        ></Button>
                    </div>
            }

        </div>
}
import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import TabsMobile from '../../common/components/tabs/tabs-mobile'
import IPv6State, { IPv6Context } from './ipv6-context';
import IPv6ProxyMLD from './ipv6-proxy-mld/ipv6-proxy-mld';
import IPv6Status from './ipv6-status/ipv6-status';
import IPv6Radvd from './ipv6-radvd/ipv6-radvd'
import Ipv6Dhcp from './ipv6-dhcp/ipv6-dhcp'
import SystemAclIpv6 from './acl-ipv6/system-acl-ipv6';
import { useTranslation } from 'react-i18next';
import Ipv6RouteList from './ipv6-route-list/ipv6-route-list'
import Ipv6StaticRoute from './ipv6-static-route/ipv6-static-route'
import Ipv6StaticRouteEdit from './ipv6-static-route/ipv6-static-route-edit';
import Ipv6IpPortFilter from './ipv6-ip-port-filter/ipv6-ip-port-filter'
import Ipv6IpPortFilterEdit from './ipv6-ip-port-filter/ipv6-ip-port-filter-edit'
import Ipv6DhcpActiveClients from './ipv6-dhcp/ipv6-dhcp-active-clients/ipv6-dhcp-active-clients';
import Ipv6PrefixDelegation from './prefix-delegation/ipv6-prefix-delegation'
/*IMPORTS_TAG*/

export default function IPv6Page() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const mainTabs = [
        { label: t('menu.IPV6_ENABLE'), route: '/ipv6/status' },
        { label: t('ipv6.proxy_mld.title.PROXY_MLD'), route: '/ipv6/proxy-mld' },
        { label: t('ipv6.radvd.title.RADVD'), route: '/ipv6/radvd' },
        { label: t('ipv6.dhcp.title.IPV6_DHCP'), route: '/ipv6/dhcp' },
        { label: t('menu.ACL_IPV6'), route: '/ipv6/acl-ipv6' },
        { label: t('menu.IPV6_ROUTE_LIST'), route: '/ipv6/ipv6-route-list' },
        { label: t('menu.IPV6_STATIC_ROUTE'), route: '/ipv6/ipv6-static-route' },
        { label: t('menu.IPV6_IP_PORT_FILTER'), route: '/ipv6/ipv6-ip-port-filter' },
        { label: t('menu.IPV6_PREFIX_DELEGATION'), route: '/ipv6/prefix-delegation' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.IPV6'))
        header.backRoute.set('/ipv6/status')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('ipv6') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <IPv6State>
            <IPv6Context.Consumer>
                {({ isIPv6Enabled }) => (
                    <TabsMobile
                        data={mainTabs}
                        route={history.location.pathname}
                        activateFn={changeRoute}
                    ></TabsMobile>
                )}
            </IPv6Context.Consumer>

            <Switch>
                <Route path='/ipv6/status' render={(props) => {
                    return <IPv6Status {...props} ></IPv6Status>
                }}></Route>

                <Route path='/ipv6/proxy-mld' render={(props) => {
                    return <IPv6ProxyMLD {...props} ></IPv6ProxyMLD>
                }}></Route>

                <Route path='/ipv6/radvd' render={(props) => {
                    return <IPv6Radvd {...props} ></IPv6Radvd>
                }}></Route>

                <Route path='/ipv6/dhcp/active-clients' exact render={(props) => {
                    return <Ipv6DhcpActiveClients {...props} ></Ipv6DhcpActiveClients>
                }}></Route>

                <Route path='/ipv6/dhcp' render={(props) => {
                    return <Ipv6Dhcp {...props} ></Ipv6Dhcp>
                }}></Route>

                <Route path='/ipv6/acl-ipv6' render={(props) => {
                    return <SystemAclIpv6 {...props} ></SystemAclIpv6>
                }}></Route>

                <Route path='/ipv6/ipv6-route-list' render={(props) => {
                    return <Ipv6RouteList {...props} ></Ipv6RouteList>
                }}></Route>

                <Route path='/ipv6/ipv6-static-route/:id' render={(props) => {
                    return <Ipv6StaticRouteEdit {...props} ></Ipv6StaticRouteEdit>
                }}></Route>

                <Route path='/ipv6/ipv6-static-route' render={(props) => {
                    return <Ipv6StaticRoute {...props} ></Ipv6StaticRoute>
                }}></Route>

                <Route path='/ipv6/ipv6-ip-port-filter/:id' render={(props) => {
                    return <Ipv6IpPortFilterEdit {...props} ></Ipv6IpPortFilterEdit>
                }}></Route>

                <Route path='/ipv6/ipv6-ip-port-filter' render={(props) => {
                    return <Ipv6IpPortFilter {...props} ></Ipv6IpPortFilter>
                }}></Route>

                <Route path='/ipv6/prefix-delegation' render={(props) => {
                    return <Ipv6PrefixDelegation {...props} ></Ipv6PrefixDelegation>
                }}></Route>

                {/*ROUTES_TAG*/}
            </Switch>
        </IPv6State>
    )
}

import { backendStatus } from '../../backend/backend'

const URL = 'qos_classification';

export async function fetchQosClassification(backend, id, setQosClassification) {
    let result = await backend.retrieveFresh(`${URL}/${id}`)
    if (result.status === backendStatus.SUCCESS) {
        setQosClassification(result.content)
    }
}

export async function fetchQosClassificationList(backend, setQosClassificationList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if (result.status === backendStatus.SUCCESS) {

        setQosClassificationList(result.content)
    }
}

export async function deleteQosClassificationById(backend, id, setQosClassificationList) {
    let result = await backend.delete(`${URL}/${id}`)

    if (result.status === backendStatus.SUCCESS) {
        fetchQosClassificationList(backend, setQosClassificationList)
    }
}

export async function saveQosClassification(backend, qosClassification, setErrorMessage) {

    try {

        let result = null;

        if (!qosClassification.id) {
            result = await backend.create(`${URL}`, qosClassification)
        }
        else {
            result = await backend.update(`${URL}/${qosClassification.id}`, qosClassification)
        }

        if (result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || qosClassification)

    } catch (error) {

        console.error('Error saving QOS_CLASSIFICATION:', error)

        return false
    }

}
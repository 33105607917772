import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import PONState, { PONContext } from './pon-context';
import PONCounters from './pon-counters/pon-counters';
import PONStats from './pon-stats/pon-stats';
import PONSettings from './pon-settings/pon-settings';
// import PONNodes from './pon-nodes/pon-nodes';
import PONStatus from './pon-status/pon-status';
import GPONStatus from './gpon-status/gpon-status';
import EponLLIDStatus from './epon-llid-status/epon-llid-status';
import GemPortStatistics from './gem-port-statistics/gem-port-statistics';
import TabsMobile from '../../common/components/tabs/tabs-mobile';
import { useTranslation } from 'react-i18next';
/*IMPORTS_TAG*/

export default function PONPage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const mainTabs = [
        { label: t('pon.label.COUNTERS'), route: '/pon/counters' },
        { label: t('pon.label.STATISTICS'), route: '/pon/stats' },
        { label: t('common.label.SETTINGS'), route: '/pon/settings' },
        { label: t('common.label.STATUS'), route: '/pon/status' },
        /*TABS_TAG*/
    ]

    const eponTabs = [
        { label: t('pon.label.EPON_LLID_STATUS'), route: '/pon/epon-llid-status' }
    ]

    const gponTabs = [
        { label: t('pon.label.GPON_STATUS'), route: '/pon/gpon-status' },
        { label: t('pon.label.GEM_TABLE'), route: '/pon/gem-port-statistics' },
        // {label: 'Nós', route:'/pon/nodes'} 
    ]

    useEffect(() => {
        header.title.set(t('menu.PON'))
        header.backRoute.set('/pon/counters')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('pon') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <PONState>

            <PONContext.Consumer>
                {({ currentPONMode }) => (
                    <TabsMobile
                        data={[...mainTabs, ...(currentPONMode() === 'epon' ? eponTabs : gponTabs)]}
                        route={history.location.pathname}
                        activateFn={changeRoute}
                    ></TabsMobile>
                )}
            </PONContext.Consumer>

            <Switch>
                <Route path='/pon/counters' render={(props) => {
                    return <PONCounters {...props} ></PONCounters>
                }}></Route>
                
                <Route path='/pon/stats' render={(props) => {
                    return <PONStats {...props} ></PONStats>
                }}></Route>
                
                <Route path='/pon/settings' render={(props) => {
                    return <PONSettings {...props} ></PONSettings>
                }}></Route>
                
                {/* <Route path='/pon/nodes' render={(props)=>{
                    return <PONNodes {...props} ></PONNodes>
                }}></Route> */}
                
                <Route path='/pon/status' render={(props) => {
                    return <PONStatus {...props} ></PONStatus>
                }}></Route>
                
                <Route path='/pon/gpon-status' render={(props) => {
                    return <GPONStatus {...props} ></GPONStatus>
                }}></Route>
                
                <Route path='/pon/epon-llid-status' render={(props) => {
                    return <EponLLIDStatus {...props} ></EponLLIDStatus>
                }}></Route>
                
                <Route path='/pon/gem-port-statistics' render={(props) => {
                    return <GemPortStatistics {...props} ></GemPortStatistics>
                }}></Route>
                
                {/*ROUTES_TAG*/}
            </Switch>
        </PONState>
    )
}

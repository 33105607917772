import React, { useContext, useState } from 'react'
import { BackendContext } from '../backend/backend'
import { fetchLan } from './resources/lan'
import { fetchUser } from './resources/user'
import macutils from './utils/macutils'
import MenuID from './utils/menu'
import { LimitedUserName } from './common/constants'

export const GlobalsContext = React.createContext({})

export default function Globals({children}) {

    const backend = useContext(BackendContext)

    const [data, setData] = useState({})

    const updateUser = async() => {
        const user = await fetchUser(backend)
        if (user) {
            data.username = user.username
            data.password = user.password
            setData({...data})
        }
    }

    const getDeviceID = async () => {
        if ('deviceid' in data) {
            return data['deviceid']
        }

        const result = await fetchLan(backend)

        if (result)
            return macutils.getDeviceIdFromMac(result.mac)
        else
            return ''
    }

    const setItem = (key, value) => {
        data[key] = value
        setData({...data})
    }

    const getItem = (key) => {
        return key in data ? data[key] : null
    }

    const deleteItem = (key) => {
        delete data[key]
        setData({...data})
    }

    const isLimitedUserFlag = () => {
        const isLimitedUser = data.username === LimitedUserName;
        return isLimitedUser;
    }

    const DeviceFeatures = {
        HasFourLanPorts: 'HasFourLanPorts',
        HasAxSupport: 'HasAxSupport',
        HasAx160MHzSupport: 'HasAx160MHzSupport',
        HasNoAclHttpsSupport: 'HasNoAclHttpsSupport'
    };

    const containsFeature = (feature) => {
        let compatible_devices;

        switch (feature) {
            case MenuID.Voip.name:
                compatible_devices = [
                    'Bifrost mock',
                    '121AC',
                    'AX1800V',
                    'AX3000V'
                ];
                break;
            case DeviceFeatures.HasFourLanPorts:
                compatible_devices = [
                    'AX1800',
                    'AX1800V',
                    'AX3000V'
                ];
                break;
            case DeviceFeatures.HasAxSupport:
                compatible_devices = [
                    'AX1800',
                    'AX1800V',
                    'AX3000V'
                ];
                break;
            case DeviceFeatures.HasAx160MHzSupport:
                compatible_devices = [
                    'Bifrost mock',
                    'AX3000V'
                ];
                break;
            case DeviceFeatures.HasNoAclHttpsSupport:
                compatible_devices = [
                    '1200R',
                ];
                break;
            default:
                return true;
        }

        return compatible_devices.some(device => {
            return sessionStorage.getItem('onu_model')?.toUpperCase().includes(device.toUpperCase())
        });
    }

    const filterMenuItemsLimitedUser = (featureName) => {

        if (isLimitedUserFlag()) {
            const features = [
                MenuID.Voip.name,
                MenuID.Ipv6.name,
                MenuID.Pon.name,
                MenuID.Tools.name,
                MenuID.Advanced.name,
                MenuID.NetworkWan.name,
                MenuID.NetworkLan.name,
                MenuID.ServiceProxyIgmp.name,
                MenuID.ServiceDynamicDns.name,
                MenuID.ServiceRipConfig.name,
                MenuID.FirewallAlg.name,
                MenuID.FirewallMacFiltering.name,
                MenuID.FirewallIpPortFiltering.name,
                MenuID.FirewallPortForwarding.name,
                MenuID.FirewallWlanAccessControl.name,
                MenuID.SystemAclAdmin.name,
                MenuID.SystemTr069Config.name,
                MenuID.SystemRestore.name,
                MenuID.SystemBackupRestore.name,
                MenuID.SystemChangeFirwareVersion.name,
                MenuID.SystemUpdate.name,
                MenuID.SystemDateTime.name,
                MenuID.SystemAutomaticMaintenance.name,
                MenuID.SystemInformation.name
            ];

            return !features.includes(featureName);
        }

        return true;
    }

    return <GlobalsContext.Provider value={{
        setItem: setItem,
        getItem: getItem,
        deleteItem: deleteItem,
        updateUser: updateUser,
        getDeviceID: getDeviceID,
        containsFeature: containsFeature,
        filterMenuItemsLimitedUser: filterMenuItemsLimitedUser,
        isLimitedUserFlag: isLimitedUserFlag
    }}>{children}</GlobalsContext.Provider>
}
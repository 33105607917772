export default {
  "translations": {
    "advanced": {
      "bridging": {
        "label": {
          "BRIDGING_SETTINGS": "Configurações de bridging"
        },
        "title": {
          "AGEING_TIME": "Ageing Time",
          "AGEING_TIMER": "Ageing Timer",
          "IS_LOCAL": "Local?",
          "MAC_ADDRESS": "Endereço MAC",
          "PORT": "Porta",
          "STP": "802.1d Spanning Tree",
          "TABLE": "Base de Dados de Encaminhamento Bridge"
        }
      },
      "global_routing": {
        "error": {
          "COULD_NOT_GET_INFO": "Não foi possível recuperar as informações da tabela de roteamento global"
        },
        "title": {
          "GLOBAL_ROUTING": "Roteamento global"
        },
        "info": {
          "NO_FORWARDS": "Não há encaminhamentos bridge na base de dados"
        }
      },
      "igmp_mld_snooping": {
        "status": {
          "label": {
            "ENABLED": "Ativar IGMP/MLD Snooping"
          }
        },
        "title": {
          "ADVANCED_IGMP_MLD_SNOOPING": "Ativar/Desativar IGMP e MLD Snooping"
        }
      },
      "neighbour_hosts": {
        "error": {
          "COULD_NOT_GET_INFO": "Não foi possível recuperar as informações dos dispositivos vizinhos"
        },
        "title": {
          "NEIGHBOUR_HOSTS": "Dispositivos vizinhos"
        }
      },
      "qos_classification": {
        "error": {
          "INVALID_PORT_RANGE_SRC": "Intervalo de porta de origem inválido, o fim deve ser maior ou igual ao início.",
          "INVALID_PORT_RANGE_DST": "Intervalo de porta de destino inválido, o fim deve ser maior ou igual ao início.",
          "MAX_NUM_OF_RULES": "O número máximo de regras é 16!",
          "INVALID_DST_IPV4_MASK": "A máscara IPv4 de destino é inválida",
          "INVALID_SRC_IPV4_MASK": "A máscara IPv4 de origem é inválida",
          "SRC_IP_NO_SET_WHEN": "Ao configurar a máscara de origem, você deve configurar o respectivo IP.",
          "DST_IP_NO_SET_WHEN": "Ao configurar a máscara de destino, você deve configurar o respectivo IP.",
          "SRC_IP6_NO_SET_WHEN": "Ao configurar o tamanho de prefixo de origem, você deve configurar o respectivo IPv6.",
          "DST_IP6_NO_SET_WHEN": "Ao configurar o tamanho de prefixo de destino, você deve configurar o respectivo IPv6.",
          "QUEUE_DISABLED": "é necessário habilitar primeiramente, no recurso Política QoS, as filas a serem utilizadas.",
          "INVALID_ETHTYPE": "EtherType deve ser um valor entre 0x800 e 0xFFFF"
        },
        "info": {
          "NO_QOS_CLASSIFICATIONS": "Não há classificações de QoS criadas"
        },
        "label": {
          "_802_1P": "802.1p",
          "ADD_QOS_CLASSIFICATION_RULE": "Adicionar regras de classificação de QoS",
          "ALL_PORTS": "Todas as Portas",
          "CLASS_OF_SERVICE": "Classe de Serviço",
          "DEST_IP": "IP de Destino",
          "DEST_MAC": "MAC de Destino",
          "DEST_MASK": "Máscara de Destino",
          "DEST_PORT": "Portas de Destino",
          "DEST_PORT_BEGIN": "Início das portas de destino",
          "DEST_PORT_END": "Fim das portas de destino",
          "DEST_PREFIX": "Prefixo de Destino",
          "DETAILS": "Detalhes",
          "DSCP_MARK": "Marcação DSCP",
          "DSCP_PATTERN": "Padrão DSCP",
          "DSCP_REMARKING": "DSCP Remarking",
          "ETHERNET_TYPE": "Ethernet",
          "ICMP_PROTO": "Protocolo ICMP",
          "ID": "ID",
          "INFORMATION": "Informações",
          "IP_TYPE": "Protocolo IP",
          "IP_VERSION": "Versão de IP",
          "IPV4_IPV6_VERSION": "IPv4/IPv6",
          "IPV4_VERSION": "Versão IPv4",
          "IPV6_VERSION": "Versão IPv6",
          "LAN_01_PORT": "LAN 01",
          "LAN_02_PORT": "LAN 02",
          "MAC_TYPE": "Endereço MAC",
          "NAME": "Nome",
          "NEW_CLASSIFICATION": "Criar classificação QoS",
          "NO_PROTO": "Sem Protocolo",
          "OPTIONS": "Opções",
          "ORDER": "Ordem",
          "PHYSICAL_PORT": "Porta Física",
          "PORT_TYPE": "Porta",
          "PORTS": "Portas",
          "QOS_CLASSIFICATION_LIST": "Lista de classificações",
          "QOS_RULE_TYPE": "Tipo de regra QoS",
          "QUEUE": "Precedência",
          "QUEUE_1": "Fila 01",
          "QUEUE_2": "Fila 02",
          "QUEUE_3": "Fila 03",
          "QUEUE_4": "Fila 04",
          "RULE_NAME": "Nome da Regra",
          "RULE_TYPE": "Tipo da Regra",
          "SOURCE_IP": "IP de Origem",
          "SOURCE_MAC": "MAC de Origem",
          "SOURCE_MASK": "Máscara de Origem",
          "SOURCE_PORT": "Portas de Origem",
          "SOURCE_PORT_BEGIN": "Início das portas de origem",
          "SOURCE_PORT_END": "Fim das portas de origem",
          "SOURCE_PREFIX": "Prefixo de Origem",
          "TCP_PROTO": "Protocolo TCP",
          "TRAFFIC_CLASSIFICATION": "Especificação de Regras de Classificação de Tráfego",
          "UDP_PROTO": "Protocolo UDP",
          "VALUE_IN_HEX": "Valor em Hexadecimal"
        },
        "title": {
          "QOS_CLASSIFICATION": "Classificação QoS"
        }
      },
      "qos_traffic_control": {
        "label": {
          "ADD_QOS_TRAFFIC_CONTROL_RULE": "Adicionar regra de controle de tráfego",
          "DOWNSTREAM": "Downstream",
          "UPSTREAM": "Upstream",
          "LIMIT_RATE": "Limite de banda (kbps)",
          "ADD_NEW_RULE": "Adicionar nova regra",
          "DST_PORT": "Porta de destino",
          "SRC_PORT": "Porta de origem"
        },
        "error": {
          "SELECT_PROTOCOL": "Selecione um protocolo válido quando se utiliza portas: TCP, UDP, TCP/UDP",
          "MAX_OF_CONNECTIONS": "Número máximo de configurações alcançado"
        },
        "info": {
          "NO_RULES": "Não há regras para controle de tráfego criadas"
        }
      },
      "static_routing": {
        "error": {
          "NETMASK_AND_IP_ERROR": "verifique se o endereço IP representa uma rede válida para esta máscara.",
          "ERROR_SERVER": "Erro ao tentar aplicar as modificações no servidor: ",
          "ROUTER_ALREADY_EXISTS": "Essa rota já existe, modifique o campo de destino ou máscara de rede"
        },
        "info": {
          "NO_ROUTES": "Não há rotas estáticas criadas"
        },
        "label": {
          "DESTINATION": "Destino",
          "DISABLED": "Desabilitado",
          "ENABLED": "Habilitado",
          "INTERFACEID": "Interface",
          "METRIC": "Métrica",
          "NET_MASK": "Máscara de rede",
          "NEW_ROUTE": "Criar rota",
          "NEXT_HOP": "Próximo salto",
          "INTERFACE_ANY": "Qualquer"
        },
        "paceholder": {
          "VALID_IP_INPUT": "Entre com um endereço IP válido",
          "VALID_METRIC_INPUT": "Entre com uma métrica válida",
          "VALID_NETMASK_INPUT": "Entre com uma máscara de rede válida"
        },
        "title": {
          "STATIC_ROUTING": "Lista de Rotas",
          "STATIC_ROUTING_CREATE": "Criar nova rota estática",
          "STATIC_ROUTING_EDIT": "Editar rota estática"
        }
      },
      "qos_policy": {
        "title": {
          "BANDWIDTH_CONFIG": "Configuração de largura de banda",
          "QUEUE_CONFIG": "Configurações de fila QoS",
          "ENABLE_QUEUE": "Habilitar Fila",
          "HIGHEST_PRIORITY": "Maior prioridade",
          "WEIGHT": "Peso",
          "PRIORITY": "Prioridade",
          "QUEUE_WEIGHT": "Peso da Fila",
          "QUEUE_WEIGHT_SUM": "A soma total dos pesos deve ser 100. Filas com peso 0 serão desativadas.",
          "QUEUE_WEIGHT_SUM_GREATER": "A soma dos pesos é superior a 100.",
          "QUEUE_WEIGHT_SUM_LESS": "A soma dos pesos é inferior a 100."
        },
        "label": {
          "USER_DEF_BANDWIDTH": "Habilitar largura de banda definido pelo usuário",
          "TOTALBANDWIDTH": "Máxima largura de banda (Kbps)",
          "POLICY": "Política"
        },
        "error": {
          "QUEUE_DISABLED": "não é possível desativar as filas utilizadas pelo recurso Classificação QoS. Caso deseje realmente desativar tais filas, remover a utilização destas no recurso Classificação QoS.",
          "TOTAL_SUM": "a soma total dos pesos das filas deve ser 100. Além disso, ao menos uma fila deve ser habilitada."
        }
      }
    },
    "common": {
      "label": {
        "RESULTS": "Resultados",
        "ACTIONS": "Ações",
        "ADD": "Adicionar",
        "ALL_INTERFACES": "Qualquer",
        "ALLOW": "Permitir",
        "ALWAYS_NEAR": "Sempre próxima",
        "BACK": "Voltar",
        "BOTH": "Ambos",
        "CHOOSE": "Escolha",
        "CANCEL": "Cancelar",
        "CONFIRM": "Confirmar",
        "CONFIRM_PASSWORD": "Confirmar Senha",
        "DELETE": "Excluír",
        "DENY": "Negar",
        "DESCRIPTION": "Descrição",
        "DESTINATION": "Destino",
        "DESTINY": "Destino",
        "DIRECTION": "Direção",
        "DIRECTION_ABREVIATION": "Dir",
        "DISABLED": "Desativado",
        "DOMAIN": "Domínio",
        "DOWN": "Down",
        "DOWNLOAD": "Download",
        "EDIT": "Editar",
        "ENABLE": "Habilitar",
        "ENABLED": "Ativado",
        "GATEWAY_IPV4": "Gateway IPv4",
        "GATEWAY_IPV6": "Gateway IPv6",
        "HOST": "Host",
        "ICMP": "ICMP",
        "ID": "Id",
        "INTERFACE": "Interface",
        "IP": "IP",
        "IP_ADDRESS": "Endereço IP",
        "IPV4": "IPv4",
        "IPV6": "IPv6",
        "KEYWORD": "Keyword",
        "KEYWORDS": "Keywords",
        "LAN": "LAN",
        "MAC_ADDRESS": "MAC",
        "MASK": "Máscara",
        "METRIC": "Métrica",
        "NEXT_HOP": "Próximo salto",
        "NUMBER": "Nº",
        "OPERATIONS": "Operações",
        "PAGE": "Página",
        "PASSWORD": "Senha",
        "PROTOCOL": "Protocolo",
        "ROUTE": "Rota",
        "RULE": "Regra",
        "RX": "RX (Pacotes)",
        "SAVE": "Salvar",
        "SAVING": "Salvando",
        "SETTINGS": "Configurações",
        "INTERFACE_ANY": "Qualquer",
        "SOURCE": "Origem",
        "START": "Iniciar",
        "STATUS": "Status",
        "TABLE_REFRESH": "Atualizar valores",
        "TABLE_REFRESHING": "Atualizando",
        "TCP": "TCP",
        "TTL": "TTL",
        "TX": "TX (Pacotes)",
        "UDP": "UDP",
        "UP": "Up",
        "URL": "Url",
        "URLS": "URLs",
        "USER": "Usuário",
        "VLAN_ID": "VLAN ID",
        "WAIT": "Aguarde",
        "OUTGOING": "Saída",
        "INGOING": "Entrada",
        "SUNDAY": "Dom",
        "MONDAY": "Seg",
        "TUESDAY": "Ter",
        "WEDNESDAY": "Qua",
        "THURSDAY": "Qui",
        "FRIDAY": "Sex",
        "SATURDAY'": "Sáb",
        "BEGINNING": "Início",
        "END": "Fim",
        "SATURDAY": "Sábado",
        "PORT_SOURCE": "Porta de origem",
        "PORT_DESTINY": "Porta de destino"
      },
      "message": {
        "error": {
          "VALIDATE_TIME_FIELD": "Formato inválido (HH:MM)",
          "ALPHANUMERIC_FIELD": "Este campo deve conter apenas letras e números",
          "CHECK_CONNECTION": "Verifique sua conexão com o dispositivo e tente novamente.",
          "EXPIRED_SESSION": "Sua sessão expirou.",
          "FIELD_INVALID": "Campo inválido",
          "FORBID_WHITESPACES_ONLY_FIELD": "Contém apenas caracteres em branco",
          "FORBID_ANY_WHITESPACES": "Contém caracteres em branco",
          "IS_HEX_FIELD": "Deve ser um hexadecimal no formato 0x<HEX>",
          "IS_NUMBER_FIELD": "Valor deve ser numérico",
          "IS_INTEGER_NUMBER_FIELD": "Valor deve ser um número inteiro",
          "IS_URL_FIELD": "URL inválida",
          "IS_VENDORID_FIELD": "O campo deve ter 4 letras maiúsculas",
          "ITEM_ALREADY_EXISTS": "Esse item já existe",
          "MAX_NUMBER_OF_ELEMENTS_IS": "Limite máximo de elementos é {{max}}",
          "NO_WHITESPACES_ALLOWED": "Não podem haver espaços em branco nesse campo",
          "NON_ASCII_FIELD": "Contém caracteres inválidos",
          "REQUIRED_FIELD": "Este campo é obrigatório",
          "SERVER_ERROR": "Houve um erro: {{error}}",
          "SIZE_FIELD": "O campo deve conter entre {{min}} e {{max}} caracteres",
          "VALIDATE_HOST": "Endereço inválido",
          "VALIDATE_HOSTNAME": "Hostname inválido",
          "VALIDATE_IPV4_FIELD": "Endereço inválido",
          "VALIDATE_IPV6_FIELD": "Endereço inválido",
          "VALIDATE_CANNOT_BE_LOCALHOST": "Endereço não deve ser de localhost",
          "VALIDATE_IPV6_PREFIX_FORMAT": "O prefixo precisa estar no formato 2001:db8:0:17b5::/64",
          "VALIDATE_IPV6_PREFIX_LENGTH": "Prefixo deve ser um número inteiro entre 48 e 64",
          "VALIDATE_IPV6_LINK_LOCAL_IS_NOT_ALLOWED": "Endereços do tipo link-local não são permitidos",
          "VALIDATE_IPV6_VALID_TIME": "A validade do prefixo precisa ser maior ou igual a validade desejada",
          "VALIDATE_IPV6_WITH_PREFIX_FIELD": "Endereço inválido, um endereço IPv6 com prefixo é necessário",
          "VALIDATE_MAC_FIELD": "Endereço inválido",
          "VALIDATE_SUBNETMASK_FIELD": "Máscara inválida",
          "VALIDATION_PORT_RANGE": "Valores válidos: [1, 65535]",
          "VALIDATION_PORT_RANGE_IP_PORT_FILTER": "Valores válidos: [0, 65535]",
          "VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM": "Início menor que fim",
          "VALIDATION_PORT_RANGE_BIGGER_THAN": "Início maior que fim",
          "VALIDATION_SAME_SRC_DST_IP": "O endereço de rede de origem é idêntico ao de destino",
          "VALUE_MAX_FIELD": "O campo deve possuir valor menor ou igual a {{max}}",
          "VALUE_MIN_FIELD": "O campo deve possuir valor maior ou igual a {{min}}",
          "VALUE_MIN_MAX_FIELD": "Permitido valores entre {{min}} e {{max}}"
        },
        "info": {
          "SUCCESS_ON_PERSIST": "Configurações salvas com sucesso!"
        },
        "sucess": {
          "DELETE_SUCCESS_TEXT_MESSAGE_CUSTOM": "Entrada(s) de registro(s) excluída(s) com sucesso."
        },
        "warning": {
          "CONFIRM_EXCLUSION": "Deseja mesmo deletar o item?",
          "LOGIN_TO_CONTINUE": "Realize o login novamente para continuar.",
          "PERMANENT_DELETE_WARNING": "Os itens selecionados serão excluídos permanentemente.",
          "OPERATION_CANT_BE_REVERTED": "Essa operação não poderá ser revertida.",
          "WAIT": "Aguarde",
          "WISH_TO_CONTINUE": "Deseja prosseguir?",
          "IPV6_ENABLE": "Para poder realizar as configurações habilite o IPv6 em "
        }
      }
    },
    "dashboard": {
      "title": {
        "INTERFACE_STATISTICS": "Estatísticas das interfaces",
        "LAN_SETTINGS": "Configurações de LAN",
        "NO_WANS_REGISTERED": "Não há WANs registradas",
        "STATUS_LAN": "Status porta LAN",
        "WAN_SETTINGS": "Configurações de WAN"
      },
      "label": {
        "MTU_SIZE": "Tamanho da MTU",
        "WAN_CONNECTION": "Conexão WAN",
        "OMCI_CONFIGURED": "WAN OMCI",
        "LOCAL_NETWORK_LAN": "Rede local (LAN)",
        "IP_ADDRESS": "Endereço IP",
        "IPV6_ADDRESS": "Endereço IPv6",
        "IPV6_ADDRESS_GLOBAL": "IPv6 Global",
        "IPV6_ADDRESS_LINK_LOCAL": "IPv6 Link Local",
        "NETMASK": "Máscara de sub-rede",
        "MAC_ADDRESS": "Endereço MAC",
        "DHCP_SERVER": "Servidor DHCP",
        "2_4G_NETWORK": "Rede 2.4 GHz",
        "5G_NETWORK": "Rede 5 GHz",
        "GPON_STATE": "Estado GPON",
        "LAN_IPV4_ADDR": "Conexão LAN",
        "MODEL": "Modelo",
        "PRODUCT_HAS_NO_LICENSE": "Nenhuma licença instalada",
        "LAN_MAC_ADDR": "Endereço MAC da LAN",
        "FIRMWARE_VERSION": "Versão de firmware",
        "ACTIVE_TIME": "Tempo ativo do sistema",
        "INTELBRAS_SW_LICENSE": "Licença de software Intelbras",
        "DETAILS": "Detalhes",
        "CONNECTION_STATUS": "Status de conexão",
        "INITIAL_STATE": "Sem conexão PON",
        "SERIAL_NUMBER_STATE": "Aguardando provisionamento",
        "RANGING_STATE": "Em provisionamento",
        "OPERATION_STATE": "Em operação",
        "INTERMITTENT_LODS_STATE": "LOS/LOF intermitente",
        "EMERGENCY_STOP_STATE": "Ocorreu um erro",
        "ENABLED": "Ativado",
        "DISABLED": "Desabilitado",
        "WLAN_INTERFACES": "Interfaces WLAN",
        "RADIO_CONFIG": "Configurações de rádio",
        "CONNECTED_DEVICES": "Dispositivos Conectados",
        "WAN_INTERFACES": "Interfaces WAN",
        "INMESH_CONNECTION": "Dispositivos inMesh"
      }
    },
    "parental_control": {
      "label": {
        "CONNECTED_DEVICES": "Dispositivos Conectados"
      },
      "connected_devices": {
        "NO_DEVICES_CONNECTED": "Não há dispositivos conectados",
        "DEVICE_LIST": "Lista de Dispositivos",
        "HOSTNAME": "Hostname",
        "IP": "Endereço IP",
        "IPV6_COUNT": "Número de endereços IPv6",
        "IPV6_LIST": "Lista de endereços IPv6:",
        "IS_DMZ": "É o host DMZ?",
        "IS_STATIC_IP": "É IP estático?",
        "MAC": "Endereço MAC",
        "NETWORK": "Rede",
        "OPMODE": "Banda",
        "RXBYTES": "Bytes recebidos (Mb)",
        "TXBYTES": "Bytes transmitidos (Mb)",
        "RXRATE": "Taxa de recepção (Mbps)",
        "TXRATE": "Taxa de transmissão (Mbps)",
        "SIGNAL_LEVEL": "Nível de sinal (dBm)",
        "TIME": "Tempo (s)",
        "UNITY_SIZE": "Dimensão"
      },
      "parental_control": {
        "NO_RULES": "Não há regras criadas",
        "NEW_RULE": "Criar nova Regra",
        "title": {
          "EDIT_RULE": "Editar/Criar nova Regra"
        },
        "label": {
          "RULE_NAME": "Nome da Regra",
          "RULE_TYPE": "Tipo da Regra",
          "IP_FROM": "Endereço IP de início",
          "IP_TO": "Endereço IP de fim",
          "MAC": "Endereço MAC",
          "DAYS": "Dias a serem aplicados",
          "TIME": "Horário a ser aplicado",
          "START_TIME": "Horário de início",
          "END_TIME": "Horário de fim",
          "ACTIONS": "Ações",
          "ADDRESSES": "Endereços",
          "ENABLE_RES": "Habilitar recurso",
          "DISABLE_RES": "Desabilitar recurso"
        },
        "warning": {
          "MAX_RULES_NUM": "Número máximo de regras atingidas",
          "RESOURCE_DISABLED": "O recurso está desabilitado. Habilite-o para que as configurações surtam efeito."
        },
        "error": {
          "IP_RANGE": "O endereço IP de início deve anteceder o de fim.",
          "DAYS_RANGE": "É necessário selecionar pelo menos um dia.",
          "TIME_RANGE": "O horário de início deve anteceder o horário de fim."
        }
      }
    },
    "firewall": {
      "alg": {
        "info": {
          "ALG_SUBTITLE": "Esta página é usada para habilitar/desabilitar serviços ALG."
        },
        "label": {
          "FTP_ENABLED": "FTP",
          "H323_ENABLED": "H323",
          "IP_SEC_ENABLED": "IP Sec",
          "L2TP_ENABLED": "L2TP",
          "PPTP_ENABLED": "PPTP",
          "RTSP_ENABLED": "RTSP",
          "SIP_ENABLED": "SIP",
          "TFTP_ENABLED": "TFTP"
        },
        "title": {
          "FIREWALL_ALG": "ALG",
          "FIREWALL_ALG_ENABLE": "Habilitar configurações de ALG"
        }
      },
      "dmz": {
        "title": {
          "FIREWALL_DMZ": "Configurações de DMZ",
          "FIREWALL_DMZ_ENABLE": "Habilitar configurações de DMZ"
        },
        "message": {
          "error": {
            "HAS_IP_OUT_OF_RANGE": "O endereço IP não está na faixa da LAN",
            "HAS_HOST_IP": " O endereço IP não pode ser o mesmo que o do host"
          }
        }
      },
      "domain_blocking": {
        "title": {
          "DOMAIN_BLOCKING_SETTINGS": "Configurações de bloqueio de Domínios",
          "DOMAINS": "Domínios",
          "DOMAIN_BLOCKING": "Bloqueio de domínio"
        },
        "label": {
          "ADD_DOMAIN_BLOCKING": "Adicionar novo domínio",
          "ENABLE_DOMAIN_BLOCKING": "Ativar bloqueio de domínio"
        },
        "info": {
          "NO_DOMAINS": "Não há domínios adicionados à lista"
        }
      },
      "ip_port_filtering": {
        "label": {
          "ACTION_DEFAULT_INCOMING": "Ação padrão incoming",
          "ACTION_DEFAULT_OUTGOING": "Ação padrão outgoing",
          "DESTINY_IP": "IP de destino",
          "DESTINY_PORT_FROM": "Porta de destino início",
          "DESTINY_PORT_TO": "Porta de destino fim",
          "DIRECTION": "Direção",
          "FILTER_TYPE": "Tipo de filtro",
          "SOURCE_IP": "IP de origem",
          "SOURCE_PORT_FROM": "Porta de origem início",
          "SOURCE_PORT_TO": "Porta de origem fim"
        },
        "message": {
          "error": {
            "DEFAULT_ERROR": "A ação de padrão de {{direction}} esta como {{rule_type}}, essa regra não tem efeito prático",
            "RULE_CONFLICT": "Essa regra entra em conflito com a regra: {{rule}}",
            "SERVER_ERROR": "Erro no servidor."
          }
        },
        "title": {
          "DEFAULT_ACTIONS": "Ações padrão",
          "FILTER_LIST": "Lista de Filtros",
          "INSERT_NEW_FILTER": "Inserir novo filtro",
          "IP_PORT_FILTERING": "Filtragem de IP/Porta"
        },
        "warning": {
          "LACK_OF_IP": "Deve fornecer o endereço IP de origem e/ou IP de destino"
        },
        "info": {
          "NO_FILTERS": "Não há filtros adicionados à lista"
        }
      },
      "mac_filtering": {
        "label": {
          "DEFAULT_INCOMING_ACTION": "Ação padrão incoming",
          "DEFAULT_OUTGOING_ACTION": "Ação padrão outgoing",
          "DESTINY_MAC": "MAC de destino",
          "FILTER_TYPE": "Tipo de filtro",
          "SOURCE_MAC": "MAC de origem"
        },
        "title": {
          "ADD_NEW_FILTER": "Inserir novo filtro",
          "DEFAULT_ACTIONS": "Ações padrão",
          "FILTER_LIST": "Lista de Filtros",
          "FILTER_LIST_MAX": "Limite máximo de filtros atingido.",
          "MAC_FILTER_SETTINGS": "Configurações de filtro de MAC",
          "MAC_FILTERING": "Filtragem de MAC"
        },
        "warning": {
          "LACK_OF_MAC": "Deve fornecer o endereço MAC de origem e/ou MAC de destino",
          "SAME_SRC_DST_MAC": "O endereço MAC de origem é idêntico ao de destino"
        },
        "info": {
          "NO_FILTERS": "Não há filtros adicionados à lista"
        }
      },
      "port_forward": {
        "label": {
          "ADD_PORT_FORWARD_RULE": "Adicionar regra de redirecionamento de porta",
          "ALL_INTERFACES": "Qualquer",
          "EDIT_PORT_FORWARD_RULE": "Editar regra de redirecionamento de porta",
          "ENABLE_PORT_MAPPING": "Habilitar redirecionamento de portas",
          "INTERFACE": "Interface",
          "INTERFACE_ABREVIATION": "Itf",
          "LOCAL": "Local",
          "LOCAL_IP": "IP local",
          "LOCAL_PORT_FROM": "Porta (de)",
          "LOCAL_PORT_TO": "Porta (para)",
          "OBSERVATION": "Observação",
          "PROTOCOL": "Protocolo",
          "PROTOCOL_ABREVIATION": "Proto",
          "REMOTE": "Remoto",
          "REMOTE_IP": "IP remoto",
          "REMOTE_PORT_FROM": "Porta (de)",
          "REMOTE_PORT_TO": "Porta (para)"
        },
        "message": {
          "error": {
            "ERROR_CREATING_PORT_FORWARD_RULE": "Erro ao criar nova regra: ",
            "ERROR_IP_OUT_OF_RANGE": "Endereço de IP inválido! Ele deve ser definido na sub-rede atual.",
            "ERROR_SAVING_DATA": "Falha ao salvar as configurações de mapeamento de portas: ",
            "FULL_TABLE_PORT_FORWARD": "O número máximo de regras foi atingido",
            "RULE_ALREADY_EXISTS": "Já existe uma regra neste intervalo"
          },
          "warning": {
            "DELETE_ITEM_WARNING": "Tem certeza de que deseja excluir esta regra?",
            "OPERATION_CANNOT_BE_REVERTED": "Esta operação não pode ser revertida.",
            "WISH_TO_GO_ON": "Você quer prosseguir?"
          }
        },
        "title": {
          "PORT_MAPPING": "Redirecionamento de portas"
        },
        "info": {
          "NO_REDIRECTS": "Não há redirecionamentos adicionados à lista"
        }
      },
      "url_blocking": {
        "title": {
          "URL_BLOCKING": "Bloqueio de URL",
          "KEYWORDS_TABLE": "Lista de keywords",
          "URL_TABLE": "Lista de URLs",
          "URL_BLOCKING_SETTINGS": "Configurações de bloqueio de URLs"
        },
        "message": {
          "error": {
            "NO_RULES_ERROR": "você deve fornecer urls e palavras-chave para habilitar este serviço.",
            "MAX_CHARACTERS": "um ou mais elementos excede o máximo de caracteres."
          }
        },
        "label": {
          "ADD_NEW_KEYWORD": "Adicionar nova keyword",
          "ADD_NEW_URL": "Adicionar nova url",
          "ENABLE_URL_BLOCKING": "Ativar bloqueio de URLs"
        },
        "info": {
          "NO_KEYWORDS": "Não há keywords adicionadas à lista",
          "NO_URLS": "Não há URLs adicionadas à lista"
        }
      },
      "wlan-access-control": {
        "info": {
          "NO_ELEMENTS": "Não há MACs cadastrados"
        },
        "label": {
          "ACTIONS": "Ações",
          "RADIO": "Rádio",
          "POLICY_CONFIG": "Configurações de políticas para os rádios",
          "ALLOW": "Permitir",
          "DENY": "Negar",
          "DISABLED": "Desativado",
          "NEW_MAC_ADDR": "Cadastrar novo endereço MAC",
          "ADD_NEW_MAC": "Adicionar novo MAC",
          "RADIO_SELECT": "Selecionar Rádio"
        },
        "warn": {
          "2": {
            "4GHZ_AND_5GHZ_LIMIT": "Limite máximo atingido para os rádios 2,4GHz e 5GHz",
            "4GHZ_LIMIT": "Limite máximo atingido para o rádio 2,4GHz"
          },
          "5GHZ_LIMIT": "Limite máximo atingido para o rádio 5GHz"
        },
        "error": {
          "INVALID_MAC_ADDR": "Endereço MAC inválido"
        }
      }
    },
    "ipv6": {
      "ip_port_filtering": {
        "title": {
          "INSERT_NEW_FILTER": "Inserir nova regra",
          "GENERAL_CONFIG": "Configurações gerais",
          "IP_SRC_CONFIG": "IP de origem",
          "IP_DST_CONFIG": "IP de destino",
          "FILTER_LIST": "Lista de regras",
          "DEFAULT_ACTIONS": "Ações padrão"
        },
        "label": {
          "IPV6_ENABLE_SRC_CONFIG": "Configurar IP de origem",
          "IPV6_ENABLE_DST_CONFIG": "Configurar IP de destino",
          "IPV6_ADDRESS_SOURCE": "Endereço IPv6 de origem",
          "IPV6_ADDRESS_DESTINATION": "Endereço IPv6 de destino",
          "IPV6_START": "Início do intervalo IPv6",
          "IPV6_END": "Fim do intervalo IPv6",
          "IPV6_PREFIX_LENGTH": "Tamanho do prefixo",
          "PORT_FROM": "Início do intervalo de portas",
          "PORT_TO": "Fim do intervalo de portas",
          "ACTION_DEFAULT_INCOMING": "Ação padrão incoming",
          "ACTION_DEFAULT_OUTGOING": "Ação padrão outgoing",
          "ADD_NEW_RULE": "Adicionar nova regra"
        },
        "error": {
          "FULL_TABLE": "O número máximo de regras foi atingido.",
          "INVALID_ADDRESS": "Insira um endereço IPv6 válido.",
          "INVALID_PORT_RANGE": "Porta de origem deve ser menor ou igual à porta de destino",
          "WAN_WARNING": "Para adicionar ou editar uma regra, pelo menos uma interface WAN configurada com protocolo IPv6 deve estar ativada.",
          "INSERT_DATA": "Insira um IP e/ou porta válidos",
          "TABLE_FULL": "Tabela de filtros cheia"
        },
        "message": {
          "IPV6_DISABLED": "O recurso de Filtro IP/Porta se encontra desabilitado pois o IPv6 não está ativado."
        }
      },
      "ip_port_filter": {
        "messages": {
          "NO_RULES": "Não há regras de filtro IP/Porta criadas."
        },
        "delete": {
          "DELETE_CONFIRM": "Deseja confirmar a exclusão?",
          "DELETE_WARNING": "Esta regra será excluída permanentemente."
        }
      },
      "dhcp_client": {
        "info": {
          "NO_DHCP_CLIENTS": "Não há clientes DHCP registrados."
        }
      },
      "dhcp": {
        "label": {
          "addresses_config": {
            "CLIENT_DUID": "Cliente DUID",
            "IP_POOL_RANGE_END": "Endereço IPv6 final",
            "IP_POOL_RANGE_START": "Endereço IPv6 inicial",
            "PREFFERED_TIME": "Tempo desejado",
            "PREFIX_LENGTH": "Tamanho do prefixo",
            "REBIND_TIME": "Tempo de recuperação",
            "RENEW_TIME": "Tempo de renovação",
            "VALID_LIFETIME": "Tempo de vida válido"
          },
          "DNS_SERVERS": "Servidores DNS",
          "DOMAINS": "Domínios",
          "ENABLE_DHCP": "Ativar DHCPv6",
          "MODE_RELAY": "Modo Relay",
          "MODE_SERVER": "Modo Servidor",
          "ENABLE_MODE_SERVER_AUTO": "Ativar modo Servidor Automático"
        },
        "message": {
          "IPV6_DISABLED": "O recurso de Configurações DHCPv6 se encontra desabilitado pois o IPv6 não está ativado.",
          "error": {
            "RELAY_MODE": "O modo Relay requer pelo menos uma interface WAN configurada com protocolo IPv6 ativado.",
            "RELAY_MODE_CONFIG_WAN": "Acesse as ",
            "RELAY_MODE_CONFIG_WAN_PAG": "configurações de WAN",
            "RELAY_MODE_CONFIG_WAN_END": " para configurar uma interface",
            "SET_MODE": "Ao ativar o DHCPv6 escolha o modo Relay ou Servidor.",
            "CLIENT_DUID": "Insira um valor entre 0x00 a 0xFF",
            "IPV6_DHCP_ENABLED": "O recurso DHCPv6 está habilitado. Desabilite-o antes de desligar o IPv6."
          }
        },
        "title": {
          "DNS_SERVERS": "Servidores DNS",
          "DNS_SERVERS_LIST": "Lista de Servidores DNS",
          "DOMAINS": "Domínios",
          "DOMAINS_LIST": "Lista de Domínios",
          "CONFIG_MODES": "Modo de configuração",
          "IPV6_DHCP": "DHCPv6",
          "IPV6_DHCP_CHOICE": "Escolha a configuração DHCPv6",
          "ENABLE_IPV6_DHCP": "Ativar / Desativar DHCPv6",
          "RELAY_MENU": "Configurações modo Relay",
          "SERVER_MENU": "Configurações modo Servidor",
          "ACTIVE_CLIENTS": "Clientes DHCPv6 Ativos",
          "ACTIVE_CLIENTS_BUTTON": "Exibir clientes ativos"
        }
      },
      "prefix_delegation": {
        "label": {
          "PREFIX_MODE": "Modo do prefixo",
          "PREFIX_MODE_STATIC": "Estático",
          "PREFIX_MODE_WAN_DELEGATED": "Delegado pela WAN"
        }
      },
      "prefix-delegation": {
        "label": {
          "PREFIX": "Prefixo",
          "INTERFACE": "Interface"
        },
        "title": {
          "PREFIX_DELEGATION": "Prefixo"
        }
      },
      "proxy_mld": {
        "label": {
          "QUERY_INTERVAL": "Query Interval (em segundos)",
          "QUERY_RESPONSE_INTERVAL": "Query Response Interval (em milissegundos)",
          "RESPONSE_INTERVAL_OF_LAST_GROUP_MEMBER": "Response Interval of Last Group Member (em segundos)",
          "ROBUST_COUNT": "Robust Count"
        },
        "title": {
          "PROXY_MLD": "Proxy MLD",
          "PROXY_MLD_SETTINGS": "Configurações de Proxy MLD"
        },
        "message": {
          "IPV6_DISABLED": "O recurso de Configurações de Proxy MLD se encontra desabilitado pois o IPv6 não está ativado."
        }
      },
      "radvd": {
        "label": {
          "ADV_AUTONOMOUS": "AdvAutonomous",
          "ADV_MANAGED_FLAG": "AdvManagedFlag",
          "ADV_ON_LINK": "AdvOnLink",
          "ADV_VALID_LIFETIME": "AdvValidLifetime",
          "ADV_PREFERRED_LIFETIME": "AdvPreferredLifetime",
          "ENABLE_ULA": "Ativar ULA",
          "IPV6_PREFIX_MODE_AUTO": "Modo de endereço IPv6 automático",
          "MAX_RTR_ADV_INTERVAL": "MaxRtrAdvInterval",
          "MIN_RTR_ADV_INTERVAL": "MinRtrAdvInterval",
          "PREFIX": "Prefixo",
          "PREFIX_SIZE": "Tamanho de prefixo IPv6",
          "RDNSS1": "RDNSS1",
          "RDNSS2": "RDNSS2",
          "ULA_PREFER_TIME": "Validade Desejada do Prefixo ULA",
          "ULA_PREFIX": "Prefixo ULA",
          "ULA_PREFIX_LEN": "Tamanho do Prefixo ULA",
          "ULA_VALID_TIME": "Validade do Prefixo ULA"
        },
        "title": {
          "PREFIX": "Prefixo",
          "RADVD": "RADVD",
          "RADVD_SETTINGS": "Configurações de RADVD",
          "ULA_PREFIX": "Prefixo de ULA"
        },
        "message": {
          "IPV6_DISABLED": "O recurso de Configurações de RADVD se encontra desabilitado pois o IPv6 não está ativado."
        }
      },
      "ipv6_static_route": {
        "error": {
          "ERROR_SERVER": "Erro ao tentar aplicar as modificações no servidor: ",
          "ROUTE_ALREADY_EXISTS": "Essa rota já existe, modifique o campo de destino",
          "WAN_WARNING": "Para adicionar ou editar uma rota, pelo menos uma interface WAN configurada com protocolo IPv6 deve estar ativada.",
          "ROUTE_FAIL": "A atualização da rota falhou.",
          "NEXTHOP_FAIL": "A criação da rota falhou; cheque se o next hop está em uma rede conhecida da interface escolhida.",
          "DESTINATION_FAIL": "Endereço de destino já em uso",
          "FIELD_NOT_FOUND": "Campo não encontrado"
        },
        "info": {
          "NO_ROUTES": "Não há rotas estáticas IPv6 criadas"
        },
        "label": {
          "DESTINATION": "Destino",
          "DISABLED": "Desabilitado",
          "ENABLED": "Habilitado",
          "INTERFACE": "Interface",
          "METRIC": "Métrica",
          "NEW_ROUTE": "Criar rota",
          "NEXT_HOP": "Próximo salto",
          "INTERFACE_ANY": "Qualquer"
        },
        "message": {
          "IPV6_DISABLED": "O recurso de Configurações de Rotas Estáticas IPv6 está desabilitado pois o IPv6 não está ativado."
        },
        "placeholder": {
          "VALID_IPV6_INPUT": "Entre com um endereço IPv6 válido",
          "VALID_METRIC_INPUT": "Entre com uma métrica válida"
        },
        "title": {
          "STATIC_ROUTE": "Lista de Rotas IPv6",
          "STATIC_ROUTE_CREATE": "Criar nova rota estática IPv6",
          "STATIC_ROUTE_EDIT": "Editar rota estática"
        }
      },
      "status": {
        "label": {
          "ENABLE": "Ativar IPv6",
          "IPV6_DISABLE": "Rede/WAN",
          "IPV6_DISABLE_DHCP": "IPv6/DHCPv6"
        },
        "title": {
          "ENABLE_IPV6": "Ativar / Desativar IPv6",
          "STATUS": "Status"
        },
        "message": {
          "warning": {
            "WAN_IPV6_INTERFACES": "As seguintes interfaces WAN estão configuradas com protocolo IPv6:",
            "MODIFY_WAN_PROTOCOL": "Você deve realizar a modificação do protocolo para IPv4. Para poder realizar as configurações acesse "
          }
        }
      },
      "route_list": {
        "label": {
          "DESTINATION": "Destino",
          "NEXT_HOP": "Próximo Salto",
          "FLAGS": "Flags",
          "METRIC": "Métrica",
          "REF": "Ref",
          "USE": "Uso",
          "INTERFACEID": "Interface",
          "NO_ROUTES": "Não há rotas IPv6"
        }
      }
    },
    "login": {
      "button": {
        "ENTER": "Entrar"
      },
      "checkbox": {
        "KEEP_SESSION_ALIVE": "Manter a sessão ativa"
      },
      "message": {
        "error": {
          "INVALID_USER": "Usuário não permitido.",
          "INVALID_CREDENTIALS": "Credenciais inválidas",
          "INVALID_CREDENTIALS_MESSAGE": "Tentativas de login inválido serão penalizadas com atrasos progressivos de tempo.",
          "LOGIN_REQUEST_ERROR": "Houve um erro ao tentar realizar seu login."
        },
        "warning": {
          "CHANGE_PASSWORD_ADVICE": "É necessário alterar as credenciais de Usuário Padrão, nos campos a seguir, para aumentar a segurança do seu roteador.",
          "DEFAULT_CREDENTIALS": "Seu roteador está configurado com usuário e senha de acesso padrão."
        }
      },
      "label": {
        "ACCEPT_TERMS_OF_USE_1": "Eu aceito os",
        "ACCEPT_TERMS_OF_USE_2": "Termos de uso",
        "ACCEPT_TERMS_OF_USE_3": "do roteador Intelbras.",
        "ACCEPT_TERMS_OF_USE": "Eu aceito os {{term}} do roteador Intelbras."
      },
      "title": {
        "warning": {
          "INSECURE_CREDENTIALS": "Credenciais de acesso inseguras"
        }
      }
    },
    "menu": {
      "PARENTAL_CONTROL": "Controle dos pais",
      "PARENTAL_CONTROL_DESCRIPTION": "Configurações relacionadas ao controle dos pais",
      "CONNECTED_DEVICES": "Dispositivos Conectados",
      "CONNECTED_DEVICES_DESCRIPTION": "Lista de Dispositivos Conectados",
      "ACL_ADMIN": "Configurações de ACL",
      "ACL_ADMIN_DESCRIPTION": "Acesse as configurações de ACL do seu dispositivo",
      "ACL_IPV6": "ACL IPv6",
      "ACL_IPV6_DESCRIPTION": "Acesse as configurações de ACL IPv6 do seu dispositivo",
      "ADVANCED": "Avançado",
      "ADVANCED_DESCRIPTION": "Configurações avançadas",
      "BACKUP_RESTORE": "Backup e restauração",
      "BACKUP_RESTORE_DESCRIPTION": "Faça backup das suas configurações atuais ou restaure um backup anterior",
      "BRIDGING": "Bridging",
      "BRIDGING_DESCRIPTION": "Configuração de parâmetros de bridge",
      "CHANGE_TO_FIRMWARE_VERSION": "Trocar versão",
      "CHANGE_TO_FIRMWARE_VERSION_DESCRIPTION": "Substituir para a versão de firmware de backup",
      "DASHBOARD": "Dashboard",
      "DASHBOARD_DESCRIPTION": "Veja o status de seu dispositivo",
      "DATETIME": "Fuso horário",
      "DYNAMIC_DNS": "DNS dinâmico",
      "DYNAMIC_DNS_DESCRIPTION": "Configure um serviço de DNS dinâmico",
      "DATETIME_DESCRIPTION": "Gerencie as configurações de fuso horário do seu aparelho",
      "DOMAIN_BLOCKING": "Bloqueio de domínio",
      "DOMAIN_BLOCKING_DESCRIPTION": "Configurações de bloqueio de domínio",
      "FACTORY_RESET": "Restaurar",
      "FACTORY_RESET_DESCRIPTION": "Restaure seu aparelho as configurações de fábrica",
      "FIREWALL": "Firewall",
      "FIREWALL_ALG": "ALG",
      "FIREWALL_ALG_DESCRIPTION": "Gerencie configurações de ALG",
      "FIREWALL_DMZ": "DMZ",
      "FIREWALL_DMZ_DESCRIPTION": "Gerencie configurações de DMZ",
      "FIREWALL_DESCRIPTION": "Configure as informações de firewall",
      "FIREWALL_WLAN_ACCESS_CONTROL": "Controle de acesso WLAN",
      "WLAN_ACCESS_CONTROL_DESCRIPTION": "Permitir/Negar conexão de dispositivos via WLAN",
      "GLOBAL_ROUTING": "Roteamento global",
      "GLOBAL_ROUTING_DESCRIPTION": "Visualize a tabela de roteamento",
      "IGMP_MLD_SNOOPING": "IGMP/MLD Snooping",
      "IGMP_MLD_SNOOPING_DESCRIPTION": "Ativação e desativação de IGMP/MLD Snooping",
      "INFORMATION": "Termos de uso",
      "INFORMATION_DESCRIPTION": "Revise os termos de uso",
      "IP_PORT_FILTER": "Filtragem de IP/Porta",
      "IP_PORT_FILTER_DESCRIPTION": "Configurações de filtro de IP/Porta",
      "IPV6": "IPv6",
      "IPV6_DESCRIPTION": "Gerencie configurações avançadas de IPv6",
      "IPV6_DHCP": "DHCPv6",
      "IPV6_DHCP_DESCRIPTION": "Configurações de DHCPv6",
      "IPV6_STATIC_ROUTE": "Rotas Estáticas IPv6",
      "IPV6_STATIC_ROUTE_DESCRIPTION": "Configurações de rotas Estáticas IPv6",
      "LEDS": "Configurações de LEDs",
      "LEDS_DESCRIPTION": "Configurar LEDs do aparelho",
      "LOGOUT": "Logout",
      "LOGOUT_DESCRIPTION": "Encerrar a sessão",
      "LOOP_BACK_DETECT": "Detecção de loops",
      "LOOP_BACK_DETECT_DESCRIPTION": "Gerencie as configurações de detecção de loop do seu aparelho",
      "MAC_FILTER": "Filtragem de MAC",
      "MAC_FILTER_DESCRIPTION": "Configurações de filtro de MAC",
      "MESH_DESCRIPTION": "Habilitar InMesh",
      "NEIGHBOUR_HOSTS": "Dispositivos vizinhos",
      "NEIGHBOUR_HOSTS_DESCRIPTION": "Visualize os dispositivos vizinhos do seu dispositivo",
      "NETWORK": "Rede",
      "NETWORK_DESCRIPTION": "Gerencie as redes Wi-Fi, Internet e LAN",
      "NETWORK_WAN": "WAN",
      "NETWORK_WAN_DESCRIPTION": "Gerencie as configurações de rede WAN",
      "NETWORK_LAN": "LAN",
      "NETWORK_LAN_DESCRIPTION": "Gerencie as configurações de rede LAN",
      "NETWORK_WIFI": "Wi-Fi",
      "NETWORK_WIFI_DESCRIPTION": "Gerencie as configurações de rede WiFi",
      "NETWORK_MESH": "Mesh",
      "NETWORK_MESH_DESCRIPTION": "Gerencie as configurações de rede Mesh",
      "IPV6_RADVD": "RADVD",
      "IPV6_RADVD_DESCRIPTION": "Configurações de RADVD",
      "IPV6_PROXY_MLD": "Proxy MLD",
      "IPV6_PROXY_MLD_DESCRIPTION": "Configurações de Proxy MLD",
      "IPV6_ENABLE": "Protocolo IPv6",
      "IPV6_ENABLE_DESCRIPTION": "Ativar / Desativar protocolo IPv6",
      "IPV6_PREFIX_DELEGATION": "Prefixo Delegado",
      "IPV6_PREFIX_DELEGATION_DESCRIPTION": "Configurar prefixo",
      "VOIP_HISTORY_CALL": "Histórico de chamadas",
      "VOIP_HISTORY_CALL_DESCRIPTION": "Visualização de histórico de chamadas",
      "VOIP_DIAL_PLAN": "Plano de Discagem",
      "VOIP_DIAL_PLAN_DESCRIPTION": "Configurações do plano de discagem",
      "VOIP_ADVANCED_SIP": "SIP",
      "VOIP_ADVANCED_SIP_DESCRIPTION": "Configurações avançadas de SIP",
      "VOIP_ACCOUNT_CONFIG": "Configurações de contas",
      "VOIP_ACCOUNT_CONFIG_DESCRIPTION": "Configure uma linha VoIP",
      "VOIP_CODEC": "Codec",
      "VOIP_CODEC_DESCRIPTION": "Configurações de VoIP Codec",
      "PING": "Ping",
      "PING_DESCRIPTION": "Realize ping em algum IP/Host",
      "PON": "PON",
      "PON_COUNTERS": "Contadores",
      "PON_COUNTERS_DESCRIPTION": "Visualizar métricas da porta PON",
      "PON_STATS": "Estatísticas",
      "PON_STATS_DESCRIPTION": "Visualizar estatísticas da porta PON",
      "PON_SETTINGS": "Configurações",
      "PON_SETTINGS_DESCRIPTION": "Configurações da porta PON",
      "PON_STATUS": "Status",
      "PON_STATUS_DESCRIPTION": "Visualizar status da porta PON",
      "PON_EPON_LLID_STATUS": "EPON status LLID",
      "PON_EPON_LLID_STATUS_DESCRIPTION": "Visualizar status da tabela de LLID",
      "PON_GPON_STATUS": "Status GPON",
      "PON_GPON_STATUS_DESCRIPTION": "Visualizar informações de status GPON",
      "PON_GEM_PORT_STATISTICS": "Tabela GEM",
      "PON_GEM_PORT_STATISTICS_DESCRIPTION": "Visualizar informações da tabela GEM",
      "PON_DESCRIPTION": "Gerencie a interface PON do seu aparelho",
      "PORT_FORWARD": "Redirecionamento de portas",
      "PORT_FORWARD_DESCRIPTION": "Configure os redirecionamentos de porta do seu dispositivo",
      "PROXY_IGMP": "Proxy IGMP",
      "PROXY_IGMP_DESCRIPTION": "Configurações de Proxy IGMP",
      "QOS_CLASSIFICATION": "Classificação QoS",
      "QOS_CLASSIFICATION_DESCRIPTION": "Configurações de Classificação QoS",
      "QOS_TRAFFIC_CONTROL": "Controle de tráfego QoS",
      "QOS_TRAFFIC_CONTROL_DESCRIPTION": "Configurações de controle Tráfego QoS",
      "REBOOT": "Reiniciar",
      "REBOOT_DESCRIPTION": "Reinicie o seu aparelho",
      "RIP_CONFIG": "Configuração de RIP",
      "RIP_CONFIG_DESCRIPTION": "Habilite e ajuste as configurações de RIP",
      "SERVICE": "Serviços",
      "SERVICE_DESCRIPTION": "Administre serviços do seu dispositivo",
      "STATIC_ROUTING": "Rotas Estáticas",
      "STATIC_ROUTING_DESCRIPTION": "Configuração e edição de rotas estáticas",
      "SYSTEM": "Sistema",
      "SYSTEM_DESCRIPTION": "Atualize e realize ações de manutenção",
      "TOOLS": "Ferramentas",
      "TOOLS_DESCRIPTION": "Acesse funções úteis para seu dia a dia",
      "TR069_CONFIG": "Configurações de TR-069",
      "TR069_CONFIG_DESCRIPTION": "Faça as configurações de TR-069",
      "TR069_INFORM": "Informações de TR-069",
      "TR069_INFORM_DESCRIPTION": "Acesse informações de diagnóstico sobre o TR-069",
      "TRACEROUTE": "Traceroute",
      "TRACEROUTE_DESCRIPTION": "Verifique as rotas de saída do seu dispositivo",
      "UPDATE_FIRMWARE": "Atualizar firmware",
      "UPDATE_FIRMWARE_DESCRIPTION": "Atualize a versão do seu firmware",
      "UPNP": "UPnP",
      "UPNP_DESCRIPTION": "Configurações de UPnP",
      "URL_BLOCKING": "Bloqueio de URL",
      "URL_BLOCKING_DESCRIPTION": "Configurações de bloqueio de URL",
      "USER_AND_PASSWORD": "Usuário e senha",
      "USER_AND_PASSWORD_DESCRIPTION": "Altere as informações de acesso ao roteador",
      "VOIP": "VoIP",
      "VOIP_DESCRIPTION": "Gerencie as configurações de VoIP do seu aparelho",
      "WIFI_DESCRIPTION": "Salvar rede Wi-Fi",
      "WPS_DESCRIPTION": "WPS iniciado com sucesso",
      "WPS_REQUIREMENT": "Requisito para o WPS",
      "IPV6_ROUTE_LIST": "Lista de Rotas",
      "IPV6_ROUTE_LIST_DESCRIPTION": "Lista de Rotas IPv6",
      "IPV6_IP_PORT_FILTER": "Filtro IP/Porta",
      "IPV6_IP_PORT_FILTER_DESCRIPTION": "Configurações de filtro IP/Porta",
      "ADVANCED_QOS_POLICY": "Política QoS",
      "ADVANCED_QOS_POLICY_DESCRIPTION": "Configurações de política QoS",
      "ADVANCED_QOS_POLICY_ENABLE": "Habilitar política QoS",
      "AUTOMATIC_MAINTENANCE": "Manutenção automática",
      "AUTOMATIC_MAINTENANCE_DESCRIPTION": "Configure a manutenção automática do sistema"
    },
    "modal": {
      "answer": {
        "CONTINUE": "Continuar",
        "NO": "Não",
        "POLITE_NO": "Não, obrigado",
        "UNDERSTOOD": "Ok, entendi",
        "YES": "Sim",
        "YES_CHANGE": "Sim, quero alterar"
      },
      "question": {
        "WISH_TO_DELETE": "Tem certeza que deseja excluir esse item?"
      }
    },
    "network": {
      "label": {
        "NETWORK": "Rede"
      },
      "lan": {
        "error": {
          "TRY_DELETE_ERROR": "Erro ao tentar excluir o elemento da lista.",
          "ACL_ADMIN_MUST_BE_DISABLE_TO_AVIOD_LOST_OF_ACCESS": "ACL admin deve ser desabilitado para evitar perda de acesso.",
          "ADDRESS_OUT_OF_RANGE": "Endereço IP fora da faixa do servidor DHCP",
          "COULD_NOT_ADD_LEASE": "Ocorreu um erro ao tentar adicionar reserva.",
          "COULD_NOT_EDIT_LEASE": "Ocorreu um erro ao tentar editar reserva.",
          "DNS_REPEATED": "Os endereços DNS não podem ser repetidos",
          "ERROR_MESSAGE_CUSTOM": "Ocorreu um erro ao tentar excluir registro(s).",
          "ERROR_TEXT_MESSAGE_CUSTOM": "Excluindo registros(s)...",
          "IP_ADDRESS_ALREADY_IN_USE": "Endereço IP já utilizado",
          "MAC_ADDRESS_ALREADY_IN_USE": "Endereço MAC já utilizado",
          "MAX_LEASES_REACHED": "Não foi possível incluir uma nova reserva, porque o número máximo de reservas foi atingido.",
          "MAX_NUMBER_OF_RULES_CUSTOM": "O número máximo de registros foi atingido."
        },
        "label": {
          "CONNECTED": "Conectado",
          "DHCP_RANGE_END": "Endereço IP Final",
          "DHCP_RANGE_START": "Endereço IP Inicial",
          "DHCP_SERVER": "Servidor DHCP",
          "DISCONNECTED": "Desconectado",
          "DNS1": "DNS 1",
          "DNS2": "DNS 2",
          "ENABLED": "Habilitar",
          "IP": "IP",
          "IP_ADDRESS": "Endereço IP",
          "IPV4_ADDRESS": "Endereço IPv4",
          "LEASE": "registro",
          "MAC": "MAC",
          "MAC_ADDRESS": "Endereço MAC",
          "NETMASK": "Máscara de sub-rede",
          "NO_RULE_ON_REGISTER_MESSAGE_CUSTOM": "Não há registros no cadastro",
          "RESERVATION": "Reservas de endereço IP",
          "STATUS": "Status",
          "TOTAL_RULES_LABEL_CUSTOM": "Total de registros",
          "DNS_MANUALLY": "DNS Manual"
        },
        "success": {
          "SUCCESS_TEXT_MESSAGE_CUSTOM": "Entrada(s) de registro(s) excluída(s) com sucesso."
        },
        "title": {
          "IP_LEASES": "Reservas de endereço IP",
          "ADD_NEW_IP_LEASE": "Adicionar nova reserva de endereço IP",
          "LAN_PAGE": "Configurações da interface LAN",
          "LAN": "LAN",
          "DHCP_ACTIVE_CLIENTS": "Exibir clientes ativos",
          "ACTIVE_CLIENTS": "Clientes DHCP Ativos"
        },
        "info": {
          "NO_DHCP_CLIENTS": "Não há clientes DHCP registrados.",
          "NO_IP_LEASE": "Não há endereços de IP reservados."
        },
        "warning": {
          "ADDRESS_OUT_OF_RANGE": "de endereço fora da nova faixa de rede configurada.",
          "CONFIRM_EXCLUSION": "Deseja mesmo deletar o item?",
          "DHCP_STATIC_LEASE": "Reservas de IP somente farão efeito caso o servidor DHCP esteja habilitado.",
          "LEASE_DELETE": "Essa regra será excluída permanentemente.",
          "MAY_BE_NECESSARY_TO_RECONNECT": "Pode ser necessário conectar novamente à rede do seu roteador.",
          "ON_SAVE_PLURAL": "Ao salvar, essas",
          "ON_SAVE_SINGULAR": "Ao salvar, essa",
          "PERMANENT_DELETE_WARNING": "Os itens selecionados serão excluídos permanentemente.",
          "RECONNECT_TO_NETWORK": "Reconecte-se à rede do seu roteador utilizando o novo endereço ip.",
          "REMOVED_PLURAL": "excluídas",
          "REMOVED_SINGULAR": "excluída",
          "RESERVATION_PLURAL": "reservas",
          "RESERVATION_SINGULAR": "reserva",
          "WAIT_FOR_APPLY": "Aguarde enquanto aplicamos suas configurações.",
          "WILL_BE_PLURAL": "serão",
          "WILL_BE_SINGULAR": "será",
          "WISH_TO_CONTINUE": "Deseja continuar?",
          "YOU_GOT": "Você possui"
        }
      },
      "mesh": {
        "label": {
          "ADD_NEW_ROUTER": "Adicionar novo roteador",
          "TRY_AGAIN": "Tente novamente",
          "ADD_ROUTER": "Adicionar roteador",
          "CONNECT_WITH": "Conectado ao",
          "IP": "Endereço IP",
          "CONNECTED_DEVICES": "Dispositivos conectados",
          "CONTINUE": "Continuar",
          "CURRENT_NODE": "Nó atual",
          "DATE_TIME": "Data e hora",
          "DELETE_NODE": "Excluir nó",
          "DEVICE": "dispositivo",
          "DEVICE_FIRSTUP": "Dispositivo",
          "ENABLE": "Habilitar",
          "EXCELENT_CABLE": "Excelente",
          "PHYSICAL_ENV": "Meio físico",
          "FINISH": "Finalizar",
          "FIRMWARE_VERSION": "Versão do firmware",
          "INMESH": "INMESH",
          "ENABLE_INMESH": "Habilitar rede INMESH",
          "IPV6_ADDRESS": "Endereço IPv6",
          "IPV6_LAN": "IPv6 (LAN)",
          "LOCALIZATION": "Localização",
          "MAC_LAN": "MAC da LAN",
          "MAIN_GATEWAY": "Principal (Gateway)",
          "MAIN_NODE": "Nó principal",
          "MESH_NODES": "Nós INMESH",
          "MODEL": "Modelo",
          "MY_INMESH_NETWORK": "Minha Rede InMesh",
          "NEW_ROUTER": "Novo roteador",
          "OFFICE": "Ex.: Escritório",
          "REMOVE": "Remover",
          "ROUTER": "Roteador",
          "RUNNING_TIME": "Tempo ativo",
          "SECONDARY": "Secundário",
          "SECONDARY_NODE": "Nó secundário",
          "SIGNAL": "Sinal",
          "SIGNAL_QUALITY": "Qualidade de sinal",
          "START": "Iniciar",
          "STATUS": "Status",
          "STATUS_NODE": "Status do Nó",
          "TX_DOWNLOAD": "Taxa de download",
          "TX_UPLOAD": "Taxa de upload",
          "TYPE": "Tipo",
          "VIEW": "Visualizar"
        },
        "message": {
          "info": {
            "SUCCESS": "Roteador adicionado com sucesso! Se o novo equipamento não aparecer na lista em 5 minutos reinicie esse dispositivo.",
            "AMPLIFY_YOUR_COVERAGE_ARE_CLICK_BUTTON_BELOW": "Clique no botão abaixo e comece a ampliar sua área de cobertura!",
            "WPS_DISABLED": "O WPS está desativado para a rede raíz, portanto, novos roteadores não poderão ser adicionados.",
            "CHECK_IF_ROUTER_IS_ON": "Certifique-se de que o seu novo roteador esteja ligado e aguarde um instante, por favor...",
            "CONDITIONS_FOR_MESH": "A rede mesh só poderá ser habilitada como Nó principal se a rede wireless estiver configurada como dualband, com senha definida, e com ambas as frequências de radio ativadas.",
            "CONFIG_WIRELESS_NETWORK": "Configurar rede wireless e rádio",
            "CONFIGURING_NEW_ROUTER": "Configurando seu novo roteador",
            "CONFIRM_CONTROLLER_1": "Ao habilitar este dispositivo como nó primário, ele passará a ser o controlador de uma nova rede mesh,",
            "CONFIRM_CONTROLLER_2": "necessitando sincronizar os demais dispositivos da rede.",
            "CONNECTING_NEW_ROUTER": "Conectando seu novo roteador",
            "DEVICE_SYNCING_WARNING": "O aparelho está em processo de sincronização da rede Mesh.",
            "DEVICE_SYNCING_WAIT": "Aguarde até sua finalização, que poderá levar até 5 minutos.",
            "ENJOY_NEW_COVERED_AREA": "Seu roteador está pronto para ser posicionado no cômodo desejado. Aproveite sua nova área de cobertura.",
            "FINAL_STEP": "Estamos na etapa final. Aguarde mais um instante, por favor...",
            "FINISHING_SYNC": "Finalizando sincronização...",
            "INSTRUCTION_1": "Clique no botão abaixo para Iniciar o processo de adição do seu produto",
            "INSTRUCTION_2": "Posicione e ligue o seu novo roteador ao lado do nó principal",
            "INSTRUCTION_3": "Ao fim do processo, desligue e posicione o seu roteador no cômodo desejado",
            "MAIN_ROUTER_INFO": "O roteador em modo de nó primário será o responsável pelo gerenciamento da rede mesh, enquanto os nós secundários se conectarão utilizando as configurações de um nó primário.",
            "NO_ROUTER_ADDED": "Nenhum roteador adicionado.",
            "NO_ROUTER_ADDED_AT_THIS_MOMENT": "Você não possui um roteador adicionado no momento.",
            "RADIO0_CONDITIONS_FOR_MESH": "Um canal estático deve ser selecionado para o rádio de 5 GHz de modo que a rede mesh possa ser habilitada.",
            "ROUTER_EXAMPLES": "Adicionar novo nó, modelos disponíveis:",
            "ROUTER_POSITION": "Insira o local onde o seu roteador será posicionado para facilitar a organização da sua rede mesh.",
            "SEARCHING_ROUTER": "Buscando seu novo roteador",
            "SYNCING": "Sincronizando",
            "SYNCING_INFO": "O dispositivo entrará em estado de sincronização e permanecerá assim por 5 minutos, ou até sincronizar com outro dispositivo.",
            "SYNCRHONIZE": "Sincronizar",
            "THIS_PROCEDURE_CAN_TAKE_SEVERAL_MINUTES": "Esse procedimento pode levar alguns minutos. Por favor, não feche esta janela.",
            "VAPS_CONDITIONS_FOR_MESH": "Para habilitar a rede mesh, os VAPs ativos devem possuir opção de segurança selecionada e senha preenchida.",
            "WAIT_A_MINUTE_PLEASE": "Aguarde um instante, por favor...",
            "WAIT_WHILE_WE_DELETE_NODE": "Aguarde enquanto deletamos o nó da rede mesh.",
            "WISH_TO_GO_ON": "Deseja prosseguir?",
            "WPA2_CONDITIONS_FOR_MESH": "A rede InMesh atualmente suporta apenas o modo de encriptação WPA2. Para habilitá-la, modifique o modo de encriptação selecionado na rede raíz.",
            "YOU_ARE_GOING_TO_BE_REDIRECTED_TO_LOGIN": "Ao finalizar, você será direcionado para a tela inicial."
          },
          "error": {
            "COULD_NOT_CONNECT": "Não foi possível se conectar ao novo roteador.",
            "DOES_NOT_SUPORT_MODE": "Esse aparelho não suporta esse modo"
          }
        },
        "title": {
          "INMESH_NODE_INFO": "Informações sobre nó INMESH",
          "ADVANCED_INFO": "Informações avançadas",
          "CONNECTION_STATUS": "Status de conexão",
          "NEW_MESH_ROUTER": "Novo roteador mesh",
          "PRODUCT_INFO": "Informações do produto",
          "USE_INFO": "Informações de uso"
        }
      },
      "wan": {
        "label": {
          "ACTIVATE_NAPT": "Ativar NAPT",
          "ACTIVATE_VLAN": "Ativar VLAN",
          "REQUEST_PREFIX": "Solicitar prefixo",
          "CONNECTION_TYPE": "Tipo de conexão",
          "DNS_SERVER_1": "Servidor DNS 1",
          "DNS_SERVER_2": "Servidor DNS 2",
          "ENABLE_MULTICAST_VLAN": "Ativar VLAN de Multicast (Avançado)",
          "GATEWAY_IPV4": "Gateway IPv4",
          "INTERFACE": "Interface",
          "IP_ADDRESS": "Endereço IP local",
          "IPV6_ADDRESS": "Endereço IPv6",
          "IPV6_GATEWAY": "Gateway IPv6",
          "IPV6_ADDR_MODE": "Modo de endereço IPv6",
          "IPV6_ADDR_MODE_SLAAC": "Stateless DHCPv6 (SLAAC)",
          "MODE": "Modo",
          "MTU": "MTU",
          "MULTICAST_VLAN_ID": "VLAN de Multicast",
          "NETMASK": "Máscara de sub-rede",
          "PASSWORD": "Senha",
          "REQUEST_DNS": "Solicitar DNS",
          "SERVER": "Servidor",
          "SERVICE_NAME": "Nome do serviço",
          "USER": "Usuário",
          "IPV6_STATUS": "IPv6/Status",
          "VLAN_ID": "VLAN ID"
        },
        "message": {
          "error": {
            "DNS_IPV4_IS_EMPTY": "Deve fornecer ao menos um endereço de DNS IPv4",
            "DNS_IPV4_REQUIRED": "Em modo de conexão WAN Static, não é possível selecionar o DNS",
            "DNS_IPV6_IS_EMPTY": "Deve fornecer ao menos um endereço de DNS IPv6",
            "ERROR_SERVER": "Erro no servidor:",
            "ERROR_SERVER_RETRIEVE": "\"Não foi possível recuperar as informações de WAN\" ",
            "GATEWAY_AND_IPV4_ADDRESS_MUST_BE_DIFFERENT": "Gateway e endereço IPv4 não podem ser iguais",
            "GATEWAY_AND_IPV4_ARE_NOT_IN_SAME_SUBNET": "Gateway e endereço IPv4 não pertecem a mesma sub-rede",
            "IPV4_ADDRESS_CANNOT_BE_IN_THE_SAME_SUBNET_OF_LAN": "O endereço IPv4 não pode estar na mesma sub-rede da LAN",
            "IPV4_GATEWAY_IS_EMPTY": "Está faltando o endereço de gateway IPv4",
            "IPV6_ADDRESS_AND_GATEWAY_CANNOT_BE_EQUALS": "Gateway e endereço IPv6 não podem ser iguais",
            "IPV6_GATEWAY_IS_EMPTY": "Está faltando o endereço de gateway IPv6",
            "MUST_HAVE_IPV4_OR_IPV6": "Para uma conexão Static deve fornecer informações de IPv4 e/ou IPv6",
            "ONLY_ONE_WAN_WITH_DISABLED_VLAN_IS_ENABLED": "Já existe uma WAN com VLAN desabilitada",
            "VLAN_ID_ALREADY_USED": "Já existe uma WAN com esse ID",
            "WAN_INTERFACE_NOT_FOUND": "Interface WAN não encontrada",
            "VLAN_ALREADY_IN_USE": " existe outra conexão com a mesma configuração de VLAN",
            "GATEWAY_AND_IPV4_SUBNET_ERROR": "Gateway IPv4 e endereço IP local devem pertercer a mesma sub-rede"
          },
          "NO_WANS_REGISTERED": "Não há nenhuma WAN configurada.",
          "warning": {
            "DISABLED_PORTS": "ATENÇÃO: As portas com '*' já estão sendo usadas em outras WANs.",
            "MAX_WANS_REACHED": "O número máximo de WANs foi alcançando",
            "MUST_CHOOSE_AT_LEAST_ONE_PORT": "Escolha pelo menos uma porta",
            "IPV6_PROTOCOL_ENABLE": "Para poder realizar as configurações habilite o IPV6 em ",
            "IPV6_OPTIONS_DISABLED": "As opções de protocolo relacionadas a IPv6 se encontram desabilitadas pois o IPv6 não está ativado.",
            "NETWORK_MODE_CHANGED": "Modificando o modo, outros recursos atrelados a esta interface WAN poderão precisar ser reconfigurados e suas configurações atuais podem não funcionar corretamente.",
            "NETWORK_PROTOCOL_CHANGED": "Modificando o protocolo, configurações de outros recursos atrelados a esta interface WAN podem não funcionar corretamente."
          }
        },
        "title": {
          "INSERT_NEW_WAN": "Inserir WAN",
          "IPV4_CONFIGURATION": "Configurações de IPv4",
          "IPV6_CONFIGURATION": "Configurações de IPv6",
          "PORT_MAPPING": "Mapeamento de portas",
          "PPPOE_CONFIGURATION": "Configurações de PPPoE",
          "STANDARD_INTERFACE": "Interface padrão",
          "UPDATE": "Atualizar",
          "WAN": "WAN",
          "WAN_LIST": "Lista de WANs"
        },
        "warning": {
          "PERMANENT_DELETE_WARNING": "A WAN selecionada será excluída permanentemente e quaisquer recursos que a referenciam serão desabilitados ou terão suas respectivas regras removidas."
        }
      },
      "wifi": {
        "radio": {
          "WARNING": "Ao alterar as configurações dos rádios, as redes WiFi criadas serão reconfiguradas automaticamente."
        },
        "label": {
          "IEEE_STD": "Tipo de banda IEEE",
          "BANDWIDTH": "Largura de banda",
          "BROADCAST_CHECKBOX": "Divulgar o nome da rede",
          "CHANNEL": "Canal",
          "CHANNEL_EXTENSION": "Canal de extensão",
          "CREATE_NETWORK": "Criar rede Wi-Fi",
          "CREATE_WIFI_NETWORK": "Criar rede wi-fi",
          "CURRENT_CHANNEL": "Canal atual",
          "DUAL_BAND": "Dual band (2,4GHz e 5GHz)",
          "ENABLE_MU_MIMO": "Habilitar MU-MIMO",
          "OPEN_WLAN_CHECKBOX": "Rede Wi-Fi sem senha",
          "PASSWORD": "Senha da rede",
          "SECURITY": "Segurança",
          "SECURITY_MODE_CRYPTO": "Sem criptografia",
          "SPLIT_DUAL": "Separar rede dualband",
          "SSID_5GHZ_NAME": "Nome da rede 5GHz",
          "SSID_NAME": "Nome da rede",
          "VAP_COUNTER_2_4GHZ": "VAPs em 2,4 GHz",
          "VAP_COUNTER_5GHZ": "VAPs em 5 GHz",
          "WPA2_RECOMMENDED": "WPA2 (recomendado)",
          "WPS_CHECKBOX": "Habilitar o botão WPS"
        },
        "message": {
          "delete": {
            "DELETE_CONFIRM": "Deseja confirmar a exclusão?",
            "DELETE_ERROR": "Ocorreu um erro ao tentar excluir a rede wireless.",
            "DELETE_SUCCESS": "Rede wireless excluída com sucesso.",
            "DELETE_WARNING": "Esta rede wireless será excluída permanentemente.",
            "DELETING": "Excluindo..."
          },
          "info_popup": {
            "BROADCAST_CHECKBOX": "Ao desabilitar esta opção, o nome não irá aparecer na lista de redes Wi-Fi do seu dispositivo. Assim, para se conectar, você terá que especificar o nome e senha nas configurações adicionais de rede.",
            "DUALBAND_CHECKBOX": "Ao ativar esta opção, a rede irá operar em ambas frequências com os mesmos parâmetros definidos neste formulário.",
            "MESH_EDIT": "Esta não pode ser alterada para o funcionamento da rede Mesh. Caso queira desfazer a rede Mesh, acesse o menu Mesh.",
            "MESH_OFF": "Esta opção deve permanecer DESATIVADA para o funcionamento da rede Mesh. Caso queira desfazer a rede Mesh, acesse o menu Mesh.",
            "MESH_ON": "Esta opção deve permanecer ATIVADA para o funcionamento da rede Mesh. Caso queira desfazer a rede Mesh, acesse o menu Mesh.",
            "SPLIT_DUAL": "Ao habilitar esta opção, será possível definir nomes de rede distintos para as frequências de 2,4GHz e 5GHz. Dessa forma, as redes Wi-Fi aparecerão em itens distintos na lista e poderão ter seus parâmetros editados separadamente.",
            "WPS_CHECKBOX": "Ao habilitar esta opção, uma conexão simplificada poderá ser feita com dispositivos que possuam a função WPS. Assim, para se conectar, você terá que pressionar o botão WPS neste roteador e no outro dispositivo."
          },
          "warning": {
            "EXPLAINING_BROADCAST_WPS": "Desabilitar a divulgação do nome da rede irá também desabilitar o WPS. Deseja continuar?",
            "EXPLAINING_MESH_VAP": "O VAP0 para o Rádio 2,4 GHZ e/ou 5 GHz será gerenciado automaticamente.",
            "EXPLAINING_PASSWORD_WPS": "Alguns clientes talvez não consigam se conectar via WPS quando há um espaço em branco diretamente precedendo ou sucedendo a senha, e.g., \" abcd\" ou \"abcd \".",
            "EXPLAINING_WIFI_SECURITY": "Não há opção de segurança selecionada; isso pode ser perigoso.",
            "EXPLAINING_WPA3_WPS": "A opção de criptografia WPA3 não suporta WPS, portanto, ele será desabilitado. Deseja continuar?",
            "EXPLAINING_WPS_INFO": "Você deve executar o WPS no aparelho cliente em até 2 minutos.",
            "FORBIDDEN_PREFIX": "Prefixo não permitido",
            "MAX_WIFI": "A quantidade máxima de Redes Wi-Fi foi atingida.",
            "NO_WIFI_NETWORKS": "Não há redes WiFi disponíveis. Verifique se há algum rádio configurado e tente novamente.",
            "RESERVED_NAME": "Nome reservado",
            "SAME_SSID": "Esse nome já está sendo usado",
            "RESTART_ROUTER": "Pode ser necessário conectar novamente à rede do seu roteador.",
            "SAME_SPLIT_SSID": "Os nomes das redes (SSIDs) precisam ser diferentes",
            "SAVE_CONFIG": "Aguarde enquanto aplicamos suas configurações.",
            "SAVE_CONN": "Se você está acessando o roteador via Wi-Fi, para continuarmos, conecte-se à rede "
          }
        },
        "tip": {
          "SHOW_CONNECTED_CLIENT": "Exibir clientes conectados",
          "EDIT_WIFI": "Editar rede Wi-Fi",
          "DELETE_WIFI": "Excluir rede Wi-Fi"
        },
        "title": {
          "RADIO": "Rádio",
          "RADIO_TITLE": "Configurações de rádio",
          "WIFI_EDIT": "Editar rede Wi-Fi",
          "WIFI_CONFIG": "Configurações de rede Wi-Fi",
          "WIFI_TITLE": "Redes disponíveis",
          "WPS_TITLE": "Conectar por WPS"
        },
        "status": {
          "title": {
            "SHOW_WLAN_STATUS": "Exibir status WLAN",
            "WLAN_STATUS_5G": "Status WLAN 5 GHz",
            "WLAN_STATUS_2_4G": "Status WLAN 2.4 GHz",
            "WLAN_STATUS": "Rádio",
            "WLAN_STATUS_VAP": "Pontos de acesso virtuais (VAP)"
          },
          "messages": {
            "WLAN_NOT_CONFIGURED": "Rede WLAN não configurada.",
            "WLAN_VAP_NOT_CONFIGURED": "Pontos de acesso virtuais não configurados.",
            "UPDATE_LIST": "Atualizar",
            "SHOW_2_4G": "Visualizar rádio 2.4 GHz",
            "SHOW_5G": "Visualizar rádio 5 GHz"
          },
          "label": {
            "CRYPTOGRAPHY": "Criptografia"
          }
        },
        "connected_devices": {
          "title": {
            "CONNECTED_TO_NETWORK": "Dispositivos conectados na rede",
            "_5GHZ_FREQUENCY": "Frequência de 5GHz",
            "_2_4GHZ_FREQUENCY": "Frequência de 2.4GHz"
          },
          "info": {
            "NO_DEFICES_CONNECTED": "Não há dispositivos conectados nesta rede sem fio."
          },
          "column_header": {
            "HOSTNAME": "Nome",
            "MAC": "Endereço MAC",
            "TX_BYTES": "Bytes Tx",
            "RX_BYTES": "Bytes Rx",
            "TX_RATE": "Taxa Tx (Mbps)"
          }
        }
      }
    },
    "pon": {
      "counters": {
        "label": {
          "BYTES_RECEIVED": "Bytes recebidos",
          "BYTES_SENT": "Bytes enviados",
          "FEC_ERRORS": "Erros FEC",
          "HEC_ERRORS": "Erros HEC",
          "PACKAGES_BROADCAST_RECEIVED": "Pacotes broadcast recebidos",
          "PACKAGES_BROADCAST_SENT": "Pacotes broadcast enviados",
          "PACKAGES_DROPPED": "Pacotes descartados",
          "PACKAGES_MULTICAST_RECEIVED": "Pacotes multicast recebidos",
          "PACKAGES_MULTICAST_SENT": "Pacotes multicast enviados",
          "PACKAGES_PAUSE_RECEIVED": "Pacotes de pausa recebidos",
          "PACKAGES_PAUSE_SENT": "Pacotes de pausa enviados",
          "PACKAGES_RECEIVED": "Pacotes recebidos",
          "PACKAGES_SENT": "Pacotes enviados",
          "PACKAGES_UNICAST_RECEIVED": "Pacotes unicast recebidos",
          "PACKAGES_UNICAST_SENT": "Pacotes unicast enviados"
        },
        "title": {
          "COUNTERS": "Contadores"
        }
      },
      "epon_llid": {
        "message": {
          "error": {
            "COULD_NOT_GET_INFO": "Não foi possível recuperar as informações de LLIDStatus"
          }
        },
        "title": {
          "EPON_LLID_STATUS": "EPON status LLID"
        }
      },
      "gem_port_statistics": {
        "error": {
          "COULD_NOT_GET_INFO": "Não foi possível recuperar informações de GEM Port."
        },
        "label": {
          "DIRECTION": "Direção",
          "FLOW_ID": "ID do fluxo",
          "GEM_PORT_ID": "ID da porta GEM",
          "PACKAGE_COUNT": "Contagem de pacotes"
        },
        "title": {
          "GEM_PORT_STATISTICS": "Estatísticas das portas GEM"
        }
      },
      "gpon_status": {
        "label": {
          "ONU_ID": "ONU ID",
          "ONU_STATE": "ONU state",
          "ONU_STATE_DESC": "ONU state desc"
        },
        "message": {
          "error": {
            "COULD_NOT_RETRIEVE_DATA": "Não foi possível recuperar informações de GPON Status"
          }
        },
        "title": {
          "GPON_STATUS": "Informações De Status GPON"
        }
      },
      "label": {
        "COUNTERS": "Contadores",
        "EPON_LLID_STATUS": "EPON status LLID",
        "GEM_TABLE": "Tabela GEM",
        "GPON_STATUS": "Status GPON",
        "STATISTICS": "Estatísticas"
      },
      "settings": {
        "label": {
          "EPON_ONU_MODEL": "EPON ONU model",
          "GPON_ID": "GPON ID",
          "GPON_ONU_MODEL": "GPON ONU model",
          "HARDWARE_VERSION": "Versão do hardware",
          "LOID": "Loid",
          "LOID_PASSWORD": "Senha LOID",
          "OLT_PROFILE": "Perfil da OLT",
          "ONU_MAC": "MAC da ONU",
          "PLOAM_PASSWORD": "Senha PLOAM",
          "SERIAL_NUMBER": "Número de série"
        },
        "olt_profile": {
          "INTELBRAS": "Intelbras",
          "HUAWEI": "Huawei",
          "ZTE": "ZTE",
          "CUSTOM": "Customizado",
          "FH_CUSTOM_1": "FH Custom 1",
          "HUAWEI_NOKIA": "Huawei/Nokia"
        },
        "title": {
          "OAM": "OAM",
          "OMCI": "OMCI",
          "SECURITY": "Segurança"
        }
      },
      "stats": {
        "label": {
          "RX": "RX (Bps)",
          "RX_PACKAGE": "RX (pps)",
          "TX": "TX (Bps)",
          "TX_PACKAGE": "TX (pps)"
        },
        "title": {
          "FLOW_RATE": "Vazão",
          "PACKAGES_PER_SECOND": "Pacotes Por Segundo (PPS)",
          "PON_STATISTICS": "Estatísticas PON"
        }
      },
      "status": {
        "label": {
          "CURRENT_MODE": "Modo atual",
          "POLARIZATION_CURRENT": "Corrente de polarização (mA)",
          "RX_SIGNAL_STRENGTH": "Intensidade do sinal RX (dBm)",
          "TEMPERATURE": "Temperatura (°C)",
          "TX_SIGNAL_STRENGTH": "Intensidade do sinal TX (dBm)",
          "VOLTAGE": "Tensão (V)"
        }
      }
    },
    "search": {
      "placeholder": {
        "SEARCH": "Pesquisar..."
      }
    },
    "service": {
      "dynamic_dns": {
        "message": {
          "information": {
            "NO_ENTRY_ON_LIST": "Não regras de DNS dinâmico cadastradas"
          },
          "error": {
            "REPEATED_HOSTNAME": "Endereço já existe",
            "PROVIDER_NOT_SUPPORTED": " provedor não suportado.",
            "HOSTNAME_REQUIRED": " nome do hostname necessário.",
            "HOSTNAME_INVALID": " hostname inválido.",
            "USER_AND_PASSWORD_REQUIRED": " usuário e senha necessários.",
            "HOSTNAME_ALREADY_IN_USE": " hostname já em uso."
          },
          "delete": {
            "DELETE_SUCCESS": "Item excluído com sucesso."
          }
        },
        "title": {
          "DYNAMIC_DNS_LIST": "Lista de DNSs dinâmicos"
        },
        "label": {
          "HOSTNAME": "Hostname",
          "PROVIDER": "Provedor",
          "INTERFACE": "Interface",
          "USERNAME": "Usuário",
          "PASSWORD": "Senha",
          "ENABLED": "Ativo",
          "INSTANCE_NUM": "Instância",
          "SERVICE_PORT": "Porta de serviço",
          "SETTINGS": "Configurações de DNS dinâmico",
          "STATUS": "Status",
          "DDNS_STATUS_SUCCESFULLY_UPDATED": "Atualizado com sucesso",
          "DDNS_STATUS_CONNECTION_ERROR": "Falha na conexão",
          "DDNS_STATUS_AUTH_FAILURE": "Autenticação falhou",
          "DDNS_STATUS_WRONG_OPTION": "Opção errada",
          "DDNS_STATUS_HANDLING": "Atualizando",
          "DDNS_STATUS_LINK_DOWN": "Link desligado"
        }
      },
      "proxy_igmp": {
        "label": {
          "ALLOW_MULTICAST": "Habilitar multicast",
          "GROUP_LEAVE_DELAY": "Group leave delay",
          "LAST_MEMBER_QUERY_COUNT": "Last member query count",
          "QUERY_INTERVAL": "Query interval",
          "QUERY_RESPONSE_INTERVAL": "Query response interval",
          "ROBUST_COUNT": "Robust count"
        },
        "title": {
          "PROXY_IGMP": "Proxy IGMP",
          "SETTINGS": "Configurações de proxy IGMP"
        },
        "upnp": {
          "label": {
            "ALL_INTERFACES": "Qualquer",
            "ALLOW_UPNP": "Habilitar UPnP",
            "INTERFACE": "Interface"
          },
          "title": {
            "UPNP": "UPnP"
          }
        }
      },
      "rip_config": {
        "error": {
          "ERROR_SERVER": "\"Erro ao tentar aplicar as modificações no servidor\":",
          "INTERFACE_BUSY": "Interface já em uso",
          "DESTINATION_BUSY": "Destination busy"
        },
        "info": {
          "NO_RIP_CONFIGS": "Não há configurações de RIP criadas"
        },
        "label": {
          "ALL_INTERFACES": "Qualquer",
          "INTERFACE": "Interface",
          "RECEIVE_MODE": "Modo RX",
          "SEND_MODE": "Modo TX",
          "NONE": "Nenhum",
          "RIPV1": "RIPV1",
          "RIPV2": "RIPV2",
          "RIPV1_COMPAT": "RIPV1_COMPAT"
        },
        "title": {
          "RIP_CONFIG": "Configuração de RIP",
          "RIP_CONFIG_TABLE": " Tabela de configuração RIP",
          "RIP_CONFIG_CARD": "Seleção de interface",
          "RIP_ENABLE": "Habilitar/Desabilitar RIP"
        }
      },
      "title": {
        "SERVICE": "Serviço"
      },
      "upnp": {
        "label": {
          "ALLOW_UPNP": "Habilitar UPnP",
          "INTERFACE": "Interface"
        },
        "title": {
          "SETTINGS": "Configurações de UPnP",
          "UPNP": "UPnP"
        },
        "warning": {
          "NO_WAN_INTERFACES_AVAILABLE": "Não há interfaces WAN disponíveis"
        }
      }
    },
    "system": {
      "acl_admin": {
        "label": {
          "ANY": "Todos",
          "ENABLE_ACL_ADMIN": "Habilitar ACL",
          "FTP": "FTP",
          "HTTP": "HTTP",
          "HTTPS": "HTTPS",
          "IP_END_ADDR": "IP (fim)",
          "IP_START_ADDR": "IP (início)",
          "PING": "Ping",
          "SERVICES": "Serviços",
          "SSH": "SSH",
          "TELNET": "Telnet",
          "TFTP": "TFTP"
        },
        "message": {
          "error": {
            "BOTH_IPS_OF_RANGE_CANNOT_BE_EQUALS_THE_DEVICE_IP": "Ambos IPs são iguais o endereço do aparelho",
            "CONFLIT_WITH_OTHER_RULE": "Já existe uma regra nesse intervalo para esta interface.",
            "ERROR_ON_SERVER": "Erro ao salvar ACL:",
            "INVALID_IP": "IP inválido no intervalo",
            "INVALID_RANGE": "O fim deve ser maior ou igual ao início",
            "IP_ADDRESSES_ON_RANGE_MUST_BE_IN_THE_SAME_NETWORK_OF_LAN": "Os IPs do intervalo devem estar na rede LAN",
            "NO_VALID_LAN_RULE": "não existe uma regra de acesso LAN (habilite o HTTP ou todas as regras)",
            "LAN_DEFAULT_RULE_MISSING": "deve haver uma regra acessível à LAN para habilitar o ACL",
            "MAX_RULES_NUM": "Número máximo de regras atingidas"
          },
          "IPV6_DISABLED": "O recurso de Configurações de ACL IPv6 se encontra desabilitado pois o IPv6 não está ativado."
        },
        "title": {
          "ACL_ADMIN_CAPABILITY": "Habilitar/Desabilitar ACL",
          "ACL_CONFIG_CREATE": "Criar regra ACL",
          "ACL_CONFIG_LIST": "Lista de regras ACL",
          "SERVICE_LIST": "Lista de serviços habilitados",
          "IP_RANGE": "Intervalo de endereços IP",
          "ACL_CONFIG_IPV6": "Configurações de ACL IPv6"
        },
        "info": {
          "NO_RULES": "Não há regras de ACL IPv6 adicionadas a lista"
        }
      },
      "backup_restore": {
        "error": {
          "COULD_NOT_CREATE_BACKUP_FILE": "Não foi possível criar o arquivo de backup.",
          "COULD_NOT_RESTORE": "Arquivo recusado, verificar se é um arquivo de backup válido.",
          "INVALID_FILE": "Arquivo inválido",
          "TRY_AGAIN_OR_REACH_SUPPORT_TEAM": "Tente novamente ou entre em contato com nosso suporte."
        },
        "info": {
          "BACKUP_GENERATION_SUCCESS": "O arquivo de backup foi gerado com sucesso.",
          "CREATE_FILE": "Criar arquivo",
          "PLEASE_WAIT": "Por favor aguarde, enquanto criamos o arquivo de backup.",
          "RESTORE_BACKUP": "Restaurar configurações",
          "RESTORE_BACKUP_SUCCESS": "A restauração do backup terminou.",
          "DEVICE_WILL_REBOOT": "O dispositivo será reiniciado.",
          "SELECT_FILE": "Selecione o arquivo",
          "UPDATING": "Restaurando",
          "CUSTOM_CONFIG_ENABLE": "Habilitar configurações customizadas",
          "CUSTOM_CONFIG_SAVE": "Salvar e atualizar arquivo",
          "CUSTOM_CONFIG": "Ao ativar essa função, a configuração atual será a configuração padrão do dispositivo, prevenindo o dispositivo de perder as configurações atuais em caso de restauração.",
          "FACTORY_DEFAULT_CONFIRM": "Atenção, o tempo em que o botão reset deverá ser mantido pressionado para realizar a restauração da configuração será alterado para",
          "FACTORY_DEFAULT_CONFIRM_SECONDS": "segundos, deseja continuar?",
          "FACTORY_DEFAULT_INFO": "Defina o tempo, em segundos, em que o botão reset deverá ser pressionado para realizar a restauração das configuração",
          "FACTORY_DEFAULT_RANGE": "O valor precisa estar entre 5 e 300 segundos"
        },
        "title": {
          "CUSTOM_CONFIG": "Alterar configurações padrão do sistema para configurações atuais",
          "CREATE_BACKUP_FILE": "Criar arquivo de Backup das configurações atuais",
          "RESTORE_BACKUP": "Restaurar configurações a partir de um arquivo de Backup",
          "FACTORY_DEFAULT": "Atraso no Botão Reset"
        },
        "warning": {
          "MUST_REBOOT_TO_APPLY_SETTINGS": "É necessário reiniciar o roteador para aplicar as modificações.",
          "DO_NOT_SHUTDOWN_THE_DEVICE": "Não desligue o aparelho durante a restauração!",
          "IMPORTANT": "Importante",
          "RESTORE_ON_GOING": "Por favor aguarde, restauração em andamento."
        }
      },
      "change_to_firmware_version": {
        "label": {
          "ACTION_CAN_BE_UNDO": "O usuário poderá, a qualquer momento, retornar para esta versão de firmware.",
          "BACKUP_VERSION": "Versão de firmware de backup",
          "CONFIRMATION_TO_RESET": "Tem certeza que deseja alternar para versão de firmware {{version}}?",
          "CURRENT_VERSION": "Versão de firmware atual",
          "DO_RESTORE": "Sim, quero substituir",
          "DONT_RESTORE": "Não, quero manter",
          "EXPLAINING": "Ao alternar entre as versões de firmware do roteador, todas as configurações realizadas serão mantidas em caso de compatibilidade.",
          "SWITCH_TO_BACKUP": "Alternar versões",
          "UNDERSTOOD": "Entendi"
        },
        "message": {
          "error": {
            "ERROR_ON_SERVER": "Erro tentar alternar para versão de backup."
          }
        },
        "title": {
          "CHANGE_TO_FIRMWARE_VERSION_DESCRIPTION": "Substituir versão de firmware",
          "CHANGING": "Alternando o firmware"
        },
        "warning": {
          "CHANGED": "Firmware alternado!",
          "RECONNECT": "Pode ser necessário conectar novamente à rede do seu roteador.",
          "WAIT_A_FEW_SECONDS": "Aguarde alguns instantes enquanto o firmware do seu roteador é alternado."
        }
      },
      "datetime": {
        "label": {
          "CURRENT_TIME": "Data/Hora",
          "DAYLIGHT_SAVING_ENABLED": "Horário de verão",
          "NTP_SERVER_AUTOMATIC": "Obter servidores NTP automáticamente",
          "NTP_SERVER_ENABLED": "Servidor NTP ativado",
          "NTP_SERVER_NTP_SERVER_1": "Servidor NTP 1",
          "NTP_SERVER_NTP_SERVER_2": "Servidor NTP 2",
          "SYNCED": "Sincronizado",
          "TIMEZONE": "Fuso horário"
        },
        "title": {
          "DATETIME_SETTINGS": "Configurações de data/hora (formato 24h)"
        },
        "warning": {
          "SYNCED_INFO": "Indica se roteador está sincronizado com o horário da internet.",
          "CONFIRMATION_MSG": "Ao confirmar, o recurso Manutenção automática será desabilitado.",
          "CONFIRM_QUESTION": "Deseja confirmar?"
        },
        "error": {
          "INVALID_NTP_SERVER": "Um ou mais servidores NTP Inválidos"
        }
      },
      "factory_reset": {
        "label": {
          "DO_RESTORE": "Sim, quero restaurar",
          "DONT_RESTORE": "Não, quero manter",
          "UNDERSTOOD": "Entendi"
        },
        "title": {
          "RESTORE_SYSTEM": "Restauração do sistema",
          "RESTORE_SYSTEM_BRIEF": "Restaure as configurações do seu roteador ao padrão de fábrica.",
          "RESTORING": "Restaurando"
        },
        "warning": {
          "ACTION_CANNOT_BE_UNDONE": "Esta ação não pode ser desfeita.",
          "CONFIRMATION_TO_RESET": "Tem certeza que deseja restaurar o roteador?",
          "EXPLAINING_FACTORY_RESET": "Ao restaurar o seu roteador, todas as configurações realizadas serão retornadas ao padrão de fábrica.",
          "RECONNECT": "Caso você esteja realizando este procedimento via Wi-Fi, conecte-se à rede INTELBRAS.",
          "RESTORED": "Seu roteador foi restaurado",
          "WAIT_A_FEW_SECONDS": "Aguarde alguns instantes enquanto seu roteador é restaurado ao padrão de fábrica."
        }
      },
      "leds": {
        "label": {
          "ENABLE": "Habilitar LEDs"
        },
        "message": {
          "error": {
            "ERROR_ON_SERVER": "Erro ao salvar configurações de LEDs"
          }
        },
        "title": {
          "LEDS": "LEDs",
          "ENABLE": "Habilitar/Desabilitar LEDs"
        }
      },
      "reboot": {
        "button": {
          "REBOOT": "Reiniciar"
        },
        "info": {
          "REBOOT_SUCCESSFUL": "O seu roteador foi reiniciado. Se você estava conectado via Wi-Fi , reconecte-se à rede."
        },
        "warning": {
          "MAY_HAVE_TO_RECONNECT": "Pode ser necessário conectar novamente à rede do seu roteador.",
          "MUST_REBOOT": "Reinicialização necessária",
          "MUST_REBOOT_TO_APPLY_SETTINGS": "Seu aparelho necessita ser reiniciado para que todas as alterações tenham efeito.",
          "PRESS_BELOW_BUTTON_TO_REBOOT": "Pressione o botão abaixo para reiniciar o seu roteador imediatamente.",
          "REBOOT_YOUR_SYSTEM": "Reinicie seu sistema",
          "REBOOTING": "Reiniciando. Aguarde.",
          "WANT_TO_REBOOT_NOW": "Deseja reiniciar seu aparelho agora?"
        }
      },
      "title": {
        "SYSTEM": "Sistema"
      },
      "tr069_config": {
        "label": {
          "ACS_INFORM": "Ativar informação periódica",
          "ACS_INTERVAL": "Intervalo de informação periódica",
          "CONNECTION_ENABLE": "Habilitar autenticação",
          "CONNECTION_PATH": "Caminho",
          "CONNECTION_PORT": "Porta",
          "TR069_ENABLE": "Habilitar TR-069"
        },
        "message": {
          "error": {
            "MUST_START_WITH_PROTOCOL": "A URL deve começar com http:// ou https://",
            "SERVER_ERROR": "Houve um erro ao tentar realizar as configurações de TR-069: "
          }
        },
        "title": {
          "TR069_ACS_CONFIG": "Configurações de ACS",
          "TR069_CONNECTION_CONFIG": "Configurações de Conexão",
          "TR069_ENABLE": "Habilitar/Desabilitar TR-069"
        }
      },
      "upgrade": {
        "error": {
          "COULD_NOT_UPDATE": "A atualização de firmware não pode ser realizada.",
          "INVALID_FILE": "Arquivo inválido",
          "TRY_AGAIN_OR_REACH_SUPPORT": "Tente novamente ou entre em contato com nosso suporte."
        },
        "info": {
          "FIRMWARE_UPDATED": "O firmware foi atualizado com sucesso."
        },
        "label": {
          "CURRENT_VERSION": "Versão atual:",
          "FIRMWARE_VERSION": "Versão do firmware:",
          "MODEL": "Modelo:",
          "SELECT_FILE": "Selecione o arquivo",
          "UPGRADE": "Atualizar"
        },
        "title": {
          "SELECT_FILE": "Selecione um arquivo de firmware para atualizar seu roteador.",
          "UPGRADE_FROM_FILE": "Atualizar a partir de um arquivo",
          "UPGRADING": "Atualizando"
        },
        "warning": {
          "DO_NOT_TURN_OFF": "Não desligue o roteador durante a atualização!",
          "IMPORTANTING": "Importante:",
          "UPGRADING": "Por favor aguarde, a atualização de firmware está em andamento."
        }
      },
      "users": {
        "label": {
          "DEFINE_THE_CREDENTIALS": "Defina as credenciais para acesso às configurações do roteador",
          "PASSWORDS_DO_NOT_MATCH": "As senhas não coincidem",
          "PASSWORDS_EQUALS_TO_PREVIOUS": "A nova senha não pode ser igual a senha atual",
          "UPDATE_CREDENTIALS": "Atualizar credenciais",
          "ENABLE_LIMITED_USER": "Habilitar usuário limitado"
        },
        "message": {
          "warning": {
            "INVALID_PASSWORD": "Sua senha deve conter: ao menos um caractere maiúsculo; Ao menos um caractere minúsculo; Ao menos um caractere especial;"
          },
          "error": {
            "INVALID_PASSWORD": "Senha pouco segura"
          }
        }
      },
      "automatic_maintenance": {
        "label": {
          "HOUR": "Hora (formato 24h)",
          "MIN": "Minutos",
          "CONFIG_TIME": "Configurar horário para manutenção"
        },
        "warning": {
          "CONFIRMATION_MSG": "Ao confirmar, o cliente STNP será habilitado no recurso de Fuso horário. Além disso, o dispositivo precisa estar conectado à internet para o correto funcionamento.",
          "CONFIRM_QUESTION": "Deseja confirmar?"
        }
      }
    },
    "tools": {
      "ping": {
        "label": {
          "ALL_INTERFACES": "Qualquer",
          "EXECUTION_TIME_IN_SECONDS": "Tempo máximo de execução (segundos)",
          "INTERFACE": "Interface",
          "IP_HOST_ADDRESS": "Endereço de IP/Host",
          "LOST": "Perdidos",
          "MAX_HOPS_NUMBER": "Número máximo de saltos",
          "MAXIMUM": "Máximo",
          "MEAN": "Média",
          "MINIMUM": "Mínimo",
          "NUMBER_OF_PACKAGES": "Número de pacotes",
          "PACKAGE_SIZE": "Tamanho do pacote",
          "PACKAGES": "Pacotes",
          "PING_SETTINGS": "Configuração de ping",
          "RECEIVED": "Recebidos",
          "RTT": "RTT",
          "SENT": "Enviados",
          "START": "Iniciar"
        },
        "message": {
          "error": {
            "PING_ERROR": "Houve um erro ao requisitar o ping: "
          }
        },
        "title": {
          "ADVANCED": "Avançado",
          "PING": "Ping"
        }
      },
      "tr069_inform": {
        "label": {
          "CAUSE": "Causa",
          "STATUS": "Status"
        },
        "message": {
          "error": {
            "TR069_INFORM_ERROR": "Não foi possível recuperar as informações de TR-069"
          },
          "causes": {
            "NO_INFORM": "Inform inativo",
            "NO_RESPONSE": "Sem resposta",
            "INFORM_BREAK": "Inform interrompido",
            "INFORM_SUCCESS": "Sucesso",
            "INFORM_AUTH_FAIL": "Falha na autenticação",
            "INFORMING": "Reportando",
            "UNKNOWN": "Causa desconhecida"
          },
          "ENABLE_TR069": "Habilite o TR-069 em  ",
          "CONFIGS_TR069": "Configurações de TR-069",
          "TO_ENABLE_INFORM": " para habilitar o Inform."
        },
        "title": {
          "TR069_INFORM": "Informação de TR-069",
          "STOP_INFORM": "Parar informe",
          "START_INFORM": "Iniciar informe"
        }
      },
      "traceroute": {
        "label": {
          "ALL_HOPS": "Lista de saltos",
          "ALL_INTERFACES": "Qualquer",
          "FIRST_TTL": "Primeiro TTL",
          "HOPS": "Saltos",
          "INTERFACE": "Interface",
          "IP_ADDRESS": "Endereço de IP",
          "IP_HOST_ADDRESS": "Endereço de IP/Host",
          "LATENCY": "Latência {{index}} (ms)",
          "TRACEROUTE_SETTINGS": "Configuração de traceroute "
        },
        "message": {
          "error": {
            "TRACEROUTE_ERROR": "Houve um erro ao requisitar o traceroute: ",
            "TRACEROUTE_INVALID_IP": "O endereço IP é inválido para o protocolo selecionado"
          }
        },
        "title": {
          "TRACEROUTE": "Traceroute"
        }
      },
      "loop_back_detection": {
        "label": {
          "ADD_VLAN_ID": "Adicionar novo VLAN ID",
          "DETECTION_INTERVAL": "Intervalo de Detecção (em segundos)",
          "ENABLE_LOOP_DETECTION": "Ativar Detecção de Loops",
          "FRAME_TYPE": "Tipo do quadro",
          "RECOVERY_INTERVAL": "Intervalo de recuperação (em segundos)",
          "VLAN_ID": "VLAN ID",
          "NO_TAG": "Sem Tag",
          "CONFIG_TABLE": "Tabela de tags VLAN configuradas",
          "ADD_VLAN": "Adicionar nova tag VLAN",
          "PORT": "Porta",
          "DETECTED": "Detectado",
          "NOT_DETECTED": "Não detectado"
        },
        "title": {
          "LOOP_BACK_DETECT": "Configurações de detecção de loop",
          "LOOP_BACK_DETECT_STATUS": "Status de detecção de loop",
          "INTERVAL_FRAME_CONFIG": "Configurações de intervalos e quatro",
          "VLAN_LIST": "Lista de VLANs com Detecção de Loop Ativada"
        },
        "info": {
          "NO_VLANS": "Não há VLANs adicionadas a lista",
          "VLAN_WARN": "Valor \"0\" representa a não existência de tag VLAN.",
          "SAVE_WARN": "Ao adicionar uma nova tag VLAN, é necessário salvar as configurações para que esta seja aplicada.",
          "DISABLE_LAN_WARN": "A porta LAN é desabilitada em casos de detecção de loop."
        },
        "error": {
          "LOOP_BACK_DETECT_REQUIRE_VLAN": "Ao menos uma VLAN deve ser adicionada quando a detecção de loop é ativada.",
          "RETRIEVE_LBD_STATUS_FAILED": "Houve uma falha na recuperação do status da detecção de loop"
        }
      }
    },
    "validators": {
      "ID_ALREADY_EXISTS": "Esse id já existe",
      "MAX_NUMBER_OF_CHARACTERS": "Máximo de {{max}} caracteres",
      "MAX_NUMBER_OF_ELEMENTS": "Limite máximo de elementos é {{max}}"
    },
    "voip": {
      "advanced_sip": {
        "label": {
          "CALL_TRANSFER": "Transferência de ligação",
          "CALL_WAITING_CALL_WAITING_ID": "Chamada em espera",
          "CALL_WAITING_HAS_CALL_WAITING": "Possui chamada em espera",
          "CONFERENCE_ON_SERVER_OR_CPE": "Conferência em servidor ou CPE",
          "CONFERENCE_URI": "URI de conferência",
          "DTMF_DTMF_AS_FAXMODEM": "DTMF como fax/modem",
          "DTMF_DTMF_PACKET_INTERVAL": "Intervalo de pacote DTMF",
          "DTMF_DTMF_PAYLOAD": "Payload DTMF",
          "DTMF_DTMF_RELAY": "Retransmissão DTMF",
          "DTMF_FAXMODEM_PAYLOAD": "Payload fax/modem",
          "DTMF_PACKET_INTERVAL": "Intervalo de pacotes DTMF",
          "DTMF_SIP_INFO_DURATION": "Duração SIP INFO",
          "FAXMODEM_PACKET_INTERVAL": "Intervalo de pacotes fax/modem",
          "HIDE_CALL_ID": "Esconder ligação",
          "HOOK_FLASH_RELAY": "Hook Flash Relay",
          "MEDIA_PORT": "Media port",
          "MIC_AGC_ENABLED": "Microfone Agc ativado",
          "MIC_AGC_MAX_GAIN_UP": "Ganho máximo microfone",
          "PORT": "Porta",
          "REJECT_IP_CALL": "Rejeitar chamadas IP",
          "RX_GAIN": "Ganho RX",
          "SIP_PORT": "Porta SIP",
          "SPEAKER_AGC_ENABLED": "Auto-falante Agc ativado",
          "SPEAKER_AGC_MAX_GAIN_UP": "Ganho máximo auto-falante",
          "THREE_WAY_CONFERENCE": "Conferência de três vias",
          "TX_GAIN": "Ganho TX",
          "SIP_PRACK": "Habilitar SIP Prack"
        },
        "title": {
          "VOIP_ADVANCED_SIP": "Configurações Avançadas de SIP"
        }
      },
      "codec": {
        "label": {
          "AVAILABLE_CODECS": "Codecs disponíveis",
          "CODECS": "Codecs",
          "ENABLED_CODECS": "Codecs habilitados",
          "OPTION_BIT_RATE": "Taxa de bit",
          "OPTION_PACKING_ORDER": "Ordem do pacote",
          "PACKETIZATION": "Amostragem",
          "PORT": "Porta",
          "PRECEDENCE": "Precedência",
          "RTP_REDUNDANT_CODEC": "RTP redundante codec",
          "RTP_REDUNDANT_PAYLOAD_TYPE": "RTP redundante tipo de payload",
          "DISABLE_RTP_REDUNTANT_CODEC": "Desabilitar",
          "BIT_RATE_LEFT": "Esquerda",
          "BIT_RATE_RIGHT": "Direita"
        },
        "message": {
          "error": {
            "MUST_CHOOSE_AT_LEAST_ONE_CODEC": "Escolha ao menos um codec"
          }
        },
        "title": {
          "CODEC": "Codec"
        }
      },
      "dial_plan": {
        "label": {
          "DIAL_PLAN": "Plano de Discagem",
          "DIGITMAP_ENABLE": "Ativar Plano de Discagem",
          "PORT": "Porta"
        },
        "title": {
          "DIAL_PLAN": "Plano de Discagem",
          "CONFIGURE_DIAL_PLAN": "Configuração de plano de Discagem"
        }
      },
      "history_call": {
        "label": {
          "DATE_HOUR": "Data/Hora",
          "DESTINY": "Destino",
          "DIRECTION": "Direção",
          "DURATION": "Duração",
          "PORT": "Porta",
          "SOURCE": "Origem",
          "direction": {
            "INCOMING_CALL": "Chamada recebida",
            "OUTGOING_CALL": "Chamada de saída"
          },
          "status": {
            "COMPLETED": "Concluída",
            "ABORTED": "Cancelada"
          }
        },
        "messages": {
          "NO_HISTORY_CALL": "Não há histórico de chamadas."
        },
        "title": {
          "HISTORY_CALL": "Histórico de chamadas"
        }
      },
      "proxy": {
        "label": {
          "DEFAULT_PROXY": "Proxy Padrão",
          "ENABLED": "Ativo",
          "LOGIN_ID": "Login ID",
          "NAME": "Nome",
          "NUMBER": "Número",
          "OUTBOUND_PROXY_ADDR": "Endereço proxy de saída",
          "OUTBOUND_PROXY_ENABLE": "Habilitar proxy de saída",
          "OUTBOUND_PROXY_PORT": "Porta proxy de saída",
          "PASSWORD": "Senha",
          "PORT": "Porta",
          "PROXY_ADDR": "Endereço",
          "REG_EXPIRE": "Expirar",
          "REGISTER_STATUS": "Status do registro",
          "register_status": {
            "ERROR": "Erro ao ler status do registro",
            "NOT_REGISTERED": "Não registrado",
            "REGISTER_FAIL": "Falha no registro",
            "REGISTERED": "Registrado",
            "REGISTERING": "Registrando",
            "VOIP_RESTART": "VoIP reiniciando"
          },
          "SESSION_TIME_ENABLE": "Habilitar tempo de sessão",
          "SESSION_UPDATE_TIMER": "Temporizador de atualização de sessão",
          "SIP_DOMAIN": "Domínio SIP",
          "SIP_SUBSCRIBE": "Registrar SIP"
        },
        "title": {
          "CONNECTION_INFO": "Informações de conexão",
          "OUTBOUND_PROXY": "Proxy de saída",
          "ACCOUNT_01": "Conta 1",
          "ACCOUNT_CONFIG": "Configuração de contas",
          "GENERAL_CONFIG": "Configurações Gerais",
          "SESSION_INFORMATION": "Informações de sessão",
          "SIP_INFO": "Informações SIP"
        }
      }
    }
  }
}
import React, { useState, useEffect, useContext } from 'react'

import Input from '../../../../components/input/input'
import Select from '../../../../components/select/select'

import { useParams, useHistory } from 'react-router-dom'
import { BackendContext } from '../../../../backend/backend'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import waitRouter from '../../../utils/wait-router'
import { GlobalsContext } from '../../../globals-context'
import Form, { FormContext } from '../../../../components/form/form'
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next'
import extraValidators from '../../../common/validators'
import Button from '../../../../components/button/button'
import { saveQosClassification, fetchQosClassificationList, fetchQosClassification } from '../../../resources/advanced-qos-classification'
import { AdvancedContext } from '../advanced-context';

import {
    qosClassificationConstants,
    TypeOfRuleEnumeration,
    QoSClassificationStruct,
    IPVersionEnumeration,
    IPProtocolEnumeration,
    createQueueOptions,
    createDSCPOptions,
    createIEEE_802_1pOptions,
    createTypeOfRuleOptions,
    createPhysicalPortOptions,
    createIPProtocolOptions,
    createIPVersionOptions,
} from "./qos-classification-common"

export default function QoSClassificationEdit({ isWizard, isFormSegment, formSegment }) {
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [dataFetched, setDataFetched] = useState(false)

    const [QoSClassification, setQoSClassification] = useState(QoSClassificationStruct)

    const params = useParams()
    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const { qosClassificationElementsList, setQosClassificationElementsList } = useContext(AdvancedContext)
    const history = useHistory()
    const { t } = useTranslation()

    const QueueOptions = createQueueOptions(t)
    const DSCPOptions = createDSCPOptions(t)
    const IEEE_802_1pOptions = createIEEE_802_1pOptions(t)
    const TypeOfRuleOptions = createTypeOfRuleOptions(t)
    const PhysicalPortOptions = createPhysicalPortOptions(t)
    const IPProtocolOptions = createIPProtocolOptions(t)
    const IPVersionOptions = createIPVersionOptions(t)

    useEffect(() => {
        const fetchData = async () => {
            if (qosClassificationElementsList === null)
                await fetchQosClassificationList(backend, setQosClassificationElementsList)

            setDataFetched(true)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (dataFetched === true) {
            if (params.id !== 'add') {
                if (qosClassificationElementsList !== null && qosClassificationElementsList.length > 0) {
                    setQoSClassification(qosClassificationElementsList.filter(element => element.id === params.id)[0])
                }
                else {
                    fetchQosClassification(backend, params.id, setQoSClassification)
                }
            }
        }
        // eslint-disable-next-line
    }, [dataFetched, qosClassificationElementsList])


    const submitConfirm = async () => {
        if (saving || success || error) return
        submit()
    }

    const submit = async () => {
        if (params.id === 'add')
            await create()
        else
            await save()

        await backend.backendApply()
    }

    const goBackToList = () => {
        history.push(`/advanced/qos-classification`)
    }

    function ip4ConfigIsValid(ip4, mask) {
        if (mask !== "") {
            if (ip4 === "" || ip4 === "0.0.0.0") {
                return false
            }
        }

        return true
    }

    function ip6ConfigIsValid(ip6, prefix) {
        if (prefix !== "") {
            if (ip6 === "") {
                return false
            }
        }

        return true
    }

    function resetValues() {
        /* reset value */
        if (QoSClassification.ip_protocol.ip_config_src.ip4_mask === "0.0.0.0")
            QoSClassification.ip_protocol.ip_config_src.ip4_mask = "";

        if (QoSClassification.ip_protocol.ip_config_dst.ip4_mask === "0.0.0.0")
            QoSClassification.ip_protocol.ip_config_dst.ip4_mask = "";

        if (QoSClassification.mode === TypeOfRuleEnumeration.IP_PROTO &&
            QoSClassification.ip_protocol.ip_version !== IPVersionEnumeration.IPVERSION_IPV4) {

            QoSClassification.ip_protocol.ip_config_src.ip4_mask = "";
            QoSClassification.ip_protocol.ip_config_dst.ip4_mask = "";
        }

        if (QoSClassification.mode === TypeOfRuleEnumeration.IP_PROTO) {
            if (QoSClassification.ip_protocol.dscp_pattern === 0)
                QoSClassification.ip_protocol.dscp_pattern = 1

            if (QoSClassification.ip_protocol.protocol === IPProtocolEnumeration.PROTOCOL_NONE || QoSClassification.ip_protocol.protocol === IPProtocolEnumeration.PROTOCOL_ICMP) {
                QoSClassification.ip_protocol.src_port.start = 0;
                QoSClassification.ip_protocol.src_port.end = 0;
                QoSClassification.ip_protocol.dst_port.start = 0;
                QoSClassification.ip_protocol.dst_port.end = 0;
            }
        }
    }

    const create = async () => {

        if (saving) return

        setSaving(true)

        resetValues()

        /* validators */
        if (QoSClassification.mode === TypeOfRuleEnumeration.IP_PROTO) {
            if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4) {
                if (!ip4ConfigIsValid(QoSClassification.ip_protocol.ip_config_src.ip4, QoSClassification.ip_protocol.ip_config_src.ip4_mask)) {
                    setErrorMsg(t('advanced.qos_classification.error.SRC_IP_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }

                if (!ip4ConfigIsValid(QoSClassification.ip_protocol.ip_config_dst.ip4, QoSClassification.ip_protocol.ip_config_dst.ip4_mask)) {
                    setErrorMsg(t('advanced.qos_classification.error.DST_IP_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }
            }

            if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV6) {
                if (!ip6ConfigIsValid(QoSClassification.ip_protocol.ip_config_src.ip6, QoSClassification.ip_protocol.ip_config_src.ip6_prefix_length)) {
                    setErrorMsg(t('advanced.qos_classification.error.SRC_IP6_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }

                if (!ip6ConfigIsValid(QoSClassification.ip_protocol.ip_config_dst.ip6, QoSClassification.ip_protocol.ip_config_dst.ip6_prefix_length)) {
                    setErrorMsg(t('advanced.qos_classification.error.DST_IP6_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }
            }
        }

        let status = await saveQosClassification(backend, QoSClassification, setErrorMsg)

        if (status === false) {
            setSaving(false)
            setSuccess(false)
            setError(true)
            return
        }

        await waitReturn()
    }

    const save = async () => {

        if (saving) return

        setSaving(true)

        resetValues()

        /* validators */
        if (QoSClassification.mode === TypeOfRuleEnumeration.IP_PROTO) {
            if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4) {
                if (!ip4ConfigIsValid(QoSClassification.ip_protocol.ip_config_src.ip4, QoSClassification.ip_protocol.ip_config_src.ip4_mask)) {
                    setErrorMsg(t('advanced.qos_classification.error.SRC_IP_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }

                if (!ip4ConfigIsValid(QoSClassification.ip_protocol.ip_config_dst.ip4, QoSClassification.ip_protocol.ip_config_dst.ip4_mask)) {
                    setErrorMsg(t('advanced.qos_classification.error.DST_IP_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }
            }

            if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV6) {
                if (!ip6ConfigIsValid(QoSClassification.ip_protocol.ip_config_src.ip6, QoSClassification.ip_protocol.ip_config_src.ip6_prefix_length)) {
                    setErrorMsg(t('advanced.qos_classification.error.SRC_IP6_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }

                if (!ip6ConfigIsValid(QoSClassification.ip_protocol.ip_config_dst.ip6, QoSClassification.ip_protocol.ip_config_dst.ip6_prefix_length)) {
                    setErrorMsg(t('advanced.qos_classification.error.DST_IP6_NO_SET_WHEN'))
                    setSaving(false)
                    setSuccess(false)
                    setError(true)
                    return
                }
            }
        }

        delete QoSClassification['order'];

        let status = await saveQosClassification(backend, QoSClassification, setErrorMsg)

        if (status === false) {
            setSaving(false)
            setSuccess(false)
            setError(true)
            return
        }

        await waitReturn()
    }

    const waitReturn = async () => {

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        if (error === false)
            goBackToList()

        setSaving(false)
    }

    const dismissModals = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'the max number of rules is 16')
            return t('advanced.qos_classification.error.MAX_NUM_OF_RULES')
        else if (errorMsg === 'invalid destination ipv4 mask')
            return t('advanced.qos_classification.error.INVALID_DST_IPV4_MASK')
        else if (errorMsg === 'invalid source ipv4 mask')
            return t('advanced.qos_classification.error.INVALID_SRC_IPV4_MASK')
        else if (errorMsg === 'you need to activate the respective queue in qos_policy')
            return t('advanced.qos_classification.error.QUEUE_DISABLED')
        else
            return errorMsg
    }

    const validateEthType = value => {
        let get_value = parseInt(value.slice(2), 16)

        if (value !== 0 && (value.length < 5 || value.length > 6 || get_value < parseInt("800", 16)))
            return t('advanced.qos_classification.error.INVALID_ETHTYPE');

        return ''
    }

    const QoSClassificationPortForm = () => {
        return <React.Fragment>
            <div className='qos_classification_d_flex'>
                <Select
                    id='qos_classification_physical_ports'
                    name='qos_classification_physical_ports'
                    label={t('advanced.qos_classification.label.PHYSICAL_PORT')}
                    options={PhysicalPortOptions}
                    value={QoSClassification.port.physical_port}
                    onChange={(e) => {
                        let new_val = { ...QoSClassification, port: { ...QoSClassification.port, physical_port: Number(e.target.value) } };
                        setQoSClassification(new_val)
                    }}
                ></Select>

                <Select
                    id='qos_classification_dscp_pattern'
                    name='qos_classification_dscp_pattern'
                    label={t('advanced.qos_classification.label.DSCP_PATTERN')}
                    options={DSCPOptions}
                    value={QoSClassification.port.dscp_pattern}
                    onChange={(e) => {
                        let new_val = { ...QoSClassification, port: { ...QoSClassification.port, dscp_pattern: Number(e.target.value) } };
                        setQoSClassification(new_val)
                    }}
                ></Select>
            </div>
        </React.Fragment>
    }

    const QoSClassificationEtherTypeForm = () => {
        return <React.Fragment>
            <Input
                id='qos_classification_ether_type'
                name='qos_classification_ether_type'
                label={t('advanced.qos_classification.label.ETHERNET_TYPE')}
                type='text'
                placeholder={t('advanced.qos_classification.label.VALUE_IN_HEX')}
                value={`0x${QoSClassification.ethertype.value.toString(16).toUpperCase()}`}
                onChange={(e) => {
                    let value = 0;

                    if (!isNaN(parseInt(e.target.value, 16)))
                        value = parseInt(e.target.value, 16)

                    let new_val = { ...QoSClassification, ethertype: { ...QoSClassification.ethertype, value: value } };
                    setQoSClassification(new_val)
                }}
                disabled={false}
                validators={[
                    extraValidators.required,
                    validateEthType,
                    { fn: extraValidators.isHex }
                ]}
            ></Input>
        </React.Fragment>
    }

    const QoSClassificationIPProtoForm = () => {
        if (QoSClassification.ip_protocol.ip_version < IPVersionEnumeration.IPVERSION_IPV4 ||
            QoSClassification.ip_protocol.ip_version > IPVersionEnumeration.IPVERSION_BOTH) {
            QoSClassification.ip_protocol.ip_version = IPVersionEnumeration.IPVERSION_IPV4;
        }

        return (
            <div>
                <Select
                    id='qos_classification_ip_rule_ip_version'
                    name='qos_classification_ip_rule_ip_version'
                    label={t('advanced.qos_classification.label.IP_VERSION')}
                    options={IPVersionOptions}
                    value={QoSClassification.ip_protocol.ip_version}
                    onChange={(e) => {
                        let new_val = { ...QoSClassification, ip_protocol: { ...QoSClassification.ip_protocol, ip_version: Number(e.target.value) } };
                        setQoSClassification(new_val)
                    }}
                ></Select>

                <div className='qos_classification_d_flex'>
                    <Select
                        id='qos_classification_ip_rule_ip_protocol'
                        name='qos_classification_ip_rule_ip_protocol'
                        label={t('common.label.PROTOCOL')}
                        options={IPProtocolOptions}
                        value={QoSClassification.ip_protocol.protocol}
                        onChange={(e) => {
                            let new_val = { ...QoSClassification, ip_protocol: { ...QoSClassification.ip_protocol, protocol: Number(e.target.value) } };
                            setQoSClassification(new_val)
                        }}
                    ></Select>

                    <Select
                        id='qos_classification_ip_rule_dscp_pattern'
                        name='qos_classification_ip_rule_dscp_pattern'
                        label={t('advanced.qos_classification.label.DSCP_PATTERN')}
                        options={DSCPOptions}
                        value={QoSClassification.ip_protocol.dscp_pattern}
                        onChange={(e) => {
                            let new_val = { ...QoSClassification, ip_protocol: { ...QoSClassification.ip_protocol, dscp_pattern: Number(e.target.value) } };
                            setQoSClassification(new_val)
                        }}
                    ></Select>
                </div>

                {QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_BOTH ? null :
                    <div className='qos_classification_d_flex'>
                        <Input
                            id='qos_classification_ip_rule_source_ip'
                            name='qos_classification_ip_rule_source_ip'
                            label={t('advanced.qos_classification.label.SOURCE_IP')}
                            type='text'
                            placeholder={t('advanced.qos_classification.label.SOURCE_IP')}
                            value={
                                QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ?
                                    ((QoSClassification.ip_protocol.ip_config_src.ip4 === '0.0.0.0') ? '' : QoSClassification.ip_protocol.ip_config_src.ip4) :
                                    QoSClassification.ip_protocol.ip_config_src.ip6
                            }
                            onChange={(e) => {
                                if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4) {
                                    let new_val = {
                                        ...QoSClassification, ip_protocol: {
                                            ...QoSClassification.ip_protocol, ip_config_src: {
                                                ...QoSClassification.ip_protocol.ip_config_src, ip4: e.target.value
                                            }
                                        }
                                    };
                                    setQoSClassification(new_val)
                                }
                                else {
                                    let new_val = {
                                        ...QoSClassification, ip_protocol: {
                                            ...QoSClassification.ip_protocol, ip_config_src: {
                                                ...QoSClassification.ip_protocol.ip_config_src, ip6: e.target.value
                                            }
                                        }
                                    };
                                    setQoSClassification(new_val)
                                }
                            }}
                            disabled={false}
                            validators={[
                                {fn: extraValidators.optionalValidators, params: {
                                    shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4,
                                    validators: [
                                        {fn: extraValidators.validateIPv4, params: ''},
                                        {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                    ]
                                }}, 
                                {fn: extraValidators.optionalValidators, params: {
                                    shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV6,
                                    validators: [
                                        {fn: extraValidators.validateIPv6, params: ''},
                                        {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                    ]
                                }}, 
                            ]}
                        ></Input>

                        <Input
                            id='qos_classification_ip_rule_source_mask'
                            name='qos_classification_ip_rule_source_mask'
                            label={QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ? t('advanced.qos_classification.label.SOURCE_MASK') : t('advanced.qos_classification.label.SOURCE_PREFIX')}
                            type={QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ? "text" : "number"}
                            placeholder={QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ? t('advanced.qos_classification.label.SOURCE_MASK') : t('advanced.qos_classification.label.SOURCE_PREFIX')}
                            value={
                                QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ?
                                    ((QoSClassification.ip_protocol.ip_config_src.ip4_mask === '0.0.0.0') ? '' : QoSClassification.ip_protocol.ip_config_src.ip4_mask) :
                                    QoSClassification.ip_protocol.ip_config_src.ip6_prefix_length
                            }
                            onChange={(e) => {
                                if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4) {
                                    let new_val = {
                                        ...QoSClassification, ip_protocol: {
                                            ...QoSClassification.ip_protocol, ip_config_src: {
                                                ...QoSClassification.ip_protocol.ip_config_src, ip4_mask: e.target.value
                                            }
                                        }
                                    };
                                    setQoSClassification(new_val)
                                }
                                else {
                                    let new_val = {
                                        ...QoSClassification, ip_protocol: {
                                            ...QoSClassification.ip_protocol, ip_config_src: {
                                                ...QoSClassification.ip_protocol.ip_config_src, ip6_prefix_length: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                            }
                                        }
                                    };
                                    setQoSClassification(new_val)
                                }
                            }}
                            disabled={false}
                            validators={[
                                {fn: extraValidators.optionalValidators, params: {
                                    shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4,
                                    validators: [
                                        {fn: extraValidators.validateSubNetMask, params: ''},
                                    ]
                                }}, 
                                {fn: extraValidators.optionalValidators, params: {
                                    shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV6,
                                    validators: [
                                        {fn: extraValidators.isNumber, params: ''},
                                        { fn: extraValidators.value, params: { min: 0, max: 128 } }
                                    ]
                                }}, 
                            ]}
                        ></Input>
                    </div>
                }

                {
                    QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_BOTH ? null :
                        <div className='qos_classification_d_flex'>
                            <Input
                                id='qos_classification_ip_rule_dest_ip'
                                name='qos_classification_ip_rule_dest_ip'
                                label={t('advanced.qos_classification.label.DEST_IP')}
                                type='text'
                                placeholder={t('advanced.qos_classification.label.DEST_IP')}
                                value={
                                    QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ?
                                        ((QoSClassification.ip_protocol.ip_config_dst.ip4 === '0.0.0.0') ? '' : QoSClassification.ip_protocol.ip_config_dst.ip4) :
                                        QoSClassification.ip_protocol.ip_config_dst.ip6
                                }
                                onChange={(e) => {
                                    if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4) {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, ip_config_dst: {
                                                    ...QoSClassification.ip_protocol.ip_config_dst, ip4: e.target.value
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }
                                    else {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, ip_config_dst: {
                                                    ...QoSClassification.ip_protocol.ip_config_dst, ip6: e.target.value
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }
                                }}
                                disabled={false}
                                validators={[
                                    {fn: extraValidators.optionalValidators, params: {
                                        shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4,
                                        validators: [
                                            {fn: extraValidators.validateIPv4, params: ''},
                                            {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                        ]
                                    }}, 
                                    {fn: extraValidators.optionalValidators, params: {
                                        shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV6,
                                        validators: [
                                            {fn: extraValidators.validateIPv6, params: ''},
                                            {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                        ]
                                    }}, 
                                ]}
                            ></Input>

                            <Input
                                id='qos_classification_ip_rule_dest_mask'
                                name='qos_classification_ip_rule_dest_mask'
                                label={QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ? t('advanced.qos_classification.label.DEST_MASK') : t('advanced.qos_classification.label.DEST_PREFIX')}
                                type={QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ? "text" : "number"}
                                placeholder={QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ? t('advanced.qos_classification.label.DEST_MASK') : t('advanced.qos_classification.label.DEST_PREFIX')}
                                value={
                                    QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ?
                                        ((QoSClassification.ip_protocol.ip_config_dst.ip4_mask === '0.0.0.0') ? '' : QoSClassification.ip_protocol.ip_config_dst.ip4_mask) :
                                        QoSClassification.ip_protocol.ip_config_dst.ip6_prefix_length
                                }
                                onChange={(e) => {
                                    if (QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4) {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, ip_config_dst: {
                                                    ...QoSClassification.ip_protocol.ip_config_dst, ip4_mask: e.target.value
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }
                                    else {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, ip_config_dst: {
                                                    ...QoSClassification.ip_protocol.ip_config_dst, ip6_prefix_length: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }
                                }}
                                disabled={false}
                                validators={[
                                    {fn: extraValidators.optionalValidators, params: {
                                        shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4,
                                        validators: [
                                            {fn: extraValidators.validateSubNetMask, params: ''},
                                        ]
                                    }}, 
                                    {fn: extraValidators.optionalValidators, params: {
                                        shouldValidate: () => QoSClassification.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV6,
                                        validators: [
                                            {fn: extraValidators.isNumber, params: ''},
                                            { fn: extraValidators.value, params: { min: 0, max: 128 } }
                                        ]
                                    }}, 
                                ]}
                            ></Input>
                        </div>
                }

                {
                    (QoSClassification.ip_protocol.protocol === IPProtocolEnumeration.PROTOCOL_TCP || QoSClassification.ip_protocol.protocol === IPProtocolEnumeration.PROTOCOL_UDP) ?
                        <div>
                            <div className='subtitle'><Translator path={'advanced.qos_classification.label.SOURCE_PORT'} /></div>
                            <div className='qos_classification_d_flex'>
                                <Input
                                    id='qos_classification_ip_rule_port_source_start'
                                    name='qos_classification_ip_rule_port_source_start'
                                    label={t('advanced.qos_classification.label.SOURCE_PORT_BEGIN')}
                                    type="number"
                                    placeholder={t('advanced.qos_classification.label.SOURCE_PORT_BEGIN')}
                                    value={QoSClassification.ip_protocol.src_port.start}
                                    onChange={(e) => {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, src_port: {
                                                    ...QoSClassification.ip_protocol.src_port, start: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }}
                                    disabled={false}
                                    validators={[
                                        extraValidators.isNumber,
                                        { fn: extraValidators.value, params: { min: 0, max: 65535 } }
                                    ]}
                                ></Input>

                                <Input
                                    id='qos_classification_ip_rule_port_source_end'
                                    name='qos_classification_ip_rule_port_source_end'
                                    label={t('advanced.qos_classification.label.SOURCE_PORT_END')}
                                    type="number"
                                    placeholder={t('advanced.qos_classification.label.SOURCE_PORT_END')}
                                    value={QoSClassification.ip_protocol.src_port.end}
                                    onChange={(e) => {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, src_port: {
                                                    ...QoSClassification.ip_protocol.src_port, end: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }}
                                    disabled={false}
                                    validators={[
                                        extraValidators.isNumber,
                                        { fn: extraValidators.value, params: { min: 0, max: 65535 } }
                                    ]}
                                ></Input>
                            </div>

                            <div className='subtitle'><Translator path={'advanced.qos_classification.label.DEST_PORT'} /></div>
                            <div className='qos_classification_d_flex'>
                                <Input
                                    id='qos_classification_ip_rule_port_dest_start'
                                    name='qos_classification_ip_rule_port_dest_start'
                                    label={t('advanced.qos_classification.label.DEST_PORT_BEGIN')}
                                    type="number"
                                    placeholder={t('advanced.qos_classification.label.DEST_PORT_BEGIN')}
                                    value={QoSClassification.ip_protocol.dst_port.start}
                                    onChange={(e) => {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, dst_port: {
                                                    ...QoSClassification.ip_protocol.dst_port, start: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }}
                                    disabled={false}
                                    validators={[
                                        extraValidators.isNumber,
                                        { fn: extraValidators.value, params: { min: 0, max: 65535 } }
                                    ]}
                                ></Input>
                                <Input
                                    id='qos_classification_ip_rule_port_dest_end'
                                    name='qos_classification_ip_rule_port_dest_end'
                                    label={t('advanced.qos_classification.label.DEST_PORT_END')}
                                    type="number"
                                    placeholder={t('advanced.qos_classification.label.DEST_PORT_END')}
                                    value={QoSClassification.ip_protocol.dst_port.end}
                                    onChange={(e) => {
                                        let new_val = {
                                            ...QoSClassification, ip_protocol: {
                                                ...QoSClassification.ip_protocol, dst_port: {
                                                    ...QoSClassification.ip_protocol.dst_port, end: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                                }
                                            }
                                        };
                                        setQoSClassification(new_val)
                                    }}
                                    disabled={false}
                                    validators={[
                                        extraValidators.isNumber,
                                        { fn: extraValidators.value, params: { min: 0, max: 65535 } }
                                    ]}
                                ></Input>
                            </div>
                        </div>
                        : null
                }
            </div>
        )
    }

    const QoSClassificationMACForm = () => {
        return <React.Fragment>
            <div className='qos_classification_d_flex'>
                <Input
                    id='qos_classification_mac_rule_source_mac'
                    name='qos_classification_mac_rule_source_mac'
                    label={t('advanced.qos_classification.label.SOURCE_MAC')}
                    type='text'
                    placeholder={t('advanced.qos_classification.label.SOURCE_MAC')}
                    value={QoSClassification.mac.src}
                    onChange={(e) => {
                        let new_val = { ...QoSClassification, mac: { ...QoSClassification.mac, src: e.target.value } };
                        setQoSClassification(new_val)
                    }}
                    disabled={false}
                    validators={[
                        extraValidators.required,
                        extraValidators.validateMAC
                    ]}
                ></Input>
                <Input
                    id='qos_classification_mac_rule_dest_mac'
                    name='qos_classification_mac_rule_dest_mac'
                    label={t('advanced.qos_classification.label.DEST_MAC')}
                    type='text'
                    placeholder={t('advanced.qos_classification.label.DEST_MAC')}
                    value={QoSClassification.mac.dst}
                    onChange={(e) => {
                        let new_val = { ...QoSClassification, mac: { ...QoSClassification.mac, dst: e.target.value } };
                        setQoSClassification(new_val)
                    }}
                    disabled={false}
                    validators={[
                        extraValidators.required,
                        extraValidators.validateMAC
                    ]}
                ></Input>
            </div>
        </React.Fragment>
    }

    const QoSClassificationForm = (formContext) => {
        return <div>

            <div className='subtitle'><Translator path={'advanced.qos_classification.label.ADD_QOS_CLASSIFICATION_RULE'} /></div>

            <Input
                id='qos_classification_rule_name'
                name='qos_classification_rule_name'
                label={t('advanced.qos_classification.label.RULE_NAME')}
                type='text'
                placeholder={t('validators.MAX_NUMBER_OF_CHARACTERS', { max: qosClassificationConstants.RULE_NAME_MAX_LENGTH })}
                value={QoSClassification.rule_name}
                onChange={(e) => {
                    let new_val = { ...QoSClassification, rule_name: e.target.value };
                    setQoSClassification(new_val)
                }}
                disabled={false}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: 0, max: qosClassificationConstants.RULE_NAME_MAX_LENGTH } }
                ]}
            ></Input>

            <Select
                id='qos_classification_rule_precedence'
                name='qos_classification_rule_precedence'
                label={t('advanced.qos_classification.label.QUEUE')}
                options={QueueOptions}
                value={QoSClassification.precedence.precedence}
                onChange={(e) => {
                    let new_val = { ...QoSClassification, precedence: { ...QoSClassification.precedence, precedence: Number(e.target.value) } };
                    setQoSClassification(new_val)
                }}
            ></Select>

            <div className='qos_classification_d_flex'>
                <Select
                    id='qos_classification_dscp_remarking'
                    name='qos_classification_dscp_remarking'
                    label={t('advanced.qos_classification.label.DSCP_REMARKING')}
                    options={DSCPOptions}
                    value={QoSClassification.precedence.dscp_remarking}
                    onChange={(e) => {
                        let new_val = { ...QoSClassification, precedence: { ...QoSClassification.precedence, dscp_remarking: Number(e.target.value) } };
                        setQoSClassification(new_val)
                    }}
                ></Select>

                <Select
                    id='qos_classification_cos'
                    name='qos_classification_cos'
                    label={t('advanced.qos_classification.label.CLASS_OF_SERVICE')}
                    options={IEEE_802_1pOptions}
                    value={QoSClassification.precedence.cos_802_1p}
                    onChange={(e) => {
                        let new_val = { ...QoSClassification, precedence: { ...QoSClassification.precedence, cos_802_1p: Number(e.target.value) } };
                        setQoSClassification(new_val)
                    }}
                ></Select>
            </div>

            <div className='subtitle'><Translator path={'advanced.qos_classification.label.TRAFFIC_CLASSIFICATION'} /></div>

            <Select
                id='qos_classification_type_of_rule'
                name='qos_classification_type_of_rule'
                label={t('advanced.qos_classification.label.QOS_RULE_TYPE')}
                options={TypeOfRuleOptions}
                value={QoSClassification.mode}
                onChange={(e) => {
                    let new_val = { ...QoSClassification, mode: Number(e.target.value) };
                    setQoSClassification(new_val)
                    formContext.clearErrors()
                }}
            ></Select>

            {QoSClassification.mode === TypeOfRuleEnumeration.PORT && QoSClassificationPortForm()}
            {QoSClassification.mode === TypeOfRuleEnumeration.ETHER_TYPE && QoSClassificationEtherTypeForm()}
            {QoSClassification.mode === TypeOfRuleEnumeration.IP_PROTO && QoSClassificationIPProtoForm()}
            {QoSClassification.mode === TypeOfRuleEnumeration.MAC_ADDRESS && QoSClassificationMACForm()}

        </div >
    }

    return (
        !QoSClassification ? <div className='loading'></div> :

            <div id='edit-qos-classification-page'>
                <DefaultStatusModals
                    modalID='qos-classification'
                    saving={saving}
                    success={success}
                    error={error}
                    errorText={t('common.message.error.SERVER_ERROR', { error: translateErrorMessagesFromBackend(errorMsg) })}
                    continueFn={dismissModals}
                    isWarningModal={true}
                    savingText={t('common.label.SAVING')}
                ></DefaultStatusModals>

                <div className='subtitle'><Translator path={'advanced.qos_classification.title.QOS_CLASSIFICATION'} /></div>

                <Button
                    id='network-qos-classification-return'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={e => goBackToList()}
                ></Button>

                <div className='card mt2'>
                    <Form
                        onSubmit={submitConfirm}
                        submitText={<Translator path="common.label.SAVE"></Translator>}
                        buttonId='button-save'
                    >
                        <FormContext.Consumer>
                            {value => QoSClassificationForm(value)}
                        </FormContext.Consumer>
                    </Form>
                </div>
            </div>
    )
}
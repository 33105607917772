export default {
  "translations": {
    "advanced": {
      "bridging": {
        "label": {
          "BRIDGING_SETTINGS": "Configuración de bridging"
        },
        "title": {
          "AGEING_TIME": "Ageing Time",
          "AGEING_TIMER": "Ageing Timer",
          "IS_LOCAL": "¿Local?",
          "MAC_ADDRESS": "Dirección MAC",
          "PORT": "Puerto",
          "STP": "802.1d Spanning Tree",
          "TABLE": "Base de Datos de Enrutamiento Bridge"
        }
      },
      "global_routing": {
        "error": {
          "COULD_NOT_GET_INFO": "No se pudo recuperar la información de la tabla de enrutamiento global"
        },
        "title": {
          "GLOBAL_ROUTING": "Enrutamiento global"
        },
        "info": {
          "NO_FORWARDS": "No hay reenvíos bridge en la base de datos"
        }
      },
      "igmp_mld_snooping": {
        "status": {
          "label": {
            "ENABLED": "Habilitar IGMP/MLD Snooping"
          }
        },
        "title": {
          "ADVANCED_IGMP_MLD_SNOOPING": "Habilitar/Deshabilitar IGMP y MLD Snooping"
        }
      },
      "neighbour_hosts": {
        "error": {
          "COULD_NOT_GET_INFO": "No se puede recuperar la información de dispositivos vecinos"
        },
        "title": {
          "NEIGHBOUR_HOSTS": "Dispositivos vecinos"
        }
      },
      "qos_classification": {
        "error": {
          "INVALID_PORT_RANGE_SRC": "Intervalo de puerto de origen no válido, el final debe ser mayor o igual que el inicio.",
          "INVALID_PORT_RANGE_DST": "Intervalo de puerto de destino no válido, el final debe ser mayor o igual que el inicio.",
          "MAX_NUM_OF_RULES": "¡El número máximo de reglas es 16!",
          "INVALID_DST_IPV4_MASK": "La máscara IPv4 de destino no es válida",
          "INVALID_SRC_IPV4_MASK": "La máscara IPv4 de origen no es válida",
          "SRC_IP_NO_SET_WHEN": "Al configurar la máscara de origen, debe configurar la IP respectiva.",
          "DST_IP_NO_SET_WHEN": "Al configurar la máscara de destino, debe configurar la IP respectiva.",
          "SRC_IP6_NO_SET_WHEN": "Al configurar el prefijo de origen, debe configurar la IPv6 respectiva.",
          "DST_IP6_NO_SET_WHEN": "Al configurar el prefijo de destino, debe configurar la IPv6 respectiva.",
          "QUEUE_DISABLED": "es necesario habilitar primero, en la función de Política de QoS, las colas que se utilizarán.",
          "INVALID_ETHTYPE": "EtherType debe tener un valor entre 0x800 y 0xFFFF"
        },
        "info": {
          "NO_QOS_CLASSIFICATIONS": "No se han creado clasificaciones de QoS"
        },
        "label": {
          "_802_1P": "802.1p",
          "ADD_QOS_CLASSIFICATION_RULE": "Agregar reglas de clasificación de QoS",
          "ALL_PORTS": "Todos Las Puertas",
          "CLASS_OF_SERVICE": "Clase de Servicio",
          "DEST_IP": "IP de Destino",
          "DEST_MAC": "MAC de Destino",
          "DEST_MASK": "Máscara de Destino",
          "DEST_PORT": "Puertas de Destino",
          "DEST_PORT_BEGIN": "Inicio de las puertas de destino",
          "DEST_PORT_END": "Fin de las puertas de destino",
          "DEST_PREFIX": "Prefijo de Destino",
          "DETAILS": "Detalles",
          "DSCP_MARK": "Marcado DSCP",
          "DSCP_PATTERN": "Estándar DSCP",
          "DSCP_REMARKING": "DSCP Remarking",
          "ETHERNET_TYPE": "Ethernet",
          "ICMP_PROTO": "Protocolo ICMP",
          "ID": "ID",
          "INFORMATION": "Información",
          "IP_TYPE": "Protocolo IP",
          "IP_VERSION": "Versión de IP",
          "IPV4_IPV6_VERSION": "IPv4/IPv6",
          "IPV4_VERSION": "Versión IPv4",
          "IPV6_VERSION": "Versión IPv6",
          "LAN_01_PORT": "LAN 01",
          "LAN_02_PORT": "LAN 02",
          "MAC_TYPE": "Dirección MAC",
          "NAME": "Nombre",
          "NEW_CLASSIFICATION": "Crear clasificación de QoS",
          "NO_PROTO": "Sin Protocolo",
          "OPTIONS": "Opciones",
          "ORDER": "Orden",
          "PHYSICAL_PORT": "Puerta Física",
          "PORT_TYPE": "Puerta",
          "PORTS": "Puertas",
          "QOS_CLASSIFICATION_LIST": "lista de clasificación",
          "QOS_RULE_TYPE": "Tipo de regla de QoS",
          "QUEUE": "Precedencia",
          "QUEUE_1": "Fila 01",
          "QUEUE_2": "Fila 02",
          "QUEUE_3": "Fila 03",
          "QUEUE_4": "Fila 04",
          "RULE_NAME": "Nombre de la Regla",
          "RULE_TYPE": "Tipo de Regla",
          "SOURCE_IP": "IP de Origen",
          "SOURCE_MAC": "MAC de Origen",
          "SOURCE_MASK": "Máscara de Origen",
          "SOURCE_PORT": "Puertas de Origen",
          "SOURCE_PORT_BEGIN": "Inicio de las puertas de origen",
          "SOURCE_PORT_END": "Fin de las puertas de origen",
          "SOURCE_PREFIX": "Prefijo de origen",
          "TCP_PROTO": "Protocolo TCP",
          "TRAFFIC_CLASSIFICATION": "Especificación de reglas de clasificación de tráfico",
          "UDP_PROTO": "Protocolo UDP",
          "VALUE_IN_HEX": "Valor en Hexadecimal"
        },
        "title": {
          "QOS_CLASSIFICATION": "Clasificación de QoS"
        }
      },
      "qos_traffic_control": {
        "label": {
          "ADD_QOS_TRAFFIC_CONTROL_RULE": "Agregar regla de control de tráfico",
          "DOWNSTREAM": "Downstream",
          "UPSTREAM": "Upstream",
          "LIMIT_RATE": "Límite de banda (kbps)",
          "ADD_NEW_RULE": "Añadir nueva regla",
          "DST_PORT": "Puerta de destino",
          "SRC_PORT": "Puerta de origen"
        },
        "error": {
          "SELECT_PROTOCOL": "Seleccione un protocolo válido cuando use puertos: TCP, UDP, TCP/UDP",
          "MAX_OF_CONNECTIONS": "Número máximo de configuraciones alcanzado"
        },
        "info": {
          "NO_RULES": "No hay reglas de control de tráfico creadas"
        }
      },
      "static_routing": {
        "error": {
          "NETMASK_AND_IP_ERROR": "verifique que la dirección IP represente una red válida para esta máscara.",
          "ERROR_SERVER": "Error al intentar aplicar cambios al servidor: ",
          "ROUTER_ALREADY_EXISTS": "Esta ruta ya existe, modifique el campo de destino o net masquerade"
        },
        "info": {
          "NO_ROUTES": "No hay rutas estáticas creadas"
        },
        "label": {
          "DESTINATION": "Destino",
          "DISABLED": "Discapacitado",
          "ENABLED": "Habilitado",
          "INTERFACEID": "Interfaz",
          "METRIC": "Métrico",
          "NET_MASK": "Máscara de red",
          "NEW_ROUTE": "Crear ruta",
          "NEXT_HOP": "Siguiente salto",
          "INTERFACE_ANY": "Cualquier"
        },
        "paceholder": {
          "VALID_IP_INPUT": "Introduzca una dirección IP válida",
          "VALID_METRIC_INPUT": "Introduce una métrica válida",
          "VALID_NETMASK_INPUT": "Introduzca una máscara de red válida"
        },
        "title": {
          "STATIC_ROUTING": "Lista de rutas",
          "STATIC_ROUTING_CREATE": "Crear nueva ruta estática",
          "STATIC_ROUTING_EDIT": "Editar ruta estática"
        }
      },
      "qos_policy": {
        "title": {
          "BANDWIDTH_CONFIG": "Configuración de ancho de banda",
          "QUEUE_CONFIG": "Configuración de la cola de QoS",
          "ENABLE_QUEUE": "Habilitar cola",
          "HIGHEST_PRIORITY": "Más alta prioridad",
          "WEIGHT": "Peso",
          "PRIORITY": "Prioridad",
          "QUEUE_WEIGHT": "Peso de la cola",
          "QUEUE_WEIGHT_SUM": "La suma total de los pesos debe ser 100. Las colas con peso 0 serán deshabilitadas.",
          "QUEUE_WEIGHT_SUM_GREATER": "La suma de los pesos es mayor que 100.",
          "QUEUE_WEIGHT_SUM_LESS": "La suma de los pesos es menor que 100."
        },
        "label": {
          "USER_DEF_BANDWIDTH": "Habilitar ancho de banda definido por el usuario",
          "TOTALBANDWIDTH": "Ancho de banda máximo (Kbps)",
          "POLICY": "Política"
        },
        "error": {
          "QUEUE_DISABLED": "no es posible deshabilitar las colas utilizadas por la función Clasificación de QoS. Si realmente desea deshabilitar dichas colas, elimine su uso en la función Clasificación de QoS.",
          "TOTAL_SUM": "la suma total de los pesos de la cola debe ser 100. Además, al menos una cola debe estar habilitada."
        }
      }
    },
    "common": {
      "label": {
        "RESULTS": "Resultados",
        "ACTIONS": "Acciones",
        "ADD": "Adicionar",
        "ALL_INTERFACES": "Cualquier",
        "ALLOW": "Permitir",
        "ALWAYS_NEAR": "Siempre cerca",
        "BACK": "Volver",
        "BOTH": "Ambos",
        "CHOOSE": "Elija",
        "CANCEL": "Cancelar",
        "CONFIRM": "Confirmar",
        "CONFIRM_PASSWORD": "Confirmar contraseña",
        "DELETE": "Eliminar",
        "DENY": "Negar",
        "DESCRIPTION": "Descripción",
        "DESTINATION": "Ruta",
        "DESTINY": "Destino",
        "DIRECTION": "Direcíon",
        "DIRECTION_ABREVIATION": "Dir",
        "DISABLED": "Desactivado",
        "DOMAIN": "Dominio",
        "DOWN": "Down",
        "DOWNLOAD": "Bajar",
        "EDIT": "Editar",
        "ENABLE": "Habilitar",
        "ENABLED": "Activado",
        "GATEWAY_IPV4": "Puerta de enlace IPv4",
        "GATEWAY_IPV6": "Puerta de enlace IPv6",
        "HOST": "Host",
        "ICMP": "ICMP",
        "ID": "Id",
        "INTERFACE": "Interfaz",
        "IP": "IP",
        "IP_ADDRESS": "Dirección IP",
        "IPV4": "IPv4",
        "IPV6": "IPv6",
        "KEYWORD": "Keyword",
        "KEYWORDS": "Keywords",
        "LAN": "LAN",
        "MAC_ADDRESS": "MAC",
        "MASK": "Máscara",
        "METRIC": "Métrica",
        "NEXT_HOP": "Siguiente salto",
        "NUMBER": "Nº",
        "OPERATIONS": "Operaciones",
        "PAGE": "Página",
        "PASSWORD": "Contraseña",
        "PROTOCOL": "Protocolo",
        "ROUTE": "Ruta",
        "RULE": "Regla",
        "RX": "RX (Paquetes)",
        "SAVE": "Salvar",
        "SAVING": "Salvando",
        "SETTINGS": "Ajustes",
        "INTERFACE_ANY": "Cualquier",
        "SOURCE": "Origen",
        "START": "Empezar",
        "STATUS": "Estado",
        "TABLE_REFRESH": "Actualizar valores",
        "TABLE_REFRESHING": "Actualizando",
        "TCP": "TCP",
        "TTL": "TTL",
        "TX": "TX (Paquetes)",
        "UDP": "UDP",
        "UP": "Up",
        "URL": "Url",
        "URLS": "URLs",
        "USER": "Usuario",
        "VLAN_ID": "VLAN ID",
        "WAIT": "Espera",
        "OUTGOING": "Salida",
        "INGOING": "Entrada",
        "SUNDAY": "Dom",
        "MONDAY": "Lun",
        "TUESDAY": "Mar",
        "WEDNESDAY": "Mié",
        "THURSDAY": "Jue",
        "FRIDAY": "Vie",
        "SATURDAY'": "Sáb",
        "BEGINNING": "Comienzo",
        "END": "El fin",
        "SATURDAY": "Sábado",
        "PORT_SOURCE": "Puerto de origen",
        "PORT_DESTINY": "Puerto de destino"
      },
      "message": {
        "error": {
          "VALIDATE_TIME_FIELD": "Formato inválido (HH:MM)",
          "ALPHANUMERIC_FIELD": "Este campo debe contener solo letras y números",
          "CHECK_CONNECTION": "Verifique su conexión con el dispositivo e intente nuevamente.",
          "EXPIRED_SESSION": "Su sesión ha caducado.",
          "FIELD_INVALID": "Campo no valido",
          "FORBID_WHITESPACES_ONLY_FIELD": "Contiene solo caracteres en blanco",
          "FORBID_ANY_WHITESPACES": "Contiene caracteres en blanco",
          "IS_HEX_FIELD": "Debe ser un hexadecimal en el formato 0x<HEX>",
          "IS_NUMBER_FIELD": "El valor debe ser numérico",
          "IS_INTEGER_NUMBER_FIELD": "El valor debe ser un número entero",
          "IS_URL_FIELD": "URL inválida",
          "IS_VENDORID_FIELD": "El campo debe tener 4 letras mayúsculas",
          "ITEM_ALREADY_EXISTS": "Este elemento ya existe",
          "MAX_NUMBER_OF_ELEMENTS_IS": "El límite máximo de elementos es {{max}}",
          "NO_WHITESPACES_ALLOWED": "No puede haber espacios en blanco en este campo",
          "NON_ASCII_FIELD": "Contiene caracteres inválidos",
          "REQUIRED_FIELD": "Este campo es obligatorio",
          "SERVER_ERROR": "Hubo un error: {{error}}",
          "SIZE_FIELD": "El campo debe contener entre {{min}} y {{max}} caracteres",
          "VALIDATE_HOST": "Dirección inválida",
          "VALIDATE_HOSTNAME": "Hostname inválida",
          "VALIDATE_IPV4_FIELD": "Dirección inválida",
          "VALIDATE_IPV6_FIELD": "Dirección inválida",
          "VALIDATE_CANNOT_BE_LOCALHOST": "La dirección no debe ser de localhost",
          "VALIDATE_IPV6_PREFIX_FORMAT": "El prefijo debe tener el formato 2001:db8:0:17b5::/64",
          "VALIDATE_IPV6_PREFIX_LENGTH": "El prefijo debe ser un número entero entre 48 y 64",
          "VALIDATE_IPV6_LINK_LOCAL_IS_NOT_ALLOWED": "No se permiten direcciones link-local",
          "VALIDATE_IPV6_VALID_TIME": "La validez del prefijo debe ser mayor o igual a la validez deseada",
          "VALIDATE_IPV6_WITH_PREFIX_FIELD": "Dirección no válida, se requiere una dirección IPv6 prefijada",
          "VALIDATE_MAC_FIELD": "Dirección inválida",
          "VALIDATE_SUBNETMASK_FIELD": "Máscara inválida",
          "VALIDATION_PORT_RANGE": "Valores válidos: [1, 65535]",
          "VALIDATION_PORT_RANGE_IP_PORT_FILTER": "Valores válidos: [0, 65535]",
          "VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM": "El valor inicial es menor que el final",
          "VALIDATION_PORT_RANGE_BIGGER_THAN": "El valor inicial es más grande que el final",
          "VALIDATION_SAME_SRC_DST_IP": "La dirección de la red de origen es idéntica a la dirección de la red de destino",
          "VALUE_MAX_FIELD": "El campo debe tener un valor menor o igual a {{max}}",
          "VALUE_MIN_FIELD": "El campo debe tener un valor mayor o igual a {{min}}",
          "VALUE_MIN_MAX_FIELD": "Valores permitidos entre {{min}} y {{max}}"
        },
        "info": {
          "SUCCESS_ON_PERSIST": "¡La configuración se ha guardado correctamente!"
        },
        "sucess": {
          "DELETE_SUCCESS_TEXT_MESSAGE_CUSTOM": "Entrada(s) de registro(s) eliminada(s) con éxito."
        },
        "warning": {
          "CONFIRM_EXCLUSION": "¿Realmente desea eliminar el elemento?",
          "LOGIN_TO_CONTINUE": "Vuelva a iniciar sesión para continuar.",
          "PERMANENT_DELETE_WARNING": "Los elementos seleccionados se eliminarán de forma permanente.",
          "OPERATION_CANT_BE_REVERTED": "Esta operación no se puede revertir.",
          "WAIT": "Espera",
          "WISH_TO_CONTINUE": "¿Desea continuar?",
          "IPV6_ENABLE": "Para poder configurar los ajustes, habilite IPv6 en "
        }
      }
    },
    "dashboard": {
      "title": {
        "INTERFACE_STATISTICS": "Estadísticas de interfaz",
        "LAN_SETTINGS": "Configuración de LAN",
        "NO_WANS_REGISTERED": "No hay WANs registradas",
        "STATUS_LAN": "Estado de LAN",
        "WAN_SETTINGS": "Configuración de WAN"
      },
      "label": {
        "MTU_SIZE": "Tamaño de MTU",
        "WAN_CONNECTION": "Conexión WAN",
        "OMCI_CONFIGURED": "WAN OMCI",
        "LOCAL_NETWORK_LAN": "Red de área local (LAN)",
        "IP_ADDRESS": "Dirección IP",
        "IPV6_ADDRESS": "Dirección IPv6",
        "IPV6_ADDRESS_GLOBAL": "IPv6 Global",
        "IPV6_ADDRESS_LINK_LOCAL": "IPv6 Link Local",
        "NETMASK": "Máscara de subred",
        "MAC_ADDRESS": "Dirección MAC",
        "DHCP_SERVER": "Servidor DHCP",
        "2_4G_NETWORK": "Red de 2.4 GHz",
        "5G_NETWORK": "Red de 5 GHz",
        "GPON_STATE": "Estado GPON",
        "LAN_IPV4_ADDR": "Conexión LAN",
        "MODEL": "Modelo",
        "PRODUCT_HAS_NO_LICENSE": "No hay licencias instaladas",
        "LAN_MAC_ADDR": "Dirección MAC de LAN",
        "FIRMWARE_VERSION": "Versión de firmware",
        "ACTIVE_TIME": "Tiempo activo del sistema",
        "INTELBRAS_SW_LICENSE": "Licencia de software Intelbras",
        "DETAILS": "Detalles",
        "CONNECTION_STATUS": "Estado de conexión",
        "INITIAL_STATE": "Sin conexión PON",
        "SERIAL_NUMBER_STATE": "En espera de aprovisionamiento",
        "RANGING_STATE": "En aprovisionamiento",
        "OPERATION_STATE": "En la operación",
        "INTERMITTENT_LODS_STATE": "LOS/LOF intermitente",
        "EMERGENCY_STOP_STATE": "Ocorreu um erro",
        "ENABLED": "Activado",
        "DISABLED": "Desactivado",
        "WLAN_INTERFACES": "Interfaces WLAN",
        "RADIO_CONFIG": "Ajustes de radio",
        "CONNECTED_DEVICES": "Dispositivos conectados",
        "WAN_INTERFACES": "Interfaces WAN",
        "INMESH_CONNECTION": "Dispositivos inMesh"
      }
    },
    "parental_control": {
      "label": {
        "CONNECTED_DEVICES": "Dispositivos conectados"
      },
      "connected_devices": {
        "NO_DEVICES_CONNECTED": "No hay dispositivos conectados",
        "DEVICE_LIST": "Lista de dispositivos",
        "HOSTNAME": "Hostname",
        "IP": "Dirección IP",
        "IPV6_COUNT": "Número de direcciones IPv6",
        "IPV6_LIST": "Lista de direcciones IPv6:",
        "IS_DMZ": "¿Es el host DMZ?",
        "IS_STATIC_IP": "¿Es IP estática?",
        "MAC": "Dirección MAC",
        "NETWORK": "Red",
        "OPMODE": "Banda",
        "RXBYTES": "Bytes recibidos (Mb)",
        "TXBYTES": "Bytes transmitidos (Mb)",
        "RXRATE": "Tarifa de recepción (Mbps)",
        "TXRATE": "Tarifa de transmision (Mbps)",
        "SIGNAL_LEVEL": "Nivel de señal (dBm)",
        "TIME": "Tiempo (s)",
        "UNITY_SIZE": "Dimensión"
      },
      "parental_control": {
        "NO_RULES": "No se han creado reglas",
        "NEW_RULE": "Crear nueva regla",
        "title": {
          "EDIT_RULE": "Editar/Crear nueva regla"
        },
        "label": {
          "RULE_NAME": "Nombre de la regla",
          "RULE_TYPE": "Tipo de regla",
          "IP_FROM": "Dirección IP de inicio",
          "IP_TO": "Dirección IP final",
          "MAC": "Dirección MAC",
          "DAYS": "Días a aplicar",
          "TIME": "Tiempo para ser aplicado",
          "START_TIME": "hora de inicio",
          "END_TIME": "hora de finalización",
          "ACTIONS": "Acciones",
          "ADDRESSES": "Direcciones",
          "ENABLE_RES": "Habilitar función",
          "DISABLE_RES": "Deshabilitar función"
        },
        "warning": {
          "MAX_RULES_NUM": "Número máximo de reglas alcanzado",
          "RESOURCE_DISABLED": "La función está deshabilitada. Habilítelo para que la configuración surta efecto."
        },
        "error": {
          "IP_RANGE": "La dirección IP inicial debe preceder a la final.",
          "DAYS_RANGE": "Debe seleccionar al menos un día.",
          "TIME_RANGE": "La hora de inicio debe preceder a la hora de finalización."
        }
      }
    },
    "firewall": {
      "alg": {
        "info": {
          "ALG_SUBTITLE": "Esta página se utiliza para habilitar/deshabilitar los servicios ALG."
        },
        "label": {
          "FTP_ENABLED": "FTP",
          "H323_ENABLED": "H323",
          "IP_SEC_ENABLED": "IP Sec",
          "L2TP_ENABLED": "L2TP",
          "PPTP_ENABLED": "PPTP",
          "RTSP_ENABLED": "RTSP",
          "SIP_ENABLED": "SIP",
          "TFTP_ENABLED": "TFTP"
        },
        "title": {
          "FIREWALL_ALG": "ALG",
          "FIREWALL_ALG_ENABLE": "Habilitar configuración de ALG"
        }
      },
      "dmz": {
        "title": {
          "FIREWALL_DMZ": "Configuración de DMZ",
          "FIREWALL_DMZ_ENABLE": "Habilitar configuración de DMZ"
        },
        "message": {
          "error": {
            "HAS_IP_OUT_OF_RANGE": "La dirección IP no está en el rango LAN",
            "HAS_HOST_IP": "La dirección IP no puede ser la misma que la del host"
          }
        }
      },
      "domain_blocking": {
        "title": {
          "DOMAIN_BLOCKING_SETTINGS": "Configuración de bloqueo de dominio",
          "DOMAINS": "Dominios",
          "DOMAIN_BLOCKING": "Bloqueo de dominio"
        },
        "label": {
          "ADD_DOMAIN_BLOCKING": "Añadir nuevo dominio",
          "ENABLE_DOMAIN_BLOCKING": "Habilitar bloqueo de dominio"
        },
        "info": {
          "NO_DOMAINS": "No hay dominios agregados a la lista"
        }
      },
      "ip_port_filtering": {
        "label": {
          "ACTION_DEFAULT_INCOMING": "Acción estándar incoming",
          "ACTION_DEFAULT_OUTGOING": "Acción estándar outgoing",
          "DESTINY_IP": "IP de destino",
          "DESTINY_PORT_FROM": "Puerto de destino de inicio",
          "DESTINY_PORT_TO": "Puerto de destino final",
          "DIRECTION": "Dirección",
          "FILTER_TYPE": "Tipo de filtro",
          "SOURCE_IP": "IP de origen",
          "SOURCE_PORT_FROM": "Puerto de origen de inicio",
          "SOURCE_PORT_TO": "Puerto de origen final"
        },
        "message": {
          "error": {
            "DEFAULT_ERROR": "La acción predeterminada de {{direction}} esta como {{rule_type}}, esta regla no tiene efecto práctico",
            "RULE_CONFLICT": "Esta regla entra en conflicto con la regla: {{rule}}",
            "SERVER_ERROR": "Error del Servidor:"
          }
        },
        "title": {
          "DEFAULT_ACTIONS": "Acciones estándar",
          "FILTER_LIST": "Lista de filtros",
          "INSERT_NEW_FILTER": "Insertar filtro nuevo",
          "IP_PORT_FILTERING": "Filtrado de IP/Puertos"
        },
        "warning": {
          "LACK_OF_IP": "Debe proporcionar la dirección IP de origen y/o la IP de destino"
        },
        "info": {
          "NO_FILTERS": "No hay filtros agregados a la lista"
        }
      },
      "mac_filtering": {
        "label": {
          "DEFAULT_INCOMING_ACTION": "Acción estándar incoming",
          "DEFAULT_OUTGOING_ACTION": "Acción estándar outgoing",
          "DESTINY_MAC": "MAC de destino",
          "FILTER_TYPE": "Tipo de filtro",
          "SOURCE_MAC": "MAC de origen"
        },
        "title": {
          "ADD_NEW_FILTER": "Insertar filtro nuevo",
          "DEFAULT_ACTIONS": "Acciones estándar",
          "FILTER_LIST": "Lista de filtros",
          "FILTER_LIST_MAX": "Límite máximo de filtrado alcanzado.",
          "MAC_FILTER_SETTINGS": "Configuración del filtro MAC",
          "MAC_FILTERING": "Filtrado MAC"
        },
        "warning": {
          "LACK_OF_MAC": "Debe proporcionar la dirección MAC de origen y/o la dirección MAC de destino",
          "SAME_SRC_DST_MAC": "La dirección MAC de origen es idéntica a la de destino"
        },
        "info": {
          "NO_FILTERS": "No hay filtros agregados a la lista"
        }
      },
      "port_forward": {
        "label": {
          "ADD_PORT_FORWARD_RULE": "Agregar regla de asignación",
          "ALL_INTERFACES": "Cualquier",
          "EDIT_PORT_FORWARD_RULE": "Editar regla de asignación",
          "ENABLE_PORT_MAPPING": "Habilitar asignación de puertos",
          "INTERFACE": "Interfaz",
          "INTERFACE_ABREVIATION": "Itf",
          "LOCAL": "Local",
          "LOCAL_IP": "IP local",
          "LOCAL_PORT_FROM": "Puerto (de)",
          "LOCAL_PORT_TO": "Puerto (hasta)",
          "OBSERVATION": "Observación",
          "PROTOCOL": "Protocolo",
          "PROTOCOL_ABREVIATION": "Proto",
          "REMOTE": "Remoto",
          "REMOTE_IP": "IP remoto",
          "REMOTE_PORT_FROM": "Puerto (de)",
          "REMOTE_PORT_TO": "Puerto (hasta)"
        },
        "message": {
          "error": {
            "ERROR_CREATING_PORT_FORWARD_RULE": "Error al crear nueva regla: ",
            "ERROR_IP_OUT_OF_RANGE": "¡Dirección IP inválida! Debe estar definido en la subred actual.",
            "ERROR_SAVING_DATA": "No se pudo salvar la configuración de asignación de puertos: ",
            "FULL_TABLE_PORT_FORWARD": "Se ha alcanzado el número máximo de reglas.",
            "RULE_ALREADY_EXISTS": "Ya hay una regla en este rango"
          },
          "warning": {
            "DELETE_ITEM_WARNING": "¿Está seguro de que desea eliminar esta regla?",
            "OPERATION_CANNOT_BE_REVERTED": "Esta operación no se puede revertir.",
            "WISH_TO_GO_ON": "¿Quieres proceder?"
          }
        },
        "title": {
          "PORT_MAPPING": "Asignación de puertos"
        },
        "info": {
          "NO_REDIRECTS": "No hay redirecciones añadidas a la lista"
        }
      },
      "url_blocking": {
        "title": {
          "URL_BLOCKING": "Bloqueo de URL",
          "KEYWORDS_TABLE": "Lista de palabras clave",
          "URL_TABLE": "Lista de URL",
          "URL_BLOCKING_SETTINGS": "Configuración de bloqueo de URL"
        },
        "message": {
          "error": {
            "NO_RULES_ERROR": "debe proporcionar direcciones URL y palabras clave para habilitar este servicio.",
            "MAX_CHARACTERS": "uno o más elementos excede el máximo de caracteres."
          }
        },
        "label": {
          "ADD_NEW_KEYWORD": "Agregar nueva palabra clave",
          "ADD_NEW_URL": "Agregar nueva URL",
          "ENABLE_URL_BLOCKING": "Habilitar el bloqueo de URL"
        },
        "info": {
          "NO_KEYWORDS": "No hay keywords añadidas a la lista",
          "NO_URLS": "No hay URLs añadidas a la lista"
        }
      },
      "wlan-access-control": {
        "info": {
          "NO_ELEMENTS": "No hay MAC registrados"
        },
        "label": {
          "ACTIONS": "Acciones",
          "RADIO": "Radio",
          "POLICY_CONFIG": "Configuración de políticas para radios",
          "ALLOW": "Permitir",
          "DENY": "Denegar",
          "DISABLED": "Desactivado",
          "NEW_MAC_ADDR": "Registrar nueva dirección MAC",
          "ADD_NEW_MAC": "Agregar nuevo mac",
          "RADIO_SELECT": "Seleccionar radio"
        },
        "warn": {
          "2": {
            "4GHZ_AND_5GHZ_LIMIT": "Límite máximo alcanzado para radios de 2,4 GHz y 5 GHz",
            "4GHZ_LIMIT": "Límite máximo alcanzado para radio de 2,4 GHz"
          },
          "5GHZ_LIMIT": "Límite máximo alcanzado para la radio de 5 GHz"
        },
        "error": {
          "INVALID_MAC_ADDR": "Dirección MAC no válida"
        }
      }
    },
    "ipv6": {
      "ip_port_filtering": {
        "title": {
          "INSERT_NEW_FILTER": "Insertar nueva regla",
          "GENERAL_CONFIG": "Configuración general",
          "IP_SRC_CONFIG": "IP de origen",
          "IP_DST_CONFIG": "IP de destino",
          "FILTER_LIST": "Lista de reglas",
          "DEFAULT_ACTIONS": "Acciones estándar"
        },
        "label": {
          "IPV6_ENABLE_SRC_CONFIG": "Configurar IP de origen",
          "IPV6_ENABLE_DST_CONFIG": "Configurar IP de destino",
          "IPV6_ADDRESS_SOURCE": "Dirección IPv6 de origen",
          "IPV6_ADDRESS_DESTINATION": "Dirección IPv6 de destino",
          "IPV6_START": "Comienzo del rango de IPv6",
          "IPV6_END": "Fin del rango de IPv6",
          "IPV6_PREFIX_LENGTH": "Tamaño del prefijo",
          "PORT_FROM": "Comienzo del intervalo de puertos",
          "PORT_TO": "Fin del intervalo de puertos",
          "ACTION_DEFAULT_INCOMING": "Acción estándar incoming",
          "ACTION_DEFAULT_OUTGOING": "Acción estándar outgoing",
          "ADD_NEW_RULE": "Añadir nueva regla"
        },
        "error": {
          "FULL_TABLE": "Se ha alcanzado el número máximo de reglas.",
          "INVALID_ADDRESS": "Ingrese una dirección IPv6 válida.",
          "INVALID_PORT_RANGE": "El puerto de origen debe ser menor o igual que el puerto de destino",
          "WAN_WARNING": "Para agregar o editar una regla, se debe habilitar al menos una interfaz WAN configurada con el protocolo IPv6.",
          "INSERT_DATA": "Ingrese una IP y/o puerto válido",
          "TABLE_FULL": "Tabla de filtrado completa"
        },
        "message": {
          "IPV6_DISABLED": "La función Filtro IP/Puerto está deshabilitada porque IPv6 no está habilitado."
        }
      },
      "ip_port_filter": {
        "messages": {
          "NO_RULES": "No se han creado reglas de filtrado de IP/puerto."
        },
        "delete": {
          "DELETE_CONFIRM": "¿Desea confirmar la eliminación?",
          "DELETE_WARNING": "Esta regla se eliminará de forma permanente."
        }
      },
      "dhcp_client": {
        "info": {
          "NO_DHCP_CLIENTS": "No hay clientes DHCP registrados."
        }
      },
      "dhcp": {
        "label": {
          "addresses_config": {
            "CLIENT_DUID": "Cliente DUID",
            "IP_POOL_RANGE_END": "Dirección IPv6 final",
            "IP_POOL_RANGE_START": "Dirección IPv6 inicial",
            "PREFFERED_TIME": "Tiempo deseado.",
            "PREFIX_LENGTH": "Tamaño del prefijo",
            "REBIND_TIME": "Tiempo de recuperación",
            "RENEW_TIME": "Tiempo de renovación",
            "VALID_LIFETIME": "Vida útil válida"
          },
          "DNS_SERVERS": "Servidores DNS",
          "DOMAINS": "Dominios",
          "ENABLE_DHCP": "Habilitar DHCPv6",
          "MODE_RELAY": "Modo Relay",
          "MODE_SERVER": "Modo Servidor",
          "ENABLE_MODE_SERVER_AUTO": "Habilitar modo Servidor Automático"
        },
        "message": {
          "IPV6_DISABLED": "La función Configuraciones DHCPv6 está deshabilitada porque IPv6 no está habilitado.",
          "error": {
            "RELAY_MODE": "El modo Relay requiere al menos una interfaz WAN configurada con el protocolo IPv6 activado.",
            "RELAY_MODE_CONFIG_WAN": "Acceda a la ",
            "RELAY_MODE_CONFIG_WAN_PAG": "configuración de la WAN",
            "RELAY_MODE_CONFIG_WAN_END": " para configurar una interfaz",
            "SET_MODE": "Al habilitar el DHCPv6 elige el modo Relé o Servidor.",
            "CLIENT_DUID": "Introduzca un valor entre 0x00 y 0xFF",
            "IPV6_DHCP_ENABLED": "La función DHCPv6 está habilitada. Desactívelo antes de apagar IPv6."
          }
        },
        "title": {
          "DNS_SERVERS": "Servidores DNS",
          "DNS_SERVERS_LIST": "Lista de Servidores DNS",
          "DOMAINS": "Dominios",
          "DOMAINS_LIST": "Lista de Dominios",
          "CONFIG_MODES": "Modo de configuración",
          "IPV6_DHCP": "DHCPv6",
          "IPV6_DHCP_CHOICE": "Elija la configuración de DHCPv6",
          "ENABLE_IPV6_DHCP": "Activar / desactivar DHCPv6",
          "RELAY_MENU": "Configuraciones modo Relay",
          "SERVER_MENU": "Configuraciones modo Servidor",
          "ACTIVE_CLIENTS": "Clientes DHCPv6 activos",
          "ACTIVE_CLIENTS_BUTTON": "Ver clientes activos"
        }
      },
      "prefix_delegation": {
        "label": {
          "PREFIX_MODE": "Modo de prefijo",
          "PREFIX_MODE_STATIC": "Estático",
          "PREFIX_MODE_WAN_DELEGATED": "WAN delegada"
        }
      },
      "prefix-delegation": {
        "label": {
          "PREFIX": "Prefijo",
          "INTERFACE": "Interfaz"
        },
        "title": {
          "PREFIX_DELEGATION": "Prefijo"
        }
      },
      "proxy_mld": {
        "label": {
          "QUERY_INTERVAL": "Query Interval (en segundos)",
          "QUERY_RESPONSE_INTERVAL": "Query Response Interval (en milisegundos)",
          "RESPONSE_INTERVAL_OF_LAST_GROUP_MEMBER": "Response Interval of Last Group Member (en segundos)",
          "ROBUST_COUNT": "Robust Count"
        },
        "title": {
          "PROXY_MLD": "Proxy MLD",
          "PROXY_MLD_SETTINGS": "Configuración de proxy MLD"
        },
        "message": {
          "IPV6_DISABLED": "La función Configuración de proxy MLD está deshabilitada porque IPv6 no está habilitado."
        }
      },
      "radvd": {
        "label": {
          "ADV_AUTONOMOUS": "AdvAutonomous",
          "ADV_MANAGED_FLAG": "AdvManagedFlag",
          "ADV_ON_LINK": "AdvOnLink",
          "ADV_VALID_LIFETIME": "AdvValidLifetime",
          "ADV_PREFERRED_LIFETIME": "AdvPreferredLifetime",
          "ENABLE_ULA": "Activar ALU",
          "IPV6_PREFIX_MODE_AUTO": "Modo de dirección IPv6 automática",
          "MAX_RTR_ADV_INTERVAL": "MaxRtrAdvInterval",
          "MIN_RTR_ADV_INTERVAL": "MinRtrAdvInterval",
          "PREFIX": "Prefijo",
          "PREFIX_SIZE": "Tamaño del prefijo IPv6",
          "RDNSS1": "RDNSS1",
          "RDNSS2": "RDNSS2",
          "ULA_PREFER_TIME": "Validez deseada del prefijo ULA",
          "ULA_PREFIX": "Prefijo ULA",
          "ULA_PREFIX_LEN": "Tamaño del prefijo ULA",
          "ULA_VALID_TIME": "Validez del prefijo ULA"
        },
        "title": {
          "PREFIX": "Prefijo",
          "RADVD": "RADVD",
          "RADVD_SETTINGS": "Configuración de RADVD",
          "ULA_PREFIX": "Prefijo ULA"
        },
        "message": {
          "IPV6_DISABLED": "La función Configuración de RADVD está deshabilitada porque IPv6 no está habilitado."
        }
      },
      "ipv6_static_route": {
        "error": {
          "ERROR_SERVER": "Error al intentar aplicar cambios al servidor: ",
          "ROUTE_ALREADY_EXISTS": "Esta ruta ya existe, modifique el campo de destino",
          "WAN_WARNING": "Para agregar o editar una ruta, se debe habilitar al menos una interfaz WAN configurada con el protocolo IPv6.",
          "ROUTE_FAIL": "La actualización de la ruta falló.",
          "NEXTHOP_FAIL": "La creación de la ruta falló; verificar si el próximo salto está en una red conocida de la interfaz elegida.",
          "DESTINATION_FAIL": "Dirección de destino ya en uso",
          "FIELD_NOT_FOUND": "Campo no encontrado"
        },
        "info": {
          "NO_ROUTES": "No hay rutas estáticas IPv6 creadas"
        },
        "label": {
          "DESTINATION": "Destino",
          "DISABLED": "Discapacitado",
          "ENABLED": "Habilitado",
          "INTERFACE": "Interfaz",
          "METRIC": "Métrico",
          "NEW_ROUTE": "Crear ruta",
          "NEXT_HOP": "Siguiente salto",
          "INTERFACE_ANY": "Cualquier"
        },
        "message": {
          "IPV6_DISABLED": "La función Configuración de rutas estáticas IPv6 está deshabilitada porque IPv6 no está habilitado."
        },
        "placeholder": {
          "VALID_IPV6_INPUT": "Introduzca una dirección IPv6 válida",
          "VALID_METRIC_INPUT": "Introduce una métrica válida"
        },
        "title": {
          "STATIC_ROUTE": "Lista de rutas IPv6",
          "STATIC_ROUTE_CREATE": "Crear nueva ruta estática IPv6",
          "STATIC_ROUTE_EDIT": "Editar ruta estática"
        }
      },
      "status": {
        "label": {
          "ENABLE": "Habilitar IPv6",
          "IPV6_DISABLE": "Red/WAN",
          "IPV6_DISABLE_DHCP": "IPv6/DHCPv6"
        },
        "title": {
          "ENABLE_IPV6": "Activar / desactivar IPv6",
          "STATUS": "Estado"
        },
        "message": {
          "warning": {
            "WAN_IPV6_INTERFACES": "Las siguientes interfaces WAN están configuradas con el protocolo IPv6:",
            "MODIFY_WAN_PROTOCOL": "Debe realizar la modificación del protocolo a IPv4. Para poder configurar los ajustes, vaya a "
          }
        }
      },
      "route_list": {
        "label": {
          "DESTINATION": "Destino",
          "NEXT_HOP": "Siguiente salto",
          "FLAGS": "Flags",
          "METRIC": "Métrico",
          "REF": "Ref",
          "USE": "Uso",
          "INTERFACEID": "Interfaz",
          "NO_ROUTES": "No hay rutas IPv6"
        }
      }
    },
    "login": {
      "button": {
        "ENTER": "Entrar"
      },
      "checkbox": {
        "KEEP_SESSION_ALIVE": "Mantener la sesión activa"
      },
      "message": {
        "error": {
          "INVALID_USER": "Usuario no permitido.",
          "INVALID_CREDENTIALS": "Credenciales no válidas",
          "INVALID_CREDENTIALS_MESSAGE": "Los intentos de inicio de sesión no válidos serán penalizados con retrasos de tiempo progresivos.",
          "LOGIN_REQUEST_ERROR": "Hubo un error al intentar iniciar sesión."
        },
        "warning": {
          "CHANGE_PASSWORD_ADVICE": "Es necesario cambiar las credenciales de usuario predeterminado en los siguientes campos para aumentar la seguridad de su enrutador.",
          "DEFAULT_CREDENTIALS": "Su enrutador está configurado con nombre de usuario y contraseña predeterminados."
        }
      },
      "label": {
        "ACCEPT_TERMS_OF_USE_1": "Acepto los",
        "ACCEPT_TERMS_OF_USE_2": "Términos de uso",
        "ACCEPT_TERMS_OF_USE_3": "del enrutador Intelbras.",
        "ACCEPT_TERMS_OF_USE": "Acepto los {{term}} del enrutador Intelbras."
      },
      "title": {
        "warning": {
          "INSECURE_CREDENTIALS": "Credenciales de acceso no seguras"
        }
      }
    },
    "menu": {
      "PARENTAL_CONTROL": "Control parental",
      "PARENTAL_CONTROL_DESCRIPTION": "Ajustes relacionados con los controles parentales",
      "CONNECTED_DEVICES": "Dispositivos conectados",
      "CONNECTED_DEVICES_DESCRIPTION": "Lista de dispositivos conectados",
      "ACL_ADMIN": "Configuración de ACL",
      "ACL_ADMIN_DESCRIPTION": "Acceda a la configuración de ACL de su dispositivo",
      "ACL_IPV6": "ACL IPv6",
      "ACL_IPV6_DESCRIPTION": "Acceda a la configuración de ACL IPv6 de su dispositivo",
      "ADVANCED": "Avanzado",
      "ADVANCED_DESCRIPTION": "Configuraciones avanzadas",
      "BACKUP_RESTORE": "Copia de seguridad",
      "BACKUP_RESTORE_DESCRIPTION": "Realice una copia de seguridad de su configuración actual o restaure una copia de seguridad anterior",
      "BRIDGING": "Bridging",
      "BRIDGING_DESCRIPTION": "Configuración de parámetros de bridge",
      "CHANGE_TO_FIRMWARE_VERSION": "Cambiar la versión",
      "CHANGE_TO_FIRMWARE_VERSION_DESCRIPTION": "Cambiar a la versión de firmware de copia de seguridad",
      "DASHBOARD": "Panel",
      "DASHBOARD_DESCRIPTION": "Ver el estado de su dispositivo",
      "DATETIME": "Zona horaria",
      "DYNAMIC_DNS": "DNS Dinámico",
      "DYNAMIC_DNS_DESCRIPTION": "Configurar un servicio de DNS dinámico",
      "DATETIME_DESCRIPTION": "Administre la configuración de la zona horaria de su dispositivo",
      "DOMAIN_BLOCKING": "Bloqueo de dominio",
      "DOMAIN_BLOCKING_DESCRIPTION": "Configuración de bloqueo de dominio",
      "FACTORY_RESET": "Restaurar",
      "FACTORY_RESET_DESCRIPTION": "Restablece tu dispositivo a la configuración de fábrica",
      "FIREWALL": "Firewall",
      "FIREWALL_ALG": "ALG",
      "FIREWALL_ALG_DESCRIPTION": "Administra la configuración de ALG",
      "FIREWALL_DMZ": "DMZ",
      "FIREWALL_DMZ_DESCRIPTION": "Administra la configuración de DMZ",
      "FIREWALL_DESCRIPTION": "Configurar la información del firewall",
      "FIREWALL_WLAN_ACCESS_CONTROL": "Control de acceso WLAN",
      "WLAN_ACCESS_CONTROL_DESCRIPTION": "Permitir/denegar la conexión del dispositivo a través de WLAN",
      "GLOBAL_ROUTING": "Enrutamiento global",
      "GLOBAL_ROUTING_DESCRIPTION": "Ver la tabla de enrutamiento",
      "IGMP_MLD_SNOOPING": "IGMP/MLD Snooping",
      "IGMP_MLD_SNOOPING_DESCRIPTION": "Activación y desactivación de IGMP/MLD Snooping",
      "INFORMATION": "Términos de uso",
      "INFORMATION_DESCRIPTION": "Revisa los términos de uso",
      "IP_PORT_FILTER": "Filtrado de IP/Puertos",
      "IP_PORT_FILTER_DESCRIPTION": "Configuración de filtro de puerto/IP",
      "IPV6": "IPv6",
      "IPV6_DESCRIPTION": "Administrar la configuración avanzada de IPv6",
      "IPV6_DHCP": "DHCPv6",
      "IPV6_DHCP_DESCRIPTION": "Configuración de DHCPv6",
      "IPV6_STATIC_ROUTE": "Rutas Estáticas IPv6",
      "IPV6_STATIC_ROUTE_DESCRIPTION": "Configuración de rutas Estáticas IPv6",
      "LEDS": "Configuración de LEDs",
      "LEDS_DESCRIPTION": "Configurar los LEDs del dispositivo",
      "LOGOUT": "Cerrar sesión",
      "LOGOUT_DESCRIPTION": "Cerrar la sesión actual",
      "LOOP_BACK_DETECT": "Detección de bucle",
      "LOOP_BACK_DETECT_DESCRIPTION": "Administre la configuración de detección de bucles de su dispositivo",
      "MAC_FILTER": "Filtrado de MAC",
      "MAC_FILTER_DESCRIPTION": "Configuración del filtro MAC",
      "MESH_DESCRIPTION": "Habilitar InMesh",
      "NEIGHBOUR_HOSTS": "Dispositivos vecinos",
      "NEIGHBOUR_HOSTS_DESCRIPTION": "Ver dispositivos vecinos de su dispositivo",
      "NETWORK": "Red",
      "NETWORK_DESCRIPTION": "Administre redes Wi-Fi, Internet y LAN",
      "NETWORK_WAN": "WAN",
      "NETWORK_WAN_DESCRIPTION": "Administrar la configuración de la red WAN",
      "NETWORK_LAN": "LAN",
      "NETWORK_LAN_DESCRIPTION": "Administrar la configuración de la red LAN",
      "NETWORK_WIFI": "Wi-Fi",
      "NETWORK_WIFI_DESCRIPTION": "Administrar la configuración de la red WiFi",
      "NETWORK_MESH": "Mesh",
      "NETWORK_MESH_DESCRIPTION": "Administrar la configuración de la red Mesh",
      "IPV6_RADVD": "RADVD",
      "IPV6_RADVD_DESCRIPTION": "Configuración de RADVD",
      "IPV6_PROXY_MLD": "Proxy MLD",
      "IPV6_PROXY_MLD_DESCRIPTION": "Configuración de proxy MLD",
      "IPV6_ENABLE": "Protocolo IPv6",
      "IPV6_ENABLE_DESCRIPTION": "Activar / Desactivar el protocolo IPv6",
      "IPV6_PREFIX_DELEGATION": "Prefijo Delegado",
      "IPV6_PREFIX_DELEGATION_DESCRIPTION": "Configurar prefijo",
      "VOIP_HISTORY_CALL": "Historial de llamadas",
      "VOIP_HISTORY_CALL_DESCRIPTION": "Vista del historial de llamadas",
      "VOIP_DIAL_PLAN": "Plan de marcación",
      "VOIP_DIAL_PLAN_DESCRIPTION": "Configuración del plan de marcación",
      "VOIP_ADVANCED_SIP": "SIP",
      "VOIP_ADVANCED_SIP_DESCRIPTION": "Configuración avanzada de SIP",
      "VOIP_ACCOUNT_CONFIG": "Configuraciones de la cuenta",
      "VOIP_ACCOUNT_CONFIG_DESCRIPTION": "Configurar una línea VoIP",
      "VOIP_CODEC": "Codec",
      "VOIP_CODEC_DESCRIPTION": "Configuración de Códec VoIP",
      "PING": "Ping",
      "PING_DESCRIPTION": "Haga ping a cualquier IP/Host",
      "PON": "PON",
      "PON_COUNTERS": "Contadores",
      "PON_COUNTERS_DESCRIPTION": "Ver métricas del puerto PON",
      "PON_STATS": "Estadísticas",
      "PON_STATS_DESCRIPTION": "Ver estadísticas de puertos PON",
      "PON_SETTINGS": "Ajustes",
      "PON_SETTINGS_DESCRIPTION": "Configuración del puerto PON",
      "PON_STATUS": "Estado",
      "PON_STATUS_DESCRIPTION": "Ver estado del puerto PON",
      "PON_EPON_LLID_STATUS": "EPON estado LLID",
      "PON_EPON_LLID_STATUS_DESCRIPTION": "Ver el estado de la tabla LLID",
      "PON_GPON_STATUS": "Estado GPON",
      "PON_GPON_STATUS_DESCRIPTION": "Ver información de estado de GPON",
      "PON_GEM_PORT_STATISTICS": "Tabla GEM",
      "PON_GEM_PORT_STATISTICS_DESCRIPTION": "Ver información de la tabla GEM",
      "PON_DESCRIPTION": "Administre la interfaz PON de su dispositivo",
      "PORT_FORWARD": "Asignación de puertos",
      "PORT_FORWARD_DESCRIPTION": "Configura la asignación de puertos de su dispositivo",
      "PROXY_IGMP": "Proxy IGMP",
      "PROXY_IGMP_DESCRIPTION": "Configuración del proxy IGMP",
      "QOS_CLASSIFICATION": "Clasificación de QoS",
      "QOS_CLASSIFICATION_DESCRIPTION": "Configuración de Clasificación de QoS",
      "QOS_TRAFFIC_CONTROL": "Control de tráfico de QoS",
      "QOS_TRAFFIC_CONTROL_DESCRIPTION": "Configuración de control de tráfico de QoS",
      "REBOOT": "Reiniciar",
      "REBOOT_DESCRIPTION": "Reinicia tu dispositivo",
      "RIP_CONFIG": "Configuración de RIP",
      "RIP_CONFIG_DESCRIPTION": "Habilite y ajuste la configuración de RIP",
      "SERVICE": "Servicios",
      "SERVICE_DESCRIPTION": "Administra servicios desde su dispositivo",
      "STATIC_ROUTING": "Rutas Estáticas",
      "STATIC_ROUTING_DESCRIPTION": "Configuración y edición de rutas estáticas",
      "SYSTEM": "Sistema",
      "SYSTEM_DESCRIPTION": "Actualizar y realizar acciones de mantenimiento",
      "TOOLS": "Herramientas",
      "TOOLS_DESCRIPTION": "Accede a funciones útiles para tu uso diario",
      "TR069_CONFIG": "Configuración de TR-069",
      "TR069_CONFIG_DESCRIPTION": "Realice la configuración de TR-069",
      "TR069_INFORM": "Información de TR-069",
      "TR069_INFORM_DESCRIPTION": "Acceder a información de diagnóstico sobre el TR-069",
      "TRACEROUTE": "Traceroute",
      "TRACEROUTE_DESCRIPTION": "Consulta las rutas de salida de su dispositivo",
      "UPDATE_FIRMWARE": "Actualizar firmware",
      "UPDATE_FIRMWARE_DESCRIPTION": "Actualice su versión de firmware",
      "UPNP": "UPnP",
      "UPNP_DESCRIPTION": "Configuración UPnP",
      "URL_BLOCKING": "Bloqueo de URL",
      "URL_BLOCKING_DESCRIPTION": "Configuración de bloqueo de URL",
      "USER_AND_PASSWORD": "Nombre de usuario y contraseña",
      "USER_AND_PASSWORD_DESCRIPTION": "Cambiar la información de acceso al enrutador",
      "VOIP": "VoIP",
      "VOIP_DESCRIPTION": "Administre la configuración de VoIP de su dispositivo",
      "WIFI_DESCRIPTION": "Salvar red WiFi",
      "WPS_DESCRIPTION": "WPS comenzó con éxito",
      "WPS_REQUIREMENT": "Requisito para WPS",
      "IPV6_ROUTE_LIST": "Lista de rutas",
      "IPV6_ROUTE_LIST_DESCRIPTION": "Lista de rutas IPv6",
      "IPV6_IP_PORT_FILTER": "Filtro IP/Puerto",
      "IPV6_IP_PORT_FILTER_DESCRIPTION": "Configuración de filtro IP/Puerto",
      "ADVANCED_QOS_POLICY": "Política de QoS",
      "ADVANCED_QOS_POLICY_DESCRIPTION": "Configuración de la política de QoS",
      "ADVANCED_QOS_POLICY_ENABLE": "Habilitar política de QoS",
      "AUTOMATIC_MAINTENANCE": "Mantenimiento automático",
      "AUTOMATIC_MAINTENANCE_DESCRIPTION": "Configurar el mantenimiento automático del sistema"
    },
    "modal": {
      "answer": {
        "CONTINUE": "Continuar",
        "NO": "No",
        "POLITE_NO": "No, gracias",
        "UNDERSTOOD": "Ok, entendí",
        "YES": "Sí",
        "YES_CHANGE": "Sí, quiero cambiar"
      },
      "question": {
        "WISH_TO_DELETE": "¿Está seguro de que desea eliminar este elemento?"
      }
    },
    "network": {
      "label": {
        "NETWORK": "Red"
      },
      "lan": {
        "error": {
          "TRY_DELETE_ERROR": "Error al intentar eliminar el elemento de la lista.",
          "ACL_ADMIN_MUST_BE_DISABLE_TO_AVIOD_LOST_OF_ACCESS": "ACL Admin debe estar deshabilitado para evitar la pérdida de acceso.",
          "ADDRESS_OUT_OF_RANGE": "Dirección IP fuera del rango del servidor DHCP",
          "COULD_NOT_ADD_LEASE": "Se produjo un error al intentar agregar la reserva.",
          "COULD_NOT_EDIT_LEASE": "Se produjo un error al intentar editar la reserva.",
          "DNS_REPEATED": "Las direcciones DNS no se pueden duplicar",
          "ERROR_MESSAGE_CUSTOM": "Se produjo un error al intentar eliminar lo(s) registro(s).",
          "ERROR_TEXT_MESSAGE_CUSTOM": "Borrando registro(s)...",
          "IP_ADDRESS_ALREADY_IN_USE": "Dirección IP ya utilizada",
          "MAC_ADDRESS_ALREADY_IN_USE": "Dirección MAC ya utilizada",
          "MAX_LEASES_REACHED": "No fue posible agregar una nueva reserva porque se alcanzó el número máximo de reservas.",
          "MAX_NUMBER_OF_RULES_CUSTOM": "Se ha alcanzado el número máximo de registros."
        },
        "label": {
          "CONNECTED": "Conectado",
          "DHCP_RANGE_END": "Dirección IP final",
          "DHCP_RANGE_START": "Dirección IP inicial",
          "DHCP_SERVER": "Servidor DHCP",
          "DISCONNECTED": "Desenchufado",
          "DNS1": "DNS 1",
          "DNS2": "DNS 2",
          "ENABLED": "Habilitar",
          "IP": "IP",
          "IP_ADDRESS": "Dirección IP",
          "IPV4_ADDRESS": "Dirección IPv4",
          "LEASE": "registro",
          "MAC": "MAC",
          "MAC_ADDRESS": "Dirección MAC",
          "NETMASK": "Máscara de subred",
          "NO_RULE_ON_REGISTER_MESSAGE_CUSTOM": "No hay registros en el registro.",
          "RESERVATION": "Reservas de direcciones IP",
          "STATUS": "Estado",
          "TOTAL_RULES_LABEL_CUSTOM": "Registros totales",
          "DNS_MANUALLY": "DNS manuales"
        },
        "success": {
          "SUCCESS_TEXT_MESSAGE_CUSTOM": "Entrada(s) de registro(s) eliminada(s) con éxito."
        },
        "title": {
          "IP_LEASES": "Reservas de direcciones IP",
          "ADD_NEW_IP_LEASE": "Agregar nueva reserva de direcciones IP",
          "LAN_PAGE": "Configuración de la interfaz LAN",
          "LAN": "LAN",
          "DHCP_ACTIVE_CLIENTS": "Ver clientes activos",
          "ACTIVE_CLIENTS": "Clientes DHCP activos"
        },
        "info": {
          "NO_DHCP_CLIENTS": "No hay clientes DHCP registrados.",
          "NO_IP_LEASE": "No hay direcciones IP reservadas."
        },
        "warning": {
          "ADDRESS_OUT_OF_RANGE": "fuera del nuevo rango de red configurado.",
          "CONFIRM_EXCLUSION": "¿Realmente desea eliminar el elemento?",
          "DHCP_STATIC_LEASE": "Las reservas de IP solo tendrán efecto si el servidor DHCP está habilitado.",
          "LEASE_DELETE": "Esta regla se eliminará de forma permanente.",
          "MAY_BE_NECESSARY_TO_RECONNECT": "Es posible que deba volver a conectarse a la red de su enrutador.",
          "ON_SAVE_PLURAL": "Al salvar, estos",
          "ON_SAVE_SINGULAR": "Al salvar, esto",
          "PERMANENT_DELETE_WARNING": "Los elementos seleccionados se eliminarán de forma permanente.",
          "RECONNECT_TO_NETWORK": "Vuelva a conectarse a la red de su enrutador utilizando la nueva dirección IP.",
          "REMOVED_PLURAL": "eliminados",
          "REMOVED_SINGULAR": "eliminado",
          "RESERVATION_PLURAL": "reservas",
          "RESERVATION_SINGULAR": "reserva",
          "WAIT_FOR_APPLY": "Espere mientras aplicamos su configuración.",
          "WILL_BE_PLURAL": "estarán",
          "WILL_BE_SINGULAR": "será",
          "WISH_TO_CONTINUE": "¿Desea continuar?",
          "YOU_GOT": "Tú tienes"
        }
      },
      "mesh": {
        "label": {
          "ADD_NEW_ROUTER": "Añadir nuevo enrutador",
          "TRY_AGAIN": "Inténtalo de nuevo",
          "ADD_ROUTER": "Agregar enrutador",
          "CONNECT_WITH": "Conectado a",
          "IP": "Dirección IP",
          "CONNECTED_DEVICES": "Dispositivos conectados",
          "CONTINUE": "Continuar",
          "CURRENT_NODE": "Nodo actual",
          "DATE_TIME": "Fecha y hora",
          "DELETE_NODE": "Eliminar nodo",
          "DEVICE": "dispositivo",
          "DEVICE_FIRSTUP": "Dispositivo",
          "ENABLE": "Permitir",
          "EXCELENT_CABLE": "Excelente",
          "PHYSICAL_ENV": "Entorno físico",
          "FINISH": "Finalizar",
          "FIRMWARE_VERSION": "Versión de firmware",
          "INMESH": "INMESH",
          "ENABLE_INMESH": "Habilitar red INMESH",
          "IPV6_ADDRESS": "Dirección IPv6",
          "IPV6_LAN": "IPv6 (LAN)",
          "LOCALIZATION": "Localización",
          "MAC_LAN": "MAC de LAN",
          "MAIN_GATEWAY": "Principal (Puerta de enlace)",
          "MAIN_NODE": "Nodo principal",
          "MESH_NODES": "Nodos INMESH",
          "MODEL": "Modelo",
          "MY_INMESH_NETWORK": "Mi red InMesh",
          "NEW_ROUTER": "Nuevo enrutador",
          "OFFICE": "Ej.: Oficina",
          "REMOVE": "Para eliminar",
          "ROUTER": "Enrutador",
          "RUNNING_TIME": "Tiempo activo",
          "SECONDARY": "Secundario",
          "SECONDARY_NODE": "Nodo secundario",
          "SIGNAL": "Señal",
          "SIGNAL_QUALITY": "Calidad de la señal",
          "START": "Comienzo",
          "STATUS": "Estado",
          "STATUS_NODE": "Estado del nodo",
          "TX_DOWNLOAD": "Tasa de descarga",
          "TX_UPLOAD": "Tasa de carga",
          "TYPE": "Tipo",
          "VIEW": "Para ver"
        },
        "message": {
          "info": {
            "SUCCESS": "Roteador adicionado com sucesso! Se o novo equipamento não aparecer na lista em 5 minutos reinicie esse dispositivo.",
            "AMPLIFY_YOUR_COVERAGE_ARE_CLICK_BUTTON_BELOW": "¡Haga clic en el botón de abajo y comience a expandir su área de cobertura!",
            "WPS_DISABLED": "WPS está deshabilitado para la red raíz, por lo que no se pueden agregar nuevos enrutadores.",
            "CHECK_IF_ROUTER_IS_ON": "Asegúrese de que su nuevo enrutador esté encendido y espere un momento, por favor...",
            "CONDITIONS_FOR_MESH": "La red mesh solo se puede habilitar como Master Nodo si la red wi-fi está configurada como de doble banda, con una contraseña definida y con ambas frecuencias de radio activadas.",
            "CONFIG_WIRELESS_NETWORK": "Configurar red inalámbrica y radio",
            "CONFIGURING_NEW_ROUTER": "Configurando tu nuevo enrutador",
            "CONFIRM_CONTROLLER_1": "Al habilitar este dispositivo como nodo principal, se convertirá en el controlador de una nueva red de malla,",
            "CONFIRM_CONTROLLER_2": "necesitando sincronizar los otros dispositivos en la red.",
            "CONNECTING_NEW_ROUTER": "Conexión de su nuevo enrutador",
            "DEVICE_SYNCING_WARNING": "El dispositivo está en proceso de sincronización de la red Mesh.",
            "DEVICE_SYNCING_WAIT": "Espera hasta que termine, lo que puede demorar hasta 5 minutos.",
            "ENJOY_NEW_COVERED_AREA": "Su enrutador está listo para colocarse en la habitación deseada. Disfruta de su nueva área de cobertura.",
            "FINAL_STEP": "Estamos en la etapa final. Por favor, espera un momento más...",
            "FINISHING_SYNC": "Finalizando la sincronización...",
            "INSTRUCTION_1": "Haga clic en el botón de abajo para iniciar el proceso de agregar su producto",
            "INSTRUCTION_2": "Coloque y encienda su nuevo enrutador junto al nodo principal",
            "INSTRUCTION_3": "Al final del proceso, apague y coloque su enrutador en la habitación deseada",
            "MAIN_ROUTER_INFO": "El router en modo nodo primario será el encargado de gestionar la red mesh, mientras que los nodos secundarios se conectarán utilizando las configuraciones de un nodo primario.",
            "NO_ROUTER_ADDED": "No se agregaron enrutadores.",
            "NO_ROUTER_ADDED_AT_THIS_MOMENT": "Actualmente no tiene un enrutador agregado.",
            "RADIO0_CONDITIONS_FOR_MESH": "Se debe seleccionar un canal estático para la radio de 5 GHz para que se pueda habilitar la red de malla.",
            "ROUTER_EXAMPLES": "Agregar nuevo nodo, plantillas disponibles:",
            "ROUTER_POSITION": "Ingrese la ubicación donde se colocará su enrutador para facilitar la organización de su red mesh.",
            "SEARCHING_ROUTER": "Buscando su nuevo enrutador",
            "SYNCING": "Sincronizando",
            "SYNCING_INFO": "El dispositivo entrará en el estado de sincronización y permanecerá así durante 5 minutos o hasta que se sincronice con otro dispositivo.",
            "SYNCRHONIZE": "Sincronizar",
            "THIS_PROCEDURE_CAN_TAKE_SEVERAL_MINUTES": "Este procedimiento puede tardar unos minutos. Por favor, no cierre esta ventana.",
            "VAPS_CONDITIONS_FOR_MESH": "Para habilitar la red de malla, los VAP activos deben tener seleccionada la opción de seguridad y completar la contraseña.",
            "WAIT_A_MINUTE_PLEASE": "Por favor, espere un momento...",
            "WAIT_WHILE_WE_DELETE_NODE": "Espere mientras eliminamos el nodo de malla.",
            "WISH_TO_GO_ON": "Quieres proceder?",
            "WPA2_CONDITIONS_FOR_MESH": "La red InMesh actualmente solo admite el modo de cifrado WPA2. Para habilitarlo, cambie el modo de cifrado seleccionado en la red raíz.",
            "YOU_ARE_GOING_TO_BE_REDIRECTED_TO_LOGIN": "Cuando haya terminado, será llevado a la pantalla de inicio."
          },
          "error": {
            "COULD_NOT_CONNECT": "Não foi possível se conectar ao novo roteador.",
            "DOES_NOT_SUPORT_MODE": "Este dispositivo no es compatible con este modo."
          }
        },
        "title": {
          "INMESH_NODE_INFO": "Información sobre el nodo INMESH",
          "ADVANCED_INFO": "Informacion avanzada",
          "CONNECTION_STATUS": "Estado de conexión",
          "NEW_MESH_ROUTER": "Nuevo enrutador mesh",
          "PRODUCT_INFO": "Información del producto",
          "USE_INFO": "Información de uso"
        }
      },
      "wan": {
        "label": {
          "ACTIVATE_NAPT": "Habilitar NAPT",
          "ACTIVATE_VLAN": "Habilitar VLAN",
          "REQUEST_PREFIX": "Solicitar prefijo",
          "CONNECTION_TYPE": "Tipo de conexión",
          "DNS_SERVER_1": "Servidor DNS 1",
          "DNS_SERVER_2": "Servidor DNS 2",
          "ENABLE_MULTICAST_VLAN": "Habilitar VLAN de multidifusión (avanzado)",
          "GATEWAY_IPV4": "Puerta de enlace IPv4",
          "INTERFACE": "Interfaz",
          "IP_ADDRESS": "Dirección IP local",
          "IPV6_ADDRESS": "Dirección IPv6",
          "IPV6_GATEWAY": "Puerta de enlace IPv6",
          "IPV6_ADDR_MODE": "Modo de dirección IPv6",
          "IPV6_ADDR_MODE_SLAAC": "Stateless DHCPv6 (SLAAC)",
          "MODE": "Modo",
          "MTU": "MTU",
          "MULTICAST_VLAN_ID": "VLAN de Multicast",
          "NETMASK": "Máscara de subred",
          "PASSWORD": "Contraseña",
          "REQUEST_DNS": "Solicitar DNS",
          "SERVER": "Servidor",
          "SERVICE_NAME": "Nombre del servicio",
          "USER": "Usuario",
          "IPV6_STATUS": "IPv6/Estado",
          "VLAN_ID": "VLAN ID"
        },
        "message": {
          "error": {
            "DNS_IPV4_IS_EMPTY": "Debe proporcionar al menos una dirección DNS IPv4",
            "DNS_IPV4_REQUIRED": "En el modo de conexión WAN estática, no se puede seleccionar DNS",
            "DNS_IPV6_IS_EMPTY": "Debe proporcionar al menos una dirección DNS IPv6",
            "ERROR_SERVER": "Error del servidor: ",
            "ERROR_SERVER_RETRIEVE": "No se puede recuperar la información de la WAN",
            "GATEWAY_AND_IPV4_ADDRESS_MUST_BE_DIFFERENT": "La puerta de enlace y la dirección IPv4 no pueden ser iguales",
            "GATEWAY_AND_IPV4_ARE_NOT_IN_SAME_SUBNET": "La puerta de enlace y la dirección IPv4 no pertenecen a la misma subred",
            "IPV4_ADDRESS_CANNOT_BE_IN_THE_SAME_SUBNET_OF_LAN": "La dirección IPv4 no puede estar en la misma subred que la LAN",
            "IPV4_GATEWAY_IS_EMPTY": "Falta la dirección de puerta de enlace IPv4",
            "IPV6_ADDRESS_AND_GATEWAY_CANNOT_BE_EQUALS": "La puerta de enlace y la dirección IPv6 no pueden ser iguales",
            "IPV6_GATEWAY_IS_EMPTY": "Falta la dirección de puerta de enlace IPv6",
            "MUST_HAVE_IPV4_OR_IPV6": "Para una conexión estática, debe proporcionar información de IPv4 y/o IPv6",
            "ONLY_ONE_WAN_WITH_DISABLED_VLAN_IS_ENABLED": "Ya existe una WAN con VLAN deshabilitada",
            "VLAN_ID_ALREADY_USED": "Ya existe una WAN con este ID",
            "WAN_INTERFACE_NOT_FOUND": "Interfaz WAN no encontrada",
            "VLAN_ALREADY_IN_USE": " hay otra conexión con la misma configuración de VLAN",
            "GATEWAY_AND_IPV4_SUBNET_ERROR": "La puerta de enlace IPv4 y la dirección IP local deben pertenecer a la misma subred"
          },
          "NO_WANS_REGISTERED": "No hay una WAN configurada.",
          "warning": {
            "DISABLED_PORTS": "ATENCIÓN: Los puertos com '*' ya se están utilizando en otras WAN.",
            "MAX_WANS_REACHED": "Se ha alcanzado el número máximo de WAN",
            "MUST_CHOOSE_AT_LEAST_ONE_PORT": "Elija al menos un puerto",
            "IPV6_PROTOCOL_ENABLE": "Para poder configurar los ajustes, habilite IPV6 en ",
            "IPV6_OPTIONS_DISABLED": "Las opciones de protocolo relacionadas con IPv6 están deshabilitadas porque IPv6 no está habilitado.",
            "NETWORK_MODE_CHANGED": "Al modificar el modo, es posible que sea necesario reconfigurar otras funciones vinculadas a esta interfaz WAN y es posible que sus configuraciones actuales no funcionen correctamente.",
            "NETWORK_PROTOCOL_CHANGED": "Al modificar el protocolo, es posible que la configuración de otras funciones adjuntas a esta interfaz WAN no funcione correctamente."
          }
        },
        "title": {
          "INSERT_NEW_WAN": "Insertar WAN",
          "IPV4_CONFIGURATION": "Configuración de IPv4",
          "IPV6_CONFIGURATION": "Configuración de IPv6",
          "PORT_MAPPING": "Asignación de puertos",
          "PPPOE_CONFIGURATION": "Configuración de PPPoE",
          "STANDARD_INTERFACE": "Interfaz estándar",
          "UPDATE": "Actualizar",
          "WAN": "WAN",
          "WAN_LIST": "Lista de WANs"
        },
        "warning": {
          "PERMANENT_DELETE_WARNING": "La WAN seleccionada se eliminará de forma permanente y cualquier característica que haga referencia a ella se desactivará o se eliminarán sus respectivas reglas."
        }
      },
      "wifi": {
        "radio": {
          "WARNING": "Al cambiar la configuración de las radios, las redes WiFi creadas se reconfigurarán automáticamente."
        },
        "label": {
          "IEEE_STD": "Tipo de banda IEEE",
          "BANDWIDTH": "Ancho de banda",
          "BROADCAST_CHECKBOX": "Divulgar el nombre de la red",
          "CHANNEL": "Canal",
          "CHANNEL_EXTENSION": "Canal de extensión",
          "CREATE_NETWORK": "Crear red Wi-Fi",
          "CREATE_WIFI_NETWORK": "Crear red wi-fi",
          "CURRENT_CHANNEL": "Canal actual",
          "DUAL_BAND": "Dual band (2,4GHz y 5GHz)",
          "ENABLE_MU_MIMO": "Habilitar MU-MIMO",
          "OPEN_WLAN_CHECKBOX": "Red wifi sin contraseña",
          "PASSWORD": "Contraseña de red",
          "SECURITY": "Seguridad",
          "SECURITY_MODE_CRYPTO": "Sin encriptación",
          "SPLIT_DUAL": "Separar la red de doble banda",
          "SSID_5GHZ_NAME": "Nombre de la red de 5GHz",
          "SSID_NAME": "Nombre de red",
          "VAP_COUNTER_2_4GHZ": "VAPs en 2,4 GHz",
          "VAP_COUNTER_5GHZ": "VAPs en 5 GHz",
          "WPA2_RECOMMENDED": "WPA2 (recomendado)",
          "WPS_CHECKBOX": "Habilitar el botón WPS"
        },
        "message": {
          "delete": {
            "DELETE_CONFIRM": "¿Desea confirmar la eliminación?",
            "DELETE_ERROR": "Ocurrió un error al intentar eliminar la red wireless.",
            "DELETE_SUCCESS": "Red wireless eliminada con éxito.",
            "DELETE_WARNING": "Esta red wireless se eliminará de forma permanente.",
            "DELETING": "Excluyendo..."
          },
          "info_popup": {
            "BROADCAST_CHECKBOX": "Al deshabilitar esta opción, el nombre no aparecerá en la lista de redes Wi-Fi de su dispositivo. Entonces, para conectarse, deberá especificar el nombre y la contraseña en la configuración de red adicional.",
            "DUALBAND_CHECKBOX": "Al activar esta opción, la red operará en ambas frecuencias con los mismos parámetros definidos en este formulario.",
            "MESH_EDIT": "Esto no se puede cambiar para que funcione la red Mesh. Si desea deshacer la red Mesh, acceda al menú Mesh.",
            "MESH_OFF": "Esta opción debe permanecer DESHABILITADA para que funcione la red Mesh. Si desea deshacer la red Mesh, acceda al menú Mesh.",
            "MESH_ON": "Esta opción debe permanecer ACTIVADA para que funcione la red Mesh. Si desea deshacer la red Mesh, acceda al menú Mesh.",
            "SPLIT_DUAL": "Al habilitar esta opción, será posible definir diferentes nombres de red para las frecuencias de 2,4 GHz y 5 GHz. De esta forma, las redes Wi-Fi aparecerán en diferentes elementos de la lista y podrán editar sus parámetros por separado.",
            "WPS_CHECKBOX": "Habilitando esta opción, se puede realizar una conexión simplificada con dispositivos que tengan la función WPS. Entonces, para conectarse, deberá presionar el botón WPS en este enrutador y en el otro dispositivo."
          },
          "warning": {
            "EXPLAINING_BROADCAST_WPS": "Deshabilitar la divulgación del nombre de la red también deshabilitará WPS. ¿Desea continuar?",
            "EXPLAINING_MESH_VAP": "VAP0 para 2,4GHz y/o 5GHz Radio se administrará automáticamente.",
            "EXPLAINING_PASSWORD_WPS": "Es posible que algunos clientes no puedan conectarse a través de WPS cuando hay un espacio en blanco que precede o sigue directamente a la contraseña, por ejemplo, \" abcd\" o \"abcd \".",
            "EXPLAINING_WIFI_SECURITY": "No se seleccionó ninguna opción de seguridad. Puede ser peligroso.",
            "EXPLAINING_WPA3_WPS": "La opción de cifrado WPA3 no es compatible con WPS, por lo que se desactivará. ¿Desea continuar?",
            "EXPLAINING_WPS_INFO": "Debe ejecutar WPS en el dispositivo cliente en 2 minutos.",
            "FORBIDDEN_PREFIX": "Prefijo no permitido",
            "MAX_WIFI": "Se ha alcanzado el número máximo de redes Wi-Fi.",
            "NO_WIFI_NETWORKS": "No hay redes WiFi disponibles. Compruebe si hay radios configurados y vuelva a intentarlo.",
            "RESERVED_NAME": "Nombre reservado",
            "SAME_SSID": "Este nombre ya está siendo utilizado",
            "RESTART_ROUTER": "Es posible que deba volver a conectarse a la red de su enrutador.",
            "SAME_SPLIT_SSID": "Los nombres de red (SSIDs) deben ser diferentes",
            "SAVE_CONFIG": "Espere mientras aplicamos su configuración.",
            "SAVE_CONN": "Si está accediendo al enrutador a través de Wi-Fi, para que podamos continuar, conéctese a la red "
          }
        },
        "tip": {
          "SHOW_CONNECTED_CLIENT": "Ver clientes conectados",
          "EDIT_WIFI": "Editar red Wi-Fi",
          "DELETE_WIFI": "Eliminar red WiFi"
        },
        "title": {
          "RADIO": "Radio",
          "RADIO_TITLE": "Configuración de radio",
          "WIFI_EDIT": "Editar red Wi-Fi",
          "WIFI_CONFIG": "Ajustes de red Wi-Fi",
          "WIFI_TITLE": "Redes disponibles",
          "WPS_TITLE": "Conectar por WPS"
        },
        "status": {
          "title": {
            "SHOW_WLAN_STATUS": "Mostrar estado WLAN",
            "WLAN_STATUS_5G": "Estado de WLAN 5 GHz",
            "WLAN_STATUS_2_4G": "Estado de WLAN 2.4 GHz",
            "WLAN_STATUS": "Radio",
            "WLAN_STATUS_VAP": "Puntos de acceso virtuales (VAP)"
          },
          "messages": {
            "WLAN_NOT_CONFIGURED": "Red WLAN no configurada.",
            "WLAN_VAP_NOT_CONFIGURED": "Puntos de acceso virtuales no configurados.",
            "UPDATE_LIST": "Actualizar",
            "SHOW_2_4G": "Ver radio de 2.4 GHz",
            "SHOW_5G": "Ver radio de 5 GHz"
          },
          "label": {
            "CRYPTOGRAPHY": "Criptografía"
          }
        },
        "connected_devices": {
          "title": {
            "CONNECTED_TO_NETWORK": "Dispositivos conectados a la red",
            "_5GHZ_FREQUENCY": "Frecuencia de 5GHz",
            "_2_4GHZ_FREQUENCY": "Frecuencia de 2.4GHz"
          },
          "info": {
            "NO_DEFICES_CONNECTED": "No hay dispositivos conectados a esta red inalámbrica."
          },
          "column_header": {
            "HOSTNAME": "Nombre",
            "MAC": "Dirección MAC",
            "TX_BYTES": "Bytes Tx",
            "RX_BYTES": "Bytes Rx",
            "TX_RATE": "Tasa Tx (MBps)"
          }
        }
      }
    },
    "pon": {
      "counters": {
        "label": {
          "BYTES_RECEIVED": "Bytes enviados",
          "BYTES_SENT": "Bytes enviados",
          "FEC_ERRORS": "Errores FEC",
          "HEC_ERRORS": "Errores de HEC",
          "PACKAGES_BROADCAST_RECEIVED": "Paquetes de broadcast recibidos",
          "PACKAGES_BROADCAST_SENT": "Paquetes de broadcast enviados",
          "PACKAGES_DROPPED": "Paquetes desechados",
          "PACKAGES_MULTICAST_RECEIVED": "Paquetes de multicast recibidos",
          "PACKAGES_MULTICAST_SENT": "Paquetes de multicast enviados",
          "PACKAGES_PAUSE_RECEIVED": "Paquetes de pausa recibidos",
          "PACKAGES_PAUSE_SENT": "Paquetes de pausa enviados",
          "PACKAGES_RECEIVED": "Paquetes recibidos",
          "PACKAGES_SENT": "Paquetes enviados",
          "PACKAGES_UNICAST_RECEIVED": "Paquetes de unicast recibidos",
          "PACKAGES_UNICAST_SENT": "Paquetes unicast enviados"
        },
        "title": {
          "COUNTERS": "Contadores"
        }
      },
      "epon_llid": {
        "message": {
          "error": {
            "COULD_NOT_GET_INFO": "No se puede recuperar la información de estado LLID"
          }
        },
        "title": {
          "EPON_LLID_STATUS": "EPON estado LLID"
        }
      },
      "gem_port_statistics": {
        "error": {
          "COULD_NOT_GET_INFO": "No se puede recuperar la información de los puertos GEM."
        },
        "label": {
          "DIRECTION": "Dirección",
          "FLOW_ID": "ID de flujo",
          "GEM_PORT_ID": "ID de puerto GEM",
          "PACKAGE_COUNT": "Recuento de paquetes"
        },
        "title": {
          "GEM_PORT_STATISTICS": "Estadísticas de los puertos GEM"
        }
      },
      "gpon_status": {
        "label": {
          "ONU_ID": "ONU ID",
          "ONU_STATE": "ONU state",
          "ONU_STATE_DESC": "ONU state desc"
        },
        "message": {
          "error": {
            "COULD_NOT_RETRIEVE_DATA": "No se puede recuperar la información del estado de GPON"
          }
        },
        "title": {
          "GPON_STATUS": "Información de estado GPON"
        }
      },
      "label": {
        "COUNTERS": "Contadores",
        "EPON_LLID_STATUS": "EPON estado LLID",
        "GEM_TABLE": "Tabla GEM",
        "GPON_STATUS": "Estado GPON",
        "STATISTICS": "Estadísticas"
      },
      "settings": {
        "label": {
          "EPON_ONU_MODEL": "EPON ONU model",
          "GPON_ID": "GPON ID",
          "GPON_ONU_MODEL": "GPON ONU model",
          "HARDWARE_VERSION": "Versión del hardware",
          "LOID": "Loid",
          "LOID_PASSWORD": "Contraseña LOID",
          "OLT_PROFILE": "Perfil de OLT",
          "ONU_MAC": "MAC de la ONU",
          "PLOAM_PASSWORD": "Contraseña PLOAM",
          "SERIAL_NUMBER": "Numero de serie"
        },
        "olt_profile": {
          "INTELBRAS": "Intelbras",
          "HUAWEI": "Huawei",
          "ZTE": "ZTE",
          "CUSTOM": "Personalizado",
          "FH_CUSTOM_1": "FH Custom 1",
          "HUAWEI_NOKIA": "Huawei/Nokia"
        },
        "title": {
          "OAM": "OAM",
          "OMCI": "OMCI",
          "SECURITY": "Seguridad"
        }
      },
      "stats": {
        "label": {
          "RX": "RX (Bps)",
          "RX_PACKAGE": "RX (pps)",
          "TX": "TX (Bps)",
          "TX_PACKAGE": "TX (pps)"
        },
        "title": {
          "FLOW_RATE": "Tasa de flujo",
          "PACKAGES_PER_SECOND": "Paquetes por segundo (PPS)",
          "PON_STATISTICS": "Estadísticas PON"
        }
      },
      "status": {
        "label": {
          "CURRENT_MODE": "Modo actual",
          "POLARIZATION_CURRENT": "Corriente de polarización (mA)",
          "RX_SIGNAL_STRENGTH": "Intensidad de la señal RX (dBm)",
          "TEMPERATURE": "Temperatura (° C)",
          "TX_SIGNAL_STRENGTH": "Intensidad de la señal TX (dBm)",
          "VOLTAGE": "Voltaje (v)"
        }
      }
    },
    "search": {
      "placeholder": {
        "SEARCH": "Buscar..."
      }
    },
    "service": {
      "dynamic_dns": {
        "message": {
          "information": {
            "NO_ENTRY_ON_LIST": "No se han registrado reglas de DNS dinámico"
          },
          "error": {
            "REPEATED_HOSTNAME": "La dirección ya existe",
            "PROVIDER_NOT_SUPPORTED": " proveedor no compatible.",
            "HOSTNAME_REQUIRED": " nombre de hostname requerido.",
            "HOSTNAME_INVALID": " hostname no válido.",
            "USER_AND_PASSWORD_REQUIRED": " se requiere usuario y contraseña.",
            "HOSTNAME_ALREADY_IN_USE": " hostname ya está en uso."
          },
          "delete": {
            "DELETE_SUCCESS": "Ítem eliminado con éxito."
          }
        },
        "title": {
          "DYNAMIC_DNS_LIST": "Listado de DNSs dinámicos"
        },
        "label": {
          "HOSTNAME": "Hostname",
          "PROVIDER": "Proveedor",
          "INTERFACE": "Interfaz",
          "USERNAME": "Usuario",
          "PASSWORD": "Contraseña",
          "ENABLED": "Activo",
          "INSTANCE_NUM": "Instancia",
          "SERVICE_PORT": "Puerto de servicio",
          "SETTINGS": "Configuración de DNS dinámico",
          "STATUS": "Estado",
          "DDNS_STATUS_SUCCESFULLY_UPDATED": "Actualizado con éxito",
          "DDNS_STATUS_CONNECTION_ERROR": "Error de conexión",
          "DDNS_STATUS_AUTH_FAILURE": "La autenticación falló",
          "DDNS_STATUS_WRONG_OPTION": "Opción incorrecta",
          "DDNS_STATUS_HANDLING": "Actualizando",
          "DDNS_STATUS_LINK_DOWN": "Enlace desactivado"
        }
      },
      "proxy_igmp": {
        "label": {
          "ALLOW_MULTICAST": "Habilitar multicast",
          "GROUP_LEAVE_DELAY": "Group leave delay",
          "LAST_MEMBER_QUERY_COUNT": "Last member query count",
          "QUERY_INTERVAL": "Query interval",
          "QUERY_RESPONSE_INTERVAL": "Query response interval",
          "ROBUST_COUNT": "Robust count"
        },
        "title": {
          "PROXY_IGMP": "Proxy IGMP",
          "SETTINGS": "Configuración de proxy IGMP"
        },
        "upnp": {
          "label": {
            "ALL_INTERFACES": "Cualquier",
            "ALLOW_UPNP": "Habilitar UPnP",
            "INTERFACE": "Interfaz"
          },
          "title": {
            "UPNP": "UPnP"
          }
        }
      },
      "rip_config": {
        "error": {
          "ERROR_SERVER": "Error al intentar aplicar cambios al servidor:",
          "INTERFACE_BUSY": "Interfaz ya en uso",
          "DESTINATION_BUSY": "Destination busy"
        },
        "info": {
          "NO_RIP_CONFIGS": "No se ha creado ninguna configuración RIP"
        },
        "label": {
          "ALL_INTERFACES": "Cualquier",
          "INTERFACE": "Interface",
          "RECEIVE_MODE": "Modo RX",
          "SEND_MODE": "Modo TX",
          "NONE": "ninguno",
          "RIPV1": "RIPV1",
          "RIPV2": "RIPV2",
          "RIPV1_COMPAT": "RIPV1_COMPAT"
        },
        "title": {
          "RIP_CONFIG": "Configuración de RIP",
          "RIP_CONFIG_TABLE": "Tabla de configuración de RIP",
          "RIP_CONFIG_CARD": "Selección de interfaz",
          "RIP_ENABLE": "Habilitar/Deshabilitar RIP"
        }
      },
      "title": {
        "SERVICE": "Servicio"
      },
      "upnp": {
        "label": {
          "ALLOW_UPNP": "Habilitar UPnP",
          "INTERFACE": "Interfaz"
        },
        "title": {
          "SETTINGS": "Configuración de UPnP",
          "UPNP": "UPnP"
        },
        "warning": {
          "NO_WAN_INTERFACES_AVAILABLE": "No hay interfaces WAN disponibles"
        }
      }
    },
    "system": {
      "acl_admin": {
        "label": {
          "ANY": "Todos",
          "ENABLE_ACL_ADMIN": "Habilitar ACL",
          "FTP": "FTP",
          "HTTP": "HTTP",
          "HTTPS": "HTTPS",
          "IP_END_ADDR": "IP (final)",
          "IP_START_ADDR": "IP (inicio)",
          "PING": "Ping",
          "SERVICES": "Servicios",
          "SSH": "SSH",
          "TELNET": "Telnet",
          "TFTP": "TFTP"
        },
        "message": {
          "error": {
            "BOTH_IPS_OF_RANGE_CANNOT_BE_EQUALS_THE_DEVICE_IP": "Ambas IP son la misma dirección de dispositivo",
            "CONFLIT_WITH_OTHER_RULE": "Ya existe una regla en ese rango para esta interfaz.",
            "ERROR_ON_SERVER": "Error al salvar ACL:",
            "INVALID_IP": "IP no válida en rango",
            "INVALID_RANGE": "El final debe ser mayor o igual que el inicio",
            "IP_ADDRESSES_ON_RANGE_MUST_BE_IN_THE_SAME_NETWORK_OF_LAN": "Las IP de rango deben estar en la red LAN",
            "NO_VALID_LAN_RULE": "no hay una regla de acceso LAN (habilitar HTTP o todas las reglas).",
            "LAN_DEFAULT_RULE_MISSING": "debe haber una regla de acceso LAN para habilitar ACL",
            "MAX_RULES_NUM": "Número máximo de reglas alcanzado"
          },
          "IPV6_DISABLED": "La función Configuración de ACL IPv6 está deshabilitada porque IPv6 no está habilitado."
        },
        "title": {
          "ACL_ADMIN_CAPABILITY": "Habilitar/Deshabilitar ACL",
          "ACL_CONFIG_CREATE": "Crear regla de ACL",
          "ACL_CONFIG_LIST": "Lista de reglas de ACL",
          "SERVICE_LIST": "Lista de servicios habilitados",
          "IP_RANGE": "rango de direcciones IP",
          "ACL_CONFIG_IPV6": "Configuración de ACL IPv6"
        },
        "info": {
          "NO_RULES": "No se han agregado reglas de ACL IPv6 a la lista"
        }
      },
      "backup_restore": {
        "error": {
          "COULD_NOT_CREATE_BACKUP_FILE": "No se pudo crear el archivo de respaldo.",
          "COULD_NOT_RESTORE": "Archivo rechazado, verifique que sea un archivo de respaldo válido.",
          "INVALID_FILE": "Archivo inválido",
          "TRY_AGAIN_OR_REACH_SUPPORT_TEAM": "Vuelva a intentarlo o póngase en contacto con nuestro servicio de asistencia."
        },
        "info": {
          "BACKUP_GENERATION_SUCCESS": "El archivo de respaldo se generó con éxito.",
          "CREATE_FILE": "Crear un archivo",
          "PLEASE_WAIT": "Espere mientras creamos el archivo de respaldo.",
          "RESTORE_BACKUP": "Restaurar configuracion",
          "RESTORE_BACKUP_SUCCESS": "La restauración desde la copia de seguridad ha finalizado.",
          "DEVICE_WILL_REBOOT": "El dispositivo se reiniciará.",
          "SELECT_FILE": "Seleccionar el archivo",
          "UPDATING": "Restaurando",
          "CUSTOM_CONFIG_ENABLE": "Restaurando",
          "CUSTOM_CONFIG_SAVE": "Restaurando",
          "CUSTOM_CONFIG": "Al activar esta función, la configuración actual será la configuración predeterminada del dispositivo, evitando que el dispositivo pierda la configuración actual en caso de reinicio.",
          "FACTORY_DEFAULT_CONFIRM": "Atención, el tiempo que se debe presionar el botón de reinicio para realizar el reinicio de la configuración se cambiará a",
          "FACTORY_DEFAULT_CONFIRM_SECONDS": "segundos, ¿quieres continuar?",
          "FACTORY_DEFAULT_INFO": "Establece el tiempo, en segundos, en el que se debe presionar el botón de reinicio para realizar la restauración de la configuración",
          "FACTORY_DEFAULT_RANGE": "El valor debe estar entre 5 y 300 segundos"
        },
        "title": {
          "CUSTOM_CONFIG": "Cambiar la configuración predeterminada del sistema a la configuración actual",
          "CREATE_BACKUP_FILE": "Crear archivo de respaldo de la configuración actual",
          "RESTORE_BACKUP": "Restaurar la configuración desde un archivo de respaldo",
          "FACTORY_DEFAULT": "Retraso del botón de reinicio"
        },
        "warning": {
          "MUST_REBOOT_TO_APPLY_SETTINGS": "Es necesario reiniciar el router para aplicar los cambios.",
          "DO_NOT_SHUTDOWN_THE_DEVICE": "¡No apague el dispositivo durante la restauración!",
          "IMPORTANT": "Importante",
          "RESTORE_ON_GOING": "Espere, la restauración en curso está en curso."
        }
      },
      "change_to_firmware_version": {
        "label": {
          "ACTION_CAN_BE_UNDO": "El usuario puede, en cualquier momento, volver a esta versión de firmware.",
          "BACKUP_VERSION": "Versión de firmware de respaldo",
          "CONFIRMATION_TO_RESET": "¿Está seguro de que desea cambiar a la versión de firmware {{version}}?",
          "CURRENT_VERSION": "Versión actual del firmware",
          "DO_RESTORE": "Si, quiero cambiar",
          "DONT_RESTORE": "No, quiero mantener",
          "EXPLAINING": "Al cambiar entre versiones de firmware del enrutador, todas las configuraciones realizadas se mantendrán en caso de compatibilidad.",
          "SWITCH_TO_BACKUP": "Cambiar versiones",
          "UNDERSTOOD": "Entendí"
        },
        "message": {
          "error": {
            "ERROR_ON_SERVER": "Error al intentar cambiar a la versión de respaldo"
          }
        },
        "title": {
          "CHANGE_TO_FIRMWARE_VERSION_DESCRIPTION": "Cambiar versión de firmware",
          "CHANGING": "Trocando de firmware"
        },
        "warning": {
          "CHANGED": "Firmware intercambiado",
          "RECONNECT": "Es posible que deba volver a conectarse a la red de su enrutador.",
          "WAIT_A_FEW_SECONDS": "Espere unos momentos mientras se cambia el firmware de su enrutador."
        }
      },
      "datetime": {
        "label": {
          "CURRENT_TIME": "Fecha y hora",
          "DAYLIGHT_SAVING_ENABLED": "Horario de verano",
          "NTP_SERVER_AUTOMATIC": "Obtenga servidores NTP automáticamente",
          "NTP_SERVER_ENABLED": "Servidor NTP habilitado",
          "NTP_SERVER_NTP_SERVER_1": "Servidor NTP 1",
          "NTP_SERVER_NTP_SERVER_2": "Servidor NTP 2",
          "SYNCED": "Sincronizado",
          "TIMEZONE": "Huso horario"
        },
        "title": {
          "DATETIME_SETTINGS": "Configuración de fecha/hora (formato 24h)"
        },
        "warning": {
          "SYNCED_INFO": "Indica si el enrutador está sincronizado con la hora de internet.",
          "CONFIRMATION_MSG": "Al confirmar, la función de mantenimiento automático se desactivará.",
          "CONFIRM_QUESTION": "¿Quieres confirmar?"
        },
        "error": {
          "INVALID_NTP_SERVER": "Uno o más servidores NTP no válidos"
        }
      },
      "factory_reset": {
        "label": {
          "DO_RESTORE": "Sí, quiero restaurar",
          "DONT_RESTORE": "No, quiero mantener",
          "UNDERSTOOD": "Entendí"
        },
        "title": {
          "RESTORE_SYSTEM": "Restauracion del sistema",
          "RESTORE_SYSTEM_BRIEF": "Restablezca la configuración de su enrutador a los valores predeterminados de fábrica.",
          "RESTORING": "Restaurando"
        },
        "warning": {
          "ACTION_CANNOT_BE_UNDONE": "Esta acción no se puede deshacer.",
          "CONFIRMATION_TO_RESET": "¿Estás seguro de que deseas restablecer el enrutador?",
          "EXPLAINING_FACTORY_RESET": "Al restablecer su enrutador, todos los ajustes realizados volverán a los valores predeterminados de fábrica.",
          "RECONNECT": "Si está realizando este procedimiento a través de Wi-Fi, conéctese a la red INTELBRAS.",
          "RESTORED": "Tu enrutador ha sido reiniciado",
          "WAIT_A_FEW_SECONDS": "Espere unos momentos mientras su enrutador se restablece a los valores predeterminados de fábrica."
        }
      },
      "leds": {
        "label": {
          "ENABLE": "Habilitar LEDs"
        },
        "message": {
          "error": {
            "ERROR_ON_SERVER": "Error al salvar la configuración de LEDs"
          }
        },
        "title": {
          "LEDS": "LEDs",
          "ENABLE": "Habilitar/Deshabilitar LEDs"
        }
      },
      "reboot": {
        "button": {
          "REBOOT": "Reiniciar"
        },
        "info": {
          "REBOOT_SUCCESSFUL": "Su enrutador se ha reiniciado. Si estaba conectado a través de Wi-Fi, vuelva a conectarse a la red."
        },
        "warning": {
          "MAY_HAVE_TO_RECONNECT": "Es posible que deba volver a conectarse a la red de su enrutador.",
          "MUST_REBOOT": "Reinicio requerido",
          "MUST_REBOOT_TO_APPLY_SETTINGS": "Su dispositivo debe reiniciarse para que todos los cambios surtan efecto.",
          "PRESS_BELOW_BUTTON_TO_REBOOT": "Presione el botón de abajo para reiniciar su enrutador inmediatamente.",
          "REBOOT_YOUR_SYSTEM": "Reinicia tu sistema",
          "REBOOTING": "Reiniciando. Espera.",
          "WANT_TO_REBOOT_NOW": "¿Quieres reiniciar tu dispositivo ahora?"
        }
      },
      "title": {
        "SYSTEM": "Sistema"
      },
      "tr069_config": {
        "label": {
          "ACS_INFORM": "Activar información periódica",
          "ACS_INTERVAL": "Intervalo de información periódica",
          "CONNECTION_ENABLE": "Habilitar la autenticación",
          "CONNECTION_PATH": "Ruta",
          "CONNECTION_PORT": "Puerto",
          "TR069_ENABLE": "Habilitar TR-069"
        },
        "message": {
          "error": {
            "MUST_START_WITH_PROTOCOL": "La URL debe comenzar con http:// o https://",
            "SERVER_ERROR": "Hubo un error al intentar configurar el TR-069: "
          }
        },
        "title": {
          "TR069_ACS_CONFIG": "Configuración de ACS",
          "TR069_CONNECTION_CONFIG": "Configuración de Conexión",
          "TR069_ENABLE": "Habilitar/Deshabilitar TR-069"
        }
      },
      "upgrade": {
        "error": {
          "COULD_NOT_UPDATE": "No se puede realizar la actualización del firmware.",
          "INVALID_FILE": "Archivo inválido",
          "TRY_AGAIN_OR_REACH_SUPPORT": "Vuelva a intentarlo o póngase en contacto con nuestro servicio de asistencia."
        },
        "info": {
          "FIRMWARE_UPDATED": "El firmware se actualizó correctamente."
        },
        "label": {
          "CURRENT_VERSION": "Versión actual:",
          "FIRMWARE_VERSION": "Versión de firmware:",
          "MODEL": "Modelo:",
          "SELECT_FILE": "Seleccionar el archivo",
          "UPGRADE": "Actualizar"
        },
        "title": {
          "SELECT_FILE": "Seleccione un archivo de firmware para actualizar su enrutador.",
          "UPGRADE_FROM_FILE": "Actualizar desde un archivo",
          "UPGRADING": "Actualizando"
        },
        "warning": {
          "DO_NOT_TURN_OFF": "¡No apague el enrutador durante la actualización!",
          "IMPORTANTING": "Importante:",
          "UPGRADING": "Espere, la actualización del firmware está en curso."
        }
      },
      "users": {
        "label": {
          "DEFINE_THE_CREDENTIALS": "Establecer credenciales para acceder a la configuración del enrutador",
          "PASSWORDS_DO_NOT_MATCH": "Las contraseñas no coinciden",
          "PASSWORDS_EQUALS_TO_PREVIOUS": "La nueva contraseña no puede ser la misma que la contraseña actual",
          "UPDATE_CREDENTIALS": "Actualizar credenciales",
          "ENABLE_LIMITED_USER": "Habilitar usuario limitado"
        },
        "message": {
          "warning": {
            "INVALID_PASSWORD": "Su contraseña debe contener: al menos un carácter en mayúscula; Al menos un carácter en minúscula; Al menos un carácter especial;"
          },
          "error": {
            "INVALID_PASSWORD": "Contraseña poco segura"
          }
        }
      },
      "automatic_maintenance": {
        "label": {
          "HOUR": "Hora (formato 24h)",
          "MIN": "Minutos",
          "CONFIG_TIME": "Configurar el tiempo para el mantenimiento"
        },
        "warning": {
          "CONFIRMATION_MSG": "Al confirmar, el cliente STNP se habilitará en la función Zona horaria. También, la necesidad de estar conectado a internet para que el dispositivo funcione correctamente.",
          "CONFIRM_QUESTION": "¿Quieres confirmar?"
        }
      }
    },
    "tools": {
      "ping": {
        "label": {
          "ALL_INTERFACES": "Cualquier",
          "EXECUTION_TIME_IN_SECONDS": "Tiempo máximo de ejecución (segundos)",
          "INTERFACE": "Interfaz",
          "IP_HOST_ADDRESS": "Dirección IP/Host",
          "LOST": "Perdidos",
          "MAX_HOPS_NUMBER": "Numero máximo de saltos",
          "MAXIMUM": "Máximo",
          "MEAN": "Promedio",
          "MINIMUM": "Mínimo",
          "NUMBER_OF_PACKAGES": "Numero de paquetes",
          "PACKAGE_SIZE": "Tamaño del paquete",
          "PACKAGES": "Paquetes",
          "PING_SETTINGS": "Configuración de ping",
          "RECEIVED": "Recebidos",
          "RTT": "RTT",
          "SENT": "Enviados",
          "START": "Empezar"
        },
        "message": {
          "error": {
            "PING_ERROR": "Hubo un error al solicitar ping: "
          }
        },
        "title": {
          "ADVANCED": "Avanzado",
          "PING": "Ping"
        }
      },
      "tr069_inform": {
        "label": {
          "CAUSE": "Causa",
          "STATUS": "Estado"
        },
        "message": {
          "error": {
            "TR069_INFORM_ERROR": "No se pudo recuperar información de TR-069"
          },
          "causes": {
            "NO_INFORM": "Inform inactivo",
            "NO_RESPONSE": "Sin respuesta",
            "INFORM_BREAK": "Inform interrumpido",
            "INFORM_SUCCESS": "Éxito",
            "INFORM_AUTH_FAIL": "Autenticación fallida",
            "INFORMING": "Reportando",
            "UNKNOWN": "Causa desconocida"
          },
          "ENABLE_TR069": "Habilitar TR-069 en el ",
          "CONFIGS_TR069": "Configuración de TR-069",
          "TO_ENABLE_INFORM": " para habilitar Inform."
        },
        "title": {
          "TR069_INFORM": "Información de TR-069",
          "STOP_INFORM": "Dejar informe",
          "START_INFORM": "Comenzar informe"
        }
      },
      "traceroute": {
        "label": {
          "ALL_HOPS": "Lista de saltos",
          "ALL_INTERFACES": "Cualquier",
          "FIRST_TTL": "Primer TTL",
          "HOPS": "Saltos",
          "INTERFACE": "Interfaz",
          "IP_ADDRESS": "Dirección IP",
          "IP_HOST_ADDRESS": "Dirección de IP/Host",
          "LATENCY": "Latencia {{index}} (ms)",
          "TRACEROUTE_SETTINGS": "Configuración de traceroute"
        },
        "message": {
          "error": {
            "TRACEROUTE_ERROR": "Hubo un error al solicitar traceroute: ",
            "TRACEROUTE_INVALID_IP": "La dirección IP no es válida para el protocolo seleccionado"
          }
        },
        "title": {
          "TRACEROUTE": "Traceroute"
        }
      },
      "loop_back_detection": {
        "label": {
          "ADD_VLAN_ID": "Agregar nueva ID de VLAN",
          "DETECTION_INTERVAL": "Intervalo de detección (en segundos)",
          "ENABLE_LOOP_DETECTION": "Habilitar detección de bucle",
          "FRAME_TYPE": "Tipo de marco",
          "RECOVERY_INTERVAL": "Intervalo de recuperación (en segundos)",
          "VLAN_ID": "VLAN ID",
          "NO_TAG": "Sin etiqueta",
          "CONFIG_TABLE": "Tabla de etiquetas de VLAN configuradas",
          "ADD_VLAN": "Agregar nueva etiqueta VLAN",
          "PORT": "Puerto",
          "DETECTED": "Detectado",
          "NOT_DETECTED": "No detectado"
        },
        "title": {
          "LOOP_BACK_DETECT": "Configuración de detección de bucle",
          "LOOP_BACK_DETECT_STATUS": "Status de detección de bucle",
          "INTERVAL_FRAME_CONFIG": "Configuración de Intervalos y cuatro",
          "VLAN_LIST": "Lista de VLAN con detección de bucle habilitada"
        },
        "info": {
          "NO_VLANS": "No hay VLAN agregadas a la lista",
          "VLAN_WARN": "El valor \"0\" no representa ninguna etiqueta VLAN.",
          "SAVE_WARN": "Al agregar una nueva etiqueta VLAN, es necesario guardar la configuración para que se aplique.",
          "DISABLE_LAN_WARN": "El puerto LAN está deshabilitado en casos de detección de loops."
        },
        "error": {
          "LOOP_BACK_DETECT_REQUIRE_VLAN": "Se debe agregar al menos una VLAN cuando la detección de bucle está habilitada.",
          "RETRIEVE_LBD_STATUS_FAILED": "No se pudo recuperar el estado de detección de bucle"
        }
      }
    },
    "validators": {
      "ID_ALREADY_EXISTS": "Esta id ya existe",
      "MAX_NUMBER_OF_CHARACTERS": "Máximo de {{max}} caracteres",
      "MAX_NUMBER_OF_ELEMENTS": "El límite máximo de elementos es {{max}}"
    },
    "voip": {
      "advanced_sip": {
        "label": {
          "CALL_TRANSFER": "Transferencia de llamada",
          "CALL_WAITING_CALL_WAITING_ID": "Llamar en espera",
          "CALL_WAITING_HAS_CALL_WAITING": "Tener llamada en espera",
          "CONFERENCE_ON_SERVER_OR_CPE": "Conferencia en servidor o CPE",
          "CONFERENCE_URI": "URI de conferencia",
          "DTMF_DTMF_AS_FAXMODEM": "DTMF como fax/modem",
          "DTMF_DTMF_PACKET_INTERVAL": "Intervalo de paquetes DTMF",
          "DTMF_DTMF_PAYLOAD": "Payload DTMF",
          "DTMF_DTMF_RELAY": "Retransmisión DTMF",
          "DTMF_FAXMODEM_PAYLOAD": "Payload fax/modem",
          "DTMF_PACKET_INTERVAL": "Intervalo de paquetes DTMF",
          "DTMF_SIP_INFO_DURATION": "Duración SIP INFO",
          "FAXMODEM_PACKET_INTERVAL": "Intervalo de paquetes fax/modem",
          "HIDE_CALL_ID": "Ocultar llamada",
          "HOOK_FLASH_RELAY": "Hook Flash Relay",
          "MEDIA_PORT": "Media port",
          "MIC_AGC_ENABLED": "Micrófono Agc habilitado",
          "MIC_AGC_MAX_GAIN_UP": "Ganancia máxima de micrófono",
          "PORT": "Puerto",
          "REJECT_IP_CALL": "Rechazar llamadas IP",
          "RX_GAIN": "Ganancia RX",
          "SIP_PORT": "Puerto SIP",
          "SPEAKER_AGC_ENABLED": "Altavoz Agc habilitado",
          "SPEAKER_AGC_MAX_GAIN_UP": "Ganancia máxima del altavoz",
          "THREE_WAY_CONFERENCE": "Conferencia de tres vías",
          "TX_GAIN": "Ganancia de TX",
          "SIP_PRACK": "Habilitar SIP Prack"
        },
        "title": {
          "VOIP_ADVANCED_SIP": "Configuración avanzada de SIP"
        }
      },
      "codec": {
        "label": {
          "AVAILABLE_CODECS": "Códecs disponibles",
          "CODECS": "Codecs",
          "ENABLED_CODECS": "Códecs habilitados",
          "OPTION_BIT_RATE": "Tasa de bit",
          "OPTION_PACKING_ORDER": "Orden de paquete",
          "PACKETIZATION": "Muestreo",
          "PORT": "Puerto",
          "PRECEDENCE": "Precedencia",
          "RTP_REDUNDANT_CODEC": "RTP redundante codec",
          "RTP_REDUNDANT_PAYLOAD_TYPE": "RTP redundante tipo de carga útil",
          "DISABLE_RTP_REDUNTANT_CODEC": "Deshabilitar",
          "BIT_RATE_LEFT": "Izquierda",
          "BIT_RATE_RIGHT": "Derecha"
        },
        "message": {
          "error": {
            "MUST_CHOOSE_AT_LEAST_ONE_CODEC": "Elija al menos un codec"
          }
        },
        "title": {
          "CODEC": "Codec"
        }
      },
      "dial_plan": {
        "label": {
          "DIAL_PLAN": "Plan de marcación",
          "DIGITMAP_ENABLE": "Activar plan de marcado",
          "PORT": "Puerto"
        },
        "title": {
          "DIAL_PLAN": "Plan de marcación",
          "CONFIGURE_DIAL_PLAN": "Configuración del plan de marcación"
        }
      },
      "history_call": {
        "label": {
          "DATE_HOUR": "Fecha y hora",
          "DESTINY": "Destino",
          "DIRECTION": "Dirección",
          "DURATION": "Duración",
          "PORT": "Puerto",
          "SOURCE": "Fuente",
          "direction": {
            "INCOMING_CALL": "Llamada entrante",
            "OUTGOING_CALL": "Llamada saliente"
          },
          "status": {
            "COMPLETED": "Terminada",
            "ABORTED": "Abortada"
          }
        },
        "messages": {
          "NO_HISTORY_CALL": "No hay historial de llamadas."
        },
        "title": {
          "HISTORY_CALL": "Historial de llamadas"
        }
      },
      "proxy": {
        "label": {
          "DEFAULT_PROXY": "Proxy predeterminado",
          "ENABLED": "Activo",
          "LOGIN_ID": "Login ID",
          "NAME": "Nombre",
          "NUMBER": "Número",
          "OUTBOUND_PROXY_ADDR": "Dirección de proxy de salida",
          "OUTBOUND_PROXY_ENABLE": "Habilitar el proxy de salida",
          "OUTBOUND_PROXY_PORT": "Puerto proxy de salida",
          "PASSWORD": "Contraseña",
          "PORT": "Puerto",
          "PROXY_ADDR": "Dirección",
          "REG_EXPIRE": "Expirar",
          "REGISTER_STATUS": "Estado de registro",
          "register_status": {
            "ERROR": "Error al leer el estado del registro",
            "NOT_REGISTERED": "No registrado",
            "REGISTER_FAIL": "Registro fallido",
            "REGISTERED": "Registrado",
            "REGISTERING": "Registrando",
            "VOIP_RESTART": "VoIP reiniciando"
          },
          "SESSION_TIME_ENABLE": "Habilitar el tiempo de la sesión",
          "SESSION_UPDATE_TIMER": "Temporizador de actualización de sesión",
          "SIP_DOMAIN": "Dominio SIP",
          "SIP_SUBSCRIBE": "Registrar SIP"
        },
        "title": {
          "CONNECTION_INFO": "Información de conexión",
          "OUTBOUND_PROXY": "Proxy de salída",
          "ACCOUNT_01": "Cuenta 1",
          "ACCOUNT_CONFIG": "Configuracion de cuenta",
          "GENERAL_CONFIG": "Configuración general",
          "SESSION_INFORMATION": "Información de la sesión",
          "SIP_INFO": "Información SIP"
        }
      }
    }
  }
}
import React, { useEffect, useState, useContext } from 'react'
import Input from '../../../../components/input/input';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { DNSMode, fetchDhcpServer, updateDhcpServer } from '../../../resources/dhcp-server';
import { BackendContext } from '../../../../backend/backend';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import network from '../../../../components/form/validators/network';
import Checkbox from '../../../../components/checkbox/checkbox';
import { deleteDhcpLease, hasLeasesToDelete } from '../../../resources/dhcp-lease'
import iputils from '../../../../components/utils/iputils'
import confirmContent from './dhcp-lease-confirm';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators';

export default function LanDhcpServer({dhcpServer, setDhcpServer, lanInterface, baseLanInterface}) {

    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [leasesToDelete, setLeasesToDelete] = useState([])
    const [startIPErrorMsg, setStartIPErrorMsg] = useState('')
    const [endIPErrorMsg, setEndIPErrorMsg] = useState('')
    const [dnsErrorMessage, setDNSErrorMessage] = useState('')
    const [dhcpServerRetrived, setDhcpServerRetrived] = useState('')

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {

        fetchDhcpServer(backend, setDhcpServer)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(!dhcpServer || !lanInterface) return

        validateDhcpServerIP()

        if(!dhcpServerRetrived)
            setDhcpServerRetrived({...dhcpServer})

        // eslint-disable-next-line
    }, [dhcpServer])

    const validateDhcpServerIP = () => {

        if(!dhcpServer || !lanInterface || !baseLanInterface) return

        setStartIPErrorMsg("")
        setEndIPErrorMsg("")

        // range start
        var result = network.sameNetwork(dhcpServer.rangeStart, {ip: baseLanInterface.ip4, network: baseLanInterface.netmask})
        if(result !== ""){
            setStartIPErrorMsg(result)
        } else {
            result = network.ipLessThan(dhcpServer.rangeStart, {checkAddress: dhcpServer.rangeEnd})
            if(result !== ""){
                setStartIPErrorMsg(result)
            }
        }

        //range end
        result = network.sameNetwork(dhcpServer.rangeEnd, {ip: baseLanInterface.ip4, network: baseLanInterface.netmask})
        if(result !== ""){
            setEndIPErrorMsg(result)
        } else {
            result = network.ipGreaterThan(dhcpServer.rangeEnd, {checkAddress: dhcpServer.rangeStart})
            if(result !== ""){
                setEndIPErrorMsg(result)
            }
        }
    }

    const saveDhcpServer = async() => {

        setConfirm(false)
        setSaving(true)

        leasesToDelete.forEach(async (lease) => {
            await deleteDhcpLease(backend, lease.id)
        })

        let ok = await updateDhcpServer(backend, dhcpServer)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
    }

    const saveConfirm = async () => {

        if (success || saving || error || confirm) return

        const isOutOfRange = (ip) => !iputils.isIpInRange(ip, dhcpServer.rangeStart, dhcpServer.rangeEnd)
        const needConfirm = await hasLeasesToDelete(backend, setLeasesToDelete, isOutOfRange)

        if (needConfirm) {
            setConfirm(true)
        } else {
            saveDhcpServer()
        }
    }

    const handleInput = (event, name) => {
        let value = event.target.value
        dhcpServer[name] = value
        setDhcpServer({...dhcpServer})

        if (name.includes("dns")) {
            if (dhcpServer.dns1 && dhcpServer.dns1 === dhcpServer.dns2) {
                setDNSErrorMessage(t('network.lan.error.DNS_REPEATED'))
            } else {
                setDNSErrorMessage('')
            }
        }
    }

    const dismiss = async(confirmed) => {
        if (confirm && confirmed) {
            await saveDhcpServer()
        } else {
            setSaving(false)
            setSuccess(false)
            setError(false)
            setConfirm(false)
        }
        setLeasesToDelete([])
    }

    const shouldDisableFormButton = () => {
        return startIPErrorMsg !== "" || endIPErrorMsg !== "" || dnsErrorMessage !== ""
    }

    return (
        !dhcpServer ? <Loading show={true}></Loading> :
        <Form onSubmit={saveConfirm} buttonId='button-save-dhcp-server' disableButton={shouldDisableFormButton()}>

            <div className='section-divider'></div>
            <div className='subtitle'>{<Translator path="network.lan.label.DHCP_SERVER"></Translator>}</div>

            <DefaultStatusModals
                success={success}
                error={error}
                saving={saving}
                confirm={confirm}
                continueFn={dismiss}
                isWarningModal={true}
                confirmContent={confirmContent(leasesToDelete.length)}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <Checkbox
                label={<Translator path="network.lan.label.ENABLED"></Translator>}
                name="enable_dhcp_server"
                id='enable-dhcp-server'
                value={dhcpServer.enabled}
                toggleFn={() => {
                    setDhcpServer({...dhcpServer, enabled: !dhcpServer.enabled})
                }}
            ></Checkbox>

            <Input id='rangeStart'
                label={<Translator path="network.lan.label.DHCP_RANGE_START" required={true}></Translator>}
                value={dhcpServer.rangeStart}
                name="rangeStart"
                onChange={(e) => {
                    handleInput(e, "rangeStart")
                }}
                collapse={!dhcpServer.enabled}
                validators={[
                    extraValidators.required,
                    network.ip4
                ]}
                errorMessage={startIPErrorMsg}
            ></Input>

            <Input id='rangeEnd'
                label={<Translator path="network.lan.label.DHCP_RANGE_END" required={true}></Translator>}
                name="rangeEnd"
                value={dhcpServer.rangeEnd}
                onChange={(e) => {
                    handleInput(e, "rangeEnd")
                }}
                collapse={!dhcpServer.enabled}
                validators={[
                    extraValidators.required,
                    network.ip4
                ]}
                errorMessage={endIPErrorMsg}
            ></Input>

            <Checkbox
                label={<Translator path="network.lan.label.DNS_MANUALLY"></Translator>}
                name="dns_manually_dhcp_server"
                id='dns-manually-dhcp-server'
                value={dhcpServer.dns_mode}
                clearErrors='true'
                toggleFn={() => {
                    setDhcpServer((dhcpServer) => {
                        dhcpServer.dns_mode = Number(!dhcpServer.dns_mode);

                        if(dhcpServer.dns_mode === DNSMode.DNS_PROXY) {
                            dhcpServer.dns1 = dhcpServerRetrived.dns1
                            dhcpServer.dns2 = dhcpServerRetrived.dns2
                        }

                        return {...dhcpServer}
                    })
                }}
            ></Checkbox>

            {dhcpServer.dns_mode === DNSMode.DNS_MANUALLY &&
                <>
                    <Input id='dns1'
                        label={<Translator path="network.lan.label.DNS1" required={true}></Translator>}
                        name="dns1"
                        value={dhcpServer.dns1}
                        onChange={(e) => {
                            handleInput(e, "dns1")
                        }}
                        collapse={!dhcpServer.enabled}
                        validators={[
                            extraValidators.required,
                            network.ip4,
                        ]}
                        errorMessage={dnsErrorMessage}
                    ></Input>
        
                    <Input id='dns2'
                        label={<Translator path="network.lan.label.DNS2"></Translator>}
                        name="dns2"
                        value={dhcpServer.dns2}
                        onChange={(e) => {
                            handleInput(e, "dns2")
                        }}
                        collapse={!dhcpServer.enabled}
                        validators={[
                            network.ip4
                        ]}
                        errorMessage={dnsErrorMessage}
                    ></Input>
                </>
            }


        </Form>
    )
}
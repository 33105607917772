import React, { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'

import './voip-codec.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipCodec, saveVoipCodec } from '../../../resources/voip-codec';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import Translator from '../../../common/components/translator/translator'
import Select from '../../../../components/select/select';
import Input from '../../../../components/input/input';
import Button from '../../../../components/button/button';
import { useTranslation } from 'react-i18next';

export default function VoipCodec({isWizard, setSaved, isFormSegment, formSegment, history}) {
    const [voipCodec, setVoipCodec] = useState(null)

    const params = useParams()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const { t } = useTranslation()

    const [pristine, setPristine] = useState(true)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchVoipCodec(params.id, backend, setVoipCodec)

        // eslint-disable-next-line
    }, [])

    const onDragOver = e => {
        e.preventDefault();
    }

    const onDragStart = (e, name) => {
        e.dataTransfer.setData("id", name)
    }

    const onDrop = (e, list, pos) => {
        let codec = e.dataTransfer.getData("id");

        if (list === 'enable') {
            enableCodec(codec, pos)

        } else {
            disableCodec(codec)
        }
    }

    const getCodecPacktizationOptions = cod => {
        if (cod.startsWith('G711') || cod.startsWith('G722')) {
            return [10, 20, 30, 40]
        }

        if (cod.startsWith('G723')) {
            return [30, 60, 90]
        }

        if (cod.startsWith('G729') ||cod.startsWith('G726') ) {
            return [10, 20, 30, 40, 50, 60, 70, 80, 90]
        }

        return []
    }

    const enableCodec = (codec, pos) => {
        if (!voipCodec.type[codec].enable) {
            if(pos === 'root') {
                pos = voipCodec.available_codecs.filter(cod => voipCodec.type[cod].enable).length + 1
            }

            voipCodec.available_codecs.filter(cod => voipCodec.type[cod].enable).forEach(element => {
                if (voipCodec.type[element].precedence >= pos) {
                    voipCodec.type[element].precedence++
                }
            });

            voipCodec.type[codec].enable = true;
            voipCodec.type[codec].precedence = pos;
        } else {
            if (pos !== 'root') {
                disableCodec(codec)
                enableCodec(codec, pos)
            }
        }
        setVoipCodec({...voipCodec})
        setPristine(false)
    }

    const disableCodec = codec => {
        if (voipCodec.type[codec].enable) {
            voipCodec.available_codecs.filter(cod => voipCodec.type[cod].enable).forEach(element => {
                if (voipCodec.type[element].precedence > voipCodec.type[codec].precedence) {
                    voipCodec.type[element].precedence--
                }
            });

            voipCodec.type[codec].enable = false;
            voipCodec.type[codec].precedence = 0;
        }
        setVoipCodec({...voipCodec})
        setPristine(false)
    }

    const voipCodecForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className="codec-cards-wrapper">

                <div className="card mt2">
                    <div className='subtitle'><Translator path="voip.codec.label.RTP_REDUNDANT_CODEC"></Translator></div>
                    <Select
                        id='voip-codec-rtp-redundant-codec'
                        name='codec'
                        label={<Translator path="voip.codec.label.RTP_REDUNDANT_CODEC"></Translator>}
                        options={[
                            {
                                text: t('voip.codec.label.DISABLE_RTP_REDUNTANT_CODEC'),
                                value: -1 
                            },
                            {
                                text: 'u-law',
                                value: 0
                            },
                            {
                                text: 'a-law',
                                value: 8
                            },
                            {
                                text: 'G.729',
                                value: 18
                            },
                        ]}
                        value={voipCodec.rtp_redundant.codec}
                        onChange={(e) => {
                            voipCodec.rtp_redundant.codec = e.target.value ? Number(e.target.value) : null
                            setVoipCodec({...voipCodec})
                        }}
                    ></Select>

                    <Select
                        id='voip-codec-rtp-redundant-payload-type'
                        name='payload_type'
                        label={<Translator path="voip.codec.label.RTP_REDUNDANT_PAYLOAD_TYPE"></Translator>}
                        options={[
                            ...[
                                96, 101, 102, 107, 108, 109, 110, 111,
                                112, 113, 114, 115, 116, 117, 118, 119,
                                120, 121, 122, 123, 124, 125, 126, 127
                            ].map(payload => ({text: `${payload}`, value: payload}))
                        ]}
                        value={voipCodec.rtp_redundant.payload_type}
                        onChange={(e) => {
                            voipCodec.rtp_redundant.payload_type = e.target.value ? Number(e.target.value) : null
                            setVoipCodec({...voipCodec})
                        }}
                    ></Select>
                </div>
                <div className="card mt2">
                <div className='subtitle'><Translator path="voip.codec.label.OPTION_PACKING_ORDER"></Translator></div>
                    <Select
                        id='voip-codec-option-packing-order'
                        name='packing_order'
                        label={<Translator path="voip.codec.label.OPTION_PACKING_ORDER"></Translator>}
                        options={[
                            {
                                text: t('voip.codec.label.BIT_RATE_LEFT'),
                                value: 1
                            },
                            {
                                text: t('voip.codec.label.BIT_RATE_RIGHT'),
                                value: 2
                            },
                        ]}
                        value={voipCodec.option.packing_order}
                        onChange={(e) => {
                            voipCodec.option.packing_order = e.target.value ? Number(e.target.value) : null
                            setVoipCodec({...voipCodec})
                        }}
                    ></Select>

                    <Select
                        id='voip-codec-option-bit-rate'
                        name='bit_rate'
                        label={<Translator path="voip.codec.label.OPTION_BIT_RATE"></Translator>}
                        options={[
                            {
                                text: 'G723-5K3',
                                value: 0
                            },
                            {
                                text: 'G723-6K3',
                                value: 1
                            },
                        ]}
                        value={voipCodec.option.bit_rate}
                        onChange={(e) => {
                            voipCodec.option.bit_rate = e.target.value ? Number(e.target.value) : null
                            setVoipCodec({...voipCodec})
                        }}
                    ></Select>
                </div>
            </div>
            <div className="card mt2">
                <div className='subtitle'><Translator path="voip.codec.label.ENABLED_CODECS"></Translator></div>
                <div className="codec-lists-wrapper">
                    <div className="codec-list-left">
                        <div>
                            <Translator path="voip.codec.label.AVAILABLE_CODECS"></Translator>
                        </div>
                        <div className="active-codec-list" onDragOver={e => onDragOver(e)} onDrop={e => onDrop(e, 'disable', 'root')}>
                            {
                                voipCodec.available_codecs.filter(cod => !voipCodec.type[cod].enable)
                                    .map((cod, key) => 
                                        <div
                                            key={key}
                                            draggable="true"
                                            onDragStart={e => onDragStart(e, cod)}
                                        >
                                            <fieldset className="active-codec-wrapper">
                                                <legend>
                                                    {cod}
                                                </legend>
                                                <div className="align-center">
                                                    <Button type="button" onClick={e => enableCodec(cod, 'root')} id={`btn-add-codec-${cod}`}
                                                        circular='true'  text='+'>
                                                    </Button>
                                                </div>
                                            </fieldset>
                                        </div>)
                            }
                        </div>
                    </div>
                    <div className="codec-list-right">
                        <div>
                            <Translator path="voip.codec.label.ENABLED_CODECS"></Translator>
                        </div>
                        <div  className="active-codec-list" onDragOver={e => onDragOver(e)} onDrop={e => onDrop(e, 'enable', 'root')}>
                            {
                                voipCodec.available_codecs.filter(cod => voipCodec.type[cod].enable)
                                    .sort((e1, e2) => voipCodec.type[e1].precedence - voipCodec.type[e2].precedence)
                                        .map((cod, key) => 
                                            <fieldset
                                                className="active-codec-wrapper"
                                                key={key}
                                                onDrop ={e => onDrop(e, 'enable', voipCodec.type[cod].precedence)}
                                                draggable="true"
                                                onDragStart={e => onDragStart(e, cod)}
                                            >
                                                <legend>{cod}</legend>
                                                <div className="codec-handle-controls">
                                                    <div>
                                                        <Input id={`voip-codec-type-precedence-${cod}`}
                                                            name={`codec-${cod}-precedence`}
                                                            label={<Translator path="voip.codec.label.PRECEDENCE"></Translator>}
                                                            value={voipCodec.type[cod].precedence}
                                                            readOnly={true}
                                                        ></Input>
                                                    
                                                        <Select
                                                            id={`voip-codec-type-packetization-${cod}`}
                                                            name={`codec-${cod}-packetization`}
                                                            label={<Translator path="voip.codec.label.PACKETIZATION"></Translator>}
                                                            options={getCodecPacktizationOptions(cod).map(pkt => ({text: `${pkt}ms`, value: pkt}))}
                                                            value={voipCodec.type[cod].packetization}
                                                            onChange={(e) => {
                                                                voipCodec.type[cod].packetization = e.target.value ? Number(e.target.value) : null
                                                                setVoipCodec({...voipCodec})
                                                            }}
                                                        ></Select>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            type="button"
                                                            onClick={e => disableCodec(cod)}
                                                            id={`btn-remove-codec-${cod}`}
                                                            circular="true"
                                                            isError="true"
                                                            text="-">
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            disabled={voipCodec.type[cod].precedence === 1}
                                                            onClick={e => enableCodec(cod, voipCodec.type[cod].precedence - 1)}
                                                            id={`btn-up-codec-${cod}`}
                                                            circular="true"
                                                            text={<span> &#8593; </span>}>
                                                        </Button>
                                                        <Button
                                                            type="button"
                                                            disabled={
                                                                !voipCodec.available_codecs.filter(cod => voipCodec.type[cod].enable)
                                                                    .some(item => voipCodec.type[item].precedence > voipCodec.type[cod].precedence)
                                                            } onClick={e => enableCodec(cod, voipCodec.type[cod].precedence + 1)}
                                                            id={`btn-down-codec-${cod}`}
                                                            circular="true"
                                                            text={<span> &#8595; </span>}>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </fieldset>)
                            }
                            <span className="itb-input-error-text">
                                {voipCodec.available_codecs.filter(cod => voipCodec.type[cod].enable).length === 0 ? t('voip.codec.message.error.MUST_CHOOSE_AT_LEAST_ONE_CODEC') : ''} 
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveVoipCodec(backend, voipCodec)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipCodec ? <Loading show={true}></Loading> :

        <div id='voip-codec-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="voip.codec.title.CODEC"></Translator></div>

            <Button id='back-to-voip-codecs'
                text={<Translator path="common.label.BACK"></Translator>}
                onClick={() => history.push('/voip/codec')}
            ></Button>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            {!isFormSegment ? <Form
                onSubmit={submit}
                buttonId='button-save'
                isPristine={pristine}
                key={pristine}
                disableButton={voipCodec.available_codecs.filter(cod => voipCodec.type[cod].enable).length === 0}
            >
                {voipCodecForm()}
            </Form> : 

            <FormSegment
                title={formSegment.title} 
                active={formSegment.active} 
                nextLabel={formSegment.nextLabel} 
                nextClicked={submit} 
            >
                {voipCodecForm()}
            </FormSegment>}

        </div>
    )
}

import React, { useContext, useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
// import './tools.css'
import { MainHeaderContext } from '../../../components/main-header/main-header-state';
import { useTranslation } from 'react-i18next';
import ToolsState from './tools-context'
import Tabs from '../../../components/tabs/tabs'
import ToolsPing from './tools-ping';
import ToolsTR069Inform from './tools-tr069-inform';
import ToolsTraceroute from './tools-traceroute';
import LoopBackDetectSettings from './loop_back_detect/loop-back-detect-settings'
/*IMPORTS_TAG*/

export default function ToolsPage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const tabs = [
        { label: t('tools.ping.title.PING'), route: '/tools/ping' },
        { label: t('tools.tr069_inform.title.TR069_INFORM'), route: '/tools/tr069-inform' },
        { label: t('tools.traceroute.title.TRACEROUTE'), route: '/tools/traceroute' },
        { label: t('menu.LOOP_BACK_DETECT'), route: '/tools/loop-back-detect' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.TOOLS'))
        header.backRoute.set('/tools/ping')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('tools') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <ToolsState>
            <Tabs
                data={tabs}
                route={history.location.pathname}
                activateFn={changeRoute}
            ></Tabs>

            <Switch>
                <Route path='/tools/ping' render={(props) => {
                    return <ToolsPing {...props} ></ToolsPing>
                }}></Route>

                <Route path='/tools/tr069-inform' render={(props) => {
                    return <ToolsTR069Inform {...props} ></ToolsTR069Inform>
                }}></Route>

                <Route path='/tools/traceroute' render={(props) => {
                    return <ToolsTraceroute {...props} ></ToolsTraceroute>
                }}></Route>

                <Route path='/tools/loop-back-detect' render={(props) => {
                    return <LoopBackDetectSettings {...props} ></LoopBackDetectSettings>
                }}></Route>
                
                {/*ROUTES_TAG*/}
            </Switch>
        </ToolsState>
    )
}

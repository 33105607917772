export const QosTCIPVersionEnumeration = {
    TRAFFIC_CTRL_IPV4: 1,
    TRAFFIC_CTRL_IPV6: 2
}

export const QosTCDirectionEnumeration = {
    UPSTREAM: 0,
    DOWNSTREAM: 1
}
export const QosTCProtocolTypeEnumeration = {
    TRAFFIC_CTRL_NONE: 0,
    TRAFFIC_CTRL_TCP: 1,
    TRAFFIC_CTRL_UDP: 2,
    TRAFFIC_CTRL_ICMP: 3,
    TRAFFIC_CTRL_TCP_UDP: 4,
}


export const QosTCStruct = {
    ip_version: QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4,
    direction: QosTCDirectionEnumeration.UPSTREAM,
    interfaceID: "",
    protocol_type: QosTCProtocolTypeEnumeration.TRAFFIC_CTRL_NONE,
    src_port: 0,
    dst_port: 0,
    limit_rate: 0,
    ip4_config: {
        src_ip: "",
        src_prefix_length: 0,
        dst_ip: "",
        dst_prefix_length: 0
    },
    ip6_config: {
        src_ip: "",
        src_prefix_length: 0,
        dst_ip: "",
        dst_prefix_length: 0
    }
}

export const getIPVersionEnumerationElementsName = (element, t) => {
    switch (element) {
        case QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4:
            return t('common.label.IPV4')
        case QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV6:
            return t('common.label.IPV6')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getDirectionEnumerationElementsName = (element, t) => {
    switch (element) {
        case QosTCDirectionEnumeration.DOWNSTREAM:
            return t('advanced.qos_traffic_control.label.DOWNSTREAM')
        case QosTCDirectionEnumeration.UPSTREAM:
            return t('advanced.qos_traffic_control.label.UPSTREAM')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}

export const getProtocolEnumerationElementsName = (element, t) => {
    switch (element) {
        case QosTCProtocolTypeEnumeration.TRAFFIC_CTRL_NONE:
            return t('advanced.qos_classification.label.NO_PROTO')
        case QosTCProtocolTypeEnumeration.TRAFFIC_CTRL_TCP:
            return t('advanced.qos_classification.label.TCP_PROTO')
        case QosTCProtocolTypeEnumeration.TRAFFIC_CTRL_UDP:
            return t('advanced.qos_classification.label.UDP_PROTO')
        case QosTCProtocolTypeEnumeration.TRAFFIC_CTRL_TCP_UDP:
            return (t('common.label.TCP') + "/" + t('common.label.UDP'))
        case QosTCProtocolTypeEnumeration.TRAFFIC_CTRL_ICMP:
            return t('advanced.qos_classification.label.ICMP_PROTO')
        default:
            return t('common.message.error.FIELD_INVALID')
    }
}


export const createDirectionOptions = (t) => {
    let direction = []
    Object.keys(QosTCDirectionEnumeration).forEach(key => {
        direction.push(
            {
                value: QosTCDirectionEnumeration[key],
                text: getDirectionEnumerationElementsName(QosTCDirectionEnumeration[key], t)
            }
        )
    })
    return direction
}

export const createIPVersionOptions = (t) => {
    let IPVersionOptions = []
    Object.keys(QosTCIPVersionEnumeration).forEach(key => {
        IPVersionOptions.push(
            {
                value: QosTCIPVersionEnumeration[key],
                text: getIPVersionEnumerationElementsName(QosTCIPVersionEnumeration[key], t)
            }
        )
    })
    return IPVersionOptions
}

export const createProtocolOptions = (t) => {
    let IPProtocolOptions = []
    Object.keys(QosTCProtocolTypeEnumeration).forEach(key => {
        IPProtocolOptions.push(
            {
                value: QosTCProtocolTypeEnumeration[key],
                text: getProtocolEnumerationElementsName(QosTCProtocolTypeEnumeration[key], t)
            }
        )
    })
    return IPProtocolOptions
}

import React, { useEffect, useContext, useState } from 'react'
import { BackendContext } from '../backend/backend';
import { fetchDevice } from './resources/device';

export const SessionCheckContext = React.createContext()

const SESSION_INTERVAL = 30000

export default function SessionCheck({activeSession, children}) {

    const [device, setDevice] = useState(null)
    const [disabled, setDisabled] = useState(false)

    let backend = useContext(BackendContext)

    useEffect(() => {

        activeSession && !disabled && fetchDevice(backend, setDevice)

        // eslint-disable-next-line
    }, [disabled, activeSession])

    const fetchDeviceData = (mounted) => {

        if(disabled || !activeSession){
            return
        }

        mounted && fetchDevice(backend, setDevice) && backend.retrieveFresh('system')
    }

    useEffect(() => {

        if(!device)
            return

        let mounted = true
        setTimeout(() => fetchDeviceData(mounted), SESSION_INTERVAL)

        return () => mounted = false

        // eslint-disable-next-line
    }, [device])

    return <SessionCheckContext.Provider value={{
        setDisabled: setDisabled
    }}>
            {children}
    </SessionCheckContext.Provider>
}
import React, { useState, useEffect, useContext } from 'react'

import './ipv6-proxy-mld.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchIPv6ProxyMLD, saveIPv6ProxyMLD } from '../../../resources/ipv6-proxy-mld';
import { fetchServiceSystem } from '../../../resources/system';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input'
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import extraValidators from '../../../common/validators';
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next'

export default function IPv6ProxyMLD({isWizard, setSaved, isFormSegment, formSegment}) {
    const [ipv6ProxyMLD, setIPv6ProxyMLD] = useState(null)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    let [system, setSystem] = useState(true)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchIPv6ProxyMLD(backend, setIPv6ProxyMLD)
        fetchServiceSystem(backend, setSystem)

        // eslint-disable-next-line
    }, [])

    const ipv6ProxyMLDForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
                <Input id='ipv6-proxy-mld-robust-count'
                    name='robust_count'
                    type="number"
                    label={<Translator path="ipv6.proxy_mld.label.ROBUST_COUNT"></Translator>}
                    value={ipv6ProxyMLD.robust_count}
                    onChange={(e) => {
                            ipv6ProxyMLD.robust_count = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                            setIPv6ProxyMLD({...ipv6ProxyMLD})
                    }}
                    validators={[extraValidators.required, extraValidators.isNumber, {fn: extraValidators.value, params:{min: -2147483648, max: 2147483647}}]}
                ></Input>
                <Input id='ipv6-proxy-mld-query-interval'
                    name='query_interval'
                    type="number"
                    label={<Translator path="ipv6.proxy_mld.label.QUERY_INTERVAL"></Translator>}
                    value={ipv6ProxyMLD.query_interval}
                    onChange={(e) => {
                        ipv6ProxyMLD.query_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setIPv6ProxyMLD({...ipv6ProxyMLD})
                    }}
                    validators={[extraValidators.required, extraValidators.isNumber, {fn: extraValidators.value, params:{min: -2147483648, max: 2147483647}}]}
                ></Input>
                
                <Input id='ipv6-proxy-mld-query-response-interval'
                    name='query_response_interval'
                    type="number"
                    label={<Translator path="ipv6.proxy_mld.label.QUERY_RESPONSE_INTERVAL"></Translator>}
                    value={ipv6ProxyMLD.query_response_interval}
                    onChange={(e) => {
                        ipv6ProxyMLD.query_response_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setIPv6ProxyMLD({...ipv6ProxyMLD})
                    }}
                    validators={[extraValidators.required, extraValidators.isNumber, {fn: extraValidators.value, params:{min: -2147483648, max: 2147483647}}]}
                    ></Input>

                <Input id='ipv6-proxy-mld-response-interval-of-last-group-member'
                    name='response_interval_of_last_group_member'
                    type="number"
                    label={<Translator path="ipv6.proxy_mld.label.RESPONSE_INTERVAL_OF_LAST_GROUP_MEMBER"></Translator>}
                    value={ipv6ProxyMLD.response_interval_of_last_group_member}
                    onChange={(e) => {
                        ipv6ProxyMLD.response_interval_of_last_group_member = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setIPv6ProxyMLD({...ipv6ProxyMLD})
                    }}
                    validators={[extraValidators.required, extraValidators.isNumber, {fn: extraValidators.value, params:{min: -2147483648, max: 2147483647}}]}
                ></Input>
       
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveIPv6ProxyMLD(backend, ipv6ProxyMLD)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    const hasIPv6Requiremnts = () => {
        if(isWizard || system === true) {
            return true
        }

        if(system) {
            if(system.ipv6) {
                if(system.ipv6.enabled) {
                    return true
                }
            }
        }

        return false
    }

    return (
        !ipv6ProxyMLD ? <Loading show={true}></Loading> :

        <div id='ipv6-proxy-mld-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="ipv6.proxy_mld.title.PROXY_MLD"></Translator></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="ipv6.proxy_mld.title.PROXY_MLD_SETTINGS"></Translator></div>

                {
                    hasIPv6Requiremnts() ? null : 
                    <div>
                        <p className='ipv6-proxy-mld-info'>
                            {t(`ipv6.proxy_mld.message.IPV6_DISABLED`)}
                            <br/>
                            {t(`common.message.warning.IPV6_ENABLE`)}
                            <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                        </p>
                    </div>
                }

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    success={success}
                    continueFn={dismissModal}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                {!isFormSegment ? <Form
                    onSubmit={submit}
                    buttonId='button-save'
                    disableButton={!hasIPv6Requiremnts()}
                >
                    {ipv6ProxyMLDForm()}
                </Form> : 

                <FormSegment
                    title={formSegment.title} 
                    active={formSegment.active} 
                    nextLabel={formSegment.nextLabel} 
                    nextClicked={submit} 
                >
                    {ipv6ProxyMLDForm()}
                </FormSegment>}
            </div>


        </div>
    )
}

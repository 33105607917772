import React, { useState, useEffect, useContext } from 'react'
import Ip, {} from 'ip'

import './firewall-ip-port-filtering.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchFirewallIpPortFiltering, saveFirewallIpPortFiltering } from '../../../resources/firewall-ip-port-filtering';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import RemoveIcon from '../../../../components/icons/remove';
import Select from '../../../../components/select/select';
import extraValidators from '../../../common/validators';
import Input from '../../../../components/input/input';
import { fetchNetworkWanList } from '../../../resources/network-wan';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import Button from '../../../../components/button/button';

export default function FirewallIpPortFiltering({isWizard, setSaved, isFormSegment, formSegment}) {
    const [firewallIpPortFiltering, setFirewallIpPortFiltering] = useState(null)
    const [firewallIpPortFilteringPagination, setFirewallIpPortFilteringPagination] = useState({pageNumber: 1, pageSize: 5})

    const [newRegister, setNewRegister] = useState({
        dest_ip_address: '',
        dest_port_from: '',
        dest_port_to: '',
        dest_subnet_mask: 24,
        direction: 0,
        interface_index: 'any',
        protocol: 1,
        rule_action: false,
        source_ip_address: '',
        source_port_from: '',
        source_port_to: '',
        source_subnet_mask: 24,

    })

    const [pristine, setPristine] = useState(true)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const [hasChange, setHasChange] = useState(false)

    const [interfaces, setInterfaces] = useState([])

    const { t } = useTranslation()

    const backend = useContext(BackendContext)


    useEffect(() => {

        fetchNetworkWanList(backend, setInterfaces)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        fetchFirewallIpPortFiltering(backend, setFirewallIpPortFiltering)

        // eslint-disable-next-line
    }, [])

    const getProtocol = (proto) => {
        switch(proto) {
            case 1: return t('common.label.TCP')
            case 2: return t('common.label.UDP')
            case 3: return t('common.label.ICMP')
            default: return '-'
        }
    }

    const validatePort = value => {
        return !value || (value && value >= 0 && value <= 65535) ? '' : t('common.message.error.VALIDATION_PORT_RANGE_IP_PORT_FILTER')
    }

    const validateRangeSource = value => {
        return (newRegister.source_port_from > newRegister.source_port_to) ? t('common.message.error.VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM') : ''        
    }

    const validateRangeDest = value => {
        return (newRegister.dest_port_from > newRegister.dest_port_to) ? t('common.message.error.VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM') : ''
    }

    const getColumnsIpPort = () => {

        return [
            {header: 'Nº', align: 'center', size: '20px'},
            {header: t('common.label.SOURCE'), align: 'center', size: '250px'},
            {header: t('common.label.DESTINY'), align: 'center', size: '250px'},
            {header: t('common.label.DIRECTION_ABREVIATION'), align: 'center'},
            {header: t('firewall.port_forward.label.INTERFACE_ABREVIATION'), align: 'center'},
            {header: t('firewall.port_forward.label.PROTOCOL_ABREVIATION'), align: 'center'},
            {header: t('common.label.RULE'), align: 'center'},
            {header: t('common.label.OPERATIONS'), align: 'center'},
        ]
    }

    const getSubnetMaskList = () => {
        const masks = []

        for(let i = 8; i <= 32; i++) {
            masks.push({value: i, text: i})
        }

        return masks
    }

    const getIpPort = () => {
        let begining = (firewallIpPortFilteringPagination.pageNumber - 1) * (firewallIpPortFilteringPagination.pageSize)
        let end = begining + firewallIpPortFilteringPagination.pageSize
        if (end > firewallIpPortFiltering.filter_lists.length) {
            end = firewallIpPortFiltering.filter_lists.length
        }
        let firewallIpPortFilteringLines = [];
        for(let i = begining; i < end; i++) {
            if(firewallIpPortFiltering.filter_lists[i]){
                firewallIpPortFilteringLines.push(
                    [
                        i + 1,
                        firewallIpPortFiltering.filter_lists[i].source_ip_address ? `${firewallIpPortFiltering.filter_lists[i].source_ip_address}/${firewallIpPortFiltering.filter_lists[i].source_subnet_mask}:[${firewallIpPortFiltering.filter_lists[i].source_port_from}-${firewallIpPortFiltering.filter_lists[i].source_port_to}]` : '-',
                        firewallIpPortFiltering.filter_lists[i].dest_ip_address ? `${firewallIpPortFiltering.filter_lists[i].dest_ip_address}/${firewallIpPortFiltering.filter_lists[i].dest_subnet_mask}:[${firewallIpPortFiltering.filter_lists[i].dest_port_from}-${firewallIpPortFiltering.filter_lists[i].dest_port_to}]` : '-',
                        firewallIpPortFiltering.filter_lists[i].direction === 0 ? t('common.label.OUTGOING') : t('common.label.INGOING'),
                        firewallIpPortFiltering.filter_lists[i].interface_index,
                        getProtocol(firewallIpPortFiltering.filter_lists[i].protocol),
                        firewallIpPortFiltering.filter_lists.rule_action ? t('common.label.ALLOW') : t('common.label.DENY'),
                        <span className="clickable" onClick={e => removeItemIpPort(firewallIpPortFiltering.filter_lists[i])}><RemoveIcon></RemoveIcon></span>
                    ]);
            }
        }
        return firewallIpPortFilteringLines;
            
    }

    const removeItemIpPort = (ipPortID) => {
        firewallIpPortFiltering.filter_lists = firewallIpPortFiltering.filter_lists.filter(it => it !== ipPortID)
        setFirewallIpPortFiltering({...firewallIpPortFiltering})
        setPristine(false)
    }

    const getInterfaceOptions = () => {
        // return newRegister.direction === 1 ? 
        //     [{ value: 'any', text: t('common.label.ALL_INTERFACES')}, ...interfaces.map(it => ({value: it.id, text: it.id}))] : 
        //     [{ value: 'any', text: t('common.label.ALL_INTERFACES')}]
        
        return newRegister.direction === 0 ? [{ value: 'any', text: t('common.label.ALL_INTERFACES')}] : [{ value: 'any', text: t('common.label.ALL_INTERFACES')}, ...interfaces.map(it => ({value: it.id, text: it.id}))]
    }

    const addNewRegister = () => {
        if(newRegister.source_port_from === '') {
            newRegister.source_port_from = 0
        }

        if(newRegister.source_port_to === '') {
            newRegister.source_port_to = 0
        }

        if(newRegister.dest_port_from === '') {
            newRegister.dest_port_from = 0
        }

        if(newRegister.dest_port_to === '') {
            newRegister.dest_port_to = 0
        }

        if(newRegister.source_ip_address === '') {
            if(!newRegister.source_port_to) {
                newRegister.source_port_to = newRegister.source_port_from
            }
            newRegister.source_subnet_mask = 0
        } else if(!newRegister.source_port_to) {
            newRegister.source_port_to = newRegister.source_port_from
        }

        if(newRegister.dest_ip_address === '') {
            if(!newRegister.dest_port_to) {
                newRegister.dest_port_to = newRegister.dest_port_from
            }
            newRegister.dest_subnet_mask = 0
        } else if(!newRegister.dest_port_to) {
            newRegister.dest_port_to = newRegister.dest_port_from
        }

        if (newRegister.direction === 0) {
            newRegister.interface_index = 'any'
        }

        if(newRegister.protocol === 3) {
            newRegister.source_port_from = 0
            newRegister.source_port_to = 0

            newRegister.dest_port_from = 0
            newRegister.dest_port_to = 0
        }

        if(newRegister.source_port_from > newRegister.source_port_to) {
            setErrorMessage(t('common.message.error.VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM'))
            setError(true)
            return
        }

        if(newRegister.dest_port_from > newRegister.dest_port_to) {
            setErrorMessage(t('common.message.error.VALIDATION_PORT_RANGE_TO_MORE_THAN_FROM'))
            setError(true)
            return
        }

        setNewRegister({...newRegister})

        var ipMod = Ip

        var currentSourceNetMask = 0
        var currentSourceNetAddr = ''

        var currentDestNetMask = 0
        var currentDestNetAddr = ''

        if(newRegister.source_ip_address !== '') {
            currentSourceNetMask = ipMod.fromPrefixLen(newRegister.source_subnet_mask)
            currentSourceNetAddr = ipMod.mask(newRegister.source_ip_address, currentSourceNetMask)
        }

        if(newRegister.dest_ip_address !== '') {
            currentDestNetMask = ipMod.fromPrefixLen(newRegister.dest_subnet_mask)
            currentDestNetAddr = ipMod.mask(newRegister.dest_ip_address, currentDestNetMask)
        }

        if(ipMod.toLong(currentSourceNetAddr) === ipMod.toLong(currentDestNetAddr)) {
            setErrorMessage(t('common.message.error.VALIDATION_SAME_SRC_DST_IP'))
            setError(true)
            return
        }

        let alreadyExists = -1

        firewallIpPortFiltering.filter_lists.forEach( (flt, index) => {            
            var fltSourceNetMask = 0
            var fltSourceNetAddr = ''

            var fltDestNetMask = 0
            var fltDestNetAddr = ''

            if(flt.source_ip_address !== '') {
                fltSourceNetMask = ipMod.fromPrefixLen(flt.source_subnet_mask)
                fltSourceNetAddr = ipMod.mask(flt.source_ip_address, fltSourceNetMask)
            }

            if(flt.dest_ip_address !== '') {
                fltDestNetMask = ipMod.fromPrefixLen(flt.dest_subnet_mask)
                fltDestNetAddr = ipMod.mask(flt.dest_ip_address, fltDestNetMask)
            }

            if(
                ipMod.toLong(currentSourceNetAddr) === ipMod.toLong(fltSourceNetAddr) &&
                ipMod.toLong(currentDestNetAddr) === ipMod.toLong(fltDestNetAddr) &&
                newRegister.direction === flt.direction &&
                newRegister.protocol === flt.protocol &&
                newRegister.source_port_from === flt.source_port_from &&
                newRegister.source_port_to === flt.source_port_to &&
                newRegister.dest_port_from === flt.dest_port_from &&
                newRegister.dest_port_to === flt.dest_port_to
            ) {
                alreadyExists = index
            }
        })

        if(alreadyExists !== -1) {
            setError(true)
            setErrorMessage(t('firewall.ip_port_filtering.message.error.RULE_CONFLICT', {rule: alreadyExists + 1}))
            return
        }

        firewallIpPortFiltering.filter_lists.push(newRegister)
        setFirewallIpPortFiltering({...firewallIpPortFiltering})
        setNewRegister({
            dest_ip_address: '',
            dest_port_from: '',
            dest_port_to: '',
            dest_subnet_mask: 24,
            direction: 0,
            interface_index: 'any',
            protocol: 1,
            rule_action: false,
            source_ip_address: '',
            source_port_from: '',
            source_port_to: '',
            source_subnet_mask: 24,
        })
        setPristine(false)
        setHasChange(false)
    }

    const changePage = page => {
        setFirewallIpPortFilteringPagination({...firewallIpPortFilteringPagination, pageNumber: page})
    }

    const firewallIpPortFilteringForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className="card mt2">
                <div className='subtitle'> <Translator path="firewall.ip_port_filtering.title.DEFAULT_ACTIONS"></Translator></div>
                    <div className='row'>
                        <Select
                            id='firewall-ip-port-filtering-incoming-default-action'
                            name='incoming_default_action'
                            label={<Translator path="firewall.ip_port_filtering.label.ACTION_DEFAULT_INCOMING"></Translator>}
                            options={[{value: 1, text: t('common.label.ALLOW')}, {value: 0, text: t('common.label.DENY')}]}
                            value={firewallIpPortFiltering.incoming_default_action}
                            onChange={(e) => {
                                firewallIpPortFiltering.incoming_default_action = Number(e.target.value)
                                setFirewallIpPortFiltering({...firewallIpPortFiltering})
                            }}
                        ></Select>

                        <Select
                            id='firewall-ip-port-filtering-outgoing-default-action'
                            name='outgoing_default_action'
                            label={<Translator path="firewall.ip_port_filtering.label.ACTION_DEFAULT_OUTGOING"></Translator>}
                            options={[{value: 1, text: t('common.label.ALLOW')}, {value: 0, text: t('common.label.DENY')}]}
                            value={firewallIpPortFiltering.outgoing_default_action}
                            onChange={(e) => {
                                firewallIpPortFiltering.outgoing_default_action = Number(e.target.value)
                                setFirewallIpPortFiltering({...firewallIpPortFiltering})
                            }}
                        ></Select>
                    </div>
            </div>
            <div className='card mt2'>
            <div className='subtitle'> {<Translator path="firewall.ip_port_filtering.title.INSERT_NEW_FILTER"></Translator>} </div>
                <div className="row larger-field-wrapper">
                    <div className="firewall-filter-group">
                        <div className="firewall-filter-sub-group">
                            <Input id='firewall-ip-port-filtering-source-ip-address'
                                name='source_ip_address'
                                type="text"
                                label={<Translator path="firewall.ip_port_filtering.label.SOURCE_IP"></Translator>}
                                value={newRegister.source_ip_address}
                                onChange={(e) => {
                                    newRegister.source_ip_address = e.target.value
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                                validators={[
                                    extraValidators.nonASCII, 
                                    extraValidators.validateIPv4,
                                    extraValidators.validateIfNotLocalhost
                                ]}
                                errorMessage={hasChange && newRegister.source_ip_address === '' && newRegister.dest_ip_address === '' ? t('firewall.ip_port_filtering.warning.LACK_OF_IP') : ''}
                            ></Input>

                            <Select
                                id='firewall-ip-port-filtering-source-subnet-mask'
                                name='source_subnet_mask'
                                label={<Translator path="common.label.MASK"></Translator>}
                                options={getSubnetMaskList()}
                                value={newRegister.source_subnet_mask}
                                onChange={(e) => {
                                    newRegister.source_subnet_mask = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                            ></Select>
                        </div>
                            <div className="firewall-filter-sub-group">
                                <Input id='firewall-ip-port-filtering-source-port-from'
                                    name='source_port_from'
                                    type="text"
                                    disabled={newRegister.protocol === 3}
                                    label={<Translator path="firewall.ip_port_filtering.label.SOURCE_PORT_FROM"></Translator>}
                                    value={newRegister.source_port_from}
                                    dependentValues={[newRegister.protocol]}
                                    onChange={(e) => {
                                        newRegister.source_port_from = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                        setNewRegister({...newRegister})
                                        setHasChange(true)
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => newRegister.protocol !== 3,
                                            validators: [
                                                {fn: extraValidators.nonASCII, params: ''},
                                                {fn: extraValidators.isNumber, params: ''},
                                                {fn: validatePort, params: ''},
                                            ]
                                        }}, 
                                    ]}
                                ></Input>

                                <Input id='firewall-ip-port-filtering-source-port-to'
                                    name='source_port_to'
                                    type="text"
                                    disabled={newRegister.protocol === 3}
                                    label={<Translator path="firewall.ip_port_filtering.label.SOURCE_PORT_TO"></Translator>}
                                    value={newRegister.source_port_to}
                                    dependentValues={[newRegister.protocol]}
                                    onChange={(e) => {
                                        newRegister.source_port_to = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                        setNewRegister({...newRegister})
                                        setHasChange(true)
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => newRegister.protocol !== 3,
                                            validators: [
                                                {fn: extraValidators.nonASCII, params: ''},
                                                {fn: extraValidators.isNumber, params: ''},
                                                {fn: validatePort, params: ''},
                                                {fn: validateRangeSource, params: ''},
                                            ]
                                        }},
                                    ]}
                                ></Input>
                            </div>                          
                        </div>
                    </div>
                <div className="row larger-field-wrapper">
                    <div className="firewall-filter-group">
                        <div className="firewall-filter-sub-group">
                            <Input id='firewall-ip-port-filtering-dest-ip-address'
                                name='dest_ip_address'
                                type="text"
                                label={<Translator path="firewall.ip_port_filtering.label.DESTINY_IP"></Translator>}
                                value={newRegister.dest_ip_address}
                                onChange={(e) => {
                                    newRegister.dest_ip_address = e.target.value
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                                validators={[
                                    extraValidators.nonASCII, 
                                    extraValidators.validateIPv4,
                                    extraValidators.validateIfNotLocalhost
                                ]}
                                errorMessage={hasChange && newRegister.source_ip_address === '' && newRegister.dest_ip_address === '' ? t('firewall.ip_port_filtering.warning.LACK_OF_IP') : ''}
                            ></Input>

                            <Select
                                id='firewall-ip-port-filtering-dest-subnet-mask'
                                name='dest_subnet_mask'
                                label={<Translator path="common.label.MASK"></Translator>}
                                options={getSubnetMaskList()}
                                value={newRegister.dest_subnet_mask}
                                onChange={(e) => {
                                    newRegister.dest_subnet_mask = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                            ></Select>
                        </div>
                            <div className="firewall-filter-sub-group">
                                <Input id='firewall-ip-port-filtering-dest-port-from'
                                    name='dest_port_from'
                                    type="text"
                                    disabled={newRegister.protocol === 3}
                                    label={<Translator path="firewall.ip_port_filtering.label.DESTINY_PORT_FROM"></Translator>}
                                    value={newRegister.dest_port_from}
                                    dependentValues={[newRegister.protocol]}
                                    onChange={(e) => {
                                        newRegister.dest_port_from = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                        setNewRegister({...newRegister})
                                        setHasChange(true)
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => newRegister.protocol !== 3,
                                            validators: [
                                                {fn: extraValidators.nonASCII, params: ''},
                                                {fn: extraValidators.isNumber, params: ''},
                                                {fn: validatePort, params: ''},
                                            ]
                                        }},
                                    ]}
                                ></Input>

                                <Input id='firewall-ip-port-filtering-dest-port-to'
                                    name='dest_port_to'
                                    type="text"
                                    disabled={newRegister.protocol === 3}
                                    label={<Translator path="firewall.ip_port_filtering.label.DESTINY_PORT_TO"></Translator>}
                                    value={newRegister.dest_port_to}
                                    dependentValues={[newRegister.protocol]}
                                    onChange={(e) => {
                                        newRegister.dest_port_to = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                        setNewRegister({...newRegister})
                                        setHasChange(true)
                                    }}
                                    validators={[
                                        {fn: extraValidators.optionalValidators, params: {
                                            shouldValidate: () => newRegister.protocol !== 3,
                                            validators: [
                                                {fn: extraValidators.nonASCII, params: ''},
                                                {fn: extraValidators.isNumber, params: ''},
                                                {fn: validatePort, params: ''},
                                                {fn: validateRangeDest, params: ''},
                                            ]
                                        }},
                                    ]}
                                ></Input>
                            </div>                          
                        </div>
                    </div>
                <div className="row larger-field-wrapper">
                    <div className="firewall-filter-group">
                        <div className="firewall-filter-sub-group">
                            <Select
                                id='firewall-ip-port-filtering-direction'
                                name='direction'
                                label={<Translator path="firewall.ip_port_filtering.label.DIRECTION"></Translator>}
                                options={[{value: 0, text: t('common.label.OUTGOING')}, {value: 1, text: t('common.label.INGOING')}]}
                                value={newRegister.direction}
                                onChange={(e) => {
                                    newRegister.direction = Number(e.target.value)
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                            ></Select>

                            <Select
                                key={newRegister.direction}
                                id='firewall-ip-port-filtering-interface-index'
                                name='interface_index'
                                label={<Translator path="common.label.INTERFACE"></Translator>}
                                options={getInterfaceOptions()}
                                value={newRegister.interface_index}
                                onChange={(e) => {
                                    newRegister.interface_index = e.target.value
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                                
                            ></Select>
                        </div>

                        <div className="firewall-filter-sub-group">
                            <Select
                                id='firewall-ip-port-filtering-protocol'
                                name='protocol'
                                label={<Translator path="common.label.PROTOCOL"></Translator>}
                                options={[{value: 1, text: t('common.label.TCP')}, {value: 2, text: t('common.label.UDP')}, {value: 3, text: t('common.label.ICMP')}]}
                                value={newRegister.protocol}
                                onChange={(e) => {
                                    let newProtocol = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                    if(newProtocol === 3){
                                        newRegister.source_port_from = ""
                                        newRegister.source_port_to = ""
                                        newRegister.dest_port_from = ""
                                        newRegister.dest_port_to = ""
                                    }
                                    newRegister.protocol = newProtocol
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                            ></Select>

                            <Select
                                id='firewall-ip-port-filtering-rule-action'
                                name='rule_action'
                                label={<Translator path="firewall.ip_port_filtering.label.FILTER_TYPE"></Translator>}
                                options={[{value: true, text: t('common.label.ALLOW')}, {value: false, text: t('common.label.DENY')}]}
                                value={newRegister.rule_action}
                                onChange={(e) => {
                                    newRegister.rule_action = e.target.value === "true"
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                            ></Select>
                        </div>
                    </div>
                </div>
                <Button
                    type='button'
                    id='btn-add-ip-filter'
                    text={<Translator path="common.label.ADD"></Translator>}
                    onClick={addNewRegister}
                    disabled={
                        (newRegister.source_ip_address === '' && newRegister.dest_ip_address === '')
                    }
                ></Button>
            </div>
            <div id='firewall-ip-port-filtering-table'>
                <div className='subtitle'> {<Translator path="firewall.ip_port_filtering.title.FILTER_LIST"></Translator>} </div>
                        { getIpPort().length > 0 ?
                            <div>
                                <div className="scroll-list">
                                    <List 
                                        lines={getIpPort()}
                                        columns={getColumnsIpPort()}
                                        width='1200px'
                                    ></List>
                                </div>
                                <Pager
                                    pageNumber={firewallIpPortFilteringPagination.pageNumber}
                                    totalElements={firewallIpPortFiltering.filter_lists.length}
                                    pageSize={firewallIpPortFilteringPagination.pageSize}
                                    pageChangeFn={changePage}>
                                </Pager>
                            </div>
                            :
                            <div id='firewall-ip-port-filtering-no-filters'>
                                <div className='info-card noelements-card'>
                                    <Translator path="firewall.ip_port_filtering.info.NO_FILTERS"/>
                                </div>
                            </div>  
                        }
            </div>
        </React.Fragment>
    }

    const submit = async() => {

        setErrorMessage("")

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveFirewallIpPortFiltering(backend, firewallIpPortFiltering, setErrorMessage)
        setErrorMessage(t('firewall.ip_port_filtering.message.error.RULE_CONFLICT') + errorMessage)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !firewallIpPortFiltering ? <Loading show={true}></Loading> :

        <div id='firewall-ip-port-filtering-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'>{<Translator path="firewall.ip_port_filtering.title.IP_PORT_FILTERING"></Translator>}</div>
            
            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                errorText={errorMessage}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>
        
            {!isFormSegment ? <Form
                onSubmit={submit}
                buttonId='button-save'
                isPristine={pristine}
                key={pristine}
            >
                {firewallIpPortFilteringForm()}
            </Form> : 

            <FormSegment
                title={formSegment.title} 
                active={formSegment.active} 
                nextLabel={formSegment.nextLabel} 
                nextClicked={submit} 
            >
                {firewallIpPortFilteringForm()}
            </FormSegment>}
        </div>
    )
}

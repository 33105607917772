import { backendStatus } from '../../backend/backend'

const URL = 'igmp_mld_snooping';

export async function fetchIgmpMldSnooping(backend, setIgmpMldSnooping) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setIgmpMldSnooping(result.content[0])
    }
}

export async function saveAdvancedIgmpMldSnooping(backend, igmpMldSnooping, setErrorMessage) {

    try{

        let result = await backend.update(`${URL}/0`, igmpMldSnooping)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || igmpMldSnooping)

    }catch(error){

        console.error('Error saving IGMP_MLD_SNOOPING:', error)

        return false
    }

}
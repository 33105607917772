import { backendStatus } from '../../backend/backend'

const URL = 'ddns';

export async function fetchServiceDynamicDns(backend, id, setDynamicDns) {

    if(id === null) {
        setDynamicDns({
            provider: 0,
            hostname: '',
            interface: '',
            username: '',
            password: '',
            enabled: 1,
            instance_num: 0,
            service_port: '',
            status: 0
        })
        return
    }
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setDynamicDns(result.content)
    }
    
}

export async function fetchServiceDynamicDnsList(backend, setDynamicDnsList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setDynamicDnsList(result.content)
    }
}

export async function deleteServiceDynamicDnsById(backend, id, setDynamicDnsList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchServiceDynamicDnsList(backend, setDynamicDnsList)
        return true
    }
    return false
}

export async function saveServiceDynamicDns(backend, dynamicDns, setErrorMessage) {

    try{

        let result = null;

        if(!dynamicDns.id) {
            result = await backend.create(`${URL}`, dynamicDns)
        }
        else {
            result = await backend.update(`${URL}/${dynamicDns.id}`, dynamicDns)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || dynamicDns)

    }catch(error){

        console.error('Error saving DYNAMIC_DNS:', error)

        return false
    }

}
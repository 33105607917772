import React, { useState, useEffect, useContext } from 'react'

import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form'
import List from '../../../../components/list/list';
import Button from '../../../../components/button/button'
import { BackendContext } from '../../../../backend/backend';
import { fetchRadioList} from '../../../resources/radio';
import { fetchWirelessList, cipher, ieeeStd } from '../../../resources/wireless';
import { fetchConnectedDevicesList } from '../../../resources/connected-devices';
import { fetchEasyMesh, easyMeshBackhaul } from '../../../resources/easy-mesh';
import extraValidators from '../../../common/validators';

/**
 * status Radio 5.0GHz 
 * 
 */
export function Wifi5GStatus({ history }) {
    const [radios, setRadios] = useState(null)
    const [wireless, setWireless] = useState(null)
    const [connected_device, setConnectedDevices] = useState(null)
    const [easymesh, setEasyMesh] = useState(null)

    const { t } = useTranslation()
    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchWirelessList(backend, setWireless)
        fetchRadioList(backend, setRadios)
        fetchConnectedDevicesList(backend, setConnectedDevices)
        fetchEasyMesh(backend, setEasyMesh)

        // eslint-disable-next-line
    }, [])

    const refresh5G = () => {
        fetchWirelessList(backend, setWireless)
        fetchRadioList(backend, setRadios)
        fetchConnectedDevicesList(backend, setConnectedDevices)
        fetchEasyMesh(backend, setEasyMesh)
    }

    const getWifiStatusColumns = () => {
        return [
            { header: t('Modo'), align: 'center', size: '80px' },
            { header: t('Banda'), align: 'center', size: '150px' },
            { header: t('SSID'), align: 'center', size: '200px' },
            { header: t('Canal'), align: 'center', size: '100px' },
            { header: t('network.wifi.status.label.CRYPTOGRAPHY'), align: 'center', size: '120px' },
            { header: t('BSSID'), align: 'center', size: '160px' },
            {
                header:
                    <div>
                        <div><Translator path='Clientes'></Translator></div>
                        <div><Translator path='conectados'></Translator></div>
                    </div>,
                align: 'center', size: '120px'
            },
        ]
    }

    const getWifiStatusVapColumns = () => {
        return [
            { header: t('VAP'), align: 'center', size: '80px' },
            { header: t('Banda'), align: 'center', size: '150px' },
            { header: t('SSID'), align: 'center', size: '200px' },
            { header: t('network.wifi.status.label.CRYPTOGRAPHY'), align: 'center', size: '120px' },
            { header: t('BSSID'), align: 'center', size: '160px' },
            {
                header:
                    <div>
                        <div><Translator path='Clientes'></Translator></div>
                        <div><Translator path='conectados'></Translator></div>
                    </div>,
                align: 'center', size: '120px'
            },
        ]
    }

    const getWifiStatus5GLines = () => {
        let has_radio5g = false
        let wirelessList = wireless
        let channel = radios[0].channel

        let wifiStatus5GLines = [];
        for (let i = 0; i < wirelessList.length; i++) {
            if (wirelessList[i].id === "wlan0") {
                has_radio5g = true
                wifiStatus5GLines.push([
                    wirelessList[i].mode === 0 ? 'AP' : t('Client'),
                    bandStrGen(wirelessList[i].ieee_std, 0),
                    wirelessList[i].ssid,
                    channel,
                    cipherStrGen(wirelessList[i].cipher),
                    wirelessList[i].bssid,
                    countConnDevices(connected_device, wirelessList[i].ssid, 1)
                ])
            }
        }

        if (has_radio5g)
            return wifiStatus5GLines
        else
            return []
    }

    const getWifiStatus5GVapLines = () => {
        let has_vap5g = false
        let wirelessList = wireless
        let easymeshList = easymesh

        let j = 1
        let wifiStatus5GVapLines = [];
        for (let i = 0; i < wirelessList.length; i++) {
            if (wirelessList[i].id.indexOf("wlan0-vap") !== -1 && extraValidators.reservedPrefix(wirelessList[i].ssid) === "") {
                has_vap5g = true
                wifiStatus5GVapLines.push([
                    j,
                    bandStrGen(wirelessList[i].ieee_std, 0),
                    wirelessList[i].ssid,
                    cipherStrGen(wirelessList[i].cipher),
                    wirelessList[i].bssid,
                    wirelessList[i].id === "wlan0-vap0" ? countConnDevicesVAP(connected_device, wirelessList[i].ssid, 1, easymeshList) : countConnDevices(connected_device, wirelessList[i].ssid, 1)
                ])
                j = j + 1
            }
        }

        if (has_vap5g)
            return wifiStatus5GVapLines
        else
            return []
    }

    const wifiStatus5GForm = () => {
        return (
            <React.Fragment>
                {/* Radio 5Ghz */}
                <div className='subtitle'><Translator path="network.wifi.status.title.WLAN_STATUS"></Translator></div>
                <div id='info-card-wifi-status'>
                    {getWifiStatus5GLines().length === 0 ?
                        <div>
                            <div className='info-card noelements-card'>
                                <Translator path="network.wifi.status.messages.WLAN_NOT_CONFIGURED"></Translator>
                            </div>
                            &nbsp;
                        </div>
                        :
                        <div className="network-wifi-status-5g">
                            <List
                                useTooltip={true}
                                lines={getWifiStatus5GLines()}
                                columns={getWifiStatusColumns()}
                            ></List>
                        </div>
                    }
                </div>

                {/* VAP 5Ghz */}
                <div id='info-card-wifi-status-vap'>
                    <div className='subtitle'><Translator path="network.wifi.status.title.WLAN_STATUS_VAP"></Translator></div>
                    {getWifiStatus5GVapLines().length === 0 || getWifiStatus5GLines().length === 0 ?
                        <div>
                            <div className='info-card noelements-card'>
                                <Translator path="network.wifi.status.messages.WLAN_VAP_NOT_CONFIGURED"></Translator>
                            </div>
                            &nbsp;
                        </div>
                        :
                        <div className="network-wifi-status-vap">
                            <List
                                useTooltip={true}
                                lines={getWifiStatus5GVapLines()}
                                columns={getWifiStatusVapColumns()}
                            ></List>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

    return (
        (!wireless || !radios || !connected_device || !easymesh) ? <Loading show={true}></Loading> :
            <React.Fragment>
                <div id='edit-wifi-status-page'>
                    <div className="subtitle"><Translator path="network.wifi.status.title.WLAN_STATUS_5G"></Translator></div>
                    <div className="row">
                        <div id='back-to-wifi-btn'>
                            <Button
                                text={<Translator path="common.label.BACK"></Translator>}
                                onClick={() => history.push('/network/wifi')}
                            ></Button>
                        </div>

                        <Button id='back-to-radio24ghz-btn'
                            text={<Translator path="network.wifi.status.messages.SHOW_2_4G"></Translator>}
                            onClick={() => history.push('/network/wifi/status/radio2_4ghz')}
                        ></Button>
                    </div>
                </div>

                <Form
                    hideButton={true}
                >
                    {wifiStatus5GForm()}
                </Form>

                <div id='refresh-btn'>
                    <Button
                        text={<Translator path="network.wifi.status.messages.UPDATE_LIST"></Translator>}
                        onClick={() => refresh5G()}
                    ></Button>
                </div>
            </React.Fragment>
    )
}


/**'
 * status Radio 2.4GHz 
 * 
 */
export function Wifi24GStatus({ history }) {
    const [radios, setRadios] = useState(null)
    const [wireless, setWireless] = useState(null)
    const [connected_device, setConnectedDevices] = useState(null)
    const [easymesh, setEasyMesh] = useState(null)

    const { t } = useTranslation()
    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchWirelessList(backend, setWireless)
        fetchRadioList(backend, setRadios)
        fetchConnectedDevicesList(backend, setConnectedDevices)
        fetchEasyMesh(backend, setEasyMesh)

        // eslint-disable-next-line
    }, [])

    const refresh2_4G = () => {
        fetchWirelessList(backend, setWireless)
        fetchRadioList(backend, setRadios)
        fetchConnectedDevicesList(backend, setConnectedDevices)
        fetchEasyMesh(backend, setEasyMesh)
    }

    const getWifiStatusColumns = () => {
        return [
            { header: t('Modo'), align: 'center', size: '80px' },
            { header: t('Banda'), align: 'center', size: '150px' },
            { header: t('SSID'), align: 'center', size: '200px' },
            { header: t('Canal'), align: 'center', size: '100px' },
            { header: t('network.wifi.status.label.CRYPTOGRAPHY'), align: 'center', size: '120px' },
            { header: t('BSSID'), align: 'center', size: '160px' },
            {
                header:
                    <div>
                        <div><Translator path='Clientes'></Translator></div>
                        <div><Translator path='conectados'></Translator></div>
                    </div>,
                align: 'center', size: '120px'
            },
        ]
    }

    const getWifiStatusVapColumns = () => {
        return [
            { header: t('VAP'), align: 'center', size: '80px' },
            { header: t('Banda'), align: 'center', size: '150px' },
            { header: t('SSID'), align: 'center', size: '200px' },
            { header: t('network.wifi.status.label.CRYPTOGRAPHY'), align: 'center', size: '120px' },
            { header: t('BSSID'), align: 'center', size: '160px' },
            {
                header:
                    <div>
                        <div><Translator path='Clientes'></Translator></div>
                        <div><Translator path='conectados'></Translator></div>
                    </div>,
                align: 'center', size: '120px'
            },
        ]
    }

    const getWifiStatus2_4GLines = () => {
        let has_radio2_4g = false
        let wirelessList = wireless
        let channel = radios[1].channel

        let wifiStatus5GLines = [];
        for (let i = 0; i < wirelessList.length; i++) {
            if (wirelessList[i].id === "wlan1") {
                has_radio2_4g = true
                wifiStatus5GLines.push([
                    wirelessList[i].mode === 0 ? 'AP' : t('Client'),
                    bandStrGen(wirelessList[i].ieee_std, 1),
                    wirelessList[i].ssid,
                    channel,
                    cipherStrGen(wirelessList[i].cipher),
                    wirelessList[i].bssid,
                    countConnDevices(connected_device, wirelessList[i].ssid, 0)
                ])
            }
        }

        if (has_radio2_4g)
            return wifiStatus5GLines
        else
            return []
    }

    const getWifiStatus2_4GVapLines = () => {
        let has_vap2_4g = false
        let wirelessList = wireless
        let easymeshList = easymesh

        let j = 1
        let wifiStatus5GVapLines = [];
        for (let i = 0; i < wirelessList.length; i++) {
            if (wirelessList[i].id.indexOf("wlan1-vap") !== -1 && extraValidators.reservedPrefix(wirelessList[i].ssid) === "") {
                has_vap2_4g = true
                wifiStatus5GVapLines.push([
                    j,
                    bandStrGen(wirelessList[i].ieee_std, 1),
                    wirelessList[i].ssid,
                    cipherStrGen(wirelessList[i].cipher),
                    wirelessList[i].bssid,
                    wirelessList[i].id === "wlan1-vap0" ? countConnDevicesVAP(connected_device, wirelessList[i].ssid, 0, easymeshList) : countConnDevices(connected_device, wirelessList[i].ssid, 0)
                ])
                j = j + 1
            }
        }

        if (has_vap2_4g)
            return wifiStatus5GVapLines
        else
            return []
    }

    const wifiStatus5GForm = () => {
        return (
            <React.Fragment>
                {/* Radio 2_4Ghz */}
                <div className='subtitle'><Translator path="network.wifi.status.title.WLAN_STATUS"></Translator></div>
                <div id='info-card-wifi-status'>
                    {getWifiStatus2_4GLines().length === 0 ?
                        <div>
                            <div className='info-card noelements-card'>
                                <Translator path="network.wifi.status.messages.WLAN_NOT_CONFIGURED"></Translator>
                            </div>
                            &nbsp;
                        </div>
                        :
                        <div className="network-wifi-status-5g">
                            <List
                                useTooltip={true}
                                lines={getWifiStatus2_4GLines()}
                                columns={getWifiStatusColumns()}
                            ></List>
                        </div>
                    }
                </div>

                {/* VAP 2_4Ghz */}
                <div id='info-card-wifi-status-vap'>
                    <div className='subtitle'><Translator path="network.wifi.status.title.WLAN_STATUS_VAP"></Translator></div>
                    {getWifiStatus2_4GVapLines().length === 0 || getWifiStatus2_4GLines().length === 0 ?
                        <div>
                            <div className='info-card noelements-card'>
                                <Translator path="network.wifi.status.messages.WLAN_VAP_NOT_CONFIGURED"></Translator>
                            </div>
                            &nbsp;
                        </div>
                        :
                        <div className="network-wifi-status-vap">
                            <List
                                useTooltip={true}
                                lines={getWifiStatus2_4GVapLines()}
                                columns={getWifiStatusVapColumns()}
                            ></List>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

    return (
        (!wireless || !radios || !connected_device || !easymesh) ? <Loading show={true}></Loading> :
            <React.Fragment>
                <div id='edit-wifi-status-page'>
                    <div className="subtitle"><Translator path="network.wifi.status.title.WLAN_STATUS_2_4G"></Translator></div>

                    <div className="row">
                        <div id='back-to-wifi-btn'>
                            <Button
                                text={<Translator path="common.label.BACK"></Translator>}
                                onClick={() => history.push('/network/wifi')}
                            ></Button>
                        </div>

                        <Button id='back-to-radio5ghz-btn'
                            text={<Translator path="network.wifi.status.messages.SHOW_5G"></Translator>}
                            onClick={() => history.push('/network/wifi/status/radio5ghz')}
                        ></Button>
                    </div>
                </div>

                <Form
                    hideButton={true}
                >
                    {wifiStatus5GForm()}
                </Form>

                <div id='refresh-btn'>
                    <Button
                        text={<Translator path="network.wifi.status.messages.UPDATE_LIST"></Translator>}
                        onClick={() => refresh2_4G()}
                    ></Button>
                </div>
            </React.Fragment>
    )
}

/**
 * shared methods
 * 
 */
function bandStrGen(band, frequency) {
    let r_str = ''
    var ieee_std_str = ''

    if (frequency === 0)
        r_str = '5 GHz'
    else
        r_str = '2.4 GHz'

    if (band & ieeeStd.B) {
        ieee_std_str += '+B'
    }
    if (band & ieeeStd.A) {
        ieee_std_str += '+A'
    }
    if (band & ieeeStd.G) {
        ieee_std_str += '+G'
    }
    if (band & ieeeStd.N) {
        ieee_std_str += '+N'
    }
    if (band & ieeeStd.AC) {
        ieee_std_str += '+AC'
    }
    if (band & ieeeStd.AX) {
        ieee_std_str += '+AX'
    }

    if (ieee_std_str){
        r_str = r_str.concat(' (', ieee_std_str.substring(1), ')')
    }

    return r_str
}

function cipherStrGen(cip) {
    switch (cip) {
        case cipher.NONE:
            return 'None'
        case cipher.WEP:
            return 'WEP'
        case cipher.WPA:
            return 'WPA'
        case cipher.WPA2:
            return 'WPA2'
        case cipher.WPA_WPA2:
            return 'WPA+WPA2'
        case cipher.WPA3:
            return 'WPA3'
        case cipher.WPA2_WPA3:
            return 'WPA2+WPA3'
        default:
            return '--'
    }
}

function countConnDevices(connDeviceList, ssid, freq) {
    let count = 0

    for (let i = 0; i < connDeviceList.length; i++) {
        if (connDeviceList[i].network === ssid && connDeviceList[i].frequency === freq)
            count += 1
    }

    return count
}

function countConnDevicesVAP(connDeviceList, ssid, freq, easymeshList) {
    let count = 0
    let aux_backhaul = 0

    if (freq === 1)
        aux_backhaul = easyMeshBackhaul._5_0GHZ;
    else
        aux_backhaul = easyMeshBackhaul._2_4GHZ;

    if (easymeshList.enabled) {
        for (let k = 0; k < easymeshList.neighbors.length; k++) {
            if (easymeshList.neighbors[k].is_online && easymeshList.neighbors[k].backhaul === aux_backhaul) {
                count += 1
            }
        }
        return count
    }

    count = countConnDevices(connDeviceList, ssid, freq)
    return count
}

import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom';

import './voip-advanced-sip.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipAdvancedSipList } from '../../../resources/voip-advanced-sip';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next';
import EditIcon from '../../../../components/icons/edit';


export default function VoipAdvancedSipList({isWizard, setSaved, isFormSegment, formSegment}) {
    const [voipAdvancedSipList, setVoipAdvancedSipList] = useState(null)

    const history = useHistory()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchVoipAdvancedSipList(backend, setVoipAdvancedSipList)

        // eslint-disable-next-line
    }, [])

    const editAdvancedSip = id => {
        history.push(`/voip/advanced-sip/${id}`)
    }

    const voipAdvancedSipListTable = () => {
        return <React.Fragment>
            <div className='voip-advanced-sips-box'>
                { voipAdvancedSipList.map((adSi, key) => 
                    <InfoCard
                        key={key}
                        lines={[
                            {label: <Translator path="voip.advanced_sip.label.PORT"></Translator>, value: <span>{adSi.voip_port ? adSi.voip_port.toUpperCase() : `${adSi.voip_port}` } </span> , id: `card-voip-advanced-sip-name-${key}`},
                            {label: <Translator path="voip.advanced_sip.label.DTMF_DTMF_AS_FAXMODEM"></Translator>, value: adSi.dtmf.dtmf_as_faxmodem ? t('modal.answer.YES') : t('modal.answer.NO'), id: `card-voip-advanced-sip-advanced-sip-dtmf-dtmf-as-faxmodem-${key}`},
                            // {label: <Translator path="voip.advanced_sip.label.DTMF_DTMF_PACKET_INTERVAL"></Translator>, value: adSi.dtmf.dtmf_packet_interval },
                            // {label: <Translator path="voip.advanced_sip.label.DTMF_DTMF_PAYLOAD"></Translator>, value: adSi.dtmf.dtmf_payload },
                            // {label: <Translator path="voip.advanced_sip.label.DTMF_DTMF_RELAY"></Translator>, value: adSi.dtmf.dtmf_relay },
                            // {label: <Translator path="voip.advanced_sip.label.FAXMODEM_PACKET_INTERVAL"></Translator>, value: adSi.dtmf.faxmodem_packet_interval },
                            // {label: <Translator path="voip.advanced_sip.label.DTMF_FAXMODEM_PAYLOAD"></Translator>, value: adSi.dtmf.faxmodem_payload },
                            {label: <Translator path="voip.advanced_sip.label.DTMF_SIP_INFO_DURATION"></Translator>, value: adSi.dtmf.sip_info_duration },
                            {label: t('common.label.OPERATIONS'), value:  <span className="card-voip-edit-icon" onClick={e => editAdvancedSip(adSi.id)}><EditIcon /></span> },
                        ]}
                    ></InfoCard>)
                }
            </div>
            
        </React.Fragment>
    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipAdvancedSipList ? <Loading show={true}></Loading> :

        <div id='voip-advanced-sip-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="voip.advanced_sip.title.VOIP_ADVANCED_SIP"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>
  
            {voipAdvancedSipListTable()}

        </div>
    )
}

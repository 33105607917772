import { backendStatus } from '../../backend/backend'

const URL = 'parental_control/0';

export async function fetchParentalControlParentalControlList(backend, setParentalControlList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setParentalControlList(result.content)
    }
}

export async function saveParentalControlParentalControl(backend, parentalControl, setErrorMessage) {

    try{

        let result = await backend.update(`${URL}`, parentalControl)
        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || parentalControl)

    }catch(error){
        console.error('Error saving PARENTAL_CONTROL:', error)
        return false
    }

}
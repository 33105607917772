import { backendStatus } from '../../backend/backend'

export const loopBackDetectConstants = {
    DETECTION_INTERVAL_MIN: 1,
    DETECTION_INTERVAL_MAX: 60,
    RECOVERY_INTERVAL_MIN: 10,
    RECOVERY_INTERVAL_MAX: 1800,
    MAX_VLAN_ID_ELEMENTS: 16
}

export async function fetchLoopBackDetectSettings(backend, setloopBackDetectSettings) {
    let result = await backend.retrieveFresh('loop_back_detect')

    if(result.status === backendStatus.SUCCESS){

        let loopBackDetectSettingsList = result.content

        let loopBackDetectSettings = loopBackDetectSettingsList.length !== 0 ? loopBackDetectSettingsList[0] : {
            enabled: false,
            detection_interval: 0,
            recovery_interval: 0,
            frame_type: 0,
            vlan_list: [],
            isCreate: true
        }

        setloopBackDetectSettings(loopBackDetectSettings)
    }
}

export async function saveLoopBackDetectSettings(backend, loopBackDetectSettings) {

    if(!loopBackDetectSettings.id) {
        loopBackDetectSettings.id = '0'
    }

    try{

        let result = null;

        if(loopBackDetectSettings.isCreate)
            result = await backend.create('loop_back_detect', loopBackDetectSettings)
        else
            result = await backend.update(`loop_back_detect/${loopBackDetectSettings.id}`, loopBackDetectSettings)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Loop Back Detect:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import './parental-control-parental-control.css'
import Loading from '../../../../components/loading/loading';
import { useTranslation } from 'react-i18next';
import { ParentalControlContext } from '../parental-control-context';
import Button from '../../../../components/button/button';
import { AddrTypeEnumeration, getDaysArray, ParentalControlElementStruct, USERNAME_MAX_LENGTH } from './parental-control-parental-control-common';
import Input from '../../../../components/input/input'
import Select from '../../../../components/select/select'
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators'
import Form from '../../../../components/form/form';
import Checkbox from '../../../../components/checkbox/checkbox';
import { useHistory, useParams } from 'react-router-dom';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';

export default function ParentalControlParentalControlEdit({ isWizard, setSaved, isFormSegment, formSegment }) {

    const { t } = useTranslation()
    const parentCtrlCtx = useContext(ParentalControlContext)
    const params = useParams()
    const history = useHistory()

    const [parentalControlElement, SetParentalControlElement] = useState(ParentalControlElementStruct)
    const [beginTime, setBeginTime] = useState("00:00")
    const [endTime, setEndTime] = useState("00:00")
    const [errorMessage, setErrorMessage] = useState('')
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (parentCtrlCtx.parentalControlRes === null) {
                await parentCtrlCtx.retrieveParentalControl()
            }
        }

        fetchData()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (params.id !== 'add') {
            if (parentCtrlCtx.parentalControlRes !== null && Number(params.id) < parentCtrlCtx.parentalControlRes.parental_control_list.length) {
                let element = parentCtrlCtx.parentalControlRes.parental_control_list[Number(params.id)]

                SetParentalControlElement(element)
                setBeginTime(`${('0' + element.hours_begin).slice(-2)}:${('0' + element.minutes_begin).slice(-2)}`)
                setEndTime(`${('0' + element.hours_end).slice(-2)}:${('0' + element.minutes_end).slice(-2)}`)
            }
            else // ID not found
                history.push('/parental-control/parental-control')
        }
        // eslint-disable-next-line
    }, [parentCtrlCtx.parentalControlRes])

    const getTime = str => {

        const regex = new RegExp('(^(?<hour>[0-9]{2}):(?<min>[0-9]{2})$)')
        const execution = regex.exec(str)

        if (regex.test(str))
            return { hour: Number(execution.groups.hour), min: Number(execution.groups.min) };
        else
            return null
    }

    const submit = async () => {

        setSaving(true)

        if (params.id === 'add') {

            if (await parentCtrlCtx.createParentalControlElement(parentalControlElement, setErrorMessage))
                setSuccess(true)
            else
                setError(true)
        }
        else if (Number(params.id) < parentCtrlCtx.parentalControlRes.parental_control_list.length) {
            if (await parentCtrlCtx.updateParentalControlElement(Number(params.id), parentalControlElement, setErrorMessage))
                setSuccess(true)
            else
                setError(true)
        }

        setSaving(false)

    }

    const dismiss = () => {
        setSuccess(false)
        setSaving(false)

        if (!error) {
            setError(false)
            history.push('/parental-control/parental-control')
        }

        setError(false)
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'Time begin must be smaller than time end')
            return t('parental_control.parental_control.error.TIME_RANGE')
        else if (errorMsg === 'IPs are not in a valid range')
            return t('parental_control.parental_control.error.IP_RANGE')
        else if (errorMsg === 'Value of days is not in range [1, 127]')
            return t('parental_control.parental_control.error.DAYS_RANGE')
        else
            return errorMsg
    }

    const parentalControlEditForm = () => {
        return <React.Fragment>

            <div className='subtitle'><Translator path={'parental_control.parental_control.title.EDIT_RULE'} /></div>

            <Input
                id='parental_control_rule_name'
                name='parental_control_rule_name'
                label={t('parental_control.parental_control.label.RULE_NAME')}
                type='text'
                placeholder={t('validators.MAX_NUMBER_OF_CHARACTERS', { max: USERNAME_MAX_LENGTH })}
                value={parentalControlElement.username}
                onChange={(e) => {
                    SetParentalControlElement({ ...parentalControlElement, username: e.target.value })
                }}
                // disabled={false}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: 0, max: USERNAME_MAX_LENGTH } }
                ]}
            ></Input>

            <Select
                id='parental_control_rule_type'
                name='parental_control_rule_type'
                label={t('parental_control.parental_control.label.RULE_TYPE')}
                clearErrors={true}
                options={[
                    {
                        value: AddrTypeEnumeration.PARENTAL_CONTROL_ADDR_IP,
                        text: 'IP'
                    },
                    {
                        value: AddrTypeEnumeration.PARENTAL_CONTROL_ADDR_MAC,
                        text: 'MAC'
                    }
                ]}
                value={parentalControlElement.address_type}
                onChange={(e) => {
                    SetParentalControlElement({ ...parentalControlElement, address_type: Number(e.target.value) })
                }}
            ></Select>


            {parentalControlElement.address_type === AddrTypeEnumeration.PARENTAL_CONTROL_ADDR_IP ?
                <div className='parental-control-d-flex'>
                    <Input
                        id='parental_control_ip_address_from'
                        name='parental_control_ip_address_from'
                        label={t('parental_control.parental_control.label.IP_FROM')}
                        type={'text'}
                        placeholder={t('parental_control.parental_control.label.IP_FROM')}
                        value={parentalControlElement.ip_address_from}
                        onChange={(e) => {
                            SetParentalControlElement({ ...parentalControlElement, ip_address_from: e.target.value })
                        }}
                        disabled={false}
                        validators={[
                            extraValidators.validateIPv4,
                            extraValidators.required,
                            extraValidators.validateIfNotLocalhost
                        ]}
                    ></Input>

                    <Input
                        id='parental_control_ip_address_to'
                        name='parental_control_ip_address_to'
                        label={t('parental_control.parental_control.label.IP_TO')}
                        type='text'
                        placeholder={t('parental_control.parental_control.label.IP_TO')}
                        value={parentalControlElement.ip_address_to}
                        onChange={(e) => {
                            SetParentalControlElement({ ...parentalControlElement, ip_address_to: e.target.value })
                        }}
                        disabled={false}
                        validators={[
                            extraValidators.validateIPv4,
                            extraValidators.required,
                            extraValidators.validateIfNotLocalhost
                        ]}
                    ></Input>
                </div>
                :
                <div className='parental-control-d-flex'>
                    <Input
                        id='parental_control_mac_address'
                        name='parental_control_mac_address'
                        label={t('parental_control.parental_control.label.MAC')}
                        type='text'
                        placeholder={t('parental_control.parental_control.label.MAC')}
                        value={parentalControlElement.mac_address}
                        onChange={(e) => {
                            SetParentalControlElement({ ...parentalControlElement, mac_address: e.target.value })
                        }}
                        disabled={false}
                        validators={[
                            extraValidators.required,
                            extraValidators.validateMAC
                        ]}
                    ></Input>
                </div>
            }

            <div className='subtitle'><Translator path={'parental_control.parental_control.label.DAYS'} /></div>

            <div className='parental-control-days-box'>
                {
                    getDaysArray(parentalControlElement.days, t).map((obj, idx) => {
                        return <div className='parental-control-day-element' key={`day-${idx}`} >
                            <div className='parental-control-day-txt'>
                                {obj.name.substring(0, 3)}
                            </div>
                            <Checkbox
                                id={`check1${idx}`}
                                value={obj.en}
                                toggleFn={() => {
                                    if (parentalControlElement.days & (1 << idx))
                                        parentalControlElement.days &= ~(1 << idx)
                                    else
                                        parentalControlElement.days |= (1 << idx)

                                    SetParentalControlElement({ ...parentalControlElement, days: parentalControlElement.days })
                                }}
                                label=''
                            ></Checkbox>
                        </div>
                    })
                }
            </div>

            <div className='subtitle'><Translator path={'parental_control.parental_control.label.TIME'} /></div>

            <div className='parental-control-d-flex'>
                <Input
                    id='parental_control_start_time'
                    name='parental_control_start_time'
                    label={t('parental_control.parental_control.label.START_TIME')}
                    type='text'
                    placeholder={'HH:MM'}
                    value={beginTime}
                    onChange={(e) => {
                        setBeginTime(e.target.value)
                        let time = getTime(e.target.value)
                        time && SetParentalControlElement({ ...parentalControlElement, hours_begin: time.hour, minutes_begin: time.min })
                    }}
                    disabled={false}
                    validators={[extraValidators.validateTimeHHMM]}
                ></Input>

                <Input
                    id='parental_control_end_time'
                    name='parental_control_end_time'
                    label={t('parental_control.parental_control.label.END_TIME')}
                    type='text'
                    placeholder={'HH:MM'}
                    value={endTime}
                    onChange={(e) => {
                        setEndTime(e.target.value)
                        let time = getTime(e.target.value)
                        time && SetParentalControlElement({ ...parentalControlElement, hours_end: time.hour, minutes_end: time.min })
                    }}
                    disabled={false}
                    validators={[extraValidators.validateTimeHHMM]}
                ></Input>
            </div>
        </React.Fragment>
    }

    return (
        !parentalControlElement ? <Loading show={true}></Loading> :

            <div id='parental-control-parental-control-edit-page' className='with-tabs'>

                <DefaultStatusModals
                    modalID='parental-control'
                    saving={saving}
                    success={success}
                    error={error}
                    errorText={t('common.message.error.SERVER_ERROR', { error: translateErrorMessagesFromBackend(errorMessage) })}
                    continueFn={dismiss}
                    isWarningModal={true}
                    savingText={t('common.label.SAVING')}
                ></DefaultStatusModals>

                <div className='subtitle'><Translator path="menu.PARENTAL_CONTROL"></Translator></div>

                <Button
                    id='parental_control_return'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={e => history.push('/parental-control/parental-control')}
                ></Button>

                <div className='card mt2'>
                    <Form
                        onSubmit={submit}
                        submitText={<Translator path="common.label.SAVE"></Translator>}
                        buttonId='button-save'
                    >
                        {parentalControlEditForm()}
                    </Form>
                </div>
            </div>
    )
}

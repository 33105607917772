
import React, { useState, useContext, useEffect } from 'react'
import Input from '../../../components/input/input'
import Form from '../../../components/form/form'
import List from '../../../components/list/list'
import './tools-traceroute.css'
import { BackendContext, backendStatus } from '../../../backend/backend'
import { fetchTraceroute, createTraceroute, constants, getInitialTraceroute, ProbeOutput, isValidIPv4, isValidIPv6 } from '../../resources/traceroute'
import common from '../../../components/form/validators/common'
import network from '../../../components/form/validators/network'
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import CollapseBox from '../../../components/collapsebox/collapse-box';
import Checkbox from '../../../components/checkbox/checkbox'
import Translator from '../../common/components/translator/translator'
import { fetchNetworkWanList } from '../../resources/network-wan'
import { useTranslation } from 'react-i18next'
import Select from '../../../components/select/select'
import PacketsIcon from '../../../components/icons/packets-icon'
import Pager from '../../../components/pager/pager'


export default function TraceroutePage() {

    const [tracerouteInput, setTracerouteInput] = useState(
        getInitialTraceroute()
    )
    const [tracerouteResult, setTracerouteResult] = useState({})
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorInfo, setErrorInfo] = useState(null)
    const [showInfo, setShowInfo] = useState(null)
    const [networkWanList, setNetworkWanList] = useState([])

    const [qosClassificationPagination, setQosClassificationPagination] = useState(
        {
            pageNumber: 1,
            totalElements: 0,
            pageSize: 10
        }
    )

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchNetworkWanList(backend, setNetworkWanList)

        // eslint-disable-next-line
    }, [])

    const changePage = page => {
        setQosClassificationPagination({ ...qosClassificationPagination, pageNumber: page })
    }

    const runTraceroute = async () => {
        setErrorInfo(null);
        if (error || loading) return

        setLoading(true)

        let tracerouteRequest = {}
        Object.keys(tracerouteInput).forEach((key) => {
            if (tracerouteInput[key]) {
                tracerouteRequest[key] = tracerouteInput[key]
            }
        })

        // Send traceroute request
        let result = await createTraceroute(backend, tracerouteRequest, setErrorInfo)
        if (!result || result === backendStatus.ERROR) {
            setError(true)
            setLoading(false)
            return
        }

        // Retrieve traceroute result
        result = await fetchTraceroute(backend, setTracerouteResult, setErrorInfo)
        if (!result) {
            setError(true)
        }
        else {
            setShowInfo(true)
        }

        setLoading(false)
    }

    const dismiss = () => {
        setLoading(false)
        setError(false)
        setShowInfo(false)
    }

    const getHop = () => {

        let qosClassificationFiltered = tracerouteResult.hop_list

        if (qosClassificationFiltered.length !== qosClassificationPagination.totalElements) {
            qosClassificationPagination.totalElements = qosClassificationFiltered.length
            setQosClassificationPagination({ ...qosClassificationPagination })
        }

        let qosClassificationLines = [];

        if (qosClassificationFiltered.length > 0) {
            let begining = (qosClassificationPagination.pageNumber - 1) * (qosClassificationPagination.pageSize)
            let end = begining + qosClassificationPagination.pageSize
            if (end > qosClassificationPagination.totalElements) {
                end = qosClassificationPagination.totalElements
            }

            for (let i = begining; i < end; i++) {
                qosClassificationLines.push([
                    tracerouteResult.hop_list[i].hop,
                    tracerouteResult.hop_list[i].domain,
                    (!tracerouteResult.hop_list[i].ip.localeCompare("") ? "*" : tracerouteResult.hop_list[i].ip),
                    ((tracerouteResult.hop_list[i].rtt_1 > constants.MAX_RTT_MS || tracerouteResult.hop_list[i].rtt_1 < constants.MIN_RTT_MS) ? "*" : tracerouteResult.hop_list[i].rtt_1.toFixed(3) + ProbeOutput.filter(po => { return po.value === tracerouteResult.hop_list[i].probe_1_output })[0].text),
                    ((tracerouteResult.hop_list[i].rtt_2 > constants.MAX_RTT_MS || tracerouteResult.hop_list[i].rtt_2 < constants.MIN_RTT_MS) ? "*" : tracerouteResult.hop_list[i].rtt_2.toFixed(3) + ProbeOutput.filter(po => { return po.value === tracerouteResult.hop_list[i].probe_2_output })[0].text),
                    ((tracerouteResult.hop_list[i].rtt_3 > constants.MAX_RTT_MS || tracerouteResult.hop_list[i].rtt_3 < constants.MIN_RTT_MS) ? "*" : tracerouteResult.hop_list[i].rtt_3.toFixed(3) + ProbeOutput.filter(po => { return po.value === tracerouteResult.hop_list[i].probe_3_output })[0].text)
                ]);
            }

        }

        return qosClassificationLines;
    }


    const getColums = () => {

        return [
            { header: t('tools.traceroute.label.HOPS'), align: 'center' },
            { header: t('common.label.DOMAIN'), align: 'center' },
            { header: t('common.label.IP'), align: 'center' },
            { header: t('tools.traceroute.label.LATENCY', { index: 1 }), align: 'center' },
            { header: t('tools.traceroute.label.LATENCY', { index: 2 }), align: 'center' },
            { header: t('tools.traceroute.label.LATENCY', { index: 3 }), align: 'center' },
        ]
    }

    const tracerouteInterfaces = (tracerouteInput) => {
        if (!tracerouteInput.ip6) {
            return [{ value: 'any', text: t('tools.traceroute.label.ALL_INTERFACES') }, ...networkWanList.filter(wan => (wan.mode !== 3 && wan.protocol !== 1)).map(wan => ({ value: wan.id, text: wan.interfaceID }))]
        }
        if (tracerouteInput.ip6) {
            return [{ value: 'any', text: t('tools.traceroute.label.ALL_INTERFACES') }, ...networkWanList.filter(wan => (wan.mode !== 3 && wan.protocol !== 0)).map(wan => ({ value: wan.id, text: wan.interfaceID }))]
        }
    }

    return <div id='traceroute-page' className='page container'>
        <DefaultStatusModals error={error}
            errorText={
                <>
                    <div>
                        {t('tools.traceroute.message.error.TRACEROUTE_ERROR')}
                    </div>
                    <br />
                    <div>
                        {errorInfo?.message}
                    </div>
                </>
            }
            saving={loading}
            savingText={t('common.label.WAIT')}
            continueFn={dismiss}
            info={showInfo}
            infoText={
                !tracerouteResult.ip ? '' :
                    <div className='traceroute-result'>
                        <div className='subtitle'> <Translator path="common.label.RESULTS"></Translator></div>
                        {
                            tracerouteResult.host !== tracerouteResult.ip ?
                                <div className="info-text"><b><Translator path="common.label.HOST"></Translator>:</b> {tracerouteResult.host}</div> : ''
                        }
                        <div className="info-text"><b><Translator path="tools.traceroute.label.IP_ADDRESS"></Translator>:</b> {tracerouteResult.ip}</div>
                        <div className="info-text"><b><Translator path="tools.ping.label.PACKAGE_SIZE"></Translator>:</b> {tracerouteResult.packet_len}</div>
                        <div className='traceroute-result-details'>
                            <div>

                                <div className='results-table-subtitle'>
                                    <PacketsIcon size='18'></PacketsIcon>
                                    <div className='subtitle'> <Translator path="tools.traceroute.label.ALL_HOPS"></Translator></div>
                                </div>

                                <div className="traceroute-results-list-wrapper">
                                    <List
                                        lines={getHop()}
                                        columns={getColums()}
                                        width={1200}
                                        useTooltip={true}
                                    ></List>
                                </div>
                                <Pager
                                    pageNumber={qosClassificationPagination.pageNumber}
                                    totalElements={qosClassificationPagination.totalElements}
                                    pageSize={qosClassificationPagination.pageSize}
                                    pageChangeFn={changePage}>
                                </Pager>

                            </div>
                        </div>
                    </div>
            }
        ></DefaultStatusModals>


        <div className='subtitle'> <Translator path="tools.traceroute.label.TRACEROUTE_SETTINGS"></Translator></div>
        <div className='card mt2'>
            <div className='subtitle'> <Translator path="tools.traceroute.title.TRACEROUTE"></Translator></div>

            <Form id='traceroute-form'
                onSubmit={runTraceroute}
                submitText={t('common.label.START')}
                buttonId='button-traceroute'
                disableButton={(tracerouteInput.ip6 && isValidIPv4(tracerouteInput.host)) || (!tracerouteInput.ip6 && isValidIPv6(tracerouteInput.host))}
            >
                <Input id='traceroute-host' type='text'
                    name='host'
                    label={<Translator path="tools.traceroute.label.IP_HOST_ADDRESS" required={true}></Translator>}
                    value={tracerouteInput.host}
                    onChange={e => setTracerouteInput({ ...tracerouteInput, host: e.target.value })}
                    dependentValues={[tracerouteInput]}
                    validators={[
                        common.required,
                        common.nonASCII,
                        { fn: common.size, params: { min: constants.MIN_HOST_LENGTH, max: constants.MAX_HOST_LENGTH } },
                        network.hostname
                    ]}
                    errorMessage={(tracerouteInput.ip6 && isValidIPv4(tracerouteInput.host) && t('tools.traceroute.message.error.TRACEROUTE_INVALID_IP')) || (!tracerouteInput.ip6 && isValidIPv6(tracerouteInput.host) && t('tools.traceroute.message.error.TRACEROUTE_INVALID_IP'))}
                ></Input>

                <Checkbox
                    name="ip6"
                    label={t('common.label.IPV6')}
                    id='traceroute-ip6'
                    value={tracerouteInput.ip6}
                    validateOnChange={true}
                    toggleFn={() => setTracerouteInput({ ...tracerouteInput, ip6: !tracerouteInput.ip6, packet_len: !tracerouteInput.ip6 ? constants.MIN_PACKET_LEN_IPV6 : constants.MIN_PACKET_LEN })}
                ></Checkbox>

                <Select
                    id='tools-traceroute-interface-id'
                    name='interfaceID'
                    label={<Translator path="tools.traceroute.label.INTERFACE"></Translator>}
                    options={tracerouteInterfaces(tracerouteInput)}
                    value={tracerouteInput.interfaceID}
                    onChange={e => setTracerouteInput({ ...tracerouteInput, interfaceID: e.target.value })}
                ></Select>

                <CollapseBox title={<Translator path="tools.ping.title.ADVANCED"></Translator>} startOpen={true}>

                    {tracerouteInput.ip6 && <Input id='traceroute-bytes' type='number'
                        name='packet_len'
                        label={<Translator path={t("tools.ping.label.PACKAGE_SIZE") + " (bytes)"} required={true}></Translator>}
                        value={tracerouteInput.packet_len ? tracerouteInput.packet_len : ''}
                        onChange={e => setTracerouteInput({ ...tracerouteInput, packet_len: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            common.required,
                            { fn: common.value, params: { min: constants.MIN_PACKET_LEN_IPV6, max: constants.MAX_PACKET_LEN } }
                        ]}
                        collapsible='true'
                    ></Input>}

                    {!tracerouteInput.ip6 && <Input id='traceroute-bytes' type='number'
                        name='packet_len'
                        label={<Translator path={t("tools.ping.label.PACKAGE_SIZE") + " (bytes)"} required={true}></Translator>}
                        value={tracerouteInput.packet_len ? tracerouteInput.packet_len : ''}
                        onChange={e => setTracerouteInput({ ...tracerouteInput, packet_len: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            common.required,
                            { fn: common.value, params: { min: constants.MIN_PACKET_LEN, max: constants.MAX_PACKET_LEN } }

                        ]}
                        collapsible='true'
                    ></Input>}

                    <Input id='traceroute-hops' type='number'
                        name='hops'
                        label={<Translator path="tools.ping.label.MAX_HOPS_NUMBER" required={true}></Translator>}
                        value={tracerouteInput.hops_limit}
                        onChange={e => setTracerouteInput({ ...tracerouteInput, hops_limit: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            common.required,
                            { fn: common.value, params: { min: constants.MIN_HOPS_LIMIT, max: constants.MAX_HOPS_LIMIT } }
                        ]}
                        collapsible='true'
                    ></Input>

                    <Input id='traceroute-deadline' type='number'
                        name='deadline'
                        label={<Translator path="tools.ping.label.EXECUTION_TIME_IN_SECONDS" required={true}></Translator>}
                        value={tracerouteInput.deadline_seconds}
                        onChange={e => setTracerouteInput({ ...tracerouteInput, deadline_seconds: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            common.required,
                            { fn: common.value, params: { min: constants.MIN_DEADLINE_SECONDS, max: constants.MAX_DEADLINE_SECONDS } }
                        ]}
                        collapsible='true'
                    ></Input>

                    <Input id='traceroute-first-ttl' type='number'
                        name='first_ttl'
                        label={<Translator path="tools.traceroute.label.FIRST_TTL" required={true}></Translator>}
                        value={tracerouteInput.first_ttl}
                        onChange={e => setTracerouteInput({ ...tracerouteInput, first_ttl: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value) })}
                        validators={[
                            common.required,
                            { fn: common.value, params: { min: constants.MIN_FIRST_TTL, max: constants.MAX_FIRST_TTL } }
                        ]}
                        collapsible='true'
                    ></Input>
                </CollapseBox>
            </Form>
        </div>
    </div>
}
import { backendStatus } from  '../../backend/backend'

export const ProbeOutputNum = {
    PROBE_SUCCESS: 0,
    PROBE_TIMEOUT: 1,
    H_HOST_UNREACHABLE: 2,
    N_NETWORK_UNREACHABLE: 3,
    P_PROTOCOL_UNREACHABLE: 4,
    S_SOURCE_ROUTE_FAILED: 5,
    F_FRAGMENTATION_NEEDED: 6,
    X_COMMUNICATION_ADM_PROHIBITED: 7,
    V_HOST_PRECEDENCE_VIOLATION: 8,
    C_PRECEDENCE_CUTOFF: 9,
    ICMP_UNREACHABLE_CODE_0: 10,
    ICMP_UNREACHABLE_CODE_1: 11,
    ICMP_UNREACHABLE_CODE_2: 12,
    ICMP_UNREACHABLE_CODE_3: 13,
    ICMP_UNREACHABLE_CODE_4: 14,
    ICMP_UNREACHABLE_CODE_5: 15,
    ICMP_UNREACHABLE_CODE_6: 16,
    ICMP_UNREACHABLE_CODE_7: 17,
    ICMP_UNREACHABLE_CODE_8: 18,
    ICMP_UNREACHABLE_CODE_9: 19,
    ICMP_UNREACHABLE_CODE_10: 20,
    ICMP_UNREACHABLE_CODE_11: 21,
    ICMP_UNREACHABLE_CODE_12: 22,
    ICMP_UNREACHABLE_CODE_13: 23,
    ICMP_UNREACHABLE_CODE_14: 24,
    ICMP_UNREACHABLE_CODE_15: 25,
    UNKNOWN_ANNOTATION: 26
}

export const ProbeOutput = [
    {
        value: ProbeOutputNum.PROBE_SUCCESS,
        text: ''
    },
    {
        value: ProbeOutputNum.PROBE_TIMEOUT,
        text: ''
    },
    {
        value: ProbeOutputNum.H_HOST_UNREACHABLE,
        text: ' !H'
    },
    {
        value: ProbeOutputNum.N_NETWORK_UNREACHABLE,
        text: ' !N'
    },
    {
        value: ProbeOutputNum.P_PROTOCOL_UNREACHABLE,
        text: ' !P'
    },
    {
        value: ProbeOutputNum.S_SOURCE_ROUTE_FAILED,
        text: ' !S'
    },
    {
        value: ProbeOutputNum.F_FRAGMENTATION_NEEDED,
        text: ' !F'
    },
    {
        value: ProbeOutputNum.X_COMMUNICATION_ADM_PROHIBITED,
        text: ' !X'
    },
    {
        value: ProbeOutputNum.V_HOST_PRECEDENCE_VIOLATION,
        text: ' !V'
    },
    {
        value: ProbeOutputNum.C_PRECEDENCE_CUTOFF,
        text: ' !C'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_0,
        text: ' !<0>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_1,
        text: ' !<1>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_2,
        text: ' !<2>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_3,
        text: ' !<3>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_4,
        text: ' !<4>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_5,
        text: ' !<5>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_6,
        text: ' !<6>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_7,
        text: ' !<7>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_8,
        text: ' !<8>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_9,
        text: ' !<9>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_10,
        text: ' !<10>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_11,
        text: ' !<11>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_12,
        text: ' !<12>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_13,
        text: ' !<13>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_14,
        text: ' !<14>'
    },
    {
        value: ProbeOutputNum.ICMP_UNREACHABLE_CODE_15,
        text: ' !<15>'
    },
    {
        value: ProbeOutputNum.UNKNOWN_ANNOTATION,
        text: ' UNKWOWN ANN'
    },
]

export const constants = {
    MIN_HOST_LENGTH: 1,
    MAX_HOST_LENGTH: 255,
    MIN_HOPS_LIMIT: 0,
    MAX_HOPS_LIMIT: 255,
    MIN_DEADLINE_SECONDS: 0,
    MAX_DEADLINE_SECONDS: 60,
    MIN_PACKET_LEN: 38,
    MAX_PACKET_LEN: 32768,
    MIN_PACKET_LEN_IPV6: 64,
    MIN_FIRST_TTL: 1,
    MAX_FIRST_TTL: 255,
    DEFAULT_DEADLINE_SECONDS: 3,
    DEFAULT_PACKET_LEN: 38,
    DEFAULT_FIRST_TTL: 1,
    DEFAULT_HOPS_LIMIT: 30,
    MAX_RTT_MS: 1000000,
    MIN_RTT_MS: 0.0000001
}

export function getInitialTraceroute() {
    return {
        host: '',
        interfaceID: 'any',
        hops_limit: constants.DEFAULT_HOPS_LIMIT,
        deadline_seconds: constants.DEFAULT_DEADLINE_SECONDS,
        ip6: false,
        packet_len: constants.DEFAULT_PACKET_LEN,
        first_ttl: constants.DEFAULT_FIRST_TTL,
        hop_list: ''
    }
}

/** Return the result of a traceroute result fetch. Must receive the backend, settraceroute function and traceroute request object */
export async function fetchTraceroute(backend, setTraceroute, setErrorInfo) {
    let result = await backend.retrieveFresh('traceroute')

    if(result.status === backendStatus.SUCCESS && result.content.length > 0) {
        // Verify if response is from the traceroute requested
        setTraceroute(result.content[0])
        return true
    } else {
        setErrorInfo(result.content?.body)
    }

    return false
}


/** Create a traceroute request */
export async function createTraceroute(backend, traceroute, setErrorInfo) {
    try {
        let result = await backend.create('traceroute', traceroute, undefined, false)
        if (result.status !== backendStatus.SUCCESS) {
            setErrorInfo(result.content?.body)
        }
        return result.status
    } catch(error) {
        console.error('Error creating traceroute:', error)
        return false
    }
}

export const isValidIPv4 = value => {
    return value && /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value);
}

export const isValidIPv6 = value => {
    const regex = new RegExp('('+
    '([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|' +         // 1:2:3:4:5:6:7:8
    '([0-9a-fA-F]{1,4}:){1,7}:|' +                        // 1::                              1:2:3:4:5:6:7::
    '([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|' +        // 1::8             1:2:3:4:5:6::8  1:2:3:4:5:6::8
    '([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|' + // 1::7:8           1:2:3:4:5::7:8  1:2:3:4:5::8
    '([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|' + // 1::6:7:8         1:2:3:4::6:7:8  1:2:3:4::8
    '([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|' + // 1::5:6:7:8       1:2:3::5:6:7:8  1:2:3::8
    '([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|' + // 1::4:5:6:7:8     1:2::4:5:6:7:8  1:2::8
    '[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|' +      // 1::3:4:5:6:7:8   1::3:4:5:6:7:8  1::8  
    ':((:[0-9a-fA-F]{1,4}){1,7}|:)|' +                    // ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8 ::8       ::     
    'fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|' +    // fe80::7:8%eth0   fe80::7:8%1     (link-local IPv6 addresses with zone index)
    '::(ffff(:0{1,4}){0,1}:){0,1}' +
    '((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}' +
    '(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|' +         // ::255.255.255.255   ::ffff:255.255.255.255  ::ffff:0:255.255.255.255  (IPv4-mapped IPv6 addresses and IPv4-translated addresses)
    '([0-9a-fA-F]{1,4}:){1,4}:' +
    '((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}' +
    '(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])' +          // 2001:db8:3:4::192.0.2.33  64:ff9b::192.0.2.33 (IPv4-Embedded IPv6 Address)
    ')$')

    // IPv6 RegEx
    return value && regex.test(value);
}
import React from 'react'
import Input from '../../../../components/input/input'
import Loading from '../../../../components/loading/loading'
import { ponSettingsConstants } from '../../../resources/pon-settings'
import extraValidators from '../../../common/validators'
import Translator from '../../../common/components/translator/translator'

export default function PONSettingsSecurity(ponSettings, ponStatus, setPONSettings) {

    return (
        !ponSettings.security ? <Loading show={true}></Loading> :
        <React.Fragment>
            <div className="card mt2">
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path="pon.settings.title.SECURITY"></Translator></div>
                { ponStatus?.current_mode === 'epon' ?
                    <>
                        <Input id='pon-settings-security-loid'
                            name='loid'
                            label={<Translator path="pon.settings.label.LOID"></Translator>}
                            value={ponSettings.security.loid}
                            onChange={(e) => {
                                ponSettings.security.loid = e.target.value
                                setPONSettings({...ponSettings})
                            }}
                            validators={[
                                extraValidators.nonASCII,
                                {fn:extraValidators.size, params: {
                                    min: 0,
                                    max: ponSettingsConstants.LOID_MAX_SIZE
                                }},
                                extraValidators.forbidWhitespacesOnly
                            ]}
                        ></Input>
                        <Input id='pon-settings-security-loid-password'
                            name='loid_password'
                            type='password'
                            label={<Translator path="pon.settings.label.LOID_PASSWORD"></Translator>}
                            value={ponSettings.security.loid_password}
                            onChange={(e) => {
                                ponSettings.security.loid_password = e.target.value
                                setPONSettings({...ponSettings})
                            }}
                            validators={[
                                extraValidators.nonASCII,
                                {fn:extraValidators.size, params: {
                                    min: 0,
                                    max: ponSettingsConstants.LOID_PASSWD_MAX_SIZE
                                }},
                                extraValidators.forbidWhitespacesOnly
                            ]}
                        ></Input>
                    </> :
                    <Input id='pon-settings-security-ploam-password'
                        name='ploam_password'
                        label={<Translator path="pon.settings.label.PLOAM_PASSWORD"></Translator>}
                        type="password"
                        value={ponSettings.security.ploam_password}
                        onChange={(e) => {
                            ponSettings.security.ploam_password = e.target.value
                            setPONSettings({...ponSettings})
                        }}
                        validators={[
                            extraValidators.nonASCII,
                            {fn:extraValidators.size, params: {
                                min: 0,
                                max: ponSettingsConstants.GPON_PLOAM_PASSWD_MAX_SIZE
                            }},
                            extraValidators.forbidWhitespacesOnly
                        ]}
                    ></Input>
                }
            </div>
        </React.Fragment>
    )
}
import React, { useContext, useState } from 'react'
import { FormContext } from '../form/form';
import WarnIcon from '../icons/warn-icon';
import ChevronRightIcon from '../icons/chevron-right';

import './collapse-box.css'

const CollapseBox  = ({title, startOpen, children, height}) => {

    const [open, setOpen] = useState(startOpen)
    const formContext = useContext(FormContext)

    let size = open ? height : '0px';

    const hasCollapsibleErrors = () => {
        return formContext && formContext.hasCollapsibleErrors()
    }

    return <div className='collapse-box'>

        <div className='collapse-box-header' onClick={() => {setOpen(!open)}}>
            <div className='collapse-box-title'>
                {!open && hasCollapsibleErrors() ? <WarnIcon size={20} color={'red'}></WarnIcon> : null} {title}
            </div>
            <div className={`collapse-arrow ${open ? 'up' : 'down'}`}>
                <ChevronRightIcon size={18}></ChevronRightIcon>
            </div>
        </div>

        <div className={`collapse-box-content`} style={{height: size}}>
            {children}
        </div>
    </div>
}

export default CollapseBox
import React from 'react'
import TrafficIndicator from '../traffic-indicator/traffic-indicator'
import PowerIndicator from '../power_indicator/power_indicator'

import './internet-widget.css'

import Loading from '../../../components/loading/loading'

export default function InternetWidget({ponStatus, status, download, upload, rx, tx}) {
    
    if(!ponStatus || !status) return <Loading show={true} mini></Loading>

    return(
        <div className={`internet-status-wrapper ${true ? '' : 'offline'}`}>
            <div className='internet-status-outer'></div>
            <div className='internet-status'>
                <div id='internet-status' className='internet-status-title'>Modo</div>
                <div>{ponStatus.current_mode.toUpperCase()}</div>
            </div>


            <div className='internet-speed-indicator'>
                <TrafficIndicator id='internet-upload' traffic={upload} label='Upload' isUpload></TrafficIndicator>
                <TrafficIndicator id='internet-download' traffic={download} label='Download'></TrafficIndicator>
            </div>

            <div className='internet-power-indicator'>
                <PowerIndicator id='internet-upload' power={tx} label='TX Power' isTx></PowerIndicator>
                <PowerIndicator id='internet-download' power={rx} label='RX Power'></PowerIndicator>
            </div>
        </div>

    )
}

import React from 'react'


export const VoipContext = React.createContext({})

export default function VoipState({children}) {
    return <VoipContext.Provider value={{
        
    }}>
        {children}
    </VoipContext.Provider>
}

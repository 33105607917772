import { backendStatus } from '../../backend/backend'

export async function fetchPONStatus(backend, setPONStatus) {
    let result = await backend.retrieveFresh('pon_status')

    if(result.status === backendStatus.SUCCESS){

        let ponStatusList = result.content

        let ponStatus = ponStatusList.length !== 0 ? ponStatusList[0] : {
            isCreate: true,
            bias_current: 0,
            current_mode: "",
            part_number: "",
            rx_power: 0,
            temperature: 0,
            tx_power: 0,
            vendor_name: "",
            voltage: 0,
        }

        setPONStatus(ponStatus)
    }
}

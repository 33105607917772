import React, { useState, useEffect, useContext } from 'react'

import './advanced-bridging.css'

import Input from '../../../../components/input/input';
import { BackendContext } from '../../../../backend/backend'
import { fetchAdvancedBridgingList, saveAdvancedBridging } from '../../../resources/advanced-bridging';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import List from '../../../../components/list/list';
import Form from '../../../../components/form/form';
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import Checkbox from '../../../../components/checkbox/checkbox';
import CommonValidators from '../../../../components/form/validators/common';
import Button from '../../../../components/button/button';
import extraValidators from '../../../common/validators';

export default function AdvancedBridging({ isWizard, setSaved, isFormSegment, formSegment }) {
    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    const [bridging, setAdvancedBridging] = useState(null)

    const [bridgingPagination, setAdvancedBridgingPagination] = useState(null)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {
        setAdvancedBridgingPagination({ pageNumber: 1, totalElements: 0, pageSize: 5 })
        fetchAdvancedBridgingList(backend, setAdvancedBridging)

        // eslint-disable-next-line
    }, [])

    let save = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await saveAdvancedBridging(backend, bridging, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        await fetchAdvancedBridgingList(backend, setAdvancedBridging)

        setSaving(false)
        setSuccess(true)

    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    const nextClicked = () => {
        if (isWizard) {
            formSegment.nextClicked()
        }
    }

    const getColumns = () => {

        return [
            { header: t('advanced.bridging.title.PORT'), align: 'center', size: '100px' },
            { header: t('advanced.bridging.title.MAC_ADDRESS'), align: 'center', size: '250px' },
            { header: t('advanced.bridging.title.IS_LOCAL'), align: 'center', size: '150px' },
            { header: t('advanced.bridging.title.AGEING_TIMER'), align: 'center', size: '150px' },
        ]
    }

    const changePage = page => {
        setAdvancedBridgingPagination({ ...bridgingPagination, pageNumber: page })
    }

    const getAdvancedBridging = () => {
        let begining = (bridgingPagination.pageNumber - 1) * (bridgingPagination.pageSize)
        let end = begining + bridgingPagination.pageSize
        if (end > bridging.mac_list.length) {
            end = bridging.mac_list.length
        }

        let bridgingLines = [];
        for (let i = begining; i < end; i++) {
            if (bridging.mac_list[i]) {
                bridgingLines.push(
                    [
                        bridging.mac_list[i].port,
                        (bridging.mac_list[i].mac_addr.toLocaleUpperCase()).replace(/-/g, ":"),
                        bridging.mac_list[i].is_local === true ? t('modal.answer.YES') : t('modal.answer.NO'),
                        bridging.mac_list[i].ageing_time
                    ]);
            }
        }
        return bridgingLines;
    }

    const bridgingForm = () => {
        return <React.Fragment>
            <div className='advanced-bridging-control'>
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path="advanced.bridging.label.BRIDGING_SETTINGS"></Translator></div>
                <Input name='ageing_time'
                    id='ageing_time'
                    label={<Translator path="advanced.bridging.title.AGEING_TIME"></Translator>}
                    value={bridging.ageing_time}
                    onChange={(e) => {
                        let value = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setAdvancedBridging({ ...bridging, ageing_time: value })
                    }}
                    validators={[
                        CommonValidators.nonASCII,
                        CommonValidators.isNumber,
                        { fn: extraValidators.size, params: { min: 0, max: 5 } },
                        { fn: extraValidators.value, params: { min: 0, max: 65535 } }
                    ]}
                ></Input>
                <Checkbox id='stp-enable'
                    name='stp_enable'
                    label={<Translator path="advanced.bridging.title.STP"></Translator>}
                    value={bridging["802_1_d_spanning_tree"]}
                    toggleFn={(e) => {
                        bridging["802_1_d_spanning_tree"] = !bridging["802_1_d_spanning_tree"]
                        setAdvancedBridging({ ...bridging })
                    }}
                ></Checkbox>
            </div>
        </React.Fragment>
    }

    const bridgingTable = () => {
        return <React.Fragment>
            <div className='subtitle'><Translator path="advanced.bridging.title.TABLE"></Translator></div>
            <div className="advanced-bridging-list-wrapper">
                <List
                    lines={getAdvancedBridging()}
                    columns={getColumns()}
                ></List>
            </div>
            <Pager
                pageNumber={bridgingPagination.pageNumber}
                totalElements={bridging.mac_list.length}
                pageSize={bridgingPagination.pageSize}
                pageChangeFn={changePage}>
            </Pager>
            <Button
                id='button-refresh'
                type='button'
                text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                onClick={async () => {
                    await fetchAdvancedBridgingList(backend, setAdvancedBridging)
                }
                }
            ></Button>
        </React.Fragment>
    }

    return (
        !bridging ? <Loading show={true}></Loading> :

            <div id='advanced-bridging-page' className='container scroll-area'>

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                    success={success}
                    continueFn={dismiss}
                ></DefaultStatusModals>

                {!isFormSegment ?

                    <React.Fragment>
                        <div>
                            <div className='subtitle'><Translator path="menu.BRIDGING"></Translator></div>
                            <div className='card mt2'>
                                <Form
                                    onSubmit={save}
                                    buttonId='button-save-advanced-bridging'
                                >
                                    {bridgingForm()}
                                </Form>
                            </div>
                        </div>

                        {bridgingTable()}

                    </React.Fragment> :

                    <FormSegment
                        title={formSegment.title}
                        active={formSegment.active}
                        previousLabel={formSegment.previousLabel}
                        previousClicked={formSegment.previousClicked}
                        disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                        nextLabel={formSegment.nextLabel}
                        nextClicked={nextClicked}
                    >
                        {bridgingForm()}
                    </FormSegment>}

            </div>
    )
}

import React, { useState, useEffect, useContext } from 'react'

import './ipv6-dhcp.css'

import { dhcpConstants, dhcpMode, dhcpServerMode, fetchIpv6DhcpServer, saveIpv6DhcpServer, fetchIpv6Dhcp, saveIpv6Dhcp, fetchIpv6DhcpRelay, saveIpv6DhcpRelay } from '../../../resources/ipv6-dhcp';
import { fetchServiceSystem } from '../../../resources/system';
import { fetchNetworkWanList } from '../../../resources/network-wan';
import { BackendContext } from '../../../../backend/backend';

import Input from '../../../../components/input/input';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form, { FormContext } from '../../../../components/form/form'
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators';
import RemoveIcon from '../../../../components/icons/remove';
import List from '../../../../components/list/list';
import Button from '../../../../components/button/button';
import Checkbox from '../../../../components/checkbox/checkbox';
import Select from '../../../../components/select/select';
import { useHistory } from 'react-router-dom';
import EyeIcon from '../../../../components/icons/eye-icon';
import ChevronRightIcon from '../../../../components/icons/chevron-right';

export default function Ipv6Dhcp({ isWizard, isFormSegment, formSegment }) {

    const history = useHistory()

    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [dhcpServer, setDhcpServer] = useState(null)

    let [dhcp, setDhcp] = useState(null)
    let [dhcpEnabled, setDhcpEnabled] = useState(false)
    let [currentDhcpMode, setCurrentDhcpMode] = useState(dhcpMode.DHCPV6_MODE_NONE)
    let [dhcpModeServerAuto, setDhcpModeServerAuto] = useState(false)

    let [dhcpRelay, setDhcpRelay] = useState({
        interfaceID: ''
    })

    const [interfaces, setInterfaces] = useState(null)

    let [pristine, setPristine] = useState(true)
    let [domain, setDomain] = useState('')
    let [dns, setDnsServer] = useState('')
    let [system, setSystem] = useState(true)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchNetworkWanList(backend, setInterfaces)
        fetchIpv6Dhcp(backend, setDhcp, setDhcpEnabled, setCurrentDhcpMode)
        fetchIpv6DhcpRelay(backend, setDhcpRelay)
        fetchIpv6DhcpServer(backend, 0, setDhcpServer, setDhcpModeServerAuto)
        fetchServiceSystem(backend, setSystem)

        // eslint-disable-next-line
    }, [])

    let save = async () => {
        setPristine(true)
        if (saving || error || success) return

        setSaving(true)

        let ok;
        if (!dhcpEnabled) {
            ok = await saveIpv6Dhcp(backend, dhcp, setErrorMessage)
            if (!ok) {
                setSaving(false)
                setError(true)
                return
            }
        } else if (dhcpEnabled && currentDhcpMode === dhcpMode.DHCPV6_MODE_SERVER) {
            ok = await saveIpv6DhcpServer(backend, dhcpServer, setErrorMessage)
            if (!ok) {
                setSaving(false)
                setError(true)
                return
            }
        } else if (dhcpEnabled && currentDhcpMode === dhcpMode.DHCPV6_MODE_RELAY) {
            ok = await saveIpv6DhcpRelay(backend, dhcpRelay, setErrorMessage)
            if (!ok) {
                setSaving(false)
                setError(true)
                return
            }
        }

        setSaving(false)
        setSuccess(true)
    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const nextClicked = () => {
        if (isWizard) {
            formSegment.nextClicked()
        }
    }

    /* Domains */
    const handleKeyDownDomain = (event, fromButton) => {
        if (event.key === 'Enter' || fromButton) {
            if (!fromButton) {
                event.preventDefault()
            }
            if (domain.trim() && !domainIdAlreadyExists(domain) &&
                dhcpServer.domains.length < dhcpConstants.IPV6_DHCP_DOMAINS_MAX_LEN) {
                dhcpServer.domains.push(domain)
                setDhcpServer({ ...dhcpServer })
                setDomain('')
            }
        }
    }

    const domainIdAlreadyExists = (domainId) => {
        return dhcpServer.domains.filter(it => it === domainId).length > 0;
    }

    const domainAlreadyExistsValidator = async (value) => {
        return value && domainIdAlreadyExists(value) ? t('common.message.error.ITEM_ALREADY_EXISTS') : '';
    }

    const domainMaxSize = async (value) => {
        const max = dhcpConstants.IPV6_DHCP_DOMAINS_MAX_LEN;
        return value && dhcpServer.domains.length > max - 1 ? t('common.message.error.MAX_NUMBER_OF_ELEMENTS_IS', { max: max }) : '';
    }

    const getColumnsDomains = () => {

        return [
            { header: t('common.label.DOMAIN'), align: 'center' },
            { header: t('common.label.ACTIONS'), align: 'center' },
        ]
    }

    const getDomains = () => {
        return dhcpServer.domains.map(domainId => [domainId, <span className="clickable" onClick={e => removeItemDomain(domainId)}><RemoveIcon></RemoveIcon></span>])
    }

    const removeItemDomain = (domainId) => {
        setPristine(false)
        dhcpServer.domains = dhcpServer.domains.filter(it => it !== domainId)
        setDhcpServer({ ...dhcpServer })
    }

    const getInterfaceOptions = () => {
        var interface_list = [{ value: '', text: 'Escolha' }]
        for (var i = 0; i < interfaces?.length; i++) {
            const element = interfaces[i];

            if (element.protocol !== 0)
                interface_list.push(({ value: element.interfaceID, text: element.interfaceID }))
        }

        return interface_list
    }

    /* DNS Servers */
    const handleKeyDownDnsServer = (event, fromButton) => {
        if (event.key === 'Enter' || fromButton) {
            if (!fromButton) {
                event.preventDefault()
            }
            if (dns.trim() && !extraValidators.validateIPv6(dns) && !dnsServerIdAlreadyExists(dns) &&
                dhcpServer.dns_servers.length < dhcpConstants.IPV6_DHCP_DNS_SERVER_MAX_LEN) {
                dhcpServer.dns_servers.push(dns)
                setDhcpServer({ ...dhcpServer })
                setDnsServer('')
            }
        }
    }

    const dnsServerIdAlreadyExists = (dnsServerID) => {
        return dhcpServer.dns_servers.filter(it => it === dnsServerID).length > 0;
    }

    const dnsServerAlreadyExistsValidator = async (value) => {
        return value && dnsServerIdAlreadyExists(value) ? t('common.message.error.ITEM_ALREADY_EXISTS') : '';
    }

    const dnsServerMaxSize = async (value) => {
        const max = dhcpConstants.IPV6_DHCP_DNS_SERVER_MAX_LEN;
        return value && dhcpServer.dns_servers.length > max - 1 ? t('common.message.error.MAX_NUMBER_OF_ELEMENTS_IS', { max: max }) : '';
    }

    const getDnsServers = () => {
        return dhcpServer.dns_servers.map(dnsServerID => [dnsServerID, <span className="clickable" onClick={e => removeItemDnsServer(dnsServerID)}><RemoveIcon></RemoveIcon></span>])
    }

    const getColumnsDnsServers = () => {

        return [
            { header: 'Servidor DNS', align: 'center' },
            { header: t('common.label.ACTIONS'), align: 'center' },
        ]
    }

    const removeItemDnsServer = (dnsServerId) => {
        setPristine(false)
        dhcpServer.dns_servers = dhcpServer.dns_servers.filter(it => it !== dnsServerId)
        setDhcpServer({ ...dhcpServer })
    }

    const validatorDuid = async (value, { size }) => {
        return value && !new RegExp(`^0x[0-9A-Fa-f]{1,${size}}$`, 'g').test(value) ? t('ipv6.dhcp.message.error.CLIENT_DUID') : ''; //smartMessage('IS_HEX_FIELD') : '';
    }

    const hasIPv6Requiremnts = () => {
        if (isWizard || system === true) {
            return true
        }

        if (system) {
            if (system.ipv6) {
                if (system.ipv6.enabled) {
                    return true
                }
            }
        }

        return false
    }

    function hasConfigModesSet() {
        if (dhcpEnabled && currentDhcpMode === dhcpMode.DHCPV6_MODE_NONE)
            return false

        return true
    }

    function hasInterfaceToRelayMode() {
        if (dhcpEnabled && currentDhcpMode === dhcpMode.DHCPV6_MODE_RELAY && (getInterfaceOptions().length === 1))
            return false

        return true
    }

    const updateDhcpMode = (newMode) => {

        let enableDhcp = (newMode !== dhcpMode.DHCPV6_MODE_NONE)
        setDhcpEnabled(enableDhcp)

        if (newMode === dhcpMode.DHCPV6_MODE_SERVER) {
            setDhcpServer({ ...dhcpServer, mode: dhcpServerMode.DHCPV6_SERVER_STATIC })
        }

        setDhcpModeServerAuto(false)
        setDhcpRelay({ ...dhcpRelay, interfaceID: '' })

        if (enableDhcp)
            setDhcp({ ...dhcp, mode: newMode, enabled: enableDhcp })
        else
            setDhcp({ ...dhcp, enabled: enableDhcp })

        setCurrentDhcpMode(newMode)
    }

    const systemDhcpForm = (formContext) => {
        return <React.Fragment>

            {/* Enabled */}
            <div className='card mt2'>
                <div className='subtitle'><Translator path='ipv6.dhcp.title.CONFIG_MODES'></Translator></div>

                {/* Mode Relay and Mode Server */}
                <Select
                    id='ipv6-dhcp-mode'
                    name='dhcp_mode'
                    label={<Translator path="ipv6.dhcp.title.IPV6_DHCP_CHOICE"></Translator>}
                    options={[
                        { value: dhcpMode.DHCPV6_MODE_NONE, text: t('common.label.DISABLED') },
                        { value: dhcpMode.DHCPV6_MODE_RELAY, text: t('ipv6.dhcp.label.MODE_RELAY') },
                        { value: dhcpMode.DHCPV6_MODE_SERVER, text: t('ipv6.dhcp.label.MODE_SERVER') }
                    ]}
                    value={currentDhcpMode}
                    onChange={(e) => {
                        updateDhcpMode(Number(e.target.value))
                    }}
                    validators={[]}
                ></Select>


            </div>

            {(currentDhcpMode === dhcpMode.DHCPV6_MODE_SERVER) &&
                <div id='dhcpv6-active-clients-button'>
                    <div id='dhcpv6-active-clients' className='card dhcpv6-active-clients' onClick={() => { history.push('/ipv6/dhcp/active-clients') }}>
                        <div className='dhcpv6-active-clients-icon'>
                            <EyeIcon></EyeIcon>
                        </div>
                        <span><Translator path="ipv6.dhcp.title.ACTIVE_CLIENTS_BUTTON"></Translator></span>
                        <ChevronRightIcon size={22}></ChevronRightIcon>
                    </div>
                </div>
            }

            {/* Mode Server Auto/Static */}
            {dhcpEnabled && currentDhcpMode === dhcpMode.DHCPV6_MODE_SERVER &&
                <div>
                    <div className='card mt2'>
                        <div className='subtitle'><Translator path="ipv6.dhcp.title.SERVER_MENU"></Translator></div>
                        <Checkbox id='ipv6-dhcp-mode-server-auto'
                            name='server-auto'
                            label={<Translator path="ipv6.dhcp.label.ENABLE_MODE_SERVER_AUTO"></Translator>}
                            value={dhcpModeServerAuto}
                            toggleFn={(e) => {
                                dhcpModeServerAuto = !dhcpModeServerAuto;
                                setDhcpModeServerAuto(dhcpModeServerAuto)

                                if (dhcpModeServerAuto)
                                    dhcpServer.mode = dhcpServerMode.DHCPV6_SERVER_AUTO
                                else
                                    dhcpServer.mode = dhcpServerMode.DHCPV6_SERVER_STATIC

                                setDhcpServer({ ...dhcpServer })

                                formContext.clearErrors()
                            }}
                            validators={[]}
                        ></Checkbox>

                        {/* Form DHCP Server Static */}
                        {!dhcpModeServerAuto &&
                            <div>
                                <br />
                                <div className="row larger-field-wrapper">
                                    <Input name='ip_pool_range_start'
                                        id='ipv6-dhcp-ip-pool-range-start'
                                        value={dhcpServer.addresses_config.ip_pool_range_start}
                                        onChange={(e) => setDhcpServer({ ...dhcpServer, addresses_config: { ...dhcpServer.addresses_config, ip_pool_range_start: e.target.value } })}
                                        validators={[
                                            extraValidators.required,
                                            extraValidators.validateIPv6,
                                            extraValidators.validateIfNotLocalhost,
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.IP_POOL_RANGE_START" required={true}></Translator>}
                                    ></Input>

                                    <Input name='ip_pool_range_end'
                                        id='ipv6-dhcp-ip-pool-range-end'
                                        value={dhcpServer.addresses_config.ip_pool_range_end}
                                        onChange={(e) => setDhcpServer({ ...dhcpServer, addresses_config: { ...dhcpServer.addresses_config, ip_pool_range_end: e.target.value } })}
                                        validators={[
                                            extraValidators.required,
                                            extraValidators.validateIPv6,
                                            extraValidators.validateIfNotLocalhost,
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.IP_POOL_RANGE_END" required={true}></Translator>}
                                    ></Input>
                                </div>

                                <div className="row larger-field-wrapper">
                                    <Input name='prefix_length'
                                        id='ipv6-dhcp-prefix-length'
                                        value={dhcpServer.addresses_config.prefix_length}
                                        onChange={(e) => {
                                            dhcpServer.addresses_config.prefix_length = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                            setDhcpServer({ ...dhcpServer })
                                        }}
                                        validators={[
                                            extraValidators.required,
                                            extraValidators.isNumber,
                                            { fn: extraValidators.size, params: { min: 0, max: 3 } },
                                            { fn: extraValidators.value, params: { min: 0, max: 999 } },
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.PREFIX_LENGTH" required={true}></Translator>}
                                    ></Input>

                                    <Input name='valid_lifetime'
                                        id='ipv6-dhcp-valid-lifetime'
                                        value={dhcpServer.addresses_config.valid_lifetime}
                                        onChange={(e) => {
                                            dhcpServer.addresses_config.valid_lifetime = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                            setDhcpServer({ ...dhcpServer })
                                        }}
                                        validators={[
                                            extraValidators.required,
                                            extraValidators.isNumber,
                                            { fn: extraValidators.size, params: { min: 0, max: 9 } },
                                            { fn: extraValidators.value, params: { min: 0, max: 999999999 } },
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.VALID_LIFETIME" required={true}></Translator>}
                                    ></Input>
                                </div>

                                <div className="row larger-field-wrapper">
                                    <Input name='preffered_lifetime'
                                        id='ipv6-dhcp-preffered-lifetime'
                                        value={dhcpServer.addresses_config.preffered_lifetime}
                                        onChange={(e) => {
                                            dhcpServer.addresses_config.preffered_lifetime = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                            setDhcpServer({ ...dhcpServer })
                                        }}
                                        validators={[
                                            extraValidators.required,
                                            extraValidators.isNumber,
                                            { fn: extraValidators.size, params: { min: 0, max: 9 } },
                                            { fn: extraValidators.value, params: { min: 0, max: 999999999 } },
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.PREFFERED_TIME" required={true}></Translator>}
                                    ></Input>

                                    <Input name='renew_time'
                                        id='ipv6-dhcp-renew-time'
                                        value={dhcpServer.addresses_config.renew_time}
                                        onChange={(e) => {
                                            dhcpServer.addresses_config.renew_time = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                            setDhcpServer({ ...dhcpServer })
                                        }}
                                        validators={[
                                            extraValidators.required,
                                            extraValidators.isNumber,
                                            { fn: extraValidators.size, params: { min: 0, max: 9 } },
                                            { fn: extraValidators.value, params: { min: 0, max: 999999999 } },
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.RENEW_TIME" required={true}></Translator>}
                                    ></Input>
                                </div>

                                <div className="row larger-field-wrapper">
                                    <Input name='rebind_time'
                                        id='ipv6-dhcp-rebind-time'
                                        value={dhcpServer.addresses_config.rebind_time}
                                        onChange={(e) => {
                                            dhcpServer.addresses_config.rebind_time = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                            setDhcpServer({ ...dhcpServer })
                                        }}
                                        validators={[
                                            extraValidators.required,
                                            extraValidators.isNumber,
                                            { fn: extraValidators.size, params: { min: 0, max: 9 } },
                                            { fn: extraValidators.value, params: { min: 0, max: 999999999 } },
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.REBIND_TIME" required={true}></Translator>}
                                    ></Input>

                                    <Input name='client_duid'
                                        id='ipv6-dhcp-client-duid'
                                        value={'0x' + dhcpServer.addresses_config.client_duid}
                                        onChange={(e) => setDhcpServer({ ...dhcpServer, addresses_config: { ...dhcpServer.addresses_config, client_duid: e.target.value.replace('0x', '') } })}
                                        validators={[
                                            extraValidators.required,
                                            { fn: validatorDuid, params: { size: 2 } },
                                        ]}
                                        label={<Translator path="ipv6.dhcp.label.addresses_config.CLIENT_DUID" required={true}></Translator>}
                                    ></Input>
                                </div>
                            </div>
                        }
                    </div>

                    {!dhcpModeServerAuto &&
                        <div>

                            {/* Domains */}
                            <div className='card mt2'>
                                <div className='subtitle'><Translator path="ipv6.dhcp.title.DOMAINS"></Translator></div>
                                <Input id='ipv6-dhcp-domains'
                                    name='domains'
                                    label={<Translator path="ipv6.dhcp.label.DOMAINS"></Translator>}
                                    value={domain}
                                    onChange={(e) => {
                                        domain = e.target.value
                                        setDomain(domain)
                                    }}
                                    onKeyDown={handleKeyDownDomain}
                                    validators={[{ fn: domainAlreadyExistsValidator }, { fn: domainMaxSize }, extraValidators.forbidWhitespacesOnly]}
                                ></Input>

                                <div className="dhcpv6-rule-button">
                                    <Button type="button" id='btn-add-rule'
                                        onClick={evt => handleKeyDownDomain(evt, true)} text={t('common.label.ADD')}>
                                    </Button>
                                </div>
                            </div>
                            <div className='ipv6-dhcp-server-tbl'>
                                <div className='subtitle'><Translator path="ipv6.dhcp.title.DOMAINS_LIST"></Translator></div>
                                <List
                                    lines={getDomains()}
                                    columns={getColumnsDomains()}
                                ></List>
                            </div>


                            {/* DNS Servers */}
                            <div className='card mt2'>
                                <div className='subtitle'><Translator path="ipv6.dhcp.title.DNS_SERVERS"></Translator></div>
                                <Input id='ipv6-dhcp-dns-servers'
                                    name='dns_servers'
                                    label={<Translator path="ipv6.dhcp.label.DNS_SERVERS"></Translator>}
                                    value={dns}
                                    onChange={(e) => {
                                        dns = e.target.value
                                        setDnsServer(dns)
                                    }}
                                    onKeyDown={handleKeyDownDnsServer}
                                    validators={[
                                        { fn: dnsServerAlreadyExistsValidator },
                                        { fn: dnsServerMaxSize },
                                        extraValidators.forbidWhitespacesOnly,
                                        extraValidators.validateIPv6,
                                        extraValidators.validateIfNotLocalhost,
                                    ]}
                                ></Input>

                                <div className="dhcpv6-rule-button">
                                    <Button type="button" id='btn-add-rule'
                                        onClick={evt => handleKeyDownDnsServer(evt, true)} text={t('common.label.ADD')}>
                                    </Button>
                                </div>
                            </div>
                            <div className='ipv6-dhcp-server-tbl'>
                                <div className='subtitle'><Translator path="ipv6.dhcp.title.DNS_SERVERS_LIST"></Translator></div>
                                <List
                                    lines={getDnsServers()}
                                    columns={getColumnsDnsServers()}
                                ></List>
                            </div>
                        </div>
                    }
                </div>
            }

            {/* Form DHCP Relay */}
            {
                (dhcpEnabled && currentDhcpMode === dhcpMode.DHCPV6_MODE_RELAY && (getInterfaceOptions().length > 1)) &&
                <div className='card mt2'>
                    <div className='subtitle'><Translator path="ipv6.dhcp.title.RELAY_MENU"></Translator></div>
                    <Select
                        id='ipv6-dhcp-relay-menu-interface'
                        name='interface_index'
                        label={<Translator path="common.label.INTERFACE"></Translator>}
                        options={getInterfaceOptions()}
                        value={dhcpRelay?.interfaceID}
                        onChange={(e) => {
                            dhcpRelay.interfaceID = e.target.value
                            setDhcpRelay({ ...dhcpRelay })
                        }}
                    ></Select>
                </div>
            }

        </React.Fragment>
    }

    return (
        !dhcpServer ? <Loading show={true}></Loading> :

            <div id='ipv6-dhcp-page' className='with-tabs'>

                <div className='section-divider'></div>

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                    success={success}
                    continueFn={dismiss}
                ></DefaultStatusModals>

                {
                    hasIPv6Requiremnts() ? null :
                        <div>
                            <p className='dhcp-ipv6-info'>
                                {t(`ipv6.dhcp.message.IPV6_DISABLED`)}
                                <br />
                                {t(`common.message.warning.IPV6_ENABLE`)}
                                <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                            </p>
                        </div>
                }
                {
                    hasInterfaceToRelayMode() ? null :
                        <div>
                            <p className='dhcp-ipv6-info'>
                                {t(`ipv6.dhcp.message.error.RELAY_MODE`)}
                                <br />
                                {t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN`)}
                                <a href='#/network/wan'>{t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN_PAG`)}</a>
                                {t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN_END`)}.
                                <br />
                            </p>
                        </div>
                }

                {!isFormSegment ?

                    <React.Fragment>
                        <div className='info-text align-center'></div>
                        <div className='subtitle'><Translator path='ipv6.dhcp.title.IPV6_DHCP'></Translator></div>
                        <Form id='ipv6-dhcp-form'
                            key={pristine}
                            onSubmit={save}
                            buttonId='button-save-ipv6-dhcpServer'
                            isPristine={pristine}
                            disableButton={
                                !hasIPv6Requiremnts() ||
                                !hasConfigModesSet() ||
                                !hasInterfaceToRelayMode() ||
                                (currentDhcpMode === dhcpMode.DHCPV6_MODE_RELAY && dhcpRelay.interfaceID === '')}
                        >
                            <FormContext.Consumer>
                                {value => systemDhcpForm(value)}
                            </FormContext.Consumer>
                        </Form>
                    </React.Fragment> :

                    <FormSegment
                        title={formSegment.title}
                        active={formSegment.active}
                        previousLabel={formSegment.previousLabel}
                        previousClicked={formSegment.previousClicked}
                        disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                        nextLabel={formSegment.nextLabel}
                        nextClicked={nextClicked}
                    >
                        <FormContext.Consumer>
                            {value => systemDhcpForm(value)}
                        </FormContext.Consumer>
                    </FormSegment>}

            </div>
    )
}
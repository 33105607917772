import React, { useState, useEffect, useContext } from 'react'

import './ipv6-route-list.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchIpv6RouteListList } from '../../../resources/ipv6-route-list';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import Button from '../../../../components/button/button';
import { createLimitedTableCell } from '../../../utils/common';

import * as constants from '../../../common/constants';

export default function Ipv6RouteList({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [ipv6RouteList, setIpv6RouteList] = useState(null)

    const [ipv6RouteListPagination, setIpv6RouteListPagination] = useState(null)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {
        setIpv6RouteListPagination({ pageNumber: 1, totalElements: 0, pageSize: 10 })
        fetchIpv6RouteListList(backend, setIpv6RouteList)

        // eslint-disable-next-line
    }, [])

    const getColumns = () => {

        return [
            { header: t('common.label.NUMBER'), align: 'center', size: '60px' },
            { header: t('ipv6.route_list.label.DESTINATION'), align: 'center' },
            { header: t('ipv6.route_list.label.NEXT_HOP'), align: 'center' },
            { header: t('ipv6.route_list.label.FLAGS'), align: 'center', size: '100px' },
            { header: t('ipv6.route_list.label.METRIC'), align: 'center', size: '100px' },
            { header: t('ipv6.route_list.label.REF'), align: 'center', size: '100px' },
            { header: t('ipv6.route_list.label.USE'), align: 'center', size: '100px' },
            { header: t('ipv6.route_list.label.INTERFACEID'), align: 'center', size: '100px' },
        ]
    }

    const changePage = page => {
        setIpv6RouteListPagination({ ...ipv6RouteListPagination, pageNumber: page })
    }

    const getRouteList = () => {

        const ipv6ListMap = ipv6RouteList[0].ipv6_static_route_list
            .map((element) => {
                if (element?.interfaceID === constants.LOOPBACK_INTERFACE) return undefined
                if (element?.destination.toLowerCase().substr(0, 2) === constants.IPV6_MULTICAST_PREFIX) return undefined
                return element
            })
            .filter(function (element) {
                return element !== undefined;
            })

        return ipv6ListMap
    }

    const getIpv6RouteList = () => {

        let ipv6RouteListFiltered = getRouteList()

        if (ipv6RouteListFiltered.length !== ipv6RouteListPagination.totalElements) {
            ipv6RouteListPagination.totalElements = ipv6RouteListFiltered.length
            setIpv6RouteListPagination({ ...ipv6RouteListPagination })
        }

        let ipv6RouteListLines = [];

        let begining = (ipv6RouteListPagination.pageNumber - 1) * (ipv6RouteListPagination.pageSize)
        let end = begining + ipv6RouteListPagination.pageSize
        if (end > ipv6RouteListPagination.totalElements) {
            end = ipv6RouteListPagination.totalElements
        }

        for (let i = begining, nr = (1 + (ipv6RouteListPagination.pageNumber - 1) * ipv6RouteListPagination.pageSize); i < end; i++) {
            ipv6RouteListLines.push([
                nr++,
                <div>{createLimitedTableCell(`${ipv6RouteListFiltered[i].destination}`)}</div>,
                <div>{createLimitedTableCell(`${ipv6RouteListFiltered[i].next_hop}`)}</div>,
                ipv6RouteListFiltered[i].flags,
                ipv6RouteListFiltered[i].metric,
                ipv6RouteListFiltered[i].ref,
                ipv6RouteListFiltered[i].use,
                ipv6RouteListFiltered[i].interfaceID
            ]);
        }

        return ipv6RouteListLines;
    }

    const ipv6RouteListForm = () => {
        return <React.Fragment>
            <div className="ipv6-route-list-list-wrapper">
                <List
                    lines={getIpv6RouteList()}
                    columns={getColumns()}
                ></List>
            </div>
            <Pager
                pageNumber={ipv6RouteListPagination.pageNumber}
                totalElements={ipv6RouteListPagination.totalElements}
                pageSize={ipv6RouteListPagination.pageSize}
                pageChangeFn={changePage}>
            </Pager>

        </React.Fragment>
    }

    return (
        !ipv6RouteList ? <Loading show={true}></Loading> :

            <div id='ipv6-route-list-page' className='with-tabs'>
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path="menu.IPV6_ROUTE_LIST"></Translator></div>
                {
                    getRouteList().length > 0 ?
                        ipv6RouteListForm()
                        :
                        <div id='ipv6-route-list-no-rules'>
                            <div className='info-card noelements-card'>
                                <Translator path="ipv6.route_list.label.NO_ROUTES" />
                            </div>
                        </div>
                }
                <Button
                    id='button-refresh'
                    type='button'
                    text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                    onClick={async () => {
                        await fetchIpv6RouteListList(backend, setIpv6RouteList)
                    }
                    }
                ></Button>

            </div>
    )
}

import React, { useState, useEffect, useContext } from 'react'
import Button from '../../../../components/button/button'

import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import Select from '../../../../components/select/select'
import { fetchRadioList, saveRadio, bandwidth, frequencyString, frequency, radioOpMode } from '../../../resources/radio';
import { BackendContext } from '../../../../backend/backend';
import { MainHeaderContext } from '../../../../components/main-header/main-header-state';
import Loading from '../../../../components/loading/loading';
import { GlobalsContext } from '../../../globals-context';
import waitRouter from '../../../utils/wait-router';
import Checkbox from '../../../../components/checkbox/checkbox';
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next';
import { MeshContext } from '../mesh/mesh-context'

import './wifi.css'

export default function WifiRadio({ history }) {

    const [radios, setRadios] = useState(null)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [easyMesh, setEasyMesh] = useState(null)
    const [errorInfo, setErrorInfo] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)
    const globals = useContext(GlobalsContext)
    const meshContext = useContext(MeshContext)

    const { t } = useTranslation()

    useEffect(() => {
        header.title.set(t('network.wifi.title.RADIO'))
        header.backRoute.set('/network/wifi')

        fetchRadioList(backend, setRadios)
        if (!meshContext.easyMesh) meshContext.retrieveEasyMesh()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setEasyMesh(meshContext.easyMesh)

        // eslint-disable-next-line
    }, [meshContext.easyMesh])

    const mu_mimoSupport = (radio) => {
        switch (radio.opMode) {
            case radioOpMode.AC:
            case radioOpMode.ANAC:
            case radioOpMode.AX:
            case radioOpMode.ANACAX:
                return true
            default:
                return false
        }
    }

    const channelOptions = (radio) => {
        let channels = []
        if (radio.frequency === frequency._2_4GHz) {
            if (radio.bandwidth === bandwidth._20MHz) {
                channels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
            } else {
                channels = [0, 5, 6, 7, 8, 9, 10, 11]
            }
        }
        else {
            if (radio.bandwidth === bandwidth._160MHz)
                channels = [0, 21, 36, 40, 44, 48, 52, 56, 60, 64, 100, 104, 108, 112, 116, 120, 124, 128]
            else
                channels = [0, 21, 36, 40, 44, 48, 149, 153, 157, 161]

            if (easyMesh) {
                if (easyMesh.enabled === true) {
                    channels.splice(0, 2)
                }
            }

            if (radio.bandwidth === bandwidth._20MHz) {
                channels.push(165)
            }
        }

        return channels.map(channel => ({ value: channel, text: channel === 0 ? (radio.frequency === frequency._2_4GHz ? 'Automático' : 'Automático (DFS)') : (channel === 21 ? (radio.frequency === frequency._5GHz ? 'Automático' : channel) : channel) }))
    }

    const opModeOptions = (radio) => {
        let opModes = []
        if (radio.frequency === frequency._2_4GHz) {
            if (globals.containsFeature('HasAxSupport')) {
                opModes.push({ value: radioOpMode.B, text: 'B' })
                opModes.push({ value: radioOpMode.BG, text: 'B+G' })
                opModes.push({ value: radioOpMode.GN, text: 'G+N' })
                opModes.push({ value: radioOpMode.BGN, text: 'B+G+N' })
                opModes.push({ value: radioOpMode.AX, text: 'B+G+N+AX' })
            } else {
                opModes.push({ value: radioOpMode.B, text: 'B' })
                opModes.push({ value: radioOpMode.G, text: 'G' })
                opModes.push({ value: radioOpMode.BG, text: 'B+G' })
                opModes.push({ value: radioOpMode.N, text: 'N' })
                opModes.push({ value: radioOpMode.GN, text: 'G+N' })
                opModes.push({ value: radioOpMode.BGN, text: 'B+G+N' })
            }
        }
        else if (radio.frequency === frequency._5GHz) {
            if (globals.containsFeature('HasAxSupport')) {
                opModes.push({ value: radioOpMode.A, text: 'A' })
                opModes.push({ value: radioOpMode.AN, text: 'A+N' })
                opModes.push({ value: radioOpMode.NAC, text: 'N+AC' })
                opModes.push({ value: radioOpMode.ANAC, text: 'A+N+AC' })
                opModes.push({ value: radioOpMode.ANACAX, text: 'A+N+AC+AX' })
            } else {
                opModes.push({ value: radioOpMode.A, text: 'A' })
                opModes.push({ value: radioOpMode.N, text: 'N' })
                opModes.push({ value: radioOpMode.AN, text: 'A+N' })
                opModes.push({ value: radioOpMode.NAC, text: 'N+AC' })
                opModes.push({ value: radioOpMode.AC, text: 'AC' })
                opModes.push({ value: radioOpMode.ANAC, text: 'A+N+AC' })
            }
        }

        return opModes
    }


    const radioOpModeSel = (index) => {
        let radio = radios[index]
        return (
            <Select
                id={`${radio.id}-opMode`}
                label={<Translator path="network.wifi.label.IEEE_STD"></Translator>}
                value={radio.opMode}
                onChange={(e) => {
                    let value = Number(e.target.value)
                    radios[index].opMode = value

                    setRadios([...radios])
                }}
                options={opModeOptions(radio)}
            ></Select>
        )
    }

    const radioChannels = (index) => {
        let radio = radios[index]

        return <React.Fragment>
            <Select
                id={`${radio.id}-channel`}
                label={<Translator path="network.wifi.label.CHANNEL"></Translator>}
                value={radio.configuredChannel}
                onChange={(e) => {
                    let channel = Number(e.target.value)
                    setRadios((current) => {
                        current[index].configuredChannel = channel
                        return [...current]
                    })
                }}
                options={channelOptions(radio)}
            ></Select>
            <div className='radio-current-channel'><b><Translator path="network.wifi.label.CURRENT_CHANNEL"></Translator>: </b> {radio.channel}</div>
        </React.Fragment>
    }

    const radioMuMimo = (index) => {
        let radio = radios[index]

        const updateMuMimo = (radiosState, mimoEnabled = null) => {
            if (mimoEnabled === null)
                radiosState[index].mu_mimo = !radiosState[index].mu_mimo
            else
                radiosState[index].mu_mimo = mimoEnabled

            return [...radiosState]
        }

        if (mu_mimoSupport(radio)) {
            return (
                <React.Fragment>
                    <Checkbox id={`${radio.id}-mu_mimo`}
                        label={<Translator path="network.wifi.label.ENABLE_MU_MIMO"></Translator>}
                        value={radio.mu_mimo}
                        toggleFn={() => {
                            setRadios((radiosState) => {
                                return updateMuMimo(radiosState)
                            })
                        }}
                    ></Checkbox>

                </React.Fragment >
            )
        }

        if (radio.mu_mimo) {
            setRadios((radiosState) => {
                return updateMuMimo(radiosState, false)
            })
        }

        return <div></div>

    }

    const bandwidthOptions = (radio) => {
        let band = []

        switch (radio.opMode) {
            case radioOpMode.ANACAX:
                if (globals.containsFeature('HasAx160MHzSupport')) {
                    band.push({ value: bandwidth._160MHz, text: '160MHz' })
                }
                // falls through
            case radioOpMode.AC:
            case radioOpMode.ANAC:
            case radioOpMode.NAC:
                band.push({ value: bandwidth._80MHz, text: '80MHz' })
                // falls through
            case radioOpMode.AX:
            case radioOpMode.N:
            case radioOpMode.BGN:
            case radioOpMode.AN:
            case radioOpMode.GN:
                band.push({ value: bandwidth._40MHz, text: '40MHz' })
                // falls through
            default:
                band.push({ value: bandwidth._20MHz, text: '20MHz' })
                break;
        }

        return band.reverse()
    }

    const updateBandwidth = (index, newBandwidth) => {
        if (radios[index].bandwidth === newBandwidth)
            return

        radios[index].bandwidth = newBandwidth
        setRadios([...radios])
    }

    const radioBandwidth = (index) => {
        let radio = radios[index];
        let bandwidths = bandwidthOptions(radio);

        (bandwidths.length === 1) && updateBandwidth(index, bandwidths[0].value)

        return (
            <Select
                id={`${radio.id}-band`}
                label={<Translator path="network.wifi.label.BANDWIDTH"></Translator>}
                value={radio.bandwidth}
                onChange={(e) => {
                    updateBandwidth(index, Number(e.target.value))
                }}
                options={bandwidthOptions(radio)}
            ></Select>
        )
    }

    const submitRadio = async (radio) => {

        if (saving)
            return

        setSaving(true)
        setErrorInfo(null)

        meshContext.retrieveEasyMesh()

        if (easyMesh) {
            if (easyMesh.enabled === true && radio.enabled === false) {
                setErrorInfo(t('network.wifi.message.info_popup.MESH_ON'))
                setSaving(false)
                setError(true)
                return
            }
        }

        let ok = await saveRadio(backend, radio)
        if (!ok) {
            setSaving(false)
            setError(true)
        }

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        setSaving(false)
        setSuccess(true)

    }

    const dismiss = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        history.push(`/network/wifi`)
    }

    const getRadioBoxes = () => {

        return radios.map((radio, key) => {
            return (
                <div key={`${radio.id}-card`} className='card mt2'>
                    <div className='subtitle'>{frequencyString[radio.frequency]}</div>
                    <Checkbox id={`${radio.id}-enabled`}
                        label={<Translator path="common.label.ENABLE"></Translator>}
                        value={radio.enabled}
                        toggleFn={() => {
                            setRadios((radiosState) => {
                                radiosState[key].enabled = !radiosState[key].enabled
                                let newState = [...radiosState]
                                return newState
                            })
                        }}
                    ></Checkbox>

                    {!radio.enabled ? '' : <React.Fragment>
                        {radioOpModeSel(key)}
                        {radioBandwidth(key)}
                        {radioChannels(key)}
                        {radioMuMimo(key)}
                    </React.Fragment>}
                </div>
            )
        })
    }

    return !radios ? <Loading show={true}></Loading> :
        <div className='radio-page container scroll-area'>
            <div className='subtitle'><Translator path="network.wifi.title.RADIO_TITLE"></Translator></div>
            <DefaultStatusModals
                saving={saving}
                error={error}
                errorText={errorInfo}
                success={success}
                continueFn={dismiss}
                savingText={<div>
                    <Translator path="network.wifi.message.warning.SAVE_CONFIG"></Translator><br></br>
                    <Translator path="network.wifi.message.warning.RESTART_ROUTER"></Translator>
                </div>}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <Button id='back-to-wifi-list'
                text={<Translator path="common.label.BACK"></Translator>}
                onClick={() => history.push('/network/wifi')}
            ></Button>

            <div className='radio-boxes-container'> {getRadioBoxes()} </div>

            <strong><Translator path={'network.wifi.radio.WARNING'} /></strong>

            <Button id='radio-save' text={<Translator path="common.label.SAVE"></Translator>}
                onClick={() => { radios.map(radio => (submitRadio(radio))) }} />

        </div>
}

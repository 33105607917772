import { backendStatus } from  '../../backend/backend'

export const constants = {
    MIN_HOST_LENGTH: 1,
    MAX_HOST_LENGTH: 255,
    MIN_PACKET_SIZE: 4,
    MAX_PACKET_SIZE: 65507,
    DEFAULT_PACKET_SIZE: 56,
    MIN_PACKETS_QTY: 0,
    MAX_PACKETS_QTY: 50,
    MIN_HOPS_LIMIT: 0,
    MAX_HOPS_LIMIT: 255,
    MIN_DEADLINE_SECONDS: 0,
    MAX_DEADLINE_SECONDS: 60,
    DEFAULT_PACKETS_QTY: 4
}

/** Return the result of a ping result fetch. Must receive the backend, setPing function and ping request object */
export async function fetchPing(backend, setPing, setErrorInfo) {
    let result = await backend.retrieveFresh('ping')

    if(result.status === backendStatus.SUCCESS && result.content.length > 0) {
        // Verify if response is from the ping requested
        setPing(result.content[0])
        return true
    } else {
        setErrorInfo(result.content?.body)
    }

    return false
}

/** Create a ping request */
export async function createPing(backend, ping, setErrorInfo) {
    try {
        let result = await backend.create('ping', ping, undefined, false)
        return result.status
    } catch(error) {
        console.error('Error creating ping:', error)
        setErrorInfo(error)
        return false
    }
}

export const isValidIPv4 = value => {
    return value && /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value);
}

export const isValidIPv6 = value => {
    const regex = new RegExp('('+
    '([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|' +         // 1:2:3:4:5:6:7:8
    '([0-9a-fA-F]{1,4}:){1,7}:|' +                        // 1::                              1:2:3:4:5:6:7::
    '([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|' +        // 1::8             1:2:3:4:5:6::8  1:2:3:4:5:6::8
    '([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|' + // 1::7:8           1:2:3:4:5::7:8  1:2:3:4:5::8
    '([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|' + // 1::6:7:8         1:2:3:4::6:7:8  1:2:3:4::8
    '([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|' + // 1::5:6:7:8       1:2:3::5:6:7:8  1:2:3::8
    '([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|' + // 1::4:5:6:7:8     1:2::4:5:6:7:8  1:2::8
    '[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|' +      // 1::3:4:5:6:7:8   1::3:4:5:6:7:8  1::8  
    ':((:[0-9a-fA-F]{1,4}){1,7}|:)|' +                    // ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8 ::8       ::     
    'fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|' +    // fe80::7:8%eth0   fe80::7:8%1     (link-local IPv6 addresses with zone index)
    '::(ffff(:0{1,4}){0,1}:){0,1}' +
    '((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}' +
    '(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|' +         // ::255.255.255.255   ::ffff:255.255.255.255  ::ffff:0:255.255.255.255  (IPv4-mapped IPv6 addresses and IPv4-translated addresses)
    '([0-9a-fA-F]{1,4}:){1,4}:' +
    '((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]).){3,3}' +
    '(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])' +          // 2001:db8:3:4::192.0.2.33  64:ff9b::192.0.2.33 (IPv4-Embedded IPv6 Address)
    ')$')

    // IPv6 RegEx
    return value && regex.test(value);
}
import React, { useState, useEffect, useContext } from 'react'

import './wifi.css'
import { BackendContext } from '../../../../backend/backend'
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import { frequency } from '../../../../app/resources/radio'
import { fetchConnectedDevicesList } from '../../../resources/connected-devices'
import { NetworksContext } from '../networks-context';
import Button from '../../../../components/button/button'
import { reduceWireless } from '../../../resources/wireless'
import { fetchWirelessList } from '../../../resources/wireless'
import { fetchRadioList } from '../../../resources/radio'

export default function WifiConnectedDevice({isWizard, setSaved, isFormSegment, formSegment, history}) {
    
    const networks = useContext(NetworksContext)
    const backend = useContext(BackendContext)
    
    const [wifiConnectedDevicePagination5ghz, setWifiConnectedDevicePagination5ghz] = useState(null)
    const [wifiConnectedDevicePagination24ghz, setWifiConnectedDevicePagination24ghz] = useState(null)
    const [connectedDevices, setConnectedDevices] = useState(null)
    const [wifiById, setWifiById] = useState(null)

    let reducedWifi = networks.reducedWifi.value

    let selectedWifiId = history.location.pathname.substring(history.location.pathname.lastIndexOf('/') + 1)


    const { t } = useTranslation()

    useEffect(() => {
        setWifiConnectedDevicePagination5ghz({pageNumber: 1, totalElements: 0, pageSize: 5})
        setWifiConnectedDevicePagination24ghz({pageNumber: 1, totalElements: 0, pageSize: 5})
        fetchConnectedDevicesList(backend, setConnectedDevices)
        
        fetchWirelessList(backend, networks.wifi.set)
        fetchRadioList(backend, networks.radio.set)
        
        // eslint-disable-next-line
    }, [])
    
    useEffect(() => {

        if(!networks.wifi.value || !networks.radio.value) return

        networks.reducedWifi.set(reduceWireless(networks.wifi.value, networks.radio.value))

        // eslint-disable-next-line
    }, [networks.wifi.value, networks.radio.value])
    

    useEffect(() => {
        if (reducedWifi) {
            setWifiById(
                reducedWifi?.reduce((dictionary, wifi) => {
                    if (wifi.frequency === frequency.dualband && wifi.id.length > 1)
                        return {...dictionary, [wifi.id[0]]: wifi, [wifi.id[1]]: wifi}
                    return {...dictionary, [wifi.id[0]]: wifi}
                },{})
            )
        }
    }, [reducedWifi])

    const selectedWifiFrequency = () => {
        return wifiById && wifiById[selectedWifiId].frequency
    }

    const selectedWifiSsid = () => {
        return wifiById && wifiById[selectedWifiId].ssid
    }

    const getColumns = () => {

        return [
            {header: getTranslation("column_header", "HOSTNAME"), align: 'center', size: '250px'},
            {header: getTranslation("column_header", "MAC"), align: 'center', size: '150px'},
            {header: <div style={{ whiteSpace: "normal" }}>{getTranslation("column_header", "TX_BYTES") + " (MB)"}</div>, align: 'center', size: '150px'},
            {header: <div style={{ whiteSpace: "normal" }}>{getTranslation("column_header", "RX_BYTES") + " (MB)"}</div>, align: 'center', size: '150px'},
            {header: <div style={{ whiteSpace: "normal" }}>{getTranslation("column_header", "TX_RATE")}</div>, align: 'center', size: '150px'},
        ]
    }

    const changePage5ghz = page => {
        setWifiConnectedDevicePagination5ghz({...wifiConnectedDevicePagination5ghz, pageNumber: page})
    }
    
    const changePage24ghz = page => {
        setWifiConnectedDevicePagination24ghz({...wifiConnectedDevicePagination24ghz, pageNumber: page})
    }

    const refreshConnectedDevices = () => {
        setConnectedDevices(null)
        fetchConnectedDevicesList(backend, setConnectedDevices)
    }

    const getTranslation = (type, key) => t(`network.wifi.connected_devices.${type}.${key}`)

    const getWifiConnectedDevice = (selectedFrequency) => {

        let wifiConnectedDeviceFiltered = []

        for(let i = 0; i < connectedDevices.length; i++) {

            if (wifiById && wifiById[selectedWifiId] && connectedDevices[i].frequency === selectedFrequency
                && connectedDevices[i].network === wifiById[selectedWifiId].ssid) {

                console.log("unitySize")
                console.log(connectedDevices[i].unitySize)
                wifiConnectedDeviceFiltered.push(
                    [
                        connectedDevices[i].hostname,
                        connectedDevices[i].mac, 
                        connectedDevices[i].txBytes, 
                        connectedDevices[i].rxBytes,
                        connectedDevices[i].txRate, 
                    ])
            }
        }

        let wifiConnectedDevicePagination = (selectedFrequency === frequency._5GHz) ? wifiConnectedDevicePagination5ghz : wifiConnectedDevicePagination24ghz
        let setWifiConnectedDevicePagination = (selectedFrequency === frequency._5GHz) ? setWifiConnectedDevicePagination5ghz : setWifiConnectedDevicePagination24ghz

        if(wifiConnectedDeviceFiltered.length !== wifiConnectedDevicePagination.totalElements) {
            wifiConnectedDevicePagination.totalElements = wifiConnectedDeviceFiltered.length
            setWifiConnectedDevicePagination({...wifiConnectedDevicePagination})
        }

        let wifiConnectedDeviceLines = [];

        let begining = (wifiConnectedDevicePagination.pageNumber - 1) * (wifiConnectedDevicePagination.pageSize)
        let end = begining + wifiConnectedDevicePagination.pageSize
        if (end > wifiConnectedDevicePagination.totalElements) {
            end = wifiConnectedDevicePagination.totalElements
        }

        for(let i = begining; i < end; i++) {
            wifiConnectedDeviceLines.push([
                ...wifiConnectedDeviceFiltered[i],
            ]);
        }

        return wifiConnectedDeviceLines;
    }

    const WifiConnectedDeviceList = (props) => {
        return <React.Fragment>
            <div id="info-card-connected-devices-list">
                <div className='subtitle'>{ props.title}</div>

                { getWifiConnectedDevice(props.selectedFrequency).length === 0 ?
                    <div className='info-card noelements-card'>
                        <Translator path={getTranslation("info","NO_DEFICES_CONNECTED")}></Translator>
                    </div>
                :
                    <div>
                        <div className="wifi-connected-devices-list-wrapper">
                            <List 
                                lines={getWifiConnectedDevice(props.selectedFrequency)}
                                columns={getColumns()}
                            ></List>
                        </div>
                        <Pager
                            pageNumber={props.pagination.pageNumber}
                            totalElements={props.pagination.totalElements}
                            pageSize={props.pagination.pageSize}
                            pageChangeFn={props.changePage}>
                        </Pager>
                    </div>
                }
                
            </div>
        </React.Fragment>
    }

    const wifiConnectedDeviceGeneralForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path={getTranslation("title","CONNECTED_TO_NETWORK") + " " + selectedWifiSsid()}></Translator></div>
            <div className='row'>
                <div><Button text={<Translator path="common.label.BACK"></Translator>} onClick={() => history.push('/network/wifi')} /></div>
                <div><Button text={<Translator path="network.wifi.status.messages.UPDATE_LIST"></Translator>} onClick={() => refreshConnectedDevices()} /></div>
            </div>
            { (selectedWifiFrequency() === frequency._5GHz || selectedWifiFrequency() === frequency.dualband) &&
                <WifiConnectedDeviceList 
                    title={getTranslation("title", "_5GHZ_FREQUENCY")} 
                    selectedFrequency={frequency._5GHz} 
                    pagination={wifiConnectedDevicePagination5ghz}
                    changePage={changePage5ghz}
                />
            }
            { (selectedWifiFrequency() === frequency._2_4GHz || selectedWifiFrequency() === frequency.dualband) &&
                <WifiConnectedDeviceList 
                    title={getTranslation("title", "_2_4GHZ_FREQUENCY")} 
                    selectedFrequency={frequency._2_4GHz} 
                    pagination={wifiConnectedDevicePagination24ghz}
                    changePage={changePage24ghz}
                />
            }
        </React.Fragment>
    }

    return (
        (!connectedDevices) || (!reducedWifi) || (!networks.wifi.value) || (!networks.radio.value) ? <Loading show={true}></Loading> :

        <div id='wifi-connected-devices-page' className='with-tabs'>

            { wifiConnectedDeviceGeneralForm() }

        </div>
    )
}

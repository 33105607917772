import { backendStatus } from "../../backend/backend"

export async function createSession(backend, user) {
    let result = await backend.create('session', user, undefined, false)
    if(result.status === backendStatus.SUCCESS){
        backend.setAuthInfo(result.content)
        return result.content
    }

    return result.status
}
import React, { useState, useEffect, useContext } from 'react'

import './system-automatic-maintenance.css'

import { fetchSystemAutomaticMaintenance, updateAutomaticMaintenance } from '../../../resources/system-automatic-maintenance';
import { fetchDatetime } from '../../../resources/datetime';
import { BackendContext } from '../../../../backend/backend';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import Checkbox from '../../../../components/checkbox/checkbox';
import Input from '../../../../components/input/input';
import extraValidators from '../../../common/validators';

export default function SystemAutomaticMaintenance({ isWizard, isFormSegment, formSegment }) {
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)

    const [automaticMaintenance, setAutomaticMaintenance] = useState(null)
    const [updateContinue, setUpdateContinue] = useState(false)
    const [datetime, setDatetime] = useState(null)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchSystemAutomaticMaintenance(backend, 0, setAutomaticMaintenance)
        fetchDatetime(backend, setDatetime)
        // eslint-disable-next-line
    }, [])

    const save = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await updateAutomaticMaintenance(backend, automaticMaintenance, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
    }

    const dismiss = () => {
        setUpdateContinue(false)
        setSuccess(false)
        setError(false)
    }

    const preSave = async () => {
        setSaving(false)
        setError(false)
        setSuccess(false)

        if (automaticMaintenance.enabled && !datetime.ntp_server.enabled)
            setUpdateContinue(true)
        else
            await save()
    }

    const continueUpdate = async (confirm) => {
        setSaving(false)
        setError(false)
        setSuccess(false)

        if (confirm) {
            setUpdateContinue(false)
            await save()
        } else {
            dismiss()
        }
    }

    const systemAutomaticMaintenanceForm = () => {
        return <React.Fragment>
            <Checkbox
                id='automatic-maint-enabled'
                name='automatic-maint-enabled'
                label={t('common.label.ENABLE')}
                value={automaticMaintenance.enabled}
                toggleFn={(e) => {
                    automaticMaintenance.enabled = !automaticMaintenance.enabled;
                    setAutomaticMaintenance({ ...automaticMaintenance })
                }}
                validators={[]}
            ></Checkbox>

            <div className='row'>
                <Input
                    type='number'
                    id='automatic-maint-hour'
                    name='automatic-maint-hour'
                    label={t('system.automatic_maintenance.label.HOUR')}
                    value={automaticMaintenance.time.hour}
                    onChange={(e) => {
                        automaticMaintenance.time.hour = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setAutomaticMaintenance({ ...automaticMaintenance })
                    }}
                    validators={[
                        extraValidators.isNumber,
                        extraValidators.isInteger,
                        extraValidators.required,
                        { fn: extraValidators.size, params: { min: 0, max: 2 } },
                        { fn: extraValidators.value, params: { min: 0, max: 23 } },
                    ]}
                ></Input>

                <Input
                    type='number'
                    id='automatic-maint-min'
                    name='automatic-maint-min'
                    label={t('system.automatic_maintenance.label.MIN')}
                    value={automaticMaintenance.time.min}
                    onChange={(e) => {
                        automaticMaintenance.time.min = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setAutomaticMaintenance({ ...automaticMaintenance })
                    }}
                    validators={[
                        extraValidators.isNumber,
                        extraValidators.isInteger,
                        extraValidators.required,
                        { fn: extraValidators.size, params: { min: 0, max: 2 } },
                        { fn: extraValidators.value, params: { min: 0, max: 59 } },
                    ]}
                ></Input>
            </div>

        </React.Fragment>
    }

    return !automaticMaintenance || !datetime ? <Loading show={true}></Loading> :
        <div id='system-automatic-maintenance-page' className='container scroll-area'>

            <DefaultStatusModals
                error={error}
                errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                success={success}
                continueFn={continueUpdate}
                confirm={updateContinue}
                confirmContent={
                    <div style={{ width: '420px' }}>
                        <span>
                            <Translator path="system.automatic_maintenance.warning.CONFIRMATION_MSG"></Translator>
                            <br></br>
                            <br></br>
                            <strong><Translator path="system.automatic_maintenance.warning.CONFIRM_QUESTION"></Translator></strong>
                        </span>
                    </div>
                }
                isWarningModal={true}
                dismissText={<Translator path="common.label.CANCEL"></Translator>}
                confirmText={<Translator path="common.label.CONFIRM"></Translator>}
            ></DefaultStatusModals>

            <DefaultStatusModals
                saving={saving}
                error={error}
                errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                success={success}
                continueFn={dismiss}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <React.Fragment>
                <div className='subtitle'><Translator path="menu.AUTOMATIC_MAINTENANCE"></Translator></div>
                <div className="card mt2">
                    <div className='subtitle'><Translator path="system.automatic_maintenance.label.CONFIG_TIME"></Translator></div>

                    <Form id='system-automatic-maintenance-form'
                        onSubmit={preSave}
                        buttonId='button-save-system-automatic-maintenance'
                    >
                        {systemAutomaticMaintenanceForm()}
                    </Form>

                </div>
            </React.Fragment>

        </div>
}
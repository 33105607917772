import MenuID from '../menu'

export const keywords = {
    [MenuID.DashboardSub.name]: {
        'pt-BR': [
            'de',
            'da',
            'do',
            'modo',
            'tempo',
            'ativo',
            'interfaces',
            'configuracao',
            'configuracoes',
            'dispositivos',
            'conectados',
            'status',
            'conexao',
            'versão',
            'sistema',
            'endereço',
            'modelo',
        ],
        'es': [
            'de',
            'del',
            'modo',
            'panel',
            'estado',
            'interfaces',
            'ajustes',
            'dispositivos',
            'conectados',
            'conexion',
            'red',
            'modelo',
            'direccion',
            'version',
            'tiempo',
            'activo',
            'sistema',
        ],
        'common': [
            'dashboard',
            'upload',
            'download',
            'tx',
            'rx',
            'power',
            'wlan',
            'wan',
            'inmesh',
            'mesh',
            'firmware',
            'uptime',
            'mac',
            'lan',
            'uptime',
            'radio',
        ]
    },

    [MenuID.NetworkWan.name]: {
        'pt-BR': [
            'rede',
            'tipo',
            'de',
            'conexao',
            'lista',
            'inserir',
            'nova',
            'configurar',
            'configurações',
            'mapeamento',
            'portas',
            'ativar',
            'mascara',
            'sub-rede',
        ],
        'es': [
            'red',
            'interfaz',
            'insertar',
            'lista',
            'habilitar',
            'de',
            'multidifusión',
            'solicitar',
            'prefijo',
            'asignación',
            'puertos',
            'puerta',
            'enlace',
            'configuración',
            'dirección',
            'máscara',
            'subred',
        ],
        'common': [
            'wan',
            'wans',
            'interface',
            'internet',
            'multicast',
            'dhcp',
            'static',
            'pppoe',
            'bridge',
            'tr-069',
            'tr069',
            'mtu',
            'dns',
            'vlan',
            'id',
            'ipv4',
            'ipv6',
            'nat',
            'napt',
            'prefix',
            'stateless',
            'dhcpv6',
            'slaac',
            'gateway',
        ],
    },

    [MenuID.NetworkLan.name]: {
        'pt-BR': [
            'rede',
            'configuracoes',
            'endereco',
            'enderecos',
            'mascara',
            'servidor',
            'reserva',
            'reservados',
            'clientes',
            'ativos',
            'sub-rede',
            'de',
            'da',
        ],
        'es': [
            'red',
            'configuración',
            'de',
            'la',
            'interfaz',
            'dirección',
            'direcciones',
            'máscara',
            'subred',
            'servidor',
            'inicil',
            'final',
            'reservas',
            'ver',
            'clientes',
            'activos',
        ],
        'common': [
            'lan',
            'interface',
            'ipv4',
            'dhcp',
            'dns',
            'ip',
            'hostname',
            'mac',
            'aging',
            'time',
        ]
    },

    [MenuID.NetworkWifi.name]: {
        'pt-BR': [
            'rede',
            'redes',
            'disponiveis',
            'criar',
            'nova',
            'configuracoes',
            'banda',
            'largura',
            'canal',
            'conectar',
            'senha',
            'frequencia',
            'conectados',
            'pontos',
            'de',
            'acesso',
            'virtuais',
            'segurança',
            'criptografia',
            'exibir',
            'clientes',
            'conectados',
            'dispositivos',
            'na',
            'nome',
            'senha',
            'clientes',
            'conectados',
            'canal',
            'criptografia',
        ],
        'es': [
            'red',
            'redes',
            'disponibles',
            'crear',
            'configuracion',
            'de',
            'la',
            'conectar',
            'por',
            'mostrar',
            'estado',
            'nombre',
            'contraseña',
            'canal',
            'criptografia',
            'clientes',
            'conectados',
        ],
        'common': [
            '2,4',
            '2,4G',
            '2,4ghz',
            '2.4',
            '2.4G',
            '2.4ghz',
            '5',
            '5G',
            '5ghz',
            'ghz',
            'vap',
            'ieee',
            'mu-mimo',
            'dual',
            'band',
            'status',
            'radio',
            'vap',
            'vaps',
            'password',
            'dual',
            'bssid',
            'wi-fi',
            'wifi',
            'wireless',
            'wlan',
            'wps',
            'ssid',
        ]
    },

    [MenuID.NetworkMesh.name]: {
        'pt-BR': [
            'rede',
            'configuracao',
            'configuracoes',
            'topologia',
        ],
        'es': [
            'red',
            'habilitar',
        ],
        'common': [
            'inmesh',
            'mesh',
        ]
    },

    /*NETWORK_TAG*/

    [MenuID.Ipv6Status.name]: {
        'pt-BR': [
            'protocolo',
            'ativar',
            'desativar',
        ],
        'es': [
            'protocolo',
            'habilitar',
            'desactivar',
        ],
        'common': [
            'ipv6',
            'status',
        ]
    },

    [MenuID.Ipv6ProxyMld.name]: {
        'pt-BR': [
            'configuracoes',
            'de',
        ],
        'es': [
            'configuración',
            'de',
        ],
        'common': [
            'ipv6',
            'proxy',
            'mld',
            'query',
            'interval',
        ]
    },

    [MenuID.Ipv6Radvd.name]: {
        'pt-BR': [
            'prefixo',
            'configurações',
            'de',
        ],
        'es': [
            'configuración',
            'de',
            'prefijo',
            'activar',
        ],
        'common': [
            'ipv6',
            'radvd',
            'advmanagedflag',
            'ula',
            'flag',
            'advonlink',
            'advautonomous',
            'rdnss',
        ]
    },

    [MenuID.Ipv6Dhcp.name]: {
        'pt-BR': [
            'clientes',
            'domínios',
            'lista',
            'de',
            'servidores',
            'prefixo',
            'ativos'
        ],
        'es': [
            'clientes',
            'activos',
            'lista',
            'de',
            'dominios',
            'servidores',
            'prefijo',
            'modo',
        ],
        'common': [
            'ipv6',
            'dhcp',
            'dhcpv6',
            'dns',
            'aging',
            'time',
            'relay',
        ]
    },

    [MenuID.Ipv6Acl.name]: {
        'pt-BR': [
            'configuracoes',
            'de',
            'segurança',
            'permissão',
            'prefixo',
            'regras',
        ],
        'es': [
            'configuración',
            'de',
            'reglas',
        ],
        'common': [
            'ipv6',
            'acl',
            'aclv6',
            'firewall',
            'TFTP',
            'FTP',
            'HTTP',
            'HTTPS',
            'SSH',
            'ping',
        ]
    },

    [MenuID.Ipv6RouteList.name]: {
        'pt-BR': [
            'lista',
            'de',
            'rotas',
            'salto',
            'metrica',
        ],
        'es': [
            'lista',
            'de',
            'rutas',
            'salto',
        ],
        'common': [
            'ipv6',
        ]
    },

    [MenuID.Ipv6StaticRoute.name]: {
        'pt-BR': [
            'lista',
            'de',
            'rotas',
            'estaticas',
            'salto',
            'metrica',
        ],
        'es': [
            'lista',
            'de',
            'rutas',
            'estaticas',
            'salto',
        ],
        'common': [
            'ipv6',
        ]
    },

    [MenuID.Ipv6IpPortFilter.name]: {
        'pt-BR': [
            'filtro',
            'ip/porta',
            'porta',
            'politica',
            'lista',
            'de',
            'regras',
        ],
        'es': [
            'filtro',
            'ip/puerto',
            'puerto',
            'politica',
            'lista',
            'de',
            'regras',
        ],
        'common': [
            'ipv6',
            'ip',
            'policy',
        ]
    },

    [MenuID.Ipv6PrefixDelegation.name]: {
        'pt-BR': [
            'prefixo',
            'delegado',
            'delegacao',
        ],
        'es': [
            'prefijo',
            'delegado',
            'modo',
            'de',
            'estatico',
        ],
        'common': [
            'ipv6',
            'prefix',
            'wab',
            'delegated',
        ]
    },

    /*IPV6_TAG*/

    [MenuID.PonCounters.name]: {
        'pt-BR': [
            'contadores',
            'taxas',
            'pacotes',
        ],
        'es': [
            'contadores',
            'paquetes',
        ],
        'common': [
            'pon',
            'FEC',
            'HEC',
            'unicast',
            'multicast',
            'broadcast',
        ]
    },

    [MenuID.PonStats.name]: {
        'pt-BR': [
            'estatisticas',
            'taxas',
            'pacotes',
        ],
        'es': [
            'estadísticas',
        ],
        'common': [
            'pon',
            'pps',
            'bps',
        ]
    },

    [MenuID.PonSettings.name]: {
        'pt-BR': [
            'configuracoes',
            'versão',
            'do',
            'de',
            'segurança',
            'numero',
            'serie',
            'perfil',
        ],
        'es': [
            'ajustes',
            'numero',
            'de',
            'serie',
            'versión',
            'del',
            'perfil',
            'contraseña'
        ],
        'common': [
            'pon',
            'oam',
            'omci',
            'mac',
            'hardware',
            'loid',
            'epon',
            'gpon',
            'id',
            'ploam',
            'onu',
            'model',
            'olt',
            'profile',
            'intelbras',
            'huawei',
            'zte',
            'fh',
            'nokia',
        ]
    },

    [MenuID.PonStatus.name]: {
        'pt-BR': [
            'temperatura',
            'tensão',
            'corrente',
            'intensidade',
            'sinal',
            'modo',
            'taxas',
            'de',
            'do',
            'polarização',
        ],
        'es': [
            'estado',
            'modo',
            'intensidad',
            'señal',
            'de',
            'la',
            'voltaje',
            'corriente',
            'polarización',
            'temperatura',
        ],
        'common': [
            'pon',
            'status',
            'tx',
            'rx',
            'dbm',
            'mA',
            'v',
        ]
    },

    [MenuID.PonEponLlidStatus.name]: {
        'pt-BR': [
        ],
        'es': [
            'estado',
        ],
        'common': [
            'pon',
            'epon',
            'status',
            'llid',
            'id',
        ]
    },

    [MenuID.PonGponStatus.name]: {
        'pt-BR': [
            'estado',
        ],
        'es': [
            'información',
            'de',
            'estado',
        ],
        'common': [
            'pon',
            'status',
            'gpon',
            'state',
            'onu',
            'id',
        ]
    },

    [MenuID.PonGemPortStatistics.name]: {
        'pt-BR': [
            'tabela',
            'estatísticas',
            'das',
            'portas',
            'pacotes',
        ],
        'es': [
            'tabla',
            'estadísticas',
            'de',
            'los',
            'puertos',
            'recuento',
            'de',
            'paquetes',
        ],
        'common': [
            'pon',
            'gem',
            'id',
        ]
    },

    /*PON_TAG*/
    [MenuID.VoipProxy.name]: {
        'pt-BR': [
            'configuracoes',
            'gerais',
            'registro',
            'padrao',
            'senha',
            'informacoes',
            'conexao',
            'endereco',
            'porta',
            'dominio',
            'saida',
            'sessao',
            'registrar',
            'telefone',
        ],
        'es': [
            'configuracion',
            'de',
            'cuenta',
            'registro',
            'numero',
            'contraseña',
            'información',
            'conexión',
            'dominio',
        ],
        'common': [
            'voip',
            'proxy',
            'status',
            'fxs',
            'login',
            'id',
            'sip',
        ]
    },

    [MenuID.VoipHistoryCall.name]: {
        'pt-BR': [
            'historico',
            'chamadas',
            'telefone',
            'de',
        ],
        'es': [
            'historial',
            'de',
            'llamadas',
        ],
        'common': [
            'voip',
        ]
    },

    [MenuID.VoipDialPlan.name]: {
        'pt-BR': [
            'plano',
            'de',
            'discagem',
            'porta',
            'telefone',
        ],
        'es': [
            'plan',
            'de',
            'marcación',
            'puerto',
            'marcado',
        ],
        'common': [
            'voip',
        ]
    },

    [MenuID.VoipAdvancedSip.name]: {
        'pt-BR': [
            'configuracoes',
            'porta',
            'ganho',
            'microfone',
            'auto-falante',
            'maximo',
            'telefone',
            'de',
            'avançadas',
            'plano',
            'discagem',
        ],
        'es': [
            'configuración',
            'puerto',
            'avanzada',
            'de',
            'ganancia',
            'micrófono',
            'altavoz',
            'maxima',
        ],
        'common': [
            'voip',
            'sip',
            'info',
            'dtmf',
            'fxs',
            'fax/modem',
            'modem',
            'fax',
            'agc',
            'rx',
            'tx',
            'rfc2833',
            'inband',
            'delete',
            'prack',
        ]
    },

    [MenuID.VoipCodec.name]: {
        'pt-BR': [
            'amostragem',
            'precedencia',
            'telefone',
            'ordem',
            'do',
            'pacote',
            'redundante',
        ],
        'es': [
            'redundante',
            'orden',
            'de',
            'paquete',
        ],
        'common': [
            'voip',
            'codec',
            'fxs',
            'rtp',
            'u-law',
            'a-law',
            'g.729',
            'g711-ulaw',
            'g711-alaw',
            'g729',
            'g722',
            'g726-24k',
            'g726-32k',
            'g726-40k',
            'g723',
            'bit',
            'payload',
        ]
    },

    /*VOIP_TAG*/

    [MenuID.ParentalControlConnectedDevices.name]: {
        'pt-BR': [
            'controle',
            'dos',
            'pais',
            'parental',
            'lista',
            'de',
            'dispositivos',
            'conectados',
            'historico',
            'taxa',
            'endereço',
        ],
        'es': [
            'control',
            'parental',
            'dispositivos',
            'conectados',
            'lista',
            'de',
        ],
        'common': [
            'mac',
            'ip',
        ]
    },

    [MenuID.ParentalControlSub.name]: {
        'pt-BR': [
            'controle',
            'dos',
            'pais',
            'parental',
            'regra',
            'dias',
            'horário',
        ],
        'es': [
            'control',
            'parental',
            'regla',
            'días',
            'tiempo',
            'hora',
        ],
        'common': [
        ]
    },

    /*PARENTAL_CONTROL_TAG*/

    [MenuID.FirewallAlg.name]: {
        'pt-BR': [
            'segurança',
            'habilitar',
            'configurações',
            'de',
        ],
        'es': [
            'habilitar',
            'configuración',
            'de',
        ],
        'common': [
            'firewall',
            'alg',
            'ftp',
            'ipsec',
            'pptp',
            'sip',
            'h323',
            'l2tp',
            'rtsp',
            'tftp',
        ]
    },

    [MenuID.FirewallUrlBlocking.name]: {
        'pt-BR': [
            'bloqueio',
            'de',
            'configuracoes',
            'segurança',
            'ativar',
            'lista',
        ],
        'es': [
            'bloqueo',
            'de',
            'configuración',
            'agregar',
            'nueva',
            'palabra',
            'clave',
            'lista',
        ],
        'common': [
            'firewall',
            'url',
            'urls',
            'keyword',
        ]
    },

    [MenuID.FirewallMacFiltering.name]: {
        'pt-BR': [
            'filtro',
            'filtragem',
            'filtros',
            'configuracoes',
            'de',
            'lista',
            'segurança',
        ],
        'es': [
            'filtrado',
            'configuración',
            'del',
            'filtro',
            'lista',
            'de',
        ],
        'common': [
            'firewall',
            'mac',
        ]
    },

    [MenuID.FirewallIpPortFiltering.name]: {
        'pt-BR': [
            'filtro',
            'filtragem',
            'filtros',
            'ip/porta',
            'porta',
            'lista',
            'segurança',
            'configurações',
            'de',
        ],
        'es': [
            'filtrado',
            'de',
            'ip/puertos',
            'puertos',
        ],
        'common': [
            'firewall',
            'ip',
        ]
    },

    [MenuID.FirewallDomainBlocking.name]: {
        'pt-BR': [
            'bloqueio',
            'dominio',
            'dominios',
            'configuracoes',
            'de',
            'segurança',
        ],
        'es': [
            'configuración',
            'de',
            'bloqueo',
            'dominio',
        ],
        'common': [
            'firewall',
        ]
    },

    [MenuID.FirewallPortForwarding.name]: {
        'pt-BR': [
            'redirecionamento',
            'de',
            'portas',
            'segurança',
        ],
        'es': [
            'asignación',
            'de',
            'puertos',
            'regla',
        ],
        'common': [
            'firewall',
            'port',
            'forward',
            'forwarding',
        ]
    },

    [MenuID.FirewallDmz.name]: {
        'pt-BR': [
            'de',
            'configuracoes',
            'segurança',
        ],
        'es': [
            'configuración',
            'de',
        ],
        'common': [
            'firewall',
            'dmz',
        ]
    },

    [MenuID.FirewallWlanAccessControl.name]: {
        'pt-BR': [
            'controle',
            'de',
            'acesso',
            'segurança',
            'configurações',
            'políticas',
            'para',
            'os',
            'banda',
        ],
        'es': [
            'control',
            'de',
            'acceso',
            'configuración',
            'políticas',
        ],
        'common': [
            'firewall',
            'wlan',
            'mac',
            '2,4',
            '2.4',
            '5',
            '5G',
            '2.4G',
            '2,4G',
            'ghz',
            '2,4ghz',
            '2.4ghz',
            '5ghz',
            'radio',
            'radios',
        ]
    },

    /*FIREWALL_TAG*/

    [MenuID.ToolsPing.name]: {
        'pt-BR': [
            'ferramentas',
            'de',
            'configurações',
            'saltos',
            'pacotes',
        ],
        'es': [
            'herramientas',
            'configuración',
            'de',
            'paquete',
            'saltos',
        ],
        'common': [
            'ping',
            'ipv6',
        ]
    },

    [MenuID.ToolsTr069Inform.name]: {
        'pt-BR': [
            'ferramentas',
            'de',
            'informacao',
            'informe',
        ],
        'es': [
            'herramientas',
            'información',
            'informe',
            'de',
        ],
        'common': [
            'tr069',
            'tr-069',
            'cwmp',
        ]
    },

    [MenuID.ToolsTraceroute.name]: {
        'pt-BR': [
            'ferramentas',
            'configuração',
            'configurações',
            'de',
            'saltos',
            'pacotes',
        ],
        'es': [
            'herramientas',
            'configuración',
            'de',
            'saltos',
            'paquete',

        ],
        'common': [
            'traceroute',
            'ipv6',
            'ttl',
        ]
    },

    [MenuID.ToolsLoopbackDetect.name]: {
        'pt-BR': [
            'ferramentas',
            'deteccao',
            'de',
            'configuracoes',
            'intervalos',
            'quadro',
        ],
        'es': [
            'herramientas',
            'status',
            'de',
            'detección',
            'bucle',
            'configuración',
            'intervalo',
            'intervalos',
        ],
        'common': [
            'loop',
            'loops',
            'status',
            'vlan',
            'id',
        ]
    },

    /*TOOLS_TAG*/

    [MenuID.ServiceProxyIgmp.name]: {
        'pt-BR': [
            'servicos',
            'de',
            'configuracoes',
        ],
        'es': [
            'servicios',
            'configuración',
            'de',
        ],
        'common': [
            'proxy',
            'igmp',
            'multicast',
            'query',
            'interval',
        ]
    },

    [MenuID.ServiceUpnp.name]: {
        'pt-BR': [
            'servicos',
            'configurações',
            'de',
        ],
        'es': [
            'servicios',
            'configuración',
            'de',
        ],
        'common': [
            'upnp',
        ]
    },

    [MenuID.ServiceDynamicDns.name]: {
        'pt-BR': [
            'servicos',
            'servico',
            'servico dns',
            'dinamicos',
            'configuração',
            'configurações',
            'de',
            'lista',
            'provedor',
        ],
        'es': [
            'servicios',
            'configuración',
            'de',
            'dinámico',
            'proveedor',
        ],
        'common': [
            'dns',
            'ddns',
            'dyndns',
            'noip',
            'ddns'
        ]
    },

    [MenuID.ServiceRipConfig.name]: {
        'pt-BR': [
            'servicos',
            'de',
            'configuracao',
            'tabela',
        ],
        'es': [
            'servicios',
            'configuración',
            'de',
            'tabla',
        ],
        'common': [
            'rip',
            'tx',
            'rx',
        ]
    },

    /*SERVICE_TAG*/

    [MenuID.AdvancedNeighbourHosts.name]: {
        'pt-BR': [
            'avancado',
            'dispositivos',
            'vizinhos',
        ],
        'es': [
            'avanzado',
            'dispositivos',
            'vecinos',
        ],
        'common': [
            'mac',
        ]
    },

    [MenuID.AdvancedStaticRouting.name]: {
        'pt-BR': [
            'avancado',
            'rotas',
            'de',
            'estaticas',
            'lista',
            'saltos',
            'salto',
            'metrica',
        ],
        'es': [
            'avanzado',
            'lista',
            'de',
            'rutas',
            'estaticas',
            'crear',
            'nueva'
        ],
        'common': [
        ]
    },

    [MenuID.AdvancedQosClassification.name]: {
        'pt-BR': [
            'avancado',
            'classificacao',
            'classificações',
            'de',
            'qualidade',
            'prioridade',
            'precedência',
        ],
        'es': [
            'avanzado',
            'clasificación',
            'de',
            'tráfico',
        ],
        'common': [
            'qos',
            '802.1p',
            'dscp',
            'cos',
        ]
    },

    [MenuID.AdvancedQosPolicy.name]: {
        'pt-BR': [
            'avancado',
            'politica',
            'fila',
            'configurações',
            'de',
            'banda',
            'largura',
            'qualidade',
            'prioridade',
        ],
        'es': [
            'avanzado',
            'política',
            'de',
            'prioridad',
            'cola',
        ],
        'common': [
            'qos',
        ]
    },

    [MenuID.AdvancedQosTrafficControl.name]: {
        'pt-BR': [
            'avancado',
            'controle',
            'de',
            'trafego',
            'qualidade',
            'prioridade',
            'direção',
        ],
        'es': [
            'avanzado',
            'control',
            'de',
            'trafico',
        ],
        'common': [
            'qos',
        ]
    },

    [MenuID.AdvancedGlobalRouting.name]: {
        'pt-BR': [
            'avancado',
            'roteamento',
            'global',
            'salto',
            'metrica',
        ],
        'es': [
            'avanzado',
            'enrutamiento',
            'global',
            'métrica',
            'salto',
        ],
        'common': [
        ]
    },

    [MenuID.AdvancedBridging.name]: {
        'pt-BR': [
            'avancado',
            'de',
            'configuracoes',
            'encaminhamento',
        ],
        'es': [
            'avanzado',
            'configuración',
            'de',
            'enrutamiento',
        ],
        'common': [
            'bridging',
            'bridge',
            '802.1d',
            'spanning',
            'tree',
            'mac',
            'ageing',
            'aging',
            'timer',
            'time',
            'mac',
        ]
    },

    [MenuID.AdvancedIgmpMldSnooping.name]: {
        'pt-BR': [
            'avancado',
        ],
        'es': [
            'avanzado',
        ],
        'common': [
            'igmp',
            'mld',
            'igmp/mld',
            'snooping',
        ]
    },

    /*ADVANCED_TAG*/

    [MenuID.SystemUsers.name]: {
        'pt-BR': [
            'sistema',
            'usuario',
            'senha',
            'credenciais',
        ],
        'es': [
            'sistema',
            'nombre',
            'de',
            'usuario',
            'y',
            'contraseña',
            'credenciales',
        ],
        'common': [
            'login',
            'admin',
            'password',
            'user',
        ]
    },

    [MenuID.SystemAclAdmin.name]: {
        'pt-BR': [
            'sistema',
            'configuracoes',
            'de',
            'regra',
            'regras',
            'segurança',
            'permissão',
        ],
        'es': [
            'sistema',
            'configuración',
            'de',
        ],
        'common': [
            'acl',
            'firewall',
            'tftp',
            'ftp',
            'http',
            'https',
            'ssh',
            'ping',
        ]
    },

    [MenuID.SystemTr069Config.name]: {
        'pt-BR': [
            'sistema',
            'configurações',
            'de',
            'conexão',
        ],
        'es': [
            'sistema',
            'configuración',
            'de',
            'conexion',
        ],
        'common': [
            'tr-069',
            'tr069',
            'cwmp',
            'acs',
        ]
    },

    [MenuID.SystemLeds.name]: {
        'pt-BR': [
            'sistema',
            'configuracoes',
        ],
        'es': [
            'sistema',
            'configuración',
            'de',
        ],
        'common': [
            'led',
            'leds',
        ]
    },

    [MenuID.SystemReboot.name]: {
        'pt-BR': [
            'sistema',
            'reiniciar',
        ],
        'es': [
            'sistema',
            'reiniciar',
        ],
        'common': [
            'reboot',
            'restart',
        ]
    },

    [MenuID.SystemRestore.name]: {
        'pt-BR': [
            'sistema',
            'restaurar',
            'restauracao',
            'do',
        ],
        'es': [
            'sistema',
            'restaurar',
            'restauracion',
            'del',
            'sistema',
        ],
        'common': [
            'restore',
        ]
    },

    [MenuID.SystemBackupRestore.name]: {
        'pt-BR': [
            'sistema',
            'restauracao',
            'atraso',
            'no',
            'botao',
            'configuracoes',
            'atuais',
            'alterar',
            'do',
            'para',
            'customizadas',
            'padrao',
        ],
        'es': [
            'sistema',
            'copia',
            'de',
            'seguridad',
            'crear',
            'archivo',
            'respaldo',
            'la',
            'configuración',
            'restaurar',
            'actual',
            'desde',
            'un',
            'retraso',
            'del',
            'botón',
            'reinicio',
            'cambiar',
            'predeterminada',
            'a',
        ],
        'common': [
            'backup',
            'reset',
            'reboo',
            'button',
        ]
    },

    [MenuID.SystemChangeFirwareVersion.name]: {
        'pt-BR': [
            'sistema',
            'versao',
            'de',
            'trocar',
            'substituir',
            'versão',
        ],
        'es': [
            'sistema',
            'cambiar',
            'la',
            'versión',
            'de',
            'actual',
            'respaldo',
            'versiones',
        ],
        'common': [
            'firmware',
            'backup',
            'software',
            'version',
        ]
    },

    [MenuID.SystemUpdate.name]: {
        'pt-BR': [
            'sistema',
            'atualizar',
            'versao',
            'de'
        ],
        'es': [
            'sistema',
            'actualizar',
            'firmware',
            'versión',
            'archivo',
        ],
        'common': [
            'firmware',
            'software',
            'update',
            'upgrade',
        ]
    },

    [MenuID.SystemDateTime.name]: {
        'pt-BR': [
            'sistema',
            'fuso',
            'horario',
            'configurações',
            'de',
            'horario',
            'data',
            'hora',
            'data/hora',
            'verao',
        ],
        'es': [
            'sistema',
            'zona',
            'horaria',
            'horario',
            'de',
            'verano',
            'fecha',
            'hora',
            'fecha/hora',
            'huso',
        ],
        'common': [
            'ntp',
            'timezone'
        ]
    },

    [MenuID.SystemAutomaticMaintenance.name]: {
        'pt-BR': [
            'sistema',
            'manutencao',
            'automatica',
            'configurar',
            'horario',
            'para',
        ],
        'es': [
            'sistema',
            'mantenimiento',
            'automático',
            'configurar',
            'el',
            'tiempo',
            'para',
        ],
        'common': [
        ]
    },

    [MenuID.SystemInformation.name]: {
        'pt-BR': [
            'sistema',
            'termos',
            'de',
            'uso',
            'contrato',
            'licença',
            'direitos',
            'autorais',
            'privacidade',
        ],
        'es': [
            'sistema',
            'términos',
            'de',
            'uso',
            'del',
            'licencia',
            'contrato',
        ],
        'common': [
        ]
    },

    /*SYSTEM_TAG*/

    [MenuID.Logout.name]: {
        'pt-BR': [
            'sair',
            'encerrar',
        ],
        'es': [
            'cerrar',
            'sesion',
        ],
        'common': [
            'logout',
            'quit'
        ]
    },
}

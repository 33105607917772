import { backendStatus } from '../../backend/backend'

export const NeighbourUnreachabilityDetection = [
    'PERMANENT',
    'NOARP',
    'REACHABLE',
    'STALE',
    'NONE',
    'INCOMPLETE',
    'DELAY',
    'PROBE',
    'FAILED'
]

export async function fetchNeighbourHosts(backend, setNeighbourHosts, handleError) {
    let result = await backend.retrieveFresh('neighbour_hosts')

    if(result.status === backendStatus.SUCCESS){

        let neighbourHosts = result.content
        setNeighbourHosts(neighbourHosts)
    } else {
        handleError();
    }
}

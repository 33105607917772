import { backendStatus } from '../../backend/backend'

export const WANServiceTypeOptions = [
    {
        value: 1,
        text: 'TR-069',
        serviceType: {
            service_other_video: false,
            service_tr069: true,
            service_internet: false,
            service_voice: false
        }
    },
    {
        value: 2,
        text: 'Internet',
        serviceType: {
            service_other_video: false,
            service_tr069: false,
            service_internet: true,
            service_voice: false
        }
    },
    {
        value: 3,
        text: 'Internet/TR-069',
        serviceType: {
            service_other_video: false,
            service_tr069: true,
            service_internet: true,
            service_voice: false
        }
    },
    {
        value: 4,
        text: 'Outro',
        serviceType: {
            service_other_video: true,
            service_tr069: false,
            service_internet: false,
            service_voice: false
        }
    },
    {
        value: 8,
        text: 'Voz',
        serviceType: {
            service_other_video: false,
            service_tr069: false,
            service_internet: false,
            service_voice: true
        }
    },
    {
        value: 9,
        text: 'Voz/TR-069',
        serviceType: {
            service_other_video: false,
            service_tr069: true,
            service_internet: false,
            service_voice: true
        }
    },
    {
        value: 10,
        text: 'Voz/Internet',
        serviceType: {
            service_other_video: false,
            service_tr069: false,
            service_internet: true,
            service_voice: true
        }
    },
    {
        value: 11,
        text: 'Voz/Internet/TR-069',
        serviceType: {
            service_other_video: false,
            service_tr069: true,
            service_internet: true,
            service_voice: true
        }
    },
]

export const WANServiceTypeBridgedOptions = [
    {
        value: 2,
        text: 'Internet',
        serviceType: {
            service_other_video: false,
            service_tr069: false,
            service_internet: true,
            service_voice: false
        }
    },
    {
        value: 4,
        text: 'Outro',
        serviceType: {
            service_other_video: true,
            service_tr069: false,
            service_internet: false,
            service_voice: false
        }
    },
]

export async function fetchNetworkWan(id, backend, setWan) {

    let wan = {
        "id": "",
        "interfaceID": "",
        "mode": 0,
        "address_mode_v6" : 2,
        "gateway": "",
        "gateway_v6": "",
        "dns_v4": {
            "automatic": true,
            "dns1": "",
            "dns2": "",
            "dns3": ""
        },
        "dns_v6": {
            "automatic": true,
            "dns1": "",
            "dns2": "",
            "dns3": ""
        },
        "clonedMac": "",
        "napt_enable": true,
        "vlan_enable": false,
        "prefix_enable": true,
        "protocol": 0,
        "vlanID": 0,
        "multicast_vlanID": 0,
        "isCreate": true,
    }
    if (!id) {
        setWan(wan)
    } else {
        let result = await backend.retrieveFresh(`wan/${id}`)

        if (result.status === backendStatus.SUCCESS) {

            let wanR = result.content

            if (wanR.gateway_v6 === '0::0/0') {
                wanR.gateway_v6 = ''
            }

            let backup = wanR.port_mapping
            wanR.port_mapping = wan.port_mapping
            for (let key in backup) {
                let mirror = wanR.port_mapping.filter(pm => pm.interfaceID === backup[key].interfaceID)
                if (mirror.length > 0) {
                    mirror[0].enabled = backup[key].enabled
                }
            }

            setWan(wanR)
        }
    }
}

export async function fetchNetworkWanList(backend, setWanList) {
    let result = await backend.retrieveFresh('wan')

    if (result.status === backendStatus.SUCCESS) {

        let wanList = result.content

        setWanList(wanList)
    }
}

export async function deleteWanById(backend, id, setWanList) {
    let result = await backend.delete(`wan/${id}`)

    if (result.status === backendStatus.SUCCESS) {
        await fetchNetworkWanList(backend, setWanList)
    } else {
        return false
    }
    return true
}

export async function saveNetworkWan(backend, wan, setErrorMessage, apply_now = false) {

    try {

        let result = null;

        if (wan.isCreate) {
            result = await backend.create('wan', wan, '', apply_now)
        }
        else {
            result = await backend.update(`wan/${wan.id}`, wan, '', apply_now)
        }

        if (result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && result.content

    } catch (error) {

        console.error('Error saving WAN:', error)

        return false
    }

}
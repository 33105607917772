import { backendStatus } from  '../../backend/backend'

export async function fetchPppoeList(backend, setPppoeList) {
    
    try{
        let result = await backend.retrieveFresh('pppoe')
        if(result.status === backendStatus.SUCCESS){
            setPppoeList(result.content)
            return result.content
        }
    }catch(error){
        console.error('Error fetching pppoe list')
    }

    return null
}

export async function fetchPppoe(id, backend, setPppoe) {
    let empty = {
        "id": "",
        "interfaceID": "",
        "username": "",
        "password": "",
        "service": "",
        "server": "",
        "mtu": 1492,
    }
    try{
        let result = await backend.retrieveFresh(`pppoe/${id}`)
        if(result.status === backendStatus.SUCCESS && result.content && Object.keys(result.content).length !== 0){
            setPppoe(result.content)
            return result.content
        } else {
            setPppoe(empty)
            return empty
        }
    }catch(error){
        console.error('Error fetching pppoe')
    }

    return null
}

export async function savePpppoe(backend, pppoe, apply_now = false) {

    try{

        let result = await backend.update(`pppoe/${pppoe.id}`, pppoe, '', apply_now)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving PPPoE:', error)

        return false
    }

}
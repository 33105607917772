import { backendStatus } from  '../../backend/backend'

export async function fetchStatus(backend, setStatus) {

    try{
        let result = await backend.retrieveFresh('status')
        if(result.status === backendStatus.SUCCESS){
            setStatus(result.content[0])
            return result.content[0]
        }
    }catch(error){
        console.error('Error fetching status: trying again in 5s')
        setTimeout(() => fetchStatus(backend, setStatus), 5000)
    }

    return null
}
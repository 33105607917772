import React, { useState, useEffect, useContext } from 'react'
import Modal, { ModalTypes } from '../components/modal/modal';
import { BackendContext } from '../backend/backend';
import { fetchStatus } from './resources/status';

export const SystemStatusContext = React.createContext()

const STATUS_INTERVAL = 10000

const UpdatingModal = ({open}) => {

    const updatingContent = <React.Fragment>
        <div>Por favor aguarde, seu dispositivo está sendo atualizado.</div>
    </React.Fragment>

    return <Modal
        title='Atualizando'
        type={ModalTypes.LOADING}
        show={open}
        content={updatingContent}
    ></Modal>
}

export default function SystemStatus({children}) {

    const [status, setStatus] = useState(null)
    const [disableUpdateModal, setDisableUpdateModal] = useState(false)
    const [disabled, setDisabled] = useState(false)

    let backend = useContext(BackendContext)

    useEffect(() => {

        if(!disabled) fetchStatus(backend, setStatus)

        // eslint-disable-next-line
    }, [disabled])

    const fetchStatusData = (mounted) => {

        if(disabled){
            console.log('Status disabled')
            return
        }

        mounted && fetchStatus(backend, setStatus)
    }

    useEffect(() => {

        if(!status)
            return

        let mounted = true
        setTimeout(() => fetchStatusData(mounted), STATUS_INTERVAL)

        return () => mounted = false

        // eslint-disable-next-line
    }, [status])

    return status ? <SystemStatusContext.Provider value={{
        status: status,
        disableUpdateModal: setDisableUpdateModal,
        setDisabled: setDisabled
    }}>
        {
            !disableUpdateModal ? <UpdatingModal open={status.firmware_update}></UpdatingModal> : null
        }
            {children}
    </SystemStatusContext.Provider> : null
}
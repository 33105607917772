import React, { useContext } from "react"
import { Route } from "react-router-dom"
import { GlobalsContext } from "../../globals-context"

export default function FeatureBasedRoute(props) {
    let globals = useContext(GlobalsContext)

    return (
        <div>
            {globals.containsFeature(props.id) &&
                <Route exact path={props.path} component={props.component}></Route>}
        </div>
    )
}

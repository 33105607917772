import { backendStatus } from '../../backend/backend'

export async function fetchVoipProxy(id, backend, setVoipProxy) {
    let result = await backend.retrieveFresh(`voip_proxy/${id}`)

    if(result.status === backendStatus.SUCCESS){

        let voipProxy = result.content

        setVoipProxy(voipProxy)
    }
}

export async function fetchVoipProxyList(backend, setVoipProxyList) {
    let result = await backend.retrieveFresh('voip_proxy')

    if(result.status === backendStatus.SUCCESS){

        let voipProxyList = result.content

        setVoipProxyList(voipProxyList)
    }
}

export async function saveVoipProxy(backend, voipProxy) {

    try{

        let result = null;

        if(voipProxy.isCreate)
            result = await backend.create('voip_proxy', voipProxy)
        else
            result = await backend.update(`voip_proxy/${voipProxy.id}`, voipProxy)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving VOIP Proxy:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import GeneralSettings from './components/general-settings'
import IpSettings from './components/ip-settings'

import './ipv6-ip-port-filter.css'

import { useParams, useHistory } from 'react-router-dom'
import { BackendContext } from '../../../../backend/backend'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import waitRouter from '../../../utils/wait-router'
import { GlobalsContext } from '../../../globals-context'
import Form, { FormContext } from '../../../../components/form/form'
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next'
import Button from '../../../../components/button/button'
import { fetchWanList } from '../../../resources/wan';
import { fetchServiceSystem } from '../../../resources/system';

import {
    fetchIpv6IpPortFilter,
    saveIpv6IpPortFilter,
    createIpv6IpPortFilter,
} from '../../../resources/ipv6-ip-port-filter';

import { Ipv6IpPortFilterStruct, ProtocolEnumeration } from "./ipv6-ip-port-filter-common"

export default function Ipv6IpPortFilterEdit({ isWizard, isFormSegment, formSegment }) {
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [dataFetched, setDataFetched] = useState(false)

    const [wanRes, setWanRes] = useState([])
    const [system, setSystem] = useState(true)
    const [generalSettings, setGeneralSettings] = useState(null)
    const [srcIp, setSrcIp] = useState(null)
    const [srcPort, setSrcPort] = useState(null)
    const [dstIp, setDstIp] = useState(null)
    const [dstPort, setDstPort] = useState(null)

    const [allowConfigureSrcIP, setAllowConfigureSrcIP] = useState(true)
    const [allowConfigureDstIP, setAllowConfigureDstIP] = useState(true)

    const params = useParams()
    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const [Ipv6IpPortFilterElementsList, setIpv6IpPortFilterElementsList] = useState(null)

    const history = useHistory()
    const { t } = useTranslation()

    useEffect(() => {
        const fetchData = async () => {
            await fetchIpv6IpPortFilter(backend, setIpv6IpPortFilterElementsList)
            await fetchWanList(backend, setWanRes)
            await fetchServiceSystem(backend, setSystem)
            setDataFetched(true)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const anyConfigMade = (ip, port) => {
            return ip.start !== '' || ip.end !== '' || ip.prefix_length !== '' || port.from !== '' || port.to !== ''
        }

        if (dataFetched === true) {
            if (params.id === 'add') {
                let { src_ipv6, dst_ipv6, source_port, destination_port, ...defaultGeneralSettings } = Ipv6IpPortFilterStruct
                setGeneralSettings(defaultGeneralSettings)
                setSrcIp(src_ipv6)
                setDstIp(dst_ipv6)
                setSrcPort(source_port)
                setDstPort(destination_port)

            } else {
                if (Ipv6IpPortFilterElementsList !== null && Ipv6IpPortFilterElementsList.length > 0) {
                    let filterToEdit = Ipv6IpPortFilterElementsList.filter(element => element.id === params.id)[0]

                    let { src_ipv6, dst_ipv6, source_port, destination_port, ...defaultGeneralSettings } = filterToEdit
                    setGeneralSettings(defaultGeneralSettings)
                    setSrcIp(src_ipv6)
                    setDstIp(dst_ipv6)
                    setSrcPort(source_port)
                    setDstPort(destination_port)

                    let configMade = anyConfigMade(filterToEdit.src_ipv6, filterToEdit.source_port)
                    setAllowConfigureSrcIP(configMade)

                    configMade = anyConfigMade(filterToEdit.dst_ipv6, filterToEdit.destination_port)
                    setAllowConfigureDstIP(configMade)
                }

            }
        }

        // eslint-disable-next-line
    }, [dataFetched])

    useEffect(() => {
        if (!allowConfigureSrcIP && srcIp && srcPort) {

            let src_ipv6 = { ...srcIp }
            let src_port = { ...srcPort }
            let ipChanged = false
            let portChanged = false

            Object.keys(src_ipv6).forEach(att => {
                if (src_ipv6[att] !== '') {
                    src_ipv6[att] = ''
                    ipChanged = true
                }
            });

            Object.keys(src_port).forEach(att => {
                if (src_port[att] !== '') {
                    src_port[att] = ''
                    portChanged = true
                }
            });

            if (ipChanged)
                setSrcIp(src_ipv6)

            if (portChanged)
                setSrcPort(src_port)
        }

        // eslint-disable-next-line
    }, [allowConfigureSrcIP])

    useEffect(() => {
        if (!allowConfigureDstIP && dstIp && dstPort) {

            let dst_ipv6 = { ...srcIp }
            let dst_port = { ...srcPort }
            let ipChanged = false
            let portChanged = false

            Object.keys(dst_ipv6).forEach(att => {
                if (dst_ipv6[att] !== '') {
                    dst_ipv6[att] = ''
                    ipChanged = true
                }
            });

            Object.keys(dst_port).forEach(att => {
                if (dst_port[att] !== '') {
                    dst_port[att] = ''
                    portChanged = true
                }
            });

            if (ipChanged)
                setDstIp(dst_ipv6)

            if (portChanged)
                setDstPort(dst_port)
        }

        // eslint-disable-next-line
    }, [allowConfigureDstIP])

    const submitConfirm = async () => {
        if (saving || success || error) return
        submit()
    }

    const submit = async () => {
        if (params.id === 'add')
            await create()
        else
            await save()

        await backend.backendApply()
    }

    const goBackToList = () => {
        history.push(`/ipv6/ipv6-ip-port-filter`)
    }

    const newIpPortFilterObj = () => {
        return {
            src_ipv6: { ...srcIp },
            dst_ipv6: { ...dstIp },
            source_port: { ...srcPort },
            destination_port: { ...dstPort },
            ...generalSettings
        }
    }

    const create = async () => {

        if (saving) return

        setSaving(true)

        let ipPorfilter = newIpPortFilterObj()

        let status = await createIpv6IpPortFilter(backend, ipPorfilter, setErrorMsg)

        if (status === false) {
            setSaving(false)
            setSuccess(false)
            setError(true)
            return
        }

        await waitReturn()
    }

    const save = async () => {

        if (saving) return

        setSaving(true)

        let ipPorfilter = newIpPortFilterObj()

        let status = await saveIpv6IpPortFilter(backend, ipPorfilter, setErrorMsg)

        if (status === false) {
            setSaving(false)
            setSuccess(false)
            setError(true)
            return
        }

        await waitReturn()
    }

    const waitReturn = async () => {

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        setSaving(false)

        if (error === false)
            goBackToList()
    }

    const dismissModals = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'Please insert a valid IPv6 address')
            return t('ipv6.ip_port_filtering.error.INVALID_ADDRESS')
        if (errorMsg === 'you need to choose a protocol when use ports')
            return t('advanced.qos_traffic_control.error.SELECT_PROTOCOL')
        if (errorMsg === 'port_from greater than port_to')
            return t('ipv6.ip_port_filtering.error.INVALID_PORT_RANGE')
        if (errorMsg === 'Please insert a valid IPv6 dst/src or port')
            return t('ipv6.ip_port_filtering.error.INSERT_DATA')
        if (errorMsg === 'IPv6 IP Port chain is full')
            return t('ipv6.ip_port_filtering.error.TABLE_FULL')
        return errorMsg
    }

    const hasIPv6Requiremnts = () => {
        if (isWizard || system === true) {
            return true
        }

        if (system) {
            if (system.ipv6) {
                if (system.ipv6.enabled) {
                    return true
                }
            }
        }

        return false
    }

    function hasIpv6Interfaces() {
        if (!dataFetched)
            return true

        for (var i = 0; i < wanRes?.length; i++) {
            const element = wanRes[i];

            if (element.protocol !== 0)
                return true
        }

        return false
    }

    const missingConfiguration = () => {
        return !allowConfigureSrcIP && !allowConfigureDstIP
    }

    const updateInterface = (value) => {
        setGeneralSettings((generalSettings) => { return { ...generalSettings, interface_id: value } })
    }

    const updateDirection = (value) => {
        setGeneralSettings((generalSettings) => { return { ...generalSettings, direction: Number(value) } })
    }

    const updateProtocol = (value) => {
        let newProtocol = isNaN(Number(value)) ? value : Number(value)

        setGeneralSettings((generalSettings) => { return { ...generalSettings, protocol: newProtocol } })

        if (newProtocol === ProtocolEnumeration.IPV6_FILTER_ICMPV6) {
            setSrcPort((srcPort) => { return { ...srcPort, from: "", to: "" } })
            setDstPort((dstPort) => { return { ...dstPort, from: "", to: "" } })
        }
    }

    const updateRuleAction = (value) => {
        setGeneralSettings((generalSettings) => { return { ...generalSettings, rule_action: Number(value) } })
    }

    const updateSrcIpStart = (value) => {
        setSrcIp((srcIp) => { return { ...srcIp, start: value } })
    }

    const updateSrcIpEnd = (value) => {
        setSrcIp((srcIp) => { return { ...srcIp, end: value } })
    }

    const updateSrcIpPrefixLength = (value) => {
        setSrcIp((srcIp) => { return { ...srcIp, prefix_length: isNaN(Number(value)) || !value ? value : Number(value) } })
    }

    const updateSrcPortFrom = (value) => {
        setSrcPort((srcPort) => { return { ...srcPort, from: isNaN(Number(value)) || !value ? value : Number(value) } })
    }

    const updateSrcPortTo = (value) => {
        setSrcPort((srcPort) => { return { ...srcPort, to: isNaN(Number(value)) || !value ? value : Number(value) } })
    }

    const updateDstIpStart = (value) => {
        setDstIp((dstIp) => { return { ...dstIp, start: value } })
    }

    const updateDstIpEnd = (value) => {
        setDstIp((dstIp) => { return { ...dstIp, end: value } })
    }

    const updateDstIpPrefixLength = (value) => {
        setDstIp((dstIp) => { return { ...dstIp, prefix_length: isNaN(Number(value)) || !value ? value : Number(value) } })
    }

    const updateDstPortFrom = (value) => {
        setDstPort((dstPort) => { return { ...dstPort, from: isNaN(Number(value)) || !value ? value : Number(value) } })
    }

    const updateDstPortTo = (value) => {
        setDstPort((dstPort) => { return { ...dstPort, to: isNaN(Number(value)) || !value ? value : Number(value) } })
    }

    const addIpv6IpPortFilterForm = (formContext) => {
        return <React.Fragment>
            <div className='subtitle'> {<Translator path="ipv6.ip_port_filtering.title.INSERT_NEW_FILTER"></Translator>}</div>

            <GeneralSettings
                idInterface='ip6-port-filtering-interface'
                idDirection='ip6-port-filtering-direction'
                idProtocol='ip6-port-filtering-protocol'
                idRuleAction='ip6-port-filtering-rule-action'
                settings={generalSettings}
                wanResources={wanRes}
                handleInterface={updateInterface}
                handleProtocol={updateProtocol}
                handleDirection={updateDirection}
                handleRuleAction={updateRuleAction}
            />

            <IpSettings
                title={t('ipv6.ip_port_filtering.title.IP_SRC_CONFIG')}
                appendID='src'
                enableConfigMessage={t('ipv6.ip_port_filtering.label.IPV6_ENABLE_SRC_CONFIG')}
                ipv6Info={srcIp}
                portInfo={srcPort}
                protocol={generalSettings.protocol}
                allowConfigure={allowConfigureSrcIP}
                setAllowConfigure={setAllowConfigureSrcIP}
                handleIpStart={updateSrcIpStart}
                handleIpEnd={updateSrcIpEnd}
                handleIpPrefixLength={updateSrcIpPrefixLength}
                handlePortFrom={updateSrcPortFrom}
                handlePortTo={updateSrcPortTo}
            />
            <IpSettings
                title={t('ipv6.ip_port_filtering.title.IP_DST_CONFIG')}
                appendID='dst'
                enableConfigMessage={t('ipv6.ip_port_filtering.label.IPV6_ENABLE_DST_CONFIG')}
                ipv6Info={dstIp}
                portInfo={dstPort}
                protocol={generalSettings.protocol}
                allowConfigure={allowConfigureDstIP}
                setAllowConfigure={setAllowConfigureDstIP}
                handleIpStart={updateDstIpStart}
                handleIpEnd={updateDstIpEnd}
                handleIpPrefixLength={updateDstIpPrefixLength}
                handlePortFrom={updateDstPortFrom}
                handlePortTo={updateDstPortTo}
            />
        </React.Fragment>
    }


    return (
        !(generalSettings || srcIp || dstIp || srcPort || dstPort) ? <div className='loading'></div> :

            <div className='edit-ipv6-ip-port-filter-page'>

                <DefaultStatusModals
                    modalID='ipv6-ip-port-filter'
                    saving={saving}
                    success={success}
                    error={error}
                    errorText={translateErrorMessagesFromBackend(errorMsg)}
                    continueFn={dismissModals}
                    isWarningModal={true}
                    savingText={t('common.label.SAVING')}
                ></DefaultStatusModals>

                {
                    hasIPv6Requiremnts() ? null :
                        <div>
                            <p className='ipv6-ip-port-filter-info'>
                                {t(`ipv6.ip_port_filtering.message.IPV6_DISABLED`)}
                                <br />
                                {t(`common.message.warning.IPV6_ENABLE`)}
                                <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                            </p>
                        </div>
                }

                <Button
                    id='ipv6-ip-port-filter-return'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={e => goBackToList()}
                ></Button>

                {
                    hasIpv6Interfaces() ? null :
                        <div>
                            <p className='ipv6-ip-port-filter-info '>
                                {t(`ipv6.ip_port_filtering.error.WAN_WARNING`)}
                                <br />
                                {t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN`)}
                                <a href='#/network/wan'>{t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN_PAG`)}</a>
                                {t(`ipv6.dhcp.message.error.RELAY_MODE_CONFIG_WAN_END`)}.
                                <br />
                            </p>
                        </div>
                }

                <Form
                    onSubmit={submitConfirm}
                    submitText={<Translator path="common.label.SAVE"></Translator>}
                    buttonId='button-save'
                    disableButton={!hasIPv6Requiremnts() || !hasIpv6Interfaces() || missingConfiguration()}
                >

                    <FormContext.Consumer>
                        {value => addIpv6IpPortFilterForm(value)}
                    </FormContext.Consumer>

                </Form>

            </div>
    )
}
import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import TabsMobile from '../../common/components/tabs/tabs-mobile';
import FirewallState from './firewall-context'
import FirewallIpPortFiltering from './firewall-ip-port-filtering/firewall-ip-port-filtering'
import FirewallDomainBlocking from './firewall-domain-blocking/firewall-domain-blocking'
import FirewallMacFiltering from './firewall-mac-filtering/firewall-mac-filtering'
import FirewallUrlBlocking from './firewall-url-blocking/firewall-url-blocking'
import FirewallPortForward from './firewall-port-forward/firewall-port-forward'
import FirewallAlg from './firewall-alg/firewall-alg'
import { useTranslation } from 'react-i18next'
import FirewallDmz from './firewall-dmz/firewall-dmz'
import FirewallWlanAccessControl from './wlan-access-control/firewall-wlan-access-control'
import { GlobalsContext } from '../../globals-context'
/*IMPORTS_TAG*/

export default function FirewallPage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    let globals = useContext(GlobalsContext)

    const { t, i18n } = useTranslation()

    const tabs = [
        { label: t('firewall.alg.title.FIREWALL_ALG'), route: '/firewall/alg' },
        { label: t('firewall.url_blocking.title.URL_BLOCKING'), route: '/firewall/url_blocking' },
        { label: t('firewall.mac_filtering.title.MAC_FILTERING'), route: '/firewall/mac_filtering' },
        { label: t('firewall.ip_port_filtering.title.IP_PORT_FILTERING'), route: '/firewall/ip_port_filtering' },
        { label: t('firewall.domain_blocking.title.DOMAIN_BLOCKING'), route: '/firewall/domain_blocking' },
        { label: t('firewall.port_forward.title.PORT_MAPPING'), route: '/firewall/port_forwarding' },
        { label: t('firewall.dmz.title.FIREWALL_DMZ'), route: '/firewall/dmz' },
        { label: t('menu.FIREWALL_WLAN_ACCESS_CONTROL'), route: '/firewall/wlan-access-control' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.FIREWALL'))
        header.backRoute.set('/firewall/alg')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('firewall') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    let filteredTabs = tabs;
    if (globals.isLimitedUserFlag()) {
        filteredTabs = tabs.filter(tab => tab.route === '/firewall/url_blocking' || tab.route === '/firewall/domain_blocking' || tab.route === '/firewall/dmz');
    }

    return (
        <FirewallState>

            <TabsMobile
                data={filteredTabs}
                route={history.location.pathname}
                activateFn={changeRoute}
            ></TabsMobile>

            <Switch>
                <Route path='/firewall/url_blocking' render={(props) => {
                    return <FirewallUrlBlocking {...props} ></FirewallUrlBlocking>
                }}></Route>

                <Route path='/firewall/ip_port_filtering' render={(props) => {
                    return <FirewallIpPortFiltering {...props} ></FirewallIpPortFiltering>
                }}></Route>

                <Route path='/firewall/domain_blocking' render={(props) => {
                    return <FirewallDomainBlocking {...props} ></FirewallDomainBlocking>
                }}></Route>

                <Route path='/firewall/mac_filtering' render={(props) => {
                    return <FirewallMacFiltering {...props} ></FirewallMacFiltering>
                }}></Route>

                <Route path='/firewall/port_forwarding' render={(props) => {
                    return <FirewallPortForward {...props} ></FirewallPortForward>
                }}></Route>

                <Route path='/firewall/alg' render={(props) => {
                    return <FirewallAlg {...props} ></FirewallAlg>
                }}></Route>

                <Route path='/firewall/dmz' render={(props) => {
                    return <FirewallDmz {...props} ></FirewallDmz>
                }}></Route>

                <Route path='/firewall/wlan-access-control' render={(props) => {
                    return <FirewallWlanAccessControl {...props} ></FirewallWlanAccessControl>
                }}></Route>
                {/*ROUTES_TAG*/}
            </Switch>
        </FirewallState>
    )
}

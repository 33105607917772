import { backendStatus } from  '../../backend/backend'

export async function fetchDevice(backend, setDevice) {

    let result = await backend.retrieveFresh('device')
    if(result.status === backendStatus.SUCCESS){
        setDevice(result.content[0])
        return result.content[0]
    }

    return null
}

export async function updateDevice(backend, device, setErrorMessage) {
    try {
        let result = await backend.update(`device/0`, device)
        
        if(result.status === backendStatus.SUCCESS) {
            return true
        } else {
            setErrorMessage(result.content?.body)
            return false
        }
    }catch(error){
        console.error('Error updating device:', error)
        return false
    }
}
import { backendStatus } from '../../backend/backend'
import { AdminUserName } from '../common/constants'

const blankUser = {
    id: AdminUserName,
    username: AdminUserName,
    password: '',
}

export async function fetchUser(backend, setUser) {
    let result = await backend.retrieveFresh('user')
    if(result.status === backendStatus.SUCCESS){
        if (setUser) {
            setUser(result.content[0])
            return true
        }
        return result.content[0]
    }

    return false
}

export async function fetchBlankUser(setUser) {
    setUser({...blankUser})
    return true
}

export async function updateUser(backend, user, setErrorMessage) {

    try {
        let result = await backend.update(`user/${user.id}`, user)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS
    }catch(error){
        return false
    }
}
import React, { useState, useEffect, useContext } from 'react'
import Button from '../../../../components/button/button';

import UpoadIcon from '../../../../components/icons/upload';
import Modal, { ModalTypes } from '../../../../components/modal/modal';
import Checkbox from '../../../../components/checkbox/checkbox';
import Form from '../../../../components/form/form';

import './backup-restore.css'
import { BackendContext } from '../../../../backend/backend';
import { fetchDevice } from '../../../resources/device';
import { useHistory } from 'react-router-dom';
import waitRouter from '../../../utils/wait-router';
import { GlobalsContext } from '../../../globals-context';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { SessionCheckContext } from '../../../session-check-context';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import { fetchFactoryDefault, updateFactoryDefault, MAX_PRESSED_TIME, MIN_PRESSED_TIME} from '../../../resources/factory-default';
import Input from '../../../../components/input/input';
import extraValidators from '../../../common/validators';
import { showRebootModal } from '../../../utils/reboot';
import { fetchCustomDefaultConfig, updateCustomDefaultConfig } from '../../../resources/custom-default-config';

const UPDATE_WAIT_TIME = 60000

const RestoreModal = ({ open }) => {

    const updatingContent = <React.Fragment>
        <div>
            <Translator path="system.backup_restore.warning.RESTORE_ON_GOING"></Translator>
            <br></br>

            <strong>
                <Translator path="system.backup_restore.warning.IMPORTANT"></Translator>:{' '}
            </strong>
            <Translator path="system.backup_restore.warning.DO_NOT_SHUTDOWN_THE_DEVICE"></Translator>
        </div>
    </React.Fragment>

    return <Modal show={open}
        title={<Translator path="system.backup_restore.info.UPDATING"></Translator>}
        content={updatingContent}
        type={ModalTypes.LOADING}
    ></Modal>
}

const BackupModal = ({ open }) => {

    const updatingContent = <React.Fragment>
        <div>
            <Translator path="system.backup_restore.info.PLEASE_WAIT"></Translator>
        </div>
    </React.Fragment>

    return <Modal show={open}
        title={<Translator path="system.backup_restore.info.UPDATING"></Translator>}
        content={updatingContent}
        type={ModalTypes.LOADING}
    ></Modal>
}

const RestoreErrorModal = (props) => {

    const errorContent = <React.Fragment>
        <div>
            <Translator path="system.backup_restore.error.COULD_NOT_RESTORE"></Translator>
            <br></br>
            <Translator path="system.backup_restore.error.TRY_AGAIN_OR_REACH_SUPPORT_TEAM"></Translator>
        </div>
    </React.Fragment>

    return errorContent
}

const BackupErrorModal = (props) => {

    const errorContent = <React.Fragment>
        <div>
            <Translator path="system.backup_restore.error.COULD_NOT_CREATE_BACKUP_FILE"></Translator>
            <br></br>
            <Translator path="system.backup_restore.error.TRY_AGAIN_OR_REACH_SUPPORT_TEAM"></Translator>
        </div>
    </React.Fragment>

    return errorContent
}

const successRestoreModalContent = () => {

    return <React.Fragment>
        <div>
            <Translator path="system.backup_restore.info.RESTORE_BACKUP_SUCCESS"></Translator>
        </div>
    </React.Fragment>
}

const SystemBackupRestore = () => {
    let [saving, setSaving] = useState(false)
    let [confirmFactoryDefault, setConfirmFactoryDefault] = useState(false)
    let [file, setFile] = useState(null)
    let [updating, setUpdating] = useState(false)
    let [downloading, setDownloading] = useState(false)
    let [error, setError] = useState(false)
    let [errorDownload, setErrorDownload] = useState(false)
    let [errorFactoryDefault, setErrorFactoryDefault] = useState(false)
    let [errorCustomDefaultConfig, setErrorCustomDefaultConfig] = useState(false)
    let [success, setSuccess] = useState(false)
    let [successDownload, setSuccessDownload] = useState(false)
    let [rebooting, setRebooting] = useState(false)
    let [rebootError, setRebootError] = useState(false)
    let [rebootSuccess, setRebootSuccess] = useState(false)
    let [device, setDevice] = useState(null)
    let [filedownloadlink, setFiledownloadlink] = useState("");
    let [filename, setFilename] = useState("");
    let [factoryDefault, setFactoryDefault] = useState(null);
    let [successFactoryDefault, setSuccessFactoryDefault] = useState(false)
    let [customDefaultConfig, setCustomDefaultConfig] = useState(false);
    let [errorMessage, setErrorMessage] = useState("")
    const [confirm, setConfirm] = useState(false)

    const { t } = useTranslation()

    const successBackupModalContent = () => {
        return <React.Fragment>
            <div>
                <Translator path="system.backup_restore.info.BACKUP_GENERATION_SUCCESS"></Translator>
            </div>
            <div>
                {filedownloadlink && <a download={filename} href={filedownloadlink}><Translator path="common.label.DOWNLOAD"></Translator></a>}
            </div>
        </React.Fragment>
    }

    const backend = useContext(BackendContext)
    const history = useHistory()
    const globals = useContext(GlobalsContext)
    const sessionCheck = useContext(SessionCheckContext)

    useEffect(() => {

        fetchDevice(backend, setDevice)
        fetchFactoryDefault(backend, setFactoryDefault)
        fetchCustomDefaultConfig(backend, setCustomDefaultConfig)

        // eslint-disable-next-line
    }, [])

    let backup = async (evt) => {
        evt.preventDefault()

        if (downloading) return

        try {
            setDownloading(true)

            let url = backend.getDriverBaseUrl()

            let result = await fetch(`${url}/backup_restore`, {
                headers: {
                    'authorization': 'Bearer ' + sessionStorage.getItem('token'),
                },
                method: 'GET'
            })

            if (result.status === 401) {
                history.push('/login?expired')
            }

            if (result.status !== 200) {
                throw new Error('Error downloading')
            } else {
                let blob = await result.blob();
                var name = `backup_${new Date().toLocaleString('pt-br').replaceAll(' ', '_').replaceAll('/', '_').replaceAll(':', '_').replaceAll(',', '')}.xml`;
                var file = new File([blob], name)
                var myURL = window.URL || window.webkitURL
                var fileURL = myURL.createObjectURL(file);
                setFiledownloadlink(fileURL);
                setFilename(name)
            }

            sessionCheck.setDisabled(false)
            setDownloading(false)
            setSuccessDownload(true)

        } catch (error) {
            setDownloading(false)
            setErrorDownload(true)
        }
    }

    let restore = async (evt) => {
        evt.preventDefault()

        if (updating) return

        try {
            setUpdating(true)

            let url = backend.getDriverBaseUrl()

            let result = await fetch(`${url}/backup_restore`, {
                headers: {
                    'authorization': 'Bearer ' + sessionStorage.getItem('token'),
                },
                method: 'POST',
                body: file
            })

            if (result.status === 401) {
                history.push('/login?expired')
            }

            if (result.status !== 200)
                throw new Error('Error updating')

            await waitRouter(
                backend,
                globals.getItem('username'),
                globals.getItem('password'),
                history,
                UPDATE_WAIT_TIME
            )
            
            setUpdating(false)
            setSuccess(true)

        } catch (error) {
            setUpdating(false)
            setError(true)
        }

    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
        setUpdating(false)
        setSaving(false)
        history.push('/login')
        window.location.reload()
    }

    let dismissDownload = () => {
        setSuccessDownload(false)
        setErrorDownload(false)
        setDownloading(false)
    }

    let dismissFactoryDefault = () => {
        setSuccessFactoryDefault(false)
        setErrorFactoryDefault(false)
        setSaving(false)
    }

    let dismissReboot = () => {
        setRebootSuccess(false)
        setRebootError(false)
        setRebooting(false)
        history.push('/login')
        window.location.reload()
    }

    const validatePressedTime = (value) => {
        if (value < MIN_PRESSED_TIME || value > MAX_PRESSED_TIME){
            return t('system.backup_restore.info.FACTORY_DEFAULT_RANGE')
        }
        return ''
    }

    let saveFactoryDefault = async () => {

        if(saving || error || success ) return

        setSaving(true)

        setFactoryDefault({ ...factoryDefault })
        
        let ok = await updateFactoryDefault(backend, factoryDefault, setErrorMessage)
        if(!ok){
            setSaving(false)
            setErrorFactoryDefault(true)
            return
        }

        setSaving(false)
        setSuccessFactoryDefault(true)
    }

    const confirmFactoryDefaultFn = async () => {
        setConfirmFactoryDefault(true)
    }

    let continueFactoryDefault = (confirmed) => {

        setConfirmFactoryDefault(false)

        if (confirmed) {
            saveFactoryDefault()
        }
    }

    const factoryDefaultForm = () => {
        return <React.Fragment>
            <Input name='factory-default-pressed-time'
                id='pressed_time'
                label={<Translator path="system.backup_restore.title.FACTORY_DEFAULT"></Translator>}
                value={(factoryDefault) ? factoryDefault.pressedTime : ''}
                onChange={(e) => {
                    if (!isNaN(Number(e.target.value))) {
                        factoryDefault.pressedTime = Number(e.target.value)
                        setFactoryDefault({ ...factoryDefault })
                    }  
                }}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    validatePressedTime
                ]}
            ></Input>

            <div>
                <p className='factory-default-info'>
                    <Translator path="system.backup_restore.info.FACTORY_DEFAULT_INFO"></Translator>
                    <br/>
                </p>
            </div>
        </React.Fragment>
    }

    let dimissError = () => {
        setErrorCustomDefaultConfig(false)
        setRebootSuccess(false)
        setRebootError(false)
        setRebooting(false)
    }

    let dismissCustomDefaultConfig = async (res) => {
        setConfirm(false)
        if (res) {
            setRebooting(true)
            let ok = await updateCustomDefaultConfig(backend, { enabled: customDefaultConfig }, setErrorMessage)

            if (!ok)
                setErrorCustomDefaultConfig(true)
            else {
                await waitRouter(
                    backend,
                    globals.getItem('username'),
                    globals.getItem('password'),
                    history,
                    UPDATE_WAIT_TIME
                )

                setRebooting(false)
                setRebootSuccess(true)
            }
        }
    }

    const saveCustomDefaultConfig = () => {
        return <React.Fragment>
            <Checkbox id='cutom-default-config-checkbox'
                name='cutom_default_config'
                label={<Translator path="system.backup_restore.info.CUSTOM_CONFIG_ENABLE"></Translator>}
                value={customDefaultConfig}
                toggleFn={(e) => {
                    customDefaultConfig = !customDefaultConfig
                    setCustomDefaultConfig(customDefaultConfig)
                }}
                validators={[]}
            ></Checkbox>
        </React.Fragment>
    }

    const rebootMessage = <React.Fragment>
        <div><Translator path="system.backup_restore.warning.MUST_REBOOT_TO_APPLY_SETTINGS"></Translator></div>
        <div><Translator path="system.reboot.warning.WANT_TO_REBOOT_NOW"></Translator></div>
    </React.Fragment>

    return !device ? <Loading show={true}></Loading> :
        <div id='backup-restore-page' className='align-center'>

            <div className='subtitle'><Translator path="menu.BACKUP_RESTORE"></Translator></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="system.backup_restore.title.CREATE_BACKUP_FILE"></Translator></div>
                <div className='info-text'>

                    <BackupModal open={downloading}></BackupModal>

                    <DefaultStatusModals
                        success={successDownload}
                        error={errorDownload}
                        successText={successBackupModalContent()}
                        errorText={BackupErrorModal()}
                        continueFn={dismissDownload}>
                    </DefaultStatusModals>

                    <form id='create-upgrade-file' onSubmit={backup}>
                        <Button text={<Translator path="system.backup_restore.info.CREATE_FILE"></Translator>} disabled={downloading} id='file-backup'></Button>
                    </form>
                </div>
            </div>

            <div className='card mt2'>
                <div className='subtitle'><Translator path="system.backup_restore.title.RESTORE_BACKUP"></Translator></div>
                <div className='info-text'>
                    
                    <RestoreModal open={updating}></RestoreModal>

                    <DefaultStatusModals
                        success={success}
                        error={error}
                        successText={successRestoreModalContent()}
                        errorText={RestoreErrorModal()}
                        continueFn={dismiss}>
                    </DefaultStatusModals>

                    <DefaultStatusModals
                        saving={rebooting}
                        success={rebootSuccess}
                        error={rebootError}
                        continueFn={dismissReboot}
                        errorText={<Translator path="common.message.error.CHECK_CONNECTION"></Translator>}
                    ></DefaultStatusModals>

                    <form id='backup-restore-form' onSubmit={restore}>

                        <div className='backup-restore-selector'>

                            <label>
                                <UpoadIcon size='30'></UpoadIcon>
                                <span>{file ? file.name : <Translator path="system.backup_restore.info.SELECT_FILE"></Translator>}</span>
                                <input id='file-upload'
                                    disabled={!device || updating}
                                    type='file'
                                    accept=".xml,.XML"
                                    onChange={(e) => { setFile(e.target.files[0]) }}
                                ></input>
                            </label>
                        </div>
                        {
                            file && !file.name.toLowerCase().endsWith('.xml') &&
                            <span className="itb-input-error-text"><Translator path="system.upgrade.error.INVALID_FILE"></Translator></span>
                        }
                        <Button 
                            text={<Translator path="system.backup_restore.info.RESTORE_BACKUP"></Translator>} 
                            disabled={!file || !file.name.toLowerCase().endsWith('.xml')} 
                            id='file-restore'>
                        </Button>
                    </form>
                </div>
            </div>

            <div className='card mt2'>
                <div className='subtitle'><Translator path="system.backup_restore.title.FACTORY_DEFAULT"></Translator></div>
                <div className='info-text'>
                    <Modal
                        modalID='factory-default-confirm'
                        show={confirmFactoryDefault}
                        content={
                            <div style={{ maxWidth: '420px' }}>
                                <Translator path="system.backup_restore.info.FACTORY_DEFAULT_CONFIRM"></Translator>
                                <div><b>{factoryDefault?.pressedTime}</b></div>
                                <Translator path="system.backup_restore.info.FACTORY_DEFAULT_CONFIRM_SECONDS"></Translator>
                            </div>
                        }
                        type={ModalTypes.CONFIRM_WARNING}
                        onDismissClick={continueFactoryDefault}
                        dismissText={t('common.label.CANCEL')}
                        confirmText={t('common.label.CONFIRM')}
                        error={error}
                        errorText={`${errorMessage ? errorMessage : ''}`}
                    ></Modal>


                    <DefaultStatusModals
                        saving={saving}
                        success={successFactoryDefault}
                        error={errorFactoryDefault}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                        errorText={t('common.message.error.SERVER_ERROR', {error: errorMessage})}
                        continueFn={dismissFactoryDefault}>
                    </DefaultStatusModals> 
                    
                    <Form
                        onSubmit={confirmFactoryDefaultFn}
                        buttonId='button-save-factory-default'
                    >
                        {factoryDefaultForm()}
                    </Form>

                </div>
            </div>
            <React.Fragment>
                <div className="card mt2">
                    <div className='subtitle'> <Translator path="system.backup_restore.title.CUSTOM_CONFIG"></Translator> </div>
                    <div className='info-text'>
                        {showRebootModal(confirm, dismissCustomDefaultConfig, rebootMessage)}

                        <DefaultStatusModals
                            error={errorCustomDefaultConfig}
                            continueFn={() => dimissError()}
                            errorText={`${t('common.message.error.CHECK_CONNECTION')} ${errorMessage ? errorMessage : ''}`}
                        ></DefaultStatusModals>

                        <Form id='cutom-default-config-form'
                            onSubmit={() => setConfirm(true)}
                            buttonId='button-save-cutom-default-config'
                        >
                            {saveCustomDefaultConfig()}
                        </Form>
                    </div>
                    <div>
                        <p className='custom-default-config-info'>
                            <Translator path="system.backup_restore.info.CUSTOM_CONFIG"></Translator>
                            <br />
                        </p>
                    </div>
                </div>
            </React.Fragment>

        </div>
}

export default SystemBackupRestore
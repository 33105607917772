import { backendStatus } from  '../../backend/backend'

export async function fetchServiceTypeList(backend, setServiceTypeList) {
    
    try{
        let result = await backend.retrieveFresh('wan_service_type')
        if(result.status === backendStatus.SUCCESS){
            setServiceTypeList(result.content)
            return result.content
        }
    }catch(error){
        console.error('Error fetching connectionType list')
    }

    return null
}

export async function fetchServiceType(id, backend, setServiceType) {
    let empty = {
        "id": "",
        "service_other_video": false,
        "service_tr069": false,
        "service_internet": true,
        "service_voice": false
    }
    try{
        let result = await backend.retrieveFresh(`wan_service_type/${id}`)
        if(result.status === backendStatus.SUCCESS && result.content && Object.keys(result.content).length !== 0){
            setServiceType(result.content)
            return result.content
        } else {
            setServiceType(empty)
            return empty
        }
    }catch(error){
        console.error('Error fetching connectionType')
    }

    return null
}


export async function saveServiceType(backend, st, apply_now = false) {

    try{

        let result = await backend.update(`wan_service_type/${st.id}`, st, '', apply_now)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Interface:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import './firewall-mac-filtering.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchFirewallMacFiltering, saveFirewallMacFiltering } from '../../../resources/firewall-mac-filtering';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import RemoveIcon from '../../../../components/icons/remove';
import Select from '../../../../components/select/select';
import extraValidators from '../../../common/validators';
import Input from '../../../../components/input/input';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import Button from '../../../../components/button/button';

const MAC_FILTERING_LIST_MAX_SIZE = 20

export default function FirewallMacFiltering({isWizard, setSaved, isFormSegment, formSegment}) {
    const [firewallMacFiltering, setFirewallMacFiltering] = useState(null)
    const [firewallMacFilteringPagination, setFirewallMacFilteringPagination] = useState({pageNumber: 1, pageSize: 5})

    const [newRegister, setNewRegister] = useState({
        source_mac: '',
        destination_mac: '',
        direction: 0,
        rule_action: 0,
    })

    const [pristine, setPristine] = useState(true)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    const [hasChange, setHasChange] = useState(false)

    useEffect(() => {

        fetchFirewallMacFiltering(backend, setFirewallMacFiltering)

        // eslint-disable-next-line
    }, [])

    const getColumnsMac = () => {

        return [
            {header: t('common.label.SOURCE'), align: 'center', size: '175px'},
            {header: t('common.label.DESTINY'), align: 'center', size: '175px'},
            {header: t('common.label.DIRECTION_ABREVIATION'), align: 'center'},
            {header: t('common.label.RULE'), align: 'center'},
            {header: t('common.label.ACTIONS'), align: 'center'},
        ]
    }

    const getMac = () => {
        let begining = (firewallMacFilteringPagination.pageNumber - 1) * (firewallMacFilteringPagination.pageSize)
        let end = begining + firewallMacFilteringPagination.pageSize
        if (end > firewallMacFiltering.entry.length) {
            end = firewallMacFiltering.entry.length
        }
        
        let firewallMacFilteringLines = [];
        for(let i = begining;  i < end; i++) {
            if(firewallMacFiltering.entry[i]){ 
                firewallMacFilteringLines.push(
                [
                    firewallMacFiltering.entry[i].source_mac,
                    firewallMacFiltering.entry[i].destination_mac,
                    firewallMacFiltering.entry[i].direction === 1 ? 'in' : 'out',
                    firewallMacFiltering.entry[i].rule_action === 1 ? t('common.label.ALLOW') : t('common.label.DENY'),
                    <span className="clickable" onClick={e => removeItemMac(firewallMacFiltering.entry[i])}><RemoveIcon></RemoveIcon></span>
                ]);
        
            }
        }
        return firewallMacFilteringLines;
    }

    const removeItemMac = (macID) => {
        firewallMacFiltering.entry = firewallMacFiltering.entry.filter(it => it !== macID)
        setFirewallMacFiltering({...firewallMacFiltering})
        setPristine(false)
    }

    const addNewRegister = () => {
        newRegister.source_mac = newRegister.source_mac.toLocaleUpperCase()
        newRegister.destination_mac = newRegister.destination_mac.toLocaleUpperCase()

        if(newRegister.source_mac === '' && newRegister.destination_mac === '') {
            setErrorMessage(t('firewall.mac_filtering.warning.LACK_OF_MAC'))
            setError(true)
            return
        }

        if(newRegister.source_mac === newRegister.destination_mac) {
            setErrorMessage(t('firewall.mac_filtering.warning.SAME_SRC_DST_MAC'))
            setError(true)
            return
        }

        let alreadyExists = -1

        firewallMacFiltering.entry.forEach( (flt, index) => {            
            if(
                newRegister.source_mac === flt.source_mac &&
                newRegister.destination_mac === flt.destination_mac &&
                newRegister.direction === flt.direction
            ) {
                alreadyExists = index
            }
        })

        if(alreadyExists !== -1) {
            setError(true)
            setErrorMessage(t('firewall.ip_port_filtering.message.error.RULE_CONFLICT', {rule: alreadyExists + 1}))
            return
        }

        firewallMacFiltering.entry.push(newRegister)
        setFirewallMacFiltering({...firewallMacFiltering})
        setNewRegister({
            source_mac: '',
            destination_mac: '',
            direction: 0,
            rule_action: 0,
        })
        setPristine(false)
        setHasChange(false)
    }


    const firewallMacFilteringForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='card mt2'>
                <div className='subtitle'> <Translator path="firewall.mac_filtering.title.DEFAULT_ACTIONS"></Translator></div>
                <div className="row">
                    <Select
                        id='firewall-mac-filtering-incoming-default-act'
                        name='incoming_default_act'
                        label={<Translator path="firewall.mac_filtering.label.DEFAULT_INCOMING_ACTION"></Translator>}
                        options={[{value: 1, text: t('common.label.ALLOW')}, {value: 0, text: t('common.label.DENY')}]}
                        value={firewallMacFiltering.incoming_default_act}
                        onChange={(e) => {
                            firewallMacFiltering.incoming_default_act = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                            setFirewallMacFiltering({...firewallMacFiltering})
                        }}
                    ></Select>

                    <Select
                        id='firewall-mac-filtering-outgoing-default-act'
                        name='outgoing_default_act'
                        label={<Translator path="firewall.mac_filtering.label.DEFAULT_OUTGOING_ACTION"></Translator>}
                        options={[{value: 1, text: t('common.label.ALLOW')}, {value: 0, text: t('common.label.DENY')}]}
                        value={firewallMacFiltering.outgoing_default_act}
                        onChange={(e) => {
                            firewallMacFiltering.outgoing_default_act = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                            setFirewallMacFiltering({...firewallMacFiltering})
                        }}
                    ></Select>
                </div>
            </div>
            <div className='card mt2'>   
                <div className='subtitle'> <Translator path="firewall.mac_filtering.title.ADD_NEW_FILTER"></Translator> </div>
                    <div>
                        <div className="row">
                            <Input id='firewall-mac-filtering-source-mac'
                                name='source_mac'
                                type="text"
                                label={<Translator path="firewall.mac_filtering.label.SOURCE_MAC"></Translator>}
                                value={newRegister.source_mac}
                                onChange={(e) => {
                                    newRegister.source_mac = e.target.value
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                                validators={[extraValidators.nonASCII, extraValidators.validateMAC]}
                                errorMessage={hasChange && newRegister.source_mac === '' && newRegister.destination_mac === '' ? t('firewall.mac_filtering.warning.LACK_OF_MAC') : ''}
                            ></Input>
                            <Input id='firewall-mac-filtering-destination-mac'
                                name='destination_mac'
                                type="text"
                                label={<Translator path="firewall.mac_filtering.label.DESTINY_MAC"></Translator>}
                                value={newRegister.destination_mac}
                                onChange={(e) => {
                                    newRegister.destination_mac = e.target.value
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                                validators={[extraValidators.nonASCII, extraValidators.validateMAC]}
                                errorMessage={hasChange && newRegister.source_mac === '' && newRegister.destination_mac === '' ? t('firewall.mac_filtering.warning.LACK_OF_MAC') : ''}
                            ></Input>           
                        </div>
                        <div className="row">
                            <Select
                                id='firewall-mac-filtering-direction'
                                name='direction'
                                label={<Translator path="common.label.DIRECTION"></Translator>}
                                options={[{value: 1, text: 'Incoming'}, {value: 0, text: 'Outgoing'}]}
                                value={newRegister.direction}
                                onChange={(e) => {
                                    newRegister.direction = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                            ></Select>
                            <Select
                                id='firewall-mac-filtering-rule-action'
                                name='rule_action'
                                label={<Translator path="firewall.mac_filtering.label.FILTER_TYPE"></Translator>}
                                options={[{value: 1, text: t('common.label.ALLOW')}, {value: 0, text: t('common.label.DENY')}]}
                                value={newRegister.rule_action}
                                onChange={(e) => {
                                    
                                    newRegister.rule_action = isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                    setNewRegister({...newRegister})
                                    setHasChange(true)
                                }}
                            ></Select>
                        </div>
                    </div>

                    {firewallMacFiltering.entry.length >= MAC_FILTERING_LIST_MAX_SIZE && 
                        <strong><Translator path="firewall.mac_filtering.title.FILTER_LIST_MAX"></Translator></strong>
                    }     

                    <Button
                        type='button'
                        id='btn-add-mac-filter'
                        text={<Translator path="common.label.ADD"></Translator>}
                        onClick={addNewRegister}
                        disabled={newRegister.source_mac === '' || newRegister.destination_mac === '' || firewallMacFiltering.entry.length >= MAC_FILTERING_LIST_MAX_SIZE}
                    ></Button>
            </div>

            <div id='firewall-mac-filtering-table'>
                <div className='subtitle'> <Translator path="firewall.mac_filtering.title.FILTER_LIST"></Translator> </div>
                    {firewallMacFiltering.entry.length > 0 ?
                    <div>
                        <div className="scroll-list">
                            <List
                                lines={getMac()}
                                columns={getColumnsMac()}
                            ></List>
                        </div>
                        <Pager 
                            pageNumber={firewallMacFilteringPagination.pageNumber}
                            totalElements={firewallMacFiltering.entry.length}
                            pageSize={firewallMacFilteringPagination.pageSize}
                            pageChangeFn={changePage}>
                        </Pager>
                    </div>
                    :
                    <div id='firewall-mac-filtering-no-filters'>
                        <div className='info-card noelements-card'>
                            <Translator path="firewall.mac_filtering.info.NO_FILTERS"/>
                        </div>
                    </div>  
                    }
            </div>
        </React.Fragment>
    }


    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveFirewallMacFiltering(backend, firewallMacFiltering)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    const changePage = page => {
        setFirewallMacFilteringPagination({...firewallMacFilteringPagination, pageNumber: page})
    }

    return (
        !firewallMacFiltering ? <Loading show={true}></Loading> :

        <div id='firewall-mac-filtering-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="firewall.mac_filtering.title.MAC_FILTER_SETTINGS"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                errorText={errorMessage}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            {!isFormSegment ? <Form
                onSubmit={submit}
                buttonId='button-save'
                isPristine={pristine}
                key={pristine}
            >              
                {firewallMacFilteringForm()}
            </Form> : 

            <FormSegment
                title={formSegment.title} 
                active={formSegment.active} 
                nextLabel={formSegment.nextLabel} 
                nextClicked={submit} 
            >
                {firewallMacFilteringForm()}
            </FormSegment>}

        </div>
    )
}

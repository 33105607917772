import React, { useState, useEffect, useRef } from 'react'
import MenuID from '../../utils/menu'
import { keywords, Trie } from '../../utils/search-utils/search-utils'
import { useTranslation } from 'react-i18next'

export const SearchContext = React.createContext({})

export default function SearchState({ children }) {

    const [displayMenus, setDisplayMenus] = useState(new Set())
    const [defaultMenuState, setDefaultMenuState] = useState(null)

    const trie = useRef(new Trie())
    const { i18n } = useTranslation()
    
    useEffect(() => {
        
        trie.current.clean()

        let defaultMenus = () => {
            let idsByMenu = MenuID.submenuIDs()
            let idsList = Object.keys(idsByMenu).reduce((result, menu) => {
                return result.concat(idsByMenu[menu])
            }, [])
            idsList.push(...MenuID.mainMenuIDs())
            return new Set(idsList)
        }

        setDefaultMenuState(defaultMenus)
        populateTrie(keywords)

        // eslint-disable-next-line
    }, [i18n.language])

    useEffect(() => {

        defaultMenuState && setDisplayMenus(defaultMenuState)

    }, [defaultMenuState])

    const populateTrie = (keywords) => {
        if (keywords === undefined)
            return

        let submenus = Object.keys(keywords)
        submenus.forEach(submenu => {
            let subKeywords = keywords[submenu]
            subKeywords[i18n.language].forEach(keyword => {
                trie.current.insert(keyword, submenu)
            })
            subKeywords['common'].forEach(keyword => {
                trie.current.insert(keyword, submenu)
            })
        })
    }

    const displayAllMenus = () => {
        setDisplayMenus(defaultMenuState)
    }

    const query = (newQuery) => {
        let newMenuState = null

        newQuery.split(" ").forEach((e, i, arr) => {
            let words_info = []

            if (e === '') return

            if (arr.length === i + 1) {
                words_info = trie.current.findCandidates(e)
            }
            else {
                words_info = trie.current.wordInfo(e)
            }

            if (newMenuState === null) {
                newMenuState = new Set(words_info)
            }
            else {
                newMenuState = new Set(words_info.filter(e => newMenuState.has(e)))
            }
        });

        [...newMenuState]
            .map(word_info => MenuID.parentID(word_info))
            .forEach(parentID => (parentID && newMenuState.add(parentID)))

        setDisplayMenus(newMenuState)
    }

    return <SearchContext.Provider value={{
        displayMenus,
        displayAllMenus,
        query
    }}>
        {children}
    </SearchContext.Provider>
}

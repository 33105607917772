import { backendStatus } from '../../backend/backend'

export async function fetchIPv6ProxyMLD(backend, setIPv6ProxyMLD) {
    let result = await backend.retrieveFresh('ipv6_proxy_mld')

    if(result.status === backendStatus.SUCCESS){

        let ipv6ProxyMLDList = result.content

        let ipv6ProxyMLD = ipv6ProxyMLDList.length !== 0 ? ipv6ProxyMLDList[0] : {
            id: "1",
            query_interval: 250,
            query_response_interval: 4000,
            response_interval_of_last_group_member: 9,
            robust_count: 8,
            isCreate: true
        }

        setIPv6ProxyMLD(ipv6ProxyMLD)
    }
}

export async function saveIPv6ProxyMLD(backend, ipv6ProxyMLD) {

    try{

        let result = null;

        if(ipv6ProxyMLD.isCreate)
            result = await backend.create('ipv6_proxy_mld', ipv6ProxyMLD)
        else
            result = await backend.update(`ipv6_proxy_mld/${ipv6ProxyMLD.id}`, ipv6ProxyMLD)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving IPv6 Proxy MLD:', error)

        return false
    }

}
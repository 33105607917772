import React, { useState, useEffect, useContext, useRef } from 'react'
import './mesh.css'

import { BackendContext } from '../../../../backend/backend'
import { GlobalsContext } from '../../../globals-context'
import { useHistory } from 'react-router-dom'
import { MainHeaderContext } from '../../../../components/main-header/main-header-state'
import w5fMeshImg from '../../../../images/W5_1200F_mesh.svg'
import ConnectedHouse from '../../../../images/connected-house'
import ConnectedHouseTwibi from '../../../../images/connected-house-twibi'
import WifiIcon from '../../../../components/icons/wifi-icon'
import Button from '../../../../components/button/button'
import Input from '../../../../components/input/input'
import NumberedList from '../../../../components/numbered-list/numbered-list'
import createAction from '../../../resources/action'
import { fetchInterfaceLan } from '../../../resources/lan'
import { MeshContext } from './mesh-context'
import network from '../../../../components/form/validators/network'
import macutils from '../../../utils/macutils'
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator'

const WPS_PBC_ACTION = 4
const EASYMESH_SYNCING_STOPPED = 0
const EASYMESH_SYNCING_RUNNING = 1
const EASYMESH_SYNCING_CONNECTED = 2

const STEP_POSITIONING = 0
const STEP_CONFIGURE_ALIAS = 1
const STEP_SEARCHING_DEVICES = 2
const STEP_SUCCESS = 3
const STEP_FAILED = 4

const RESENT_LIMIT = 2
const TIME_LIMIT = 120 * RESENT_LIMIT // 2 min * 2 attempts
const TICK_INTERVAL = 1000
const MINIMUM_TIME_TO_SEARCH = 10
const UPDATE_SYNC_PERIOD = 5
const CONFIGURING_AGENT_AFTER_SYNC = 60

const searchingNewRouterContent = (getMeshTranslation) => {
    return <React.Fragment>
        <div className='mesh-onboarding-title center'>
            {getMeshTranslation('message.info', 'SEARCHING_ROUTER')}
        </div>
        <div className='mesh-onboarding-text center'>
            {getMeshTranslation('message.info', 'CHECK_IF_ROUTER_IS_ON')}
        </div>
    </React.Fragment>
}

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    });
  
    useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
  }

export default function MeshOnboardingPage() {
    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const header = useContext(MainHeaderContext)
    const mesh = useContext(MeshContext)
    const history = useHistory()

    const [lan, setLan] = useState(null)
    const [deviceID, setDeviceID] = useState(null)
    const [location, setLocation] = useState('')
    const [initNeighbors, setInitNeighbors] = useState(null)
    const [currentStep, setCurrentStep] = useState(STEP_POSITIONING)
    const [finishingSync,setFinishingSync] = useState(false)

    const [count, setCount] = useState(0);

    useInterval(() => {

        if (count % UPDATE_SYNC_PERIOD === 0) {
            mesh.retrieveEasyMesh(deviceID)
        }

        if (mesh.easyMesh.syncing === EASYMESH_SYNCING_CONNECTED) {
            if (!finishingSync && count > MINIMUM_TIME_TO_SEARCH) {
                setFinishingSync(true)
                mesh.setMeshScan(false)
                setCount(0)
            } else if (finishingSync && count >= CONFIGURING_AGENT_AFTER_SYNC) {
                setCurrentStep(STEP_SUCCESS)
                setFinishingSync(false)
            } else {
                setCount(count + 1);
            }
        } else if (mesh.easyMesh.syncing === EASYMESH_SYNCING_STOPPED) {
            if (count >= TIME_LIMIT) {
                setCurrentStep(STEP_FAILED)
                setFinishingSync(false)
                mesh.setMeshScan(false)
                setCount(0)
            } else {
                setCount(count + 1);
            }
        } else if (mesh.easyMesh.syncing === EASYMESH_SYNCING_RUNNING) {
            if (count >= TIME_LIMIT && finishingSync === false) {
                setFinishingSync(true)
                mesh.setMeshScan(false)
            }
            setCount(count + 1);
        }
        
    }, (mesh.meshScan || finishingSync) ? TICK_INTERVAL : null)

    const { t } = useTranslation();
    useEffect(() => {
        header.title.set(getMeshTranslation('title', 'NEW_MESH_ROUTER'))

        let mounted = true
        mesh.setMeshScan(false)

        fetchInterfaceLan(backend, (lan) => {
            mounted && setLan(lan)
        })

        async function fetchInitialData() {
            const deviceID = await globals.getDeviceID()
            mounted && setDeviceID(deviceID)
        }

        !deviceID && fetchInitialData()

        return () => {
            mounted = false
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!deviceID) return
        mesh.retrieveEasyMesh(deviceID)

        // eslint-disable-next-line
    }, [deviceID])

    useEffect(() => {
        if (!mesh.easyMesh) return

        if (!initNeighbors) {
            setInitNeighbors(mesh.meshNodes)
        }

        if (currentStep === STEP_SEARCHING_DEVICES && mesh.easyMesh.syncing !== EASYMESH_SYNCING_RUNNING)
            startWps()

        // eslint-disable-next-line
    }, [currentStep])

    useEffect(() => {
        if (!lan || !mesh.easyMesh || currentStep !== STEP_SEARCHING_DEVICES) return

        const diffNodes = mesh.meshNodes.filter((node) => !initNeighbors.some((n) => node.mac === n.mac))
        console.log(diffNodes)
        if(Array.isArray(diffNodes) && diffNodes.length && !network.sameNetwork(diffNodes[0].ip, {ip: lan.ip4, network: lan.netmask})) {
            for (let i = 0; i < diffNodes.length; i++) {
                if (diffNodes[i].ip !== lan.ip4) {
                    mesh.setMeshScan(false)
                    setCount(0);
                    changeDeviceAlias(diffNodes[0])
                    setCurrentStep(STEP_SUCCESS)
                    
                    break;
                } 
            }
        }

        // eslint-disable-next-line
    }, [mesh.easyMesh])

    const changeDeviceAlias = async (newNode) => {
        if (location !== '') {
            await mesh.updateDevice(macutils.getDeviceIdFromMac(newNode.mac), {alias: location, show_wizard: false})
       }
    }

    const startWps = async () => {
        let result = await createAction(backend, {actionID: WPS_PBC_ACTION})

        if (!result) return false

        return true
    }

    const searchDevice = () => {
        setCurrentStep(STEP_SEARCHING_DEVICES)
        mesh.setMeshScan(true)
        setCount(0);
    }

    const restartOnboarding = () => {
        setCurrentStep(STEP_POSITIONING)
        setLocation('')
    }

    const StepPositioningRouter = () => {
        return <div className='mesh-onboarding-content'>
            <img alt='roteadores' src={w5fMeshImg} width='200'></img>
            <NumberedList
                title={<p>{getMeshTranslation('message.info', 'ROUTER_EXAMPLES')}<br/> W5-1200G, W5-1200F ou GF1200</p>}
                content={
                    [
                        getMeshTranslation('message.info', 'INSTRUCTION_1'),
                        getMeshTranslation('message.info', 'INSTRUCTION_2'),
                        getMeshTranslation('message.info', 'INSTRUCTION_3')
                    ]}
            ></NumberedList>

            <div className='mesh-onboarding-first-buttons-container'>
                <Button id='mesh-back-to-settings-btn'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={() => history.push('/network/mesh/settings')}
                ></Button>

                <Button id='mesh-onboarding-btn'
                    text={getMeshTranslation('label', 'START')}
                    onClick={ () => { 
                        setCurrentStep(STEP_CONFIGURE_ALIAS)
                    }}
                ></Button>
            </div>
        </div>
    }

    const StepConfigureAlias = () => {
        return <div className='mesh-onboarding-content'>
            <ConnectedHouse size='200' ></ConnectedHouse>
            <div className='mesh-onboarding-title'>
                
                {getMeshTranslation('label', 'LOCALIZATION')}
            </div>

            <div className='mesh-onboarding-text'>
                {getMeshTranslation('message.info', 'ROUTER_POSITION')}
            </div>

            <Input
                id='mesh-onboarding-input'
                placeholder={getMeshTranslation('label', 'OFFICE')}
                value={location}
                onChange={e => setLocation(e.target.value)}
            ></Input>

            <Button id='mesh-onboarding-btn'
                text={getMeshTranslation('label', 'CONTINUE')}
                onClick={ () => {
                        searchDevice()
                    }
                }
            ></Button>
        </div>
    }

    const StepSearchingDevices = (getMeshTranslation) => {
        return <div className='mesh-onboarding-content'>
            <div className='mesh-onboarding-wifi-icon' >
                <WifiIcon
                    size='56'
                    color='green'
                ></WifiIcon>
            </div>
            {searchingNewRouterContent(getMeshTranslation)}

        </div>
    }

    const StepSuccess = () => {
        return <div className='mesh-onboarding-content'>
            <ConnectedHouseTwibi size='200' ></ConnectedHouseTwibi>
            <div className='mesh-onboarding-title center'>
                <Translator path='network.mesh.message.info.SUCCESS'></Translator>
            </div>

            <div className='mesh-onboarding-text center'>
                {getMeshTranslation('message.info', 'ENJOY_NEW_COVERED_AREA')}
            </div>

            <div className='mesh-onboarding-buttons-container'>
                <Button id='mesh-onboarding-btn-restart'
                    text={getMeshTranslation('label', 'ADD_NEW_ROUTER')}
                    onClick={ restartOnboarding }
                ></Button>

                <Button id='mesh-onboarding-btn'
                    text={getMeshTranslation('label', 'FINISH')}
                    onClick={() => { history.push('/network/mesh/settings') }}
                ></Button>
            </div>
        </div>
    }

    const StepFailed = () => {
        return <div className='mesh-onboarding-content'>
            <ConnectedHouseTwibi size='200' ></ConnectedHouseTwibi>
            <div className='mesh-onboarding-title center'>
                <Translator path='network.mesh.message.error.COULD_NOT_CONNECT'></Translator>
            </div>

            <div className='mesh-onboarding-text center'>
                <Translator path='system.upgrade.error.TRY_AGAIN_OR_REACH_SUPPORT'></Translator>
            </div>

            <div className='mesh-onboarding-buttons-container'>
                <Button id='mesh-onboarding-btn-restart'
                    text={getMeshTranslation('label', 'TRY_AGAIN')}
                    onClick={ restartOnboarding }
                ></Button>

                <Button id='mesh-onboarding-btn'
                    text={getMeshTranslation('label', 'FINISH')}
                    onClick={() => { history.push('/network/mesh/settings') }}
                ></Button>
            </div>
        </div>
    }

    const getMeshTranslation = (type, key) => t(`network.mesh.${type}.${key}`) 

    const steps = [
        StepPositioningRouter(getMeshTranslation),
        StepConfigureAlias(getMeshTranslation),
        StepSearchingDevices(getMeshTranslation),
        StepSuccess(getMeshTranslation),
        StepFailed(getMeshTranslation)
    ]

    const parseTime = time => {

        const seconds = time % 60
        time = Math.floor(time / 60) 

        return `${time < 10 ? '0' + time : time}:${seconds < 10 ? '0' + seconds : seconds}`
    }

    return <>
        <div className='mesh-onboarding-page container scroll-area'>
            {steps[currentStep] ? steps[currentStep] : null}
        </div>
        <div className="mesh-timer-wrapper">
            {
                (mesh.meshScan) && <div className="mesh-timer">{parseTime(TIME_LIMIT - count)}</div>
            }
            {
                (finishingSync) && 
                <div className='mesh-timer-info'>
                    {<Translator path='network.mesh.message.info.FINISHING_SYNC'></Translator>}
                </div>
            }
        </div>
    </>
}
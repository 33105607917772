import React from "react";
import { useTranslation } from "react-i18next";

const ErrorMessage = ({ errorMessage, data }) => {
    const { t } = useTranslation()

    return t(errorMessage, data)
}

const smartMessage = (label, data) => {
    return <ErrorMessage errorMessage={`common.message.error.${label}`} data={data}></ErrorMessage>
}

const validURL = async (str, testProtocol, testPort) => {

    let strWithoutProtocol = str
    if (testProtocol) {
        const httpProtocolString = 'http://';
        if (str.substring(0, httpProtocolString.length) === httpProtocolString) {
            strWithoutProtocol = strWithoutProtocol.replace(httpProtocolString, "")
        }
        const httpsProtocolString = 'https://';
        if (str.substring(0, httpsProtocolString.length) === httpsProtocolString) {
            strWithoutProtocol = strWithoutProtocol.replace(httpsProtocolString, "")
        }
    }

    //@TODO: Verify if any URL should accept [ipv6] or [ipv6]:port and implement if needed.
    if (ValidateIPv6(strWithoutProtocol) === '') {
        return true;
    }

    var strwithoutProtocolPort = strWithoutProtocol
    if (testPort) {
        var portPattern = new RegExp('^.*:([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$')
        if (portPattern.test(strWithoutProtocol)) {
            let commaPosition = strWithoutProtocol.lastIndexOf(":");
            strwithoutProtocolPort = strWithoutProtocol.substring(0, commaPosition)
        }
    }

    let isIPv4 = (await ValidateIPv4(strwithoutProtocolPort) === '') ? true : false

    let isDomain = (ValidateDomain(strwithoutProtocolPort) === '') ? true : false

    return isIPv4 || isDomain;
}

export const getMask = (mask) => {
    switch (mask) {
        case "0.0.0.0":
            return "/0";
        case "128.0.0.0":
            return "/1";
        case "192.0.0.0":
            return "/2";
        case "224.0.0.0":
            return "/3";
        case "240.0.0.0":
            return "/4";
        case "248.0.0.0":
            return "/5";
        case "252.0.0.0":
            return "/6";
        case "254.0.0.0":
            return "/7";
        case "255.0.0.0":
            return "/8";
        case "255.128.0.0":
            return "/9";
        case "255.192.0.0":
            return "/10";
        case "255.224.0.0":
            return "/11";
        case "255.240.0.0":
            return "/12";
        case "255.248.0.0":
            return "/13";
        case "255.252.0.0":
            return "/14";
        case "255.254.0.0":
            return "/15";
        case "255.255.0.0":
            return "/16";
        case "255.255.128.0":
            return "/17";
        case "255.255.192.0":
            return "/18";
        case "255.255.224.0":
            return "/19";
        case "255.255.240.0":
            return "/20";
        case "255.255.248.0":
            return "/21";
        case "255.255.252.0":
            return "/22";
        case "255.255.254.0":
            return "/23";
        case "255.255.255.0":
            return "/24";
        case "255.255.255.128":
            return "/25";
        case "255.255.255.192":
            return "/26";
        case "255.255.255.224":
            return "/27";
        case "255.255.255.240":
            return "/28";
        case "255.255.255.248":
            return "/29";
        case "255.255.255.252":
            return "/30";
        case "255.255.255.254":
            return "/31";
        case "255.255.255.255":
            return "/32";
        default:
            return null;
    }
}

const ValidateIPv6WithPrefixNotRequired = async value => {
    if (value === '')
        return ''

    let ip, prefix;
    if (value.includes('/'))
    {
        if (value.split('/').length !== 2) {
            return smartMessage('VALIDATE_IPV6_WITH_PREFIX_FIELD')
        }
        [ip, prefix] = value.split('/')
    
        prefix = Number(prefix)
        if (!Number.isInteger(prefix) || prefix < 48 || prefix > 127) {
            return smartMessage('VALIDATE_IPV6_WITH_PREFIX_FIELD')
        }
    }
    else
    {
        ip = value
    }
    

    if (ValidateIPv6(ip) !== '') {
        return smartMessage('VALIDATE_IPV6_FIELD')
    }

    return ''
}

const ValidateIPv6WithPrefix = async value => {
    if (value === '')
        return ''

    if (value.split('/').length !== 2) {
        return smartMessage('VALIDATE_IPV6_WITH_PREFIX_FIELD')
    }
    let [ip, prefix] = value.split('/')

    prefix = Number(prefix)
    if (!Number.isInteger(prefix) || prefix < 48 || prefix > 127) {
        return smartMessage('VALIDATE_IPV6_WITH_PREFIX_FIELD')
    }

    if (ValidateIPv6(ip) !== '') {
        return smartMessage('VALIDATE_IPV6_FIELD')
    }

    return ''
}

const ValidateIPv6Prefix = async value => {
    let prefix, length

    if (value === '')
        return ''

    if (value.split('/').length !== 2) {
        return smartMessage('VALIDATE_IPV6_PREFIX_FORMAT')
    }
    [prefix, length] = value.split('/')

    length = Number(length)
    if (!Number.isInteger(length) || length < 48 || length > 64) {
        return smartMessage('VALIDATE_IPV6_PREFIX_LENGTH')
    }

    const regex = new RegExp(
        '(?!.*:::.*)' +                  // forbid addresses like 2:::
        '(?!.*::.*::.*)' +               // forbid addresses like 2::3::
        '(?!^:[^:])' +                   // forbit addresses like :2001:db8::
        '(^([0-9A-F]{0,4}:){1,4}:$)'     // ipv6 address up 4 hextets
        , 'igm'
    )

    return regex.test(prefix) ? '' : smartMessage('VALIDATE_IPV6_PREFIX_FORMAT')
}

const NoIPv6LinkLocal = async value => {
    return value && value.startsWith('fe80::') ? smartMessage('VALIDATE_IPV6_LINK_LOCAL_IS_NOT_ALLOWED') : ''
}

const ValidateIPv6 = value => {
    const regex = new RegExp('(' +
        '(^((([0-9A-F]{1,4}:){7}([0-9A-F]{1,4}|:))|' +
        '(([0-9A-F]{1,4}:){6}(:[0-9A-F]{1,4}|((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|' +
        '(([0-9A-F]{1,4}:){5}(((:[0-9A-F]{1,4}){1,2})|:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|' +
        '(([0-9A-F]{1,4}:){4}(((:[0-9A-F]{1,4}){1,3})|((:[0-9A-F]{1,4})?:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|' +
        '(([0-9A-F]{1,4}:){3}(((:[0-9A-F]{1,4}){1,4})|((:[0-9A-F]{1,4}){0,2}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|' +
        '(([0-9A-F]{1,4}:){2}(((:[0-9A-F]{1,4}){1,5})|((:[0-9A-F]{1,4}){0,3}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|' +
        '(([0-9A-F]{1,4}:){1}(((:[0-9A-F]{1,4}){1,6})|((:[0-9A-F]{1,4}){0,4}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|' +
        '(:(((:[0-9A-F]{1,4}){1,7})|((:[0-9A-F]{1,4}){0,5}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))))' +
        ')$',
        'i'
    )

    // IPv6 RegEx
    return value && !regex.test(value) ? smartMessage('VALIDATE_IPV6_FIELD') : '';
}

const ValidateDomain = value => {
    const regex = new RegExp('^(?=.{1,253}$)((?=[a-zA-Z0-9-]{1,63}.)[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*.)+[a-zA-Z]{2,}$');

    return value && !regex.test(value) ? smartMessage('VALIDATE_HOSTNAME') : '';
}

const ValidateIfNotLocalhost = async (value) => {
    let isIPv4 = (await ValidateIPv4(value) === '') ? true : false
    let isIPv6 = (ValidateIPv6(value) === '') ? true : false

    if (
        (value.toLowerCase() === "localhost") ||
        (isIPv4 && value.startsWith("127.")) ||
        (isIPv6 && /^[0:]*1$/.test(value))
    ) {
        return smartMessage('VALIDATE_CANNOT_BE_LOCALHOST')
    }

    return ''
}

const ValidateGlobalIPv6 = value => {
    const regexGlobal = new RegExp('(' +
        '([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|' +         // 1:2:3:4:5:6:7:8
        '([0-9a-fA-F]{1,4}:){1,7}:|' +                        // 1::                              1:2:3:4:5:6:7::
        '([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|' +        // 1::8             1:2:3:4:5:6::8  1:2:3:4:5:6::8
        '([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|' + // 1::7:8           1:2:3:4:5::7:8  1:2:3:4:5::8
        '([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|' + // 1::6:7:8         1:2:3:4::6:7:8  1:2:3:4::8
        '([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|' + // 1::5:6:7:8       1:2:3::5:6:7:8  1:2:3::8
        '([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|' + // 1::4:5:6:7:8     1:2::4:5:6:7:8  1:2::8
        '[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|' +      // 1::3:4:5:6:7:8   1::3:4:5:6:7:8  1::8  
        ':((:[0-9a-fA-F]{1,4}){1,7}|:)' +                    // ::2:3:4:5:6:7:8  ::2:3:4:5:6:7:8 ::8       ::
        ')$')

    const regexFilterGlobal = new RegExp('(' +
        // Unspecified address
        '([0]{1,4}:){7,7}[0]{1,4}|' +                   // 0:0:0:0:0:0:0:0
        '([0]{1,4}:){1,7}:|' +                          // 0::                              0:0:0:0:0:0:0::
        '([0]{1,4}:){1,6}:[0]{1,4}|' +                  // 0::0             0:0:0:0:0:0::0  0:0:0:0:0:0::0
        '([0]{1,4}:){1,5}(:[0]{1,4}){1,2}|' +           // 0::0:0           0:0:0:0:0::0:0  0:0:0:0:0::0
        '([0]{1,4}:){1,4}(:[0]{1,4}){1,3}|' +           // 0::0:0:0         0:0:0:0::0:0:0  0:0:0:0::0
        '([0]{1,4}:){1,3}(:[0]{1,4}){1,4}|' +           // 0::0:0:0:0       0:0:0::0:0:0:0  0:0:0::0
        '([0]{1,4}:){1,2}(:[0]{1,4}){1,5}|' +           // 0::0:0:0:0:0     0:0::0:0:0:0:0  0:0::0
        '[0]{1,4}:((:[0]{1,4}){1,6})|' +                // 0::0:0:0:0:0:0   0::0:0:0:0:0:0  0::0
        ':((:[0]{1,4}){1,7}|:)|' +                      // ::0:0:0:0:0:0:0  ::0:0:0:0:0:0:0 ::0     ::
        // Loopback address
        '([0]{1,4}:){7,7}([0]{0,3}[1]{1,1})|' +         // 0:0:0:0:0:0:0:1 0:0:0:0:0:0:0:01 0:0:0:0:0:0:0:001 0:0:0:0:0:0:0:0001
        '^([0]{1,4}:){1,6}:([0]{0,3}[1]{1,1})|' +       // 0::1             0:0:0:0:0:0::1  0:0:0:0:0:0::1
        '^::[0]{0,3}[1]{1,1}' +                         // ::1
        ')$|' +
        // Link-local address
        '^fe8|' +
        '^fe9|' +
        '^fea|' +
        '^feb|' +
        // Multicast address
        '^ff');

    // IPv6 RegEx
    return value && !(regexGlobal.test(value) && !regexFilterGlobal.test(value)) ? smartMessage('VALIDATE_IPV6_FIELD') : '';
}

const ValidateIPv4 = async value => {
    if (!value) return ''

    return (
        /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(value)
    ) ? '' : smartMessage('VALIDATE_IPV4_FIELD');
}

const ValidateHost = async value => {
    let isIPv4 = await ValidateIPv4(value)
    let isIPv6 = await ValidateIPv6(value)
    let isUrl = await IsUrl(value)

    return !isIPv4 || !isIPv6 || !isUrl ? '' : smartMessage('VALIDATE_HOST')
}

const ValidateMAC = async value => {
    return value && (value === "00:00:00:00:00:00" || !/^(([0-9a-fA-F]){2}(:[0-9a-fA-F]{2}){5})$/.test(value)) ? smartMessage('VALIDATE_MAC_FIELD') : ''
}

const ValidateMACMulticastBroadcast = async value => {
    const regexMac = new RegExp(
        '^([0-9a-fA-F]{2}):' +
        '([0-9a-fA-F]{2}):' +
        '([0-9a-fA-F]{2}):' +
        '([0-9a-fA-F]{2}):' +
        '([0-9a-fA-F]{2}):' +
        '([0-9a-fA-F]{2})$'
    );

    if (value && regexMac.test(value)) {
        let macParsed = []
        const match = value.match(regexMac);

        for (let i = 1; i < match.length; i++) {
            macParsed.push(parseInt('0x' + match[i]))
        }

        if ((macParsed[0] & 1) || (macParsed[0] & macParsed[1] & macParsed[2] & macParsed[3] & macParsed[4] & macParsed[5]) === 0xff
            || (macParsed[0] | macParsed[1] | macParsed[2] | macParsed[3] | macParsed[4] | macParsed[5]) === 0x00)
            return smartMessage('VALIDATE_MAC_FIELD');
    }
    return ""
}

const ValidateSubNetMask = async value => {
    return value && !getMask(value) ? smartMessage('VALIDATE_SUBNETMASK_FIELD') : ''
}

const IsHex = async value => {

    // Hex
    return value && !new RegExp('0x[0-9A-Fa-f]+$', 'g').test(value) ? smartMessage('IS_HEX_FIELD') : '';
}

const ForbidWhitespacesOnly = async value => {
    value = '' + value
    return value && value.trim().length === 0 ? smartMessage('FORBID_WHITESPACES_ONLY_FIELD') : '';
}

const ForbidAnyWhitespace = async value => {
    value = '' + value
    return (/\s/g.test(value)) ? smartMessage('FORBID_ANY_WHITESPACES') : '';
}

const IsVendorId = async value => {

    // Vendor ID
    return value && !new RegExp('^[0-9A-Z]{4}$', 'g').test(value) ? smartMessage('IS_VENDORID_FIELD') : '';
}

const IsUrl = async value => {
    let validUrl = (await validURL(value, true, true))
    return value && !validUrl ? smartMessage('IS_URL_FIELD') : '';
}

const IsIpDomain = async value => {
    let validUrl = (await validURL(value, false, false))
    return value && !validUrl ? smartMessage('IS_URL_FIELD') : '';
}

const IsUrlUnique = async value => {
    let isIPv4 = (await ValidateIPv4(value) === '') ? true : false
    let isIPv6 = (await ValidateIPv6(value) === '') ? true : false
    let isUrl = (await IsUrl(value) === '') ? true : false

    return !isIPv4 && !isIPv6 && isUrl ? '' : smartMessage('VALIDATE_HOSTNAME')
}

const Required = async (value) => {
    return value === '' ? smartMessage('REQUIRED_FIELD') : ''
}

const AlphaNumeric = async (value) => {

    if (!value.match(/^[0-9a-zA-Z]+$/))
        return smartMessage('ALPHANUMERIC_FIELD')

    return ''
}

const Optional = async (value, params) => {
    let { shouldValidate, validate, validateParams } = params

    return shouldValidate() ? validate(value, validateParams) : ''
}

const OptionalValidators = async (value, params) => {
    let { shouldValidate, validators } = params

    if (!shouldValidate())
        return ''

    for (const validate of validators) {
        let res = await validate.fn(value, validate.params)

        if (res !== '')
            return res
    }
    return ''
}

const NonASCII = async (value) => {

    if (!value) return ''

    for (let i = 0; i < value.length; i++) {

        let charCode = value.charCodeAt(i)

        if (charCode < 32 || charCode > 127)
            return smartMessage('NON_ASCII_FIELD')
    }

    return ''
}

const Size = async (value, { min, max }) => {

    if (value.length < min || value.length > max)
        return smartMessage('SIZE_FIELD', { min: min, max: max })

    return ''
}

const IsNumber = async (value) => {
    if (isNaN(value))
        return smartMessage('IS_NUMBER_FIELD')

    return ''
}

const Value = async (value, { min, max }) => {
    if (((min || min === 0) && value < min) || ((max || max === 0) && value > max)) {
        if (!min && min !== 0) return smartMessage('VALUE_MAX_FIELD', { max: max })
        else if (!max && max !== 0) return smartMessage('VALUE_MIN_FIELD', { min: min })
        else return smartMessage('VALUE_MIN_MAX_FIELD', { min: min, max: max })
    }

    return ''
}

const IsInteger = async (value) => {
    if (Number(value) === value && value % 1 !== 0)
        return smartMessage('IS_INTEGER_NUMBER_FIELD')

    return ''
}

const ValidateTimeHHMM = async value => {

    const regex = new RegExp('(^(?<hour>[0-9]{2}):(?<min>[0-9]{2})$)')
    const execution = regex.exec(value)

    return value && (!regex.test(value) ||
        !(execution?.groups?.hour >= 0 && execution?.groups?.hour <= 23) ||
        !(execution?.groups?.min >= 0 && execution?.groups?.min <= 59)
    ) ? smartMessage('VALIDATE_TIME_FIELD') : '';
}

const ValidateULAPrefix = async value => {

    const regex = new RegExp('^(' +
    '[fF][cC-dD][0-9A-Fa-f]{0,2}::|' +
    '[fF][cC-dD][0-9A-Fa-f]{0,2}:[0-9A-Fa-f]{0,4}::|' +
    '[fF][cC-dD][0-9A-Fa-f]{1,2}:[0-9A-Fa-f]{0,4}:[0-9A-Fa-f]{0,4}::' +
    ')$')

    return value && (!regex.test(value)) ? smartMessage('VALIDATE_IPV6_FIELD') : '';
}

const ReservedPrefix = (value) => {
    const forbiddenPrefixes = ['EasyMeshBH-']

    for (const prefix of forbiddenPrefixes) {
        if (value.startsWith(prefix)) {
            return <ErrorMessage errorMessage='network.wifi.message.warning.FORBIDDEN_PREFIX'></ErrorMessage>
        }
    }
    return ''
}

const AnatelPasswordCriteria = async (value) => {

    const hasLowercase = (/[a-z]/.test(value))
    const hasUppercase = (/[A-Z]/.test(value))
    const hasNumber = (/[0-9]/.test(value))
    const hasSymbol = (/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value))
    const hasMinCharacters = (value.length >= 8)

    if (hasLowercase && hasUppercase && hasNumber && hasSymbol && hasMinCharacters){
        return ''
    } else {
        return 'Não é permitido o uso de senha fraca'
    }
}


const extraValidators = {
    validateIfNotLocalhost: ValidateIfNotLocalhost,
    validateTimeHHMM: ValidateTimeHHMM,
    validateHost: ValidateHost,
    validateIPv6WithPrefixNotRequired: ValidateIPv6WithPrefixNotRequired,
    validateIPv6WithPrefix: ValidateIPv6WithPrefix,
    validateIPv6Prefix: ValidateIPv6Prefix,
    validateIPv6: ValidateIPv6,
    validateGlobalIPv6: ValidateGlobalIPv6,
    noIPv6LinkLocal: NoIPv6LinkLocal,
    validateIPv4: ValidateIPv4,
    validateMAC: ValidateMAC,
    validateSubNetMask: ValidateSubNetMask,
    isHex: IsHex,
    forbidWhitespacesOnly: ForbidWhitespacesOnly,
    forbidAnyWhitespace: ForbidAnyWhitespace,
    isVendorId: IsVendorId,
    isUrl: IsUrl,
    isIpDomain: IsIpDomain,
    isUrlUnique: IsUrlUnique,
    required: Required,
    alphaNumeric: AlphaNumeric,
    nonASCII: NonASCII,
    size: Size,
    isNumber: IsNumber,
    value: Value,
    isInteger: IsInteger,
    optional: Optional,
    optionalValidators: OptionalValidators,
    validateMACMulticastBroadcast:ValidateMACMulticastBroadcast,
    validateULAPrefix:ValidateULAPrefix,
    reservedPrefix:ReservedPrefix,
    anatelPasswordCriteria:AnatelPasswordCriteria
}

export default extraValidators;

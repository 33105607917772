import React, { useState, useEffect, useContext } from 'react'

import './pon-settings.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchPONSettings, savePONSettings } from '../../../resources/pon-settings';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import PONSettingsOAM from './pon-settings-oam';
import PONSettingsOMCI from './pon-settings-omci';
import PONSettingsSecurity from './pon-settings-security';
import { fetchPONStatus } from '../../../resources/pon-status';
import { GlobalsContext } from '../../../globals-context';
import { useHistory } from 'react-router';
import { reboot, showRebootModal } from '../../../utils/reboot';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';


export default function PONSettings({isWizard, setSaved, isFormSegment, formSegment}) {
    const [ponSettings, setPONSettings] = useState(null)
    const [ponStatus, setPONStatus] = useState(null)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [successText, setSuccessText] = useState(false)
    const [success, setSuccess] = useState(false)
    const [savingText, setSavingText] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [deviceBoot, setDeviceBoot] = useState(false)
    const history = useHistory()

    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)

    const { t } = useTranslation()

    useEffect(() => {

        fetchPONStatus(backend, setPONStatus)
        fetchPONSettings(backend, setPONSettings)


        // eslint-disable-next-line
    }, [])

    const ponSettingsForm = () => {
        return <React.Fragment>
            { ponStatus?.current_mode === 'epon' ?  PONSettingsOAM(ponSettings, setPONSettings) : 
                PONSettingsOMCI(ponSettings, setPONSettings, t) }
            { PONSettingsSecurity(ponSettings, ponStatus, setPONSettings) }
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await savePONSettings(backend, ponSettings)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSuccessText(t('common.message.info.SUCCESS_ON_PERSIST'))
        setSaving(false)
        setSuccess(true)
        setDeviceBoot(true)
    }

    const dismissModal = () => {
        if(deviceBoot)
            setConfirm(true)
        
        setSaving(false)
        setError(false)
        setSuccess(false)

        if (isWizard)
            setSaved(true)
    }

    const rebootModalDismiss = res => {
        if (res) {
            reboot(history, globals, saving, setSaving, setError, setSuccessText, setSavingText, backend, setSuccess)
        }
        setConfirm(false)
        setDeviceBoot(false)
    }

    const rebootMessage = <React.Fragment>
        <div><Translator path="system.reboot.warning.MUST_REBOOT_TO_APPLY_SETTINGS"></Translator></div>
        <div><Translator path="system.reboot.warning.WANT_TO_REBOOT_NOW"></Translator></div>
    </React.Fragment>

    return (
        !ponSettings ? <Loading show={true}></Loading> :

        <div id='pon-settings-page' className='with-tabs'>
            {showRebootModal(confirm, rebootModalDismiss, rebootMessage)}

            <div className='subtitle'><Translator path="menu.PON_SETTINGS"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                savingText={savingText}
                continueFn={dismissModal}
                errorText={<Translator path="common.message.error.CHECK_CONNECTION"></Translator>}
                successText={successText}
            ></DefaultStatusModals>

            {!isFormSegment ? <Form
                onSubmit={submit}
                buttonId='button-save'
            >
                {ponSettingsForm()}
            </Form> : 

            <FormSegment
                title={formSegment.title} 
                active={formSegment.active} 
                nextLabel={formSegment.nextLabel} 
                nextClicked={submit} 
            >
                {ponSettingsForm()}
            </FormSegment>}

        </div>
    )
}
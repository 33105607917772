import React, { useRef, useState } from 'react'

import './tabs-mobile.css'

function getTabClasses(tabData, activeRoute) {
    let classes = 'tab'

    if(tabData.disable) {
        classes += ' disable'
    } else {
        classes += ' clickable'
    }

    if(activeRoute.includes(tabData.route))
        classes += ' active'

    return classes
}

function updateBullet(containerRef, setBullet) {
    const maxScrollLeft = containerRef.current?.scrollWidth - containerRef.current?.clientWidth
    const threshold = maxScrollLeft / 2

    if (containerRef.current?.scrollLeft <= threshold) {
        setBullet({active: 'left'})
    } else {
        setBullet({active: 'right'})
    }
}

export default function TabsMobile(props){

    let {data, route} = props

    const containerRef = useRef(null);

    const [bullet, setBullet] = useState({active: 'left'})


    return (
        <>
            <div ref={containerRef}  className='tabs-container' onScroll={e => updateBullet(containerRef, setBullet)}>
                {
                    data.map((tabData, key) => {
                        return <div key={key} id={`tab-${tabData.label}`} 
                            className={getTabClasses(tabData, route)}
                            onClick={tabData.disable ? null : () => props.activateFn(tabData.route)}
                        >{tabData.label}</div>
                    })
                }
            </div>
            <div key={bullet.active} className="bullets-container">
                {
                    
                        <>
                            <span className={`bullet ${bullet.active === 'left' ? 'active' : ''}`} id={`bullet-pag-left`} onClick={() => {   
                                    containerRef.current.scrollLeft = 0
                                    updateBullet(containerRef, setBullet)
                                }
                            }> </span>

                            <span className={`bullet ${bullet.active === 'right' ? 'active' : ''}`} id={`bullet-pag-right`} onClick={() => {
                                    const maxScrollLeft = containerRef.current?.scrollWidth - containerRef.current?.clientWidth
                                    if (containerRef.current) {
                                        containerRef.current.scrollLeft = maxScrollLeft
                                        updateBullet(containerRef, setBullet)
                                    }
                                }
                            }> </span>
                        </>
                    
                }
            </div>
        </>
    )
}
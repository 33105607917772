import { backendStatus } from '../../backend/backend'
import { frequency } from './radio'

export const constants = {

    MIN_SSID_LENGTH: 1,
    MAX_SSID_LENGTH: 32,

    MIN_KEY_LENGTH: 8,
    MAX_KEY_LENGTH: 63,
}

export const cipher = {
    NONE: 0,
    WEP: 1,
    WPA: 2,
    WPA2: 3,
    WPA_WPA2: 4,
    WPA3: 5,
    WPA2_WPA3: 6
}

export const crypto = {
    NONE: 0,
    TKIP: 1,
    AES: 2,
    TKIP_AES: 3
}

export const ieeeStd = {
    B: 1,
    A: 2,
    G: 4,
    N: 8,
    AC: 16,
    AX: 32
}

export function getEmptyReducedWifi() {
    return {
        id: [],
        ssid: '',
        sameSSID: true,
        ssid_5: '',
        enabled: true,
        wps: false,
        broadcast: true,
        cipher: cipher.WPA2,
        crypto: crypto.AES,
        key: '',
        frequency: frequency.dualband,
        isRoot: false,
        ieee_std: 0,
    }
}

export function getWiFiListFromReduced(reduced, networksContext) {

    let base = getEmptyReducedWifi()
    let networkList = []

    if (reduced.frequency === frequency.dualband || reduced.frequency === frequency._2_4GHz)
        networkList.push(
            {
                ...base,
                id: '',
                interfaceID: '',
                frequency: frequency._2_4GHz,
                radioID: networksContext.getRadioID(frequency._2_4GHz)
            })

    if (reduced.frequency === frequency.dualband || reduced.frequency === frequency._5GHz)
        networkList.push(
            {
                ...base,
                id: '',
                interfaceID: '',
                frequency: frequency._5GHz,
                radioID: networksContext.getRadioID(frequency._5GHz)
            })

    return networkList
}

export async function fetchWirelessList(backend, setWireless, setLoading = null) {

    let result = await backend.retrieveFresh('wireless')

    if (setLoading) setLoading(false)

    if (result.status === backendStatus.SUCCESS) {
        setTimeout(() => {
            setWireless(result.content)
        }, 300)
    }
}

export async function fetchWireless(backend, setWireless, id) {

    let result = await backend.retrieveFresh(`wireless/${id}`)

    if (result.status === backendStatus.SUCCESS) {
        setWireless(result.content)
    }
}

export async function saveWireless(backend, wireless) {

    try {

        let result = await backend.update(`wireless/${wireless.id}`, wireless, undefined, false)

        return result.status === backendStatus.SUCCESS

    } catch (error) {

        console.error('Error saving wireless:', error)

        return false
    }

}

export async function createWireless(backend, wireless) {

    try {

        let result = await backend.create(`wireless`, wireless, undefined, false)

        return result.status === backendStatus.SUCCESS

    } catch (error) {

        console.error('Error creating wireless:', error)

        return false
    }

}

export async function deleteWireless(backend, wirelessID) {

    try {

        let result = await backend.delete(`wireless/${wirelessID}`, null, false)

        return result.status === backendStatus.SUCCESS

    } catch (error) {

        console.error('Error deleting wireless:', error)

        return false
    }

}

export function setWifiFromReduced(wifi, reduced) {

    wifi.enabled = reduced.enabled
    wifi.ssid = reduced.ssid
    wifi.wps = reduced.wps
    wifi.broadcast = reduced.broadcast
    wifi.cipher = reduced.cipher
    wifi.crypto = reduced.crypto
    wifi.key = reduced.key
    wifi.ieee_std = reduced.ieee_std
}

export function getFrequency(wireless, radioList) {
    let radioID = wireless.radioID
    for (let radio of radioList) {
        if (radio.id === radioID) {
            return radio.frequency
        }
    }
}

export function canBeReduced(wireless, otherWireless) {
    return wireless.ssid === otherWireless.ssid &&
        (wireless.isRoot || wireless.enabled === otherWireless.enabled) &&
        wireless.isRoot === otherWireless.isRoot &&
        wireless.wps === otherWireless.wps &&
        wireless.broadcast === otherWireless.broadcast &&
        wireless.cipher === otherWireless.cipher &&
        wireless.crypto === otherWireless.crypto &&
        wireless.key === otherWireless.key
}

export function checkIfRadioEnabled(wireless, radioList) {
    let radioID = wireless.radioID
    for (let radio of radioList) {
        if (radio.id === radioID) {
            return radio.enabled
        }
    }
}

export function reduceWireless(wirelessList, radioList) {
    let reduced = []
    let ssids = []
    for (let wireless of wirelessList) {

        if (!checkIfRadioEnabled(wireless, radioList)) {
            continue
        }

        let wasReduced = false
        if (ssids.includes(wireless.ssid)) {
            for (let i in reduced) {
                if (canBeReduced(reduced[i], wireless)) {
                    wasReduced = true
                    reduced[i].id.push(wireless.id)
                    reduced[i].frequency = frequency.dualband
                    reduced[i].ieee_std |= wireless.ieee_std
                }

            }
            if (wasReduced) continue
        }

        let reducedWireless = {
            id: [wireless.id],
            enabled: wireless.enabled,
            isRoot: wireless.isRoot,
            ssid: wireless.ssid,
            sameSSID: true,
            ssid_5: wireless.ssid,
            wps: wireless.wps,
            broadcast: wireless.broadcast,
            cipher: wireless.cipher,
            crypto: wireless.crypto,
            key: wireless.key,
            ieee_std: wireless.ieee_std,
            frequency: getFrequency(wireless, radioList)
        }

        ssids.push(wireless.ssid)
        reduced.push(reducedWireless)
    }

    return reduced
}

export function totalWirelessConnections(wirelessList, radioList, mesh) {
    let reduced = reduceWireless(wirelessList, radioList)

    let numOfConnections = 0

    reduced.forEach((rd) => {
        if(mesh && mesh.enabled && rd.id.filter(id => (id === 'wlan0-vap0' || id === 'wlan1-vap0')).length > 0)
            return

        if(rd.frequency === frequency.dualband)
            numOfConnections += 2
        else
            numOfConnections += 1
    })

    return numOfConnections
} 
import React, { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'

import './voip-dial-plan.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipDialPlan, saveVoipDialPlan } from '../../../resources/voip-dial-plan';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input'
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators';
import Button from '../../../../components/button/button';

export default function VoipDialPlan({isWizard, setSaved, isFormSegment, formSegment, history}) {
    const [voipDialPlan, setVoipDialPlan] = useState(null)

    const params = useParams()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchVoipDialPlan(params.id, backend, setVoipDialPlan)

        // eslint-disable-next-line
    }, [])

    const voipDialPlanForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <Input id='voip-dial-plan-dialplan'
                name='dialplan'
                label={<Translator path="voip.dial_plan.label.DIAL_PLAN"></Translator>}
                value={voipDialPlan.dialplan}
                onChange={(e) => {
                    voipDialPlan.dialplan = e.target.value
                    setVoipDialPlan({...voipDialPlan})
                }}
                validators={[extraValidators.nonASCII, {fn: extraValidators.size, params: {min: 0, max:4097}}]}
            ></Input>
            <Checkbox id='voip-dial-plan-digitmap-enable'
                name='digitmap_enable'
                label={<Translator path="voip.dial_plan.label.DIGITMAP_ENABLE"></Translator>}
                value={voipDialPlan.digitmap_enable}
                toggleFn={(e) => {
                    voipDialPlan.digitmap_enable = !voipDialPlan.digitmap_enable;
                    setVoipDialPlan({...voipDialPlan})
                }}
                validators={[]}
            ></Checkbox>
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveVoipDialPlan(backend, voipDialPlan)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipDialPlan ? <Loading show={true}></Loading> :

        <div id='voip-dial-plan-page' className='with-tabs'>
            <div className='subtitle'><Translator path="voip.dial_plan.title.DIAL_PLAN"></Translator></div>

            <Button id='back-to-voip-dial-plans'
                text={<Translator path="common.label.BACK"></Translator>}
                onClick={() => history.push('/voip/dial-plan')}
            ></Button>

            <div className='voip-dial-plan-wrapper'>
                <div className='card mt2'>
                    <div className='subtitle'><Translator path="voip.dial_plan.title.CONFIGURE_DIAL_PLAN"></Translator></div>

                    <DefaultStatusModals
                        saving={saving}
                        error={error}
                        success={success}
                        continueFn={dismissModal}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    ></DefaultStatusModals>

                    {!isFormSegment ? <Form
                        onSubmit={submit}
                        buttonId='button-save'
                    >
                        {voipDialPlanForm()}
                    </Form> : 

                    <FormSegment
                        title={formSegment.title} 
                        active={formSegment.active} 
                        nextLabel={formSegment.nextLabel} 
                        nextClicked={submit} 
                    >
                        {voipDialPlanForm()}
                    </FormSegment>}
                    
                </div>
            </div>
        </div>
    )
}

import { backendStatus } from '../../backend/backend'

const URL = 'wlan_access_control';

export async function fetchFirewallWlanAccessControl(backend, id, setWlanAccessControl) {

    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setWlanAccessControl(result.content)
    }

}

export async function fetchFirewallWlanAccessControlList(backend, setWlanAccessControlList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setWlanAccessControlList(result.content)
    }
}

export async function deleteFirewallWlanAccessControlById(backend, id, setWlanAccessControlList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchFirewallWlanAccessControlList(backend, setWlanAccessControlList)
    }
}

export async function saveFirewallWlanAccessControl(backend, wlanAccessControl, setErrorMessage) {

    try{

        let result = null;

        if(!wlanAccessControl.id) {
            result = await backend.create(`${URL}`, wlanAccessControl)
        }
        else {
            result = await backend.update(`${URL}/${wlanAccessControl.id}`, wlanAccessControl)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || wlanAccessControl)

    }catch(error){

        console.error('Error saving WLAN_ACCESS_CONTROL:', error)

        return false
    }

}
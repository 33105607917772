import React, { useState, useEffect, useContext } from 'react'

import { fetchBlankUser, updateUser } from '../../resources/user';
import { BackendContext } from '../../../backend/backend';
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import Loading from '../../../components/loading/loading';
import Form from '../../../components/form/form';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/input/input';
import FormSegment from '../../../components/form/form-segment';
import common from '../../../components/form/validators/common';
import { useTranslation } from 'react-i18next'
import Translator from '../../common/components/translator/translator';
import extraValidators from '../../common/validators';
import { fetchDevice, updateDevice } from '../../resources/device';
import Select from '../../../components/select/select';
import Checkbox from '../../../components/checkbox/checkbox';
import { fetchServiceSystem, saveServiceSystem } from '../../resources/system';
import { AdminUserName, LimitedUserName } from '../../common/constants';
import { GlobalsContext } from '../../globals-context'

export default function SystemUsers({ isWizard, setWizardUser, isFormSegment, formSegment }) {
    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [user, setUser] = useState(null)
    let [confirmation, setConfirmation] = useState('')
    let [confirmationError, setConfirmationError] = useState('')
    let [errorMessage, setErrorMessage] = useState('')

    let [system, setSystem] = useState(true)
    let [savingSystem, setSavingSystem] = useState(false)
    let [errorSystem, setErrorSystem] = useState(false)
    let [successSystem, setSuccessSystem] = useState(false)

    let globals = useContext(GlobalsContext)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)
    const history = useHistory()

    useEffect(() => {

        fetchBlankUser(setUser)
        fetchServiceSystem(backend, setSystem)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!user)
            return

        if (isWizard) {
            setWizardUser(user)
        }

        passwordConfirmation()

        // eslint-disable-next-line
    }, [user, confirmation])

    let save = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await updateUser(backend, user, setErrorMessage)

        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        let device = await fetchDevice(backend, () => { })

        if (!device.login_enable) {
            device.login_enable = true
            await updateDevice(backend, device, () => { })
        }

        setSaving(false)
        setSuccess(true)
    }

    let saveSystem = async () => {

        if (saving || error || success) return

        setSavingSystem(true)
        system.id = "0"
        let ok = await saveServiceSystem(backend, system)
        if (!ok) {
            setSavingSystem(false)
            setErrorSystem(true)
            return
        }

        setSavingSystem(false)
        setSuccessSystem(true)
    }

    let dismiss = () => {
        let isSuccess = success
        setSuccess(false)
        setError(false)

        if (isSuccess)
            history.push('/login')
    }

    let dismissSystem = () => {
        setSuccessSystem(false)
        setErrorSystem(false)
    }

    const passwordConfirmation = () => {
        if (confirmation !== user.password) {
            setConfirmationError(t('system.users.label.PASSWORDS_DO_NOT_MATCH'))
            return t('system.users.label.PASSWORDS_DO_NOT_MATCH')
        } else {
            setConfirmationError('')
            return ''
        }
    }

    const nextClicked = () => {
        if (isWizard && !confirmationError) {
            formSegment.nextClicked()
        }
    }

    const systemUserForm = () => {
        return <React.Fragment>
            <Select
                id='username'
                name='username'
                label={<Translator path="common.label.USER"></Translator>}
                options={[{ value: AdminUserName, text: AdminUserName }, { value: LimitedUserName, text: LimitedUserName }]}
                value={user.username}
                onChange={(e) => setUser({ ...user, username: e.target.value.trim(), id: e.target.value.trim() })}
            ></Select>

            <Input name='user-password'
                id='password'
                type='password'
                label={<Translator path="common.label.PASSWORD"></Translator>}
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value.trim() })}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: 1, max: 30 } },
                    common.anatelPasswordCriteria
                ]}
                errorMessage={confirmationError}
                passwordMeter={true}
            ></Input>

            <Input name='confirmation-user-password'
                id='confirmation'
                type='password'
                label={<Translator path="common.label.CONFIRM_PASSWORD"></Translator>}
                value={confirmation}
                onChange={(e) => setConfirmation(e.target.value.trim())}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: 1, max: 30 } },
                ]}
                errorMessage={confirmationError}
            ></Input>
        </React.Fragment>
    }

    const systemEnableLimitedUserForm = () => {
        return <React.Fragment>
            <Checkbox
                id='limited_user_enabled'
                value={system.limited_user_enabled}
                toggleFn={() => { setSystem({ ...system, limited_user_enabled: !system.limited_user_enabled }) }}
                label={<Translator path="system.users.label.ENABLE_LIMITED_USER"></Translator>}
            ></Checkbox>

        </React.Fragment>
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'cannot accept same password')
            return t('system.users.label.PASSWORDS_EQUALS_TO_PREVIOUS')
        else
            return errorMsg
    }

    return !user ? <Loading show={true}></Loading> :
        <div id='users-page' className='container scroll-area'>
            <div className='subtitle'><Translator path="menu.USER_AND_PASSWORD"></Translator></div>
            <div className='card mt2'>
                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    errorText={translateErrorMessagesFromBackend(errorMessage)}
                    success={success}
                    continueFn={dismiss}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                {!isFormSegment ?

                    <React.Fragment>
                        <div className='subtitle'><Translator path="system.users.label.UPDATE_CREDENTIALS"></Translator></div>

                        <Form id='user-form'
                            onSubmit={save}
                            disableButton={confirmationError !== ''}
                            buttonId='button-save-user'
                        >
                            {systemUserForm()}
                        </Form>
                    </React.Fragment> :
                    <FormSegment
                        title={formSegment.title}
                        active={formSegment.active}
                        previousLabel={formSegment.previousLabel}
                        previousClicked={formSegment.previousClicked}
                        disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                        nextLabel={formSegment.nextLabel}
                        nextClicked={nextClicked}
                        disableButton={confirmationError !== ''}
                    >
                        {systemUserForm()}
                    </FormSegment>
                }
            </div>

            {!globals.isLimitedUserFlag() && (
                <div className='card mt2'>
                    <DefaultStatusModals
                        saving={savingSystem}
                        error={errorSystem}
                        success={successSystem}
                        continueFn={dismissSystem}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    ></DefaultStatusModals>

                    {!isFormSegment ?

                        <React.Fragment>
                            <div className='subtitle'><Translator path="system.users.label.ENABLE_LIMITED_USER"></Translator></div>
                            <Form id='enable-limited-user-form'
                                onSubmit={saveSystem}
                                buttonId='button-save-user'
                            >
                                {systemEnableLimitedUserForm()}
                            </Form>

                        </React.Fragment> :
                        <FormSegment
                            title={formSegment.title}
                            active={formSegment.active}
                            previousLabel={formSegment.previousLabel}
                            previousClicked={formSegment.previousClicked}
                            disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={nextClicked}
                            disableButton={confirmationError !== ''}
                        >
                            {systemEnableLimitedUserForm()}
                        </FormSegment>
                    }
                </div>
            )}
        </div>
}
import React, { useState, useEffect, useContext } from 'react'

import './gem-port-statistics.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchGemPortStatistics } from '../../../resources/gem-port-statistics';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list'
import Pager from '../../../../components/pager/pager';
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function GemPortStatistics({isWizard, setSaved, isFormSegment, formSegment}) {
    const [gemPortStatistics, setGemPortStatistics] = useState(null)

    const [gemPortStatisticsPagination, setGemPortStatisticsPagination] = useState(null)

    const backend = useContext(BackendContext)

    const handleError = () => {
        setGemPortStatistics([])
    }

    useEffect(() => {

        setGemPortStatisticsPagination({pageNumber: 1, totalElements: 0, pageSize: 10})

        fetchGemPortStatistics(backend, setGemPortStatistics, handleError)

        // eslint-disable-next-line
    }, [])
    
    const getColumns = () => {

        return [
            {header: <Translator path="pon.gem_port_statistics.label.FLOW_ID"></Translator>, align: 'center'},
            {header: <Translator path="pon.gem_port_statistics.label.GEM_PORT_ID"></Translator>, align: 'center'},
            {header: <Translator path="pon.gem_port_statistics.label.DIRECTION"></Translator>, align: 'center'},
            {header: <Translator path="pon.gem_port_statistics.label.PACKAGE_COUNT"></Translator>, align: 'center'}
        ]
    }

    const getGemPortStatistics = () => {
        if(gemPortStatistics.length !== gemPortStatisticsPagination.totalElements) {
            setGemPortStatisticsPagination({...gemPortStatisticsPagination, totalElements: gemPortStatistics.length})
        }

        let gemPortStatisticsLines = [];

        let begining = (gemPortStatisticsPagination.pageNumber - 1) * (gemPortStatisticsPagination.pageSize)
        let end = begining + gemPortStatisticsPagination.pageSize
        if (end > gemPortStatisticsPagination.totalElements) {
            end = gemPortStatisticsPagination.totalElements
        }
        for(let i = begining; i < end; i++) {
            gemPortStatisticsLines.push([gemPortStatistics[i].flow_id, gemPortStatistics[i].gemport_id, gemPortStatistics[i].gemport_direction, gemPortStatistics[i].pkt_count]);
        }

        return gemPortStatisticsLines;
    }

    const changePage = page => {
        setGemPortStatisticsPagination({...gemPortStatisticsPagination, pageNumber: page})
    }

    const gemPortStatisticsForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="pon.gem_port_statistics.title.GEM_PORT_STATISTICS"></Translator></div>
            {
                gemPortStatistics?.length > 0 ?
                    <>
                        <div className='gemport-hosts-list-wrapper'>
                        <List 
                            lines={getGemPortStatistics()}
                            columns={getColumns()}
                        ></List>
                        </div>
                        <Pager 
                            pageNumber={gemPortStatisticsPagination.pageNumber}
                            totalElements={gemPortStatisticsPagination.totalElements}
                            pageSize={gemPortStatisticsPagination.pageSize}
                            pageChangeFn={changePage}>
                        </Pager>
                        <Button
                            id='button-refresh'
                            type='button'
                            text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                            onClick={async () => {
                                await fetchGemPortStatistics(backend, setGemPortStatistics, handleError)}
                            }
                        ></Button>
                    </> : 
                        <div className='info-card noelements-card'>
                            <Translator path="pon.gem_port_statistics.error.COULD_NOT_GET_INFO"></Translator>
                        </div>
            }
            
        </React.Fragment>
    }

    return (
        !gemPortStatistics ? <Loading show={true}></Loading> :

        <div id='gem-port-statistics-page' className='with-tabs'>

            { gemPortStatisticsForm() }

        </div>
    )
}

import { useState, useEffect } from 'react'
import timeUtils from '../../utils/time-utils'

export default function DashboardUptime({uptime}) {

    const [time, setTime] = useState(uptime)

    useEffect(() => {

        let mounted = true

        setTimeout(() => mounted && setTime(time + 1), 1000)

        return () => mounted = false

    }, [time])

    return timeUtils.composeTime(time)

}

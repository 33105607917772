import React, { useContext, useEffect, useState} from 'react'
import { MainHeaderContext } from '../components/main-header/main-header-state'

import '../app/main-app.css'
import './main-app.css'
import SideBar from './pages/main/side-bar'
import { Switch, Route, useHistory } from 'react-router-dom'
import LoginPage from '../app/pages/login/login'
import MainPage from './pages/main/main-page'
import { BackendContext } from '../backend/backend'
import Loading from '../components/loading/loading'
import SystemStatus from '../app/system-status-context'
import Globals from '../app/globals-context'
import { fetchDevice } from '../app/resources/device'

function getClasses(header){

    return header.hidden.value    ? '' :
           header.collapsed.value ? 'with-header-collapsed' :
           header.extended.value  ? 'with-header-extended' : 
                                    'with-header'
}

export default function MainApp(){

    const [loading, setLoading] = useState(true)
    const [activeSession, setActiveSession] = useState(false)
    const [device, setDevice] = useState(null)

    let headerState = useContext(MainHeaderContext)
    let backend = useContext(BackendContext)
    let history = useHistory()

    useEffect(() => {

        auditBackendUrl()

        headerState.notifications.set(false)

        backend.setNeedApply(true)

        fetchDevice(backend, setDevice)

        setActiveSession(JSON.parse(sessionStorage.getItem('activeSession')))

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        sessionStorage.setItem('activeSession', activeSession)
        // eslint-disable-next-line
    }, [activeSession])

    useEffect(() => {
        if (!device)
            return

        let authToken = sessionStorage.getItem('token')

        if(authToken)
            backend.setAuthInfo({token: authToken})
        else
            history.push('/login')

        setLoading(false)

        // eslint-disable-next-line
    }, [device])

    const auditBackendUrl = () => {
        const url = backend.getDriverBaseUrl()
        if(url.indexOf('http') < 0){
            let pretendedUrl = `https://${window.location.hostname}${process.env.REACT_APP_REST_ENDPOINT}`

            try{
                let result = fetch(`${pretendedUrl}/status`)
                if(result.status !== 200)
                    return
            }catch{
                return
            }

            backend.setDriverBaseUrl(pretendedUrl)
        }
    }

    return <Globals><div id='main-app' className={getClasses(headerState)}>

        {loading ? <Loading show={true}></Loading> : <React.Fragment>

            <SideBar></SideBar>

            <SystemStatus>
                <Switch>
                    <Route path='/login' render={() => <LoginPage activeSession={activeSession} setActiveSession={setActiveSession}/>}></Route>
                    <Route path='/' render={() => <MainPage activeSession={activeSession}/>}></Route>
                </Switch>
            </SystemStatus>

        </React.Fragment>}
    </div></Globals>
}
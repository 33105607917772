import { backendStatus } from  '../../backend/backend'

export async function fetchConnectedDevicesList(backend, setConnectedDevices = null, withCache = false) {

    let result = withCache ? await backend.retrieve('connected_device') : await backend.retrieveFresh('connected_device')

    if(result.status === backendStatus.SUCCESS){
        if (setConnectedDevices)
            setConnectedDevices(result.content)
        else
            return result.content
    }
}

export async function fetchConnectedDevice(backend, id, setConnectedDevice) {

    let result = await backend.retrieveFresh(`connected_device/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setConnectedDevice(result.content)
        return true
    } else {
        setConnectedDevice(null)
        return false
    }
}
import React from 'react'

export default function ConnectedHouse({size}){

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 200 200">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path fill="#00A335" d="M175.995 55.234c-.097-25.59-24.473-43.7-48.084-50.418-21.29-6.057-39.912-5.367-58.717 5.435-6.18 3.55-11.108 9.177-13.51 16.002-2.364 6.72-1.735 14.805-1.378 21.903.617 12.283 1.938 21.484-5.932 31.16-7.83 9.627-19.388 14.323-21.718 27.682-3.61 20.683-2.026 31.05 11.882 52.193 3.808 5.79 26.716 37.037 66.804 39.71 10.535.703 22.387-2.285 29.15-10.972 7.407-9.511 9.232-22.335 5.89-33.603-4.905-16.55.937-27.076 12.023-39.792 7.454-8.55 15.173-14.856 19.193-25.708 3.873-10.462 4.439-22.506 4.397-33.592" opacity=".06" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(100.498499, 100.000000) rotate(-90.000000) translate(-100.498499, -100.000000)"/>
                            <g>
                                <path fill="#627C85" d="M0 136.275L111.968 136.275 111.968 69.181 0 69.181z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#D0DCE1" d="M2.775 133.5L109.193 133.5 109.193 71.956 2.775 71.956z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#627C85" d="M115.475 136.275L179.287 136.275 179.287 69.181 115.475 69.181z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#D0DCE1" d="M118.249 133.5L176.512 133.5 176.512 71.956 118.249 71.956z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#627C85" d="M115.687 63.6L179.287 64.825 115.687 23.2 115.687 64.825" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#49646D" d="M97.935 132.269c.345 0 .624.28.624.623 0 .345-.28.624-.624.624s-.624-.28-.624-.624.28-.623.624-.623zm8.354 0c.345 0 .624.28.624.623 0 .345-.28.624-.624.624s-.623-.28-.623-.624.279-.623.623-.623zm2.904-43.316v43.316H96.111v-23.511h-1.027v-1.613h7.054V90.714h-1.264v-1.761h8.32z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#779CAB" d="M124.601 93.606L165.674 93.606 165.674 74.009 124.601 74.009z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M134.64 91.24V76.375c-.92 0-1.667-.747-1.667-1.667h-5.432c0 .92-.747 1.667-1.667 1.667V91.24c.92 0 1.667.746 1.667 1.667h5.432c0-.921.746-1.667 1.667-1.667z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#D0DCE1" d="M133.867 89.79c0-.285-.23-.515-.515-.515-.284 0-.514.23-.514.515 0 .284.23.514.514.514.285 0 .515-.23.515-.514" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M135.658 91.24V76.375c.92 0 1.667-.747 1.667-1.667h5.432c0 .92.746 1.667 1.667 1.667V91.24c-.92 0-1.667.746-1.667 1.667h-5.432c0-.921-.746-1.667-1.667-1.667z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#D0DCE1" d="M136.43 89.79c0-.285.231-.515.515-.515.284 0 .515.23.515.515 0 .284-.23.514-.515.514-.284 0-.514-.23-.514-.514" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M154.888 91.24V76.375c-.92 0-1.667-.747-1.667-1.667h-5.432c0 .92-.746 1.667-1.667 1.667V91.24c.92 0 1.667.746 1.667 1.667h5.432c0-.921.747-1.667 1.667-1.667z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#D0DCE1" d="M154.115 89.79c0-.285-.23-.515-.514-.515-.285 0-.515.23-.515.515 0 .284.23.514.515.514.284 0 .514-.23.514-.514" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M155.906 91.24V76.375c.92 0 1.667-.747 1.667-1.667h5.432c0 .92.746 1.667 1.667 1.667V91.24c-.92 0-1.667.746-1.667 1.667h-5.432c0-.921-.746-1.667-1.667-1.667z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#D0DCE1" d="M156.679 89.79c0-.285.23-.515.514-.515.284 0 .515.23.515.515 0 .284-.23.514-.515.514-.284 0-.514-.23-.514-.514" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#779CAB" d="M163.998 115.85v.37l3.843.001v17.401h-43.545v-17.031h-1.074v-.74h40.776z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#49646D" d="M150.89 114.131c.349 0 .632.283.632.632 0 .349-.283.631-.632.631h-.33l.002 18.091h-1.017l-1.101-18.091h-17.731l-1.1 18.091h-1.017l-.001-18.091h-.328c-.348 0-.631-.282-.631-.631 0-.35.283-.632.631-.632h22.623z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#49646D" d="M122.895 109.774c.205 0 .375.147.41.341l.006.075v.047c1.274 0 2.315.99 2.4 2.24l.005.165v5.631c0 .72-.317 1.367-.818 1.808l.16-.005h7.194c1.37 0 2.49 1.068 2.574 2.416l.005.163h-1.776v10.4c0 .205-.147.375-.341.41l-.075.007c-.23 0-.416-.186-.416-.416v-10.401h-8.912v10.55c0 .205-.146.375-.34.41l-.076.007c-.204 0-.374-.147-.41-.342l-.006-.075V110.19c0-.23.186-.416.416-.416zM146.935 133.056c0 .23-.186.416-.417.416-.23 0-.415-.186-.415-.416l-.001-10.401h-1.775c0-1.424 1.154-2.579 2.579-2.579h7.194l.16.005c-.502-.441-.818-1.087-.818-1.808v-5.631c0-1.328 1.076-2.405 2.404-2.405v-.047c0-.23.186-.416.416-.416.23 0 .417.186.417.416v23.015c0 .23-.187.417-.417.417-.23 0-.416-.186-.416-.417v-10.55h-8.911v10.4zM164.727 114.389v-.837h11.785V133.5h-9.891v-19.111h-1.894zM166.621 93.606L176.512 93.606 176.512 74.009 166.621 74.009z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#779CAB" d="M69.663 133.5V98.439s-.49-10.044 10.18-10.044c10.671 0 10.054 10.044 10.054 10.044v35.06M11.288 133.534L55.151 133.534 55.151 104.897 11.288 104.897z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M19.623 108.976c0-.437-.354-.791-.791-.791-.438 0-.792.354-.792.79 0 .438.354.792.792.792.437 0 .79-.354.79-.791z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M25.006 110.472v-2.992c-.557 0-1.009-.452-1.009-1.01H13.666c0 .558-.452 1.01-1.01 1.01v2.992c.558 0 1.01.452 1.01 1.01h10.331c0-.558.452-1.01 1.01-1.01zm0 20.032v-16.479c-.557 0-1.009-.452-1.009-1.01H13.666c0 .558-.452 1.01-1.01 1.01v16.479c.558 0 1.01.452 1.01 1.01h10.331c0-.558.452-1.01 1.01-1.01zm9.005-21.528c0-.437-.354-.791-.792-.791-.437 0-.791.354-.791.79 0 .438.354.792.791.792.438 0 .792-.354.792-.791z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M39.395 110.472v-2.992c-.558 0-1.01-.452-1.01-1.01H28.054c0 .558-.452 1.01-1.01 1.01v2.992c.558 0 1.01.452 1.01 1.01h10.331c0-.558.452-1.01 1.01-1.01zm0 20.032v-16.479c-.558 0-1.01-.452-1.01-1.01H28.054c0 .558-.452 1.01-1.01 1.01v16.479c.558 0 1.01.452 1.01 1.01h10.331c0-.558.452-1.01 1.01-1.01zm9.004-21.528c0-.437-.354-.791-.792-.791-.437 0-.791.354-.791.79 0 .438.354.792.791.792.438 0 .792-.354.792-.791z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#C4BEB2" d="M52.836 124.267c0 .15-.123.273-.274.273-.15 0-.273-.123-.273-.273v-3.835c0-.151.122-.274.273-.274.151 0 .274.123.274.274v3.835" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path stroke="#D0DCE1" strokeWidth=".6" d="M53.783 110.472v-2.992c-.558 0-1.01-.452-1.01-1.01h-10.33c0 .558-.453 1.01-1.01 1.01v2.992c.557 0 1.01.452 1.01 1.01h10.33c0-.558.452-1.01 1.01-1.01zm0 20.032v-16.479c-.558 0-1.01-.452-1.01-1.01h-10.33c0 .558-.453 1.01-1.01 1.01v16.479c.557 0 1.01.452 1.01 1.01h10.33c0-.558.452-1.01 1.01-1.01z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#49646D" d="M6.448 131.48c.27.205.445.53.445.895 0 .621-.503 1.125-1.124 1.125-.622 0-1.125-.504-1.125-1.125 0-.365.175-.69.445-.896l-2.314.001v-15.62h20.452v1.771H21.2v13.849h-3.144c.27.205.445.53.445.895 0 .621-.504 1.125-1.125 1.125-.62 0-1.124-.504-1.124-1.125 0-.365.174-.69.445-.896zM65.58 104.921l1.502.424c.804.228 1.273 1.065 1.045 1.87l-4.166 14.749c.007.07.011.14.011.212v8.049c0 1.133-.919 2.052-2.052 2.052l-.237-.001c.263.073.457.314.457.6 0 .345-.28.624-.624.624h-1.324c-.344 0-.623-.279-.623-.623 0-.287.193-.528.457-.601H49.709c.264.073.457.314.457.6 0 .345-.28.624-.624.624H48.22c-.344 0-.624-.279-.624-.623 0-.287.194-.529.457-.601h-.222c-1.133 0-2.052-.918-2.052-2.051v-7.807h-.48c-.836 0-1.514-.677-1.514-1.514v-1.56c0-.836.678-1.514 1.514-1.514l15.062-.001 3.351-11.862c.228-.805 1.064-1.273 1.87-1.046zM4.725 91.388c.31 0 .562.252.562.562v22.008c0 .31-.252.563-.562.563-.31 0-.562-.252-.562-.563v-3.75H2.775V95.7h1.388v-3.75c0-.31.251-.562.562-.562z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#627C85" d="M111.137 0L0 64.8 111.6 64.8 111.6 11.729z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#A0BAC5" d="M109.2 4.8L109.2 62.4 9.6 62.4z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#49646D" d="M90.96 133.516h-.764l-.822-11.127h-6.657l-.82 11.127h-.766v-11.127h-.458v-.596h.458v-1.102c0-1.134.919-2.052 2.052-2.052h5.726c1.133 0 2.052.918 2.052 2.052v1.102h.459v.596h-.46v11.127z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#627C85" d="M60.043 35.829h16.173v7.696h3.565v19.357H55.06V52.857l-.008 10.03-12.554-.01.011-15.045 12.55.01.001-4.317h4.983v-7.696z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                                <path fill="#779CAB" d="M85.426 47.755V34.006h9.706v28.876H76.216V47.755h9.21z" transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(10.000000, 31.000000)"/>
                            </g>
                            <g>
                                <g transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(36.000000, 38.000000) translate(23.000000, 0.000000)">
                                    <circle cx="30.4" cy="30.4" r="30.4" fill="#EAF4ED" opacity=".237"/>
                                    <circle cx="30.4" cy="30.4" r="22.8" fill="#EAF4ED" opacity=".477"/>
                                    <circle cx="30.4" cy="30.4" r="15.2" fill="#EAF4ED" opacity=".8"/>
                                    <g fill="#00A335">
                                        <path d="M11.028 14.614c-.676.676-1.773.676-2.45 0-.677-.677-.677-1.774 0-2.45.677-.678 1.774-.678 2.45 0 .677.676.677 1.773 0 2.45M6.798 11.34c-.245 0-.489-.094-.675-.28-.374-.374-.374-.979 0-1.352.983-.984 2.29-1.525 3.68-1.525 1.391 0 2.698.541 3.68 1.525.374.373.374.978 0 1.351-.373.374-.978.374-1.35 0-.623-.622-1.45-.964-2.33-.964-.88 0-1.707.342-2.329.964-.187.187-.431.28-.676.28" transform="translate(20.900000, 22.800000)"/>
                                        <path d="M15.565 8.582c-.244 0-.489-.094-.676-.28-1.358-1.359-3.164-2.107-5.086-2.107-1.921 0-3.727.748-5.086 2.107-.373.373-.979.373-1.352 0-.373-.373-.373-.978 0-1.352 1.72-1.72 4.006-2.667 6.438-2.667 2.432 0 4.719.947 6.439 2.667.373.374.373.979 0 1.352-.187.186-.432.28-.677.28" transform="translate(20.900000, 22.800000)"/>
                                        <path d="M19.27 5.544c-.224.223-.517.336-.811.336-.294 0-.587-.113-.81-.336-2.096-2.095-4.882-3.25-7.846-3.25-2.963 0-5.75 1.155-7.844 3.25-.449.447-1.175.447-1.623 0-.448-.448-.448-1.175 0-1.622.624-.624 1.299-1.179 2.015-1.66C4.537.79 7.109 0 9.803 0c.538 0 1.072.031 1.598.094 2.972.35 5.721 1.68 7.87 3.828.447.447.447 1.174 0 1.622" transform="translate(20.900000, 22.800000)"/>
                                    </g>
                                </g>
                                <g transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(36.000000, 38.000000) translate(0.000000, 64.000000)">
                                    <circle cx="30.4" cy="30.4" r="30.4" fill="#EAF4ED" opacity=".237"/>
                                    <circle cx="30.4" cy="30.4" r="22.8" fill="#EAF4ED" opacity=".477"/>
                                    <circle cx="30.4" cy="30.4" r="15.2" fill="#EAF4ED" opacity=".8"/>
                                    <g fill="#00A335">
                                        <path d="M11.028 14.614c-.676.676-1.773.676-2.45 0-.677-.677-.677-1.774 0-2.45.677-.678 1.774-.678 2.45 0 .677.676.677 1.773 0 2.45M6.798 11.34c-.245 0-.489-.094-.675-.28-.374-.374-.374-.979 0-1.352.983-.984 2.29-1.525 3.68-1.525 1.391 0 2.698.541 3.68 1.525.374.373.374.978 0 1.351-.373.374-.978.374-1.35 0-.623-.622-1.45-.964-2.33-.964-.88 0-1.707.342-2.329.964-.187.187-.431.28-.676.28" transform="translate(20.900000, 22.800000)"/>
                                        <path d="M15.565 8.582c-.244 0-.489-.094-.676-.28-1.358-1.359-3.164-2.107-5.086-2.107-1.921 0-3.727.748-5.086 2.107-.373.373-.979.373-1.352 0-.373-.373-.373-.978 0-1.352 1.72-1.72 4.006-2.667 6.438-2.667 2.432 0 4.719.947 6.439 2.667.373.374.373.979 0 1.352-.187.186-.432.28-.677.28" transform="translate(20.900000, 22.800000)"/>
                                        <path d="M19.27 5.544c-.224.223-.517.336-.811.336-.294 0-.587-.113-.81-.336-2.096-2.095-4.882-3.25-7.846-3.25-2.963 0-5.75 1.155-7.844 3.25-.449.447-1.175.447-1.623 0-.448-.448-.448-1.175 0-1.622.624-.624 1.299-1.179 2.015-1.66C4.537.79 7.109 0 9.803 0c.538 0 1.072.031 1.598.094 2.972.35 5.721 1.68 7.87 3.828.447.447.447 1.174 0 1.622" transform="translate(20.900000, 22.800000)"/>
                                    </g>
                                </g>
                                <g transform="translate(-981.000000, -240.000000) translate(855.000000, 240.000000) translate(126.000000, 0.000000) translate(36.000000, 38.000000) translate(91.000000, 64.000000)">
                                    <circle cx="30.4" cy="30.4" r="30.4" fill="#EAF4ED" opacity=".237"/>
                                    <circle cx="30.4" cy="30.4" r="22.8" fill="#EAF4ED" opacity=".477"/>
                                    <circle cx="30.4" cy="30.4" r="15.2" fill="#EAF4ED" opacity=".8"/>
                                    <g fill="#00A335">
                                        <path d="M11.028 14.614c-.676.676-1.773.676-2.45 0-.677-.677-.677-1.774 0-2.45.677-.678 1.774-.678 2.45 0 .677.676.677 1.773 0 2.45M6.798 11.34c-.245 0-.489-.094-.675-.28-.374-.374-.374-.979 0-1.352.983-.984 2.29-1.525 3.68-1.525 1.391 0 2.698.541 3.68 1.525.374.373.374.978 0 1.351-.373.374-.978.374-1.35 0-.623-.622-1.45-.964-2.33-.964-.88 0-1.707.342-2.329.964-.187.187-.431.28-.676.28" transform="translate(20.900000, 22.800000)"/>
                                        <path d="M15.565 8.582c-.244 0-.489-.094-.676-.28-1.358-1.359-3.164-2.107-5.086-2.107-1.921 0-3.727.748-5.086 2.107-.373.373-.979.373-1.352 0-.373-.373-.373-.978 0-1.352 1.72-1.72 4.006-2.667 6.438-2.667 2.432 0 4.719.947 6.439 2.667.373.374.373.979 0 1.352-.187.186-.432.28-.677.28" transform="translate(20.900000, 22.800000)"/>
                                        <path d="M19.27 5.544c-.224.223-.517.336-.811.336-.294 0-.587-.113-.81-.336-2.096-2.095-4.882-3.25-7.846-3.25-2.963 0-5.75 1.155-7.844 3.25-.449.447-1.175.447-1.623 0-.448-.448-.448-1.175 0-1.622.624-.624 1.299-1.179 2.015-1.66C4.537.79 7.109 0 9.803 0c.538 0 1.072.031 1.598.094 2.972.35 5.721 1.68 7.87 3.828.447.447.447 1.174 0 1.622" transform="translate(20.900000, 22.800000)"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )

}
export const Protocol = {
    IPV4 : 0,
    IPV6: 1,
    BOTH: 2
}

export const Mode = {
    NONE: -1,
    DHCP: 0,
    IPOE: 1,
    PPPOE: 2,
    BRIDGED: 3
}

export const ipv6AddressMode = {
    NONE: 0,
    STATIC: 1,
    STATELESS_DHCPv6: 2,
    STATEFUL_DHCPv6: 3,
    AUTO_DETECT: 4
}
import React, { useState, useEffect, useContext } from 'react'

import Input from '../../../../components/input/input'
import Select from '../../../../components/select/select'

import { useParams, useHistory } from 'react-router-dom'
import { BackendContext } from '../../../../backend/backend'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import waitRouter from '../../../utils/wait-router'
import { GlobalsContext } from '../../../globals-context'
import Form, { FormContext } from '../../../../components/form/form'
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next'
import extraValidators from '../../../common/validators'
import Button from '../../../../components/button/button'
import { fetchWanList } from '../../../resources/wan';

import {
    saveAdvancedQosTrafficControl,
    fetchAdvancedQosTrafficControlList,
    fetchAdvancedQosTrafficControl
} from '../../../resources/advanced-qos-traffic-control'

import {
    QosTCIPVersionEnumeration,
    QosTCStruct,
    createProtocolOptions,
    createDirectionOptions,
    createIPVersionOptions,
} from "./qos-traffic-control-common"

export default function QoSTrafficControlEdit({ isWizard, isFormSegment, formSegment }) {
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(false)
    const [dataFetched, setDataFetched] = useState(false)

    const [QoSTrafficControl, setQoSTrafficControl] = useState(QosTCStruct)
    const [interfacesOptions, setInterfacesOptions] = useState([{ value: '', text: '' }])
    const [wanRes, setWanRes] = useState(null)

    const params = useParams()
    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const [qosTrafficControlElementsList, setQoSTrafficControlElementsList] = useState(null)
    const history = useHistory()
    const { t } = useTranslation()

    const ProtocolOptions = createProtocolOptions(t)
    const IPVersionOptions = createIPVersionOptions(t)
    const DirectionOptions = createDirectionOptions(t)

    useEffect(() => {
        const fetchData = async () => {
            if (qosTrafficControlElementsList === null)
                await fetchAdvancedQosTrafficControlList(backend, setQoSTrafficControlElementsList)
            await fetchWanList(backend, setWanRes)
            setDataFetched(true)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (dataFetched === true) {
            if (params.id !== 'add') {
                if (qosTrafficControlElementsList !== null && qosTrafficControlElementsList.length > 0) {
                    setQoSTrafficControl(qosTrafficControlElementsList.filter(element => element.id === params.id)[0])
                }
                else {
                    fetchAdvancedQosTrafficControl(backend, params.id, setQoSTrafficControl)
                }
            }
        }
        // eslint-disable-next-line
    }, [dataFetched, qosTrafficControlElementsList])

    useEffect(() => {
        if (!!wanRes) {
            let options = wanRes.map((item) => {
                return ({ value: item["interfaceID"], text: item["interfaceID"] })
            })
            setInterfacesOptions(options)
            setQoSTrafficControl({ ...QoSTrafficControl, interfaceID: (wanRes.length > 0) ? wanRes[0].interfaceID : '' })
        }
        // eslint-disable-next-line
    }, [wanRes])

    const submitConfirm = async () => {
        if (saving || success || error) return
        submit()
    }

    const submit = async () => {
        if (params.id === 'add')
            await create()
        else
            await save()

        await backend.backendApply()
    }

    const goBackToList = () => {
        history.push(`/advanced/qos-traffic-control`)
    }

    const create = async () => {

        if (saving) return

        setSaving(true)

        let status = await saveAdvancedQosTrafficControl(backend, QoSTrafficControl, setErrorMsg)

        if (status === false) {
            setSaving(false)
            setSuccess(false)
            setError(true)
            return
        }

        await waitReturn()
    }

    const save = async () => {

        if (saving) return

        setSaving(true)

        let status = await saveAdvancedQosTrafficControl(backend, QoSTrafficControl, setErrorMsg)

        if (status === false) {
            setSaving(false)
            setSuccess(false)
            setError(true)
            return
        }

        await waitReturn()
    }

    const waitReturn = async () => {

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        if (error === false)
            goBackToList()

        setSaving(false)
    }

    const dismissModals = () => {
        setSaving(false)
        setSuccess(false)
        setError(false)
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'you need to choose a protocol when use ports')
            return t('advanced.qos_traffic_control.error.SELECT_PROTOCOL')
        if (errorMsg === 'you have reached the maximum number of configurations')
            return t('advanced.qos_traffic_control.error.MAX_OF_CONNECTIONS')
        return errorMsg
    }

    const QoSTrafficControlForm = (formContext) => {
        return (
            <div>
                <div className='qos_traffic_control_d_flex'>
                    <Select
                        id='qos_traffic_control-edit-interface'
                        label={<Translator path="common.label.INTERFACE" required="true"></Translator>}
                        options={interfacesOptions}
                        value={QoSTrafficControl.interfaceID}
                        onChange={(e) => {
                            setQoSTrafficControl({ ...QoSTrafficControl, interfaceID: e.target.value })
                        }}
                        validators={[extraValidators.required]}
                    ></Select>

                    <Select
                        id='qos_traffic_control_ip_rule_dscp_pattern'
                        name='qos_traffic_control_ip_rule_dscp_pattern'
                        label={t('common.label.DIRECTION')}
                        options={DirectionOptions}
                        value={QoSTrafficControl.direction}
                        onChange={(e) => {
                            let new_val = { ...QoSTrafficControl, direction: Number(e.target.value) };
                            setQoSTrafficControl(new_val)
                        }}
                    ></Select>
                </div>

                <Input
                    id='qos_traffic_control_limit_rate'
                    name='qos_traffic_control_limit_rate'
                    label={t('advanced.qos_traffic_control.label.LIMIT_RATE')}
                    placeholder={t('advanced.qos_traffic_control.label.LIMIT_RATE')}
                    value={QoSTrafficControl.limit_rate}
                    onChange={(e) => {
                        let new_val = {
                            ...QoSTrafficControl, limit_rate: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        };
                        setQoSTrafficControl(new_val)
                    }}
                    disabled={false}
                    validators={[
                        extraValidators.required,
                        extraValidators.isNumber,
                        { fn: extraValidators.value, params: { min: 0, max: 2147483647 } }
                    ]}
                ></Input>

                <div className='qos_traffic_control_d_flex'>

                    <Select
                        id='qos_traffic_control_ip_rule_ip_version'
                        name='qos_traffic_control_ip_rule_ip_version'
                        label={t('advanced.qos_classification.label.IP_VERSION')}
                        options={IPVersionOptions}
                        value={QoSTrafficControl.ip_version}
                        onChange={(e) => {
                            let new_val = { ...QoSTrafficControl, ip_version: Number(e.target.value) };
                            setQoSTrafficControl(new_val)
                        }}
                    ></Select>

                    <Select
                        id='qos_traffic_control_ip_rule_ip_protocol'
                        name='qos_traffic_control_ip_rule_ip_protocol'
                        label={t('common.label.PROTOCOL')}
                        options={ProtocolOptions}
                        value={QoSTrafficControl.protocol_type}
                        onChange={(e) => {
                            setQoSTrafficControl({ ...QoSTrafficControl, protocol_type: Number(e.target.value) })
                        }}
                    ></Select>
                </div>

                {/* Source IP */}

                <div className='qos_traffic_control_d_flex'>
                    <Input
                        id='qos_traffic_control_ip_rule_source_ip'
                        name='qos_traffic_control_ip_rule_source_ip'
                        label={t('advanced.qos_classification.label.SOURCE_IP')}
                        type='text'
                        placeholder={t('advanced.qos_classification.label.SOURCE_IP')}
                        value={
                            QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4 ?
                                QoSTrafficControl.ip4_config.src_ip :
                                QoSTrafficControl.ip6_config.src_ip
                        }
                        onChange={(e) => {
                            if (QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4) {
                                let new_val = {
                                    ...QoSTrafficControl, ip4_config: {
                                        ...QoSTrafficControl.ip4_config, src_ip: e.target.value
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                            else {
                                let new_val = {
                                    ...QoSTrafficControl, ip6_config: {
                                        ...QoSTrafficControl.ip6_config, src_ip: e.target.value
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                        }}
                        disabled={false}
                        validators={[
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4,
                                validators: [
                                    {fn: extraValidators.validateIPv4, params: ''},
                                    {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                ]
                            }}, 
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV6,
                                validators: [
                                    {fn: extraValidators.validateIPv6, params: ''},
                                    {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                ]
                            }}, 
                        ]}
                    ></Input>

                    <Input
                        id='qos_traffic_control_ip_rule_source_mask'
                        name='qos_traffic_control_ip_rule_source_mask'
                        label={t('advanced.qos_classification.label.SOURCE_PREFIX')}
                        placeholder={t('advanced.qos_classification.label.SOURCE_PREFIX')}
                        value={
                            QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4 ?
                                QoSTrafficControl.ip4_config.src_prefix_length :
                                QoSTrafficControl.ip6_config.src_prefix_length
                        }
                        onChange={(e) => {
                            if (QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4) {
                                let new_val = {
                                    ...QoSTrafficControl, ip4_config: {
                                        ...QoSTrafficControl.ip4_config, src_prefix_length: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                            else {
                                let new_val = {
                                    ...QoSTrafficControl, ip6_config: {
                                        ...QoSTrafficControl.ip6_config, src_prefix_length: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                        }}
                        disabled={false}
                        validators={[
                            extraValidators.required,
                            extraValidators.isNumber,
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4,
                                validators: [
                                    {fn: extraValidators.value, params: { min: 0, max: 32 }},
                                ]
                            }}, 
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV6,
                                validators: [
                                    {fn: extraValidators.value, params: { min: 0, max: 128 }},
                                ]
                            }}, 
                        ]}
                    ></Input>
                </div>

                {/* Destination IP */}

                <div className='qos_traffic_control_d_flex'>
                    <Input
                        id='qos_traffic_control_ip_rule_destination_ip'
                        name='qos_traffic_control_ip_rule_destination_ip'
                        label={t('advanced.qos_classification.label.DEST_IP')}
                        type='text'
                        placeholder={t('advanced.qos_classification.label.DEST_IP')}
                        value={
                            QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4 ?
                                QoSTrafficControl.ip4_config.dst_ip :
                                QoSTrafficControl.ip6_config.dst_ip
                        }
                        onChange={(e) => {
                            if (QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4) {
                                let new_val = {
                                    ...QoSTrafficControl, ip4_config: {
                                        ...QoSTrafficControl.ip4_config, dst_ip: e.target.value
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                            else {
                                let new_val = {
                                    ...QoSTrafficControl, ip6_config: {
                                        ...QoSTrafficControl.ip6_config, dst_ip: e.target.value
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                        }}
                        disabled={false}
                        validators={[
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4,
                                validators: [
                                    {fn: extraValidators.validateIPv4, params: ''},
                                    {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                ]
                            }}, 
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV6,
                                validators: [
                                    {fn: extraValidators.validateIPv6, params: ''},
                                    {fn: extraValidators.validateIfNotLocalhost, params: ''},
                                ]
                            }}, 
                        ]}
                    ></Input>

                    <Input
                        id='qos_traffic_control_ip_rule_destination_mask'
                        name='qos_traffic_control_ip_rule_destination_mask'
                        label={t('advanced.qos_classification.label.DEST_PREFIX')}
                        placeholder={t('advanced.qos_classification.label.DEST_PREFIX')}
                        value={
                            QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4 ?
                                QoSTrafficControl.ip4_config.dst_prefix_length :
                                QoSTrafficControl.ip6_config.dst_prefix_length
                        }
                        onChange={(e) => {
                            if (QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4) {
                                let new_val = {
                                    ...QoSTrafficControl, ip4_config: {
                                        ...QoSTrafficControl.ip4_config, dst_prefix_length: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                            else {
                                let new_val = {
                                    ...QoSTrafficControl, ip6_config: {
                                        ...QoSTrafficControl.ip6_config, dst_prefix_length: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    }
                                };
                                setQoSTrafficControl(new_val)
                            }
                        }}
                        disabled={false}
                        validators={[
                            extraValidators.required,
                            extraValidators.isNumber,
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV4,
                                validators: [
                                    {fn: extraValidators.value, params: { min: 0, max: 32 }},
                                ]
                            }}, 
                            {fn: extraValidators.optionalValidators, params: {
                                shouldValidate: () => QoSTrafficControl.ip_version === QosTCIPVersionEnumeration.TRAFFIC_CTRL_IPV6,
                                validators: [
                                    {fn: extraValidators.value, params: { min: 0, max: 128 }},
                                ]
                            }},
                        ]}
                    ></Input>
                </div>

                {/* Ports */}

                <div className='qos_traffic_control_d_flex'>
                    <Input
                        id='qos_traffic_control_ip_rule_source_port'
                        name='qos_traffic_control_ip_rule_source_port'
                        label={t('advanced.qos_traffic_control.label.SRC_PORT')}
                        placeholder={t('advanced.qos_traffic_control.label.SRC_PORT')}
                        value={QoSTrafficControl.src_port}
                        onChange={(e) => {
                            let new_val = {
                                ...QoSTrafficControl, src_port: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                            }
                            setQoSTrafficControl(new_val)
                        }}
                        disabled={false}
                        validators={[
                            extraValidators.isNumber,
                            { fn: extraValidators.value, params: { min: 0, max: 65535 } }
                        ]}
                    ></Input>

                    <Input
                        id='qos_traffic_control_ip_rule_destination_port'
                        name='qos_traffic_control_ip_rule_destination_port'
                        label={t('advanced.qos_traffic_control.label.DST_PORT')}
                        placeholder={t('advanced.qos_traffic_control.label.DST_PORT')}
                        value={QoSTrafficControl.dst_port}
                        onChange={(e) => {
                            let new_val = {
                                ...QoSTrafficControl, dst_port: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                            }
                            setQoSTrafficControl(new_val)
                        }}
                        disabled={false}
                        validators={[
                            extraValidators.isNumber,
                            extraValidators.isInteger,
                            { fn: extraValidators.value, params: { min: 0, max: 65535 } }
                        ]}
                    ></Input>
                </div>
            </div >
        )
    }


    return (
        !QoSTrafficControl ? <div className='loading'></div> :

            <div id='edit-qos-traffic-control-page'>

                <DefaultStatusModals
                    modalID='qos-traffic-control'
                    saving={saving}
                    success={success}
                    error={error}
                    errorText={translateErrorMessagesFromBackend(errorMsg)}
                    continueFn={dismissModals}
                    isWarningModal={true}
                    savingText={t('common.label.SAVING')}
                ></DefaultStatusModals>

                <div className='subtitle'><Translator path="menu.QOS_TRAFFIC_CONTROL"></Translator></div>

                <Button
                    id='network-qos-traffic-control-return'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={e => goBackToList()}
                ></Button>

                <div className='card mt2'>
                    <div className='subtitle'><Translator path="advanced.qos_traffic_control.label.ADD_QOS_TRAFFIC_CONTROL_RULE"></Translator></div>
                    <Form
                        onSubmit={submitConfirm}
                        submitText={<Translator path="common.label.SAVE"></Translator>}
                        buttonId='button-save'
                    >
                        <FormContext.Consumer>
                            {value => QoSTrafficControlForm(value)}
                        </FormContext.Consumer>
                    </Form>
                </div>
            </div>
    )
}
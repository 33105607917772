import { backendStatus } from '../../backend/backend'

export const httpMethod = {
    GET: 0,
    POST: 1,
    PUT: 2,
    DELETE: 3,
}

export function HttpRequest() {
    return {
        dest: "",
        mesh_session: false,
        method: httpMethod.GET,
        token: "",
        status: 0,
        body: ""
    }
}

export async function createRequest(backend, httpRequest) {
    let result = await backend.create('http_request', httpRequest, undefined, false)
    if((result.status === backendStatus.SUCCESS && result.content.status === 200) ||
        (result.status === backendStatus.ERROR && result.content && result.content.body && result.content.body.code === "timeout")){
        return result.content
    }

    return false
}

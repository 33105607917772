import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom';

import './voip-dial-plan.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipDialPlanList } from '../../../resources/voip-dial-plan';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import EditIcon from '../../../../components/icons/edit';


export default function VoipDialPlanList({isWizard, setSaved, isFormSegment, formSegment}) {
    const [voipDialPlanList, setVoipDialPlanList] = useState(null)

    const history = useHistory()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {

        fetchVoipDialPlanList(backend, setVoipDialPlanList)

        // eslint-disable-next-line
    }, [])

    const editDialPlan = id => {
        history.push(`/voip/dial-plan/${id}`)
    }

    const voipDialPlanListTable = () => {
        return <React.Fragment>

            <div className='voip-dial-plans-box'>
                { voipDialPlanList.map((diPl, key) => 
                    <InfoCard
                        key={key}
                        lines={[
                            {label: t('voip.dial_plan.label.PORT'), value: <span>{diPl.voip_port ? diPl.voip_port.toUpperCase() : `FXS${diPl.id}` } </span> , id: `card-voip-dial-plan-name-${key}`},
                            {label: t('voip.dial_plan.label.DIGITMAP_ENABLE'), value: diPl.digitmap_enable ? t('modal.answer.YES') : t('modal.answer.NO'), id: `card-voip-dial-plan-dial-plan-digimap-enabled-${key}`},
                            {label: t('voip.dial_plan.label.DIAL_PLAN'), value: diPl.dialplan.length > 10 ? diPl.dialplan.substr(1, 10) + '...' : diPl.dialplan, infoMessage: diPl.dialplan.length > 10 ? <div className='dialplan-long-text'>{diPl.dialplan}</div> : null},
                            {label: t('common.label.OPERATIONS'), value:  <span className="card-voip-edit-icon" onClick={e => editDialPlan(diPl.id)}><EditIcon /></span> },
                        ]}
                    ></InfoCard>)
                }
            </div>
            
        </React.Fragment>
    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipDialPlanList ? <Loading show={true}></Loading> :

        <div id='voip-dial-plan-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="voip.dial_plan.title.DIAL_PLAN"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>
  
            {voipDialPlanListTable()}

        </div>
    )
}

import React, { useEffect, useContext } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'


import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import TabsMobile from '../../common/components/tabs/tabs-mobile';
import AdvancedState from './advanced-context'
import { useTranslation } from 'react-i18next';
import NeighbourHosts from './neighbour_hosts/neighbour-hosts'
import StaticRouting from './static-routing/static-routing'
import StaticRoutingEdit from './static-routing/static-routing-edit'
import AdvancedQosClassification from './qos-classification/advanced-qos-classification'
import QoSClassificationEdit from './qos-classification/qos-classification-edit'
import AdvancedGlobalRouting from './global-routing/advanced-global-routing'
import AdvancedBridging from './bridging/advanced-bridging'
import AdvancedIgmpMldSnooping from './igmp-mld-snooping/advanced-igmp-mld-snooping'
import AdvancedQosTrafficControl from './qos-traffic-control/advanced-qos-traffic-control'
import AdvancedQosTrafficControlEdit from './qos-traffic-control/qos-traffic-control-edit'
import AdvancedQosPolicy from './qos-policy/advanced-qos-policy'
/*IMPORTS_TAG*/

export default function AdvancedPage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const tabs = [
        { label: t('advanced.neighbour_hosts.title.NEIGHBOUR_HOSTS'), route: '/advanced/neighbour-hosts' },
        { label: t('menu.STATIC_ROUTING'), route: '/advanced/static-routing' },
        { label: t('advanced.qos_classification.title.QOS_CLASSIFICATION'), route: '/advanced/qos-classification' },
        { label: t('menu.ADVANCED_QOS_POLICY'), route: '/advanced/qos-policy' },
        { label: t('menu.QOS_TRAFFIC_CONTROL'), route: '/advanced/qos-traffic-control' },
        { label: t('advanced.global_routing.title.GLOBAL_ROUTING'), route: '/advanced/global-routing' },
        { label: t('menu.BRIDGING'), route: '/advanced/bridging' },
        { label: t('menu.IGMP_MLD_SNOOPING'), route: '/advanced/igmp-mld-snooping' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.ADVANCED'))
        header.backRoute.set('/advanced/neighbour-hosts')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('advanced') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <AdvancedState>

            <TabsMobile
                data={tabs}
                route={history.location.pathname}
                activateFn={changeRoute}
            ></TabsMobile>

            <Switch>
                <Route path='/advanced/neighbour-hosts' render={(props) => {
                    return <NeighbourHosts {...props} ></NeighbourHosts>
                }}></Route>

                <Route path='/advanced/static-routing/:id' render={(props) => {
                    return <StaticRoutingEdit {...props} ></StaticRoutingEdit>
                }}></Route>

                <Route path='/advanced/static-routing' render={(props) => {
                    return <StaticRouting {...props} ></StaticRouting>
                }}></Route>

                <Route path='/advanced/qos-classification/:id' render={(props) => {
                    return <QoSClassificationEdit {...props} ></QoSClassificationEdit>
                }}></Route>

                <Route path='/advanced/qos-classification' render={(props) => {
                    return <AdvancedQosClassification {...props} ></AdvancedQosClassification>
                }}></Route>

                <Route path='/advanced/global-routing' render={(props) => {
                    return <AdvancedGlobalRouting {...props} ></AdvancedGlobalRouting>
                }}></Route>

                <Route path='/advanced/bridging' render={(props) => {
                    return <AdvancedBridging {...props} ></AdvancedBridging>
                }}></Route>

                <Route path='/advanced/igmp-mld-snooping' render={(props) => {
                    return <AdvancedIgmpMldSnooping {...props} ></AdvancedIgmpMldSnooping>
                }}></Route>

                <Route path='/advanced/qos-traffic-control/:id' render={(props) => {
                    return <AdvancedQosTrafficControlEdit {...props} ></AdvancedQosTrafficControlEdit>
                }}></Route>

                <Route path='/advanced/qos-policy' render={(props) => {
                    return <AdvancedQosPolicy {...props} ></AdvancedQosPolicy>
                }}>
                </Route>

                <Route path='/advanced/qos-traffic-control' render={(props) => {
                    return <AdvancedQosTrafficControl {...props} ></AdvancedQosTrafficControl>
                }}></Route>
                {/*ROUTES_TAG*/}

            </Switch>
        </AdvancedState>
    )
}

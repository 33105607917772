import { backendStatus } from "../../backend/backend"

export const DNSMode = {
    DNS_PROXY: 0,
    DNS_MANUALLY: 1
}

export async function fetchDhcpServer(backend, setDhcpServer = null) {

    try{

        let result = await backend.retrieveFresh('dhcp_server')
        if(result.status === backendStatus.SUCCESS){
            if (setDhcpServer) {
                setDhcpServer(result.content[0])
                return true
            }

            return result.content[0]
        }

        return false

    }catch(error){
        console.error('Error fetching dhcp server:', error)
        return false
    }
}

export async function updateDhcpServer(backend, dhcpServer) {

    try{

        let result = await backend.update(`dhcp_server/${dhcpServer.id}`, dhcpServer)
        return result.status === backendStatus.SUCCESS

    }catch(error){
        console.error('Error fetching updating server:', error)
        return false
    }
}

// active clients
export async function fetchDhcpActiveClientsList(backend, setDhcpActiveClient) {
    let result = await backend.retrieveFresh('dhcp_client')

    if(result.status === backendStatus.SUCCESS){
        setDhcpActiveClient(result.content)
    }
}
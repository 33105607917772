import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import InfoCard from '../../../components/infocard/infocard'
import Modal from '../../../components/modal/modal'
import DashboardUptime from './dashboard-uptime'
import Loading from '../../../components/loading/loading'
import { fetchDhcpServer } from '../../resources/dhcp-server'
import { fetchServiceSystem } from '../../resources/system'
import { BackendContext } from '../../../backend/backend'
import './dashboard.css'
import macutils from '../../utils/macutils'
import List from '../../../components/list/list'
import { fetchGPONStatus } from '../../resources/gpon-status';
import { Protocol } from '../network/network-wan/network-wan-common'

export default function DashboardDetails({ponStatus, device, wireless, lan, wan, wanInterface, deviceID, radios}) {

    const [wanDetails, setWanDetails] = useState(false)
    const [wanInfos, setWanInfos] = useState(null)
    const [wanIfaceInfos, setWanIfaceInfos] = useState(null)

    const [lanDetails, setLanDetails] = useState(false)
    const [dhcpServer, setDhcpServer] = useState(null)
    const [system, setSystem] = useState(null)
    const [gponStatus, setGPONStatus] = useState(null)

    const backend = useContext(BackendContext)
    const { t } = useTranslation();

    useEffect(() => {
        fetchDhcpServer(backend, setDhcpServer)
        fetchServiceSystem(backend, setSystem)
        // eslint-disable-next-line 
    }, [])

    useEffect(() => {
        if(ponStatus?.current_mode.toUpperCase() === 'GPON') {
            fetchGPONStatus(backend, setGPONStatus, null)
        }
        // eslint-disable-next-line 
    }, [ponStatus])

    const getDnsLines = (dns, ipv6 = false) => {
        let addresses = []

        if(dns.dns1 !== '')
            addresses.push({label: 'DNS 1', value: dns.dns1 === '0.0.0.0' ? '--': dns.dns1, id: ipv6 ? 'card-wan-ipv6-dns1' : 'card-wan-dns1'})
        if(dns.dns2 !== '')
            addresses.push({label: 'DNS 2', value: dns.dns2 === '0.0.0.0' ? '--': dns.dns2, id: ipv6 ? 'card-wan-ipv6-dns2' : 'card-wan-dns2'})
        if(dns.dns3 !== '')
            addresses.push({label: 'DNS 3', value: dns.dns3 === '0.0.0.0' ? '--': dns.dns3, id: ipv6 ? 'card-wan-ipv6-dns3' : 'card-wan-dns3'})

        return addresses
    }

    const getIpv6Lines = (addresses) => {
        let sortedAddresses = [...addresses]

        let lines = []

        sortedAddresses.forEach((address) => {
            address.includes('fe80') ? 
                lines.push({label: `${t('dashboard.label.IPV6_ADDRESS_LINK_LOCAL')}`, value: address, id:`ipv6-link-local`})
                :
                lines.push({label: `${t('dashboard.label.IPV6_ADDRESS_GLOBAL')}`, value: address, id:`ipv6-global`}) 
        })

        return lines
    }

    const getIpv4Lines = (wanIfaceInfos, wanInfos) => {

        let lines = []

        if(wanInfos.protocol === Protocol.IPV4 || wanInfos.protocol === Protocol.BOTH) {
            lines.push(
                {label: t('common.label.IPV4'), value: (wanIfaceInfos.ip4 === '' || wanIfaceInfos.ip4 === '0.0.0.0') ? '--' : wanIfaceInfos.ip4, id: 'card-wan-ip'},
                {label: t('common.label.MASK'), value: (wanIfaceInfos.netmask === '' || wanIfaceInfos.netmask === '0.0.0.0') ? '--' : wanIfaceInfos.netmask, id: 'card-wan-netmask'},
                {label: t('common.label.GATEWAY_IPV4'), value: (wanInfos.gateway === '' || wanInfos.gateway === '0.0.0.0') ? '--' : wanInfos.gateway, id: 'card-wan-gateway'},
                ...getDnsLines(wanInfos.dns_v4)
            )
        }

        return lines
    }

    const wanDetailsModal = () => {
        return wanInfos && wanIfaceInfos && <Modal
            modalID='wan-details'
            show={wanDetails}
            onOverlayClick={() => setWanDetails(false)}
            dismissText={t('common.label.BACK')}
            onDismissClick={() => setWanDetails(false)}
            content={<div className='dashboard-modal-wan'>
                <div className='dashboard-subtitle-modal'>
                    <label>Internet (WAN)</label>
                </div>
                <div className='dashboard-modal-content'>
                    <InfoCard
                        lines={[
                            {label: t('dashboard.label.WAN_CONNECTION'), value: wanInfos.interfaceID, id: 'card-wan-op-mode'},
                            ...(ponStatus.current_mode === 'gpon' ? [{
                                label: t('dashboard.label.OMCI_CONFIGURED'),
                                value: wanInfos.omci_configured ? t('modal.answer.YES') : t('modal.answer.NO'),
                                id: 'card-wan-omci-mode'
                            }] : []),
                            {label: t('common.label.STATUS'), value: wanIfaceInfos?.running ? t('common.label.UP') : t('common.label.DOWN'), id: 'card-wan-status'},
                            {label: t('dashboard.label.MAC_ADDRESS'), value: wanIfaceInfos.mac, id: 'card-wan-mac'},
                            {label: t('common.label.VLAN_ID'), value: wanInfos.vlan_enable ? wanInfos.vlanID : t('common.label.DISABLED'), id: 'card-wan-vlan'},
                            {label: t('dashboard.label.MTU_SIZE'), value: wanIfaceInfos.mtu, id: 'card-wan-mtu'},
                            ...getIpv4Lines(wanIfaceInfos, wanInfos)
                        ]}
                    ></InfoCard>

                    { wanInfos.protocol === Protocol.IPV6 || wanInfos.protocol === Protocol.BOTH ?
                        <div>
                        <div className='dashboard-subtitle-modal'>
                            <label>IPv6</label>
                        </div>
                        <InfoCard
                            lines={[
                                ...getIpv6Lines(wanIfaceInfos.ip6_list),
                                {label: 'Gateway', value: (wanInfos.gateway_v6 ? wanInfos.gateway_v6 : ""), id: 'card-wan-ipv6-gateway'},
                                ...getDnsLines(wanInfos.dns_v6, true)
                            ]}
                        ></InfoCard>
                        </div>
                        :
                        null
                    }
                </div>
            </div>}
        ></Modal>
    }

    const getDhcpServerStatus = () => {

        if(!dhcpServer) return <Loading show={true} mini></Loading>

        return dhcpServer.enabled ? t('dashboard.label.ENABLED') : t('dashboard.label.DISABLED')
    }

    const hasLanIPv6 = () => {
        return system && system.ipv6.enabled && Array.isArray(lan.ip6_list) && lan.ip6_list.length
    }

    const lanDetailsModal = () => {

        return lan && <Modal
            show={lanDetails}
            onOverlayClick={() => setLanDetails(false)}
            dismissText={t('common.label.BACK')}
            onDismissClick={() => setLanDetails(false)}
            content={<div className='dashboard-modal-lan'>
                <div className='dashboard-subtitle-modal'>
                    <label>{t('dashboard.label.LOCAL_NETWORK_LAN')}</label>
                </div>
                <div className='dashboard-modal-content'>
                    <InfoCard
                        lines={[
                            {label: t('dashboard.label.IP_ADDRESS'), value: lan.ip4, id:'card-lan-ip'},
                            {label: t('dashboard.label.NETMASK'), value: lan.netmask, id: 'card-lan-netmask'},
                            {label: t('dashboard.label.MAC_ADDRESS'), value: lan.mac, id: 'card-lan-mac'},
                            {label: t('dashboard.label.DHCP_SERVER'), value: getDhcpServerStatus(), id: 'card-lan-dhcp'},
                        ]}
                    ></InfoCard>

                    {hasLanIPv6() && <div className='dashboard-subtitle-modal'>
                        <label>IPv6</label>
                    </div>}

                    {hasLanIPv6() && <InfoCard
                        lines={getIpv6Lines(lan.ip6_list)}
                    ></InfoCard>}

                </div>
            </div>}
        ></Modal>
    }

    const getStateDesc = (state) => {
        switch(state) {
            case 'O1':
                return `${state.toUpperCase()} - ${t('dashboard.label.INITIAL_STATE')}`
            case 'O2':
            case 'O3':
                return `${state.toUpperCase()} - ${t('dashboard.label.SERIAL_NUMBER_STATE')}`
            case 'O4':
                return `${state.toUpperCase()} - ${t('dashboard.label.RANGING_STATE')}`
            case 'O5':
                return `${state.toUpperCase()} - ${t('dashboard.label.OPERATION_STATE')}`
            case 'O6':
                return `${state.toUpperCase()} - ${t('dashboard.label.INTERMITTENT_LODS_STATE')}`
            case 'O7':
                return `${state.toUpperCase()} - ${t('dashboard.label.EMERGENCY_STOP_STATE')}`
            default:
                return '-'
        }
    }

    const createSsidSpan = ssid => {
        const MAX_STRING_SIZE = 20
        return (
            <span title={ssid}>
                {ssid.length > MAX_STRING_SIZE ? `${ssid.substring(0, MAX_STRING_SIZE)} ...` : ssid}
            </span>
        )
    }


    const getConnectionDetails = () => {

        let columns = [
            {header: t('dashboard.label.2_4G_NETWORK'), align: 'center'}
        ]

        let wifi_name_5g = wireless.filter(w => w.isRoot && w.radioID === 'radio0')[0]
        let wifi_name_2_4g = wireless.filter(w => w.isRoot && w.radioID === 'radio1')[0]

        let line = [
            [radios[1].enabled ? createSsidSpan(wifi_name_2_4g.ssid) : t('dashboard.label.DISABLED')]
        ]

        if(radios.length > 1){

            columns.push({header: t('dashboard.label.5G_NETWORK'), align: 'center'})
            line[0].push(radios[0].enabled ? createSsidSpan(wifi_name_5g.ssid) : t('dashboard.label.DISABLED'))
        }
        
        if(ponStatus?.current_mode.toUpperCase() === 'GPON'){
            columns.push({ header: t('dashboard.label.GPON_STATE'), align: 'center' })
            line[0].push(getStateDesc(gponStatus?.onu_state_id))
        }

        columns.push({ header: t('dashboard.label.WAN_CONNECTION'), align: 'center' })
        line[0].push(
                wan.map((w, key) => {
                    return (
                        <div key={key} >
                            <div id='wan-details-line' className='dashboard-link' 
                                onClick={() => {
                                    setWanInfos(w)
                                    setWanIfaceInfos(wanInterface.find(i => w.id === i.id))
                                    setWanDetails(true)}
                                }
                            >
                                {w.interfaceID}
                            </div>
                    </div>
                )})
            )

        columns.push({header: t('dashboard.label.LAN_IPV4_ADDR'), align: 'center'})
        line[0].push(
            <div id='lan-details-line' className='dashboard-link' onClick={() => setLanDetails(true)}>
                {lan.ip4}
            </div>
        )

        return <div className='dashboard-list-wrapper'>
                <List
                    extendedLines
                    columns={columns}
                    lines={line}
                ></List>
            </div>
    }

    const getDetails = () => {

        let license = '';
        let shortLicense = '';

        if (device.sw_cgi_license) {
            license = device.sw_cgi_license;
            shortLicense = license.substring(0, 19) + '...';
        } else
            shortLicense = t('dashboard.label.PRODUCT_HAS_NO_LICENSE');

        return <div className='dashboard-list-wrapper'>
            <List
                extendedLines
                columns={[
                    {header: t('dashboard.label.MODEL'), align: 'center'},
                    {header: t('dashboard.label.LAN_MAC_ADDR'), align: 'center'},
                    {header: t('dashboard.label.FIRMWARE_VERSION'), align: 'center'},
                    {header: t('dashboard.label.ACTIVE_TIME'), align: 'center'},
                    {header: t('dashboard.label.INTELBRAS_SW_LICENSE'), align: 'center'}
                ]}
                lines={[
                    [
                        device.model,
                        macutils.macAddressMask(deviceID).toUpperCase(),
                        device.fw_version,
                        <DashboardUptime uptime={device.uptime}></DashboardUptime>,
                        <span title={license}>{shortLicense}</span>
                    ]
                ]}
            ></List>
        </div>
    }

    return (
        <div id='dashboard-details-container'>

            <div className='dashboard-details info'>

                {wanDetailsModal()}
                {lanDetailsModal()}
                {/* {bridgeDetailsModal()} */}

                <div className='dashboard-subtitle'>
                    <label>{t('dashboard.label.CONNECTION_STATUS')}</label>
                </div>

                {wan && wireless && lan  && radios ? getConnectionDetails() : <Loading show={true} small></Loading>}

            </div>

            <div className='dashboard-details device'>

                <div className='dashboard-subtitle'>
                    <label>{t('dashboard.label.DETAILS')}</label>
                </div>

                {device ? getDetails() : <Loading show={true} small></Loading>}

            </div>

        </div>
    )
}

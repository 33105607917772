import { backendStatus } from '../../backend/backend'

const statusList = [{id: 0, status_tr069_inform: 'NO_INFORM'}, {id: 1, status_tr069_inform: 'NO_RESPONSE'}, {id: 2, status_tr069_inform: 'INFORM_BREAK'},
{id: 3, status_tr069_inform: 'INFORM_SUCCESS'}, {id: 4, status_tr069_inform: 'INFORM_AUTH_FAIL'}, {id: 5, status_tr069_inform: 'INFORMING'}]

export async function fetchTR069Inform(backend, setTR069Inform) {
    let result = await backend.retrieveFresh('tr069_inform')

    if(result.status === backendStatus.SUCCESS){

        let tr069inform_status = statusList.find(el => el.id === result.content[0].status)?.status_tr069_inform

        if(!tr069inform_status) {
            tr069inform_status = 'OTHER'
        }
        
        let tr069inform_cause = result.content[0].cause

        setTR069Inform({cause:tr069inform_cause, status:tr069inform_status})
    }
}

export async function create_tr069_inform(backend, errorMessage, setErrorMessage) {
    try{
        let result = null;
        
        result = await backend.create(`tr069_inform/`, {})
    
        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content.body.message)
        }
    
        return result.status === backendStatus.SUCCESS
    
    }catch(error){
        console.error('Error start tr069_inform:', error)
    
        return false
    }
}
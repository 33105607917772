import { backendStatus } from '../../backend/backend'

export async function fetchFirewallMacFiltering(backend, setFirewallMacFilterings) {
    let result = await backend.retrieveFresh('firewall_mac_filtering')

    if(result.status === backendStatus.SUCCESS){

        let firewallMacFilteringsList = result.content

        let firewallMacFilterings = firewallMacFilteringsList[0]

        setFirewallMacFilterings(firewallMacFilterings)
    }
}

export async function saveFirewallMacFiltering(backend, firewallMacFilterings) {

    try{

        let result = null;

        result = await backend.update(`firewall_mac_filtering/${firewallMacFilterings.id}`, firewallMacFilterings)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Firewall Mac Filtering:', error)

        return false
    }

}
import React from 'react'

export default function DeviceIcon({size, color, usePng}) {

    if(usePng) {
        let icon = require('./images/ic-devices.png')
        return <img alt='' src={icon} width={size} height={size}></img>
    }

    return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
		    <g fill="none" fillRule="evenodd">
		        <g>
		            <g transform="translate(-871 -458) translate(871 458)">
		                <rect width="10" height="19" x="5" y="10" stroke={color ? color : "#191C23"} rx="1.431"/>
		                <rect width="5" height="12" x="7.5" y="12.5" stroke={color ? color : "#191C23"} rx="1"/>
		                <circle cx="10" cy="27.25" r="1" fill={color ? color : "#191C23"}/>
		                <circle cx="19" cy="26.5" r="1" fill={color ? color : "#191C23"} stroke={color ? color : "#191C23"} strokeWidth=".5"/>
		                <path stroke={color ? color : "#191C23"} d="M12.5 10V6.528c0-.568.416-1.028.929-1.028H24.57c.513 0 .929.46.929 1.028v16.444c0 .568-.416 1.028-.929 1.028h-9.28"/>
		                <path stroke={color ? color : "#191C23"} d="M10 10V5.167C10 3.97 10.948 3 12.118 3h13.764C27.052 3 28 3.97 28 5.167v21.666C28 28.03 27.052 29 25.882 29h-13.95"/>
		            </g>
		        </g>
		    </g>
		</svg>
	)

}
import React, { useState, useEffect, useContext } from 'react'

import './advanced-global-routing.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchAdvancedGlobalRoutingList } from '../../../resources/advanced-global-routing';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import Button from '../../../../components/button/button';

export default function AdvancedGlobalRouting({isWizard, setSaved, isFormSegment, formSegment}) {
    const [globalRouting, setAdvancedGlobalRouting] = useState(null)

    const [globalRoutingPagination, setAdvancedGlobalRoutingPagination] = useState(null)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {
        setAdvancedGlobalRoutingPagination({pageNumber: 1, totalElements: 0, pageSize: 10})
        fetchAdvancedGlobalRoutingList(backend, setAdvancedGlobalRouting)

        // eslint-disable-next-line
    }, [])
    
    const getColumns = () => {

        return [
            {header: t('common.label.ROUTE'), align: 'center', size: '30px'},
            {header: t('common.label.DESTINATION'), align: 'center'},
            {header: t('common.label.INTERFACE'), align: 'center'},
            {header: t('common.label.METRIC'), align: 'center'},
            {header: t('common.label.MASK'), align: 'center'},
            {header: t('Próximo salto'), align: 'center'},
        ]
    }

    const changePage = page => {
        setAdvancedGlobalRoutingPagination({...globalRoutingPagination, pageNumber: page})
    }

    const getAdvancedGlobalRouting = () => {

        let globalRoutingFiltered = globalRouting

        if(globalRoutingFiltered.length !== globalRoutingPagination.totalElements) {
            globalRoutingPagination.totalElements = globalRoutingFiltered.length
            setAdvancedGlobalRoutingPagination({...globalRoutingPagination})
        }

        let globalRoutingLines = [];

        let begining = (globalRoutingPagination.pageNumber - 1) * (globalRoutingPagination.pageSize)
        let end = begining + globalRoutingPagination.pageSize
        if (end > globalRoutingPagination.totalElements) {
            end = globalRoutingPagination.totalElements
        }

        for(let i = begining; i < end; i++) {
            globalRoutingLines.push([
                parseInt(globalRoutingFiltered[i].id)+1,
                globalRoutingFiltered[i].route?.destination,
                globalRoutingFiltered[i].route?.interfaceID,
                globalRoutingFiltered[i].route?.metric,
                globalRoutingFiltered[i].route?.net_mask,
                globalRoutingFiltered[i].route?.next_hop,
            ]);
        }

        return globalRoutingLines;
    }

    const globalRoutingForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="advanced.global_routing.title.GLOBAL_ROUTING"></Translator></div>
            <div className="advanced-global-routing-list-wrapper">
                { getAdvancedGlobalRouting().length > 0 ?
                    <List 
                        lines={getAdvancedGlobalRouting()}
                        columns={getColumns()}
                    ></List>
                    :
                    <div id='advanced-global-routing-no-forwards'>
                        <div className='info-card noelements-card'>
                            <Translator path="advanced.global_routing.info.NO_FORWARDS"/>
                        </div>
                    </div> 
                }
            </div>
            <Pager
                pageNumber={globalRoutingPagination.pageNumber}
                totalElements={globalRoutingPagination.totalElements}
                pageSize={globalRoutingPagination.pageSize}
                pageChangeFn={changePage}
                pageIndicator={t('common.label.PAGE')}>
            </Pager>
            <Button
                id='button-refresh'
                type='button'
                text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                onClick={async () => {
                    await fetchAdvancedGlobalRoutingList(backend, setAdvancedGlobalRouting)}
                }
            ></Button>

        </React.Fragment>
    }

    return (
        !globalRouting ? <Loading show={true}></Loading> :

        <div id='advanced-global-routing-page' className='with-tabs'>

            { globalRoutingForm() }

        </div>
    )
}

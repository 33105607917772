import React from 'react'

export default function PacketsIcon({size}){
    return <svg height="24" viewBox="0 -3 448 447" width={size} xmlns="http://www.w3.org/2000/svg">
        <path d="m88 108.1875-88-47.382812v109.832031l88 47.382812zm0 0"/>
        <path d="m192 170.636719v-109.832031l-88 47.382812v109.832031zm0 0"/>
        <path d="m183.128906 47.410156-87.128906-46.910156-87.128906 46.910156 87.128906 46.914063zm0 0"/>
        <path d="m448 394.636719v-109.832031l-88 47.382812v109.832031zm0 0"/>
        <path d="m344 332.1875-88-47.382812v109.832031l88 47.382812zm0 0"/>
        <path d="m439.128906 271.410156-87.128906-46.910156-87.128906 46.910156 87.128906 46.914063zm0 0"/>
        <path d="m408 95.410156v68.683594l-8.96875-8.96875-11.3125 11.3125 28.28125 28.292969 28.28125-28.292969-11.3125-11.3125-8.96875 8.96875v-68.683594c-.035156-30.910156-25.085938-55.960937-56-56h-128v16h128c22.082031.027344 39.972656 17.921875 40 40zm0 0"/>
        <path d="m40 351.410156v-68.679687l8.96875 8.96875 11.3125-11.3125-28.28125-28.292969-28.28125 28.292969 11.3125 11.3125 8.96875-8.96875v68.679687c.035156 30.914063 25.085938 55.964844 56 56h128v-16h-128c-22.082031-.023437-39.972656-17.917968-40-40zm0 0"/>
        <path d="m240 223.410156c0 8.839844-7.164062 16-16 16s-16-7.160156-16-16c0-8.835937 7.164062-16 16-16s16 7.164063 16 16zm0 0"/>
    </svg>
}
import React from 'react'
import './numbered-list.css'

export default function NumberedList({title, content}) {
    return (
        <div className='numbered-list'>
            <div className='title'> {title} </div>
            <div className='content-list'>
                {content.map((element, key) => {
                    return (
                        <div className='item' key={key + 1}>
                            <div className='number-item'> {key + 1} </div>
                            <div className='content-item'> {element} </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
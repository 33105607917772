import React, { useEffect, useContext, useState } from 'react'
import Loading from '../../../../components/loading/loading'
import { MainHeaderContext } from '../../../../components/main-header/main-header-state'
import { useHistory, Switch, Route } from 'react-router-dom'
import { BackendContext } from '../../../../backend/backend'
import { fetchDhcpLeaseList } from '../../../resources/dhcp-lease'
import { fetchDhcpServer } from '../../../resources/dhcp-server'
import DhcpLeaseList from './dhcp-lease-list'
import DhcpLeaseAdd from './dhcp-lease-add'

export default function DhcpLeasePage() {

    const [leases, setLeases] = useState(null)
    const [dhcpServer, setDhcpServer] = useState(null)

    const header = useContext(MainHeaderContext)
    const history = useHistory()
    const backend = useContext(BackendContext)

    useEffect(() => {

        header.backRoute.set('/network/lan')

        header.title.set('LAN')

        fetchDhcpLeaseList(backend, setLeases)
        fetchDhcpServer(backend, setDhcpServer)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if(history.location.pathname === '/network/lan/dhcp-lease'){
            fetchDhcpLeaseList(backend, setLeases)
        }

        // eslint-disable-next-line
    }, [history.location.pathname])

    return ((!leases || !dhcpServer) ? <Loading show={true}></Loading> :
            <Switch>

                <Route path='/network/lan/dhcp-lease/add' render={() =>
                    <DhcpLeaseAdd 
                        leases={leases}
                        dhcpServer={dhcpServer}
                    ></DhcpLeaseAdd>
                }></Route>

                <Route path='/network/lan/dhcp-lease/:id' render={() =>
                    <DhcpLeaseAdd
                        leases={leases}
                        dhcpServer={dhcpServer}
                    ></DhcpLeaseAdd>
                }></Route>

                <Route path='/network/lan/dhcp-lease' render={
                    () => <DhcpLeaseList leases={leases} setLeases={setLeases}></DhcpLeaseList>
                }></Route>

            </Switch>
    )

}
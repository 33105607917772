import { backendStatus } from '../../backend/backend'

export const firewallDomainBlockingConstants = {
    MAX_DOMAIN_ELEMENTS: 8,
    MAX_DOMAIN_LEN: 119
}


export async function fetchFirewallDomainBlocking(backend, setFirewallDomainBlocking) {
    let result = await backend.retrieveFresh('firewall_domain_blocking')

    if(result.status === backendStatus.SUCCESS){

        let firewallDomainBlockingList = result.content

        let firewallDomainBlocking = firewallDomainBlockingList[0]

        setFirewallDomainBlocking(firewallDomainBlocking)
    }
}

export async function saveFirewallDomainBlocking(backend, firewallDomainBlocking) {

    try{

        let result = null;

        result = await backend.update(`firewall_domain_blocking/${firewallDomainBlocking.id}`, firewallDomainBlocking)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Firewall Domain Blocking:', error)

        return false
    }

}
import React from 'react'

export default function CircledArrow({size}) {

    return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <g stroke="#78909C" strokeWidth="1.5">
                <g>
                    <g>
                        <g>
                            <g transform="translate(-640 -176) translate(640 120) translate(0 56)">
                                <g strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M0.279 6.24L2.847 8.807" transform="translate(9.25 7.5)"/>
                                    <path d="M2.847 6.24L5.414 8.807" transform="translate(9.25 7.5) matrix(-1 0 0 1 8.26 0)"/>
                                    <path d="M2.9 0L2.9 7.8" transform="translate(9.25 7.5)"/>
                                </g>
                                <circle cx="12" cy="12" r="11.25"/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}
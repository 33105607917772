import { backendStatus } from '../../backend/backend'

export const DTMFRelayOptions = [
    {value: 0 , text: 'RFC2833'},
    {value: 1 , text: 'SIP INFO'},
    {value: 2 , text: 'InBand'},
    {value: 3 , text: 'DTMF delete'},
    // {value: 4 , text: 'VOIP_DTMF_RELAY_END'}
]

export const HookFlashRelayOptions = [
    {value: 0 , text: 'None'},
    {value: 1 , text: 'SIP INFO'}
]

export const advancedSipConstants = {
    MIN_GAIN: -32,
    MAX_GAIN: 31,
    MAX_AGC: 9
}

export async function fetchVoipAdvancedSip(id, backend, setVoipAdvancedSip) {
    let result = await backend.retrieveFresh(`voip_advanced_sip/${id}`)
    if(result.status === backendStatus.SUCCESS){
        let voipAdvancedSip = result.content
        setVoipAdvancedSip(voipAdvancedSip)
        return voipAdvancedSip
    }
}

export async function fetchVoipAdvancedSipList(backend, setVoipAdvancedSipList) {
    let result = await backend.retrieveFresh('voip_advanced_sip')

    if(result.status === backendStatus.SUCCESS){

        let voipAdvancedSipList = result.content

        setVoipAdvancedSipList(voipAdvancedSipList)
    }
}

export async function saveVoipAdvancedSip(backend, voipAdvancedSip) {

    try{

        let result = null;

        delete voipAdvancedSip['voip_port'];

        result = await backend.update(`voip_advanced_sip/${voipAdvancedSip.id}`, voipAdvancedSip)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Voip Advanced Sip:', error)

        return false
    }

}
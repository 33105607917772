import React, { useState, useEffect, useContext } from 'react'

import './connected-devices.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchConnectedDevicesList, fetchConnectedDevice } from '../../../resources/connected-devices';
import { radioOpMode } from '../../../resources/radio';
import Loading from '../../../../components/loading/loading';
import { useTranslation } from 'react-i18next';
import Pager from '../../../../components/pager/pager';
import InfoIcon from '../../../../components/icons/info-icon'
import Modal from '../../../../components/modal/modal'
import InfoCard from '../../../../components/infocard/infocard'
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button'
import DeviceIcon from '../../../../components/icons/device'

export default function ConnectedDevices({ isWizard, setSaved, isFormSegment, formSegment }) {
    let [deviceDetails, setDeviceDetails] = useState(false)
    const [deviceInfos, setDeviceInfos] = useState(null)
    const [connectedDevices, setConnectedDevices] = useState(null)
    const [connectedDevice, setConnectedDevice] = useState(null)
    const [enableButtonRefresh, setEnableButtonRefresh] = useState(true)

    const [connectedDevicesPagination, setConnectedDevicesPagination] = useState(null)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()
    // const history = useHistory()

    useEffect(() => {
        setConnectedDevicesPagination({ pageNumber: 1, totalElements: 0, pageSize: 10 })
        fetchConnectedDevicesList(backend, setConnectedDevices)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (connectedDevice) {
            setDeviceInfos(prevState => ({
                ...prevState,
                time: connectedDevice.time,
                rxRate: connectedDevice.rxRate,
                signalLevel: connectedDevice.signalLevel,
                txRate: connectedDevice.txRate
            }));
            setEnableButtonRefresh(true);
        }

        // eslint-disable-next-line
    }, [connectedDevice])

    const changePage = page => {
        setConnectedDevicesPagination({ ...connectedDevicesPagination, pageNumber: page })
    }

    const getConnectedDevices = () => {

        let connectedDevicesFiltered = connectedDevices

        if (connectedDevicesFiltered.length !== connectedDevicesPagination.totalElements) {
            connectedDevicesPagination.totalElements = connectedDevicesFiltered.length
            setConnectedDevicesPagination({ ...connectedDevicesPagination })
        }

        let begining = (connectedDevicesPagination.pageNumber - 1) * (connectedDevicesPagination.pageSize)
        let end = begining + connectedDevicesPagination.pageSize
        if (end > connectedDevicesPagination.totalElements) {
            end = connectedDevicesPagination.totalElements
        }

        let reducedConnectedDevices = connectedDevicesFiltered.slice(begining, end);

        var counter = 1
        for (var i = 0; i < reducedConnectedDevices.length; i++) {
            if (reducedConnectedDevices[i].hostname === "") {
                reducedConnectedDevices[i].hostname = t('network.mesh.label.DEVICE_FIRSTUP') + " " + (counter++)
            }
        }

        return reducedConnectedDevices;
    }

    const getIPv6List = (ip6_list) => {
        let addresses = []

        for (var i = 0; i < ip6_list.length; i++) {
            addresses.push({ label: ip6_list[i] })
        }

        return addresses
    }

    const bandStrGen = (band, frequency) => {
        let f_str = ''
        if (frequency === 0)
            f_str = '2,4 GHz'
        else
            f_str = '5 GHz'

        var ret_str = ''
        switch (band) {
            case radioOpMode.A:
                return ret_str.concat(f_str, ' ', '(A)')
            case radioOpMode.B:
                return ret_str.concat(f_str, ' ', '(B)')
            case radioOpMode.G:
                return ret_str.concat(f_str, ' ', '(G)')
            case radioOpMode.N:
                return ret_str.concat(f_str, ' ', '(N)')
            case radioOpMode.BGN:
                return ret_str.concat(f_str, ' ', '(B+G+N)')
            case radioOpMode.AC:
                return ret_str.concat(f_str, ' ', '(AC)')
            case radioOpMode.ANAC:
                return ret_str.concat(f_str, ' ', '(A+N+AC)')
            case radioOpMode.AX:
                return ret_str.concat(f_str, ' ', '(B+G+N+AX)')
            case radioOpMode.ANACAX:
                return ret_str.concat(f_str, ' ', '(A+N+AC+AX)')
            case radioOpMode.BG:
                return ret_str.concat(f_str, ' ', '(B+G)')
            case radioOpMode.AN:
                return ret_str.concat(f_str, ' ', '(A+N)')
            case radioOpMode.NAC:
                return ret_str.concat(f_str, ' ', '(N+AC)')
            case radioOpMode.GN:
                return ret_str.concat(f_str, ' ', '(G+N)')
            default:
                return '--'
        }
    }

    const deviceDetailsModal = () => {
        return deviceInfos && <Modal
            modalID='device-details'
            show={deviceDetails}
            onOverlayClick={() => setDeviceDetails(false)}
            dismissText={t('common.label.BACK')}
            onDismissClick={

                () => {
                    setDeviceDetails(() => { return false })
                }
            }
            content={<div className='device-modal-details'>
                <div className='device-title'>
                    <label>{deviceInfos.hostname}</label>
                </div>
                <div className='device-modal-content'>
                    <InfoCard
                        lines={[
                            { label: t('parental_control.connected_devices.MAC'), value: deviceInfos.mac, id: 'card-device-mac' },
                            { label: t('parental_control.connected_devices.IP'), value: deviceInfos.ip, id: 'card-device-ip' },
                            { label: t('parental_control.connected_devices.IS_DMZ'), value: deviceInfos.isDMZ ? t('modal.answer.YES') : t('modal.answer.NO'), id: 'card-device-is-dmz' },
                            { label: t('parental_control.connected_devices.IS_STATIC_IP'), value: deviceInfos.isStaticIP ? t('modal.answer.YES') : t('modal.answer.NO'), id: 'card-device-is-static-ip' }
                        ]}
                    ></InfoCard>

                    {deviceInfos.network !== '' && <InfoCard
                        lines={[
                            { label: t('parental_control.connected_devices.NETWORK'), value: deviceInfos.network, id: 'card-device-network' },
                            { label: t('parental_control.connected_devices.OPMODE'), value: bandStrGen(deviceInfos.opMode, deviceInfos.frequency), id: 'card-device-op-mode' },
                            { label: t('parental_control.connected_devices.SIGNAL_LEVEL'), value: deviceInfos.signalLevel, id: 'card-device-signal-level' },
                            { label: t('parental_control.connected_devices.TIME'), value: (deviceInfos.time === 0 ? "--" : deviceInfos.time), id: 'card-device-time' },
                            { label: t('parental_control.connected_devices.RXRATE'), value: deviceInfos.rxRate, id: 'card-device-rx-rate' },
                            { label: t('parental_control.connected_devices.TXRATE'), value: deviceInfos.txRate, id: 'card-device-tx-rate' }
                        ]}
                    ></InfoCard>}

                    {deviceInfos.network === '' && <InfoCard
                        lines={[
                            { label: t('parental_control.connected_devices.NETWORK'), value: deviceInfos.network === '' ? "Cabeado" : deviceInfos.network, id: 'card-device-network' },
                            { label: t('parental_control.connected_devices.TIME'), value: (deviceInfos.time === 0 ? "--" : deviceInfos.time), id: 'card-device-time' }
                        ]}
                    ></InfoCard>}

                    {deviceInfos.ip6_count !== 0 && <div className='device-subtitle'>
                        <label>{t('parental_control.connected_devices.IPV6_LIST')}</label>
                    </div>}

                    {deviceInfos.ip6_count !== 0 && <InfoCard
                        lines={[
                            ...getIPv6List(deviceInfos.ip6_list)
                        ]}
                    ></InfoCard>}

                    <Button
                        id='button-refresh'
                        type='button'
                        disabled={!enableButtonRefresh}
                        text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                        onClick={() => {
                            fetchConnectedDevice(backend, deviceInfos.id, setConnectedDevice)
                            setEnableButtonRefresh(false)
                        }}
                    ></Button>
                </div>
            </div >}
        ></Modal >
    }

    const getDeviceElement = (device, key) => {

        return (
            <div className='device-list-item' key={key} onClick={() => {
                setDeviceInfos(device)
                setDeviceDetails(true)
            } /*history.push(`/parental-control/devices/${device.mac}/block`) */}>
                <div className='device-item-image'>
                    <DeviceIcon size='35px' color='var(--itb-green)'></DeviceIcon>
                </div>

                <div className='device-item-info'>
                    <div className='device-item-hostname'>{device.hostname}</div>

                    <div className='device-info'>
                        <div className='device-info-item'>
                            <label>{t('network.lan.label.MAC_ADDRESS')}:</label>
                            <span>{device.mac}</span>
                        </div>
                        <div className='device-info-item'>
                            <label>{t('network.label.NETWORK')}:</label>
                            <span>{device.network ? device.network : "Cabeada"}</span>
                        </div>
                    </div>
                </div>

                <div className='device-action'>
                    <div className='device-action-icon'>
                        <InfoIcon size={24}></InfoIcon>
                    </div>
                </div>
            </div>
        )
    }

    return (
        !connectedDevices ? <Loading show={true}></Loading> :
            <div className='parental-control-connected-device-page'>
                {deviceDetailsModal()}
                <div className='subtitle'>{t('parental_control.connected_devices.DEVICE_LIST')}</div>
                {connectedDevices.length === 0 ? <div className='info-text card'>{t('parental_control.connected_devices.NO_DEVICES_CONNECTED')}</div> :
                    <div>
                        {
                            getConnectedDevices(connectedDevices).map((device, key) => {
                                return <div id={`device-${key}`} key={key} className='list-device'>
                                    {getDeviceElement(device, key)}
                                </div>
                            })
                        }

                        <Pager
                            pageNumber={connectedDevicesPagination.pageNumber}
                            totalElements={connectedDevicesPagination.totalElements}
                            pageSize={connectedDevicesPagination.pageSize}
                            showTotalPages={true}
                            showSelector={true}
                            pageChangeFn={changePage}>
                        </Pager>

                        {/* <Button
                            id='btn-all-devices'
                            text='Ver todas as regras de controle para dispositivos'
                            onClick={() => history.push(`/parental-control/devices/all/block`)}
                        ></Button> */}
                    </div>
                }
            </div>
    )
}

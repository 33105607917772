import { backendStatus } from '../../backend/backend'

export async function fetchVoipCodec(id, backend, setVoipCodec) {
    let result = await backend.retrieveFresh(`voip_codec/${id}`)

    if(result.status === backendStatus.SUCCESS){

        let voipCodec = result.content

        setVoipCodec(voipCodec)
    }
}

export async function fetchVoipCodecList(backend, setVoipProxyList) {
    let result = await backend.retrieveFresh('voip_codec')

    if(result.status === backendStatus.SUCCESS){

        let voipProxyList = result.content

        setVoipProxyList(voipProxyList)
    }
}

export async function saveVoipCodec(backend, voipCodec) {

    try{

        let result = null;

        result = await backend.update(`voip_codec/${voipCodec.id}`, voipCodec)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving VOIP Codec:', error)

        return false
    }

}
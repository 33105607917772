import React, { useState, useEffect, useContext } from 'react'

import './ipv6-static-route.css'
import { BackendContext } from '../../../../backend/backend';
import { fetchIpv6StaticRoute, deleteIpv6StaticRoute } from '../../../resources/ipv6-static-route';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import RemoveIcon from '../../../../components/icons/remove';
import EditIcon from '../../../../components/icons/edit';
import Button from '../../../../components/button/button';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import { IPv6Context } from '../ipv6-context';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { fetchServiceSystem } from '../../../resources/system';
import { GlobalsContext } from '../../../globals-context'
import waitRouter from '../../../utils/wait-router'
import { createLimitedTableCell } from '../../../utils/common';

export default function Ipv6StaticRouteEdit ({isWizard, isFormSegment, formSegment}) {

    const IPV6_STRING_MAX_LENGTH = 25

    const [ipv6StaticRoutePagination, setIpv6StaticRoutePagination] = useState({pageNumber: 1, pageSize: 10})
    const { ipv6StaticRouteElementsList, setIpv6StaticRouteElementsList } = useContext(IPv6Context)    
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleteID, setDeleteID] = useState(null)
    const backend = useContext(BackendContext)
    const history = useHistory()
    const { t } = useTranslation()
    const globals = useContext(GlobalsContext)

    let [system, setSystem] = useState(true)

    useEffect(() => {
        fetchIpv6StaticRoute(backend, setIpv6StaticRouteElementsList)
        fetchServiceSystem(backend, setSystem)
        // eslint-disable-next-line
    }, [])

    const editRoute = (element) => {
        history.push(`/ipv6/ipv6-static-route/${element.id}`)
    }

    const getColumns = () => {
        return [
            {header: 'Nº', align: 'center', size: '60px'},
            {header: t('common.label.ENABLE'), align: 'center', size: '150px' },
            {header: t('ipv6.ipv6_static_route.label.DESTINATION'), align: 'center', size: '250px' },
            {header: t('ipv6.ipv6_static_route.label.NEXT_HOP'), align: 'center', size: '250px' },
            {header: t('ipv6.ipv6_static_route.label.METRIC'), align: 'center', size: '100px' },
            {header: t('ipv6.ipv6_static_route.label.INTERFACE'), align: 'center', size: '120px' },
            {header: t('common.label.ACTIONS'), align: 'center', size: '120px'},
        ]
    }

    const removeRouteHandler = async(ipv6StaticRoute) => {
        setDeleteID(ipv6StaticRoute.id)
        setConfirmDelete(true)
    }

    const getIpv6StaticRouteLineElements = () => {
        let begining = (ipv6StaticRoutePagination.pageNumber - 1) * (ipv6StaticRoutePagination.pageSize)
        let end = begining + ipv6StaticRoutePagination.pageSize
        if (end > ipv6StaticRouteElementsList.length) {
            end = ipv6StaticRouteElementsList.length
        }
        
        let ipv6StaticRouteLines = [];
        for(let i = begining;  i < end; i++) {
                if(ipv6StaticRouteElementsList[i]){ 
                ipv6StaticRouteLines.push(
                    [
                        i + 1,
                        ipv6StaticRouteElementsList[i].enable ? t('ipv6.ipv6_static_route.label.ENABLED') : t('ipv6.ipv6_static_route.label.DISABLED'),
                        <div>{createLimitedTableCell(`${ipv6StaticRouteElementsList[i].destination}`, IPV6_STRING_MAX_LENGTH)}</div>,
                        <div>{createLimitedTableCell(`${ipv6StaticRouteElementsList[i].next_hop}`, IPV6_STRING_MAX_LENGTH)}</div>,
                        ipv6StaticRouteElementsList[i].metric,
                        ipv6StaticRouteElementsList[i].interface,
                        <div>
                            <span className="clickable" onClick={e => editRoute(ipv6StaticRouteElementsList[i])}><EditIcon/></span>
                            <span style={{ paddingInline: 4 }}/>
                            <span className="clickable" onClick={e => removeRouteHandler(ipv6StaticRouteElementsList[i])}><RemoveIcon/></span>
                        </div>
                    ]
                );
            }
        }
        return ipv6StaticRouteLines;
    }

    const showIpv6StaticRouteList = () => {

        return <React.Fragment>
            <dir className="ipv6-static-route-list-wrapper">
                <List className="align-center"
                    lines={getIpv6StaticRouteLineElements()} 
                    columns={getColumns()}
                ></List>
            </dir>
            <Pager 
                pageNumber={ipv6StaticRoutePagination.pageNumber}
                totalElements={ipv6StaticRouteElementsList.length}
                pageSize={ipv6StaticRoutePagination.pageSize}
                pageChangeFn={changePage}>
            </Pager>
        </React.Fragment>
    }

    const changePage = page => {
        setIpv6StaticRoutePagination({...ipv6StaticRoutePagination, pageNumber: page})
    }

    const createNewIpv6StaticRouteForm = () => {
        history.push(`/ipv6/ipv6-static-route/add`)
    }

    const continueDelete = async (confirm) => {
        if (success) {
            setSuccess(false)
            return
        }

        if (error) {
            setError(false)
            return
        }

        setConfirmDelete(false)

        if (confirm) {
            setSaving(true)
            await deleteIpv6StaticRoute(backend, deleteID, setIpv6StaticRouteElementsList)
            waitReturn()
        }

        setDeleteID(null)
    }

    const waitReturn = async () => {

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        setSaving(false)
        setSuccess(true)
    }


    const hasIPv6Requirements = () => {
        if(isWizard || system === true) {
            return true
        }

        if(system) {
            if(system.ipv6) {
                if(system.ipv6.enabled) {
                    return true
                }
            }
        }

        return false
    }

    return (!ipv6StaticRouteElementsList ? <Loading show={true}></Loading> :
            
        <div id='ipv6-static-route-page' className='with-tabs'>
            <DefaultStatusModals
                saving={saving}
                success={success}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                confirm={confirmDelete}
                continueFn={continueDelete}
                confirmContent={
                    <div style={{ width: '420px' }}>
                        <span style={{ display: 'block' }}><Translator path="common.message.warning.PERMANENT_DELETE_WARNING"></Translator>
                            <br></br>
                        </span>
                        <b><Translator path="common.message.warning.CONFIRM_EXCLUSION"></Translator></b>
                    </div>
                }
            ></DefaultStatusModals>
            
        {
            hasIPv6Requirements() ? null : 
            <div>
                <p className='ipv6-static-route-info'>
                    {t(`ipv6.ipv6_static_route.message.IPV6_DISABLED`)}
                    <br/>
                    {t(`common.message.warning.IPV6_ENABLE`)}
                    <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                </p>
            </div>
        }   
            <div className='section-divider'></div>
                <div className='subtitle'> 
                    <Translator path="ipv6.ipv6_static_route.title.STATIC_ROUTE"/>
                </div>
                {ipv6StaticRouteElementsList.length === 0 ? 
                    <div id='ipv6-static-route-no-rules'>
                        <div className='info-card noelements-card'>
                            <Translator path="ipv6.ipv6_static_route.info.NO_ROUTES"/>
                        </div>
                    </div>
                :
                    showIpv6StaticRouteList()
                }
            {
            hasIPv6Requirements() ? (
            <Button
                id='new_ipv6_static_route'
                text={t('ipv6.ipv6_static_route.label.NEW_ROUTE')}
                onClick={e => createNewIpv6StaticRouteForm()}
            ></Button>
            ) : ''}
        </div>
    );
}
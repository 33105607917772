import React, { useContext, useEffect, useState } from 'react'

import Loading from '../../../../components/loading/loading'
import { MeshContext } from './mesh-context'
import Modal from '../../../../components/modal/modal'
import { ModalTypes } from '../../../../components/modal/modal'


import List from '../../../../components/list/list'
import { easyMeshType, easyMeshBackhaul } from '../../../resources/easy-mesh'
import commonUtils from '../../../utils/common-utils'
import SignalIcon from '../../../../components/icons/signal'
import { useHistory } from 'react-router'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import { BackendContext } from '../../../../backend/backend'
import macutils from '../../../utils/macutils'
import Button from '../../../../components/button/button'
import { ReactComponent as GenericRouterImg } from '../../../../images/router-bootstrap.svg'
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator'
import { NetworksContext } from '../networks-context'
import { fetchWirelessList, reduceWireless } from '../../../resources/wireless'
import { fetchRadioList } from '../../../resources/radio'
import Pager from '../../../../components/pager/pager'

const EASYMESH_SYNCING_RUNNING = 1

const deletingNodeContent = (getMeshTranslation) => {
    return <div>
        {getMeshTranslation('message.info', 'WAIT_WHILE_WE_DELETE_NODE')}
        <br></br>
        {getMeshTranslation('message.info', 'THIS_PROCEDURE_CAN_TAKE_SEVERAL_MINUTES')}
    </div>
}

export default function DashboardMesh({ device, lan, rootConnectedDevices }) {

    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [confirmContent, setConfirmContent] = useState(false)
    const [nodeToDelete, setNodeToDelete] = useState(null)
    const [devicesList, setDevicesList] = useState(null)
    const [mounted, setMounted] = useState(false)
    const [showSyncingModal, setShowSyncingModal] = useState(true)
    const [nodesPagination, setNodesPagination] = useState(
        {
            pageNumber: 1,
            totalElements: 0,
            pageSize: 10
        }
    )

    const mesh = useContext(MeshContext)
    const backend = useContext(BackendContext)
    const history = useHistory()
    const networks = useContext(NetworksContext)

    const { t } = useTranslation()

    //Retrieve EasyMesh data
    useEffect(() => {
        mesh.retrieveEasyMesh()

        fetchWirelessList(backend, networks.wifi.set)
        fetchRadioList(backend, networks.radio.set)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {

        if (!networks.wifi.value || !networks.radio.value) return

        networks.reducedWifi.set(reduceWireless(networks.wifi.value, networks.radio.value))

        // eslint-disable-next-line
    }, [networks.wifi.value, networks.radio.value])

    // Retrieve devices list
    useEffect(() => {
        if (!mesh.easyMesh || !mesh.meshNodes || mounted) return
        mesh.retrieveDevicesList(setDevicesList)
        setMounted(true)

        // eslint-disable-next-line
    }, [mesh.meshNodes])

    // Delete device warning
    const handleDeleteNode = (key) => {
        setNodeToDelete(mesh.meshNodes[key - 1])
        setConfirmContent(mesh.meshNodes[key - 1].is_online ? mesh.confirmDeleteNodeContent() :
            mesh.confirmDeleteOfflineNodeContent())
        setConfirm(true)
    }

    // Delete device
    const continueDelete = async (confirmed) => {
        const TIME_TO_START_RESET = 60000 // 1 minute
        setConfirm(false)
        setError(false)

        if (confirm && confirmed) {
            setSaving(true)

            // If the device is online we proceed a factory reset
            if (nodeToDelete.is_online) {
                const result = await mesh.resetFactoryDefault(macutils.getDeviceIdFromMac(nodeToDelete.mac))
                if (!result) {
                    setSaving(false)
                    setError(true)
                    return
                }
            }

            setTimeout(async () => {
                await mesh.retrieveEasyMesh()
                setSaving(false)
            }, nodeToDelete.is_online ? TIME_TO_START_RESET : 0)

        }
    }

    const getRootConnectedDevicesNumber = () => {
        if (!rootConnectedDevices) return 0
        if (!mesh.meshNodes) return rootConnectedDevices.length

        return rootConnectedDevices.filter(device => {
            const network = device.network.split(" - ")[0]
            for (let i = 0; i < mesh.meshNodes.length; i++) {
                if (network === mesh.meshNodes[i].mac) return false
            }
            return true
        }).length
    }

    const getConnectedDevicesInNode = (mac) => {
        if (!rootConnectedDevices) return 0

        return rootConnectedDevices.filter(device => device.network.split(" - ")[0] === mac).length
    }

    const getDeviceIcon = (model, getMeshTranslation) => {
        switch (model) {
            case "":
                return <GenericRouterImg alt='offline' className='mesh-list-icon-offline' />
            case "W5-1200F":
            case "W5-1200G":
            case "GF1200":
            case "Twibi":
            case "Twibi Plug 1200F":
            case "Twibi Force W5-2100G":
            default:
                return <GenericRouterImg alt={getMeshTranslation('label', 'ROUTER')} className='mesh-list-icon' />
        }
    }

    const getNodes = (getMeshTranslation) => {
        const nRootDevices = getRootConnectedDevicesNumber()
        let lines = [[
            <div className="node-title-container">
                {getDeviceIcon(device.model, getMeshTranslation)}
                <div>
                    <div className="node-title">{device.alias}</div>
                    <div className={`node-subtitle ${mesh.easyMesh.type === easyMeshType.CONTROLLER ? 'controller' : ''}`}>
                        {mesh.easyMesh.type === easyMeshType.CONTROLLER ? getMeshTranslation('label', 'MAIN_GATEWAY') : getMeshTranslation('label', 'SECONDARY')}
                    </div>
                </div>
            </div>,
            mesh?.easyMesh?.controllerIp ? mesh.easyMesh.controllerIp : '-',
            mesh?.easyMesh?.backhaul_mac ? mesh.easyMesh.backhaul_mac : '-',
            '-',
            '-',
            '-',
            `${nRootDevices} dispositivo${nRootDevices === 1 ? '' : 's'}`
        ]]

        lines.push(...mesh.meshNodes.map((node, index) => {
            const name = devicesList[index] && <div className="node-title-container">
                {getDeviceIcon(devicesList[index].model, getMeshTranslation)}
                <div>
                    <div className="node-title">{node.name ? node.name : '-'}</div>
                    <div className="node-subtitle">{getMeshTranslation('label', 'SECONDARY')}</div>
                </div>
            </div>
            const ip = node?.ip !== '' ? node.ip : '-'
            const mac = node?.mac !== '' ? node.mac : '-'
            const signal = !node.is_online ? "Offline" : <div className="mesh-device-signal">
                <SignalIcon signal_level={node.rssi} size='16' moderate_signal_threshold={-66} bad_signal_threshold={-77}></SignalIcon>
                <span>
                    {node.rssi === -1 || node.backhaul === easyMeshBackhaul.ETH ? getMeshTranslation('label', 'EXCELENT_CABLE') :
                        `${commonUtils.getSignalQuality(node.rssi)} (${(node.rssi)}dBm)`}
                </span>
            </div>
            const parent = !node.is_online ? '-' :
                node.parent.type === easyMeshType.CONTROLLER ? getMeshTranslation('label', 'MAIN_NODE') :
                    node.parent.type === easyMeshType.AGENT ? getMeshTranslation('label', 'CURRENT_NODE') :
                        node.parent.name

            const backhaulType = (node.backhaul === easyMeshBackhaul.AUTO ? 'Auto' :
                node.backhaul === easyMeshBackhaul.ETH ? 'Cabeado' :
                    node.backhaul === easyMeshBackhaul._2_4GHZ ? '2.4GHz' :
                        node.backhaul === easyMeshBackhaul._5_0GHZ ? '5GHz' : '')

            let nDevicesConnected
            if (!node.is_online) {
                nDevicesConnected = '-'
            } else {
                nDevicesConnected = getConnectedDevicesInNode(node.mac)
                nDevicesConnected = `${nDevicesConnected} ${getMeshTranslation('label', 'DEVICE')}${nDevicesConnected === 1 ? '' : 's'}`
            }

            return name ? [name, ip, mac, signal, parent, backhaulType, nDevicesConnected] : null
        }))
        lines = lines.filter(line => line)
        return lines
    }

    const hasWPSRequirements = () => {
        // If there is no wifis networks set Easy Mesh will not work
        if (!networks.reducedWifi || !networks.reducedWifi.value) {
            return false
        }

        // WPS in root SSIDs must be enabled in order to add new routers
        return networks.reducedWifi.value.some(wifi => {
            return (wifi.isRoot && wifi.wps === true)
        })
    }

    const getMeshTranslation = (type, key) => t(`network.mesh.${type}.${key}`)

    const changePage = page => {
        setNodesPagination({ ...nodesPagination, pageNumber: page })
    }

    const getNodesPerPage = (getMeshTranslation) => {

        let nodesFiltered = getNodes(getMeshTranslation)

        if (nodesFiltered.length !== nodesPagination.totalElements) {
            nodesPagination.totalElements = nodesFiltered.length
            setNodesPagination({ ...nodesPagination })
        }

        if (nodesFiltered.length > 0) {
            let begining = (nodesPagination.pageNumber - 1) * (nodesPagination.pageSize)
            let end = begining + nodesPagination.pageSize
            if (end > nodesPagination.totalElements) {
                end = nodesPagination.totalElements
            }

            return nodesFiltered.slice(begining, end);
        }
    }

    return (
        <div id='dashboard-mesh-container'>

            <DefaultStatusModals
                modalID='mesh-device'
                saving={saving}
                error={error}
                confirm={confirm}
                isWarningModal={true}
                continueFn={continueDelete}
                confirmContent={confirmContent}
                savingText={nodeToDelete && nodeToDelete.is_online ? deletingNodeContent(getMeshTranslation) : null}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>
            <Modal
                show={showSyncingModal && mesh.easyMesh.syncing === EASYMESH_SYNCING_RUNNING}
                content={
                    <div>
                        <span><Translator path="network.mesh.message.info.DEVICE_SYNCING_WARNING"></Translator></span>
                        <br />
                        <span><Translator path="network.mesh.message.info.DEVICE_SYNCING_WAIT"></Translator></span>
                    </div>
                }
                modalID="easymesh-syncing-info"
                dismissText={t('modal.answer.UNDERSTOOD')}
                onDismissClick={() => { setShowSyncingModal(false) }}
                type={ModalTypes.INFO}
            ></Modal>

            <div className="subtitle">
                <label>{getMeshTranslation('label', 'MY_INMESH_NETWORK')}</label>
            </div>

            {!mesh.easyMesh || !mesh.meshNodes || !devicesList ? <Loading show={true} small></Loading> :
                mesh.meshNodes.length === 0 && mesh.easyMesh.type === easyMeshType.CONTROLLER ?
                    <div className="align-center">
                        <GenericRouterImg id='mesh-controller-empty-dashboard' alt={getMeshTranslation('label', 'ROUTER')} />
                        <div className='mesh-no-device-title'>
                            {getMeshTranslation('message.info', 'NO_ROUTER_ADDED')}
                        </div>
                        <div className='mesh-no-device-subtitle'>
                            {getMeshTranslation('message.info', 'NO_ROUTER_ADDED_AT_THIS_MOMENT')}
                            <br />
                            {getMeshTranslation('message.info', 'AMPLIFY_YOUR_COVERAGE_ARE_CLICK_BUTTON_BELOW')}
                        </div>
                        {
                            !hasWPSRequirements() ?
                                <div>
                                    <p className='mesh-wps-disabled'>
                                        <br />
                                        {getMeshTranslation('message.info', 'WPS_DISABLED')}
                                        <br />
                                        <a href='#/network/wifi/'>{getMeshTranslation('message.info', 'CONFIG_WIRELESS_NETWORK')}</a>
                                    </p>
                                </div>
                                :
                                ''
                        }
                        <Button
                            id='btn-add-mesh-no-nodes'
                            text={getMeshTranslation('label', 'NEW_ROUTER')}
                            onClick={() => {
                                history.push('/network/mesh/onboarding')
                            }}
                            disabled={mesh.easyMesh.syncing === EASYMESH_SYNCING_RUNNING || !hasWPSRequirements()}
                        ></Button>
                    </div>
                    :
                    <>
                        <div className="mesh-nodes-table">
                            <List
                                width={'1500px'}
                                aux_id='mesh'
                                extendedLines
                                columns={[
                                    { header: getMeshTranslation('label', 'ROUTER'), align: 'left', size: "250px" },
                                    { header: getMeshTranslation('label', 'IP'), align: 'left', size: "150px" },
                                    { header: 'MAC', align: 'left', size: "200px" },
                                    { header: getMeshTranslation('label', 'SIGNAL'), align: 'left', size: "200px" },
                                    { header: getMeshTranslation('label', 'CONNECT_WITH'), align: 'left' },
                                    { header: getMeshTranslation('label', 'PHYSICAL_ENV'), align: 'left' },
                                    { header: getMeshTranslation('label', 'CONNECTED_DEVICES'), align: 'left' }
                                ]}
                                lines={getNodesPerPage(getMeshTranslation)}
                                options={[
                                    {
                                        show: () => true, name: getMeshTranslation('label', 'VIEW'), action: (key) => {
                                            if (key === 0) {
                                                history.push(`/network/mesh/device/${lan.mac}`)
                                            } else {
                                                history.push(`/network/mesh/device/${mesh.meshNodes[key - 1].mac}`)
                                            }
                                        }
                                    },
                                    {
                                        show: (key) => key !== 0 && mesh.easyMesh.type === easyMeshType.CONTROLLER,
                                        name: getMeshTranslation('label', 'DELETE_NODE'),
                                        action: handleDeleteNode,
                                    }
                                ]}
                                verticalOptions={true}
                            ></List>
                        </div>
                        <Pager
                            pageNumber={nodesPagination.pageNumber}
                            totalElements={nodesPagination.totalElements}
                            pageSize={nodesPagination.pageSize}
                            pageChangeFn={changePage}>
                        </Pager>

                        {mesh.meshNodes.length > 0 &&
                            <Button
                                text={mesh.easyMesh.syncing === 1 ? getMeshTranslation('message.info', 'SYNCING') : getMeshTranslation('label', 'ADD_ROUTER')}
                                onClick={() => history.push('/network/mesh/onboarding')}
                                disabled={!(mesh.easyMesh && mesh.easyMesh.type === easyMeshType.CONTROLLER && mesh.meshNodes.length > 0 &&
                                    mesh.easyMesh.syncing !== EASYMESH_SYNCING_RUNNING)}
                            >
                            </Button>
                        }
                    </>
            }
        </div>
    )
}
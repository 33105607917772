import { backendStatus } from '../../backend/backend'



export async function fetchDatetime(backend, setDatetime) {
    let result = await backend.retrieveFresh('datetime')
    if(result.status === backendStatus.SUCCESS){
        if(!result.content[0].timezone_list.some(dtz => dtz.id === parseInt(result.content[0].timezone))) {
            result.content[0].timezone = result.content[0].timezone_list[0].id + ''
        }

        result.content[0].ntp_server.automatic = false;
        if (setDatetime) {
            setDatetime(result.content[0])
            return true
        }
        return result.content[0]
    }

    return false
}


export async function updateDatetime(backend, datetime, setErrorMessage) {

    try {
        let result = await backend.update(`datetime/${datetime.id ? datetime.id : 0}`, datetime)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS
        
    }catch(error){
        console.error('Error updating datetime:', error)
        return false
    }
}
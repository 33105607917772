import { backendStatus } from '../../backend/backend'

const URL = 'rip_config';

export async function fetchServiceRipConfig(backend, id, setRipConfig) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setRipConfig(result.content)
    }
    
}

export async function fetchServiceRipConfigList(backend, setRipConfigList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setRipConfigList(result.content)
    }
}

export async function deleteServiceRipConfigById(backend, id, setRipConfigList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchServiceRipConfigList(backend, setRipConfigList)
        return true
    }

    return false
}

export async function saveServiceRipConfig(backend, ripConfig, setErrorMessage) {

    try{

        let result = null;

        if(!ripConfig.id) {
            result = await backend.create(`${URL}`, ripConfig)
        }
        else {
            result = await backend.update(`${URL}/${ripConfig.id}`, ripConfig)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || ripConfig)

    }catch(error){

        console.error('Error saving RIP_CONFIG:', error)

        return false
    }

}
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../../../components/button/button'
import CollapseBox from '../../../../components/collapsebox/collapse-box'
import InfoCard from '../../../../components/infocard/infocard'
import Loading from '../../../../components/loading/loading'
import { MainHeaderContext } from '../../../../components/main-header/main-header-state'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'
import Modal from '../../../../components/modal/modal'
import { GlobalsContext } from '../../../globals-context'
import { MeshContext } from './mesh-context'
import { easyMeshType } from '../../../resources/easy-mesh'
import commonUtils from '../../../utils/common-utils'
import macutils from '../../../utils/macutils'
import timeUtils from '../../../utils/time-utils'

import './mesh.css'
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator'

const deletingNodeContent = (getMeshTranslation) => {
    return <div>
        {getMeshTranslation('message.info', 'WAIT_A_MINUTE_PLEASE')}
        <br></br>
        {getMeshTranslation('message.info', 'YOU_ARE_GOING_TO_BE_REDIRECTED_TO_LOGIN')}
    </div>
}

export default function MeshDevicePage() {

    const params = useParams()
    const history = useHistory()

    const meshContext = useContext(MeshContext)
    const header = useContext(MainHeaderContext)
    const globals = useContext(GlobalsContext)

    const [meshNode, setMeshNode] = useState(null)
    const [wan, setWan] = useState(null)
    const [lan, setLan] = useState(null)
    const [statistics, setStatistics] = useState(null)
    const [oldStatistics, setOldStatistics] = useState({ tx: 0, rx: 0, unity_size: 1, time: 0 })
    const [device, setDevice] = useState(null)
    const [datetime, setDatetime] = useState(null)
    const [traffic, setTraffic] = useState({ download: 0, upload: 0 })
    const [currentTime, setCurrentTime] = useState(null)
    const [uptime, setUptime] = useState(0)
    const [confirm, setConfirm] = useState(false)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [ipv6Details, setIPv6Details] = useState(false)

    const refreshNodeTimer = useRef(null)
    const REFRESH_NODE_INTERVAL = 5000
    const statisticsTimer = useRef(null)
    const STATISTICS_INTERVAL = 5000

    const { t } = useTranslation()

    const deviceID = macutils.getDeviceIdFromMac(params.id)

    useEffect(() => {
        meshContext.httpRetryTimeout.current = true
        getMeshNode()

        return () => {
            meshContext.httpRetryTimeout.current = false
        }
        // eslint-disable-next-line
    }, [meshContext.meshNodes])

    useEffect(() => {
        if (!meshNode) return
        if (!meshNode.is_online) {
            header.title.set(meshNode.name)

            clearTimeout(refreshNodeTimer.current)
            refreshNodeTimer.current = setTimeout(() => {
                meshContext.retrieveEasyMesh()
            }, REFRESH_NODE_INTERVAL)
            return
        }

        meshContext.retrieveDevice(deviceID, setDevice)

        return () => clearTimeout(refreshNodeTimer.current)

        // eslint-disable-next-line
    }, [meshNode])

    useEffect(() => {
        if (!device) return

        meshContext.retrieveWan(deviceID, setWan)
        meshContext.retrieveLan(deviceID, setLan)
        meshContext.retrieveDatetime(deviceID, setDatetime)

        header.title.set(device.alias)
        setUptime(device.uptime)

        // eslint-disable-next-line
    }, [device])

    useEffect(() => {
        if (!wan) return

        meshContext.retrieveInterfaceStatistics(deviceID, wan.interfaceID, setStatistics)

        // eslint-disable-next-line
    }, [wan])

    useEffect(() => {

        if (datetime) setCurrentTime(datetime.datetime * 1000)

    }, [datetime])

    useEffect(() => {
        let mounted = true
        setTimeout(() => {
            if (mounted) {
                if (uptime) setUptime(uptime + 1)
                setCurrentTime(currentTime + 1000)
            }
        }, 1000)

        return () => mounted = false

        // eslint-disable-next-line
    }, [currentTime])

    useEffect(() => {
        if (!statistics)
            return

        setTraffic(timeUtils.calculateTraffic(oldStatistics, statistics))
        setOldStatistics(statistics)

        refreshStatistics()

        return () => {
            clearTimeout(statisticsTimer.current)
        }

        // eslint-disable-next-line
    }, [statistics])

    const getMeshNode = async () => {
        if (!meshContext.easyMesh) {
            meshContext.retrieveEasyMesh()
            return
        }

        let currentNode
        const rootDeviceID = await globals.getDeviceID()
        if (deviceID === rootDeviceID) {
            currentNode = { name: getMeshTranslation('label', 'MAIN_NODE'), is_online: true, isRoot: true }
        } else {
            currentNode = meshContext.meshNodes.find(node => node.mac.toUpperCase() === params.id.toUpperCase())
        }

        if (!currentNode) {
            history.push('/')
            return
        }

        setMeshNode(currentNode)
    }

    const refreshStatistics = () => {
        clearTimeout(statisticsTimer.current)

        statisticsTimer.current = setTimeout(() => {
            meshContext.retrieveInterfaceStatistics(deviceID, wan.interfaceID, setStatistics)
        }, STATISTICS_INTERVAL)
    }

    const continueDelete = async (confirmed) => {
        const TIME_TO_START_RESET = 60000

        clearTimeout(statisticsTimer.current)
        clearTimeout(refreshNodeTimer.current)

        setError(false)
        setConfirm(false)

        if (confirm && confirmed) {
            setSaving(true)

            if (meshNode.is_online) {
                const result = await meshContext.resetFactoryDefault(deviceID)
                if (!result) {
                    setSaving(false)
                    setError(true)
                    return
                }
            }

            setTimeout(() => {
                history.push('/')
            }, meshNode.is_online ? TIME_TO_START_RESET : 0)
        }
    }

    const getIpv6Lines = (addresses) => {
        let sortedAddresses = [...addresses]

        sortedAddresses.sort((a, b) => !a.includes('fe80') ? -1 : 1)

        let lines = []

        sortedAddresses.forEach((address, i) => {
            lines.push({ label: `${getMeshTranslation('label', 'IPV6_ADDRESS')} ${i + 1}`, value: address, id: `ipv6-${i + 1}` })
        })
        return lines
    }

    const ipv6DetailsModal = () => {
        return lan && <Modal
            show={ipv6Details}
            onOverlayClick={() => setIPv6Details(false)}
            content={<div className='dashboard-modal-lan'>
                <div className='subtitle'>
                    <label>{getMeshTranslation('label', 'IPV6_LAN')}</label>
                </div>
                <div className='dashboard-modal-content'>
                    <InfoCard
                        lines={getIpv6Lines(lan.ip6_list)}
                    ></InfoCard>
                </div>
            </div>}
        ></Modal>
    }

    const getConnectionStatus = () => {
        return <React.Fragment>
            <div className='subtitle'>
                <label>{getMeshTranslation('title', 'CONNECTION_STATUS')}</label>
            </div>

            {!meshNode || (!statistics && meshNode.is_online) ? <Loading show={true} small></Loading> :
                <div className='list-wrapper'>
                    <InfoCard
                        lines={[
                            {
                                label: getMeshTranslation('label', 'STATUS_NODE'),
                                value: meshNode.isRoot && meshContext.easyMesh.type === easyMeshType.CONTROLLER ? getMeshTranslation('label', 'MAIN_NODE') : getMeshTranslation('label', 'SECONDARY_NODE')
                            },
                            { label: getMeshTranslation('label', 'STATUS'), value: meshNode.is_online ? 'Online' : 'Offline' },
                            {
                                label: getMeshTranslation('label', 'SIGNAL_QUALITY'),
                                value: meshNode.is_online && !meshNode.isRoot ? meshNode.rssi === -1 ? 'Excelente (cabeado)' :
                                    `${commonUtils.getSignalQuality(meshNode.rssi)} (${(meshNode.rssi)}dBm)` : '-'
                            },
                            { label: getMeshTranslation('label', 'TX_DOWNLOAD'), value: meshNode.is_online ? traffic.download : '-' },
                            { label: getMeshTranslation('label', 'TX_UPLOAD'), value: meshNode.is_online ? traffic.upload : '-' },
                        ]}
                    ></InfoCard>
                </div >}
        </React.Fragment >
    }

    const getProductInfo = () => {
        return <React.Fragment>
            <div className="section-divider"></div>
            <div className='subtitle'>
                <label>{getMeshTranslation('title', 'PRODUCT_INFO')}</label>
            </div>

            {!meshNode || (!device && meshNode.is_online) ? <Loading show={true} small></Loading> :
                <div className='list-wrapper'>
                    <InfoCard
                        lines={[
                            {
                                label: getMeshTranslation('label', 'MODEL'),
                                value: meshNode.is_online ? device.model + (device.alias ? ` (${device.alias})` : '') : '-'
                            },
                            {
                                label: getMeshTranslation('label', 'FIRMWARE_VERSION'),
                                value: meshNode.is_online ? device.fw_version : '-'
                            },
                            { label: getMeshTranslation('label', 'MAC_LAN'), value: macutils.macAddressMask(deviceID).toUpperCase() },
                            { label: getMeshTranslation('label', 'LOCALIZATION'), value: meshNode.name },
                        ]}
                    ></InfoCard>
                </div>
            }
        </React.Fragment>
    }

    const getUseInfo = () => {
        return <div>
            <div className='subtitle'>
                <label>{getMeshTranslation('title', 'USE_INFO')}</label>
            </div>
            {!device || !currentTime ? <Loading show={true} small></Loading> :
                <div className='list-wrapper'>
                    <InfoCard
                        lines={[
                            {
                                label: getMeshTranslation('label', 'DATE_TIME'),
                                value: new Date(currentTime).toLocaleString('pt-br')
                            },
                            {
                                label: getMeshTranslation('label', 'RUNNING_TIME'),
                                value: timeUtils.composeTime(uptime)
                            },
                        ]}
                    ></InfoCard>
                </div>}
        </div>
    }

    const getMeshTranslation = (type, key) => t(`network.mesh.${type}.${key}`)

    const getProtocolInfo = () => {
        return <div>
            <div className="section-divider"></div>
            <div className='subtitle'>
                <label>{t('common.label.PROTOCOL')}</label>
            </div>
            {!lan || !device ? <Loading show={true} small></Loading> :
                <div className='list-wrapper'>
                    <InfoCard
                        lines={[
                            {
                                label: t('common.label.IPV4'),
                                value: lan.ip4
                            },
                            {
                                label: t('common.label.IPV6'),
                                value:
                                    <div id='ipv6-details-line' className='dashboard-link' onClick={() => setIPv6Details(true)}>
                                        {lan.ip6_list.length > 0 ? getIpv6Lines(lan.ip6_list)[0].value : '-'}
                                    </div>,
                            },
                        ]}
                    ></InfoCard>
                </div>}
        </div>
    }

    return <div id="mesh-device-page">

        <DefaultStatusModals
            modalID='mesh-device'
            error={error}
            saving={saving}
            confirm={confirm}
            isWarningModal={true}
            continueFn={continueDelete}
            confirmContent={meshNode && meshNode.is_online ? meshContext.confirmDeleteNodeContent() :
                meshContext.confirmDeleteOfflineNodeContent()}
            savingText={deletingNodeContent(getMeshTranslation)}
            successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
        ></DefaultStatusModals>

        {ipv6DetailsModal()}

        <div className='subtitle'><Translator path="network.mesh.title.INMESH_NODE_INFO"></Translator></div>

        <Button
            id='network-mesh-return'
            text={<Translator path="common.label.BACK"></Translator>}
            onClick={e => history.push('/network/mesh/settings')}
        ></Button>

        {getConnectionStatus()}
        {getProductInfo()}

        {!meshNode || !meshNode.is_online ? null :
            <div className='collapse-wrapper'>
                <CollapseBox title={getMeshTranslation('title', 'ADVANCED_INFO')} startOpen={false}>
                    {getUseInfo()}
                    {getProtocolInfo()}
                </CollapseBox>
            </div>
        }

        {!meshNode || !meshContext || !easyMeshType ||
            (meshNode.isRoot || meshContext.easyMesh.type !== easyMeshType.CONTROLLER) ? null :
            <Button
                id='btn-remove-node'
                text={getMeshTranslation('label', 'REMOVE')}
                outlined
                isError
                onClick={() => setConfirm(true)}
            ></Button>
        }
    </div>
}
import React from 'react'

import './cardbutton.css'

export default function CardButton({Icon, iconColor, iconSize, label, value, onClick, Id, RenderedIcon}) {

    const composeValue = (value) => {

        if(React.isValidElement(value)){
            return value
        }

        return value.toString()
    }

    return <div id={Id} className='card-button' onClick={onClick}>
                {RenderedIcon ? <div className='card-button-icon'>{RenderedIcon}</div> : ''}
                {Icon ? <div className='card-button-icon'><Icon usePng color={iconColor} size={iconSize}></Icon></div> : ''}
                {label ? <div className='card-button-label'>{label}</div> : ''}
                {value !== null ? <div id={`${Id}-value`} className='card-button-value'>{composeValue(value)}</div> : ''}
            </div>
}
import { backendStatus } from '../../backend/backend'

export async function fetchGPONStatus(backend, setGPONStatus, handleError) {
    let result = await backend.retrieveFresh('gpon_status')

    if(result.status === backendStatus.SUCCESS){

        let gponStatusList = result.content

        let gponStatus = gponStatusList.length !== 0 ? gponStatusList[0] : {
            isCreate: true,
            onu_state_id: "O1",
            onu_id: 0,
            onu_state_desc: ""
        }

        setGPONStatus(gponStatus)
    } else {
        handleError();
    }
}

import React, { useContext, useEffect } from 'react'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { MenuContext } from '../../../components/menu/menu-state'
import Loading from '../../../components/loading/loading'
import { Switch, useHistory, useLocation } from 'react-router-dom'
import DashboardPage from '../../../app/pages/dashboard/dashboard'
import PONPage from '../pon/pon'
import { GlobalsContext } from '../../../app/globals-context'
import { BackendContext } from '../../../backend/backend'
import SessionCheck from '../../../app/session-check-context'
import VoipPage from '../voip/voip'
import IPv6Page from '../ipv6/ipv6'
import NetworkPage from '../network/network'
import FirewallPage from '../firewall/firewall'
import SystemPage from '../system/system'
import ToolsPage from '../tools/tools'
import ServicePage from '../service/service'
import AdvancedPage from '../advanced/advanced'
import ParentalControlPage from '../parental_control/parental_control'
import MenuID from '../../utils/menu'
import FeatureBasedRoute from './feature-based-route'

export default function MainPage({ activeSession }) {

    let header = useContext(MainHeaderContext)
    let menu = useContext(MenuContext)
    let history = useHistory()
    let globals = useContext(GlobalsContext)
    let backend = useContext(BackendContext)

    useEffect(() => {
        if (!globals.getItem('username')) globals.updateUser()

        if (backend.isDefaultUnauthorizedHandler)
            backend.setOnUnauthorized(mainUnauthorizedHandler)

        header.hidden.set(false)
        menu.username.set(sessionStorage.getItem('username'))

        // eslint-disable-next-line
    }, [backend.isDefaultUnauthorizedHandler])

    async function mainUnauthorizedHandler(refresh) {

        sessionStorage.removeItem('token')
        history.push('/login?expired')
    }

    const location = useLocation()

    const checkForNewPassword = () => {
        if (sessionStorage.login_enable === 'false') {
            globals.setItem('defaultUser', true)
            history.push('/login')
        }
    }

    React.useEffect(() => {
        checkForNewPassword()
        // eslint-disable-next-line
    }, [location])

    return <SessionCheck activeSession={activeSession}>
        {backend.isDefaultUnauthorizedHandler ? <Loading show={true}></Loading> : <div id='main-page'>
            <Switch>
                <FeatureBasedRoute exact path='/' component={DashboardPage} id={MenuID.Dashboard.name} />
                <FeatureBasedRoute exact path='/network/*' component={NetworkPage} id={MenuID.Network.name} />
                <FeatureBasedRoute exact path='/ipv6/*' component={IPv6Page} id={MenuID.Ipv6.name} />
                <FeatureBasedRoute exact path='/pon/*' component={PONPage} id={MenuID.Pon.name} />
                <FeatureBasedRoute exact path='/firewall/*' component={FirewallPage} id={MenuID.Firewall.name} />
                <FeatureBasedRoute exact path='/system/*' component={SystemPage} id={MenuID.System.name} />
                <FeatureBasedRoute exact path='/tools/*' component={ToolsPage} id={MenuID.Tools.name} />
                <FeatureBasedRoute exact path='/service/*' component={ServicePage} id={MenuID.Service.name} />
                <FeatureBasedRoute exact path='/advanced/*' component={AdvancedPage} id={MenuID.Advanced.name} />
                <FeatureBasedRoute exact path='/parental-control/*' component={ParentalControlPage} id={MenuID.ParentalControl.name} />
                <FeatureBasedRoute exact path='/voip/*' component={VoipPage} id={MenuID.Voip.name} />

            </Switch>

        </div>}
    </SessionCheck>
}
import { backendStatus } from '../../backend/backend'


export async function fetchServiceProxyIGMP(backend, setServiceProxyIGMP) {
    let result = await backend.retrieveFresh('igmp_proxy')

    if(result.status === backendStatus.SUCCESS){

        let serviceProxyIGMPList = result.content

        let serviceProxyIGMP = serviceProxyIGMPList[0]

        setServiceProxyIGMP(serviceProxyIGMP)
    }
}

export async function saveServiceProxyIGMP(backend, serviceProxyIGMP) {

    try{

        let result = null;

        result = await backend.update(`igmp_proxy/${serviceProxyIGMP.id}`, serviceProxyIGMP)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Service Proxy IGMP:', error)

        return false
    }

}
import React, { useEffect, useState, useContext } from 'react'
import Input from '../../../../components/input/input';
import { MenuContext } from '../../../../components/menu/menu-state';
import { usePrevious } from '../state/state';
import { SearchContext } from '../../../pages/main/search-context';
import { useTranslation } from 'react-i18next';
import './search-bar.css'
import CloseIcon from '../../../../components/icons/close';

export default function SearchBar() {

    const [query, setQuery] = useState('')
    const [eraseEnable, setEraseEnable] = useState(false)
    const prevQuery = usePrevious(query)
    const [focusSetted, setFocusSetted] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);

    const isMobile = width <= 768;

    const menuContext = useContext(MenuContext)
    const searchContext = useContext(SearchContext)

    const { t, i18n } = useTranslation()

    const searchBarId = 'search-bar-input'

    useEffect(() => {

        if (query !== prevQuery) {

            let emptyQuery = (query === '')

            if (emptyQuery) {
                searchContext.displayAllMenus()
            } else {
                searchContext.query(query)
            }

            menuContext.expand.set(!emptyQuery)
        }

    }, [query, prevQuery, searchContext, menuContext.expand])

    useEffect(() => {

        (menuContext.open.value === false) && resetSearch()

        if (menuContext.open.value === true) {
            if (!focusSetted && !isMobile) {
                setFocusSetted(true)

                var elemento = document.getElementById(searchBarId)
                elemento && elemento.focus()
            }
        }
        else {
            setFocusSetted(false)
        }

    }, [menuContext.open.value, focusSetted, isMobile])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        resetSearch()
        // eslint-disable-next-line
    }, [i18n.language])

    const resetSearch = () => {
        setQuery('')
        setEraseEnable(false)
    }

    const doSearch = (text) => {
        if (text) {
            setQuery(text)
            setEraseEnable(true)
        } else {
            resetSearch()
        }
    }

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }

    return <div className='search-wrapper'>
        <Input name={searchBarId}
            id={searchBarId}
            placeholder={t('search.placeholder.SEARCH')}
            value={query}
            onChange={(e) => {
                doSearch(e.target.value)
            }}
        ></Input>
        <div id={"search-erase-button"}
            className={"search-erase-button clickable"}
            onClick={() => {
                resetSearch()
            }}
        >
            {eraseEnable === true && <CloseIcon></CloseIcon>}
        </div>
    </div>

}
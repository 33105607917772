import { backendStatus } from '../../backend/backend'

const URL = 'automatic_maintenance';

export async function fetchSystemAutomaticMaintenance(backend, id, setAutomaticMaintenance) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setAutomaticMaintenance(result.content)
    }
    
}

export async function updateAutomaticMaintenance(backend, automatic_maintenance, setErrorMessage) {
    try {
        let result = await backend.update(`automatic_maintenance/0`, automatic_maintenance)
        
        if(result.status === backendStatus.SUCCESS) {
            return true
        } else {
            setErrorMessage(result.content?.body)
            return false
        }
    }catch(error){
        console.error('Error updating automatic_maintenance:', error)
        return false
    }
}

import { backendStatus } from  '../../backend/backend'

function adjustIPv6(itf) {
    if(itf.ip6_count === 0) {
        itf.ip6_count = 1
        itf.ip6_list = ['']
    }

    if(itf.ip6_list[0] === '0::0/0') {
        itf.ip6_list[0] = ''
    }

    if(itf.ip6_list[0].startsWith('fe80::')) {
        if(itf.ip6_list.length === 1) {
            itf.ip6_list.push('')
            itf.ip6_count = 2
        }

        let aux = itf.ip6_list[0]
        itf.ip6_list[0] = itf.ip6_list[1]
        itf.ip6_list[1] = aux
    }

    return itf
}

export async function fetchInterfaceList(backend, setInterfaceList) {
    
    try{
        let result = await backend.retrieveFresh('interface')
        if(result.status === backendStatus.SUCCESS){
            result.content = result.content.map(itf => adjustIPv6(itf))
            setInterfaceList(result.content)
            return result.content
        }
    }catch(error){
        console.error('Error fetching interface list')
    }

    return null
}

export async function fetchInterface(id, backend, setInterface) {

    let empty = {
        "id": "",
        "ip4": "",
        "ip6_count": 1,
        "ip6_list": [
            "",
        ],
        "mac": "",
        "mtu": 1500,
        "netmask": "",
        "running": false
    }
    
    try{
        let result = await backend.retrieveFresh(`interface/${id}`)
        if(result.status === backendStatus.SUCCESS && result.content && Object.keys(result.content).length !== 0){
            result.content = adjustIPv6(result.content)
            setInterface(result.content)
            return result.content
        } else {
            setInterface(empty)
            return empty
        }
    }catch(error){
        console.error('Error fetching interface')
    }

    return null
}

export async function saveInterface(backend, itf, apply_now = false) {

    try{

        let result = await backend.update(`interface/${itf.id}`, itf, '', apply_now)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Interface:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import './advanced-igmp-mld-snooping.css'

import { fetchIgmpMldSnooping, saveAdvancedIgmpMldSnooping } from '../../../resources/advanced-igmp-mld-snooping';
import { BackendContext } from '../../../../backend/backend';

import Checkbox from '../../../../components/checkbox/checkbox';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form from '../../../../components/form/form';
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';

export default function AdvancedIgmpMldSnooping ({isWizard, isFormSegment, formSegment}) {
    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [igmpMldSnooping, setIgmpMldSnooping] = useState(null)


    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchIgmpMldSnooping(backend, setIgmpMldSnooping)

        // eslint-disable-next-line
    }, [])

    let save = async () => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveAdvancedIgmpMldSnooping(backend, igmpMldSnooping, setErrorMessage)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const nextClicked = () => {
        if (isWizard) {
            formSegment.nextClicked()
        }
    }


    const systemIgmpMldSnoopingForm = () => {
        return <React.Fragment>
            <Checkbox id='igmp-mld-snooping-status-enabled'
                name='enabled'
                label={<Translator path="common.label.ENABLE"></Translator>}
                value={igmpMldSnooping.enabled}
                toggleFn={(e) => {
                    igmpMldSnooping.enabled = !igmpMldSnooping.enabled;
                    setIgmpMldSnooping({...igmpMldSnooping})
                }}
                validators={[]}
            ></Checkbox>
        </React.Fragment>
    }

    return !igmpMldSnooping ? <Loading show={true}></Loading> :
        <div id='advanced-igmp-mld-snooping-page' className='container scroll-area'>
            <div className='subtitle'><Translator path="menu.IGMP_MLD_SNOOPING"></Translator></div>
            <div className='card mt2'>
                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    errorText={t('common.message.error.SERVER_ERROR', {error: errorMessage})}
                    success={success}
                    continueFn={dismiss}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                {!isFormSegment ? 

                <React.Fragment>
                    <div className='subtitle'><Translator path="advanced.igmp_mld_snooping.title.ADVANCED_IGMP_MLD_SNOOPING"></Translator></div>

                    <Form id='advanced-igmp-mld-snooping-form'
                        onSubmit={save}
                        buttonId='button-save-advanced-igmp-mld-snooping'
                    >
                        {systemIgmpMldSnoopingForm()}
                    </Form>
                </React.Fragment> : 

                <FormSegment
                    title={formSegment.title}
                    active={formSegment.active}
                    previousLabel={formSegment.previousLabel}
                    previousClicked={formSegment.previousClicked}
                    disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                    nextLabel={formSegment.nextLabel}
                    nextClicked={nextClicked}
                >
                    {systemIgmpMldSnoopingForm()}
                </FormSegment>}
            </div>
        </div>
}
import React, { useState, useEffect, useContext } from 'react'

import './service-rip-config.css'

import { 
    fetchServiceRipConfigList, 
    saveServiceRipConfig, 
    deleteServiceRipConfigById, 
} from '../../../resources/service-rip-config';

import { fetchServiceRipEnable, saveServiceRipEnable } from '../../../resources/service-rip-enable';
import { BackendContext } from '../../../../backend/backend';

import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form from '../../../../components/form/form';
import Checkbox from '../../../../components/checkbox/checkbox';
import Select from '../../../../components/select/select';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import { fetchNetworkWanList } from '../../../resources/network-wan';
import RemoveIcon from '../../../../components/icons/remove';
import Modal, { ModalTypes } from '../../../../components/modal/modal';
import List from '../../../../components/list/list';

export default function ServiceRipConfig ({isWizard, isFormSegment, formSegment}) {
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const backend = useContext(BackendContext)
    const { t } = useTranslation()    
    const [interfaces, setInterfaces] = useState([])
    const [ripConfigList, setRipConfigList] = useState(null)
    const [ripEnabled, setRipEnabled] = useState(null)
    const [deleteServiceRipConfigTableData, setDeleteServiceRipConfigTableData] = useState(null)

    const [ripConfig, setRipConfig] = useState({
        interfaceID: "br0", 
        receive_mode: 0, 
        send_mode: 0
    })

    useEffect(() => {
        fetchNetworkWanList(backend, setInterfaces)
        fetchServiceRipConfigList(backend, setRipConfigList)
        fetchServiceRipEnable(backend, 0, setRipEnabled)
        setDeleteServiceRipConfigTableData({showDelete: false, id: null})

        // eslint-disable-next-line
    }, [])

    const translateErrorMessagesFromBackend = (text) => {
        if(text === 'interfaceID already in use'){ 
            return t('service.rip_config.error.INTERFACE_BUSY')
        }
        if(text === 'destination already in use'){
            return t('service.rip_config.error.DESTINATION_BUSY')
        }
     }

    const getMode = (mode) => {
        switch(mode){            
            case 1: return t('service.rip_config.label.RIPV1')
            case 2: return t('service.rip_config.label.RIPV2')
            case 3: return t('common.label.BOTH')
            case 4: return t('service.rip_config.label.RIPV1_COMPAT')
            default: return t('service.rip_config.label.NONE')
        }
    }

    const getColumnsRipConfig = () => {
        return [
            {header: t('service.rip_config.label.INTERFACE'), align: 'center', size: '150px'},
            {header: t('service.rip_config.label.RECEIVE_MODE'), align: 'center', size: '100px'},
            {header: t('service.rip_config.label.SEND_MODE'), align: 'center', size: '150px'},
            {header: t('common.label.ACTIONS'), align: 'center', size: '50px'},
        ]
    }

    const getRipConfig = () => {
        return ripConfigList?.map(register => [
            register.interfaceID,
            getMode(register.receive_mode),
            getMode(register.send_mode),
            <>
                <span className="clickable" onClick={e => removeItemRipConfig(register)}><RemoveIcon/></span>
            </>
        ])
    }

    const deleteRipConfigTable = async(id) => {
        setErrorMessage('')
        if(saving || error || success) return

        setSaving(true)

        if(!(await deleteServiceRipConfigById(backend, id, setRipConfigList))){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

        await fetchServiceRipConfigList(backend, setRipConfigList)

    }

    const DeleteServiceRipConfigTableModal = (ripConfigList) => {
        return <Modal show='true'
            type={ModalTypes.CONFIRM_WARNING}
            
            onDismissClick={conf => {
                if (conf) {
                    deleteRipConfigTable(ripConfigList.id)
                }
                setDeleteServiceRipConfigTableData({showDelete: false})
            }}
            content={
                <div style={{ width: '420px' }}>
                    <span style={{ display: 'block' }}><Translator path="common.message.warning.PERMANENT_DELETE_WARNING"></Translator>
                        <br></br>
                    </span>
                    <b><Translator path="common.message.warning.CONFIRM_EXCLUSION"></Translator></b>
                </div>
            }
            dismissText={t('common.label.CANCEL')}
            confirmText={t('common.label.CONFIRM')}
        ></Modal>
    }

    const removeItemRipConfig = (ripConfig) => {
        setDeleteServiceRipConfigTableData({showDelete: true, id: ripConfig.id})
    }

    const getInterfaceOptions = () => {
        return [{ value: 'br0', text: t('br0')},
            ...interfaces.filter((it) => it.protocol !== 1).map(it => ({value: it.id, text: it.id}))]
    }

    const submitNewRegister = async () => {

        setErrorMessage('')
        if(saving || error || success) return

        setSaving(true)

        if(!await saveServiceRipConfig(backend, ripConfig, setErrorMessage)){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

        await fetchServiceRipConfigList(backend, setRipConfigList)
    }

    const dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    let save = async () => {
        setSaving(true)
        if(!await saveServiceRipEnable(backend, ripEnabled)) {
            setSaving(false)
            setError(true)
        }
        setSaving(false)
    }

    const systemRipEnableForm = () => {
        return <React.Fragment>
            <div>
                <Checkbox id='rip-enabled'
                        label={t('common.label.ENABLE')}
                        value={ripEnabled?.enable}
                        toggleFn={async() => {
                            ripEnabled.enable = !ripEnabled.enable
                            setRipEnabled({...ripEnabled})
                        }}
                    ></Checkbox>
            </div>
        </React.Fragment>
    }

    const systemRipConfigForm = () => {
        return <React.Fragment>
            { deleteServiceRipConfigTableData?.showDelete &&
                <DeleteServiceRipConfigTableModal 
                    id={deleteServiceRipConfigTableData.id}>
                </DeleteServiceRipConfigTableModal>
            }
            <div className='section-divider'></div>
            <div>
                <>
                    <Select
                        id='rip-config-interface'
                        label={t('service.rip_config.label.INTERFACE')}
                        options={getInterfaceOptions()}
                        value={ripConfig.interfaceID}
                        onChange={ (e) => { setRipConfig( {
                                    ...ripConfig, interfaceID: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        })}}
                    ></Select>
                    
                    <div className="row larger-field-wrapper">

                        <Select
                            id='rip-config-receive-mode'
                            label={t('service.rip_config.label.RECEIVE_MODE')}
                            options={
                                [
                                    {value: 0, text: t('service.rip_config.label.NONE')}, 
                                    {value: 1, text: t('service.rip_config.label.RIPV1')}, 
                                    {value: 2, text: t('service.rip_config.label.RIPV2')}, 
                                    {value: 3, text: t('common.label.BOTH')}
                                ]
                            }
                            value={ripConfig.receive_mode}
                            onChange={ (e) => { setRipConfig( {
                                        ...ripConfig, receive_mode: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                            })}}
                        ></Select>

                        <Select
                            id='rip-config-send-mode'
                            label={t('service.rip_config.label.SEND_MODE')}
                            options={
                                [
                                    {value: 0, text: t('service.rip_config.label.NONE')}, 
                                    {value: 1, text: t('service.rip_config.label.RIPV1')}, 
                                    {value: 2, text: t('service.rip_config.label.RIPV2')}, 
                                    {value: 4, text: t('service.rip_config.label.RIPV1_COMPAT')}
                                ]
                            }
                            value={ripConfig.send_mode}
                            onChange={ (e) => { setRipConfig( {
                                        ...ripConfig, send_mode: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                            })}}
                        ></Select>
                    </div>
                </>
            </div>
        </React.Fragment>
    }
    
    const rip_table = () => {
        return <div>
            <div className='subtitle  rip-config-subtitle'> {<Translator path="service.rip_config.title.RIP_CONFIG_TABLE"></Translator>} </div>
            {ripConfigList?.length > 0 ?
            <div className="service-rip-config-list-wrapper">
                <List 
                    lines={getRipConfig()}
                    columns={getColumnsRipConfig()}
                ></List>
            </div>
            :
            <div id='rip-config-no-configs'>
                <div className='info-card noelements-card rip-config-list-empty-label'>
                    <Translator path="service.rip_config.info.NO_RIP_CONFIGS" />
                </div>
            </div>}

        </div>
        
    }

    return !ripConfig ? <Loading show={true}></Loading> : 
        <div id='service-rip-config-page'> 
            <div id='service-rip-config-form'>
                <div className='subtitle'>{<Translator path="service.rip_config.title.RIP_CONFIG"></Translator>}</div>

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    errorText={
                        <>
                            <div>
                                {t('service.rip_config.error.ERROR_SERVER')}
                            </div>
                            <div>
                                {translateErrorMessagesFromBackend(errorMessage)}
                            </div>
                        </>
                    }
                    success={success}
                    continueFn={dismiss}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                <div className="card mt2">
                <div className='subtitle'> <Translator path={'service.rip_config.title.RIP_ENABLE'}></Translator> </div>
                    <div className="rip-enable-form">
                        <Form
                            onSubmit={save}
                            buttonId='button-rip-enable-save'
                            isPristine={false}
                        >
                            {systemRipEnableForm()}
                        </Form>
                    </div>
                </div>

                <div className="card mt2">
                    <div className='subtitle'> <Translator path={'service.rip_config.title.RIP_CONFIG_CARD'}></Translator> </div>
                    <div className="rip-config-new-register-form">
                        <Form
                            onSubmit={submitNewRegister}
                            buttonId='button-rip-config-add'
                            submitText={t('common.label.ADD')}
                            isPristine={false}
                        >
                            {systemRipConfigForm()}
                        </Form>
                    </div>
                </div>
            </div>
            
            {rip_table()}
            
        </div>
    }
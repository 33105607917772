import React, {useState, useEffect, useContext} from 'react'

import { fetchPONStatus } from '../../resources/pon-status';

import { BackendContext } from '../../../backend/backend';

export const PONContext = React.createContext({})

export default function PONState({children}) {

    const [ponStatus, setPONStatus] = useState(null)

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchPONStatus(backend, setPONStatus)
        // eslint-disable-next-line
    }, [])

    const currentPONMode = () => {
        return ponStatus?.current_mode ? ponStatus?.current_mode : 'epon';
    }

    return <PONContext.Provider value={{
        currentPONMode,
        setPONStatus
    }}>
        {children}
    </PONContext.Provider>
}

import React, { useState, useContext } from 'react'
import Button from '../../../components/button/button';
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import { BackendContext } from '../../../backend/backend';
import { GlobalsContext } from '../../globals-context';
import { useHistory } from 'react-router-dom';
import Translator from '../../common/components/translator/translator'

import { reboot } from '../../utils/reboot';

export default function SystemReboot() {

    const [success, setSuccess] = useState(false)
    const [successText, setSuccessText] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [savingText, setSavingText] = useState(false)

    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const history = useHistory()

    let dismiss = () => {
        setSuccess(false)
        setError(false)
        setSaving(false)
        history.push('/login')
        window.location.reload()
    }

    let rebootNow = () => {
        reboot(history, globals, saving, setSaving, setError, setSuccessText, setSavingText, backend, setSuccess)
    }

    return <div id='reboot-page' className='info text align-center'>
        <div className='subtitle'><Translator path="menu.REBOOT"></Translator></div>
        <div className='card mt2'>
            <div className='subtitle'><Translator path="system.reboot.warning.REBOOT_YOUR_SYSTEM"></Translator></div>

            <div className='info-text'>
                <Translator path="system.reboot.warning.PRESS_BELOW_BUTTON_TO_REBOOT"></Translator>
                <DefaultStatusModals
                    saving={saving}
                    success={success}
                    error={error}
                    continueFn={dismiss}
                    savingText={savingText}
                    errorText={<Translator path="common.message.error.CHECK_CONNECTION"></Translator>}
                    successText={successText}
                ></DefaultStatusModals>
            </div>

            <Button
                id='btn-reboot'
                text={<Translator path="system.reboot.button.REBOOT"></Translator>}
                type='submit'
                onClick={rebootNow}
            ></Button>
        </div>
    </div>
}

import React, { useState, useEffect, useContext } from 'react'

import './service-upnp.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchServiceSystem, saveServiceSystem } from '../../../resources/system';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Checkbox from '../../../../components/checkbox/checkbox';
import { fetchNetworkWanList } from '../../../resources/network-wan';
import Select from '../../../../components/select/select';
import extraValidators from '../../../common/validators';


export default function ServiceUPnP({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [serviceUPnP, setServiceUPnP] = useState(null)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [networkWanList, setNetworkWanList] = useState([])

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchServiceSystem(backend, setServiceUPnP)
        fetchNetworkWanList(backend, setNetworkWanList)

        // eslint-disable-next-line
    }, [])


    const serviceUPnPForm = () => {

        const updateSelectedInterface = (value) => {
            if (serviceUPnP.upnp_wan_if !== value) {
                serviceUPnP.upnp_wan_if = value
                setServiceUPnP({ ...serviceUPnP })
            }
        }

        const interfacesList = [...networkWanList.map(wan => ({ value: wan.id, text: wan.interfaceID }))]

        if (!serviceUPnP.upnp_wan_if && interfacesList.length > 0)
            updateSelectedInterface(interfacesList[0].value)

        return <React.Fragment>
            <div className='section-divider'></div>
            <Checkbox id='service-upnp-upnp'
                name='upnp'
                label={<Translator path="service.upnp.label.ALLOW_UPNP"></Translator>}
                value={serviceUPnP.upnp}
                toggleFn={(e) => {
                    serviceUPnP.upnp = !serviceUPnP.upnp;
                    setServiceUPnP({ ...serviceUPnP })
                }}
                validators={[]}
            ></Checkbox>

            <Select
                id='service-upnp-upnp-wan-if'
                name='upnp_wan_if'
                label={<Translator path="service.upnp.label.INTERFACE"></Translator>}
                options={interfacesList}
                value={serviceUPnP.upnp_wan_if}
                onChange={(e) => {
                    updateSelectedInterface(e.target.value)
                }}
                validators={[extraValidators.required]}
            ></Select>

            {interfacesList.length === 0 &&
                <div id='upnp-interfaces-info'>
                    {t(`service.upnp.warning.NO_WAN_INTERFACES_AVAILABLE`)}
                </div>}
        </React.Fragment>
    }

    const submit = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await saveServiceSystem(backend, serviceUPnP)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !serviceUPnP ? <Loading show={true}></Loading> :

            <div id='service-upnp-page' className='with-tabs'>
                <div className='section-divider'></div>
                <div className='subtitle'>{t('service.upnp.title.UPNP')}</div>
                <div className='card mt2'>
                    <div className='subtitle'>{t('service.upnp.title.SETTINGS')}</div>

                    <DefaultStatusModals
                        saving={saving}
                        error={error}
                        success={success}
                        continueFn={dismissModal}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    ></DefaultStatusModals>

                    {!isFormSegment ? <Form
                        onSubmit={submit}
                        buttonId='button-save'
                    >
                        {serviceUPnPForm()}
                    </Form> :

                        <FormSegment
                            title={formSegment.title}
                            active={formSegment.active}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={submit}
                        >
                            {serviceUPnPForm()}
                        </FormSegment>}
                </div>
            </div>
    )
}

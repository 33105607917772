import { backendStatus } from '../../backend/backend'

export async function fetchEponLLIDStatus(backend, setEponLLIDStatus, handleError) {
    let result = await backend.retrieveFresh('epon_llid_status')

    if(result.status === backendStatus.SUCCESS){

        let eponLLIDStatusList = result.content

        setEponLLIDStatus(eponLLIDStatusList)
    } else {
        handleError();
    }
}

import React, { useState, useEffect, useContext } from 'react'

import './advanced-qos-policy.css'

import { fetchAdvancedQosPolicy, saveAdvancedQosPolicy } from '../../../resources/advanced-qos-policy';
import { BackendContext } from '../../../../backend/backend';

import Input from '../../../../components/input/input';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form from '../../../../components/form/form';
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import Checkbox from '../../../../components/checkbox/checkbox';
import Select from '../../../../components/select/select'
import extraValidators from '../../../common/validators'

const PRIO_MODE = 0
const WEIGHT_MODE = 1

export default function AdvancedQosPolicy({ isWizard, isFormSegment, formSegment }) {
    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [qosPolicy, setQosPolicy] = useState(null)
    let [lastValidBandwidth, setLastValidBandwidth] = useState(null)
    let [isQosPolicySet, setIsQosPolicySet] = useState(false)


    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchAdvancedQosPolicy(backend, 0, setQosPolicy)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (qosPolicy) {
            if (qosPolicy.bandwidth_config.totalbandwidth && !isQosPolicySet) {
                setLastValidBandwidth(qosPolicy.bandwidth_config.totalbandwidth)
                setIsQosPolicySet(true)
            }
        }
        // eslint-disable-next-line
    }, [qosPolicy])

    let save = async () => {

        if (saving || error || success) return

        setSaving(true)

        if (qosPolicy.queue_mode === PRIO_MODE) {
            for (var i = 0; i < qosPolicy.queue_config.length; i++) {
                qosPolicy.queue_config[i].weight = 0
            }
        }

        let ok = await saveAdvancedQosPolicy(backend, qosPolicy, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const nextClicked = () => {
        if (isWizard) {
            formSegment.nextClicked()
        }
    }

    const bandWidthForm = () => {
        return <div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="advanced.qos_policy.title.BANDWIDTH_CONFIG"></Translator></div>

                <Checkbox id='qos_policy_user_def_bandwidth'
                    name='qos_policy_user_def_bandwidth'
                    label={t('advanced.qos_policy.label.USER_DEF_BANDWIDTH')}
                    value={qosPolicy.bandwidth_config.totalbandwidth_enable}
                    clearErrors={true}
                    toggleFn={(e) => {
                        qosPolicy.bandwidth_config.totalbandwidth_enable = !qosPolicy.bandwidth_config.totalbandwidth_enable
                        if (!qosPolicy.bandwidth_config.totalbandwidth_enable) {
                            qosPolicy.bandwidth_config.totalbandwidth = lastValidBandwidth
                        }

                        setQosPolicy({ ...qosPolicy, bandwidth_config: { ...qosPolicy.bandwidth_config } })

                    }}
                ></Checkbox>

                <Input name='qos_policy_totalbandwidth'
                    id='qos_policy_totalbandwidth'
                    label={t('advanced.qos_policy.label.TOTALBANDWIDTH')}
                    type='number'
                    value={qosPolicy.bandwidth_config.totalbandwidth}
                    readOnly={!qosPolicy.bandwidth_config.totalbandwidth_enable}
                    onChange={(e) => setQosPolicy({
                        ...qosPolicy, bandwidth_config: {
                            ...qosPolicy.bandwidth_config, totalbandwidth: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        }
                    })}

                    validators={[
                        {fn: extraValidators.optionalValidators, params: {
                            shouldValidate: () => qosPolicy.bandwidth_config.totalbandwidth_enable,
                            validators: [
                                {fn: extraValidators.required, params: ''},
                                {fn: extraValidators.isNumber, params: ''},
                                {fn: extraValidators.value, params: { min: 1, max: 0xFFFFFFFF }},
                            ]
                        }}, 
                    ]}

                    dependentValues={[qosPolicy.bandwidth_config.totalbandwidth_enable]}
                ></Input>
            </div>
        </div>
    }

    const PrioQueueForm = () => {
        return qosPolicy.queue_config.map((queue, key) => {
            return (<div key={key} style={{ display: "flex", padding: "0" }}>
                <Checkbox id={`qos_policy_enable_queue_${key}`}
                    name={`qos_policy_enable_queue_${key}`}
                    label={`${t('advanced.qos_policy.title.ENABLE_QUEUE')} ${key + 1} ${key === 0 ? '(' + t('advanced.qos_policy.title.HIGHEST_PRIORITY') + ')' : ""}`}
                    value={queue.enable}
                    toggleFn={(e) => {
                        qosPolicy.queue_config[key].enable = !qosPolicy.queue_config[key].enable
                        setQosPolicy({ ...qosPolicy })
                    }}
                ></Checkbox>
            </div>
            )
        })
    }

    const PrioWeightForm = () => {
        return (
            <div>
                {qosPolicy.queue_config.map((queue, key) => {
                    return (<div key={key}>
                        <Input name={`qos_policy_weight_${key}`}
                            id={`qos_policy_weight_${key}`}
                            label={`${t('advanced.qos_policy.title.QUEUE_WEIGHT')} ${key + 1}`}
                            value={queue.weight}
                            type='text'
                            onChange={(e) => {
                                qosPolicy.queue_config[key].weight = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                setQosPolicy({ ...qosPolicy })
                            }}
                            validators={[
                                extraValidators.required,
                                extraValidators.isNumber,
                                { fn: extraValidators.value, params: { min: 0, max: 100 } }
                            ]}
                        ></Input>
                    </div>
                    )
                })}

                <div className="qos-weight-warnings">
                    <div className="weight-warning">
                        <strong><Translator path="advanced.qos_policy.title.QUEUE_WEIGHT_SUM"></Translator></strong>
                    </div>

                    {WeightSum() > 100 && <div className="weight-error">
                        <strong><Translator path="advanced.qos_policy.title.QUEUE_WEIGHT_SUM_GREATER"></Translator></strong>
                    </div>}
                    {WeightSum() < 100 && <div className="weight-error">
                        <strong><Translator path="advanced.qos_policy.title.QUEUE_WEIGHT_SUM_LESS"></Translator></strong>
                    </div>}
                </div>
            </div>
        )

    }

    const queueConfigForm = () => {
        return <div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="advanced.qos_policy.title.QUEUE_CONFIG"></Translator></div>

                <Select
                    id='qos_policy_policy_sel'
                    name='qos_policy_policy_sel'
                    label={t('advanced.qos_policy.label.POLICY')}
                    options={[
                        { value: 0, text: t('advanced.qos_policy.title.PRIORITY') },
                        { value: 1, text: t('advanced.qos_policy.title.WEIGHT') }
                    ]}
                    value={qosPolicy.queue_mode}
                    clearErrors={true}
                    onChange={(e) => {
                        let new_val = { ...qosPolicy, queue_mode: Number(e.target.value) };
                        setQosPolicy(new_val)
                    }}
                ></Select>

                {qosPolicy.queue_mode === PRIO_MODE && PrioQueueForm()}
                {qosPolicy.queue_mode === WEIGHT_MODE && PrioWeightForm()}
            </div>
        </div>

    }

    const checkWeight = () => {

        if (qosPolicy.queue_mode !== WEIGHT_MODE)
            return true

        let weight_sum = 0
        for (var i = 0; i < qosPolicy.queue_config.length; i++) {
            qosPolicy.queue_config[i].enable = (qosPolicy.queue_config[i].weight !== 0)
            weight_sum += qosPolicy.queue_config[i].weight
        }

        if (weight_sum === 100)
            return true

        return false
    }

    const WeightSum = () => {
        let weight_sum = 0
        for (var i = 0; i < qosPolicy.queue_config.length; i++) {
            qosPolicy.queue_config[i].enable = (qosPolicy.queue_config[i].weight !== 0)
            weight_sum += qosPolicy.queue_config[i].weight
        }

        return weight_sum
    }

    const systemQosPolicyForm = () => {
        return <React.Fragment>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="menu.ADVANCED_QOS_POLICY_ENABLE"></Translator></div>
                <Checkbox id='qos_policy_enabled'
                    name='enabled'
                    label={t('common.label.ENABLE')}
                    value={qosPolicy.enable}
                    clearErrors={true}
                    toggleFn={(e) => {
                        setQosPolicy({ ...qosPolicy, enable: !qosPolicy.enable })
                    }}
                    validators={[]}
                ></Checkbox>
            </div>
            {queueConfigForm()}
            {bandWidthForm()}
        </React.Fragment >
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'you should active the queues used on QoS classification rules')
            return t('advanced.qos_policy.error.QUEUE_DISABLED')
        if (errorMsg === 'the sum of active queues should be 100 or this mode need at least one queue enabled')
            return t('advanced.qos_policy.error.TOTAL_SUM')
        return errorMsg
    }

    return !qosPolicy ? <Loading show={true}></Loading> :
        <div id='advanced-qos-policy-page' className='container scroll-area'>

            <DefaultStatusModals
                saving={saving}
                error={error}
                errorText={t('common.message.error.SERVER_ERROR', { error: translateErrorMessagesFromBackend(errorMessage) })}
                success={success}
                continueFn={dismiss}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            {!isFormSegment ?

                <React.Fragment>
                    <div className='section-divider'></div>
                    <div className='subtitle'><Translator path="menu.ADVANCED_QOS_POLICY"></Translator></div>

                    <Form id='advanced-qos-policy-form'
                        onSubmit={save}
                        buttonId='button-save-advanced-qos-policy'
                        disableButton={qosPolicy.queue_mode === WEIGHT_MODE && !checkWeight() && qosPolicy.enable}
                    >
                        {systemQosPolicyForm()}
                    </Form>
                </React.Fragment> :

                <FormSegment
                    title={formSegment.title}
                    active={formSegment.active}
                    previousLabel={formSegment.previousLabel}
                    previousClicked={formSegment.previousClicked}
                    disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                    nextLabel={formSegment.nextLabel}
                    nextClicked={nextClicked}
                >
                    {systemQosPolicyForm()}
                </FormSegment>}

        </div>
}
import React, { useState, useEffect, useContext } from 'react'

import './firewall-wlan-access-control.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchFirewallWlanAccessControlList, saveFirewallWlanAccessControl } from '../../../resources/firewall-wlan-access-control';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import { LanWlanInterfaceName } from '../../../resources/wan-lan-list';
import Select from '../../../../components/select/select';
import RemoveIcon from '../../../../components/icons/remove';
import Input from '../../../../components/input/input';
import extraValidators from '../../../common/validators';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Button from '../../../../components/button/button';

const MAX_MAC_RULES_PER_RADIO = 32

const WLAN_5GHz_ID = 'wlan0'
const WLAN_2_4GHz_ID = 'wlan1'

const WlanAccessControlMode = {
    WLAN_ACCESS_CONTROL_DISABLED : 0,
    WLAN_ACCESS_CONTROL_ALLOW: 1,
    WLAN_ACCESS_CONTROL_DENY: 2
}

const WlanAccessControlRadioSelect = {
    _5GHz: 0,
    _2_4GHz : 1,
    Both: 2
}


export default function FirewallWlanAccessControl({isWizard, setSaved, isFormSegment, formSegment}) {
    const [wlanAccessControl, setWlanAccessControl] = useState(null)

    const [wlanAccessControlPagination, setWlanAccessControlPagination] = useState(null)
    const [radioSelect, setRadioSelect] = useState(WlanAccessControlRadioSelect._5GHz)
    const [MACToAdd, setMACToAdd] = useState('')

    const backend = useContext(BackendContext)

    const [errorMessage, setErrorMessage] = useState(null)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        setWlanAccessControlPagination({pageNumber: 1, totalElements: 0, pageSize: 10})
        fetchFirewallWlanAccessControlList(backend, setWlanAccessControl)

        // eslint-disable-next-line
    }, [])

    const getColumns = () => {

        return [
            {header: t('common.label.NUMBER'), align: 'center', size: '30px'},
            {header: t('common.label.MAC_ADDRESS'), align: 'center'},
            {header: t('firewall.wlan-access-control.label.RADIO'), align: 'center', size: '100px'},
            { header: t('firewall.wlan-access-control.label.ACTIONS'), align: 'center', size: '50px' },
        ]
    }

    const changePage = page => {
        setWlanAccessControlPagination({...wlanAccessControlPagination, pageNumber: page})
    }

    const getFirewallWlanAccessControl = () => {

        let wlanAccessControlFiltered = []

        wlanAccessControl.forEach(wlan => {
            wlan.mac_list.forEach( mac => { 
                wlanAccessControlFiltered.push({wifi_id: wlan.id, mac: mac})
            })
        })

        if(wlanAccessControlFiltered.length !== wlanAccessControlPagination.totalElements) {
            wlanAccessControlPagination.totalElements = wlanAccessControlFiltered.length
            setWlanAccessControlPagination({...wlanAccessControlPagination})
        }

        let wlanAccessControlLines = [];

        let begining = (wlanAccessControlPagination.pageNumber - 1) * (wlanAccessControlPagination.pageSize)
        let end = begining + wlanAccessControlPagination.pageSize
        if (end > wlanAccessControlPagination.totalElements) {
            end = wlanAccessControlPagination.totalElements
        }

        for(let i = begining, nr = (1 + (wlanAccessControlPagination.pageNumber - 1) * wlanAccessControlPagination.pageSize); i < end; i++) {
            wlanAccessControlLines.push([
                nr++,
                wlanAccessControlFiltered[i].mac,
                LanWlanInterfaceName[wlanAccessControlFiltered[i].wifi_id],
                <div >
                    <span className="clickable" onClick={async () => {
                        setWlanAccessControl((wlanAccessControl) => {
                            let index = wlanAccessControl.findIndex(wlan => wlan.id === wlanAccessControlFiltered[i].wifi_id)
                            wlanAccessControl[index].mac_list = wlanAccessControl[index].mac_list.filter(mac => mac !== wlanAccessControlFiltered[i].mac)
                            return [...wlanAccessControl]
                        })
                    }
                    }><RemoveIcon /></span>
                </div>
            ]);
        }

        return wlanAccessControlLines;
    }

    const updateWlanAccessControlMode = async(wlan_access_control) => {
        setSaving(true)
        if(Array.isArray(wlan_access_control)) {
            for (let i = 0; i < wlan_access_control.length; i++) {
                if(!await saveFirewallWlanAccessControl(backend, wlan_access_control[i], setErrorMessage)) {
                    setError(true)
                    break;
                }
            }
        } 
        else {
            if(!await saveFirewallWlanAccessControl(backend, wlan_access_control, setErrorMessage))
                setError(true)
        }
        setSaving(false)
        await fetchFirewallWlanAccessControlList(backend, setWlanAccessControl)
    }

    const checkAvailableSpace = (radio) =>  {
        if(radio === WlanAccessControlRadioSelect._5GHz )
            return  (wlanAccessControl[WlanAccessControlRadioSelect._5GHz].mac_list.length >= MAX_MAC_RULES_PER_RADIO)
        else if (radio === WlanAccessControlRadioSelect._2_4GHz)
            return (wlanAccessControl[WlanAccessControlRadioSelect._2_4GHz].mac_list.length >= MAX_MAC_RULES_PER_RADIO)
        else
            return  (wlanAccessControl[WlanAccessControlRadioSelect._5GHz].mac_list.length >= MAX_MAC_RULES_PER_RADIO) || 
                    (wlanAccessControl[WlanAccessControlRadioSelect._2_4GHz].mac_list.length >= MAX_MAC_RULES_PER_RADIO)
    }

    const dismiss = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
    }

    const checkIfMACExist = (mac, radio) => {
        if(radio === WlanAccessControlRadioSelect._5GHz)
            return  wlanAccessControl[WlanAccessControlRadioSelect._5GHz].mac_list.includes(mac)
        else if (radio === WlanAccessControlRadioSelect._2_4GHz)
            return wlanAccessControl[WlanAccessControlRadioSelect._2_4GHz].mac_list.includes(mac)
        else
            return  wlanAccessControl[WlanAccessControlRadioSelect._5GHz].mac_list.includes(mac) || 
                    wlanAccessControl[WlanAccessControlRadioSelect._2_4GHz].mac_list.includes(mac)
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg?.includes("Invalid MAC address"))
            return t('firewall.wlan-access-control.error.INVALID_MAC_ADDR')
        return errorMsg
    }


    const wlanAccessControlForm = () => {
        return <React.Fragment>

            <DefaultStatusModals
                saving={saving}
                error={error}
                errorText={t('common.message.error.SERVER_ERROR', { error: translateErrorMessagesFromBackend(errorMessage) })}
                success={success}
                continueFn={dismiss}
            ></DefaultStatusModals>

            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="menu.FIREWALL_WLAN_ACCESS_CONTROL"></Translator></div>

            <div className='card mt2'>
                <div className='subtitle'> <Translator path="firewall.wlan-access-control.label.POLICY_CONFIG"></Translator></div>
                <div style={{display: 'flex'}}>
                    {wlanAccessControl.map( (wlan, key) => {
                        return <Select
                            key={key}
                            id='firewall-wlan-access-control-wlan0-mode'
                            name={LanWlanInterfaceName[wlan.id]}
                            label={LanWlanInterfaceName[wlan.id]}
                            options={
                                [{
                                    value: WlanAccessControlMode.WLAN_ACCESS_CONTROL_ALLOW,
                                    text: t('firewall.wlan-access-control.label.ALLOW')
                                },
                                {
                                    value: WlanAccessControlMode.WLAN_ACCESS_CONTROL_DENY,
                                    text: t('firewall.wlan-access-control.label.DENY')
                                },
                                {
                                    value: WlanAccessControlMode.WLAN_ACCESS_CONTROL_DISABLED,
                                    text: t('firewall.wlan-access-control.label.DISABLED')
                                }]
                            }
                            value={wlan.mode}
                            onChange={(e) => {
                                let index = wlanAccessControl.findIndex(wac => wac.id === wlan.id)
                                wlanAccessControl[index].mode = Number(e.target.value)
                                setWlanAccessControl([...wlanAccessControl])
                            }}
                        ></Select>
                    })}
                </div>
            </div>

            <div className='card mt2'>
                <div className='subtitle'> <Translator path="firewall.wlan-access-control.label.NEW_MAC_ADDR"></Translator></div>

                <Form
                    onSubmit={() => {
                        if(radioSelect === WlanAccessControlRadioSelect._5GHz || radioSelect === WlanAccessControlRadioSelect.Both) {
                            setWlanAccessControl((wlanAccessControl) => {
                                let index = wlanAccessControl.findIndex(wlan => wlan.id === WLAN_5GHz_ID)
                                wlanAccessControl[index].mac_list.push(MACToAdd)
                                return [...wlanAccessControl]
                            })
                        }
                        if(radioSelect === WlanAccessControlRadioSelect._2_4GHz || radioSelect === WlanAccessControlRadioSelect.Both ) {
                            setWlanAccessControl((wlanAccessControl) => {
                                let index = wlanAccessControl.findIndex(wlan => wlan.id === WLAN_2_4GHz_ID)
                                wlanAccessControl[index].mac_list.push(MACToAdd)
                                return [...wlanAccessControl]
                            })
                        }
                    }}
                    submitText={<Translator path="common.label.ADD"></Translator>}
                    buttonId='button-ADD'
                    disableButton={
                        checkAvailableSpace(radioSelect) || checkIfMACExist(MACToAdd, radioSelect)
                    }
                >

                    <div style={{display: 'flex'}}>
                        <Input
                            id='firewall-wlan-access-control-add-mac'
                            name='firewall-wlan-access-control-add-mac'
                            label={t('firewall.wlan-access-control.label.ADD_NEW_MAC')}
                            type='text'
                            placeholder={'00:00:00:00:00:00'}
                            value={MACToAdd}
                            onChange={(e) => {
                                setMACToAdd(e.target.value)
                            }}
                            validators={[
                                extraValidators.required,
                                extraValidators.validateMAC,
                                extraValidators.validateMACMulticastBroadcast
                            ]}
                        ></Input>

                        <Select
                            id='firewall-wlan-access-control-wlan0-wifi-select'
                            name='firewall-wlan-access-control-wlan0-wifi-select'
                            label={t('firewall.wlan-access-control.label.RADIO_SELECT')}
                            options={
                                [{
                                    value: WlanAccessControlRadioSelect._5GHz,
                                    text: LanWlanInterfaceName[WLAN_5GHz_ID]
                                },
                                {
                                    value: WlanAccessControlRadioSelect._2_4GHz,
                                    text: LanWlanInterfaceName[WLAN_2_4GHz_ID]
                                },
                                {
                                    value: WlanAccessControlRadioSelect.Both,
                                    text: 'Ambos'
                                }]
                            }
                            value={radioSelect}
                            onChange={(e) => {
                                setRadioSelect(Number(e.target.value))
                            }}
                        ></Select>
                    </div>
            </Form>
            {
                (   
                    checkAvailableSpace(WlanAccessControlRadioSelect._2_4GHz) && 
                    checkAvailableSpace(WlanAccessControlRadioSelect._5GHz)
                ) ?  <div className='firewall-wlan-access-control-warning'><Translator path='firewall.wlan-access-control.warn.2.4GHZ_AND_5GHZ_LIMIT'/></div> 
                : 
                (   
                    radioSelect !== WlanAccessControlRadioSelect._2_4GHz && 
                    checkAvailableSpace(WlanAccessControlRadioSelect._5GHz)
                ) ?  <div className='firewall-wlan-access-control-warning'><Translator path='firewall.wlan-access-control.warn.5GHZ_LIMIT'/></div> 
                : 
                (   
                    radioSelect !== WlanAccessControlRadioSelect._5GHz && 
                    checkAvailableSpace(WlanAccessControlRadioSelect._2_4GHz)
                ) ? <div className='firewall-wlan-access-control-warning'><Translator path='firewall.wlan-access-control.warn.2.4GHZ_LIMIT'/></div> 
                : 
                (   
                    checkIfMACExist(MACToAdd, WlanAccessControlRadioSelect._2_4GHz) && 
                    checkIfMACExist(MACToAdd, WlanAccessControlRadioSelect._5GHz)
                ) ?  <div className='firewall-wlan-access-control-warning'><Translator path='MAC Existente em 2.4GHz e 5GHz'/></div> 
                : 
                (   
                    radioSelect !== WlanAccessControlRadioSelect._2_4GHz && 
                    checkIfMACExist(MACToAdd, WlanAccessControlRadioSelect._5GHz)
                ) ? 
                    <div className='firewall-wlan-access-control-warning'><Translator path='MAC Existente em 5GHz'/></div> 
                : 
                (   
                    radioSelect !== WlanAccessControlRadioSelect._5GHz &&
                    checkIfMACExist(MACToAdd, WlanAccessControlRadioSelect._2_4GHz) 
                ) ?
                    <div className='firewall-wlan-access-control-warning'><Translator path='MAC Existente em 2.4GHz'/></div> 
                : ''
            }
        </div>

            {getFirewallWlanAccessControl().length > 0 ?

            <div id='firewall-wlan-access-control-table'>
                <div className='firewall-wlan-access-control-list-wrapper'>
                    <List
                        lines={getFirewallWlanAccessControl()}
                        columns={getColumns()}
                    ></List>
                </div>
                <Pager
                    pageNumber={wlanAccessControlPagination.pageNumber}
                    totalElements={wlanAccessControlPagination.totalElements}
                    pageSize={wlanAccessControlPagination.pageSize}
                    pageChangeFn={changePage}>
                </Pager>
            </div>
            :
            <div id='firewall-wlan-access-control-no-elements'>
                <div className='info-card noelements-card'>
                    <Translator path="firewall.wlan-access-control.info.NO_ELEMENTS"/>
                </div>
            </div>
            }

            <Button
                type='button'
                id='btn-add-url'
                text={<Translator path="common.label.SAVE"></Translator>}
                onClick={()=>updateWlanAccessControlMode(wlanAccessControl)}
            ></Button>

        </React.Fragment>
    }

    return (
        !wlanAccessControl ? <Loading show={true}></Loading> :

        <div id='firewall-wlan-access-control-page' className='with-tabs'>

            { wlanAccessControlForm() }

        </div>
    )
}

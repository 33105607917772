import React, { useState, useEffect, useContext } from 'react'

import './ipv6-ip-port-filter.css'

import { fetchIpv6IpPortFilter, deleteIpv6IpPortFilterById, fetchIpv6IpPortFilterAction, saveIpv6IpPortFilterAction } from '../../../resources/ipv6-ip-port-filter';
import { BackendContext } from '../../../../backend/backend';

import waitRouter from '../../../utils/wait-router'
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import Select from '../../../../components/select/select';
import List from '../../../../components/list/list';
import RemoveIcon from '../../../../components/icons/remove';
import EditIcon from '../../../../components/icons/edit';
import Pager from '../../../../components/pager/pager';
import Button from '../../../../components/button/button'
import { useHistory } from 'react-router-dom'
import { GlobalsContext } from '../../../globals-context'
import { fetchServiceSystem } from '../../../resources/system';
import { createLimitedTableCell } from '../../../utils/common';

import {
    getDirectionEnumerationElementsName,
    getRuleActionEnumerationElementsName,
    getProtocolEnumerationElementsName,
    DEFAULT_INTERFACE_ID
} from './ipv6-ip-port-filter-common'

export default function Ipv6IpPortFilter({ isWizard, isFormSegment, formSegment }) {
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [ipv6IpPortFilterAction, setIpv6IpPortFilterAction] = useState(null)
    const [ipv6IpPortFilter, setIpv6IpPortFilter] = useState(null)
    const [ipv6IpPortFilterPagination, setIpv6IpPortFilterPagination] = useState(null)
    const [system, setSystem] = useState(true)
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleteID, setDeleteID] = useState(null)

    const { t } = useTranslation()
    const history = useHistory()
    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)

    useEffect(() => {
        fetchIpv6IpPortFilterAction(backend, setIpv6IpPortFilterAction)
        fetchIpv6IpPortFilter(backend, setIpv6IpPortFilter)
        fetchServiceSystem(backend, setSystem)
        setIpv6IpPortFilterPagination({ pageNumber: 1, totalElements: 0, pageSize: 10 })
        // eslint-disable-next-line
    }, [])

    const continueDelete = async (confirm) => {
        if (success) {
            setSuccess(false)
            return
        }

        if (error) {
            setError(false)
            return
        }

        setConfirmDelete(false)

        if (confirm) {
            setSaving(true)
            await deleteIpv6IpPortFilterById(backend, deleteID, setIpv6IpPortFilter)
            waitReturn()
        }

        setDeleteID(null)
    }

    const getIpv6IpPortFilter = () => {

        let ipv6IpPortFilterFiltered = ipv6IpPortFilter

        if (ipv6IpPortFilterFiltered.length !== ipv6IpPortFilterPagination.totalElements) {
            ipv6IpPortFilterPagination.totalElements = ipv6IpPortFilterFiltered.length
            setIpv6IpPortFilterPagination({ ...ipv6IpPortFilterPagination })
        }

        let ipv6IpPortFilterLines = [];

        let begining = (ipv6IpPortFilterPagination.pageNumber - 1) * (ipv6IpPortFilterPagination.pageSize)
        let end = begining + ipv6IpPortFilterPagination.pageSize
        if (end > ipv6IpPortFilterPagination.totalElements) {
            end = ipv6IpPortFilterPagination.totalElements
        }

        for (let i = begining, nr = (1 + (ipv6IpPortFilterPagination.pageNumber - 1) * ipv6IpPortFilterPagination.pageSize); i < end; i++) {
            ipv6IpPortFilterLines.push([
                nr++,
                <div>
                    {ipv6IpPortFilterFiltered[i].src_ipv6.start ?
                        <div>{createLimitedTableCell(`${t('common.label.BEGINNING')}: ${ipv6IpPortFilterFiltered[i].src_ipv6.start}/${ipv6IpPortFilterFiltered[i].src_ipv6.prefix_length}`)}</div>
                        :
                        <div>{createLimitedTableCell(`${t('common.label.BEGINNING')}: --`)}</div>
                    }
                    {ipv6IpPortFilterFiltered[i].src_ipv6.end ?
                        <div>{createLimitedTableCell(`${t('common.label.END')}: ${ipv6IpPortFilterFiltered[i].src_ipv6.end}/${ipv6IpPortFilterFiltered[i].src_ipv6.prefix_length}`)}</div>
                        :
                        <div>{createLimitedTableCell(`${t('common.label.END')}: --`)}</div>
                    }
                </div>,
                <div>
                    {ipv6IpPortFilterFiltered[i].dst_ipv6.start ?
                        <div>{createLimitedTableCell(`${t('common.label.BEGINNING')}: ${ipv6IpPortFilterFiltered[i].dst_ipv6.start}/${ipv6IpPortFilterFiltered[i].dst_ipv6.prefix_length}`)}</div>
                        :
                        <div>{createLimitedTableCell(`${t('common.label.BEGINNING')}: --`)}</div>
                    }
                    {ipv6IpPortFilterFiltered[i].dst_ipv6.end ?
                        <div>{createLimitedTableCell(`${t('common.label.END')}: ${ipv6IpPortFilterFiltered[i].dst_ipv6.end}/${ipv6IpPortFilterFiltered[i].dst_ipv6.prefix_length}`)}</div>
                        :
                        <div>{createLimitedTableCell(`${t('common.label.END')}: --`)}</div>
                    }
                </div>,
                `${ipv6IpPortFilterFiltered[i].source_port.from} - ${ipv6IpPortFilterFiltered[i].source_port.to}`,
                `${ipv6IpPortFilterFiltered[i].destination_port.from} - ${ipv6IpPortFilterFiltered[i].destination_port.to}`,
                (ipv6IpPortFilterFiltered[i].interface_id === DEFAULT_INTERFACE_ID) ? t('common.label.ALL_INTERFACES') : ipv6IpPortFilterFiltered[i].interface_id,
                getDirectionEnumerationElementsName(ipv6IpPortFilterFiltered[i].direction, t),
                getProtocolEnumerationElementsName(ipv6IpPortFilterFiltered[i].protocol, t),
                getRuleActionEnumerationElementsName(ipv6IpPortFilterFiltered[i].rule_action, t),
                <>
                    <span className="clickable" onClick={e => editItemIpPort(ipv6IpPortFilterFiltered[i])}><EditIcon></EditIcon></span>
                    <span className="clickable" onClick={e => removeItemIpPortHandler(ipv6IpPortFilterFiltered[i])}><RemoveIcon></RemoveIcon></span>
                </>

            ]);
        }
        return ipv6IpPortFilterLines;
    }

    const getColumnsIpv6IpPortFilter = () => {
        return [
            { header: 'Nº', align: 'center', size: '50px' },
            { header: t('ipv6.ip_port_filtering.label.IPV6_ADDRESS_SOURCE'), align: 'center', size: '270px' },
            { header: t('ipv6.ip_port_filtering.label.IPV6_ADDRESS_DESTINATION'), align: 'center', size: '270px' },
            { header: t('common.label.PORT_SOURCE'), align: 'center', size: '130px' },
            { header: t('common.label.PORT_DESTINY'), align: 'center', size: '130px' },
            { header: t('common.label.INTERFACE'), align: 'center', size: '80px' },
            { header: t('common.label.DIRECTION'), align: 'center', size: '80px' },
            { header: t('common.label.PROTOCOL'), align: 'center', size: '80px' },
            { header: t('common.label.RULE'), align: 'center', size: '80px' },
            { header: t('common.label.ACTIONS'), align: 'center', size: '70px' },
        ]
    }

    const removeItemIpPortHandler = async(ipv6FilterIpPort) => {
        setDeleteID(ipv6FilterIpPort.id)
        setConfirmDelete(true)
    }

    const editItemIpPort = (ipPort) => {
        history.push(`/ipv6/ipv6-ip-port-filter/${ipPort.id}`)
    }

    const changePage = page => {
        setIpv6IpPortFilterPagination({ ...ipv6IpPortFilterPagination, pageNumber: page })
    }

    const createNewIpv6IpPortFilterForm = () => {
        history.push(`/ipv6/ipv6-ip-port-filter/add`)
    }

    const waitReturn = async () => {

        await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history,
            5000
        )

        setSaving(false)
        setSuccess(true)
    }

    const hasIPv6Requiremnts = () => {
        if(isWizard || system === true) {
            return true
        }

        if(system) {
            if(system.ipv6) {
                if(system.ipv6.enabled) {
                    return true
                }
            }
        }

        return false
    }


    /**
     * Default Actions Form
     * 
     */
    const ActionIpv6IpPortFilterForm = () => {
        return <div>
            <div className='subtitle'> <Translator path="ipv6.ip_port_filtering.title.DEFAULT_ACTIONS"></Translator> </div>
            <div className='ipv6_ip_port_filtering_d_flex'>
                <Select
                    id='ip6-port-filtering-incoming-default-act'
                    name='incoming_default_act'
                    label={<Translator path="ipv6.ip_port_filtering.label.ACTION_DEFAULT_INCOMING"></Translator>}
                    options={[{ value: 1, text: t('common.label.ALLOW') }, { value: 0, text: t('common.label.DENY') }]}
                    value={ipv6IpPortFilterAction?.default_incoming_action}
                    onChange={(e) => {
                        ipv6IpPortFilterAction.default_incoming_action = Number(e.target.value)
                        setIpv6IpPortFilterAction({ ...ipv6IpPortFilterAction })
                        setSaving(true)
                        saveIpv6IpPortFilterAction(backend, ipv6IpPortFilterAction, setErrorMessage)
                        waitReturn()
                    }}
                ></Select>

                <Select
                    id='ip6-port-filtering-outgoing-default-act'
                    name='outgoing_default_act'
                    label={<Translator path="ipv6.ip_port_filtering.label.ACTION_DEFAULT_OUTGOING"></Translator>}
                    options={[{ value: 1, text: t('common.label.ALLOW') }, { value: 0, text: t('common.label.DENY') }]}
                    value={ipv6IpPortFilterAction?.default_outgoing_action}
                    onChange={(e) => {
                        ipv6IpPortFilterAction.default_outgoing_action = Number(e.target.value)
                        setIpv6IpPortFilterAction({ ...ipv6IpPortFilterAction })
                        setSaving(true)
                        saveIpv6IpPortFilterAction(backend, ipv6IpPortFilterAction, setErrorMessage)
                        waitReturn()
                    }}
                ></Select>
            </div>
        </div>
    }

    return !ipv6IpPortFilter ? <Loading show={true}></Loading> :
        <div id='ipv6-ip-port-filter-page'>
            <div className='subtitle'> {<Translator path="menu.IPV6_IP_PORT_FILTER"></Translator>}</div>
            <div className='card mt2'>
                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                    success={success}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    confirm={confirmDelete}
                    continueFn={continueDelete}
                    confirmContent={
                        <div style={{ width: '420px' }}>
                            <span style={{ display: 'block' }}><Translator path="ipv6.ip_port_filter.delete.DELETE_WARNING"></Translator>
                                <br></br>
                            </span>
                            <b><Translator path="ipv6.ip_port_filter.delete.DELETE_CONFIRM"></Translator></b>
                        </div>
                    }
                ></DefaultStatusModals>

                {
                    hasIPv6Requiremnts() ? null : 
                    <div>
                        <p className='ipv6-ip-port-filter-info'>
                            {t(`ipv6.ip_port_filtering.message.IPV6_DISABLED`)}
                            <br/>
                            {t(`common.message.warning.IPV6_ENABLE`)}
                            <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                        </p>
                    </div>
                }

                {ActionIpv6IpPortFilterForm()}
            </div>

            <div className='subtitle'> {<Translator path="ipv6.ip_port_filtering.title.FILTER_LIST"></Translator>}</div>
            { (ipv6IpPortFilter.length > 0) ?
                <div>
                    <div className="ipv6-ip-port-filter-list-wrapper">
                        <List
                            width={1300}
                            lines={getIpv6IpPortFilter()}
                            columns={getColumnsIpv6IpPortFilter()}
                        ></List>
                    </div>
                    <Pager
                        pageNumber={ipv6IpPortFilterPagination.pageNumber}
                        totalElements={ipv6IpPortFilterPagination.totalElements}
                        pageSize={ipv6IpPortFilterPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                </div> :

                <div id='ipv6-ip-port-filter-page-no-rules'>
                    <div className='info-card noelements-card'>
                        <Translator path="ipv6.ip_port_filter.messages.NO_RULES"></Translator>
                    </div>
                </div>
            }
            <Button
                id='new_qos_traffic_control'
                text={t('ipv6.ip_port_filtering.label.ADD_NEW_RULE')}
                onClick={createNewIpv6IpPortFilterForm}
                disabled={!hasIPv6Requiremnts()}
            ></Button>
        </div>
}
import React, { useState, useEffect, useContext } from 'react'

import './ipv6-prefix-delegation.css'

import { fetchIpv6PrefixDelegation, updateIpv6PrefixDelegation, PrefixDelegationMode } from '../../../resources/ipv6-prefix-delegation';
import { fetchNetworkWanList } from '../../../resources/network-wan';
import { BackendContext } from '../../../../backend/backend';
import { Protocol } from '../../network/network-wan/network-wan-common';

import Input from '../../../../components/input/input';
import Select from '../../../../components/select/select';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form from '../../../../components/form/form';
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator';
import common from '../../../../components/form/validators/common';
import extraValidators from '../../../common/validators';

export default function Ipv6PrefixDelegation({ isWizard, isFormSegment, formSegment }) {
    let [saving, setSaving] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [prefixDelegation, setPrefixDelegation] = useState(null)
    const [interfaces, setInterfaces] = useState(null)

    const getIPv6PrefixInterfaces = () => {
        let ipv6PrefixInterfaces = [];

        for (let i = 0; i < interfaces?.length; i++) {
            if (interfaces[i].protocol !== Protocol.IPV4) {
                if (interfaces[i].prefix_enable) {
                    ipv6PrefixInterfaces.push({ value: i, text: interfaces[i].interfaceID })
                }
            }
        }

        return ipv6PrefixInterfaces;
    }

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchIpv6PrefixDelegation(backend, setPrefixDelegation)
        fetchNetworkWanList(backend, setInterfaces)
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if (interfaces) {
            let interfaceIPv6 = [];
            interfaceIPv6 = getIPv6PrefixInterfaces();

            if (interfaceIPv6?.length > 0) {
                if (!prefixDelegation?.interfaceID && prefixDelegation?.mode === PrefixDelegationMode.PD_WAN_DELEGATED) {
                    setPrefixDelegation({ ...prefixDelegation, interfaceID: interfaceIPv6[0].text, prefix: "" })
                }
            } else {
                setPrefixDelegation({ ...prefixDelegation, interfaceID: "" })
            }
        }
        // eslint-disable-next-line
    }, [interfaces, prefixDelegation?.mode])

    let save = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await updateIpv6PrefixDelegation(backend, prefixDelegation, setErrorMessage)
        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)
    }

    let dismiss = () => {
        setSuccess(false)
        setError(false)
    }

    const nextClicked = () => {
        if (isWizard) {
            formSegment.nextClicked()
        }
    }

    const wanDelegated = () => {
        return <Select name='ipv6-prefix-delegation-interface'
            id='ipv6-prefix-delegation-interface'
            label={t('ipv6.prefix-delegation.label.INTERFACE')}
            options={getIPv6PrefixInterfaces()}
            value={interfaces.map(iface => iface.interfaceID).indexOf(prefixDelegation.interfaceID)}
            onChange={(e) => setPrefixDelegation({ ...prefixDelegation, interfaceID: interfaces[+e.target.value].interfaceID })}
            validators={[]}
        ></Select>
    }

    const wanStatic = () => {

        return <Input name='ipv6-prefix-delegation-prefix'
            id='ipv6-prefix-delegation-prefix'
            label={t('ipv6.prefix-delegation.label.PREFIX')}
            value={prefixDelegation.prefix}
            // disabled={prefixDelegation.mode !== PrefixDelegationMode.PD_STATIC}
            disabled={false}
            onChange={(e) => setPrefixDelegation({ ...prefixDelegation, prefix: e.target.value, interfaceID: "" })}
            validators={
                [common.nonASCII, extraValidators.validateIPv6Prefix, extraValidators.required]
            }
        ></Input>
    }

    const systemPrefixDelegationForm = () => {
        return <React.Fragment>
            <div className='card mt2'>
                <div className='subtitle'> <Translator path="ipv6.prefix-delegation.title.PREFIX_DELEGATION"></Translator></div>
                <Select name='ipv6-prefix-delegation-mode'
                    id='ipv6-prefix-delegation-mode'
                    label={t('ipv6.prefix_delegation.label.PREFIX_MODE')}
                    clearErrors={true}
                    options={[
                        { value: PrefixDelegationMode.PD_STATIC, text: t('ipv6.prefix_delegation.label.PREFIX_MODE_STATIC') },
                        { value: PrefixDelegationMode.PD_WAN_DELEGATED, text: t('ipv6.prefix_delegation.label.PREFIX_MODE_WAN_DELEGATED') }
                    ]}
                    value={prefixDelegation.mode}
                    onChange={(e) => setPrefixDelegation({ ...prefixDelegation, mode: +e.target.value })}
                ></Select>

                {
                    prefixDelegation.mode === PrefixDelegationMode.PD_WAN_DELEGATED && wanDelegated()
                }

                {
                    prefixDelegation.mode === PrefixDelegationMode.PD_STATIC && wanStatic()
                }
            </div>
        </React.Fragment>
    }

    return !prefixDelegation || !interfaces ? <Loading show={true}></Loading> :
        <div id='ipv6-prefix-delegation-page' className='container scroll-area'>

            <DefaultStatusModals
                saving={saving}
                error={error}
                errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                success={success}
                continueFn={dismiss}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            {!isFormSegment ?

                <React.Fragment>
                    <div className='subtitle'><Translator path="menu.IPV6_PREFIX_DELEGATION"></Translator></div>

                    <Form id='ipv6-prefix-delegation-form'
                        onSubmit={save}
                        disableButton={getIPv6PrefixInterfaces().length === 0 && prefixDelegation?.mode === PrefixDelegationMode.PD_WAN_DELEGATED}
                        buttonId='button-save-ipv6-prefix-delegation'
                        isPristine={false}
                    >
                        {systemPrefixDelegationForm()}
                    </Form>
                </React.Fragment> :

                <FormSegment
                    title={formSegment.title}
                    active={formSegment.active}
                    previousLabel={formSegment.previousLabel}
                    previousClicked={formSegment.previousClicked}
                    disableValidatorsPrevious={formSegment.disableValidatorsPrevious}
                    nextLabel={formSegment.nextLabel}
                    nextClicked={nextClicked}
                >
                    {systemPrefixDelegationForm()}
                </FormSegment>}

        </div>
}
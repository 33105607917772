export default {

    getRandomColor: () => {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },

    isRemotizeUrl: (value) => {
        if (value.includes('remotize.intelbras.com.br')) {
            return "A URL do remotize não pode ser bloqueada"
        }
        return ""
    },

    getSignalQuality: (signal_level) => {
        if(signal_level > -66){
            return 'Bom'
        }
        if(signal_level >= -75 && signal_level <= -66){
            return 'Moderado'
        } else {
            return 'Ruim'
        }
    }

}

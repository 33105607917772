import React, { useState, useEffect, useContext } from 'react'

import { useParams } from 'react-router-dom'

import './voip-proxy.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipProxy, saveVoipProxy } from '../../../resources/voip-proxy';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/button/button';

export default function VoipProxy({isWizard, setSaved, isFormSegment, formSegment, history}) {
    const [voipProxy, setVoipProxy] = useState(null)

    const params = useParams()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)


    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchVoipProxy(params.id, backend, setVoipProxy)

        // eslint-disable-next-line
    }, [])

    const optionalRequired = (value, params) => {
        return voipProxy[params.field] ? (!value ? t('common.message.error.REQUIRED_FIELD') : ''): ''
    }


    function voipInternalTranslateRegisterStatus(register_status) {
        switch(register_status)  {
            case 'Not registered':
                return t('voip.proxy.label.register_status.NOT_REGISTERED')
            case 'Registered':
                return t('voip.proxy.label.register_status.REGISTERED')
            case 'Registering':
                return t('voip.proxy.label.register_status.REGISTERING')
            case 'Register fail':
                return t('voip.proxy.label.register_status.REGISTER_FAIL')
            case 'VoIP restart':
                return t('voip.proxy.label.register_status.VOIP_RESTART')
            default:
                return t('voip.proxy.label.register_status.ERROR')
        }
    }

    function hasRequiredFieldToFill() {
        if(voipProxy.number === '' || voipProxy.proxy_addr === '' || voipProxy.proxy_port === '')
            return true

        if(voipProxy.sip_subscribe) {
            if(voipProxy.sip_domain === '' || voipProxy.reg_expire === '')
                return true
        }
        
        if(voipProxy.outbound_proxy_enable) {
            if(voipProxy.outbound_proxy_addr === '' || voipProxy.outbound_proxy_port === '')
                return true 
        }

        if(voipProxy.session_time_enable) {
            if(voipProxy.session_update_timer === '')
                return true
        }

        return false
    }

    const voipProxyForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            
                <div className="card mt2">
                    <div className='subtitle'><Translator path="voip.proxy.title.GENERAL_CONFIG"></Translator></div>

                    <Checkbox id='voip-proxy-proxy-enable'
                        name='proxy_enable'
                        label={<Translator path="voip.proxy.label.ENABLED"></Translator>}
                        value={voipProxy.proxy_enable}
                        toggleFn={(e) => {
                            voipProxy.proxy_enable = !voipProxy.proxy_enable;
                            setVoipProxy({...voipProxy})
                        }}
                        validators={[]}
                    ></Checkbox>
            
                    <Input id='voip-proxy-display-name'
                        name='display_name'
                        label={<Translator path="voip.proxy.label.NAME" required={false}></Translator>}
                        value={voipProxy.display_name}
                        onChange={(e) => {
                            voipProxy.display_name = e.target.value
                            setVoipProxy({...voipProxy})
                        }}
                        validators={[extraValidators.nonASCII, {fn: extraValidators.size, params: {min: 0, max: 39}}]}
                    ></Input>
                    <Input id='voip-proxy-register-status'
                        name='register_status'
                        label={<Translator path="voip.proxy.label.REGISTER_STATUS"></Translator>}
                        value={voipInternalTranslateRegisterStatus(voipProxy.register_status)}
                        readOnly={true}
                    ></Input>
                    <Checkbox id='voip-proxy-default-proxy'
                        name='default_proxy'
                        label={<Translator path="voip.proxy.label.DEFAULT_PROXY"></Translator>}
                        value={voipProxy.default_proxy}
                        toggleFn={(e) => {
                            voipProxy.default_proxy = !voipProxy.default_proxy;
                            setVoipProxy({...voipProxy})
                        }}
                        validators={[]}
                    ></Checkbox>
                    <Input id='voip-proxy-number'
                        name='number'
                        label={<Translator path="voip.proxy.label.NUMBER" required={true}></Translator>}
                        value={voipProxy.number}
                        onChange={(e) => {
                            voipProxy.number = e.target.value
                            setVoipProxy({...voipProxy})
                        }}
                        validators={[extraValidators.nonASCII, extraValidators.isNumber, {fn: extraValidators.size, params: {min: 1, max: 39}}]}
                    ></Input>
                    <Input id='voip-proxy-login-id'
                        name='login_id'
                        label={<Translator path="voip.proxy.label.LOGIN_ID"></Translator>}
                        value={voipProxy.login_id}
                        onChange={(e) => {
                            voipProxy.login_id = e.target.value
                            setVoipProxy({...voipProxy})
                        }}
                        validators={[extraValidators.nonASCII, {fn: extraValidators.size, params: {min: 0, max: 39}}]}
                    ></Input>
                    <Input id='voip-proxy-password' type='password'
                        name='password'
                        label={<Translator path="voip.proxy.label.PASSWORD"></Translator>}
                        value={voipProxy.password}
                        onChange={(e) => {
                            voipProxy.password = e.target.value
                            setVoipProxy({...voipProxy})
                        }}
                        validators={[extraValidators.nonASCII, {fn: extraValidators.size, params: {min: 0, max: 39}}]}
                    ></Input>
                </div>
            <div className="card mt2">
            <div className='subtitle'><Translator path="voip.proxy.title.CONNECTION_INFO"></Translator></div>
                <Input id='voip-proxy-proxy-addr'
                    name='proxy_addr'
                    label={<Translator path="voip.proxy.label.PROXY_ADDR" required={true}></Translator>}
                    value={voipProxy.proxy_addr}
                    onChange={(e) => {
                        voipProxy.proxy_addr = e.target.value
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[extraValidators.required, extraValidators.nonASCII, {fn: extraValidators.size, params: {min: 1, max: 39}}]}
                ></Input>
                <Input id='voip-proxy-proxy-port'
                    name='proxy_port'
                    label={<Translator path="voip.proxy.label.PORT" required={true}></Translator>}
                    value={voipProxy.proxy_port}
                    onChange={(e) => {
                        voipProxy.proxy_port = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[extraValidators.required, extraValidators.nonASCII, extraValidators.isNumber, {fn: extraValidators.value, params: {min: 1, max: 65535}}]}
                ></Input>
            </div>
        
            <div className="card mt2">
                <div className='subtitle'><Translator path="voip.proxy.title.SIP_INFO"></Translator></div>
                <Checkbox id='voip-proxy-sip-subscribe'
                    name='sip_subscribe'
                    label={<Translator path="voip.proxy.label.SIP_SUBSCRIBE"></Translator>}
                    value={voipProxy.sip_subscribe}
                    toggleFn={(e) => {
                        voipProxy.sip_subscribe = !voipProxy.sip_subscribe;
                        if ( !voipProxy.sip_subscribe ){
                        voipProxy.sip_domain = ''
                        voipProxy.reg_expire = 3600
                        }
                        setVoipProxy({...voipProxy})
                    }}
                ></Checkbox>
                <Input id='voip-proxy-sip-domain'
                    name='sip_domain'
                    label={<Translator path="voip.proxy.label.SIP_DOMAIN" required={voipProxy.sip_subscribe}></Translator>}
                    value={voipProxy.sip_domain}
                    onChange={(e) => {
                        voipProxy.sip_domain = e.target.value
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[{fn: optionalRequired, params: {field: 'sip_subscribe'}}, extraValidators.nonASCII, {fn: extraValidators.size, params: {min: 0, max: 39}}]}
                    disabled={!voipProxy.sip_subscribe}
                    dependentValues={[voipProxy.sip_subscribe]}
                ></Input>
                <Input id='voip-proxy-reg-expire'
                    name='reg_expire'
                    label={<Translator path="voip.proxy.label.REG_EXPIRE" required={voipProxy.sip_subscribe}></Translator>}
                    value={voipProxy.reg_expire}
                    onChange={(e) => {
                        voipProxy.reg_expire = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[{fn: optionalRequired, params: {field: 'sip_subscribe'}}, extraValidators.nonASCII, extraValidators.isNumber, {fn: extraValidators.value, params: {min: 10, max: 86400}}]}
                    disabled={!voipProxy.sip_subscribe}
                    dependentValues={[voipProxy.sip_subscribe]}
                ></Input>
            </div>

            <div className="card mt2">
                <div className='subtitle'><Translator path="voip.proxy.title.OUTBOUND_PROXY"></Translator></div>
                <Checkbox id='voip-proxy-outbound-proxy-enable'
                    name='outbound_proxy_enable'
                    label={<Translator path="voip.proxy.label.OUTBOUND_PROXY_ENABLE"></Translator>}
                    value={voipProxy.outbound_proxy_enable}
                    toggleFn={(e) => {
                        voipProxy.outbound_proxy_enable = !voipProxy.outbound_proxy_enable;

                        if(!voipProxy.outbound_proxy_enable) {
                            voipProxy.outbound_proxy_addr = ''
                            voipProxy.outbound_proxy_port = 5060
                        }
                        setVoipProxy({...voipProxy})
                    }}
                ></Checkbox>
                
                <Input id='voip-proxy-outbound-proxy-addr'
                    name='outbound_proxy_addr'
                    label={<Translator path="voip.proxy.label.OUTBOUND_PROXY_ADDR" required={voipProxy.outbound_proxy_enable}></Translator>}
                    value={voipProxy.outbound_proxy_addr}
                    onChange={(e) => {
                        voipProxy.outbound_proxy_addr = e.target.value
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[{fn: optionalRequired, params: {field: 'outbound_proxy_enable'}}, extraValidators.nonASCII, {fn: extraValidators.size, params: {min: 0, max: 39}}]}
                    disabled={!voipProxy.outbound_proxy_enable}
                    dependentValues={[voipProxy.outbound_proxy_enable]}
                ></Input>
                <Input id='voip-proxy-outbound-proxy-port'
                    name='outbound_proxy_port'
                    label={<Translator path="voip.proxy.label.OUTBOUND_PROXY_PORT" required={voipProxy.outbound_proxy_enable}></Translator>}
                    value={voipProxy.outbound_proxy_port}
                    onChange={(e) => {
                        voipProxy.outbound_proxy_port = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[{fn: optionalRequired, params: {field: 'outbound_proxy_enable'}}, extraValidators.nonASCII, extraValidators.isNumber, {fn: extraValidators.value, params: {min: 1, max: 65535}}]}
                    disabled={!voipProxy.outbound_proxy_enable}
                    dependentValues={[voipProxy.outbound_proxy_enable]}
                ></Input>
                    
            </div>
            <div className="card mt2">
                <div className='subtitle'><Translator path="voip.proxy.title.SESSION_INFORMATION"></Translator></div>
                <Checkbox id='voip-proxy-session-time-enable'
                    name='session_time_enable'
                    label={<Translator path="voip.proxy.label.SESSION_TIME_ENABLE"></Translator>}
                    value={voipProxy.session_time_enable}
                    toggleFn={(e) => {
                        voipProxy.session_time_enable = !voipProxy.session_time_enable;
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[]}
                ></Checkbox>
                <Input id='voip-proxy-session-update-timer'
                    name='session_update_timer'
                    type="number"
                    label={<Translator path="voip.proxy.label.SESSION_UPDATE_TIMER" required={voipProxy.session_time_enable}></Translator>}
                    value={voipProxy.session_time_enable ? voipProxy.session_update_timer : 1800}
                    onChange={(e) => {
                        voipProxy.session_update_timer = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                        setVoipProxy({...voipProxy})
                    }}
                    validators={[{fn: optionalRequired, params: {field: 'session_time_enable'}}, extraValidators.nonASCII, extraValidators.isNumber, {fn: extraValidators.value, params: {min: 1, max: 65535}}]}
                    disabled={!voipProxy.session_time_enable}
                    dependentValues={[voipProxy.session_time_enable]}
                ></Input>
            </div>
        
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveVoipProxy(backend, voipProxy)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipProxy ? <Loading show={true}></Loading> :

        <div id='voip-proxy-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="voip.proxy.title.ACCOUNT_CONFIG"></Translator></div>

            <Button id='back-to-voip-proxies'
                text={<Translator path="common.label.BACK"></Translator>}
                onClick={() => history.push('/voip/proxy')}
            ></Button>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            {!isFormSegment ? <Form
                onSubmit={submit}
                disableButton={hasRequiredFieldToFill()}
                buttonId='button-save'
            >
                {voipProxyForm()}
            </Form> : 

            <FormSegment
                title={formSegment.title} 
                active={formSegment.active} 
                nextLabel={formSegment.nextLabel} 
                nextClicked={submit} 
            >
                {voipProxyForm()}
            </FormSegment>}

        </div>
    )
}

import React from 'react';
import './midgard-app.css';
import MainHeader from './components/main-header/main-header';
import { HashRouter as Router } from 'react-router-dom'
import MainHeaderGlobalState from './components/main-header/main-header-state';
import MenuGlobalState  from './components/menu/menu-state';
import MainApp from './app/main-app';
import Backend from './backend/backend';
import NotificationsState from './components/notifications/notifications-state';

function setViewPortHeightUnit() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

setViewPortHeightUnit()

window.addEventListener('resize', () => {
    setViewPortHeightUnit()
});


function App() {


  return (
      <div className="midgard-app">

        <MainHeaderGlobalState>
          <NotificationsState>
            <MenuGlobalState>
              <Router>
                <Backend>

                  <MainHeader></MainHeader>
                  <MainApp></MainApp>

                </Backend>
              </Router>
            </MenuGlobalState>
          </NotificationsState>
        </MainHeaderGlobalState>

      </div>
  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react'

import './gpon-status.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchGPONStatus } from '../../../resources/gpon-status';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard';
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function GPONStatus({isWizard, setSaved, isFormSegment, formSegment}) {
    const [gponStatus, setGPONStatus] = useState(null)

    const backend = useContext(BackendContext)

    const handleError = () => {
        setGPONStatus({error: true})
    }

    const getStateDesc = (state) => {
        switch(state) {
            case 'O1':
                return 'Initial State'
            case 'O2':
            case 'O3':
                return 'Serial Number State'
            case 'O4':
                return 'Ranging State'
            case 'O5':
                return 'Operation State'
            case 'O6':
                return 'Intermitent LODS State'
            case 'O7':
                return 'Emergency Stop State'
            default:
                return '-'

        }
    }

    useEffect(() => {

        fetchGPONStatus(backend, setGPONStatus, handleError)

        // eslint-disable-next-line
    }, [])

    const gponStatusForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="pon.gpon_status.title.GPON_STATUS"></Translator></div>
            {   
                gponStatus?.error ? 
                <div className="align-center">
                    <Translator path="pon.gpon_status.message.error.COULD_NOT_RETRIEVE_DATA"></Translator>
                </div> :
                <div>
                    <InfoCard
                        lines={[
                            {label: <Translator path="pon.gpon_status.label.ONU_STATE"></Translator>, value: gponStatus.onu_state_id, id: 'card-gpon-status-onu-status'},
                            {label: <Translator path="pon.gpon_status.label.ONU_STATE_DESC"></Translator>, value: getStateDesc(gponStatus.onu_state_id), id: 'card-gpon-status-loid-status'},
                            {label: <Translator path="pon.gpon_status.label.ONU_ID"></Translator>, value: gponStatus.onu_id ? parseInt(gponStatus.onu_id) : '-', id: 'card-gpon-status-onu-id'},
                        ]}
                    ></InfoCard>
                    <Button
                        id='button-refresh'
                        type='button'
                        text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                        onClick={async () => {
                            await fetchGPONStatus(backend, setGPONStatus, handleError)}
                        }
                    ></Button>
                </div>
            }
        </React.Fragment>
    }

    return (
        !gponStatus ? <Loading show={true}></Loading> :

        <div id='gpon-status-page' className='with-tabs'>

            { gponStatusForm() }

        </div>
    )
}

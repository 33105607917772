import React, { useState, useEffect, useContext } from 'react'

import './parental-control-parental-control.css'
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import EditIcon from '../../../../components/icons/edit';
import RemoveIcon from '../../../../components/icons/remove';
import { ParentalControlContext } from '../parental-control-context';
import Button from '../../../../components/button/button';
import { useHistory } from 'react-router-dom';
import { getDaysArray, MAX_RULES_NUM } from './parental-control-parental-control-common';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { createLimitedTableCell } from '../../../utils/common';

const PARENTAL_CONTROL_ADDR_IP = 0

export default function ParentalControlParentalControl({ isWizard, setSaved, isFormSegment, formSegment }) {

    const [parentalControlPagination, setParentalControlParentalControlPagination] = useState({ pageNumber: 1, totalElements: 0, pageSize: 10 })
    const [errorMessage, setErrorMessage] = useState('')
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [success, setSuccess] = useState(false)

    const { t } = useTranslation()
    const parentCtrlCtx = useContext(ParentalControlContext)
    const history = useHistory()

    useEffect(() => {
        parentCtrlCtx.retrieveParentalControl()

        // eslint-disable-next-line
    }, [])

    const getColumns = () => {

        return [
            { header: t('common.label.NUMBER'), align: 'center', size: '30px' },
            { header: t('parental_control.parental_control.label.RULE_NAME'), align: 'center', size: '200px' },
            { header: t('parental_control.parental_control.label.RULE_TYPE'), align: 'center', size: '120px' },
            { header: t('parental_control.parental_control.label.ADDRESSES'), align: 'center', size: '240px' },
            { header: t('parental_control.parental_control.label.DAYS'), align: 'center', size: '200px' },
            { header: t('parental_control.parental_control.label.START_TIME'), align: 'center', size: '130px' },
            { header: t('parental_control.parental_control.label.END_TIME'), align: 'center', size: '130px' },
            { header: t('parental_control.parental_control.label.ACTIONS'), align: 'center', size: '100px' },
        ]
    }

    const changePage = page => {
        setParentalControlParentalControlPagination({ ...parentalControlPagination, pageNumber: page })
    }

    const addLeadingZeros = (num, totalLength) => {
        return String(num).padStart(totalLength, '0');
    }

    const getParentalControlParentalControl = () => {

        let parentalControlFiltered = parentCtrlCtx.parentalControlRes?.parental_control_list

        if (parentalControlFiltered.length !== parentalControlPagination.totalElements) {
            parentalControlPagination.totalElements = parentalControlFiltered.length
            setParentalControlParentalControlPagination({ ...parentalControlPagination })
        }

        let parentalControlLines = [];

        let begining = (parentalControlPagination.pageNumber - 1) * (parentalControlPagination.pageSize)
        let end = begining + parentalControlPagination.pageSize
        if (end > parentalControlPagination.totalElements) {
            end = parentalControlPagination.totalElements
        }

        for (let i = begining; i < end; i++) {
            parentalControlLines.push([
                i + 1,
                <div>{createLimitedTableCell(`${parentalControlFiltered[i].username}`, 20)}</div>,
                parentalControlFiltered[i].address_type === PARENTAL_CONTROL_ADDR_IP ? 'IP' : 'MAC',
                parentalControlFiltered[i].address_type === PARENTAL_CONTROL_ADDR_IP ?
                    `${parentalControlFiltered[i].ip_address_from} - ${parentalControlFiltered[i].ip_address_to}` : parentalControlFiltered[i].mac_address,

                getDaysArray(parentalControlFiltered[i].days, t).map((obj, idx) => {
                    return <div key={`days-${idx}`} className='parental-control-days-in-table'>
                        {obj.en ? <strong>{obj.name.substring(0, 1) + ' '}</strong> : obj.name.substring(0, 1) + ' '}
                    </div>
                }),

                `${addLeadingZeros(parentalControlFiltered[i].hours_begin, 2)}:${addLeadingZeros(parentalControlFiltered[i].minutes_begin, 2)}`,
                `${addLeadingZeros(parentalControlFiltered[i].hours_end, 2)}:${addLeadingZeros(parentalControlFiltered[i].minutes_end, 2)}`,
                <div >
                    <span className="clickable" onClick={e => {
                        history.push(`/parental-control/parental-control/${i}`)
                    }}><EditIcon /></span>

                    <span style={{ paddingInline: 4 }} />

                    <span className="clickable" onClick={async () => {
                        setSaving(true)

                        if (await parentCtrlCtx.deleteParentalControlElement(i, setErrorMessage))
                            setSuccess(true)
                        else
                            setError(true)

                        setSaving(false)
                    }
                    }><RemoveIcon /></span>
                </div>
            ]);
        }

        return parentalControlLines;
    }

    const dismiss = () => {
        setError(false)
        setSuccess(false)
        setSaving(false)
    }

    const parentalControlForm = () => {
        return <React.Fragment>

            <DefaultStatusModals
                modalID='parental-control'
                saving={saving}
                success={success}
                error={error}
                errorText={errorMessage}
                continueFn={dismiss}
                isWarningModal={true}
                savingText={t('common.label.SAVING')}
            ></DefaultStatusModals>

            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="menu.PARENTAL_CONTROL"></Translator></div>

            {parentCtrlCtx.parentalControlRes?.parental_control_list.length > 0 ?
                <>
                    <div className="parental-control-parental-control-list-wrapper">
                        <List
                            lines={getParentalControlParentalControl()}
                            columns={getColumns()}
                            width='1150px'
                        ></List>
                    </div>
                    <Pager
                        pageNumber={parentalControlPagination.pageNumber}
                        totalElements={parentalControlPagination.totalElements}
                        pageSize={parentalControlPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                </>
                :
                <div id='parental-control-no-rules'>
                    <div className='info-card noelements-card'>
                        <Translator path="parental_control.parental_control.NO_RULES" />
                    </div>
                </div>
            }
            <div className='parental-control-buttons'>

                <Button
                    id='new_parental_control'
                    text={parentCtrlCtx.parentalControlRes.enabled ?
                        t('parental_control.parental_control.label.DISABLE_RES') :
                        t('parental_control.parental_control.label.ENABLE_RES')
                    }
                    onClick={async () => {
                        setSaving(true)

                        if (!(await parentCtrlCtx.setParentalControlEnabled(!parentCtrlCtx.parentalControlRes.enabled, setErrorMessage)))
                            setError(true)

                        setSaving(false)
                    }}
                ></Button>

                <Button
                    id='new_parental_control'
                    text={t('parental_control.parental_control.NEW_RULE')}
                    onClick={() => history.push('/parental-control/parental-control/add')}
                    disabled={parentCtrlCtx.parentalControlRes?.parental_control_list.length >= MAX_RULES_NUM}
                ></Button>
            </div>

            {parentCtrlCtx.parentalControlRes?.parental_control_list.length >= MAX_RULES_NUM ?
                <div className="parental-control-rules-num-warning">
                    <strong><Translator path="parental_control.parental_control.warning.MAX_RULES_NUM"></Translator></strong>
                </div> : null
            }

            {!parentCtrlCtx.parentalControlRes.enabled ?
                <div className="parental-control-rules-num-warning">
                    <strong><Translator path="parental_control.parental_control.warning.RESOURCE_DISABLED"></Translator></strong>
                </div> : null
            }

        </React.Fragment>
    }

    return (
        !parentCtrlCtx?.parentalControlRes ? <Loading show={true}></Loading> :

            <div id='parental-control-parental-control-page' className='with-tabs'>

                {parentalControlForm()}

            </div>
    )
}

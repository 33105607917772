
import React, { useState, useContext, useEffect, useRef } from 'react'


import './tools-tr069-inform.css'
import { BackendContext } from '../../../backend/backend';
import Translator from '../../common/components/translator/translator';
import InfoCard from '../../../components/infocard/infocard';
import { fetchTR069Inform, create_tr069_inform } from '../../resources/tr069-inform';
import Loading from '../../../components/loading/loading';
import { useTranslation } from 'react-i18next'
import Button from '../../../components/button/button';
import { fetchTR069Config } from '../../resources/tr069-config';

const TIME_INTERVAL = 3000;

function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    useEffect(() => {
      savedCallback.current = callback;
    });
  
    useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}


export default function ToolsTR069Inform() {

    const [TR069Inform, setTR069Inform] = useState(null)
    const [refreshEnabled, setRefreshEnabled] = useState(false)
    const [TR069Config, setTR069Config] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const backend = useContext(BackendContext)
    const {t} = useTranslation()

    useInterval(() => {
        fetchTR069Inform(backend, setTR069Inform)

    }, refreshEnabled?TIME_INTERVAL: null)

    useEffect(() => {
        fetchTR069Inform(backend, setTR069Inform)
        fetchTR069Config(backend, setTR069Config)

        // eslint-disable-next-line
    }, [])

    const toogleInform = () => {     
        if(refreshEnabled === false) {
            if(backend) 
                create_tr069_inform(backend, errorMessage, setErrorMessage)
        }
        
        setRefreshEnabled(!refreshEnabled)
    }

    function tr069InformTranslateCause(cause) {
        switch(cause)  {
            /* case 'Manual inform': // new cases (not used in sdk):
                return 'Inform manual'
            case 'Manual inform status':
                return 'Status de inform manual' */

            case 'No inform':
                return t('tools.tr069_inform.message.causes.NO_INFORM')
            case 'No response':
                return t('tools.tr069_inform.message.causes.NO_RESPONSE')
            case 'Inform break':
                return t('tools.tr069_inform.message.causes.INFORM_BREAK')
            case 'Inform success':
                return t('tools.tr069_inform.message.causes.INFORM_SUCCESS')
            case 'Inform authentication fail':
                return t('tools.tr069_inform.message.causes.INFORM_AUTH_FAIL')
            case 'Informing':
                return t('tools.tr069_inform.message.causes.INFORMING')
            default:
                return t('tools.tr069_inform.message.causes.UNKNOWN')
        }
    }

    return (
        !TR069Inform ? <Loading show={true}></Loading> :
        <div id='tr069-inform-page' className='page container'>
            <div className='section-divider'></div>
            <div id='tr069-inform-subtitle' className='subtitle'><Translator path="tools.tr069_inform.title.TR069_INFORM"></Translator></div>
            {
            refreshEnabled ?
                <div> 
                {
                    !TR069Inform ? <Loading show={true}></Loading> : <InfoCard id='tr069-inform-infocard'
                        lines={[
                            {label: <Translator path="tools.tr069_inform.label.CAUSE"></Translator>, value: tr069InformTranslateCause(TR069Inform?.cause), id: 'card-tr069-inform-cause'},
                        ]}
                    ></InfoCard>
                    
                }
                </div>
                :
                <div></div>
            }
             <div className="tr069-inform-new-button">
                <Button
                    id='tr069-inform-start-button'
                    text={<Translator path={refreshEnabled ? "tools.tr069_inform.title.STOP_INFORM" : "tools.tr069_inform.title.START_INFORM"}></Translator>}
                    onClick={e => toogleInform()}
                    disabled={!TR069Config?.tr069_enable}
                ></Button>
                {
                    !TR069Config?.tr069_enable ?
                        <div className='tr069-inform-info'>
                            {t('tools.tr069_inform.message.ENABLE_TR069')} <a href='#/system/tr069-config/'>{t('tools.tr069_inform.message.CONFIGS_TR069')}</a>{t('tools.tr069_inform.message.TO_ENABLE_INFORM')}
                        </div> : null
                }
            </div>
        </div>
    )
}
import React from 'react'


export const AdvancedContext = React.createContext({})

export default function AdvancedState({children}) {

    const [staticRoutingElementsList, setStaticRoutingElementsList] = React.useState(null);
    const [qosClassificationElementsList, setQosClassificationElementsList] = React.useState(null);
    
    return <AdvancedContext.Provider value={{
        staticRoutingElementsList,
        setStaticRoutingElementsList,
        qosClassificationElementsList,
        setQosClassificationElementsList
    }}>
        {children}
    </AdvancedContext.Provider>
}

import { backendStatus } from '../../backend/backend'

const URL_TBL = 'ipv6_ip_port_filter';
const URL_ACTIONS = 'ipv6_ip_port_filter_default_rule';

export const Wildcards = {
    EMPTY_IPV6: "::",
    EMPTY_PREFIX: 0,
    EMPTY_PORT: 0
}

/* action */
export async function fetchIpv6IpPortFilterAction(backend, setIpv6IpPortFilterAction) {
    let result = await backend.retrieveFresh(`${URL_ACTIONS}/0`)

    if(result.status === backendStatus.SUCCESS){
        let ipv6PortFilterAction = result.content
        setIpv6IpPortFilterAction(ipv6PortFilterAction)
    }
}

export async function saveIpv6IpPortFilterAction(backend, ipv6IpPortFilterAction, setErrorMessage) {

    try{

        let result = null;

        result = await backend.update(`${URL_ACTIONS}/0`, ipv6IpPortFilterAction)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || ipv6IpPortFilterAction)

    }catch(error){

        console.error('Error saving IPV6_IP_PORT_FILTER_ACTION:', error)

        return false
    }
}

/* tbl */
const processElements = (elementsList) => {
    elementsList.forEach(element => {
        if (element.src_ipv6.start === Wildcards.EMPTY_IPV6)
            element.src_ipv6.start = ''

        if (element.src_ipv6.end === Wildcards.EMPTY_IPV6)
            element.src_ipv6.end = ''

        if (element.src_ipv6.prefix_length === Wildcards.EMPTY_PREFIX)
            element.src_ipv6.prefix_length = ''

        if (element.dst_ipv6.start === Wildcards.EMPTY_IPV6)
            element.dst_ipv6.start = ''

        if (element.dst_ipv6.end === Wildcards.EMPTY_IPV6)
            element.dst_ipv6.end = ''

        if (element.dst_ipv6.prefix_length === Wildcards.EMPTY_PREFIX)
            element.dst_ipv6.prefix_length = ''

        if (element.source_port.from === Wildcards.EMPTY_PORT)
            element.source_port.from = ''

        if (element.source_port.to === Wildcards.EMPTY_PORT)
            element.source_port.to = ''

        if (element.destination_port.from === Wildcards.EMPTY_PORT)
            element.destination_port.from = ''

        if (element.destination_port.to === Wildcards.EMPTY_PORT)
            element.destination_port.to = ''
    });
}

export async function fetchIpv6IpPortFilter(backend, setIpv6IpPortFilter) {
    let result = await backend.retrieveFresh(`${URL_TBL}`)

    if(result.status === backendStatus.SUCCESS){
        let ipv6PortFilterList = result.content
        processElements(ipv6PortFilterList)
        setIpv6IpPortFilter(ipv6PortFilterList)
    }
}

export async function fetchIpv6IpPortFilterList(backend, setIpv6IpPortFilterList) {
    let result = await backend.retrieveFresh(`${URL_TBL}`)

    if(result.status === backendStatus.SUCCESS){
        setIpv6IpPortFilterList(result.content)
    }
}

export async function deleteIpv6IpPortFilterById(backend, id, setIpv6IpPortFilterList) {
    let result = await backend.delete(`${URL_TBL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchIpv6IpPortFilterList(backend, setIpv6IpPortFilterList)
    }
}

export async function saveIpv6IpPortFilter(backend, ipv6IpPortFilter, setErrorMessage) {

    try{

        let result = null;

        result = await backend.update(`${URL_TBL}/${ipv6IpPortFilter.id}`, ipv6IpPortFilter)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || ipv6IpPortFilter)

    }catch(error){

        console.error('Error saving IPV6_IP_PORT_FILTER:', error)

        return false
    }
}

export async function createIpv6IpPortFilter(backend, newRegister, setErrorMessage) {

    try{
        let result = null;
        result = await backend.create(`${URL_TBL}`, newRegister)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || newRegister)

    }catch(error){

        console.error('Error create IPV6_IP_PORT_FILTER:', error)

        return false
    }
}
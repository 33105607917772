import { backendStatus } from '../../backend/backend'

const URL = 'global_routing';

export async function fetchAdvancedGlobalRouting(backend, id, setGlobalRouting) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setGlobalRouting(result.content)
    }
    
}

export async function fetchAdvancedGlobalRoutingList(backend, setGlobalRoutingList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        setGlobalRoutingList(result.content)
    }
}

export async function deleteAdvancedGlobalRoutingById(backend, id, setGlobalRoutingList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchAdvancedGlobalRoutingList(backend, setGlobalRoutingList)
    }
}

export async function saveAdvancedGlobalRouting(backend, globalRouting, setErrorMessage) {

    try{

        let result = null;

        if(!globalRouting.id) {
            result = await backend.create(`${URL}`, globalRouting)
        }
        else {
            result = await backend.update(`${URL}/${globalRouting.id}`, globalRouting)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || globalRouting)

    }catch(error){

        console.error('Error saving GLOBAL_ROUTING:', error)

        return false
    }

}
import React, { useContext, useState } from 'react'
import { BackendContext } from '../../../backend/backend'
import { fetchParentalControlParentalControlList, saveParentalControlParentalControl } from '../../resources/parental-control-parental-control'

export const ParentalControlContext = React.createContext({})

export default function ParentalControlState({children}) {

    const [parentalControlRes, SetParentalControlRes] = useState(null)
    const backend = useContext(BackendContext)

    const retrieveParentalControl = async () => {
        let result = await fetchParentalControlParentalControlList(backend, SetParentalControlRes)
        return result
    }

    const updateParentalControlElement = async (id, parentalControlElement, setErrorMessage) => {
        let res = {...parentalControlRes}

        if(id >= res.parental_control_list.length)
            return false

        res.parental_control_list[id] = parentalControlElement
        let result = await saveParentalControlParentalControl(backend, res, setErrorMessage)

        if(result)
            SetParentalControlRes(res)

        return result
    }

    const createParentalControlElement = async (parentalControlElement, setErrorMessage) => {
        let res = {...parentalControlRes, parental_control_list: [...parentalControlRes.parental_control_list, parentalControlElement]}
        let result = await saveParentalControlParentalControl(backend, res, setErrorMessage)
        
        if(result)
            SetParentalControlRes(res)

        return result
    }

    const deleteParentalControlElement = async (id, setErrorMessage) => {
        let res = {...parentalControlRes}

        if(id >= res.parental_control_list.length)
            return false

        res.parental_control_list.splice(id, 1);
        let result = await saveParentalControlParentalControl(backend, res, setErrorMessage)
        
        if(result)
            SetParentalControlRes(res)

        return result
    }

    const setParentalControlEnabled = async (enabled, setErrorMessage) => {
        let res = {...parentalControlRes}

        res.enabled = enabled
        let result = await saveParentalControlParentalControl(backend, res, setErrorMessage)
        
        if(result)
            SetParentalControlRes(res)

        return result
    }

    return <ParentalControlContext.Provider value={{
        parentalControlRes:parentalControlRes,
        setParentalControlEnabled:setParentalControlEnabled,
        deleteParentalControlElement:deleteParentalControlElement,
        createParentalControlElement:createParentalControlElement,
        updateParentalControlElement:updateParentalControlElement,
        retrieveParentalControl:retrieveParentalControl,

    }}>
        {children}
    </ParentalControlContext.Provider>
}

import React from 'react'
import Input from '../../../../components/input/input'
import Loading from '../../../../components/loading/loading'
import Checkbox from '../../../../components/checkbox/checkbox';


import extraValidators from '../../../common/validators';
import Translator from '../../../common/components/translator/translator';
import { LimitToMaxSafeInteger } from '../../../utils/common';

export default function IPv6RadvdULAPrefix(ipv6Radvd, setIPv6Radvd) {
    
    const validGreaterThanPreferValidator = value => {
        return (ipv6Radvd.ipv6_ula_settings.ula_valid_time >= ipv6Radvd.ipv6_ula_settings.ula_prefer_time ? "" : 
        <Translator path="common.message.error.VALIDATE_IPV6_VALID_TIME"></Translator>)
    }

    const shouldValidateUlaPrefix = () => {
        return ipv6Radvd.enable_ula
    }

    return (
        !ipv6Radvd.ipv6_ula_settings ? <Loading show={true}></Loading> :
        <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="ipv6.radvd.title.ULA_PREFIX"></Translator></div>

            <Checkbox id='ipv6-radvd-enable-ula'
                name='enable_ula'
                label={<Translator path="ipv6.radvd.label.ENABLE_ULA"></Translator>}
                value={ipv6Radvd.enable_ula}
                clearErrors={true}
                toggleFn={(e) => {
                    setIPv6Radvd({...ipv6Radvd, enable_ula: !ipv6Radvd.enable_ula})
                }}
            ></Checkbox>

            <Input id='ipv6-radvd-ula-ula-prefix'
                name='ula_prefix'
                label={<Translator path="ipv6.radvd.label.ULA_PREFIX"></Translator>}
                value={ipv6Radvd.ipv6_ula_settings.ula_prefix}
                onChange={(e) => {
                    ipv6Radvd.ipv6_ula_settings.ula_prefix = e.target.value
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateUlaPrefix,
                        validators: [
                            {fn: extraValidators.required, params: ''},
                            {fn: extraValidators.validateIPv6, params: ''},
                            {fn: extraValidators.validateIfNotLocalhost, params: ''},
                            {fn: extraValidators.validateULAPrefix, params: ''}
                        ]
                    }}, 
                ]}
                disabled={!ipv6Radvd.enable_ula}
                dependentValues={[ipv6Radvd.enable_ula]}
            ></Input>

            <Input id='ipv6-radvd-ula-ula-prefix-len'
                name='ula_prefix_len'
                label={<Translator path="ipv6.radvd.label.ULA_PREFIX_LEN"></Translator>}
                value={ipv6Radvd.ipv6_ula_settings.ula_prefix_len}
                onChange={(e) => {
                    ipv6Radvd.ipv6_ula_settings.ula_prefix_len = LimitToMaxSafeInteger(e.target.value)
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateUlaPrefix,
                        validators: [
                            {fn: extraValidators.nonASCII, params: ''},
                            {fn: extraValidators.isNumber, params: ''},
                            {fn: extraValidators.value, params: {min: 48, max: 64}},
                        ]
                    }}, 
                ]}
                disabled={!ipv6Radvd.enable_ula}
                dependentValues={[ipv6Radvd.enable_ula]}
            ></Input>

            <Input id='ipv6-radvd-ula-ula-valid-time'
                name='ula_valid_time'
                label={<Translator path="ipv6.radvd.label.ULA_VALID_TIME"></Translator>}
                value={ipv6Radvd.ipv6_ula_settings.ula_valid_time}
                onChange={(e) => {
                    ipv6Radvd.ipv6_ula_settings.ula_valid_time = LimitToMaxSafeInteger(e.target.value)
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateUlaPrefix,
                        validators: [
                            {fn: extraValidators.nonASCII, params: ''},
                            {fn: extraValidators.isNumber, params: ''},
                            {fn: extraValidators.value, params: {min: 600, max: 4294967295}},
                        ]
                    }}, 
                ]}
                disabled={!ipv6Radvd.enable_ula}
                dependentValues={[ipv6Radvd.enable_ula]}
            ></Input>

            <Input id='ipv6-radvd-ula-ula-prefer-time'
                name='ula_prefer_time'
                label={<Translator path="ipv6.radvd.label.ULA_PREFER_TIME"></Translator>}
                value={ipv6Radvd.ipv6_ula_settings.ula_prefer_time}
                onChange={(e) => {
                    ipv6Radvd.ipv6_ula_settings.ula_prefer_time = LimitToMaxSafeInteger(e.target.value)
                    setIPv6Radvd({...ipv6Radvd})
                }}
                validators={[
                    {fn: extraValidators.optionalValidators, params: {
                        shouldValidate: shouldValidateUlaPrefix,
                        validators: [
                            {fn: extraValidators.nonASCII, params: ''},
                            {fn: extraValidators.isNumber, params: ''},
                            {fn: extraValidators.value, params: {min: 600, max: 4294967295}},
                            {fn: validGreaterThanPreferValidator, params: ''},
                        ]
                    }}, 
                ]}
                dependentValues = {[ipv6Radvd.enable_ula, ipv6Radvd.ipv6_ula_settings.ula_valid_time]}
                disabled={!ipv6Radvd.enable_ula}
            ></Input>

        </React.Fragment>
    )
}
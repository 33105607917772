import { backendStatus } from '../../backend/backend'

const URL = 'ipv6_static_route';

export async function fetchIpv6StaticRoute(backend, setIpv6StaticRoute) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){
        let ipv6StaticRouteList = result.content
        setIpv6StaticRoute(ipv6StaticRouteList)
    }
}

export async function fetchIpv6StaticRouteById(backend, setIpv6StaticRoute, id) {
    let result = await backend.retrieveFresh(`${URL}/${id}`)
    if(result.status === backendStatus.SUCCESS){
        let ipv6StaticRouteList = result.content
        setIpv6StaticRoute(ipv6StaticRouteList)
    }
}

export async function saveIpv6StaticRoute(backend, ipv6StaticRoute, setErrorMessage) {
    try{
        let result = null;
        result = await backend.create(`${URL}`, ipv6StaticRoute)
        
        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }
        return result.status === backendStatus.SUCCESS
    }
    catch(error){
        console.error('Error saving IPv6 Static Route:', error)
        return false
    }
}

export async function updateIpv6StaticRoute(backend, ipv6StaticRoute, setErrorMessage) {
    try{
        let result = null;
        result = await backend.update(`${URL}/${ipv6StaticRoute.id}`, ipv6StaticRoute)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }
        return result.status === backendStatus.SUCCESS
    }
    catch(error){
        console.error('Error saving IPv6 Static Route:', error)
        return false
    }
}

export async function deleteIpv6StaticRoute(backend, id, setIpv6StaticRoute) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchIpv6StaticRoute(backend, setIpv6StaticRoute)
    }
}
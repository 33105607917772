import { backendStatus } from '../../backend/backend'

export async function fetchVoipDialPlan(id, backend, setVoipDialPlan) {
    let result = await backend.retrieveFresh(`voip_dial_plan/${id}`)

    if(result.status === backendStatus.SUCCESS){

        let voipDialPlan = result.content

        setVoipDialPlan(voipDialPlan)
    }
}

export async function fetchVoipDialPlanList(backend, setVoipProxyList) {
    let result = await backend.retrieveFresh('voip_dial_plan')

    if(result.status === backendStatus.SUCCESS){

        let voipProxyList = result.content

        setVoipProxyList(voipProxyList)
    }
}

export async function saveVoipDialPlan(backend, voipDialPlan) {

    try{

        let result = null;

        delete voipDialPlan['voip_port'];

        result = await backend.update(`voip_dial_plan/${voipDialPlan.id}`, voipDialPlan)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving VOIP Dial Plan:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import './firewall-alg.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchFirewallAlg, saveFirewallAlg } from '../../../resources/firewall-alg';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import Translator from '../../../common/components/translator/translator';
import Checkbox from '../../../../components/checkbox/checkbox';

export default function FirewallAlg({isWizard, setSaved, isFormSegment, formSegment}) {
    const [firewallAlg, setFirewallAlg] = useState(null)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    const [matches, setMatches] = useState( window.matchMedia("(max-width: 300px)").matches )

    useEffect(() => {

        fetchFirewallAlg(backend, setFirewallAlg)

        window
        .matchMedia("(max-width: 300px)")
        .addEventListener('change', e => setMatches( e.matches ));

        // eslint-disable-next-line
    }, [])

    const firewallAlgForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div> 
            <div className='subtitle'> <Translator path="firewall.alg.title.FIREWALL_ALG_ENABLE"></Translator></div>
                <div 
                    id='firewall-alg-available-services-wrapper'
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '20% 40% 40% 0%',
                        gridTemplateRows: 'auto',
                    }}
                >
                {Object.keys(firewallAlg).filter(avs => avs.indexOf("enabled") !== -1).map(
                        (avs, key) => 
                            <div
                                key={`firewall-alg-ftp-${avs}`}
                                style={{
                                    gridColumnStart: matches ? 2 : parseInt(key%2)+1 + 1,
                                    gridRowStart: matches ? key+2 : parseInt(key/2)+1 + 1,
                                }}
                            >
                                <div style={{width: '200px'}}>
                                    <div style={{display: 'inline-block', width: '100px'}}></div>
                                        <Checkbox id={`firewall-alg-ftp-${avs}`}
                                            name={avs}
                                            key={avs}
                                            label={<Translator path={`firewall.alg.label.${avs.toUpperCase()}`}></Translator>}
                                            value={firewallAlg[avs]}
                                            toggleFn={(e) => {
                                                firewallAlg[avs] = !firewallAlg[avs];
                                                setFirewallAlg({...firewallAlg})
                                            }}
                                            validators={[]}
                                        ></Checkbox>
                                </div>  
                            </div> 
                        )
                    }              
                </div>  
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveFirewallAlg(backend, firewallAlg)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !firewallAlg ? <Loading show={true}></Loading> :

        <div id='firewall-alg-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="firewall.alg.title.FIREWALL_ALG"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <div className='card mt2'>
                {!isFormSegment ? <Form
                    onSubmit={submit}
                    buttonId='button-save'
                >
                    {firewallAlgForm()}
                </Form> : 

                <FormSegment
                    title={formSegment.title} 
                    active={formSegment.active} 
                    nextLabel={formSegment.nextLabel} 
                    nextClicked={submit} 
                >
                    {firewallAlgForm()}
                </FormSegment>}
            </div>
        </div>
    )
}

import { backendStatus } from '../../backend/backend'


export async function fetchFirewallAlg(backend, setFirewallAlg) {
    let result = await backend.retrieveFresh('firewall_alg')

    if(result.status === backendStatus.SUCCESS){

        let firewallAlgList = result.content

        let firewallAlg = firewallAlgList[0]

        setFirewallAlg(firewallAlg)
    }
}

export async function saveFirewallAlg(backend, firewallAlg) {

    try{

        let result = null;

        result = await backend.update(`firewall_alg/${firewallAlg.id}`, firewallAlg)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Firewall Alg:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import './ipv6-dhcp-active-clients.css'
import { BackendContext } from '../../../../../backend/backend'
import { fetchIpv6DhcpActiveClientsList } from '../../../../resources/ipv6-dhcp-active-clients';
import Loading from '../../../../../components/loading/loading';
import List from '../../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Translator from '../../../../common/components/translator/translator';
import Pager from '../../../../../components/pager/pager';
import { MainHeaderContext } from '../../../../../components/main-header/main-header-state';
import Button from '../../../../../components/button/button';

export default function Ipv6DhcpActiveClients({ history }) {
    const [dhcpActiveClients, setIpv6DhcpActiveClients] = useState(null)

    const [dhcpActiveClientsPagination, setIpv6DhcpActiveClientsPagination] = useState(null)

    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)

    const { t } = useTranslation()

    useEffect(() => {
        setIpv6DhcpActiveClientsPagination({ pageNumber: 1, totalElements: 0, pageSize: 10 })
        fetchIpv6DhcpActiveClientsList(backend, setIpv6DhcpActiveClients)
        header.backRoute.set('/ipv6/dhcp')

        // eslint-disable-next-line
    }, [])

    const getDHCPClientColumns = () => {

        return [
            { header: t('common.label.IP_ADDRESS'), align: 'center', size: '330px' },
            { header: 'DUID', align: 'center', size: '200px' },
            { header: t('Aging Time (seg)'), align: 'center', size: '150px' }
        ]
    }

    const changePage = page => {
        setIpv6DhcpActiveClientsPagination({...dhcpActiveClientsPagination, pageNumber: page})
    }

    const getIpv6DhcpActiveClients = () => {

        let dhcpActiveClientsFiltered = dhcpActiveClients

        if(dhcpActiveClientsFiltered.length !== dhcpActiveClientsPagination.totalElements) {
            dhcpActiveClientsPagination.totalElements = dhcpActiveClientsFiltered.length
            setIpv6DhcpActiveClientsPagination({...dhcpActiveClientsPagination})
        }

        let dhcpActiveClientsLines = [];

        let begining = (dhcpActiveClientsPagination.pageNumber - 1) * (dhcpActiveClientsPagination.pageSize)
        let end = begining + dhcpActiveClientsPagination.pageSize
        if (end > dhcpActiveClientsPagination.totalElements) {
            end = dhcpActiveClientsPagination.totalElements
        }

        for(let i = begining; i < dhcpActiveClientsFiltered.length; i++) {
            dhcpActiveClientsLines.push([
                dhcpActiveClientsFiltered[i].ip_address,
                dhcpActiveClientsFiltered[i].duid,
                dhcpActiveClientsFiltered[i].aging_time,
            ]);
        }

        return dhcpActiveClientsLines;
    }

    const dhcpActiveClientsForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="ipv6.dhcp.title.ACTIVE_CLIENTS"></Translator></div>
            <div>

                <Button id='back-to-dhcp-config'
                    text={<Translator path="common.label.BACK"></Translator>}
                    onClick={() => history.push('/ipv6/dhcp')}
                ></Button>

                {(getIpv6DhcpActiveClients().length === 0) ?
                    <div>
                        <div id='ipv6_dhcp_client-no-rules'>
                            <div className='info-card noelements-card'>
                                <Translator path="ipv6.dhcp_client.info.NO_DHCP_CLIENTS" />
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="ipv6-dhcp-active-clients-list-wrapper">
                        <List
                            lines={getIpv6DhcpActiveClients()}
                            columns={getDHCPClientColumns()}
                        ></List>
                        </div>
                        <Pager
                            pageNumber={dhcpActiveClientsPagination.pageNumber}
                            totalElements={dhcpActiveClientsPagination.totalElements}
                            pageSize={dhcpActiveClientsPagination.pageSize}
                            pageChangeFn={changePage}>
                        </Pager>
                    </div>
                }

                <Button id='update-active-clients'
                    text={<Translator path="network.wan.title.UPDATE"></Translator>}
                    onClick={() => fetchIpv6DhcpActiveClientsList(backend, setIpv6DhcpActiveClients)}
                ></Button>
            </div>


        </React.Fragment>
    }

    return (
        !dhcpActiveClients ? <Loading show={true}></Loading> :

            <div id='ipv6-dhcp-active-clients-page' className='with-tabs'>

                { dhcpActiveClientsForm() }

            </div>
    )
}

import { backendStatus } from "../../backend/backend"

export const MAX_PRESSED_TIME = 300
export const MIN_PRESSED_TIME = 5   

export async function fetchFactoryDefault(backend, setFactoryDefault) {

    try{

        let result = await backend.retrieveFresh('factory_default')

        if(result.status === backendStatus.SUCCESS){
            setFactoryDefault(result.content[0])
            return true
        }

    }catch(error){
        console.error('Error fetching factory default:', error)
        return false
    }
}

export async function updateFactoryDefault(backend, factoryDefault) {

    if(!factoryDefault.id) factoryDefault['id'] = '0'

    try{

        let result = await backend.update(`factory_default/${factoryDefault.id}`, factoryDefault)

        return result.status === backendStatus.SUCCESS

    }catch(error){
        console.error('Error updating factory default:', error)
        return false
    }
}
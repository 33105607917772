export default {

    macAddressMask: (mac) => {
        const MAC_ADDRESS_WITH_MASK = 17
        let res = ''
        if (mac) {
            res = mac.replaceAll(':', '').match(/.{1,2}/g).join(":")
        }
    
        if(res.length <= MAC_ADDRESS_WITH_MASK){
            return res
        }else{
            return res.substr(0, MAC_ADDRESS_WITH_MASK)
        }
    },

    getDeviceIdFromMac: (mac) => {
        if (!mac) return ""
        return mac.replace(/:/g, '').toLowerCase()
    }
}
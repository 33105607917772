import { backendStatus } from '../../backend/backend'

export const firewallIpPortFilteringsConstants = {
    MAX_KEYWORDS_ELEMENTS: 16,
    MAX_URLS_ELEMENTS: 16
}


export async function fetchFirewallIpPortFiltering(backend, setFirewallIpPortFilterings) {
    let result = await backend.retrieveFresh('firewall_ip_port_filtering')

    if(result.status === backendStatus.SUCCESS){

        let firewallIpPortFilteringsList = result.content

        let firewallIpPortFilterings = firewallIpPortFilteringsList[0]

        setFirewallIpPortFilterings(firewallIpPortFilterings)
    }
}

export async function saveFirewallIpPortFiltering(backend, firewallIpPortFilterings, setErrorMessage) {

    try{

        let result = null;

        result = await backend.update(`firewall_ip_port_filtering/${firewallIpPortFilterings.id}`, firewallIpPortFilterings)
        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Firewall IP/Port Filtering:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import Pager from '../../../../components/pager/pager';
import { fetchLoopBackDetectStatus } from '../../../resources/loop-back-detect-status';
import { BackendContext } from '../../../../backend/backend';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';

export default function LoopBackDetectStatus({ isWizard, setSaved, isFormSegment, formSegment }) {

    const [loopBackDetectStatusPagination, setLoopBackDetectStatusPagination] = useState({ pageNumber: 1, totalElements: 0, pageSize: 10 })
    const [loopBackDetectStatus, setLoopBackDetectStatus] = useState(false)
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)

    const { t } = useTranslation()
    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchLoopBackDetectStatus(backend, setLoopBackDetectStatus, setErrorMessage)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (errorMessage !== null)
            setError(true)

    }, [errorMessage])

    const getColumns = () => {

        return [
            { header: t('common.label.NUMBER'), align: 'center'},
            { header: t('tools.loop_back_detection.label.PORT'), align: 'center' },
            { header: t('common.label.STATUS'), align: 'center'},
        ]
    }

    const changePage = page => {
        setLoopBackDetectStatusPagination({ ...loopBackDetectStatusPagination, pageNumber: page })
    }

    const getLoopBackDetectStatus = () => {

        let loopBackDetectStatusFiltered = loopBackDetectStatus

        if (loopBackDetectStatusFiltered.length !== loopBackDetectStatusPagination.totalElements) {
            loopBackDetectStatusPagination.totalElements = loopBackDetectStatusFiltered.length
            setLoopBackDetectStatusPagination({ ...loopBackDetectStatusPagination })
        }

        let loopBackDetectStatusLines = [];

        let begining = (loopBackDetectStatusPagination.pageNumber - 1) * (loopBackDetectStatusPagination.pageSize)
        let end = begining + loopBackDetectStatusPagination.pageSize
        if (end > loopBackDetectStatusPagination.totalElements) {
            end = loopBackDetectStatusPagination.totalElements
        }

        for (let i = begining; i < end; i++) {
            loopBackDetectStatusLines.push([
                i + 1,
                loopBackDetectStatusFiltered[i].port,
                loopBackDetectStatusFiltered[i].loop_detect ? t('tools.loop_back_detection.label.DETECTED') : t('tools.loop_back_detection.label.NOT_DETECTED')
            ])
        }

        return loopBackDetectStatusLines;
    }

    const dismissModal = () => {
        setError(false)
    }

    const loopBackDetectStatusForm = () => {
        return <React.Fragment>

            <div className='section-divider'></div>
            <div className="loop-back-detect-status-list-wrapper">
                <List
                    lines={getLoopBackDetectStatus()}
                    columns={getColumns()}
                ></List>
            </div>
            <div><strong>{t('tools.loop_back_detection.info.DISABLE_LAN_WARN')}</strong></div>
            <Pager
                pageNumber={loopBackDetectStatusPagination.pageNumber}
                totalElements={loopBackDetectStatusPagination.totalElements}
                pageSize={loopBackDetectStatusPagination.pageSize}
                pageChangeFn={changePage}>
            </Pager>

        </React.Fragment >
    }

    return (
        <div>
            <DefaultStatusModals
                error={error}
                errorText={t('tools.loop_back_detection.error.RETRIEVE_LBD_STATUS_FAILED')}
                continueFn={dismissModal}
            ></DefaultStatusModals>

            {!loopBackDetectStatus ? <Loading show={true}></Loading> : loopBackDetectStatusForm()}
        </div>
    )
}

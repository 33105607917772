import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory } from 'react-router-dom'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import TabsMobile from '../../common/components/tabs/tabs-mobile'
import VoipAdvancedSip from './voip-advanced-sip/voip-advanced-sip';
import VoipAdvancedSipList from './voip-advanced-sip/voip-advanced-sips';
import VoipCodec from './voip-codec/voip-codec';
import VoipCodecList from './voip-codec/voip-codecs';
import VoipState from './voip-context';
import VoipDialPlan from './voip-dial-plan/voip-dial-plan';
import VoipDialPlanList from './voip-dial-plan/voip-dial-plans';
import VoipHistoryCall from './voip-history-call/voip-history-call';
import VoipProxyList from './voip-proxy/voip-proxies';
import VoipProxy from './voip-proxy/voip-proxy';
/*IMPORTS_TAG*/

export default function VoipPage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    const { t, i18n } = useTranslation()

    const tabs = [
        { label: t('voip.proxy.title.ACCOUNT_CONFIG'), route: '/voip/proxy' },
        { label: t('voip.history_call.title.HISTORY_CALL'), route: '/voip/history-call' },
        { label: t('voip.dial_plan.title.DIAL_PLAN'), route: '/voip/dial-plan' },
        { label: t('voip.advanced_sip.title.VOIP_ADVANCED_SIP'), route: '/voip/advanced-sip' },
        { label: t('voip.codec.title.CODEC'), route: '/voip/codec' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.VOIP'))
        header.backRoute.set('/voip/history-call')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('voip') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    return (
        <VoipState>

            <TabsMobile
                data={tabs}
                route={history.location.pathname}
                activateFn={changeRoute}
            ></TabsMobile>

            <Switch>
                <Route path='/voip/dial-plan/:id' render={(props) => {
                    return <VoipDialPlan {...props} ></VoipDialPlan>
                }}></Route>
                
                <Route path='/voip/dial-plan' render={(props) => {
                    return <VoipDialPlanList {...props} ></VoipDialPlanList>
                }}></Route>
                
                <Route path='/voip/history-call' render={(props) => {
                    return <VoipHistoryCall {...props} ></VoipHistoryCall>
                }}></Route>
                
                <Route path='/voip/advanced-sip/:id' render={(props) => {
                    return <VoipAdvancedSip {...props} ></VoipAdvancedSip>
                }}></Route>
                
                <Route path='/voip/advanced-sip' render={(props) => {
                    return <VoipAdvancedSipList {...props} ></VoipAdvancedSipList>
                }}></Route>
                
                <Route path='/voip/proxy/:id' render={(props) => {
                    return <VoipProxy {...props} ></VoipProxy>
                }}></Route>
                
                <Route path='/voip/proxy' render={(props) => {
                    return <VoipProxyList {...props} ></VoipProxyList>
                }}></Route>
                
                <Route path='/voip/codec/:id' render={(props) => {
                    return <VoipCodec {...props} ></VoipCodec>
                }}></Route>
                
                <Route path='/voip/codec' render={(props) => {
                    return <VoipCodecList {...props} ></VoipCodecList>
                }}></Route>
                
                {/*ROUTES_TAG*/}
            </Switch>
        </VoipState>
    )
}

import React, { useState, useEffect, useContext } from 'react'

import './static-routing.css'
import { BackendContext } from '../../../../backend/backend'
import { updateStaticRouting, saveStaticRouting, fetchStaticRoutingById, fetchStaticRouting } from '../../../resources/static-routing';
import Loading from '../../../../components/loading/loading';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom'
import Translator from '../../../common/components/translator/translator';
import Input from '../../../../components/input/input';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import Form from '../../../../components/form/form';
import Checkbox from '../../../../components/checkbox/checkbox';
import extraValidators from '../../../common/validators';
import Select from '../../../../components/select/select';
import Button from '../../../../components/button/button'
import { fetchWanList } from '../../../resources/wan';
import { AdvancedContext } from '../advanced-context';

export default function StaticRoutingElementEdit({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [staticRoutingElement, setStaticRoutingElement] = useState(
        {
            state: false,
            route: {
                destination: '',
                net_mask: '',
                next_hop: '',
                metric: 0,
                interfaceID: 'any'
            }
        })

    const { staticRoutingElementsList, setStaticRoutingElementsList } = useContext(AdvancedContext)
    const backend = useContext(BackendContext)
    const history = useHistory()
    const params = useParams()
    const { t, i18n } = useTranslation()

    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [saving, setSaving] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const [wanRes, setWanRes] = useState(null)
    const [interfacesOptions, setInterfacesOptions] = useState([{ value: '', text: '' }])
    const [dataFetched, setDataFetched] = useState(false)
    const [updateInterfacesOptions, setUpdateInterfacesOptions] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            if (staticRoutingElementsList === null)
                await fetchStaticRouting(backend, setStaticRoutingElementsList)

            await fetchWanList(backend, setWanRes)
            setDataFetched(true)
        }
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (dataFetched === true) {
            if (params.id !== 'add') {
                if (staticRoutingElementsList !== null && staticRoutingElementsList.length > 0) {
                    setStaticRoutingElement(staticRoutingElementsList.filter(element => element.id === params.id)[0])
                }
                else {
                    fetchStaticRoutingById(backend, setStaticRoutingElement, params.id)
                }
            }
            setUpdateInterfacesOptions(true)
        }
        // eslint-disable-next-line
    }, [dataFetched, staticRoutingElementsList])

    useEffect(() => {
        if (updateInterfacesOptions === true) {
            if (!!wanRes) {
                let options = wanRes.map((item) => {
                    return ({ value: item["interfaceID"], text: item["interfaceID"] })
                })
                options.push({ value: "any", text: t('advanced.static_routing.label.INTERFACE_ANY') })
                setInterfacesOptions(options)
                setStaticRoutingElement({
                    ...staticRoutingElement, route: {
                        ...staticRoutingElement.route, interfaceID: staticRoutingElement.route.interfaceID
                    }
                })
            }
        }
        // eslint-disable-next-line
    }, [updateInterfacesOptions, wanRes, i18n.language])

    const submit = async () => {
        if (saving || error || success) return

        setSaving(true)
        let ok = null
        if (params.id === 'add')
            ok = await saveStaticRouting(backend, staticRoutingElement, setErrorMessage)
        else {
            ok = await updateStaticRouting(backend, staticRoutingElement, setErrorMessage)
        }

        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }
        setSaving(false)
        setSuccess(true)
    }

    const dismiss = () => {
        setSuccess(false)
        setError(false)
        setSaving(false)
        history.push('/advanced/static-routing')
    }

    const continueFn = () => {
        let is_error = error
        setSuccess(false)
        setError(false)
        setSaving(false)
        if (!is_error) {
            history.push('/advanced/static-routing')
        }
    }

    const checkIfRouteExists = (destination) => {

        if (staticRoutingElementsList === null || staticRoutingElementsList.length === 0)
            return false

        let result = staticRoutingElementsList.filter(element => {
            return element.route.destination === destination &&
                element.route.net_mask === staticRoutingElement.route.net_mask &&
                element.id !== staticRoutingElement.id
        })

        return Boolean(result.length > 0)
    }

    const validateIfRouteDestinationExistis = (destination) => {
        let result = checkIfRouteExists(destination)
        return (result ? t('advanced.static_routing.error.ROUTER_ALREADY_EXISTS') : '')
    }

    const translateErrorMessagesFromBackend = (text) => {
        if (text === 'The specified netmask parameter is invalid. (Destination & Netmask) != Destination.')
            return t('advanced.static_routing.error.NETMASK_AND_IP_ERROR')
    }

    return (!staticRoutingElement ? <Loading show={true}></Loading> :

        <div id='static-routing-edit-page' className='with-tabs'>
            <DefaultStatusModals
                success={success}
                error={error}
                errorText={t('advanced.static_routing.error.ERROR_SERVER') + translateErrorMessagesFromBackend(errorMessage)}
                saving={saving}
                continueFn={continueFn}
                isWarningModal={true}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            <div className='subtitle'><Translator path="menu.STATIC_ROUTING"></Translator></div>

            <Button
                id='new_static_routing'
                text={t('common.label.BACK')}
                onClick={dismiss}
            ></Button>
            <div className="card mt2">
                <div className='subtitle'>
                    {params.id === 'add' ?
                        <Translator path="advanced.static_routing.title.STATIC_ROUTING_CREATE" />
                        :
                        <Translator path="advanced.static_routing.title.STATIC_ROUTING_EDIT" />
                    }
                </div>

                <Form
                    buttonId='button-save-static-routing'
                    onSubmit={submit}
                >
                    <Checkbox id='static-routing-edit-state'
                        label={t('common.label.ENABLE')}
                        value={staticRoutingElement.state}
                        toggleFn={() => {
                            setStaticRoutingElement({
                                ...staticRoutingElement, state: !staticRoutingElement.state
                            })
                        }}
                    ></Checkbox>

                    <Input id='static-routing-edit-destination'
                        name='route-destination'
                        label={t('advanced.static_routing.label.DESTINATION')}
                        placeholder={t('advanced.static_routing.paceholder.VALID_IP_INPUT')}
                        value={staticRoutingElement.route.destination}
                        dependentValues={[staticRoutingElement, staticRoutingElement.route.net_mask]}
                        validators={[
                            extraValidators.required, 
                            extraValidators.validateIPv4, 
                            extraValidators.validateIfNotLocalhost, 
                            validateIfRouteDestinationExistis
                        ]}
                        onChange={(e) => {
                            setStaticRoutingElement({
                                ...staticRoutingElement, route: {
                                    ...staticRoutingElement.route, destination: e.target.value
                                }
                            })
                        }}
                    ></Input>

                    <Input id='static-routing-edit-net_mask'
                        name='route-net_mask'
                        label={t('advanced.static_routing.label.NET_MASK')}
                        placeholder={t('advanced.static_routing.paceholder.VALID_NETMASK_INPUT')}
                        value={staticRoutingElement.route.net_mask}
                        dependentValues={[staticRoutingElement]}
                        validators={[extraValidators.required, extraValidators.validateSubNetMask]}
                        onChange={(e) => {
                            setStaticRoutingElement({
                                ...staticRoutingElement, route: {
                                    ...staticRoutingElement.route, net_mask: e.target.value
                                }
                            })
                        }}
                    ></Input>

                    <Input id='static-routing-edit-next_hop'
                        name='route-next_hop'
                        label={t('advanced.static_routing.label.NEXT_HOP')}
                        placeholder={t('advanced.static_routing.paceholder.VALID_IP_INPUT')}
                        value={staticRoutingElement.route.next_hop}
                        dependentValues={[staticRoutingElement]}
                        validators={[
                            extraValidators.required, 
                            extraValidators.validateIPv4,
                            extraValidators.validateIfNotLocalhost
                        ]}
                        onChange={(e) => {
                            setStaticRoutingElement({
                                ...staticRoutingElement, route: {
                                    ...staticRoutingElement.route, next_hop: e.target.value
                                }
                            })
                        }}
                    ></Input>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input id='static-routing-edit-metric'
                            name='route-metric'
                            label={t('advanced.static_routing.label.METRIC')}
                            type="number"
                            placeholder={t('advanced.static_routing.paceholder.VALID_METRIC_INPUT')}
                            value={staticRoutingElement.route.metric}
                            dependentValues={[staticRoutingElement]}
                            validators={[extraValidators.required, { fn: extraValidators.value, params: { min: 0, max: 16 } }]}
                            onChange={(e) => {
                                setStaticRoutingElement({
                                    ...staticRoutingElement, route: {
                                        ...staticRoutingElement.route, metric: isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                                    }
                                })
                            }}
                        ></Input>

                        <Select
                            id='static-routing-edit-interface'
                            label={t('advanced.static_routing.label.INTERFACEID')}
                            options={interfacesOptions}
                            value={staticRoutingElement.route.interfaceID}
                            onChange={(e) => {
                                setStaticRoutingElement({
                                    ...staticRoutingElement, route: {
                                        ...staticRoutingElement.route, interfaceID: e.target.value
                                    }
                                })
                            }}
                        ></Select>
                    </div>
                </Form>
            </div>
        </div>
    );
}

import React, { useState, useContext, useEffect } from 'react'
import Form from '../../../components/form/form'
import Input from '../../../components/input/input'
import Checkbox from '../../../components/checkbox/checkbox'
import errorImg from '../../../images/error.svg'
import './login.css'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import { BackendContext, backendStatus } from '../../../backend/backend'
import Modal from '../../../components/modal/modal'
import { ModalTypes } from '../../../components/modal/modal'
import { useHistory } from 'react-router-dom'
import { MenuContext } from '../../../components/menu/menu-state'
import { createSession } from '../../resources/session'
import { GlobalsContext } from '../../globals-context'
import { SystemStatusContext } from '../../system-status-context'
import Translator from '../../common/components/translator/translator'
import { useTranslation } from 'react-i18next'
import I18n from '../../common/components/i18n/i18n'
import { fetchDevice, updateDevice } from '../../resources/device';
import { fetchBlankUser, updateUser } from '../../resources/user';
import extraValidators from '../../common/validators';
import common from '../../../components/form/validators/common';
import intelbrasLogo from '../../assets/images/logo-intelbras.png'
import SystemTermsOfUseText from '../system/system-terms-of-use-text'
import Button from '../../../components/button/button'

export default function LoginPage({ activeSession, setActiveSession }) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [error, setError] = useState(false)
    const [expired, setExpired] = useState(false)
    const [defaultUser, setDefaultUser] = useState(false)
    const [acceptTermsOfUse, setAcceptTermsOfUse] = useState(false)
    const [showTermsOfUse, setShowTermsOfUse] = useState(false)

    let [saving, setSaving] = useState(false)
    let [success, setSuccess] = useState(false)

    let [user, setUser] = useState(null)
    let [confirmation, setConfirmation] = useState('')
    let [confirmationError, setConfirmationError] = useState('')

    let header = useContext(MainHeaderContext)
    let backend = useContext(BackendContext)
    let history = useHistory()
    let menu = useContext(MenuContext)
    let globals = useContext(GlobalsContext)
    let systemStatus = useContext(SystemStatusContext)

    const { t } = useTranslation()
    const { i18n } = useTranslation()

    useEffect(() => {

        systemStatus.setDisabled(true)
        fetchBlankUser(setUser)

        menu.open.set(false)
        header.hidden.set(true)
        backend.setAuthHandlerEnabled(false)

        if (globals.getItem('logged') && history.location.search.includes('expired'))
            setExpired(true)

        if (globals.getItem('defaultUser'))
            setDefaultUser(true)

        return () => {
            systemStatus.setDisabled(false)
            backend.setAuthHandlerEnabled(true)
        }
    }, [systemStatus, setUser, menu.open, header.hidden, backend, globals, history.location.search])

    useEffect(() => {
        if (!user)
            return

        passwordConfirmation()

        // eslint-disable-next-line
    }, [user, confirmation])

    let saveUser = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await updateUser(backend, user)
        if (!ok) {
            setSaving(false)
            setError(true)
            return false
        }

        let device = await fetchDevice(backend, () => { })

        if (!device.login_enable) {
            device.login_enable = true
            await updateDevice(backend, device, () => { })
        }

        setSaving(false)
        setSuccess(true)
        return true
    }

    const doLogin = async (data) => {

        if (loading || error || expired || invalid || defaultUser) return

        setLoading(true)

        let session = await createSession(backend, data)

        setLoading(false)

        if (session === backendStatus.ERROR) {
            setError(true)
            return
        }

        if (session === backendStatus.UNAUTHORIZED) {
            setInvalid(true)
            return
        }

        globals.setItem('logged', true)

        globals.setItem('username', username)
        globals.setItem('password', password)

        sessionStorage.setItem('username', username)
        sessionStorage.setItem('token', session.token)
        setLoading(false)

        let device = await fetchDevice(backend, () => { })

        sessionStorage.setItem('onu_model', device?.model)

        if (history.location.search.includes('update')) {
            history.push('/system/update?start')
            return
        }

        sessionStorage.setItem('login_enable', device?.login_enable)
        if (!device?.login_enable) {
            setDefaultUser(true)
            return
        }

        history.push('/')

    }

    const dismiss = () => {
        setLoading(false)
        setError(false)
        setInvalid(false)
        setExpired(false)
    }

    const continueLogin = async () => {
        setDefaultUser(false)
        await saveUser()
        window.location.reload();
    }

    const passwordConfirmation = () => {
        if (confirmation !== user.password) {
            setConfirmationError(t('system.users.label.PASSWORDS_DO_NOT_MATCH'))
            return t('system.users.label.PASSWORDS_DO_NOT_MATCH')
        } else {
            setConfirmationError('')
            return ''
        }
    }

    const systemUserForm = () => {
        return <React.Fragment>
            <Input name='username'
                id='username'
                type="text"
                disabled={true}
                label={<Translator path="common.label.USER"></Translator>}
                value={user.username}
            ></Input>

            <Input name='user-password'
                id='password'
                type='password'
                label={<Translator path="common.label.PASSWORD"></Translator>}
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value.trim() })}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: 1, max: 30 } },
                    common.anatelPasswordCriteria
                ]}
                passwordMeter={true}
                autoFocus="autofocus"
            ></Input>

            <Input name='confirmation-user-password'
                id='confirmation'
                type='password'
                label={<Translator path="common.label.CONFIRM_PASSWORD"></Translator>}
                value={confirmation}
                onChange={(e) => setConfirmation(e.target.value.trim())}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    { fn: extraValidators.size, params: { min: 1, max: 30 } },
                ]}
                errorMessage={confirmationError}
            ></Input>

            <Checkbox id='accept-terms-of-use'
                name='accept-terms-of-use'
                label={
                    <label>
                        {t('login.label.ACCEPT_TERMS_OF_USE_1')} <label id='terms-link' onClick={() => setShowTermsOfUse(true)}>{t('login.label.ACCEPT_TERMS_OF_USE_2')}</label> {t('login.label.ACCEPT_TERMS_OF_USE_3')}
                    </label>
                }
                value={acceptTermsOfUse}
                toggleFn={(e) => {
                    setAcceptTermsOfUse(!acceptTermsOfUse)
                }}
                validators={[]}
            ></Checkbox>

        </React.Fragment>
    }

    const defaultUserModal = () => {

        return <Modal
            show='true'
            title={<Translator path="login.title.warning.INSECURE_CREDENTIALS"></Translator>}
            content={
                <div className='first-login-form' style={{ maxWidth: '520px' }}>
                    <img alt='' src={errorImg} width='30' style={{ padding: '0 0 10px 0' }}></img>
                    <div>
                        {<Translator path="login.message.warning.DEFAULT_CREDENTIALS"></Translator>}
                        <br></br>
                        {<Translator path="login.message.warning.CHANGE_PASSWORD_ADVICE"></Translator>}&nbsp;
                        <br></br>
                    </div>
                    <Form
                        onSubmit={continueLogin}
                        disableButton={confirmationError !== '' || !acceptTermsOfUse}
                        buttonId='button-save-user'
                    >
                        {systemUserForm()}
                    </Form>
                </div>
            }
        ></Modal >
    }

    const termsOfUseModal = () => {
        return <Modal
            show='true'
            title={t('menu.INFORMATION')}
            content={
                <>
                    <div className='login-terms-of-use'>
                        {SystemTermsOfUseText(i18n)}
                    </div>
                    < Button
                        id='btn-close-terms-of-use'
                        text={t('common.label.BACK')}
                        type='button'
                        onClick={() => setShowTermsOfUse(false)}
                        outlined={true}
                    ></Button>
                </>
            }
        ></Modal >
    }

    const invalidUserModal = () => {

        return <Modal dismissText={<Translator path="modal.answer.CONTINUE"></Translator>} show='true'
            type={ModalTypes.ERROR}
            onDismissClick={dismiss}
            content={
                <React.Fragment>
                    <div className='subtitle'>{<Translator path="login.message.error.INVALID_CREDENTIALS"></Translator>}</div>
                    <div>{<Translator path="login.message.error.INVALID_CREDENTIALS_MESSAGE"></Translator>}</div>
                </React.Fragment>
            }></Modal>
    }

    const errorModal = () => {

        return <Modal dismissText={<Translator path="modal.answer.UNDERSTOOD"></Translator>} show='true'
            type={ModalTypes.ERROR}
            onDismissClick={dismiss}
            content={
                <React.Fragment>
                    <div>{<Translator path="login.message.error.LOGIN_REQUEST_ERROR"></Translator>}</div>
                    <div><Translator path="common.message.error.CHECK_CONNECTION"></Translator></div>
                </React.Fragment>
            }></Modal>
    }

    const expiredModal = () => {

        return <Modal show='true'
            dismissText={<Translator path="modal.answer.UNDERSTOOD"></Translator>}
            type={ModalTypes.ERROR}
            onDismissClick={dismiss}
            content={
                <React.Fragment>
                    <div><Translator path="common.message.error.EXPIRED_SESSION"></Translator></div>
                    <div><Translator path="common.message.warning.LOGIN_TO_CONTINUE"></Translator></div>
                </React.Fragment>
            }></Modal>
    }

    return (
        <div id='login-page'>
            <div id='header-logo' className="logo-background">
                <img src={intelbrasLogo} className='login-logo' alt='Intelbras'></img>
                <div className='login-logo-brand-moto'>{t('common.label.ALWAYS_NEAR')}</div>
            </div>



            <Modal type={ModalTypes.LOADING} content={<Translator path="common.message.warning.WAIT"></Translator>} show={loading || saving}></Modal>

            {invalid && invalidUserModal()}
            {error && errorModal()}
            {expired && expiredModal()}
            {!showTermsOfUse && defaultUser && defaultUserModal()}
            {showTermsOfUse && termsOfUseModal()}

            <div className='login-form-container'>

                <Form
                    onSubmit={doLogin}
                    submitText={<Translator path="login.button.ENTER"></Translator>}
                    buttonId='button-login'
                >

                    <Input id='login-user' type='text'
                        name='username'
                        placeholder={t('common.label.USER')} autoComplete='username'
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        autoCapitalize='off'
                        autoFocus={true}
                    ></Input>

                    <Input id='login-password' type='password'
                        name='password'
                        placeholder={t('common.label.PASSWORD')} autoComplete='current-password'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    ></Input>

                    <Checkbox
                        label={<Translator path="login.checkbox.KEEP_SESSION_ALIVE"></Translator>}
                        name="active_session"
                        id='active_session'
                        value={activeSession}
                        toggleFn={() => {
                            setActiveSession(!activeSession)
                        }}
                    ></Checkbox>

                    <I18n></I18n>
                </Form>

            </div>

        </div>
    )
}
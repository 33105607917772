import { backendStatus } from '../../backend/backend'

export async function fetchStaticRouting(backend, setStaticRouting) {
    let result = await backend.retrieveFresh('static_routing')
    if(result.status === backendStatus.SUCCESS){
        let staticRoutingList = result.content
        setStaticRouting(staticRoutingList)
    }
}

export async function fetchStaticRoutingById(backend, setStaticRouting, id) {
    let result = await backend.retrieveFresh(`static_routing/${id}`)
    if(result.status === backendStatus.SUCCESS){
        let staticRoutingList = result.content
        setStaticRouting(staticRoutingList)
    }
}

export async function saveStaticRouting(backend, staticRouting, setErrorMessage) {
    try{
        let result = null;
        result = await backend.create('static_routing', staticRouting)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }
        return result.status === backendStatus.SUCCESS
    }
    catch(error){
        console.error('Error saving Static Routing:', error)
        return false
    }
}

export async function updateStaticRouting(backend, staticRouting, setErrorMessage) {
    try{
        let result = null;
        result = await backend.update(`static_routing/${staticRouting.id}`, staticRouting)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }
        return result.status === backendStatus.SUCCESS
    }
    catch(error){
        console.error('Error saving Static Routing:', error)
        return false
    }
}

export async function deleteStaticRouting(backend, id, setStaticRouting) {
    let result = await backend.delete(`static_routing/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchStaticRouting(backend, setStaticRouting)
    }
} 
import React, { useState, useEffect, useContext } from 'react'

// Stylessheet
import './system.css'

// Resources
import { BackendContext } from '../../../backend/backend';
import { fetchDatetime, updateDatetime } from '../../resources/datetime';
import { fetchSystemAutomaticMaintenance } from '../../resources/system-automatic-maintenance';

// Midgard components
import Loading from '../../../components/loading/loading';
import Checkbox from '../../../components/checkbox/checkbox';
import DefaultStatusModals from '../../../components/modal/default-status-modals';
import Form from '../../../components/form/form';
import FormSegment from '../../../components/form/form-segment';
import Input from '../../../components/input/input';
import InfoCard from '../../../components/infocard/infocard';
import Select from '../../../components/select/select';

// Translator
import Translator from '../../common/components/translator/translator';
import { useTranslation } from 'react-i18next';

// Validators
import extraValidators from '../../common/validators';
import Button from '../../../components/button/button';

export default function Datetime({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [datetime, setDatetime] = useState({
        "datetime": 1645482702,
        "dst_enabled": false,
        "id": "",
        "ntp_server": {
            "automatic": false,
            "enabled": true,
            "ntp_server1": "200.160.7.186",
            "ntp_server2": "200.160.0.8"
        },
        "synced": true,
        "timezone": "114",
        "timezone_list": [
            {
                "id": 114,
                "region": "Am\u00E9rica, \u00CDndiaa, Vevay (UTC-05:00)"
            },
        ]
    })

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [success, setSuccess] = useState(false)
    const backend = useContext(BackendContext)

    const [currentTime, setCurrentTime] = useState(null)
    const [updateContinue, setUpdateContinue] = useState(false)
    const [automaticMaintenance, setAutomaticMaintenance] = useState(null)

    const { t } = useTranslation()

    const updateStatus = async () => {
        await fetchDatetime(backend, setDatetime)
    }

    useEffect(() => {

        updateStatus()
        fetchSystemAutomaticMaintenance(backend, 0, setAutomaticMaintenance)

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (datetime && datetime.timezone) {
            let sl = datetime.timezone_list.filter(tz => tz.id === parseInt(datetime.timezone))[0].region.split(" ")
            sl = sl[sl.length - 1]
            let signal = -1
            if (sl.indexOf('+') !== -1) {
                signal = 1
            }
            let timezone = signal * parseInt(sl.split(signal === -1 ? '-' : '+')[1].split(":")[0]) * 3600
            setCurrentTime((datetime.datetime + timezone) * 1000)
        }

    }, [datetime, datetime.timezone])

    useEffect(() => {

        let mounted = true
        setTimeout(() => mounted && setCurrentTime(currentTime + 1000), 1000)

        return () => mounted = false

    }, [currentTime])

    const resetNTPServer = async () => {
        if (saving || error || success) return

        setSaving(true)

        let currentDateTime = await fetchDatetime(backend)
        currentDateTime.ntp_server.automatic = true
        if (await updateDatetime(backend, currentDateTime, setErrorMsg)) {
            currentDateTime = await fetchDatetime(backend)
            setDatetime({ ...currentDateTime, ntp_server: { ...currentDateTime.ntp_server, automatic: false, enabled: true } })
        } else {
            setError(true)
        }
        setSaving(false)
    }

    const translateErrorMessagesFromBackend = (errorMsg) => {
        if (errorMsg === 'invalid ntp server')
            return t('system.datetime.error.INVALID_NTP_SERVER')

        return errorMsg
    }


    const NTPAutomatic = (datetime, setDatetime) => {
        return datetime.ntp_server.enabled ?
            <Button
                id='btn-default-ntp-servers'
                text={'Aplicar servidores padrão'}
                type='button'
                onClick={resetNTPServer}
                outlined={true}
            ></Button> : null
    }

    const NTPFields = (datetime, setDatetime) => {
        return datetime.ntp_server.enabled && <>
            <Input name='ntp_server_1'
                id='ntp_server_1'
                label={<Translator path="system.datetime.label.NTP_SERVER_NTP_SERVER_1"></Translator>}
                value={datetime.ntp_server.ntp_server1}
                onChange={(e) => {
                    datetime.ntp_server.ntp_server1 = e.target.value
                    setDatetime({ ...datetime })
                }}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    extraValidators.isIpDomain,
                    { fn: extraValidators.size, params: { min: 0, max: 254 } }
                ]}
            ></Input>

            <Input name='ntp_server_2'
                id='ntp_server_2'
                label={<Translator path="system.datetime.label.NTP_SERVER_NTP_SERVER_2"></Translator>}
                value={datetime.ntp_server.ntp_server2}
                onChange={(e) => {
                    datetime.ntp_server.ntp_server2 = e.target.value
                    setDatetime({ ...datetime })
                }}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    extraValidators.isIpDomain,
                    { fn: extraValidators.size, params: { min: 0, max: 254 } },
                ]}
            ></Input>
        </>
    }

    const DateTimeInfo = (datetime, currentTime) => {
        return !datetime || !currentTime ? null :
            <InfoCard
                lines={[
                    {
                        label: <Translator path="system.datetime.label.CURRENT_TIME"></Translator>,
                        value: new Date(currentTime).toUTCString('pt-br'),
                        id: 'card-datetime'
                    },
                    {
                        label: <Translator path="system.datetime.label.SYNCED"></Translator>,
                        value: datetime.synced ? <Translator path="modal.answer.YES"></Translator> :
                            <Translator path="modal.answer.NO"></Translator>,
                        id: 'card-datetime-status',
                        warning: !datetime.synced,
                        infoWidth: '260px',
                        infoMessage: <Translator path="system.datetime.warning.SYNCED_INFO"></Translator>
                    }
                ]}
            ></InfoCard>
    }

    const datetimeForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>

            {DateTimeInfo(datetime, currentTime)}

            <Checkbox id='datetime-enabled'
                name='dst_enabled'
                label={<Translator path="system.datetime.label.DAYLIGHT_SAVING_ENABLED"></Translator>}
                value={datetime.dst_enabled}
                toggleFn={(e) => {
                    datetime.dst_enabled = !datetime.dst_enabled;
                    setDatetime({ ...datetime })
                }}
            ></Checkbox>

            <Select
                id='datetime-timezone'
                name='timezone'
                label={<Translator path="system.datetime.label.TIMEZONE"></Translator>}
                options={datetime.timezone_list.map(dtli => ({ value: dtli.id, text: dtli.region }))}
                value={datetime.timezone}
                onChange={(e) => {
                    datetime.timezone = e.target.value
                    setDatetime({ ...datetime })
                }}
            ></Select>

            <Checkbox id='datetime-ntp-server-enabled'
                name='enabled'
                label={<Translator path="system.datetime.label.NTP_SERVER_ENABLED"></Translator>}
                value={datetime.ntp_server.enabled}
                clearErrors={true}
                toggleFn={(e) => {
                    datetime.ntp_server.enabled = !datetime.ntp_server.enabled;

                    if (!datetime.ntp_server.enabled) {
                        datetime.ntp_server.automatic = true
                        datetime.ntp_server.ntp_server1 = ''
                        datetime.ntp_server.ntp_server2 = ''
                    }

                    setDatetime({ ...datetime })
                }}
            ></Checkbox>

            {NTPAutomatic(datetime, setDatetime)}

            {NTPFields(datetime, setDatetime)}

        </React.Fragment>
    }

    const submit = async () => {

        if (saving || error || success) return

        setSaving(true)

        let ok = await updateDatetime(backend, datetime, setErrorMsg)

        if (!ok) {
            setSaving(false)
            setError(true)
            return
        }

        await new Promise(r => setTimeout(r, 5000));

        ok = await fetchDatetime(backend, setDatetime)

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setUpdateContinue(false)
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    const preSave = async () => {
        setSaving(false)
        setError(false)
        setSuccess(false)

        if (automaticMaintenance.enabled && !datetime.ntp_server.enabled)
            setUpdateContinue(true)
        else
            await submit()
    }

    const continueUpdate = async (confirm) => {
        setSaving(false)
        setError(false)
        setSuccess(false)

        if (confirm) {
            setUpdateContinue(false)
            await submit()
        } else {
            dismissModal()
        }
    }

    return (
        !datetime || !automaticMaintenance ? <Loading show={true}></Loading> :

            <div id='datetime-page' className='info tex align-center'>
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path='menu.DATETIME'></Translator></div>
                <div className='card mt2'>
                    <div className='subtitle'><Translator path='system.datetime.title.DATETIME_SETTINGS'></Translator></div>

                    <DefaultStatusModals
                        error={error}
                        success={success}
                        continueFn={continueUpdate}
                        confirm={updateContinue}
                        confirmContent={
                            <div style={{ width: '420px' }}>
                                <span>
                                    <Translator path="system.datetime.warning.CONFIRMATION_MSG"></Translator>
                                    <br></br>
                                    <br></br>
                                    <strong><Translator path="system.datetime.warning.CONFIRM_QUESTION"></Translator></strong>
                                </span>
                            </div>
                        }
                        isWarningModal={true}
                        dismissText={<Translator path="common.label.CANCEL"></Translator>}
                        confirmText={<Translator path="common.label.CONFIRM"></Translator>}
                    ></DefaultStatusModals>

                    <DefaultStatusModals
                        saving={saving}
                        error={error}
                        errorText={translateErrorMessagesFromBackend(errorMsg)}
                        success={success}
                        continueFn={dismissModal}
                        successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                    ></DefaultStatusModals>

                    {!isFormSegment ? <Form
                        onSubmit={preSave}
                        buttonId='button-save'
                        isPristine={false}
                    >
                        {datetimeForm()}
                    </Form> :

                        <FormSegment
                            title={formSegment.title}
                            active={formSegment.active}
                            nextLabel={formSegment.nextLabel}
                            nextClicked={preSave}
                        >
                            {datetimeForm()}
                        </FormSegment>}
                </div>
            </div>
    )
}

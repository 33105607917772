import React, { useState, useEffect, useContext } from 'react'

import './ipv6-status.css'
import { BackendContext } from '../../../../backend/backend';
import { fetchServiceSystem, saveServiceSystem } from '../../../resources/system';
import { fetchNetworkWanList } from '../../../resources/network-wan';
import { fetchIpv6Dhcp } from '../../../resources/ipv6-dhcp';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import { IPv6Context } from '../ipv6-context';
import Translator from '../../../common/components/translator/translator';
import { useTranslation } from 'react-i18next';

export default function IPv6Status({isWizard, setSaved, isFormSegment, formSegment}) {
    const [ipv6Status, setIPv6Status] = useState(null)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [networkWanList, setNetworkWanList] = useState(null)
    const [dhcpv6Enabled, setDhcpv6Enabled] = useState(false)

    const backend = useContext(BackendContext)

    const ipv6Context = useContext(IPv6Context)

    const { t } = useTranslation()

    const updateStatus = async () => {
        await fetchServiceSystem(backend, setIPv6Status)
    }

    useEffect(() => {

        updateStatus()
        fetchNetworkWanList(backend, setNetworkWanList)
        fetchIpv6Dhcp(backend, null, setDhcpv6Enabled, null, null)

        // eslint-disable-next-line
    }, [])

    const findWANIPv6 = () => {
        return networkWanList?.filter(wan => (wan.protocol === 1 || wan.protocol === 2) ).map(wan => wan.id)  
    }

    const hasWANIPv6 = () => {
        if (findWANIPv6()?.length === 0){
            return false
        }
        return true
    }

    const ipv6StatusForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <Checkbox id='ipv6-status-enabled'
                name='enabled'
                label={<Translator path="ipv6.status.label.ENABLE"></Translator>}
                value={ipv6Status.ipv6.enabled}
                toggleFn={(e) => {
                    ipv6Status.ipv6.enabled = !ipv6Status.ipv6.enabled;
                    setIPv6Status({...ipv6Status})
                }}
                validators={[]}
            ></Checkbox>
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveServiceSystem(backend, ipv6Status)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        } else {
            ipv6Context.setIPv6Status({...ipv6Status})
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !ipv6Status ? <Loading show={true}></Loading> :

        <div id='ipv6-status-page' className='container'>

            <div className='subtitle'><Translator path='common.label.STATUS'></Translator></div>
            <div className='section-divider'></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path='ipv6.status.title.ENABLE_IPV6'></Translator></div>

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    success={success}
                    continueFn={dismissModal}
                    successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
                ></DefaultStatusModals>

                {!isFormSegment ? <Form
                    onSubmit={submit}
                    buttonId='button-save'
                    disableButton={!ipv6Status.ipv6.enabled && (hasWANIPv6() || dhcpv6Enabled)}
                >
                    {ipv6StatusForm()}
                    {
                        !ipv6Status.ipv6.enabled && hasWANIPv6() ? 
                        (<div id='ipv6-status-info'>
                            <p>
                                {t(`ipv6.status.message.warning.WAN_IPV6_INTERFACES`)}
                                <br/>
                            </p>

                            {
                                findWANIPv6()?.map(wan =>
                                    <div key={wan}>
                                        <strong>{ wan }</strong>  
                                    </div>)
                            }
                            <p>
                                {t(`ipv6.status.message.warning.MODIFY_WAN_PROTOCOL`)}   
                                <a href='#/network/wan'>{" " + t(`ipv6.status.label.IPV6_DISABLE`)}</a>
                            </p>
                        </div>
                        )
                        : !ipv6Status.ipv6.enabled && dhcpv6Enabled ? 
                        (<div id='ipv6-status-info'>
                            <p>
                                {t(`ipv6.dhcp.message.error.IPV6_DHCP_ENABLED`)}
                                <a href='#/ipv6/dhcp'>{" " + t(`ipv6.status.label.IPV6_DISABLE_DHCP`)}</a>
                                <br/>
                            </p>
                        </div>
                        )
                        : null
                    }
                </Form> : 

                <FormSegment
                    title={formSegment.title} 
                    active={formSegment.active} 
                    nextLabel={formSegment.nextLabel} 
                    nextClicked={submit} 
                >
                    {ipv6StatusForm()}
                </FormSegment>}
            </div>
        </div>
    )
}

import React, {useState, useEffect, useContext} from 'react'

import { BackendContext } from '../../../backend/backend';
import { fetchServiceSystem } from '../../resources/system';

export const IPv6Context = React.createContext({})

export default function IPv6State({children}) {

    const [ipv6Status, setIPv6Status] = useState(null)
    const [ipv6StaticRouteElementsList, setIpv6StaticRouteElementsList] = useState(null)

    const backend = useContext(BackendContext)

    useEffect(() => {
        fetchServiceSystem(backend, setIPv6Status)
        // eslint-disable-next-line
    }, [])

    const isIPv6Enabled = () => {
        return ipv6Status?.ipv6?.enabled
    }

    return <IPv6Context.Provider value={{
        isIPv6Enabled,
        setIPv6Status,
        ipv6StaticRouteElementsList,
        setIpv6StaticRouteElementsList
    }}>
        {children}
    </IPv6Context.Provider>
}

import React, { useState, useEffect, useContext } from 'react'

import './neighbour-hosts.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchNeighbourHosts, NeighbourUnreachabilityDetection} from '../../../resources/neighbour-hosts';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list'
import Pager from '../../../../components/pager/pager';
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function NeighbourHosts({isWizard, setSaved, isFormSegment, formSegment}) {
    const [neighbourHosts, setNeighbourHosts] = useState(null)

    const [neighbourHostsPagination, setNeighbourHostsPagination] = useState(null)

    const backend = useContext(BackendContext)

    const handleError = () => {
        setNeighbourHosts([])
    }

    useEffect(() => {

        setNeighbourHostsPagination({pageNumber: 1, totalElements: 0, pageSize: 10})

        fetchNeighbourHosts(backend, setNeighbourHosts, handleError)

        // eslint-disable-next-line
    }, [])
    
    const getColumns = () => {

        return [
            {header: <Translator path="common.label.IP"></Translator>, align: 'center', size: '275px'},
            {header: <Translator path="common.label.MAC_ADDRESS"></Translator>, align: 'center', size: '175px'},
            {header: <Translator path="common.label.INTERFACE"></Translator>, align: 'center', size: '100px'},
            {header: 'NUD', align: 'center', size: '140px'},
        ]
    }

    const getNeighbourHosts = () => {
        if(neighbourHosts.length !== neighbourHostsPagination.totalElements) {
            setNeighbourHostsPagination({...neighbourHostsPagination, totalElements: neighbourHosts.length})
        }

        let neighbourHostsLines = [];

        let begining = (neighbourHostsPagination.pageNumber - 1) * (neighbourHostsPagination.pageSize)
        let end = begining + neighbourHostsPagination.pageSize
        if (end > neighbourHostsPagination.totalElements) {
            end = neighbourHostsPagination.totalElements
        }
        for(let i = begining; i < end; i++) {
            neighbourHostsLines.push([neighbourHosts[i].ip_addr, neighbourHosts[i].mac_addr, neighbourHosts[i].interfaceID, NeighbourUnreachabilityDetection[neighbourHosts[i].nud]]);
        }

        return neighbourHostsLines;
    }

    const changePage = page => {
        setNeighbourHostsPagination({...neighbourHostsPagination, pageNumber: page})
    }

    const neighbourHostsForm = () => {
        return <React.Fragment>
                
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="advanced.neighbour_hosts.title.NEIGHBOUR_HOSTS"></Translator></div>
            {
                neighbourHosts?.length > 0 ?
                    <>
                        <div className='neighbour-hosts-list-wrapper'>
                            <List 
                                lines={getNeighbourHosts()}
                                columns={getColumns()}
                            ></List>
                        </div>
                        <Pager 
                            pageNumber={neighbourHostsPagination.pageNumber}
                            totalElements={neighbourHostsPagination.totalElements}
                            pageSize={neighbourHostsPagination.pageSize}
                            pageChangeFn={changePage}>
                        </Pager>
                        <Button
                            id='button-refresh'
                            type='button'
                            text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                            onClick={async () => {
                                await fetchNeighbourHosts(backend, setNeighbourHosts, handleError)}
                            }
                        ></Button>
                    </> : 
                    <div className="align-center">
                        <Translator path="advanced.neighbour_hosts.error.COULD_NOT_GET_INFO"></Translator>
                    </div>
            } 
        </React.Fragment>
    }

    return (
        !neighbourHosts ? <Loading show={true}></Loading> :

        <div id='neighbour-hosts-page' className='container scroll-area'>

            { neighbourHostsForm() }

        </div>
    )
}

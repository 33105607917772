import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Switch, Route, useHistory } from 'react-router-dom'
import { MainHeaderContext } from '../../../components/main-header/main-header-state'
import Tabs from '../../../components/tabs/tabs'
import ServiceState from './service-context'
import ServiceProxyIGMP from './service-proxy-igmp/service-proxy-igmp'
import ServiceUPnP from './service-upnp/service-upnp'
import ServiceDynamicDns from './dynamic-dns/service-dynamic-dns'
import ServiceRipConfig from './rip-config/service-rip-config'
import { GlobalsContext } from '../../globals-context'
/*IMPORTS_TAG*/

export default function ServicePage() {

    const header = useContext(MainHeaderContext)
    const history = useHistory()

    let globals = useContext(GlobalsContext)

    const { t, i18n } = useTranslation();

    const tabs = [
        { label: t('service.proxy_igmp.title.PROXY_IGMP'), route: '/service/proxy_igmp' },
        { label: t('service.upnp.title.UPNP'), route: '/service/upnp' },
        { label: t('menu.DYNAMIC_DNS'), route: '/service/dynamic-dns' },
        { label: t('service.rip_config.title.RIP_CONFIG'), route: '/service/rip-config' },
        /*TABS_TAG*/
    ]

    useEffect(() => {
        header.title.set(t('menu.SERVICE'))
        header.backRoute.set('/service/proxy_igmp')
    }, [i18n.language, t, header.title, header.backRoute])

    const changeRoute = (route) => {
        let path = history.location.pathname
        if (path.includes('service') && path.includes(route))
            window.location.reload()

        history.push(route)
    }

    let filteredTabs = tabs;
    if (globals.isLimitedUserFlag()) {
        filteredTabs = tabs.filter(tab => tab.route === '/service/upnp');
    }

    return (
        <ServiceState>

            <Tabs
                data={filteredTabs}
                route={history.location.pathname}
                activateFn={changeRoute}
            ></Tabs>

            <Switch>
                <Route path='/service/proxy_igmp' render={(props) => {
                    return <ServiceProxyIGMP {...props} ></ServiceProxyIGMP>
                }}></Route>

                <Route path='/service/upnp' render={(props) => {
                    return <ServiceUPnP {...props} ></ServiceUPnP>
                }}></Route>

                <Route path='/service/dynamic-dns' render={(props) => {
                    return <ServiceDynamicDns {...props} ></ServiceDynamicDns>
                }}></Route>

                <Route path='/service/rip-config' render={(props) => {
                    return <ServiceRipConfig {...props} ></ServiceRipConfig>
                }}></Route>

                {/*ROUTES_TAG*/}
            </Switch>
        </ServiceState>
    )
}

import React from 'react'
import Input from '../../../../components/input/input'
import Loading from '../../../../components/loading/loading'

import { OLTProfileOptions } from '../../../resources/pon-settings'
import Select from '../../../../components/select/select'

import extraValidators from '../../../common/validators'
import Translator from '../../../common/components/translator/translator'

export default function PONSettingsOMCI(ponSettings, setPONSettings, t) {


    const handleOLTProfile = (event) => {
        ponSettings.omci.olt_profile = Number(event.target.value)
        setPONSettings({...ponSettings})
    }
    
    const getOltProfileOptions = () => {
        let options = [...OLTProfileOptions]
        options.forEach( (element, index) => {
            element.text = t(element.text)
        })
        return options
    }

    return (
        !ponSettings.omci ? <Loading show={true}></Loading> :
        <React.Fragment>
        <div className="card mt2">
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="pon.settings.title.OMCI"></Translator></div>
            <Input id='pon-settings-omci-model-id'
                name='model_id'
                label={<Translator path="pon.settings.label.GPON_ONU_MODEL"></Translator>}
                value={ponSettings.omci.model_id}
                onChange={(e) => {
                    ponSettings.omci.model_id = e.target.value
                    setPONSettings({...ponSettings})
                }}
                validators={[]}
                readOnly={true}
            ></Input>
            <Input id='pon-settings-omci-vendor-id'
                name='vendor_id'
                label={<Translator path="pon.settings.label.GPON_ID" required={true}></Translator>}
                value={ponSettings.omci.vendor_id}
                onChange={(e) => {
                    ponSettings.omci.vendor_id = e.target.value
                    setPONSettings({...ponSettings})
                }}
                validators={[
                    extraValidators.required,
                    extraValidators.nonASCII,
                    {fn: extraValidators.isVendorId},
                    extraValidators.forbidWhitespacesOnly
                ]}
            ></Input>
            <Input id='pon-settings-omci-serial-number'
                name='serial_number'
                label={<Translator path="pon.settings.label.SERIAL_NUMBER"></Translator>}
                value={ponSettings.omci.serial_number}
                onChange={(e) => {
                    ponSettings.omci.serial_number = e.target.value
                    setPONSettings({...ponSettings})
                }}
                validators={[]}
                readOnly={true}
            ></Input>

            <Input id='pon-settings-omci-hardware-version'
                name='hardware_version'
                label={<Translator path="pon.settings.label.HARDWARE_VERSION"></Translator>}
                value={ponSettings.omci.hardware_version}
                readOnly={true}
            ></Input>

            <Select
                id='pon-settings-omci-olt-profile'
                name='olt_profile'
                label={<Translator path="pon.settings.label.OLT_PROFILE"></Translator>}
                options={getOltProfileOptions()}
                value={ponSettings.omci.olt_profile}
                onChange={handleOLTProfile}
            ></Select>
        </div>
        </React.Fragment>
    )
}
import React from 'react'
import Input from '../../../../components/input/input'
import Loading from '../../../../components/loading/loading'
import Translator from '../../../common/components/translator/translator'

export default function PONSettingsOAM(ponSettings, setPONSettings) {

    return (
        !ponSettings.oam ? <Loading show={true}></Loading> :
        <React.Fragment>
            <div className="card mt2">
                <div className='section-divider'></div>
                <div className='subtitle'><Translator path="pon.settings.title.OAM"></Translator></div>

                <Input id='pon-settings-oam-model-id'
                    name='model_id'
                    label={<Translator path="pon.settings.label.EPON_ONU_MODEL"></Translator>}
                    value={ponSettings.oam.model_id}
                    readOnly={true}
                ></Input>

                <Input id='pon-settings-oam-onu-id-mac'
                    name='onu_id_mac'
                    label={<Translator path="pon.settings.label.ONU_MAC"></Translator>}
                    value={ponSettings.oam.onu_id_mac}
                    readOnly={true}
                ></Input>

                <Input id='pon-settings-oam-hardware-version'
                    name='hardware_version'
                    label={<Translator path="pon.settings.label.HARDWARE_VERSION"></Translator>}
                    value={ponSettings.oam.hardware_version}
                    readOnly={true}
                ></Input>
            </div>

        </React.Fragment>
    )
}
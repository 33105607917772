import { backendStatus } from '../../backend/backend'

export async function fetchPONCounters(backend, setPONCounters) {
    let result = await backend.retrieveFresh('pon_counters')

    if(result.status === backendStatus.SUCCESS){

        let ponCountersList = result.content

        let ponCounters = ponCountersList.length !== 0 ? ponCountersList[0] : {
            throughput: {
                tx_bytes: 0,
                rx_bytes: 0,
                tx_packets: 0,
                rx_packets: 0,
                tx_unicast_packets: 0,
                rx_unicast_packets: 0,
                tx_multicast_packets: 0,
                rx_multicast_packets: 0,
                tx_broadcast_packets: 0,
                rx_broadcast_packets: 0,
                tx_pause_packets: 0,
                rx_pause_packets: 0,
                fec_errors: 0,
                hec_errors: 0,
                dropped_packets: 0
            },
            isCreate: true
        }

        setPONCounters(ponCounters)
    }
}

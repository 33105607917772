/*
    Interfaces
*/
export const LOOPBACK_INTERFACE = 'loopback'

/*
    IPV6
*/
export const IPV6_MULTICAST_PREFIX = 'ff'

/*
    Others
*/
export const LimitedUserName = "user"
export const AdminUserName = "admin"

import React, { useState, useEffect, useContext } from 'react'

import './ipv6-radvd.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchIPv6Radvd, saveIPv6Radvd } from '../../../resources/ipv6-radvd';
import { fetchServiceSystem } from '../../../resources/system';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input'
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import Checkbox from '../../../../components/checkbox/checkbox';
import IPv6RadvdPrefix from './ipv6-radvd-prefix';
import IPv6RadvdULAPrefix from './ipv6-radvd-ula-prefix';
import Translator from '../../../common/components/translator/translator';
import extraValidators from '../../../common/validators';

export default function IPv6Radvd({isWizard, setSaved, isFormSegment, formSegment}) {

    const { t } = useTranslation()

    const minRtrAdvIntervalValidator = async (value) => {
        const min = 3
        const max = 0.75*ipv6Radvd.max_rtr_adv_interval
        return ipv6Radvd.max_rtr_adv_interval < 4 || ((min <= value && value <= max ) ? '': t('common.message.error.VALUE_MIN_MAX_FIELD', {min: min , max: max}))
    }

    const [ipv6Radvd, setIPv6Radvd] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    let [system, setSystem] = useState(true)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchIPv6Radvd(backend, setIPv6Radvd)
        fetchServiceSystem(backend, setSystem)

        // eslint-disable-next-line
    }, [])

    const ipv6RadvdForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>

            <div className='card mt2'>
                <div className='subtitle'><Translator path="ipv6.radvd.title.RADVD_SETTINGS"></Translator></div>
                <div className="row larger-field-wrapper">
                    <Input id='ipv6-radvd-max-rtr-adv-interval'
                        name='max_rtr_adv_interval'
                        type="number"
                        label={<Translator path="ipv6.radvd.label.MAX_RTR_ADV_INTERVAL"></Translator>}
                        value={ipv6Radvd.max_rtr_adv_interval}
                        onChange={(e) => {
                            ipv6Radvd.max_rtr_adv_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                            setIPv6Radvd({...ipv6Radvd})
                        }}
                        
                        validators={[extraValidators.required, extraValidators.nonASCII, extraValidators.isNumber, {fn: extraValidators.value, params: {min: 4, max: 1800}}]}
                    ></Input>
                    <Input id='ipv6-radvd-min-rtr-adv-interval'
                        name='min_rtr_adv_interval'
                        type="number"
                        label={<Translator path="ipv6.radvd.label.MIN_RTR_ADV_INTERVAL"></Translator>}
                        value={ipv6Radvd.min_rtr_adv_interval}
                        onChange={(e) => {
                            ipv6Radvd.min_rtr_adv_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                            setIPv6Radvd({...ipv6Radvd})                 
                        }}
                        validators={[extraValidators.required, extraValidators.nonASCII, extraValidators.isNumber, minRtrAdvIntervalValidator]}
                        dependentValues={[ipv6Radvd.max_rtr_adv_interval]}
                    ></Input>
                </div>

                <Checkbox id='ipv6-radvd-adv-managed-flag'
                    name='adv_managed_flag'
                    label={<Translator path="ipv6.radvd.label.ADV_MANAGED_FLAG"></Translator>}
                    value={ipv6Radvd.adv_managed_flag}
                    toggleFn={(e) => {
                        setIPv6Radvd({...ipv6Radvd, adv_managed_flag: !ipv6Radvd.adv_managed_flag})
                    }}
                    validators={[]}
                ></Checkbox>
            </div>

            <div className='card mt2'>
                { IPv6RadvdULAPrefix(ipv6Radvd, setIPv6Radvd) }
            </div>

            <div className='card mt2'>
                { IPv6RadvdPrefix(ipv6Radvd, setIPv6Radvd) }
            </div>
            
       
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveIPv6Radvd(backend, ipv6Radvd, setErrorMessage)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    const hasIPv6Requiremnts = () => {
        if(isWizard || system === true) {
            return true
        }

        if(system) {
            if(system.ipv6) {
                if(system.ipv6.enabled) {
                    return true
                }
            }
        }

        return false
    }

    return (
        !ipv6Radvd ? <Loading show={true}></Loading> :

        <div id='ipv6-radvd-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="ipv6.radvd.title.RADVD"></Translator></div>
            {
                hasIPv6Requiremnts() ? null : 
                <div>
                    <p className='ipv6-radvd-info'>
                        {t(`ipv6.radvd.message.IPV6_DISABLED`)}
                        <br/>
                        {t(`common.message.warning.IPV6_ENABLE`)}
                        <a href='#/ipv6/status'>{" " + t(`common.label.STATUS`)}</a>
                    </p>
                </div>
            }

            <DefaultStatusModals
                errorText={t('common.message.error.SERVER_ERROR', { error: errorMessage })}
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            {!isFormSegment ? <Form
                onSubmit={submit}
                buttonId='button-save'
                disableButton={!hasIPv6Requiremnts()}
            >
                {ipv6RadvdForm()}
            </Form> : 

            <FormSegment
                title={formSegment.title} 
                active={formSegment.active} 
                nextLabel={formSegment.nextLabel} 
                nextClicked={submit} 
            >
                {ipv6RadvdForm()}
            </FormSegment>}

        </div>
    )
}

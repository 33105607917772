import { backendStatus } from '../../backend/backend'


export async function fetchAclCapability(backend, setAclCapability) {
    let result = await backend.retrieveFresh('ipv6_acl_capability')

    if(result.status === backendStatus.SUCCESS){

        let aclCapabilityList = result.content

        let aclCapability = aclCapabilityList[0]

        setAclCapability(aclCapability)
    }
}

export async function saveAclCapability(backend, aclCapability, setErrorMessage) {

    try{

        let result = null;

        result = await backend.update(`ipv6_acl_capability/${aclCapability.id}`, aclCapability)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content.body.message)
        }

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Acl Capability:', error)

        return false
    }

}

export async function fetchAclConfigList(backend, setAclConfig) {
    let result = await backend.retrieveFresh('ipv6_acl_config')

    if(result.status === backendStatus.SUCCESS){

        let aclConfigList = result.content

        setAclConfig(aclConfigList)
    }
}

export async function saveAclConfig(backend, aclConfig, setErrorMessage) {

    try{

        let result = null;
        if (aclConfig.id) {
            result = await backend.update(`ipv6_acl_config/${aclConfig.id}`, aclConfig)
        } else {
            result = await backend.create(`ipv6_acl_config/`, aclConfig)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content.body.message)
        }

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Acl Config:', error)

        return false
    }

}

export async function deleteAclConfigById(backend, id, setAclConfigList) {
    let result = await backend.delete(`ipv6_acl_config/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchAclConfigList(backend, setAclConfigList)
    }
}
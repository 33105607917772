import React, { useContext, useState, useEffect, useRef } from 'react'
import { BackendContext } from '../../../../backend/backend'
import { easyMeshType, fetchEasyMesh } from '../../../resources/easy-mesh'
import { GlobalsContext } from '../../../globals-context'
import { createRequest, httpMethod, HttpRequest } from '../../../resources/http-request'
import { fetchLan } from '../../../resources/lan'
import { fetchWan } from '../../../resources/wan'
import { fetchInterfaceStatisticsList } from '../../../resources/interface-statistics'
import { fetchDatetime } from '../../../resources/datetime'
import { fetchDevice } from '../../../resources/device'
import macutils from '../../../utils/macutils'

const RESET_ACTION = 2;

export const MeshContext = React.createContext({})
const EASY_MESH_SCAN_INTERVAL = 3000
const STATISTICS_TIMEOUT = 2
const DEFAULT_TIMEOUT = 10

const modelsWithoutMesh = ['W4-300F']

export default function Mesh({ children }) {

    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const scanTimeout = useRef(null)

    const [meshScan, setMeshScan] = useState(false)
    const [easyMesh, setEasyMesh] = useState(null)
    const [rootDeviceID, setRootDeviceID] = useState(null)
    const [rootDevice, setRootDevice] = useState(null)
    const [meshNodes, setMeshNodes] = useState([])
    const [nodeSession, setNodeSession] = useState(null)
    const [redirectedToController, setRedirectToController] = useState(false)

    const httpRetryTimeout = useRef(null)

    useEffect(() => {

        fetchEasyMesh(backend, setEasyMesh)
        fetchRootDevice()

        !rootDeviceID && fetchDeviceID()

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        scanTimeout.current = setTimeout(() => fetchMeshInfoScan(), EASY_MESH_SCAN_INTERVAL)

        return () => clearTimeout(scanTimeout.current)

        // eslint-disable-next-line
    }, [meshScan])

    useEffect(() => {

        if (!easyMesh) return

        let linearNodes = getMeshNodes(easyMesh, [])
        setNodeSession(Object.assign({}, ...linearNodes.map((node) => ({ [macutils.getDeviceIdFromMac(node.mac)]: node }))))
        setMeshNodes(linearNodes)

        // eslint-disable-next-line
    }, [easyMesh])

    useEffect(() => {
        fetchDeviceID()

        // eslint-disable-next-line
    }, [redirectedToController])

    const fetchDeviceID = async() => {
        const rootDeviceID = await globals.getDeviceID()
        setRootDeviceID(rootDeviceID)
    }

    const confirmDeleteNodeContent = () => {
        return <div style={{width:'420px'}}>
            <span style={{display:'block'}}>Este nó será excluído da sua rede mesh.
            <br></br>
            </span>
            <b>Deseja confirmar a exclusão?</b>
        </div>
    }

    const confirmDeleteOfflineNodeContent = () => {
        return <div style={{width:'520px'}}>
            <span style={{display:'block'}}>Você está excluindo um nó <b>offline</b> da sua rede mesh.<br></br>
            O dispositivo voltará a fazer parte da sua rede caso o mesmo volte a ficar online.<br></br>
            Para realizar uma exclusão efetiva, realize a mesma com o dispositivo conectado.
            <br></br>
            <br></br>
            </span>
            <b>Deseja confirmar a exclusão?</b>
        </div>
    }

    const getMeshNodes = (node, nodeList, parent) => {

        if (Array.isArray(node.neighbors) && node.neighbors.length) {
            node.neighbors.forEach(neighbor => {
                nodeList = getMeshNodes(neighbor, nodeList, node)
            })
        }

        if (node.hasOwnProperty('type')) return nodeList

        return [...nodeList, {
            name: node.name,
            parent: parent,
            mac: node.mac,
            ip: node.ip,
            backhaul: node.backhaul,
            rssi: node.rssi,
            is_online: node.is_online,
            token: "",
        }]
    }

    const fetchMeshInfoScan = () => {
        if(!meshScan) return

        fetchEasyMesh(backend, setEasyMesh)
        scanTimeout.current = setTimeout(() => fetchMeshInfoScan(), EASY_MESH_SCAN_INTERVAL)
    }

    const fetchRootDevice = async (setDevice) => {
        return await fetchDevice(backend, setDevice ? setDevice : setRootDevice)
    }

    const getIsMeshEnabled = () => {
        if (!easyMesh) return false

        return easyMesh.enabled ? easyMesh.enabled : false
    }

    const createRequestWithRetries = async(request, maxRetries = 5) => {
        let result
        let tries = 0
        do {
            tries++
            result = await createRequest(backend, request)
        } while (tries < maxRetries && httpRetryTimeout.current && result && result.body && result.body.code === 'timeout')
        return result
    }

    const openMeshSession = async (deviceId) => {

        if(!nodeSession|| !nodeSession[deviceId]) return

        let request = HttpRequest()
        request.dest = `http://${nodeSession[deviceId].ip}/api/v1/session`
        request.method = httpMethod.POST
        request.mesh_session = true

        let result = await createRequestWithRetries(request)

        if (result && result.status === 200) {
            let body = JSON.parse(result.body)
            if (body.token) {
                let newSession = Object.create(nodeSession)
                newSession[deviceId].token = body.token
                setNodeSession(newSession)
                return body.token
            }

            return false
        }

        return false
    }

    const meshRequest = async (deviceId, resource, body, method = httpMethod.POST, timeout = DEFAULT_TIMEOUT) => {
        if(!nodeSession || !nodeSession[deviceId]) return

        let request = HttpRequest()

        request.dest = `http://${nodeSession[deviceId].ip}/api/v1/${resource}`
        request.method = method
        request.body = body ? body : ""
        request.mesh_session = false
        request.timeout = timeout

        if (nodeSession[deviceId].token === "") {
            request.token = await openMeshSession(deviceId)
            if(!request.token) {
                retrieveEasyMesh()
                return false
            }
        } else {
            request.token = nodeSession[deviceId].token
        }

        let result = await createRequestWithRetries(request)

        if (result && result.status === 401) {
            request.token = await openMeshSession(deviceId)
            if(!request.token) {
                retrieveEasyMesh()
                return false
            }

            result = await createRequestWithRetries(request)

            if (!result || result.status !== 200) return false
        }

        if (result && result.status === 200) {
            if(result.body === "") return ""
            let body = JSON.parse(result.body)
            return body
        }

        retrieveEasyMesh()
        return false
    }

    const retrieveDevice = async (deviceId, setDevice) => {
        if (deviceId === rootDeviceID){
            return await fetchRootDevice(setDevice)
        } 
        else {
            const result = await meshRequest(deviceId, 'device/0', null, httpMethod.GET)
            if (setDevice) setDevice(result)
            return result
        }
    }

    const retrieveDevicesList = async (setDevicesList) => {
        if (!meshNodes) {
            setDevicesList(null)
            return
        }

        let devicesList = []

        for (let i = 0; i < meshNodes.length; i++) {
            if (!meshNodes[i].is_online) {
                devicesList.push({model: ""})
                continue
            }

            let result = await retrieveDevice(macutils.getDeviceIdFromMac(meshNodes[i].mac))
            if (!result) result = {}
            devicesList.push(result)
        }

        setDevicesList(devicesList)
    }

    const updateDevice = async (deviceId, deviceData) => {
        let result = await meshRequest(deviceId, `device/${deviceData.id ? deviceData.id : "0"}`, deviceData, httpMethod.PUT)
        if (result) {
            return true
        }

        return false
    }

    const retrieveEasyMesh = async (deviceId) => {
        let result = null
        if (!deviceId || deviceId === rootDeviceID){
            result = await fetchEasyMesh(backend, setEasyMesh)
        } else {
            result = await meshRequest(deviceId, 'easymesh/0', null, httpMethod.GET)
        }

        return result
    }

    const updateEasyMesh = async (deviceId, easyMeshData) => {
        let result = await meshRequest(deviceId, `easymesh/${easyMeshData.id ? easyMeshData.id : "0"}`, easyMeshData, httpMethod.PUT)
        if (result) {
            return true
        }

        return false
    }

    const retrieveConnectedDevices = async (setConnectedDevice) => {
        let connectedDevices = []

        for (let i = 0; i < meshNodes.length; i++) {
            const node = meshNodes[i];

            if (!node.is_online) break

            let result = await meshRequest(macutils.getDeviceIdFromMac(node.mac), 'connected_device', null, httpMethod.GET)
            if (result) {
                connectedDevices[node.mac] = result
            }
        }

        if (setConnectedDevice) setConnectedDevice(connectedDevices)
        return connectedDevices
    }

    const retrieveWan = async (deviceId, setWan) => {
        if (deviceId === rootDeviceID){
            const result = await fetchWan(backend, setWan)
            return result
        } else {
            const result = await meshRequest(deviceId, 'wan/0', null, httpMethod.GET)
            setWan(result)
            return result
        }
    }

    const retrieveLan = async (deviceId, setLan) => {
        if (deviceId === rootDeviceID){
            const result = await fetchLan(backend, setLan)
            return result
        } else {
            let result = await meshRequest(deviceId, 'lan', null, httpMethod.GET)
            if (result) {
                result = await meshRequest(deviceId, `interface/${result[0].interfaceID}`, null, httpMethod.GET)
                setLan(result)
                return result
            }
            return null
        }
    }

    const retrieveInterfaceStatistics = async (deviceId, interfaceID, setStatistics) => {
        if (deviceId === rootDeviceID){
            const result = await fetchInterfaceStatisticsList(backend, interfaceID, setStatistics, 2)
            return result
        } else {
            const result = await meshRequest(deviceId, `interface_statistics/${interfaceID}`, null, httpMethod.GET, STATISTICS_TIMEOUT)
            setStatistics(result)
            return result
        }
    }

    const retrieveDatetime = async (deviceId, setDatetime) => {
        if (deviceId === rootDeviceID){
            const result = await fetchDatetime(backend, setDatetime)
            return result
        } else {
            const result = await meshRequest(deviceId, 'datetime/0', null, httpMethod.GET)
            setDatetime(result)
            return result
        }
    }

    const hasMesh = () => {
        return rootDevice && !modelsWithoutMesh.includes(rootDevice.model)
    }

    const resetFactoryDefault = async (deviceId) => {
        if (deviceId === rootDeviceID){
            console.error('mesh context is not allowed to restore node in use')
            return false
        } else {
            const result = await meshRequest(deviceId, 'action', {actionID: RESET_ACTION}, httpMethod.POST)
            return result === ""
        }
    }

    const isMeshAgent = () => {
        return hasMesh() && easyMesh && easyMesh.enabled && easyMesh.type === easyMeshType.AGENT
    }

    const hasBackhaulConnection = () => {
        return easyMesh && easyMesh.backhaul_mac && easyMesh.backhaul_rssi
    }

    return <MeshContext.Provider value={{
        confirmDeleteNodeContent: confirmDeleteNodeContent,
        confirmDeleteOfflineNodeContent: confirmDeleteOfflineNodeContent,
        easyMesh: easyMesh,
        meshNodes: meshNodes,
        httpRetryTimeout: httpRetryTimeout,
        rootDevice: rootDevice,
        isMeshEnabled: getIsMeshEnabled,
        hasMesh: hasMesh,
        retrieveDevice: retrieveDevice,
        retrieveDevicesList: retrieveDevicesList,
        updateDevice: updateDevice,
        retrieveEasyMesh: retrieveEasyMesh,
        updateEasyMesh: updateEasyMesh,
        retrieveConnectedDevices: retrieveConnectedDevices,
        retrieveWan: retrieveWan,
        retrieveLan: retrieveLan,
        retrieveInterfaceStatistics: retrieveInterfaceStatistics,
        retrieveDatetime: retrieveDatetime,
        resetFactoryDefault: resetFactoryDefault,
        setMeshScan: setMeshScan,
        meshScan: meshScan,
        setRedirectToController: setRedirectToController,
        isMeshAgent: isMeshAgent,
        hasBackhaulConnection: hasBackhaulConnection,
    }}>{children}</MeshContext.Provider>
}
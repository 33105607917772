import { backendStatus } from  '../../backend/backend'

export async function fetchChangeToFirmwareVersion(backend, setChangeToFirmwareVersion) {

    let result = await backend.retrieveFresh('change_to_firmware_version')
    if(result.status === backendStatus.SUCCESS){
        setChangeToFirmwareVersion(result.content[0])
        return result.content[0]
    }

    return null
}

export async function updateChangeToFirmwareVersion(backend, change_to_firmware_version, setErrorMessage) {
    try {
        let result = await backend.update(`change_to_firmware_version/0`, change_to_firmware_version)
        
        if(result.status === backendStatus.SUCCESS) {
            return true
        } else {
            setErrorMessage(result.content?.body)
            return false
        }
    }catch(error){
        console.error('Error updating change_to_firmware_version:', error)
        return false
    }
}
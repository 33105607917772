const BYTE_SIZE = 8
const MEGABIT_SIZE = 1000000

const getTraffic = (value, unitBytes, time) => {

    if(time === 0) return '0.00'

    return (((value * unitBytes * BYTE_SIZE) / time) / MEGABIT_SIZE).toFixed(2)
}

export default {
    calculateTraffic: (oldStats, newStats) => {
    
        let download = getTraffic(
                    newStats.rx - oldStats.rx,
                    newStats.unity_size,
                    newStats.time - oldStats.time
                )
        let upload = getTraffic(
                    newStats.tx - oldStats.tx,
                    newStats.unity_size,
                    newStats.time - oldStats.time
                )
    
        return {download: download, upload: upload}
    },

    deviceToStatistics: (device) => {
        return {
            tx: device.txBytes,
            rx: device.rxBytes,
            time: device.time,
            unity_size: device.unitySize
        }
    },

    composeTime: (value) => {

        let days = Math.floor(value / 86400)
        value = value % 86400
        let hours = Math.floor(value / 3600)
        value = value % 3600
        let minutes = Math.floor(value / 60)
        value = Math.floor(value % 60)

        let text = (days > 0) ? days + ' dia(s), ' : ''
        text += (text || hours > 0) ? hours + ':' : ''
        text += (minutes < 10 ? '0' : '') + minutes + ':'
        text += (value < 10 ? '0' : '') + value

        return text
    }
}

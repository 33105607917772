import React, { useState, useEffect, useContext } from 'react'
import Button from '../../../../components/button/button';

import UpoadIcon from '../../../../components/icons/upload';
import Modal, { ModalTypes } from '../../../../components/modal/modal';

import './update.css'
import { BackendContext } from '../../../../backend/backend';
import { fetchDevice } from '../../../resources/device';
import { useHistory } from 'react-router-dom';
import waitRouter from '../../../utils/wait-router';
import { GlobalsContext } from '../../../globals-context';
import Loading from '../../../../components/loading/loading';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { SystemStatusContext } from '../../../system-status-context';
import { SessionCheckContext } from '../../../session-check-context';

import Translator from '../../../common/components/translator/translator';

const UPDATE_WAIT_TIME = 60000

const UpdatingModal = ({ open }) => {

    const updatingContent = <React.Fragment>
        <div>
            <Translator path="system.upgrade.warning.UPGRADING"></Translator>
            <br></br>
            <b>
                <Translator path="system.upgrade.warning.IMPORTANTING"></Translator>
            </b>

            <Translator path="system.upgrade.warning.DO_NOT_TURN_OFF"></Translator>
        </div>
    </React.Fragment>

    return <Modal show={open}
        title={<Translator path="system.upgrade.title.UPGRADING"></Translator>}
        content={updatingContent}
        type={ModalTypes.LOADING}
    ></Modal>
}

const UpdateErrorModal = (props) => {

    const errorContent = <React.Fragment>
        <div>
            <Translator path="system.upgrade.error.COULD_NOT_UPDATE"></Translator>
            <br></br>
            <Translator path="system.upgrade.error.TRY_AGAIN_OR_REACH_SUPPORT"></Translator>
            <br></br>
        </div>
    </React.Fragment>

    return errorContent
}

const successModalContent = () => {

    return <React.Fragment>
        <div>
            <Translator path="system.upgrade.info.FIRMWARE_UPDATED"></Translator>
        </div>
    </React.Fragment>
}

const SystemUpdate = () => {
    let [file, setFile] = useState(null)
    let [updating, setUpdating] = useState(false)
    let [error, setError] = useState(false)
    let [success, setSuccess] = useState(false)
    let [device, setDevice] = useState(null)

    const backend = useContext(BackendContext)
    const history = useHistory()
    const globals = useContext(GlobalsContext)
    const status = useContext(SystemStatusContext)
    const sessionCheck = useContext(SessionCheckContext)

    useEffect(() => {

        fetchDevice(backend, setDevice)

        // eslint-disable-next-line
    }, [])


    let update = async (evt) => {
        evt.preventDefault()

        if (updating) return

        try {
            setUpdating(true)
            status.setDisabled(true)
            status.disableUpdateModal(true)
            sessionCheck.setDisabled(true)

            let url = backend.getDriverBaseUrl()

            let result = await fetch(`${url}/upgrade_by_file`, {
                headers: {
                    'authorization': 'Bearer ' + sessionStorage.getItem('token'),
                },
                method: 'POST',
                body: file
            })

            if (result.status === 401) {
                history.push('/login?expired')
            }

            if (result.status !== 200)
                throw new Error('Error updating')

            await waitRouter(
                backend,
                globals.getItem('username'),
                globals.getItem('password'),
                history,
                UPDATE_WAIT_TIME
            )

            status.setDisabled(false)
            status.disableUpdateModal(false)
            sessionCheck.setDisabled(false)
            setUpdating(false)
            setSuccess(true)

        } catch (error) {
            setUpdating(false)
            setError(true)
        }

    }

    let dismiss = () => {

        if (success)
            window.location.href = window.origin

        setSuccess(false)
        setError(false)
        setUpdating(false)
    }

    return !device ? <Loading show={true}></Loading> :
        <div id='update-page' className='info-text align-center'>

            <div className='subtitle'><Translator path="menu.UPDATE_FIRMWARE"></Translator></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="system.upgrade.title.UPGRADE_FROM_FILE"></Translator></div>
                <Translator path="system.upgrade.title.SELECT_FILE"></Translator>
                <div className='info-text'>
                    <br />
                    <b>
                        <Translator path="system.upgrade.label.MODEL"></Translator>
                    </b>
                    {device.model}
                    <br />
                    <b>
                        <Translator path="system.upgrade.label.FIRMWARE_VERSION"></Translator>
                    </b>
                    {device.fw_version}
                </div>
                <UpdatingModal open={updating}></UpdatingModal>

                <DefaultStatusModals
                    success={success}
                    error={error}
                    successText={successModalContent()}
                    errorText={UpdateErrorModal()}
                    continueFn={dismiss}></DefaultStatusModals>

                <form id='update-form' onSubmit={update}>

                    <div className='update-selector'>

                        <label>
                            <UpoadIcon size='30'></UpoadIcon>
                            <span>{file ? file.name : <Translator path="system.upgrade.label.SELECT_FILE"></Translator>}</span>
                            <input id='file-upload'
                                disabled={!device || updating}
                                type='file'
                                accept=".tar"
                                onChange={(e) => { setFile(e.target.files[0]) }}
                            ></input>
                        </label>
                    </div>
                    {
                        file && !file.name.endsWith('.tar') &&
                        <span className="itb-input-error-text"><Translator path="system.upgrade.error.INVALID_FILE"></Translator></span>
                    }

                    <Button text={<Translator path="system.upgrade.label.UPGRADE"></Translator>} disabled={!file || !file.name.endsWith('.tar')} id='file-update'></Button>
                </form>
            </div>
        </div>
}

export default SystemUpdate
import React, { useState, useEffect, useContext } from 'react'

import './firewall-url-blocking.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchFirewallUrlBlocking, saveFirewallUrlBlocking } from '../../../resources/firewall-url-blocking';
import Loading from '../../../../components/loading/loading';
import Checkbox from '../../../../components/checkbox/checkbox';
import List from '../../../../components/list/list';
import Form from '../../../../components/form/form';
import Input from '../../../../components/input/input'
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import RemoveIcon from '../../../../components/icons/remove';
import { firewallUrlBlockingConstants } from '../../../resources/firewall-url-blocking'
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';
import extraValidators from '../../../common/validators';
import Pager from '../../../../components/pager/pager';

export default function FirewallUrlBlocking({isWizard, setSaved, isFormSegment, formSegment}) {
    const [firewallUrlBlocking, setFirewallUrlBlocking] = useState(null)

    const [newEntryKeyword, setNewEntryKeyword] = useState(null)

    const [newEntryUrl, setNewEntryUrl] = useState(null)

    const [firewallUrlBlockingKeywordsPagination, setFirewallUrlBlockingKeywordsPagination] = useState({pageNumber: 1, pageSize: 5})
    const [firewallUrlBlockingPagination, setFirewallUrlBlockingPagination] = useState({pageNumber: 1, pageSize: 5})

    const [pristine, setPristine] = useState(true)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchFirewallUrlBlocking(backend, setFirewallUrlBlocking)

        setNewEntryKeyword({keyword_id: ''})
        setNewEntryUrl({url: ''})

        // eslint-disable-next-line
    }, [])

    const getColumnsKeyword = () => {

        return [
            {header: t('common.label.KEYWORD'), align: 'center'},
            {header: t('common.label.ACTIONS'), align: 'center'},
        ]
    }

    const getColumnsUrls = () => {

        return [
            {header: t('common.label.URL'), align: 'center'},
            {header: t('common.label.ACTIONS'), align: 'center'},
        ]
    }
    
    const createStringSpan = string => {
        const MAX_STRING_SIZE = 20
        return (
            <span title={string}>
                {string.length > MAX_STRING_SIZE ? `${string.substring(0, MAX_STRING_SIZE)} ...` : string}
            </span>
        )
    }

    const getKeywords = () => {
        let begining = (firewallUrlBlockingKeywordsPagination.pageNumber - 1) * (firewallUrlBlockingKeywordsPagination.pageSize)
        let end = begining + firewallUrlBlockingKeywordsPagination.pageSize
        if (end > firewallUrlBlocking.keywords.length) {
            end = firewallUrlBlocking.keywords.length
        }
        let firewallUrlBlockingKeywordsLines = [];
        for(let i = begining; i < end; i++) {
            if(firewallUrlBlocking.keywords[i]){
                firewallUrlBlockingKeywordsLines.push(
                    [
                        createStringSpan(firewallUrlBlocking.keywords[i]),
                        <div>
                            <span className="clickable" onClick={e => removeItemKeyword(firewallUrlBlocking.keywords[i])}><RemoveIcon></RemoveIcon></span>
                        </div>
                    ]);
            }
        }
        return firewallUrlBlockingKeywordsLines;
    }
    
    const getUrls = () => {
        let begining = (firewallUrlBlockingPagination.pageNumber - 1) * (firewallUrlBlockingPagination.pageSize)
        let end = begining + firewallUrlBlockingPagination.pageSize
        if (end > firewallUrlBlocking.urls.length) {
            end = firewallUrlBlocking.urls.length
        }

        let firewallUrlBlockingLines = [];
        for(let i = begining; i < end; i++) {
            if(firewallUrlBlocking.urls[i]){
                firewallUrlBlockingLines.push(
                    [
                        createStringSpan(firewallUrlBlocking.urls[i]),
                        <div>
                            <span className="clickable" onClick={e => removeItemUrl(firewallUrlBlocking.urls[i])}><RemoveIcon></RemoveIcon></span>
                        </div>
                    ]);
            }
        }
        return firewallUrlBlockingLines;
    }

    const removeItemKeyword = (keywordID) => {
        firewallUrlBlocking.keywords = firewallUrlBlocking.keywords.filter(it => it !== keywordID)
        setFirewallUrlBlocking({...firewallUrlBlocking})
        setPristine(false)
    }

    const removeItemUrl = (urlId) => {
        firewallUrlBlocking.urls = firewallUrlBlocking.urls.filter(it => it !== urlId)
        setFirewallUrlBlocking({...firewallUrlBlocking})
        setPristine(false)
    }

    const handleKeyDownKeyword = (event, buttonUpdate) => {
        if (event.key === 'Enter' || buttonUpdate) {
            if (!buttonUpdate) {
                event.preventDefault()
            }
            if (newEntryKeyword.keyword_id.trim() && !keywordIdAlreadyExists(newEntryKeyword.keyword_id) &&
                firewallUrlBlocking.keywords.length < firewallUrlBlockingConstants.MAX_KEYWORDS_ELEMENTS) {
                    firewallUrlBlocking.keywords.push(newEntryKeyword.keyword_id.trim())
                    setFirewallUrlBlocking({...firewallUrlBlocking})
                    setNewEntryKeyword({keyword_id: ''})
                    setPristine(false)
            }
        }
    }

    const handleKeyDownUrl = (event, buttonUpdate) => {
        if (event.key === 'Enter' || buttonUpdate) {
            if (!buttonUpdate) {
                event.preventDefault()
            }
            if (newEntryUrl.url.trim() && !urlIdAlreadyExists(newEntryUrl.url) &&
                firewallUrlBlocking.urls.length < firewallUrlBlockingConstants.MAX_URLS_ELEMENTS) {
                    firewallUrlBlocking.urls.push(newEntryUrl.url.trim())
                    setFirewallUrlBlocking({...firewallUrlBlocking})
                    setNewEntryUrl({url: ''})
                    setPristine(false)
            }
        }
    }

    const keywordAlreadyExistsValidator = async (value) => {
        return value && keywordIdAlreadyExists(value) ? t('common.message.error.ITEM_ALREADY_EXISTS') : '';
    }

    const urlAlreadyExistsValidator = async (value) => {
        return value && urlIdAlreadyExists(value) ? t('common.message.error.ITEM_ALREADY_EXISTS') : '';
    }

    const keywordMaxSize = () => {
        return firewallUrlBlocking.keywords.length > (firewallUrlBlockingConstants.MAX_KEYWORDS_ELEMENTS - 1);
    }

    const urlMaxSize = () => {
        return firewallUrlBlocking.urls.length > (firewallUrlBlockingConstants.MAX_URLS_ELEMENTS - 1);
    }

    const keywordIdAlreadyExists = (keywordId) => {
        return firewallUrlBlocking.keywords.filter(it => it === keywordId).length > 0;
    }

    const urlIdAlreadyExists = (urlId) => {
        return firewallUrlBlocking.urls.filter(it => it === urlId).length > 0;
    }

    const changePageKeywords = page => {
        setFirewallUrlBlockingKeywordsPagination({...firewallUrlBlockingKeywordsPagination, pageNumber: page})
    }

    const changePageURL = page => {
        setFirewallUrlBlockingPagination({...firewallUrlBlockingPagination, pageNumber: page})
    }

    const firewallUrlBlockingForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='card mt2'>
                <div className='subtitle'><Translator path="firewall.url_blocking.label.ENABLE_URL_BLOCKING"></Translator></div>
                <Checkbox id='firewall-url-blocking-enable'
                    name='enable'
                    label={<Translator path="common.label.ENABLE"></Translator>}
                    value={firewallUrlBlocking.enable}
                    toggleFn={(e) => {
                        firewallUrlBlocking.enable = !firewallUrlBlocking.enable;
                        setFirewallUrlBlocking({...firewallUrlBlocking})
                    }}
                    validators={[]}
                ></Checkbox>
            </div>

            <div className='card mt2'>
                <div className='subtitle'><Translator path="firewall.url_blocking.label.ADD_NEW_KEYWORD"></Translator></div>
                <Input id='firewall-url-blocking-keyword'
                    name='keyword'
                    label={<Translator path="firewall.url_blocking.label.ADD_NEW_KEYWORD"></Translator>}
                    value={newEntryKeyword.keyword_id}
                    onChange={(e) => {
                        newEntryKeyword.keyword_id = e.target.value
                        setNewEntryKeyword({...newEntryKeyword})
                    }}
                    onKeyDown={handleKeyDownKeyword}
                    validators={[
                        {fn: keywordAlreadyExistsValidator}, 
                        {fn: extraValidators.size, params: { min: 0, max: firewallUrlBlockingConstants.MAX_KEYWORD_LEN }},
                        extraValidators.forbidWhitespacesOnly
                    ]}
                ></Input>

                {keywordMaxSize() && 
                    <strong>{t('common.message.error.MAX_NUMBER_OF_ELEMENTS_IS', {max: firewallUrlBlockingConstants.MAX_KEYWORDS_ELEMENTS})}</strong>
                }

                <Button
                    type='button'
                    id='btn-add-keyword'
                    text={<Translator path="common.label.ADD"></Translator>}
                    onClick={evt => handleKeyDownKeyword(evt, true)}
                    disabled={keywordMaxSize() || newEntryKeyword.keyword_id.length > firewallUrlBlockingConstants.MAX_KEYWORD_LEN}
                ></Button>
            </div>
            
            <div className='firewall-url-blocking-table' >
                <div className='subtitle'><Translator path="firewall.url_blocking.title.KEYWORDS_TABLE"></Translator></div>
                {firewallUrlBlocking.keywords.length > 0 ?
                    <div>
                        <List
                            lines={getKeywords()}
                            columns={getColumnsKeyword()}
                        ></List>
                        <Pager 
                            pageNumber={firewallUrlBlockingKeywordsPagination.pageNumber}
                            totalElements={firewallUrlBlocking.keywords.length}
                            pageSize={firewallUrlBlockingKeywordsPagination.pageSize}
                            pageChangeFn={changePageKeywords}>
                        </Pager>
                    </div>
                    :
                    <div id='firewall-url-blocking-no-keywords'>
                        <div className='info-card noelements-card'>
                            <Translator path="firewall.url_blocking.info.NO_KEYWORDS"/>
                        </div>
                    </div> 
                }
            </div>
            
            <div className='card mt2'>
                <div className='subtitle'><Translator path="firewall.url_blocking.label.ADD_NEW_URL"></Translator></div>
                <Input id='firewall-url-blocking-url'
                    name='url'
                    label={<Translator path="firewall.url_blocking.label.ADD_NEW_URL"></Translator>}
                    value={newEntryUrl.url}
                    onChange={(e) => {
                        newEntryUrl.url = e.target.value
                        setNewEntryUrl({...newEntryUrl})
                    }}
                    onKeyDown={handleKeyDownUrl}
                    validators={[
                        {fn: urlAlreadyExistsValidator}, 
                        {fn: extraValidators.size, params: { min: 0, max: firewallUrlBlockingConstants.MAX_URL_LEN }},
                        extraValidators.forbidWhitespacesOnly
                    ]}
                ></Input>
                
                {urlMaxSize() && 
                    <strong>{t('common.message.error.MAX_NUMBER_OF_ELEMENTS_IS', {max: firewallUrlBlockingConstants.MAX_URLS_ELEMENTS})}</strong>
                }

                <Button
                    type='button'
                    id='btn-add-url'
                    text={<Translator path="common.label.ADD"></Translator>}
                    onClick={evt => handleKeyDownUrl(evt, true)}
                    disabled={urlMaxSize() || newEntryUrl.url.length > firewallUrlBlockingConstants.MAX_URL_LEN}
                ></Button>
            </div>

            <div className='firewall-url-blocking-table'>  
            <div className='subtitle'><Translator path="firewall.url_blocking.title.URL_TABLE"></Translator></div>
                {firewallUrlBlocking.urls.length > 0 ? 
                    <div>
                        <List
                            lines={getUrls()}
                            columns={getColumnsUrls()}
                        ></List>
                        <Pager 
                            pageNumber={firewallUrlBlockingPagination.pageNumber}
                            totalElements={firewallUrlBlocking.urls.length}
                            pageSize={firewallUrlBlockingPagination.pageSize}
                            pageChangeFn={changePageURL}>
                        </Pager>
                    </div>
                    :
                    <div id='firewall-url-blocking-no-urls'>
                        <div className='info-card noelements-card'>
                            <Translator path="firewall.url_blocking.info.NO_URLS"/>
                        </div>
                    </div>  
                }
            </div> 
        </React.Fragment>
    }

    const submit = async() => {
        setErrorMessage('')

        if(saving || error || success) return        

        setSaving(true)

        let ok = await saveFirewallUrlBlocking(backend, firewallUrlBlocking, setErrorMessage)
        // setErrorMessage(t('firewall.ip_port_filtering.message.error.RULE_CONFLICT') + errorMessage)
        if(!ok){
            checkTranslation(t,errorMessage)
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    const checkTranslation = (t, errorRaw) => {
        if(errorRaw === 'you must provide urls and keywords to enable this service.') {
            return t('firewall.url_blocking.message.error.NO_RULES_ERROR')
        } 
        
        if(errorRaw === 'one or more elements exceeds the maximum of characters') {
            return t('firewall.url_blocking.message.error.MAX_CHARACTERS')
        }
    }

    return (
        !firewallUrlBlocking ? <Loading show={true}></Loading> :

        <div id='firewall-url-blocking-page' className='with-tabs'>
            <div className='subtitle'><Translator path="firewall.url_blocking.title.URL_BLOCKING_SETTINGS"></Translator></div>
            <div className='section-divider'></div>  

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                errorText={t('common.message.error.SERVER_ERROR', {error:checkTranslation(t, errorMessage)})}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>

            {!isFormSegment ? <Form
                onSubmit={submit}
                buttonId='button-save'
                isPristine={pristine}
                key={pristine}
            >
                {firewallUrlBlockingForm()}
            </Form> : 

            <FormSegment
                title={formSegment.title} 
                active={formSegment.active} 
                nextLabel={formSegment.nextLabel} 
                nextClicked={submit} 
            >
                {firewallUrlBlockingForm()}
            </FormSegment>}
        </div>
    )
}

import React, { useState, useEffect, useContext } from 'react'

import './epon-llid-status.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchEponLLIDStatus } from '../../../resources/epon-llid-status';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list'
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function EponLLIDStatus({isWizard, setSaved, isFormSegment, formSegment}) {
    const [eponLLIDStatus, setEponLLIDStatus] = useState(null)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    const handleError = () => {
        setEponLLIDStatus([])
    }

    useEffect(() => {

        fetchEponLLIDStatus(backend, setEponLLIDStatus, handleError)

        // eslint-disable-next-line
    }, [])
    
    const getColumns = () => {

        return [
            {header: t('common.label.ID'), align: 'center', size: '50%'},
            {header: t('common.label.STATUS'), align: 'center', size: '50%'},
        ]
    }

    const getEponLLIDStatus = () => {
        let eponLLIDStatusLines = [];

        for(let i = 0; i < eponLLIDStatus.length; i++) {
            eponLLIDStatusLines.push(
                [
                    parseInt(eponLLIDStatus[i].id)+1,
                    eponLLIDStatus[i].llid_id_status, 
                ]
            );
        }

        return eponLLIDStatusLines;
    }

    const eponLLIDStatusForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'>{<Translator path="pon.epon_llid.title.EPON_LLID_STATUS"></Translator>}</div>
            {
                eponLLIDStatus?.length > 0 ?
                <List 
                    lines={getEponLLIDStatus()}
                    columns={getColumns()}
                ></List> :
                    <div className="info-card noelements-card">
                        <Translator path="pon.epon_llid.message.error.COULD_NOT_GET_INFO"></Translator>
                    </div>

            }
            <div className='section-divider'></div>
            <Button
                id='button-refresh'
                type='button'
                text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                onClick={async () => {
                    await fetchEponLLIDStatus(backend, setEponLLIDStatus, handleError)}
                }
            ></Button>
            
        </React.Fragment>
    }

    return (
        !eponLLIDStatus ? <Loading show={true}></Loading> :

        <div id='epon-llid-status-page' className='with-tabs'>

            { eponLLIDStatusForm() }

        </div>
    )
}

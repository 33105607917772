import React, { useState, useEffect, useContext } from 'react'

import './advanced-qos-classification.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchQosClassificationList, deleteQosClassificationById } from '../../../resources/advanced-qos-classification';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import RemoveIcon from '../../../../components/icons/remove';
import EditIcon from '../../../../components/icons/edit';
import Button from '../../../../components/button/button'
import { AdvancedContext } from '../advanced-context';
import Modal from '../../../../components/modal/modal'
import { ModalTypes } from '../../../../components/modal/modal'
import InfoIcon from '../../../../components/icons/info-icon';

import { getMask } from '../../../common/validators';

import {
    getDSCPEnumerationElementsName,
    getIEEE_802_1pEnumerationElementsName,
    getQueueEnumerationElementsName,
    getTypeOfRuleEnumerationElementsName,
    TypeOfRuleEnumeration,
    getPhysicalPortEnumerationElementsName,
    IPVersionEnumeration,
    getIPVersionEnumerationElementsName,
    getIPProtocolEnumerationElementsName,
} from "./qos-classification-common"


export default function AdvancedQosClassification({ isWizard, setSaved, isFormSegment, formSegment }) {
    const [qosClassificationPagination, setQosClassificationPagination] = useState(
        {
            pageNumber: 1,
            totalElements: 0,
            pageSize: 10
        }
    )

    const [detailsModal, setDetailsModal] = useState(
        {
            show: false,
            qosClassificationElement: null,
        }
    )

    const { qosClassificationElementsList, setQosClassificationElementsList } = useContext(AdvancedContext)


    const backend = useContext(BackendContext)
    const history = useHistory()
    const { t } = useTranslation()

    useEffect(() => {
        fetchQosClassificationList(backend, setQosClassificationElementsList)
        // eslint-disable-next-line
    }, [])

    const editQosClassification = (element) => {
        history.push(`/advanced/qos-classification/${element.id}`)
    }

    const removeQosClassification = (element) => {
        deleteQosClassificationById(backend, element.id, setQosClassificationElementsList)
    }

    const createNewQosClassificationForm = () => {
        history.push(`/advanced/qos-classification/add`)
    }

    const getColumns = () => {

        return [
            { header: t('advanced.qos_classification.label.ID'), align: 'center' },
            { header: t('advanced.qos_classification.label.NAME'), align: 'center', size: '150px' },
            { header: t('advanced.qos_classification.label.ORDER'), align: 'center' },
            { header: t('advanced.qos_classification.label.DSCP_MARK'), align: 'center' },
            { header: t('advanced.qos_classification.label._802_1P'), align: 'center' },
            { header: t('advanced.qos_classification.label.QUEUE'), align: 'center' },
            { header: t('advanced.qos_classification.label.RULE_TYPE'), align: 'center', size: '150px' },
            { header: t('advanced.qos_classification.label.OPTIONS'), align: 'center' },
        ]
    }

    const changePage = page => {
        setQosClassificationPagination({ ...qosClassificationPagination, pageNumber: page })
    }

    const getDetails = (element) => {
        switch (element.mode) {
            case TypeOfRuleEnumeration.PORT:
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {`${t('advanced.qos_classification.label.PORTS')}: ${getPhysicalPortEnumerationElementsName(element.port.physical_port, t)}`}
                        <br />
                        {`${t('advanced.qos_classification.label.DSCP_PATTERN')}: ${getDSCPEnumerationElementsName(element.port.dscp_pattern, t)}`}
                    </div>
                )
            case TypeOfRuleEnumeration.ETHER_TYPE:
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {`${t('advanced.qos_classification.label.ETHERNET_TYPE')}: 0x${element.ethertype.value.toString(16).toUpperCase()}`}
                    </div>
                )
            case TypeOfRuleEnumeration.IP_PROTO:
                return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        {`${getIPVersionEnumerationElementsName(element.ip_protocol.ip_version, t)}, ${getIPProtocolEnumerationElementsName(element.ip_protocol.protocol, t)}`}
                        <br />

                        {`${t('advanced.qos_classification.label.DSCP_PATTERN')}: ${getDSCPEnumerationElementsName(element.ip_protocol.dscp_pattern, t)}`}
                        <br />

                        {element.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_BOTH ? null :
                            <div>
                                {
                                    element.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ?
                                        `${t('advanced.qos_classification.label.SOURCE_IP')}:  ${element.ip_protocol.ip_config_src.ip4}${getMask(element.ip_protocol.ip_config_src.ip4_mask)}` :
                                        `${t('advanced.qos_classification.label.SOURCE_IP')}:  ${element.ip_protocol.ip_config_src.ip6}/${element.ip_protocol.ip_config_src.ip6_prefix_length}`
                                }
                                < br />

                                {
                                    element.ip_protocol.ip_version === IPVersionEnumeration.IPVERSION_IPV4 ?
                                        `${t('advanced.qos_classification.label.DEST_IP')}:  ${element.ip_protocol.ip_config_dst.ip4}${getMask(element.ip_protocol.ip_config_dst.ip4_mask)}` :
                                        `${t('advanced.qos_classification.label.DEST_IP')}:  ${element.ip_protocol.ip_config_dst.ip6}/${element.ip_protocol.ip_config_dst.ip6_prefix_length}`
                                }
                                < br />
                            </div>
                        }

                        {`${t('advanced.qos_classification.label.SOURCE_PORT')}: [${element.ip_protocol.src_port.start}...${element.ip_protocol.src_port.end}]`}
                        <br />

                        {`${t('advanced.qos_classification.label.DEST_PORT')}: [${element.ip_protocol.dst_port.start}...${element.ip_protocol.dst_port.end}]`}
                        <br />

                    </div>
                )
            case TypeOfRuleEnumeration.MAC_ADDRESS:
                return (
                    <div>
                        {`${t('advanced.qos_classification.label.SOURCE_MAC')}: ${element.mac.src}`}
                        <br />
                        {`${t('advanced.qos_classification.label.DEST_MAC')}: ${element.mac.dst}`}
                    </div>
                )
            default:
                return "Undef"
        }
    }

    const ShowDetailsModal = (props) => {
        return (
            !props.qosClassificationElement ? null :
                <Modal show='true'
                    type={ModalTypes.INFO}
                    onDismissClick={() => { setDetailsModal({ show: false, qosClassificationElement: null, }) }}
                    title={t('advanced.qos_classification.label.INFORMATION')}
                    content={
                        <div className='details-modal-main-div'>

                            <div className='header-div'>
                                {`${t('advanced.qos_classification.label.NAME')}: ${props.qosClassificationElement.rule_name}`}
                            </div>

                            <div className='details-div' >
                                {getDetails(props.qosClassificationElement)}
                            </div>
                        </div>
                    }
                    dismissText={t('common.label.CONFIRM')}
                ></Modal>
        )
    }

    const getAdvancedQosClassification = () => {

        let qosClassificationFiltered = qosClassificationElementsList

        if (qosClassificationFiltered.length !== qosClassificationPagination.totalElements) {
            qosClassificationPagination.totalElements = qosClassificationFiltered.length
            setQosClassificationPagination({ ...qosClassificationPagination })
        }

        let qosClassificationLines = [];

        if (qosClassificationFiltered.length > 0) {
            let begining = (qosClassificationPagination.pageNumber - 1) * (qosClassificationPagination.pageSize)
            let end = begining + qosClassificationPagination.pageSize
            if (end > qosClassificationPagination.totalElements) {
                end = qosClassificationPagination.totalElements
            }

            for (let i = begining, nr = (1 + (qosClassificationPagination.pageNumber - 1) * qosClassificationPagination.pageSize); i < end; i++) {
                qosClassificationLines.push([
                    nr++,
                    <span title={qosClassificationFiltered[i].rule_name}>
                        {qosClassificationFiltered[i].rule_name.length > 20 ?
                            `${qosClassificationFiltered[i].rule_name.substring(0, 20)}...` :
                            qosClassificationFiltered[i].rule_name}
                    </span>,
                    qosClassificationFiltered[i].order,
                    getDSCPEnumerationElementsName(qosClassificationFiltered[i].precedence.dscp_remarking, t),
                    getIEEE_802_1pEnumerationElementsName(qosClassificationFiltered[i].precedence.cos_802_1p, t),
                    getQueueEnumerationElementsName(qosClassificationFiltered[i].precedence.precedence, t),
                    getTypeOfRuleEnumerationElementsName(qosClassificationFiltered[i].mode, t),
                    <div >

                        <span className="clickable" onClick={e => {
                            setDetailsModal(
                                {
                                    show: true,
                                    qosClassificationElement: qosClassificationFiltered[i],
                                }
                            )
                        }}><InfoIcon size="20px" /></span>

                        <span style={{ paddingInline: 6 }} />

                        <span className="clickable" onClick={e => {
                            editQosClassification(qosClassificationFiltered[i])

                        }}><EditIcon /></span>

                        <span style={{ paddingInline: 4 }} />

                        <span className="clickable" onClick={e => {
                            removeQosClassification(qosClassificationFiltered[i])
                        }
                        }><RemoveIcon /></span>
                    </div>
                ]);
            }

        }

        return qosClassificationLines;
    }

    const qosClassificationForm = () => {
        return <React.Fragment>

            <ShowDetailsModal show={detailsModal.show} qosClassificationElement={detailsModal.qosClassificationElement} />

            <div className='section-divider'></div>
            <div className='subtitle'> <Translator path="advanced.qos_classification.label.QOS_CLASSIFICATION_LIST"></Translator></div>
            {qosClassificationElementsList.length === 0 ?
                <div id='qos-cls-no-rules'>
                    <div className='info-card noelements-card'>
                        <Translator path="advanced.qos_classification.info.NO_QOS_CLASSIFICATIONS" />
                    </div>
                </div>
                :
                <>
                    <div className="advanced-qos-classification-list-wrapper">
                        <List
                            lines={getAdvancedQosClassification()}
                            columns={getColumns()}
                        // useTooltip={true}
                        ></List>
                    </div>
                    <Pager
                        pageNumber={qosClassificationPagination.pageNumber}
                        totalElements={qosClassificationPagination.totalElements}
                        pageSize={qosClassificationPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                </>
            }
            <Button
                id='new_qos_classification'
                text={t('advanced.qos_classification.label.NEW_CLASSIFICATION')}
                onClick={createNewQosClassificationForm}
            ></Button>
        </React.Fragment>
    }

    return (
        !qosClassificationElementsList ? <Loading show={true}></Loading> :

            <div id='advanced-qos-classification-page' className='with-tabs'>

                {qosClassificationForm()}

            </div>
    )
}

import React, { useState, useEffect, useContext } from 'react';
import { fetchDevice } from '../../resources/device';
import { fetchChangeToFirmwareVersion, updateChangeToFirmwareVersion } from '../../resources/change-to-firmware-version';
import { BackendContext } from '../../../backend/backend';
import Loading from '../../../components/loading/loading';
import { useTranslation } from 'react-i18next'
import Translator from '../../common/components/translator/translator';
import Input from '../../../components/input/input';
import Button from '../../../components/button/button';
import Modal, { ModalTypes } from '../../../components/modal/modal'
import waitRouter from '../../utils/wait-router'
import { useHistory } from 'react-router-dom'
import { GlobalsContext } from '../../globals-context'

export default function SystemChangeToFirmwareVersion({ isWizard, setWizardLeds, isFormSegment, formSegment }) {

    let [error, setError] = useState(false)
    let [errorMessage, setErrorMessage] = useState(false)
    let [device, setDevice] = useState(null)
    let [change_to_firmware_version, setChangeToFirmwareVersion] = useState(null)
    let [confirm, setConfirm] = useState(null)
    let [restoring, setRestoring] = useState(null)
    let [restored, setRestored] = useState(null)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)
    const globals = useContext(GlobalsContext)
    const history = useHistory()

    useEffect(() => {
        fetchDevice(backend, setDevice);
        fetchChangeToFirmwareVersion(backend, setChangeToFirmwareVersion);
        // eslint-disable-next-line
    }, [])

    let dismiss = () => {
        setError(false)
        setConfirm(false)
        setRestoring(false)
        setRestored(false)
        window.location.reload(false)
    }

    let change_to_backup = () => {
        setConfirm({ ...confirm });
    }

    let reset = async () => {

        if (restoring) return

        setRestoring(true)

        let ok = await updateChangeToFirmwareVersion(backend, change_to_firmware_version, setErrorMessage)
        if (!ok) {
            setRestoring(false)
            setError(true)
            return
        }

        backend.setDriverBaseUrl(process.env.FACTORY_URL)
        ok = await waitRouter(
            backend,
            globals.getItem('username'),
            globals.getItem('password'),
            history
        )

        if (!ok) {
            setRestoring(false)
            setError(true)
            return
        }

        setRestoring(false)
        setRestored(true)

    }

    let continueReset = (confirmed) => {

        setConfirm(false)

        if (confirmed) {
            change_to_firmware_version.change = true;
            setChangeToFirmwareVersion({ ...change_to_firmware_version });
            reset();
        }
    }

    const SystemChangeToFirmwareVersionForm = () => {
        return <React.Fragment>
            <Input id='change-to-firmware-version-current-version'
                name='current-version'
                label={<Translator path="system.change_to_firmware_version.label.CURRENT_VERSION"></Translator>}
                value={device.fw_version}
                readOnly={true}
            ></Input>

            <Input id='change-to-firmware-version-backup-version'
                name='backup-version'
                label={<Translator path="system.change_to_firmware_version.label.BACKUP_VERSION"></Translator>}
                value={change_to_firmware_version.version}
                readOnly={true}
            ></Input>
        </React.Fragment>
    }

    return !change_to_firmware_version || !device ? <Loading show={true}></Loading> :
        <div id='change-to-firmware-version-page' className='container scroll-area'>
            <Modal
                modalID='restore'
                title={<Translator path="menu.FACTORY_RESET"></Translator>}
                show={confirm}
                content={
                    <div style={{ maxWidth: '420px' }}>
                        <div>
                            <Translator path="system.change_to_firmware_version.label.EXPLAINING"></Translator>
                        </div>
                        <div>
                            <Translator path="system.change_to_firmware_version.label.ACTION_CAN_BE_UNDO"></Translator>
                        </div>
                        <br></br>
                        <b>
                            {t('system.change_to_firmware_version.label.CONFIRMATION_TO_RESET', { version: change_to_firmware_version.version })}
                        </b>
                    </div>
                }
                type={ModalTypes.CONFIRM_WARNING}
                onDismissClick={continueReset}
                dismissText={<Translator path="system.change_to_firmware_version.label.DONT_RESTORE"></Translator>}
                confirmText={<Translator path="system.change_to_firmware_version.label.DO_RESTORE"></Translator>}
                error={error}
                errorText={`${t('system.change_to_firmware_version.message.error.ERROR_ON_SERVER')} ${errorMessage ? errorMessage : ''}`}
            ></Modal>

            <Modal
                modalID='changing_to_firmware'
                title={<Translator path="system.change_to_firmware_version.title.CHANGING"></Translator>}
                show={restoring}
                content={<React.Fragment>
                    <Translator path="system.change_to_firmware_version.warning.WAIT_A_FEW_SECONDS"></Translator>
                    <br></br>
                    <Translator path="system.change_to_firmware_version.warning.RECONNECT"></Translator>
                </React.Fragment>}
                type={ModalTypes.LOADING}
                error={error}
                errorText={`${t('system.change_to_firmware_version.message.error.ERROR_ON_SERVER')} ${errorMessage ? errorMessage : ''}`}
            ></Modal>

            <Modal
                modalID='changed_to_firmware'
                show={restored}
                content={<React.Fragment>
                    <div>{<Translator path="system.change_to_firmware_version.warning.CHANGED"></Translator>}</div>
                </React.Fragment>}
                dismissText={<Translator path="system.change_to_firmware_version.label.UNDERSTOOD"></Translator>}
                onDismissClick={dismiss}
                type={ModalTypes.SUCCESS}
                error={error}
                errorText={`${t('system.change_to_firmware_version.message.error.ERROR_ON_SERVER')} ${errorMessage ? errorMessage : ''}`}
            ></Modal>

            <React.Fragment>
                <div className='subtitle'> <Translator path="menu.CHANGE_TO_FIRMWARE_VERSION"></Translator> </div>
                <div className="card mt2">
                    <div className='subtitle'> <Translator path="system.change_to_firmware_version.title.CHANGE_TO_FIRMWARE_VERSION_DESCRIPTION"></Translator> </div>
                    <div id='change-to-firmware-version-backup-version-form'>
                        {SystemChangeToFirmwareVersionForm()}
                    </div>
                    <Button
                        id='change-to-firmware-version-backup-version-btn'
                        text={<Translator path="system.change_to_firmware_version.label.SWITCH_TO_BACKUP"></Translator>}
                        type='submit'
                        onClick={change_to_backup}
                    ></Button>
                </div>
            </React.Fragment>

        </div>
}
import { backendStatus } from  '../../backend/backend'

export async function fetchInterfaceStatisticsList(backend, setInterfaceStatistics) {
    
    try{
        let result = await backend.retrieveFresh('interface_statistics')
        if(result.status === backendStatus.SUCCESS){
            setInterfaceStatistics(result.content)
            return result.content
        }
    }catch(error){
        console.error('Error fetching interface statistics')
    }

    return null
}
import React from "react"
import Select from '../../../../../components/select/select'

import { useTranslation } from "react-i18next"
import Translator from '../../../../common/components/translator/translator';

import {
    createDirectionOptions,
    createRuleActionOptions,
    createProtocolOptions
} from "../ipv6-ip-port-filter-common"

export default function GeneralSettings({ settings, wanResources, handleInterface, handleDirection, handleProtocol, handleRuleAction, idInterface, idDirection, idProtocol, idRuleAction }) {

    const { t } = useTranslation()

    const getInterfaceOptions = () => {
        return settings.direction === 1 ? [{ value: 'any', text: t('common.label.ALL_INTERFACES') }] : [{ value: 'any', text: t('common.label.ALL_INTERFACES') }, ...(wanResources.filter((item) => item.protocol !== 0)).map(it => ({ value: it.id, text: it.id }))]
    }

    return (
        <div className='card mt2'>
            <div className='subtitle'> {<Translator path={'ipv6.ip_port_filtering.title.GENERAL_CONFIG'}></Translator>} </div>
            {/* Selects Interface, Direction */}
            <div className="row">
                {/* Interface */}
                <Select
                    id={idInterface}
                    name='interface_id'
                    label={t('common.label.INTERFACE')}
                    options={getInterfaceOptions()}
                    value={settings.interface_id}
                    onChange={e => handleInterface(e.target.value)}
                ></Select>

                {/* Direction */}
                <Select
                    id={idDirection}
                    name='direction'
                    label={t('common.label.DIRECTION')}
                    options={createDirectionOptions(t)}
                    value={settings.direction}
                    onChange={e => handleDirection(e.target.value) }
                ></Select>
            </div>

            {/* Selects Protocol, Rule Action */}
            <div className="row">
                {/* Protocol */}
                <Select
                    id={idProtocol}
                    name='direction'
                    label={t('common.label.PROTOCOL')}
                    options={createProtocolOptions(t)}
                    value={settings.protocol}
                    onChange={e => handleProtocol(e.target.value) }
                    clearErrors={true}
                ></Select>

                {/* Rule Action */}
                <Select
                    id={idRuleAction}
                    name='rule_action'
                    label={t('common.label.RULE')}
                    options={createRuleActionOptions(t)}
                    value={settings.rule_action}
                    onChange={e => handleRuleAction(e.target.value) }
                ></Select>
            </div>
        </div>
    )
}
import React from 'react'

export default function LogoutIcon({size}){

    return (<svg aria-hidden="true" 
        focusable="false" 
        data-prefix="fas" 
        data-icon="logout" 
        role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" 
        width={size}
        height={size}>

        <path 
            fill="currentColor" 
            d="M95.39,43.18l-.25-.2L83.3,31.14a1.78,1.78,0,0,0-1.24-.52,1.66,1.66,0,0,0-1.22.51,1.73,1.73,0,0,0-.5,1.24,1.67,1.67,0,0,0,.49,1.2l9,9H61.21a1.76,1.76,0,1,0,0,3.52H89.85l-8.3,8.31A1.74,1.74,0,0,0,84,56.87L95.24,45.64c0-.06.08-.13.12-.2a1.55,1.55,0,0,0,.36-.64c0-.06,0-.13,0-.22a1.71,1.71,0,0,0-.37-1.4"/><path d="M66.2,77.11H39V22.85a4.07,4.07,0,0,0-1.67-3.17c-4.43-3.22-18.9-11.48-19-11.56L15,6.21H66.2V31.84h3.87V6.46a4,4,0,0,0-4-4H8.18a4,4,0,0,0-4,4V76.85c0,1.83,1.22,3.2,4,4.46L35.76,97.18a2.59,2.59,0,0,0,2.46,0,1.78,1.78,0,0,0,.8-1.65V80.8H66.11a4,4,0,0,0,4-4v-20H66.2Z"
            >
        </path>
    </svg>)
}
import React from 'react'

export default function WarnIcon({size, color}){

    return (
        <svg width={size} height={size-3} viewBox="0 0 20 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Rounded" transform="translate(-102.000000, -732.000000)">
                    <g id="Alert" transform="translate(100.000000, 674.000000)">
                        <g id="-Round-/-Alert-/-warning" transform="translate(0.000000, 54.000000)">
                            <g>
                                <rect id="Rectangle-Copy-30" x="0" y="0" width="24" height="24"></rect>
                                <path d="M4.47,21 L19.53,21 C21.07,21 22.03,19.33 21.26,18 
                                    L13.73,4.99 C12.96,3.66 11.04,3.66 10.27,4.99 L2.74,18 C1.97,19.33 2.93,21 4.47,21 Z 
                                    M12,14 C11.45,14 11,13.55 11,13 L11,11 C11,10.45 11.45,10 12,10 C12.55,10 13,10.45 13,11 
                                    L13,13 C13,13.55 12.55,14 12,14 Z M13,18 L11,18 L11,16 L13,16 L13,18 Z" fill={color}></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
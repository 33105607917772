import React from 'react'
import Translator from '../../common/components/translator/translator'
import { useTranslation } from 'react-i18next'

// Resources
import SystemTermsOfUseText from './system-terms-of-use-text';

export default function SystemInformation() {

    const { i18n } = useTranslation()

    return <div id='information-page' className='container'>
        <div className='subtitle'><Translator path="menu.INFORMATION"></Translator></div>
        <div className="card mt2">
            {SystemTermsOfUseText(i18n)}
        </div >
    </div >
}

import { backendStatus } from '../../backend/backend'

const URL = 'bridging';

export async function fetchAdvancedBridging(backend, id, setBridging) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){

        setBridging(result.content)
    }
}

export async function fetchAdvancedBridgingList(backend, setBridgingList) {
    let result = await backend.retrieveFresh(`${URL}`)

    if(result.status === backendStatus.SUCCESS){

        let bridgingList = result.content

        let bridgings = bridgingList[0]

        setBridgingList(bridgings)
    }
}

export async function deleteAdvancedBridgingById(backend, id, setBridgingList) {
    let result = await backend.delete(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchAdvancedBridgingList(backend, setBridgingList)
    }
}

export async function saveAdvancedBridging(backend, bridging, setErrorMessage) {

    try{

        let result = null;

        if(!bridging.id) {
            result = await backend.create(`${URL}`, bridging)
        }
        else {
            result = await backend.update(`${URL}/${bridging.id}`, bridging)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || bridging)

    }catch(error){

        console.error('Error saving BRIDGING:', error)

        return false
    }

}
import React from 'react'

export default function WifiIcon({size, color, usePng}){

    if(usePng) {
        let icon = require('./images/ic-networks.png')
        return <img alt='' src={icon} width={size} height={size}></img>
    }

    return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g fillRule="nonzero" stroke={color ? color : "#191C23"}>
                <g>
                    <g>
                        <path d="M15.56 18.008c-.436-.452-1.041-.71-1.675-.713-.685 0-1.334.296-1.776.808M8.381 14.143c1.409-1.642 3.387-2.59 5.469-2.618 2.082-.03 4.082.862 5.531 2.464M4.19 9.952c2.495-2.641 5.986-4.157 9.653-4.19 3.667-.033 7.185 1.42 9.728 4.016M0 5.762C3.586 2.12 8.588.038 13.836 0c5.249-.038 10.283 1.973 13.926 5.562" transform="translate(-391 -458) translate(391 458) translate(2 6.5)"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    )
}
import { backendStatus } from '../../backend/backend'
import { type } from './filter'

/** Return the result of a limits fetch. Must receive the backend */
export async function fetchLimits(backend) {
    let result = await backend.retrieve('limits')

    if (result.status === backendStatus.SUCCESS && result.content.length > 0) {
        // Verify if response is from the ping requested
        return result.content[0]
    }

    return null
}

/** Set the result of port limits. Must receive the backend and setter */
export async function fetchPortRedirectLimit(backend, setRulesLimit) {
    const result = await fetchLimits(backend)

    if (result) {
        setRulesLimit(result.port_redirect)
    }
}

/** Set the result of leases limit. Must receive the backend and setter */
export async function fetchDhcpLeaseLimit(backend, setLeasesLimit = null) {
    const result = await fetchLimits(backend)

    if (result) {
        if (setLeasesLimit)
            setLeasesLimit(result.static_leases)
        else
            return result.static_leases
    }

    return null
}

export async function fetchFilterLimits(backend, setFiltersLimit, filterType) {
    const result = await fetchLimits(backend)

    if (result) {
        var limit = 0
        switch (filterType) {
            case type.MAC:
                limit = result.filter_mac ?? 5
                break;
            case type.URL:
                limit = result.filter_url ?? 5
                break;
            case type.PORT:
                limit = result.filter_port ?? 5
                break;
            case type.IPV4:
            case type.IPV6:
                limit = result.filter_ip ?? 5
                break;
            default:
                limit = 5
                break;
        }
        setFiltersLimit(limit)
    }
}

/** Set the result of wifi limits. Must receive the backend and setter */
export async function fetchWifiLimits(backend, setWifiLimits) {
    const wifiLimits = [{
        "filter_ip": 10,
        "filter_mac": 9,
        "filter_port": 8,
        "filter_url": 7,
        "port_redirect": 3,
        "qos_rules": 10,
        "static_leases": 3,
        "wifi_limits": [
            {
                "radio_id": "radio0",
                "value": 5
            },
            {
                "radio_id": "radio1",
                "value": 5
            }
        ],
        "wifi_vaps_limits": [
            {
                "radio_id": "radio0",
                "value": 4
            },
            {
                "radio_id": "radio1",
                "value": 4
            }
        ]
    }]

    setWifiLimits(wifiLimits)
}
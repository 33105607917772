import React, { useState, useEffect, useContext } from 'react'

import './voip-history-call.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipHistoryCallList } from '../../../resources/voip-history-call';
import Loading from '../../../../components/loading/loading';
import List from '../../../../components/list/list';
import Select from '../../../../components/select/select';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Pager from '../../../../components/pager/pager';
import Button from '../../../../components/button/button';

export default function VoipHistoryCall({isWizard, setSaved, isFormSegment, formSegment}) {
    const [voipHistoryCall, setVoipHistoryCall] = useState(null)
    const [voipHistoryCallFilters, setVoipHistoryCallFilters] = useState(null)

    const [voipHistoryCallPagination, setVoipHistoryCallPagination] = useState(null)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {
        setVoipHistoryCallPagination({pageNumber: 1, totalElements: 0, pageSize: 10})
        fetchVoipHistoryCallList(backend, setVoipHistoryCall)
        setVoipHistoryCallFilters({voip_port: 'fxs1'})

        // eslint-disable-next-line
    }, [])
    
    const getColumns = () => {

        return [
            {header: t('common.label.NUMBER'), align: 'center', size: '30px'},
            {header: t('voip.history_call.label.PORT'), align: 'center' , size: '30px'},
            {header: t('common.label.STATUS'), align: 'center'},
            {header: t('voip.history_call.label.SOURCE'), align: 'center'},
            {header: t('voip.history_call.label.DESTINY'), align: 'center'},
            {header: t('voip.history_call.label.DIRECTION'), align: 'center'},
            {header: t('voip.history_call.label.DURATION'), align: 'center'},
            {header: t('voip.history_call.label.DATE_HOUR'), align: 'center'}
        ]
    }

    const changePage = page => {
        setVoipHistoryCallPagination({...voipHistoryCallPagination, pageNumber: page})
    }

    const toTranslate = (t, text) => {
        switch(text) {
            case 'Incoming call': return t('voip.history_call.label.direction.INCOMING_CALL')
            case 'Outcoming call': return t('voip.history_call.label.direction.OUTGOING_CALL')
            case 'completed': return t('voip.history_call.label.status.COMPLETED')
            case 'aborted': return t('voip.history_call.label.status.ABORTED')
            default: return text
        }
    }

    const getWeekDay = (t, day) => {
        switch(day) {
            case 'Sun': return t('common.label.SUNDAY')
            case 'Mon': return t('common.label.MONDAY')
            case 'Tue': return t('common.label.TUESDAY')
            case 'Wed': return t('common.label.WEDNESDAY')
            case 'Thu': return t('common.label.THURSDAY')
            case 'Fri': return t('common.label.FRIDAY')
            case 'Sat': return t('common.label.SATURDAY')
            default: return day
        }
    }

    const getMonth = (t, month) => {
        switch(month) {
            case 'Jan': return 1
            case 'Feb': return 2
            case 'Mar': return 3
            case 'Apr': return 4
            case 'May': return 5
            case 'Jun': return 6
            case 'Jul': return 7
            case 'Aug': return 8
            case 'Sep': return 9
            case 'Oct': return 10
            case 'Nov': return 11
            case 'Dec': return 12
            default: return month
        }
    }

    const translateTime = (t, text) => {
        const splitted = text.split(' ')
        
        let weekDay = splitted[0]
        let month = splitted[1]
        let day = splitted[2]
        let hour = splitted[3]
        let year = splitted[4]

        return `${year}-${getMonth(t, month)}-${day} ${hour} ${getWeekDay(t, weekDay)}`
    }
    
    const getVoipHistoryCall = (t) => {

        let voipHistoryCallFiltered = voipHistoryCall.filter(it => it.voip_port === voipHistoryCallFilters.voip_port)

        if(voipHistoryCallFiltered.length !== voipHistoryCallPagination.totalElements) {
            voipHistoryCallPagination.totalElements = (voipHistoryCallFiltered.length === 0) ? 1 : voipHistoryCallFiltered.length;
            setVoipHistoryCallPagination({...voipHistoryCallPagination})
        } 

        let voipHistoryCallLines = [];

        let begining = (voipHistoryCallPagination.pageNumber - 1) * (voipHistoryCallPagination.pageSize)
        let end = begining + voipHistoryCallPagination.pageSize
        if (end > voipHistoryCallPagination.totalElements) {
            end = voipHistoryCallPagination.totalElements
        }

        for(let i = begining, nr = (1 + (voipHistoryCallPagination.pageNumber - 1) * voipHistoryCallPagination.pageSize); i < end; i++) {
            voipHistoryCallLines.push([
                nr++,
                voipHistoryCallFiltered[i].voip_port,
                toTranslate(t, voipHistoryCallFiltered[i].status),
                voipHistoryCallFiltered[i].caller,
                voipHistoryCallFiltered[i].callee,
                toTranslate(t, voipHistoryCallFiltered[i].direction),
                voipHistoryCallFiltered[i].duration,
                translateTime(t, voipHistoryCallFiltered[i].start_time),
            ]);
        }

        return voipHistoryCallLines;
    }

    const voipHistoryCallForm = () => {
        let options = [...new Set(voipHistoryCall.map(hc => hc.voip_port))].map(vp => ({value: vp, text: vp.toUpperCase()}))
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="voip.history_call.title.HISTORY_CALL"></Translator></div>
            {options.length > 1 &&
                <div className='voip-history-call-select-wrapper'>
                    <Select
                        id='voip-history-call-voip-port-filter'
                        name='voip_port'
                        label='Porta VoIP'
                        options={options}
                        value={voipHistoryCallFilters.voip_port}
                        onChange={(e) => {
                            voipHistoryCallFilters.voip_port = e.target.value
                            setVoipHistoryCallFilters({...voipHistoryCallFilters})
                            voipHistoryCallPagination.pageNumber = 1
                            setVoipHistoryCallPagination({...voipHistoryCallPagination})
                        }}
                    ></Select>
                </div>
            }
            {getVoipHistoryCall(t).length > 0 ?
                <React.Fragment>
                    
                    <div className="voip-history-call-list-wrapper">
                        <List 
                            lines={getVoipHistoryCall(t)}
                            columns={getColumns()}
                        ></List>
                    </div>
                    <Pager
                        pageNumber={voipHistoryCallPagination.pageNumber}
                        totalElements={(voipHistoryCallPagination.totalElements === 0) ? 1 : voipHistoryCallPagination.totalElements}
                        pageSize={voipHistoryCallPagination.pageSize}
                        pageChangeFn={changePage}>
                    </Pager>
                    
                </React.Fragment> :
                
                <div className='info-card noelements-card'>
                    <Translator path="voip.history_call.messages.NO_HISTORY_CALL"></Translator>
                </div>
            }
            <Button
                id='button-refresh'
                type='button'
                text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                onClick={async () => {
                    await fetchVoipHistoryCallList(backend, setVoipHistoryCall)}
                }
            ></Button>
            
        </React.Fragment>
    }

    return (
        !voipHistoryCall ? <Loading show={true}></Loading> :

        <div id='voip-history-call-page' className='with-tabs'>

            { voipHistoryCallForm() }

        </div>
    )
}

import { backendStatus } from '../../backend/backend'

const URL_IPV6_DHCP = 'ipv6_dhcp';
const URL_IPV6_DHCP_RELAY = 'ipv6_dhcp_relay';
const URL_IPV6_DHCP_SERVER = 'ipv6_dhcp_server';

/* constants */
export const dhcpConstants = {
    IPV6_DHCP_DOMAINS_MAX_LEN: 5,
    IPV6_DHCP_DNS_SERVER_MAX_LEN: 5
}

export const dhcpMode = {
    DHCPV6_MODE_RELAY: 0,
    DHCPV6_MODE_SERVER: 1,
    DHCPV6_MODE_NONE: 2
}

export const dhcpServerMode = {
    DHCPV6_SERVER_STATIC: 0,
    DHCPV6_SERVER_AUTO: 1
}

/* ipv6 dhcp relay */
export async function fetchIpv6DhcpRelay(backend, setDhcpRelay) {
    let result = await backend.retrieveFresh(`${URL_IPV6_DHCP_RELAY}/0`)

    if(result.status === backendStatus.SUCCESS){
        setDhcpRelay(result.content)
    }
}


export async function saveIpv6DhcpRelay(backend, dhcp_relay, setErrorMessage) {

    try{

        let result = null;

        result = await backend.update(`${URL_IPV6_DHCP_RELAY}/0`, dhcp_relay)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || dhcp_relay)

    }catch(error){
        console.error('Error saving DHCP:', error)
        return false
    }
}

/* ipv6 dhcp */
export async function fetchIpv6Dhcp(backend, setIpv6Dhcp, setDhcpEnabled, setCurrentDhcpMode) {
    let result = await backend.retrieveFresh(`${URL_IPV6_DHCP}/0`)

    if(result.status === backendStatus.SUCCESS){
        setIpv6Dhcp != null && setIpv6Dhcp(result.content)

        if(result.content.enabled) 
            setDhcpEnabled != null && setDhcpEnabled(true)
        else 
            setDhcpEnabled != null && setDhcpEnabled(false)

        if (setCurrentDhcpMode)
            setCurrentDhcpMode((result.content.enabled) ? result.content.mode : dhcpMode.DHCPV6_MODE_NONE)
    }
}


export async function saveIpv6Dhcp(backend, dhcp, setErrorMessage) {

    try{

        let result = null;

        result = await backend.update(`${URL_IPV6_DHCP}/0`, dhcp)

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || dhcp)

    }catch(error){
        console.error('Error saving DHCP:', error)
        return false
    }
}


/* ipv6 dhcp server */
export async function fetchIpv6DhcpServer(backend, id, setDhcp, setDhcpModeServerAuto) {
    
    let result = await backend.retrieveFresh(`${URL_IPV6_DHCP_SERVER}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setDhcp(result.content)

        if(result.content.mode === dhcpServerMode.DHCPV6_SERVER_STATIC)
            setDhcpModeServerAuto(false)
        else
            setDhcpModeServerAuto(true)
    }
    
}

export async function fetchIpv6DhcpServerList(backend, setDhcpList) {
    let result = await backend.retrieveFresh(`${URL_IPV6_DHCP_SERVER}`)

    if(result.status === backendStatus.SUCCESS){

        setDhcpList(result.content)
    }
}

export async function deleteIpv6DhcpServerById(backend, id, setDhcpList) {
    let result = await backend.delete(`${URL_IPV6_DHCP_SERVER}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchIpv6DhcpServerList(backend, setDhcpList)
    }
}

export async function saveIpv6DhcpServer(backend, dhcp, setErrorMessage) {

    try{

        let result = null;

        if(!dhcp.id) {
            result = await backend.create(`${URL_IPV6_DHCP_SERVER}`, dhcp)
        }
        else {
            result = await backend.update(`${URL_IPV6_DHCP_SERVER}/${dhcp.id}`, dhcp)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || dhcp)

    }catch(error){

        console.error('Error saving DHCP:', error)

        return false
    }

}
import React, { useState, useEffect, useContext } from 'react'

import './service-proxy-igmp.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchServiceProxyIGMP, saveServiceProxyIGMP } from '../../../resources/service-proxy-igmp';
import Loading from '../../../../components/loading/loading';
import Form from '../../../../components/form/form';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import FormSegment from '../../../../components/form/form-segment';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import Checkbox from '../../../../components/checkbox/checkbox';
import Input from '../../../../components/input/input';
import extraValidators from '../../../common/validators';

export default function ServiceProxyIGMP({isWizard, setSaved, isFormSegment, formSegment}) {
    const [serviceProxyIGMP, setServiceProxyIGMP] = useState(null)

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const { t } = useTranslation()

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchServiceProxyIGMP(backend, setServiceProxyIGMP)

        // eslint-disable-next-line
    }, [])


    const serviceProxyIGMPForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <Checkbox id='service-proxy-igmp-allow-multicast'
                name='allow_multicast'
                label={<Translator path="service.proxy_igmp.label.ALLOW_MULTICAST"></Translator>}
                value={serviceProxyIGMP.allow_multicast}
                toggleFn={(e) => {
                    serviceProxyIGMP.allow_multicast = !serviceProxyIGMP.allow_multicast;
                    setServiceProxyIGMP({...serviceProxyIGMP})
                }}
                validators={[]}
            ></Checkbox>

            <Input id='service-proxy-igmp-group-leave-delay'
                name='group_leave_delay'
                label={<Translator path="service.proxy_igmp.label.GROUP_LEAVE_DELAY"></Translator>}
                value={serviceProxyIGMP.group_leave_delay}
                onChange={(e) => {
                    serviceProxyIGMP.group_leave_delay = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                    setServiceProxyIGMP({...serviceProxyIGMP})
                }}
                validators={[
                    extraValidators.required, 
                    extraValidators.nonASCII, 
                    extraValidators.isNumber, 
                    {fn: extraValidators.value, params: { min: 0, max: 2147483647 }}
                ]}
            ></Input>

            <Input id='service-proxy-igmp-last-member-query-count'
                name='last_member_query_count'
                label={<Translator path="service.proxy_igmp.label.LAST_MEMBER_QUERY_COUNT"></Translator>}
                value={serviceProxyIGMP.last_member_query_count}
                onChange={(e) => {
                    serviceProxyIGMP.last_member_query_count = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                    setServiceProxyIGMP({...serviceProxyIGMP})
                }}
                validators={[
                    extraValidators.required, 
                    extraValidators.nonASCII, 
                    extraValidators.isNumber,
                    {fn: extraValidators.value, params: { min: 0, max: 2147483647 }}
                ]}
            ></Input>

            <Input id='service-proxy-igmp-query-interval'
                name='query_interval'
                label={<Translator path="service.proxy_igmp.label.QUERY_INTERVAL"></Translator>}
                value={serviceProxyIGMP.query_interval}
                onChange={(e) => {
                    serviceProxyIGMP.query_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                    setServiceProxyIGMP({...serviceProxyIGMP})
                }}
                validators={[
                    extraValidators.required, 
                    extraValidators.nonASCII, 
                    extraValidators.isNumber,
                    {fn: extraValidators.value, params: { min: 0, max: 2147483647 }}
                ]}
            ></Input>

            <Input id='service-proxy-igmp-query-response-interval'
                name='query_response_interval'
                label={<Translator path="service.proxy_igmp.label.QUERY_RESPONSE_INTERVAL"></Translator>}
                value={serviceProxyIGMP.query_response_interval}
                onChange={(e) => {
                    serviceProxyIGMP.query_response_interval = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                    setServiceProxyIGMP({...serviceProxyIGMP})
                }}
                validators={[extraValidators.required, extraValidators.nonASCII, extraValidators.isNumber, {fn:extraValidators.value, params: {
                    min: 0,
                    max: 127
                }}]}
            ></Input>

            <Input id='service-proxy-igmp-robust-count'
                name='robust_count'
                label={<Translator path="service.proxy_igmp.label.ROBUST_COUNT"></Translator>}
                value={serviceProxyIGMP.robust_count}
                onChange={(e) => {
                    serviceProxyIGMP.robust_count = isNaN(Number(e.target.value)) || !e.target.value ? e.target.value : Number(e.target.value)
                    setServiceProxyIGMP({...serviceProxyIGMP})
                }}
                validators={[
                    extraValidators.required, 
                    extraValidators.nonASCII, 
                    extraValidators.isNumber,
                    {fn: extraValidators.value, params: { min: 0, max: 2147483647 }}
                ]}
            ></Input>
        </React.Fragment>
    }

    const submit = async() => {

        if(saving || error || success) return

        setSaving(true)

        let ok = await saveServiceProxyIGMP(backend, serviceProxyIGMP)
        if(!ok){
            setSaving(false)
            setError(true)
            return
        }

        setSaving(false)
        setSuccess(true)

    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !serviceProxyIGMP ? <Loading show={true}></Loading> :

        <div id='service-proxy-igmp-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'>{t('service.proxy_igmp.title.PROXY_IGMP')}</div>
            <div className='card mt2'>
                <div className='subtitle'>{t('service.proxy_igmp.title.SETTINGS')}</div> 

                <DefaultStatusModals
                    saving={saving}
                    error={error}
                    success={success}
                    continueFn={dismissModal}
                ></DefaultStatusModals>

                {!isFormSegment ? <Form
                    onSubmit={submit}
                    buttonId='button-save'
                >
                    {serviceProxyIGMPForm()}
                </Form> : 

                <FormSegment
                    title={formSegment.title} 
                    active={formSegment.active} 
                    nextLabel={formSegment.nextLabel} 
                    nextClicked={submit} 
                >
                    {serviceProxyIGMPForm()}
                </FormSegment>}
            </div>
        </div>
    )
}

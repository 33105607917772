import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { deleteDhcpLease, fetchDhcpLeaseList } from '../../../resources/dhcp-lease'
import { BackendContext } from '../../../../backend/backend'
import { fetchConnectedDevicesList } from '../../../resources/connected-devices'
import { MainHeaderContext } from '../../../../components/main-header/main-header-state'
import { useTranslation } from 'react-i18next'
import Translator from '../../../common/components/translator/translator'
import Button from '../../../../components/button/button'
import List from '../../../../components/list/list'
import Pager from '../../../../components/pager/pager'
import EditIcon from '../../../../components/icons/edit'
import RemoveIcon from '../../../../components/icons/remove'
import DefaultStatusModals from '../../../../components/modal/default-status-modals'

export default function DhcpLeaseList({ leases, setLeases }) {

    const [leasesLimit] = useState(8)
    const [devices, setDevices] = useState(null)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)

    const history = useHistory()
    const backend = useContext(BackendContext)
    const header = useContext(MainHeaderContext)

    const { t } = useTranslation()

    const [dhcpLeasePagination, setdhcpLeasePagination] = useState(
        {
            pageNumber: 1,
            totalElements: 0,
            pageSize: 10
        }
    )

    const changePage = page => {
        setdhcpLeasePagination({ ...dhcpLeasePagination, pageNumber: page })
    }

    const getDhcpLeases = () => {

        let dhcpLeasesFiltered = leases

        if (dhcpLeasesFiltered.length !== dhcpLeasePagination.totalElements) {
            dhcpLeasePagination.totalElements = dhcpLeasesFiltered.length
            setdhcpLeasePagination({ ...dhcpLeasePagination })
        }

        let dhcpLeasesLines = [];

        if (dhcpLeasesFiltered.length > 0) {
            let begining = (dhcpLeasePagination.pageNumber - 1) * (dhcpLeasePagination.pageSize)
            let end = begining + dhcpLeasePagination.pageSize
            if (end > dhcpLeasePagination.totalElements) {
                end = dhcpLeasePagination.totalElements
            }

            for (let i = begining; i < end; i++) {
                dhcpLeasesLines.push([
                    dhcpLeasesFiltered[i].mac,
                    dhcpLeasesFiltered[i].ip,
                    getLeaseStatus(dhcpLeasesFiltered[i]),
                    <div >
                        <span className="clickable" onClick={e => {
                            history.push(`/network/lan/dhcp-lease/${dhcpLeasesFiltered[i].id}`)
                        }}><EditIcon /></span>

                        <span style={{ paddingInline: 4 }} />

                        <span className="clickable" onClick={async (e) => {
                            setSaving(true)
                            await deleteDhcpLease(backend, dhcpLeasesFiltered[i].id) || setError(true)
                            await fetchDhcpLeaseList(backend, setLeases)
                            setSaving(false)
                        }
                        }><RemoveIcon /></span>
                    </div>
                ]);
            }

        }

        return dhcpLeasesLines;
    }

    const getColumns = () => {

        return [
            { header: t('network.lan.label.MAC_ADDRESS'), align: 'center' },
            { header: t('network.lan.label.IP_ADDRESS'), align: 'center' },
            { header: t('network.lan.label.STATUS'), align: 'center' },
            { header: t('common.label.ACTIONS'), align: 'center' },
        ]
    }

    useEffect(() => {
        header.backRoute.set('/network/lan/')

        fetchConnectedDevicesList(backend, setDevices)
        // eslint-disable-next-line
    }, [])

    const getLeaseStatus = (lease) => {
        let notFound = true

        if (devices) {
            notFound = devices.every(device => {
                if (device.mac === lease.mac.toLocaleUpperCase() && device.isStaticIP === false) {
                    return false
                } else {
                    return true
                }
            })
        }

        return notFound ? t('network.lan.label.DISCONNECTED') : t('network.lan.label.CONNECTED')
    }

    const dismiss = () => {
        setSaving(false)
        setError(false)
    }

    return <div className='lease-list-container container'>

        <DefaultStatusModals
            saving={saving}
            error={error}
            errorText={t('network.lan.error.TRY_DELETE_ERROR')}
            continueFn={dismiss}
            successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
        ></DefaultStatusModals>

        <div className='subtitle'><Translator path="network.lan.title.IP_LEASES"></Translator></div>

        <Button id='back-to-lan-button'
            text={<Translator path="common.label.BACK"></Translator>}
            onClick={() => history.push('/network/lan')}
        ></Button>

        {(leases.length === 0) ?
            <div>
                <div id='dhcp_client-no-rules'>
                    <div className='info-card noelements-card'>
                        <Translator path="network.lan.info.NO_IP_LEASE" />
                    </div>
                </div>
            </div>
            :
            <>
                <div className="dhcp-lease-list-wrapper">
                    <List
                        lines={getDhcpLeases()}
                        columns={getColumns()}
                    ></List>
                </div>
                <Pager
                    pageNumber={dhcpLeasePagination.pageNumber}
                    totalElements={dhcpLeasePagination.totalElements}
                    pageSize={dhcpLeasePagination.pageSize}
                    pageChangeFn={changePage}>
                </Pager>
            </>
        }



        <div className='total-of-regs'><b>Total de registros:</b> {leases.length}/{leasesLimit}</div>

        <Button
            text={<Translator path="common.label.ADD"></Translator>}
            onClick={() => history.push('/network/lan/dhcp-lease/add')}
            disabled={leases.length >= leasesLimit}
        ></Button>
    </div>
}

import { backendStatus } from  '../../backend/backend'

export async function fetchWanList(backend, setWanList) {
    
    try{
        let result = await backend.retrieveFresh('wan')
        if(result.status === backendStatus.SUCCESS){
            setWanList(result.content)
            return result.content
        }
    }catch(error){
        console.error('Error fetching wan list')
    }

    return null
}


export async function fetchWan(backend, setWan) {
    
    try{
        let result = await backend.retrieveFresh('wan')
        if(result.status === backendStatus.SUCCESS){
            setWan(result.content[0])
            return result.content[0]
        }
    }catch(error){
        console.error('Error fetching wan')
    }

    return null
}
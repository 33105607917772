import React, { useState, useEffect, useContext } from 'react'

import './pon-counters.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchPONCounters } from '../../../resources/pon-counters';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard'
import Translator from '../../../common/components/translator/translator';
import Button from '../../../../components/button/button';

export default function PONCounters({isWizard, setSaved, isFormSegment, formSegment}) {
    const [ponCounters, setPONCounters] = useState(null)

    const backend = useContext(BackendContext)

    useEffect(() => {

        fetchPONCounters(backend, setPONCounters)

        // eslint-disable-next-line
    }, [])

    const ponCountersForm = () => {
        return <React.Fragment>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="pon.counters.title.COUNTERS"></Translator></div>
            <InfoCard
                lines={[
                    {label: <Translator path="pon.counters.label.BYTES_SENT"></Translator>, value: ponCounters.tx_bytes, id: 'card-pon-counters-tx-bytes'},
                    {label: <Translator path="pon.counters.label.BYTES_RECEIVED"></Translator>, value: ponCounters.rx_bytes, id: 'card-pon-counters-rx-bytes'},
                    {label: <Translator path="pon.counters.label.PACKAGES_SENT"></Translator>, value: ponCounters.tx_packets, id: 'card-pon-counters-tx-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_RECEIVED"></Translator>, value: ponCounters.rx_packets, id: 'card-pon-counters-rx-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_UNICAST_SENT"></Translator>, value: ponCounters.tx_unicast_packets, id: 'card-pon-counters-tx-unicast-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_UNICAST_RECEIVED"></Translator>, value: ponCounters.rx_unicast_packets, id: 'card-pon-counters-rx-unicast-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_MULTICAST_SENT"></Translator>, value: ponCounters.tx_multicast_packets, id: 'card-pon-counters-tx-multicast-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_MULTICAST_RECEIVED"></Translator>, value: ponCounters.rx_multicast_packets, id: 'card-pon-counters-rx-multicast-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_BROADCAST_SENT"></Translator>, value: ponCounters.tx_broadcast_packets, id: 'card-pon-counters-tx-broadcast-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_BROADCAST_RECEIVED"></Translator>, value: ponCounters.rx_broadcast_packets, id: 'card-pon-counters-rx-broadcast-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_PAUSE_SENT"></Translator>, value: ponCounters.tx_pause_packets, id: 'card-pon-counters-tx-pause-packets'},
                    {label: <Translator path="pon.counters.label.PACKAGES_PAUSE_RECEIVED"></Translator>, value: ponCounters.rx_pause_packets, id: 'card-pon-counters-rx-pause-packets'},
                    {label: <Translator path="pon.counters.label.FEC_ERRORS"></Translator>, value: ponCounters.fec_errors, id: 'card-pon-counters-fec-errors'},
                    {label: <Translator path="pon.counters.label.HEC_ERRORS"></Translator>, value: ponCounters.hec_errors, id: 'card-pon-counters-hec-errors'},
                    {label: <Translator path="pon.counters.label.PACKAGES_DROPPED"></Translator>, value: ponCounters.dropped_packets, id: 'card-pon-counters-dropped-packets'},
                ]}
            ></InfoCard>
            <Button
                id='button-refresh'
                type='button'
                text={<Translator path="common.label.TABLE_REFRESH"></Translator>}
                onClick={async () => {
                    await fetchPONCounters(backend, setPONCounters)}
                }
            ></Button>
            
        </React.Fragment>
    }

    return (
        !ponCounters ? <Loading show={true}></Loading> :

        <div id='pon-counters-page' className='with-tabs'>

            { ponCountersForm() }

        </div>
    )
}

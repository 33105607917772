import React from 'react'

export default function MeshIcon({size, color, usePng}){

    if(usePng) {
        let icon = require('./images/ic-inmesh.png')
        return <img alt='' src={icon} width={size} height={size}></img>
    }

    return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={size} height={size} viewBox="0 0 259.000000 259.000000"
        preserveAspectRatio="xMidYMid meet">
        <g fillRule="nonzero" stroke={color ? color : "#191C23"}>
            <g transform="translate(0.000000,259.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path d="M1705 2311 c-73 -19 -132 -68 -160 -133 l-15 -38 -258 0 -257 0 1 -52 2 -53 255 -3 255 -2 22 -45 c40 -83 111 -125 212 -125 l63 0 104 -183 c58 -100 115 -199 128 -220 l24 -38 27 19 c15 10 35 22 45 25 10 4 16 10 14 14 -2 5 -60 104 -127 222 -123 213 -123 213 -106 240 86 131 53 285 -74 351 -43 22 -114 32 -155 21z m147 -119 c37 -35 42 -45 45 -93 5 -63 -17 -107 -69 -139 -92 -56 -218 15 -218 123 0 88 59 147 148 147 47 0 57 -4 94 -38z"/>
                <path d="M775 2235 c-175 -62 -124 -316 61 -302 89 6 144 67 144 160 0 102 -107 177 -205 142z"/>
                <path d="M545 1709 l-138 -239 -46 0 c-120 -1 -200 -90 -189 -211 10 -97 90 -169 190 -169 l46 0 127 -220 127 -220 37 21 c20 12 40 25 44 29 5 4 -48 107 -118 228 l-127 220 23 31 c35 48 38 133 5 193 l-25 47 136 236 c74 130 134 237 132 238 -2 2 -22 15 -45 29 l-41 26 -138 -239z m-125 -324 c79 -41 79 -169 0 -210 -88 -46 -180 13 -180 116 0 33 6 47 34 75 28 28 42 34 75 34 23 0 55 -7 71 -15z"/>
                <path d="M931 1924 c-19 -13 -40 -25 -45 -27 -8 -3 151 -299 179 -335 2 -2 23 10 48 26 l45 28 -90 155 c-49 85 -93 159 -96 165 -5 8 -18 4 -41 -12z"/>
                <path d="M1235 1541 c-58 -15 -106 -44 -144 -87 -113 -128 -72 -332 81 -408 103 -51 245 -25 314 57 117 138 73 348 -87 416 -56 25 -121 33 -164 22z"/>
                <path d="M2155 1426 c-66 -29 -105 -106 -91 -181 9 -46 62 -100 114 -115 144 -44 262 136 165 251 -47 55 -123 73 -188 45z"/>
                <path d="M1640 1280 l0 -50 195 0 195 0 0 50 0 50 -195 0 -195 0 0 -50z"/>
                <path d="M1939 902 c-136 -235 -137 -237 -168 -234 -69 7 -156 -41 -185 -102 l-17 -36 -254 0 -255 0 0 -39 c0 -22 -3 -46 -6 -55 -6 -14 21 -16 254 -16 l261 0 23 -41 c50 -89 168 -120 259 -67 87 51 120 168 71 252 -12 20 -22 42 -22 47 0 8 175 319 251 446 l19 31 -46 26 c-26 14 -47 26 -48 26 0 0 -62 -107 -137 -238z m-112 -327 c60 -40 66 -136 12 -186 -101 -94 -255 19 -192 141 33 65 117 86 180 45z"/>
                <path d="M981 854 c-46 -80 -82 -147 -79 -149 6 -7 79 -55 82 -55 2 0 36 57 76 128 41 70 80 137 87 148 13 20 11 23 -33 48 -26 14 -47 26 -48 26 0 0 -39 -66 -85 -146z"/>
                <path d="M778 660 c-102 -31 -164 -134 -138 -229 13 -48 59 -105 104 -128 39 -20 123 -20 161 0 68 35 108 99 108 172 0 60 -19 101 -67 143 -51 45 -110 59 -168 42z"/>
            </g>
        </g>
    </svg>
}
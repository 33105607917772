import { backendStatus } from '../../backend/backend'

export const firewallPortForwardsConstants = {
    MAX_KEYWORDS_ELEMENTS: 16,
    MAX_URLS_ELEMENTS: 16
}


export async function fetchFirewallPortForwardEnable(backend, setFirewallPortForwards) {
    let result = await backend.retrieveFresh('firewall_port_forwarding_enable')

    if(result.status === backendStatus.SUCCESS){

        let firewallPortForwardsList = result.content

        let firewallPortForwards = firewallPortForwardsList[0]

        setFirewallPortForwards(firewallPortForwards)
    }
}

export async function saveFirewallPortForwardEnable(backend, firewallPortForwards) {

    try{

        let result = null;

        result = await backend.update(`firewall_port_forwarding_enable/${firewallPortForwards.id}`, firewallPortForwards)

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Firewall Port Forward Eanble:', error)

        return false
    }

}

export async function fetchFirewallPortForwardTable(backend, setFirewallPortForwards) {
    let result = await backend.retrieveFresh('firewall_port_forwarding_table')

    if(result.status === backendStatus.SUCCESS){

        let firewallPortForwardsList = result.content

        setFirewallPortForwards(firewallPortForwardsList)
    }
}

export async function saveFirewallPortForwardTable(backend, firewallPortForwards, setErrorMessage) {

    try{

        let result = null;
        if (firewallPortForwards.id) {
            result = await backend.update(`firewall_port_forwarding_table/${firewallPortForwards.id}`, firewallPortForwards)
        } else {
            result = await backend.create(`firewall_port_forwarding_table`, firewallPortForwards)
        }

        if (result.status !== backendStatus.SUCCESS) {
            setErrorMessage(`[Field: ${result.content.body.field}, Message: ${result.content.body.message}]`)
        }

        return result.status === backendStatus.SUCCESS

    }catch(error){

        console.error('Error saving Firewall Port Forward Table:', error)

        return false
    }

}

export async function deleteFirewallPortForwardTableById(backend, id, setFirewallPortForwardTableList) {
    let result = await backend.delete(`firewall_port_forwarding_table/${id}`)

    if(result.status === backendStatus.SUCCESS){
        fetchFirewallPortForwardTable(backend, setFirewallPortForwardTableList)
    }
}
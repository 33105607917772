import React, { useState, useEffect, useContext } from 'react'

import { useHistory } from 'react-router-dom';

import './voip-proxy.css'
import { BackendContext } from '../../../../backend/backend'
import { fetchVoipProxyList } from '../../../resources/voip-proxy';
import Loading from '../../../../components/loading/loading';
import InfoCard from '../../../../components/infocard/infocard';
import DefaultStatusModals from '../../../../components/modal/default-status-modals';
import { useTranslation } from 'react-i18next';
import Translator from '../../../common/components/translator/translator';
import EditIcon from '../../../../components/icons/edit';


export default function VoipProxyList({isWizard, setSaved, isFormSegment, formSegment}) {
    const [voipProxyList, setVoipProxyList] = useState(null)

    const history = useHistory()

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    const backend = useContext(BackendContext)

    const { t } = useTranslation()

    useEffect(() => {

        fetchVoipProxyList(backend, setVoipProxyList)

        // eslint-disable-next-line
    }, [])

    const editProxy = id => {
        history.push(`/voip/proxy/${id}`)
    }

    function voipInternalTranslateRegisterStatus(register_status) {
        switch(register_status)  {
            case 'Not registered':
                return t('voip.proxy.label.register_status.NOT_REGISTERED')
            case 'Registered':
                return t('voip.proxy.label.register_status.REGISTERED')
            case 'Registering':
                return t('voip.proxy.label.register_status.REGISTERING')
            case 'Register fail':
                return t('voip.proxy.label.register_status.REGISTER_FAIL')
            case 'VoIP restart':
                return t('voip.proxy.label.register_status.VOIP_RESTART')
            default:
                return t('voip.proxy.label.register_status.ERROR')
        }
    }

    const voipProxyListTable = () => {
        return <React.Fragment>

            { voipProxyList.map((prx, key) => 
                <InfoCard
                    key={key}
                    lines={[
                        {label: t('voip.proxy.label.NAME'), value: prx.display_name ? prx.display_name : `Proxy${prx.id}`, id: `card-voip-proxy-name-${key}`},
                        {label: t('voip.proxy.label.PORT'), value: prx.voip_port.toUpperCase() },
                        {label: t('voip.proxy.label.ENABLED'), value: prx.proxy_enable ? t('modal.answer.YES') : t('modal.answer.NO'), id: `card-voip-proxy-proxy-enabled-${key}`},
                        {label: t('voip.proxy.label.DEFAULT_PROXY'), value: prx.default_proxy ? t('modal.answer.YES') : t('modal.answer.NO'), id: `card-voip-proxy-default-${key}`},
                        {label: t('voip.proxy.label.REGISTER_STATUS'), value: voipInternalTranslateRegisterStatus(prx.register_status), id: `card-voip-proxy-register-status-${key}`},
                        {label: t('common.label.OPERATIONS'), value:  <span className="card-voip-edit-icon" onClick={e => editProxy(prx.id)}><EditIcon /></span> },
                    ]}
                ></InfoCard>)
            }
            
        </React.Fragment>
    }

    const dismissModal = () => {
        setSaving(false)
        setError(false)
        setSuccess(false)
        if (isWizard)
            setSaved(true)
    }

    return (
        !voipProxyList ? <Loading show={true}></Loading> :

        <div id='voip-proxy-page' className='with-tabs'>
            <div className='section-divider'></div>
            <div className='subtitle'><Translator path="voip.proxy.title.ACCOUNT_01"></Translator></div>

            <DefaultStatusModals
                saving={saving}
                error={error}
                success={success}
                continueFn={dismissModal}
                successText={<Translator path="common.message.info.SUCCESS_ON_PERSIST"></Translator>}
            ></DefaultStatusModals>
  
            {voipProxyListTable()}

        </div>
    )
}

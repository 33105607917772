import { backendStatus } from '../../backend/backend'

const URL = 'rip_enable';

export async function fetchServiceRipEnable(backend, id, setRipEnable) {
    
    let result = await backend.retrieveFresh(`${URL}/${id}`)

    if(result.status === backendStatus.SUCCESS){
        setRipEnable(result.content)
    }
    
}

export async function saveServiceRipEnable(backend, ripEnable, setErrorMessage) {

    try{

        let result = null;

        if(!ripEnable.id) {
            result = await backend.create(`${URL}`, ripEnable)
        }
        else {
            result = await backend.update(`${URL}/${ripEnable.id}`, ripEnable)
        }

        if(result.status !== backendStatus.SUCCESS) {
            setErrorMessage(result.content?.body.message)
        }

        return result.status === backendStatus.SUCCESS && (result.content || ripEnable)

    }catch(error){

        console.error('Error saving RIP_ENABLE:', error)

        return false
    }

}
import React from 'react'
import Menu from '../../../components/menu/menu'
import SearchBar from '../../common/components/search/search-bar'
import I18n from '../../common/components/i18n/i18n'
import intelbrasLogo from '../../assets/images/logo-intelbras.png'
import { useTranslation } from 'react-i18next'
import MainMenu from './menu/main-menu'
import SearchState from './search-context'

export default function SideBar(props) {

    let { t } = useTranslation()

    return (
        <Menu onLogout={props.handleLogout} customLogo={intelbrasLogo} headerMessage={<div className="intelbras-logo-message">{t('common.label.ALWAYS_NEAR')}</div>}>
            <I18n></I18n>
            <SearchState>
                <SearchBar />
                <MainMenu />
            </SearchState>
        </Menu>)
}